(function () {
	angular.module('Plania').directive("viewedit", ['$localStorage', function ($localStorage) {
		return function (scope, element, attrs) {
			var dtElement,
				inputElement,
				autocompleteElement,
				timepickerelement,
				editModeElement,
				viewModeElement,
				checkboxElement,
				datePickerElement,
				inputGroupElement;

			var hideInputElement = function () {
				dtElement.removeClass("p-t-10");
				checkboxElement.css('display', 'none');
				autocompleteElement.css('display', 'none');
				timepickerelement.css('display', 'none');
				datePickerElement.css('display', 'none');
				inputGroupElement.css('display', 'none');
				inputElement.css('display', 'none');
				editModeElement.css('display', 'none');
				viewModeElement.css('display', 'inline-block');
			};

			var showInputElement = function () {
				if (checkboxElement[0] && checkboxElement[0].className.indexOf('checkbox-inline') > -1)
					checkboxElement.css('display', 'inline-block');
				else
					checkboxElement.css('display', 'block');

				if (inputGroupElement[0] && inputGroupElement[0].className.indexOf('input-group-flex') > -1)
					inputGroupElement.css('display', 'flex');
				else
					inputGroupElement.css('display', 'table');

				dtElement.addClass("p-t-10");
				autocompleteElement.css('display', 'block');
				timepickerelement.css('display', 'block');
				datePickerElement.css('display', 'block');
				inputElement.css('display', 'inline-block');
				editModeElement.css('display', 'inline-block');
				viewModeElement.css('display', 'none');
			};


			scope.$watch('viewMode', function () {
				dtElement = element.find('dt');
				inputElement = element.find('.fg-line');
				autocompleteElement = element.find('autocomplete');
				timepickerelement = element.find('timepicker');
				editModeElement = element.find('.editModeElement');
				viewModeElement = element.find('.viewModeElement');
				checkboxElement = element.find('.checkbox');
				datePickerElement = element.find('pl-datepicker');
				inputGroupElement = element.find('.input-group');

				//Handling dynamicProperties
				if (scope.model && scope.model.DynamicProperty && $localStorage.userData.canOverridePermissions) {

					if (scope.property && scope.property.ForceEdition) {
						showInputElement();
						return;
					}
				}

				if (scope.viewMode) {
					hideInputElement();
				} else {
					showInputElement();
				}
			});


		};
	}]);
})();
