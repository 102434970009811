(function () {
	angular.module('Plania').controller('QrCodeOpenModalController', ['$scope', '$modal', controller]);

	function controller($scope, $modal) {
		var vm = this;

		vm.openQRCodeModal = function () {
			$modal.open({
				controller: 'QrCodeModalController',
				templateUrl: 'app/common/views/qrCodeModal.html'
			});
		};

	}
})();
