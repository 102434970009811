(function () {
	angular.module('Plania').directive('qrCode', ['$timeout', '$window', function ($timeout, $window) {
		return {
			restrict: 'E',
			scope: {
				data: '=data',
				width: '=width',
				height: '=height',
				reload: '=reload'
			},
			templateUrl: 'app/common/directives/views/qrcode.html',
			link: function (scope, element, attrs) {
				var maxHeight = 180;
				var width = scope.width;

				var qrcode;

				$timeout(function () {
					if (width === 0) {
						width = element.find('.qr-code').innerWidth() - 24;

						if (width > maxHeight) width = maxHeight;
					}

					createQrCode();
				});

				var createQrCode = function () {
					qrcode = new QRCode(element.find('.qr-code')[0], {
						text: scope.data,
						width: width ? width : 128,
						height: width ? width : 128,
						colorDark: "#000000",
						colorLight: "#ffffff",
						correctLevel: QRCode.CorrectLevel.H
					});
				};

				var resize = function () {
					element.find('.qr-code img').remove();
					element.find('.qr-code canvas').remove();
					qrcode.clear();
					createQrCode();
				};

				scope.$watch('reload', function (newValue, oldValue) {
					if (newValue === oldValue) return;

					if (scope.reload) {
						if (scope.width === 0)
							width = element.find('.qr-code').innerWidth() - 24;

						resize();

						scope.reload = false;
					}
				});
			}
		};
	}
	]);
})();
