//This directive displays a warning text if the element is deactivated
(function () {

	var app = angular.module('Plania');

	app.directive('plDeactivatedWarning', function () {
		return {
			restrict: 'E',
			replace: true,
			template:
				'<small style="color: #D7263D">' +
					'<i class="fas fa-triangle-exclamation"></i> <span pl-translate="web-common-isDeactivated">Deaktivert for fremtidig bruk</span>' +
				'</small>'
		};
	});

})();
