(function () {
	var app = angular.module('Plania');
	app.directive('entityTask', function () {
		return {
			restrict: 'E',
			scope: {
				reload: '=reload',
				entityType: '=entityType',
				guidEntity: '=guidEntity',
				disableActions: '=disableActions'
			},
			controller: ['$scope', 'Repository', 'TranslationService', controller],
			templateUrl: 'app/common/directives/views/entityTaskDirective.html'
		};
	});

	function controller($scope, repository, translationService) {
		if (!$scope.guidEntity || !$scope.entityType) return;

		$scope.entityLinks = [];
		getEntityLinks();

		function getEntityLinks() {
			repository.getWithUrlParameter(repository.apiData.entityLink.url + "actionsForEntity", 'guidEntity=' + $scope.guidEntity + '&prefix=' + $scope.entityType).then(function (result) {
				$scope.totalCount = result.length;
				$scope.entityLinks = result;
			}, function (error) {
				repository.showError(error);
			});
		}

		$scope.getStatusColor = function(status) {
			if (status === 'Created') return 'info';
			switch (status) {
				case 'Created':
					return 'info';
				case 'Error':
					return 'danger';
			}
		};

		$scope.runAction = function (link) {
			swal({
				title: "Bekreft",
				text: translationService.translate('web-entityTask-confirmAction', 'Vil du opprette handling %Name%?', link),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#337ab7",
				confirmButtonText: "Ja",
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				var params = {
					GuidEntity:  $scope.guidEntity
				};
				var guidEntityLink = link.Guid;
				repository.runAction(repository.apiData.entityLink.url + guidEntityLink + '/doAction', params).then(function (response) {
					repository.growl("Utført", 'success');
					getEntityLinks();
					swal.close();
				}).catch(function (error) {
					repository.growl(error, 'danger');
					getEntityLinks();
					swal.close();
				});
			});
		};
	}
})();
