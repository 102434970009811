(function () {
	var planiaModule = angular.module('Plania');
	var disableAnimation = false;

	planiaModule.animation('.notificationList', [function () {
		return {
			enter: function (element, doneFn) {
				if (disableAnimation) {
					doneFn();
					return;
				}
				// Hack to make the animation work on enter.
				element.css('display', 'none');
				jQuery(element).slideDown(500, doneFn);
			},

			leave: function (element, doneFn) {
				if (disableAnimation) {
					doneFn();
					return;
				}
				jQuery(element).fadeOut(500, doneFn);
			}
		};
	}]);

	planiaModule.controller('UserNotificationController', ['$scope', '$rootScope', 'authService', 'Repository', '$timeout', 'SignalR', '$localStorage', controller]);
	function controller($scope, $rootScope, authService, repository, $timeout, signalR, $localStorage) {
		$scope.showNotificationBell = false;
		$scope.showSubMenu = false;
		$scope.notificationListData = { TotalCount: 0, List: [] };
		$scope.unreadNotificationListData = { TotalCount: 0, List: [] };
		$scope.pageSize = { recent: 10, unread: 10 };
		$scope.currentTab = "recent";
		var userData = authService.getUserData();

		if (userData.guidPerson) {
			repository.getSingle(repository.apiData.person.url, userData.guidPerson).then(function (response) {
				$scope.showNotificationBell = $rootScope.hasReadAccess("UserXUserNotification") && (response.Data.DoesCleaningTasks || $localStorage.generalOptions.EnableWebWorkOrderNotifications);
				if (!$scope.showNotificationBell) return;

				getNotifications();
				getNotifications(true);

				// Returns UserXUserNotification
				signalR.on('newUserNotification', function (userXUserNotification) {
					if (!$scope.notificationListData.List.find(function (item) { item.Guid === userXUserNotification.Guid; })) {
						$scope.notificationListData.List.unshift(userXUserNotification);
						$scope.notificationListData.TotalCount++;
						$scope.pageSize.recent++;
					}

					if (!userXUserNotification.ReadDate && !$scope.unreadNotificationListData.List.find(function (item) { item.Guid === userXUserNotification.Guid; })) {
						$scope.unreadNotificationListData.List.unshift(userXUserNotification);
						$scope.unreadNotificationListData.TotalCount++;
						$scope.pageSize.unread++;
					}
				});

				// Returns UserXUserNotification
				signalR.on('updatedUserNotification', function (userXUserNotification) {
					$scope.unreadNotificationListData.List = $scope.unreadNotificationListData.List.map(function (notification) {
						if (notification.Guid === userXUserNotification.Guid)
							return userXUserNotification;
						return notification;
					});

					$scope.notificationListData.List = $scope.notificationListData.List.map(function (notification) {
						if (notification.Guid === userXUserNotification.Guid)
							return userXUserNotification;
						return notification;
					});
				});

				signalR.on('userXUserNotificationDeleted', function (guidDeleted) {
					$scope.unreadNotificationListData.List = $scope.unreadNotificationListData.List.filter(function (notification) {
						if (notification.Guid === guidDeleted) {
							$scope.unreadNotificationListData.TotalCount--;
							return false;
						} else {
							return true;
						}
					});

					$scope.notificationListData.List = $scope.notificationListData.List.filter(function (notification) {
						if (notification.Guid === guidDeleted) {
							$scope.notificationListData.TotalCount--;
							return false;
						} else {
							return true;
						}
					});
				});

			}, function (error) {
				$scope.showNotificationBell = false;
			});
		}

		var getNotifications = function (getUnread) {
			var filter = {
				PropertyFilter: [
					{ Property: "GuidUser", Operator: "=", Value: userData.guidUser }
				]
			};
			if (getUnread)
				filter.PropertyFilter.push({ Property: "ReadDate", Operator: "=", Value: null });

			repository.GetPaginated(repository.apiData.userXUserNotification.url, 0, getUnread ? $scope.pageSize.unread : $scope.pageSize.recent, { CreationDate: 'desc' }, filter).then(function (result) {
				if (getUnread)
					$scope.unreadNotificationListData = result;
				else
					$scope.notificationListData = result;
			});
		};

		$scope.getNotificationListData = function () {
			return $scope.currentTab === "recent" ? $scope.notificationListData : $scope.unreadNotificationListData;
		};

		$scope.setNotificationAsSeen = function (notification) {
			if (notification.ReadDate) return;

			var setDate = function (date) {
				$scope.unreadNotificationListData.List.forEach(function (notice) {
					if (notice.Guid === notification.Guid)
						notice.ReadDate = date;
				});

				$scope.notificationListData.List.forEach(function (notice) {
					if (notice.Guid === notification.Guid)
						notice.ReadDate = date;
				});
			};

			setDate(new Date().toISOString());

			repository.updateSingle(repository.apiData.userXUserNotification.url, notification).then(function () {
				$scope.unreadNotificationListData.TotalCount--;
				if ($scope.unreadNotificationListData.TotalCount < 0) $scope.unreadNotificationListData.TotalCount = 0;
				$scope.unreadNotificationListData.List = $scope.unreadNotificationListData.List.filter(function (item) { return !item.ReadDate; });
			}, function (error) {
				setDate(null);
				repository.growl(error, 'danger');
			});
		};

		$scope.navigateToEntity = function (notification) {
			if ((notification.UserNotification.EntityType === "AreaXCleaningTask" || notification.UserNotification.EntityType === 252) && notification.GuidDrawing) {
				$scope.navigation.go('dwg.view', { guid: notification.GuidDrawing, selectedTheme: 'cleaning', guidArea: notification.GuidArea }, { reload: true });
			}

			if ((notification.UserNotification.EntityType === "EntityComment" || notification.UserNotification.EntityType === 65)) {
				if (notification.GuidWorkOrder) {
					$scope.navigation.go('workOrder.edit', { guid: notification.GuidWorkOrder }, { reload: true });
				}
				if (notification.GuidRequest) {
					$scope.navigation.go('request.edit', { guid: notification.GuidRequest }, { reload: true });
				}
			}

			if ((notification.UserNotification.EntityType === "WorkOrder" || notification.UserNotification.EntityType === 6) && notification.GuidWorkOrder) {
				$scope.navigation.go('workOrder.edit', { guid: notification.GuidWorkOrder }, { reload: true });
			}
			if ((notification.UserNotification.EntityType === "Request" || notification.UserNotification.EntityType === 179) && notification.GuidRequest) {
				$scope.navigation.go('request.edit', { guid: notification.GuidRequest }, { reload: true });
			}
		};

		$scope.setAllAsRead = function () {
			var guids = [];
			$scope.unreadNotificationListData.List.forEach(function (notification) {
				guids.push(notification.Guid);
				notification.ReadDate = new Date().toISOString();
			});

			$scope.notificationListData.List.forEach(function (notification) {
				if (guids.indexOf(notification.Guid) > -1)
					notification.ReadDate = new Date().toISOString();
			});

			$scope.unreadNotificationListData.List = [];
			$scope.unreadNotificationListData.TotalCount = 0;

			if (guids.length > 0)
				repository.updateMultiple(repository.apiData.userXUserNotification.endpoint.setAsRead, guids);
		};

		$scope.respondToNotification = function (notification) {
			if (notification.blockRespondAction) return;
			notification.blockRespondAction = true;

			if ((notification.UserNotification.EntityType === "AreaXCleaningTask" || notification.UserNotification.EntityType === 252) &&
				notification.UserNotification.GuidEntity && !notification.UserNotification.GuidUserHandledBy && userData.guidPerson) {
				repository.patch(repository.apiData.areaXCleaningTask.url, notification.UserNotification.GuidEntity, { GuidCleaner: userData.guidPerson }).then(function (result) {
					notification.blockRespondAction = false;
				}, function (error) {
					notification.blockRespondAction = false;
					repository.growl(error, "danger");
				});
			} else
				notification.blockRespondAction = false;
		};

		$scope.setTab = function (tab) {
			disableAnimation = true;
			$scope.currentTab = tab;
			$timeout(function () {
				disableAnimation = false;
			}, 100);
		};

		$scope.getMoreNotifications = function () {
			if ($scope.currentTab === "recent") $scope.pageSize.recent += 10;
			else $scope.pageSize.unread += 10;
			getNotifications($scope.tab === "unread");
		};

		$scope.getTimeSinceNotification = function (notification) {
			var creationDate = moment(notification.CreationDate);
			var now = moment();

			if (now.diff(creationDate, 'month')) return now.diff(creationDate, 'month') + (now.diff(creationDate, 'month') > 1 ? " måneder" : " måned");
			if (now.diff(creationDate, 'week')) return now.diff(creationDate, 'week') + (now.diff(creationDate, 'week') > 1 ? " uker" : " uke");
			if (now.diff(creationDate, 'days')) return now.diff(creationDate, 'days') + (now.diff(creationDate, 'days') > 1 ? " dager" : " dag");
			if (now.diff(creationDate, 'hours')) return now.diff(creationDate, 'hours') + (now.diff(creationDate, 'hours') > 1 ? " timer" : " time");
			if (now.diff(creationDate, 'minutes')) return now.diff(creationDate, 'minutes') + (now.diff(creationDate, 'minutes') > 1 ? " minutter" : " minutt");
			if (now.diff(creationDate, 'seconds')) return now.diff(creationDate, 'seconds') + (now.diff(creationDate, 'seconds') > 1 ? " sekunder" : " sekund");
		};

		$scope.$on('$destroy', function () {
			signalR.off('newUserNotification');
			signalR.off('updatedUserNotification');
		});

		$scope.toggleSubMenuVisibility = function () {
			$scope.showSubMenu = !$scope.showSubMenu;
		};

		$scope.mainDropdownToggled = function (open) {
			if (!open)
				$scope.showSubMenu = false;
		};

		$scope.getHandledByUserName = function (notification) {
			if (!notification.UserNotification)
				return "";
			if (!notification.UserNotification.HandledByUser)
				return "";
			if (notification.UserNotification.HandledByUser.Person && notification.UserNotification.HandledByUser.FirstName)
				return notification.UserNotification.HandledByUser.Person.FirstName + " " + notification.UserNotification.HandledByUser.Person.LastName;
			if (notification.UserNotification.HandledByUser.RealName)
				return notification.UserNotification.HandledByUser.RealName;
			return "";
		};
	}
})();
