(function () {
	angular.module('Plania').controller('EditDocumentXEntityController', ['$scope', 'Repository', 'params', 'authService','$modalInstance' ,controller]);

	function controller($scope, repository, params, authService, $modalInstance) {

		$scope.update = true;
	    $scope.restrictDelete = true;
	    $scope.hideClose = true;
		repository.getSingle(repository.apiData.document.url, params.guid).then(
			function (response) {
				$scope.model = response.Data;
				$scope.restrictEdit = !authService.hasEditAccess('Document');
			},
			function (error) {
			    repository.growl(error.Data ? error.Data.message : error.Message, 'danger');
			});

		$scope.editDocument = function () {
		    if (!$scope.model) {
		        repository.growl('Could not save document', 'danger');
		    }
		    repository.updateSingle(repository.apiData.document.url, $scope.model).then(
				function () {
				    repository.growl('Dokumentet ble lagret', 'success');
				    $modalInstance.close('Ok');
				},
				function (error) {
				    repository.growl('Kunne ikke lagre dokumentet - ' + error.message, 'danger');
				});
		};


		$scope.cancel = function () {
		    $modalInstance.dismiss('canceled');
		};
	}
})();
