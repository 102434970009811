(function () {
    var app = angular.module('Plania');
    app.service('messageService', [
        '$resource', function ($resource) {
            var ms = {};
            ms.getMessage = function (img, user, text) {
                var gmList = $resource("data/messages-notifications.json");

                return gmList.get({
                    img: img,
                    user: user,
                    text: text
                });
            };

            return ms;
        }
    ]);
})();

(function () {
    var app = angular.module('Plania');
    app.service('scrollService', function () {
        var ss = {};
        ss.malihuScroll = function scrollBar(selector, theme, mousewheelaxis) {
        	$(selector).mCustomScrollbar({
        		autoExpandScrollbar:true,
                theme: theme,
                scrollInertia: 100,
                axis: 'yx',
                mouseWheel: {
                    enable: true,
                    axis: mousewheelaxis,
                    preventDefault: true
                }
            });
        };

        return ss;
    });
})();

(function() {
    var app = angular.module('Plania');
    app.service('growlService', function() {
        var gs = {};
        gs.growl = function(message, type, options) {

            if (!options) {
                options = {};
            }
            if (!options.enter)
                options.enter = 'animated bounceIn';
            if (!options.exit)
                options.exit = 'animated bounceOut';


            $.growl({
                message: message
            }, {
                type: type,
                allow_dismiss: true,
                label: 'Cancel',
                className: 'btn-xs btn-inverse',
                placement: {
                    from: 'top',
                    align: 'right'
                },
                showProgressbar: true,
                z_index:2000,
				delay: type === 'danger' ? 10000 : 3000,
                animate: {
                    enter: options.enter,
                    exit: options.exit
                },
                offset: {
                    x: 20,
                    y: 85
                }
            });
        };
        return gs;
    });
})();
