(function () {
	var app = angular.module('Plania');
	app.directive('buildingInfoDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				reloadOperationalMessages: '=reloadOperationalMessages',
				reloadDocumentTable: '=reloadDocumentTable',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				formReference: '=',
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', 'RegisteredFieldService', '$localStorage', controller],
			templateUrl: 'app/building/views/buildingInfo.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout, registeredFieldService, $localStorage) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.userInfo = $rootScope.userInfo;
		$scope.customization = $rootScope.customization;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.imageApiUrl = $rootScope.imageApiUrl;
		$scope.hideId = $localStorage.generalOptions.HideIdOnEstateAndBuildingCreation && $rootScope.navigation.current.name !== 'building.edit';
		$scope.isDynamicPropertiesCollapsed = false;
		$scope.isDocumentsCollapsed = true;
		$scope.viewMode = $scope.isUpdate;
		$scope.hasPageLoaded = $scope.currentTab === 'info';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'info' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.$watchCollection('model.Images', function () {
			if ($scope.model && $scope.model.Images && Array.isArray($scope.model.Images)) {
				var defaultImage = $scope.model.Images.find(function (img) {
					return img.IsDefault;
				});
				if (defaultImage)
					$scope.model.GuidDefaultImage = defaultImage.Guid;
				else
					delete $scope.model.GuidDefaultImage;
			}
		});

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if (estate.GuidBusinessUnit && !$scope.model.GuidBusinessUnit) {
				$scope.model.GuidBusinessUnit = estate.GuidBusinessUnit;
				$scope.model.BusinessUnit = estate.BusinessUnit;
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.GuidPost = null;
				$scope.model.PostalArea = '';
				$scope.model.County = '';
				$scope.model.Municipality = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.model.County = postalInfo.County;
				$scope.model.Municipality = postalInfo.MunicipalityName;
				$scope.postalInfo = postalInfo;
			}
		};

		$scope.countryCodeChanged = function () {
			// Don't clear post code if country code never was set previously
			if (!$scope.model.CountryCode || $scope.model.CountryCode === 'Undefined')
				return;

			$scope.model.PostalCode = null;
			$scope.onPostCodeSelect(null);
		};

		$scope.onBuildingCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidBuildingCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidBuildingCategory = category.Guid;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "postal":
					if ($scope.model.CountryCode !== -1 && $scope.model.CountryCode !== 'Undefined') {
						filter.FilterModel = {
							Condition: "and",
							Rules: [
								{ Property: 'CountryCode', Operator: '=', Value: $scope.model.CountryCode }
							]
						};
					}
					return filter;
				case "person":
					filter.PropertyFilter = [{ Property: 'IsOperationsManager', Operator: "=", Value: true }];
					return filter;
				case "buildingCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
				case "buildingType":
					filter.FilterModel = {
						Condition: 'and',
						Rules: [
							{
								Condition: 'or',
								Rules: [
									{ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() },
									{ Property: "DisabledFromDate", Operator: "=", Value: null }
								]
							},
							{ Property: 'ReferenceType.Context', Operator: "=", Value: 1 }
						]
					};
					return filter;
				case "businessUnit":
					filter.FilterModel = {
						Condition: 'and',
						Rules: [
							{
								Condition: 'or',
								Rules: [
									{ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() },
									{ Property: "DisabledFromDate", Operator: "=", Value: null }
								]
							},
							{ Property: 'ReferenceType.Context', Operator: "=", Value: 3 }
						]
					};
					return filter;
			}
		};

		$scope.supplierInfo = new function () {
			var me = this;

			this.showSupplierLineOfBusiness = false;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-building-buildingXSupplier-supplier-id', 'Leverandør'), Property: 'Supplier.Id', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-building-buildingXSupplier-supplier-supplierLineOfBusiness', 'Bransje'), Property: 'Supplier.SupplierLineOfBusiness.Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-building-buildingXSupplier-supplier-address1', 'Adresse'), Property: 'Supplier.Address1', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-building-buildingXSupplier-supplier-postalcode', 'Postnr.'), Property: 'Supplier.PostalCode', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-building-buildingXSupplier-supplier-postalarea', 'Poststed'), Property: 'Supplier.PostalArea', PropertyType: 'string' },
				{ Position: 7, Title: translationService.translate('web-building-buildingXSupplier-supplier-email', 'E-post'), Property: 'Supplier.Email', PropertyType: 'string' }
			];

			this.getPropertyValue = function (item, column) {
				if (column.Property === "Supplier.Id") {
					if (!item.Supplier) return "";
					return item.Supplier.Caption;
				}

				return listService.GetPropertyValue(item, column);
			};

			this.initRegisteredFields = function () {
				registeredFieldService.getFieldRule(repository.commonService.prefix.Supplier, 'GuidSupplierLineOfBusiness').then(function (result) {
					me.showSupplierLineOfBusiness = !result.Hidden && !($scope.userInfo.isExternalUser && result.ExternallyHidden);
				});
			};

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 10,
						sorting: { 'Supplier.Id': 'asc' },
						filter: { PropertyFilter: [{ Property: "GuidBuilding", Operator: "=", value: $scope.model.Guid }] }
					},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						paginationMaxBlocks: 6,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('Supplier.Description');
							columns.push('Supplier.GuidSupplierLineOfBusiness');

							me.isLoading = true;
							repository.GetPaginated(repository.apiData.buildingSupplier.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								me.isLoading = false;
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								me.isLoading = false;
								repository.growl(error, 'danger');
							});
						}
					});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					resolve: {
						modalParams: function () {
							return {
								title: 'Velg leverandører',
								columns: [
									{ Title: translationService.translate('web-supplier-id'), Property: 'Id' },
									{ Title: translationService.translate('web-supplier-description'), Property: 'Description' }
								],
								useObjects: false,
								url: repository.apiData.supplier.url,
								sorting: { Id: 'asc' },
								filter: {},
								alreadySavedGuids: function () {
									var selectedGuids = [];
									me.table.data.forEach(function (entity) {
										selectedGuids.push(entity.GuidSupplier);
									});
									return selectedGuids;
								}()
							};
						}
					}
				}).result.then(function (selectedEntities) {
					var buildingXSupplier = [];
					selectedEntities.forEach(function (entity) {
						buildingXSupplier.push({ GuidBuilding: $scope.model.Guid, GuidSupplier: entity });
					});
					repository.createMultiple(repository.apiData.buildingSupplier.url, buildingXSupplier).then(function (result) {
						repository.growl('Leverandør(er) har blitt latt til bygg', 'success');
						me.table.reload();
					});
				});
			};

			this.remove = function (supplier) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-building-supplier-message', "Knytning til leverandør vil bli slettet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-building-supplier-button-confirm', 'Ja, fjern knytning'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.buildingSupplier.url, supplier.Guid).then(function (result) {
						repository.growl(translationService.translate('web-swal-building-supplier-success', 'Knytning av leverandør ble fjernet!'), 'success');
						me.table.reload();
					});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];
				if ($scope.hasReadAccess("Supplier"))
					options.push(getMenuLink($rootScope.navigation.href('supplier.edit', { guid: item.GuidSupplier }), translationService.translate('web-building-buildingXSupplier-viewSupplier', 'Gå til leverandør')));

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-building-buildingXSupplier-delete', 'Slett knytning') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				if (item.Supplier.Email) {
					options.push(getMenuLink('mailto:{{supplier.Supplier.Email}}', translationService.translate('web-building-buildingXSupplier-send-mail', 'Send mail')));

				}
				return options;
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('BuildingXSupplier');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('BuildingXSupplier');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('BuildingXSupplier');
			};
			this.initTable();
			this.initRegisteredFields();
		}();

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.buildingPersonInfo = new function () {
			var me = this;
			this.isLoading = false;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-building-buildingXPerson-person-firstname', 'Navn'), Property: 'Person.FirstName', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-building-buildingXPerson-person-telephone', 'Telefon'), Property: 'Person.TelephoneWork', PropertyType: 'string', SortingDisabled: true },
				{ Position: 3, Title: translationService.translate('web-building-buildingXPerson-resourcegroup-id', 'Ressurs'), Property: 'Person.ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
				{ Position: 4, Title: translationService.translate('web-building-buildingXPerson-personrole-id', 'Rolle'), Property: 'PersonRole.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPersonRole', AutocompleteService: 'personRole' },
				{ Position: 1, Title: translationService.translate('web-building-buildingXPerson-person-email', 'E-post'), Property: 'Person.Email', PropertyType: 'string' },
			];

			this.getPropertyValue = function (item, column) {
				if (column.Property === "Person.FirstName") {
					if (!item.Person) return "";
					return item.Person.Caption;
				}

				if (column.Property === "Person.TelephoneWork") {
					if (!item.Person) return "";
					var texts = [];
					if (item.Person.TelephoneWork)
						texts.push(item.Person.TelephoneWork);
					if (item.Person.Cellphone)
						texts.push(item.Person.Cellphone);
					return texts.join(" / ");
				}
				return listService.GetPropertyValue(item, column);
			};

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 10,
						sorting: { 'Person.FirstName': 'asc' },
						filter: { PropertyFilter: [{ Property: "GuidBuilding", Operator: "=", Value: $scope.model.Guid }] }
					},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});
							columns.push('Person.LastName');
							columns.push('Person.Cellphone');

							me.isLoading = true;
							repository.GetPaginated(repository.apiData.buildingPerson.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								me.isLoading = false;
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								me.isLoading = false;
								repository.growl(error, 'danger');
							});
						}
					});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/buildingPerson/views/createBuildingPerson.html',
					controller: 'BuildingPersonController',
					resolve: {
						params: function () {
							return {
								GuidBuilding: $scope.model.Guid,
								selectedEntityGuids: _.map(me.table.data, function (row) {
									return { GuidPerson: row.GuidPerson, GuidBuilding: row.GuidBuilding, GuidPersonRole: row.GuidPersonRole };
								}),
								buildingCaption: $scope.model.Id + ' - ' + $scope.model.Description,
								isModal: true
							};
						}
					}
				}).result.then(function (selectedPersons) {
					me.table.reload();
				}, function () {
				});
			};

			this.edit = function (item) {
				$modal.open({
					templateUrl: 'app/buildingPerson/views/editBuildingPerson.html',
					controller: 'BuildingPersonController',
					resolve: {
						params: function () {
							return {
								modalParam: {
									isUpdate: true,
								},
								guid: item.Guid,
								isModal: true
							};
						}
					}
				}).result.then(function () {
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.remove = function (buildingXPerson) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-building-person-message', "Dette bygget bli frakoblet personen!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-building-person-button-confirm', 'Ja, fjern knytning'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.buildingPerson.url, buildingXPerson.Guid).then(function (result) {
						swal('Suksess!', translationService.translate('web-swal-building-person-success', 'Knytning ble fjernet!'), "success");
						me.table.reload();
					});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];
				if ($scope.hasReadAccess("Person"))
					options.push(getMenuLink($rootScope.navigation.href('person.edit', { guid: item.GuidPerson }), translationService.translate('web-building-buildingXPerson-viewPerson', 'Gå til person')));

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-building-buildingXPerson-edit', 'Rediger') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.buildingPersonInfo.edit(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-building-buildingXPerson-delete', 'Slett knytning') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.buildingPersonInfo.remove(item);
						}
					});
				}

				if (item.Person.Email) {
					options.push(getMenuLink('mailto:{{person.Person.Email}}', translationService.translate('web-building-buildingXPerson-send-mail', 'Send mail')));

				}
				return options;
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('BuildingXPerson');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('BuildingXPerson');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('BuildingXPerson');
			};
			this.initTable();
		}();

		$scope.contractInfo = new function () {
			var me = this;
			this.isLoading = false;

			this.columns = [
				'Id',
				'Description',
				'Customer.Id',
				'Customer.Description',
				'StartDate',
				'EndDate',
				'Status'
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 10,
						sorting: { 'Id': 'desc' },
						filter: { PropertyFilter: [{ Property: "ContractItems.GuidBuilding", Operator: "=", Value: $scope.model.Guid }, { Property: 'ContractItems.IsCurrentVersion', Operator: '=', Value: 'true' }] }
					},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						paginationMaxBlocks: 6,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col);
							});

							this.isLoading = true;
							repository.GetPaginated(repository.apiData.contract.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								this.isLoading = false;
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								this.isLoading = false;
								repository.growl(error, 'danger');
							});
						}
					});
			};
			this.initTable();
		}();

		$scope.getBuildingTypeColumns = function () {
			return [
				{ Title: translationService.translate('web-buildingType-indexPosition', 'Indeks'), Property: 'IndexPosition' },
				{ Title: translationService.translate('web-buildingType-id', 'Id'), Property: 'Id' },
				{ Title: translationService.translate('web-buildingType-description', 'Beskrivelse'), Property: 'Description' },
			];
		};

		$scope.getBusinessUnitColumns = function () {
			return [
				{ Title: translationService.translate('web-businessUnit-indexPosition', 'Indeks'), Property: 'IndexPosition' },
				{ Title: translationService.translate('web-businessUnit-id', 'Id'), Property: 'Id' },
				{ Title: translationService.translate('web-businessUnit-description', 'Beskrivelse'), Property: 'Description' },
			];
		};

		$scope.openReferenceData = function (type) {
			var guid = "";
			var initialModel = null;

			switch (type) {
				case "BusinessUnit":
					guid = $scope.model.GuidBusinessUnit;
					initialModel = $scope.model.BusinessUnit;
					break;
				case "BuildingType":
					guid = $scope.model.GuidBuildingType;
					initialModel = $scope.model.BuildingType;
					break;
			}

			if (!guid) return;

			$modal.open({
				templateUrl: 'app/referenceData/views/referenceDataInfoModal.html',
				controller: 'ReferenceDataInfoModalController',
				resolve: {
					modalParams: function () {
						return {
							guid: guid,
							initialModel: initialModel
						};
					}
				},
				size: 'lg'
			});
		};

		$scope.getPropertyCadastralNumber = function () {
			if (!$scope.model.PropertyCadastralNumber && !$scope.model.PropertyUnitNumber && !$scope.model.PropertyLeaseholdNumber && !$scope.model.PropertyUnderNumber) return '-';

			var str = $scope.model.PropertyUnderNumber || '';
			if (str) str += '/';
			str += $scope.model.PropertyLeaseholdNumber || '';
			if (str) str += '/';
			str += $scope.model.PropertyUnitNumber || '';
			str += '/';
			str += $scope.model.PropertyCadastralNumber || '';

			return str.split("/").reverse().join("/");
		};

		$scope.showAddressSearchModal = function () {
			$modal.open({
				templateUrl: 'app/address/views/addressSearchModal.html',
				controller: 'AddressSearchController',
				resolve: {
					modalParams: function () {
						return {

						};
					}
				},
				size: 'lg'
			}).result.then(function (selectedAddress) {
				$scope.model.PostalCode = selectedAddress.Address.PostalCode;
				$scope.model.PostalArea = selectedAddress.Address.PostalArea;
				$scope.model.Address = selectedAddress.Address.StreetAddress;
				$scope.model.PropertyCadastralNumber = selectedAddress.PropertyCadastralNumber;
				$scope.model.PropertyUnitNumber = selectedAddress.PropertyUnitNumber;
				$scope.model.PropertyLeaseholdNumber = selectedAddress.PropertyLeaseholdNumber;
				$scope.model.PropertyUnderNumber = selectedAddress.PropertyUnderNumber;
			});
		};
	}
})();
