(function () {
	angular.module('Plania').controller('RegisteredFieldEditModalController', ['$scope', '$modalInstance', 'params', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, translationService) {
		$scope.fieldRule = params.fieldRule;
		$scope.hasAccess = $scope.$root.userInfo.isSystemAdministrator;
		$scope.ok = function () {
			$modalInstance.close({ rule: $scope.fieldRule, reset: false });
		};

	    $scope.reset = function() {
	        $scope.fieldRule.OverriddenValidityRules = -1;
			$modalInstance.close({ rule: $scope.fieldRule, reset: true });
	    };

		$scope.close = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
