(function () {
    angular.module('Plania').directive('wysiwyg', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '='
            },
            link: function link(scope, element, attrs) {
                var isInitialized = false;

                scope.$watch('model', function (newValue, oldValue) {
                    if (newValue === oldValue || !isInitialized) return;

                    tinymce.activeEditor.setContent(newValue);
                });

                tinymce.init({
                    selector: '#wysiwyg',
                    height: 300,
                    theme: 'modern',
                    language: 'nb_NO',
                    branding: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                        'searchreplace visualblocks visualchars code codesample',
                        'insertdatetime media nonbreaking save table contextmenu directionality',
                        'emoticons template paste textcolor colorpicker textpattern imagetools toc help'
                    ],
                    toolbar1: 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright | bullist numlist outdent indent | preview',
                    image_advtab: true,
                    paste_data_images:true,
                    templates: [
                        { title: 'Test template 1', content: 'Test 1' },
                        { title: 'Test template 2', content: 'Test 2' }
                    ],
                    content_css: [
                        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                        '//www.tinymce.com/css/codepen.min.css'
                    ],
                    init_instance_callback: function (editor) {
                        isInitialized = true;
                        if(scope.model)
                            editor.setContent(scope.model);
                    }
                });

                scope.$on('$destroy', function () {
                    tinymce.activeEditor.destroy();
                });
            },
            templateUrl: '/app/common/directives/views/wysiwyg.html'
        };
    }
    ]);
})();
