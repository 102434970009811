(function () {
	angular.module('Plania').controller('ExitCleaningController', ['$scope', 'Repository', 'TranslationService', 'params', '$modalInstance', controller]);

	function controller($scope, repository, translationService, params, modalInstance) {
		var user = repository.authService.getUserData();

		var getCurrentIsoDate = function (input) {
			if (!input) return null;
			var date = new Date();
			var selectedDate = new Date(input);

			if (input === 'default')
				date.setHours(12, 0, 0);
			else
				date.setHours(selectedDate.getHours(), selectedDate.getMinutes());
			return date.toISOString();
		};

		repository.getSingle(repository.apiData.areaXCleaningTask.url, params.guidAreaXCleaningTask).then(function (response) {
			$scope.model = response.Data;

			if (!$scope.model.ExitCleaningDecisionDate && $scope.model.ExitCleaningRequestStatus === 'Possible')
				$scope.model.ExitCleaningDecisionDate = getCurrentIsoDate('default');

			if (!$scope.model.ExitCleaningConfirmedDate && $scope.model.ExitCleaningRequestStatus !== 'Possible')
				$scope.model.ExitCleaningConfirmedDate = getCurrentIsoDate('default');
		});

		$scope.save = function (close) {
			repository.updateSingle(repository.apiData.areaXCleaningTask.url, $scope.model).then(function (result) {
				repository.growl(translationService.translate('web-areaXCleaningTask-exitCleaning-saved', 'Utreisevasket har blitt oppdatert'), 'success');
				if (close)
					$scope.ok(result);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.cancelExitCleaning = function () {
			var cleaningCompletion = {
				GuidCleaningTask: $scope.model.GuidCleaningTask,
				GuidArea: $scope.model.GuidArea,
				GuidPerson: user.guidPerson,
				GuidResourceGroup: user.guidPersonResourceGroup,
				ExitCleaningDecisionDate: getCurrentIsoDate($scope.model.ExitCleaningDecisionDate),
				ExitCleaningConfirmedDate: getCurrentIsoDate($scope.model.ExitCleaningConfirmedDate),
				ExitCleaningRequestStatus: $scope.model.ExitCleaningRequestStatus,
				ExitCleaningCompletionStatus: 'Cancelled',
				MustCorrectDrawing: $scope.model.MustCorrectDrawing,
				MustCorrectDrawingComment: $scope.model.MustCorrectDrawingComment
			};

			repository.createMultiple(repository.apiData.cleaningCompletion.url, [cleaningCompletion]).then(function (result) {
				repository.growl(translationService.translate('web-areaXCleaningTask-exitCleaning-cancelled', 'Utreisevasket har blitt kansellert'), 'success');
				$scope.ok();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.setExitCleaningStatus = function (status) {
			if (!status || $scope.model.ExitCleaningRequestStatus === status) return;
			$scope.model.ExitCleaningRequestStatus = status;

			if (status !== 'Possible' && !$scope.model.ExitCleaningConfirmedDate)
				$scope.model.ExitCleaningConfirmedDate = getCurrentIsoDate('default');

			if (status === 'Discharged')
				$scope.model.ExitCleaningConfirmedDate = new Date().toISOString();

			$scope.save(true);
		};

		$scope.cancel = function (reason) {
			if (reason)
				modalInstance.dismiss(reason);
			else
				modalInstance.dismiss('cancel');
		};

		$scope.ok = function (result) {
			modalInstance.close(result);
		};

		$scope.isTimeInPast = function (fieldName) {
			if (!$scope.model) return false;
			var time = $scope.model[fieldName];
			if (!time) return false;

			var currentTime = new Date().getTime();
			var selectedTime = new Date(time).getTime();
			if (currentTime > selectedTime)
				return true;
			return false;
		};
	}
})();
