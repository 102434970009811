angular.module('Plania').factory('EquipmentThemeService', ['$modal', 'Repository', '$q', 'TranslationService', 'DwgMarkerService', '$filter', 'DwgThemeService', function ($modal, repository, $q, translationService, markerService, $filter, dwgThemeService) {
	var service = {};
	service.getLegends = function () {
		return null;
	};

	service.onClick = function (guid) {
		var deferred = $q.defer();
		var tabs = {
			area: { visible: repository.authService.hasReadAccess(repository.apiData.area.prefix), active: true }
		};

		$modal.open({
			templateUrl: 'app/dwg/views/dwgSelectionModal.html',
			size: 'lg',
			controller: 'DwgSelectionModalController',
			animation: false,
			resolve: {
				params: function () { return { guid: guid, tabInfo: tabs }; }
			}
		}).result.then(function (result) {
			deferred.resolve({});
		}, function (error) {
			if (error === 'cancel') deferred.resolve({});
			else deferred.reject();
		});
		return deferred.promise;
	};

	var drawLabelOnAreas = function (groupedAreas, equipmentCategoryStyle) {
		if (!groupedAreas) return false;

		var groupKeys = Object.keys(groupedAreas);

		groupKeys.forEach(function (key) {
			var elements = $('#' + key).children();
			if (elements[0]) {
				var path = elements[0];
				markerService.addEquipmentLabels(path, groupedAreas[key], equipmentCategoryStyle);
			}
		});

		$(".equipmentLabel").on('mouseenter',
			function (e) {
				//var jqObject = jQuery(this).children(getPathId(this.id));
				//jqObject.attr('fill-opacity', Number(jqObject.attr('fill-opacity')) + 0.1);
				//jqObject.attr('stroke-opacity', Number(jqObject.attr('stroke-opacity')) + 0.1);
			});
		$(".equipmentLabel").on('mouseleave',
			function (e) {
				//var jqObject = jQuery(this).children(getPathId(this.id));
				//jqObject.attr('fill-opacity', Number(jqObject.attr('fill-opacity')) - 0.1);
				//jqObject.attr('stroke-opacity', Number(jqObject.attr('stroke-opacity')) - 0.1);
			});
	};

	service.getStatus = function (areas, guidDrawing, selectedCategories, categoryColors) {
		dwgThemeService.removeAreaIndicators(areas);
		dwgThemeService.removeRequestMarkers(areas);
		dwgThemeService.removePeriodicMarkers(areas);
		dwgThemeService.removeDoorLockLabels(areas);
		var defer = $q.defer();

		if (!selectedCategories || selectedCategories.length === 0) {
			dwgThemeService.removeEquipmentCategoryLabels(areas);
			return $.Deferred().resolve().promise();
		}


		var equipmentFilter = {
			PropertyFilter: [
				{ Property: 'GuidEquipmentCategory', Operator: 'in', Value: selectedCategories.map(function (cat) { return cat.Guid; }).join(',') },
				{ Property: 'Area.GuidDrawing', Operator: '=', Value: guidDrawing }
			]
		};

		repository.GetPaginated(repository.apiData.equipment.url, 0, -1, { 'EquipmentCategory.Description': 'asc' }, equipmentFilter, null, JSON.stringify(['Id', 'Description', 'Area.Id'])).then(function (result) {
			dwgThemeService.removeEquipmentCategoryLabels(areas);
			drawLabelOnAreas($filter('groupBy')(result.List, 'GuidArea'), categoryColors);
			defer.resolve();
		}, function (error) {
			defer.reject();
		});
		return defer.promise;
	};

	service.selectEquipmentCategories = function (selectedEquipmentCategories) {
		var defer = $q.defer();

		$modal.open({
			templateUrl: 'app/common/views/multipleSelectModal.html',
			controller: 'MultipleSelectModalController',
			animation: false,
			resolve: {
				modalParams: function () {
					return {
						title: 'Velg anleggkategorier',
						columns: [
							{ Title: translationService.translate('web-equipmentCategory-Description'), Property: 'Description' },
						],
						useObjects: true,
						url: repository.apiData.equipmentCategory.url,
						sorting: { Description: 'asc' },
						filter: {},
						alreadySavedGuids: function () {
							var selectedGuids = [];
							if (selectedEquipmentCategories) {
								selectedEquipmentCategories.forEach(function (entity) {
									selectedGuids.push(entity.Guid);
								});
							}
							return selectedGuids;
						}()
					};
				}
			}
		}).result.then(function (selectedCategories) {
			var returnArray = [];

			selectedCategories.forEach(function (type) {
				if (!_.find(selectedEquipmentCategories, function (o) { return o.Guid === type.Guid; }))
					returnArray.push(type);
			});

			defer.resolve(returnArray);
		}, function (error) {
			defer.reject();
		});

		return defer.promise;
	};
	return service;
}]);
