(function () {
    var app = angular.module('Plania');

    app.directive('plLinkWidget', ['TranslationService', function (translationService) {
        return {
            restrict: 'A',
            require: '^plDashboardGrid',
            scope: {
                widget: '=widget',
                edit: '=edit',
                saveFunction: '=saveFunction'
            },
            link: function (scope, element, attrs, gridCtrl) {
                scope.$parent.attachWidget(element, attrs);

                scope.removeWidget = function () {
                    swal({
                        title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                        text: translationService.translate('web-swal-dashboard-link-message', "Linkene vil bli permanent fjernet!"),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f44336",
                        confirmButtonText: translationService.translate('web-swal-dashboard-link-confirm', 'Ja, fjern linkene'),
                        cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                        closeOnConfirm: false
                    }, function () {
                        window.onkeydown = null;
                        window.onfocus = null;
                        swal(translationService.translate('web-swal-dashboard-link-success', 'Linkene ble fjernet!'), '', "success");
                        scope.$parent.removeWidget(element, attrs);
                    });
                };
            },
            templateUrl: 'app/dashboard/directives/widgets/linkWidget.html',
            controller: ['$scope', '$modal', 'Repository', 'MenuService', 'TranslationService', safeController],
        };
	}]);

    function safeController($scope, modal, repository, menuService, translationService) {
		try {
            controller($scope, modal, repository, menuService, translationService);
		} catch (error) {
			console.log(error);
		}
	}

    function controller($scope, modal, repository, menuService, translationService) {
        $scope.model = $scope.widget;
        var webmenus = {};

        var loadWebmenus = function () {
            var guids = [];

            if ($scope.model.WidgetData && Array.isArray($scope.model.WidgetData.Links)) {
                $scope.model.WidgetData.Links.forEach(function (link) {
                    if (link.Type === "WebMenu" && link.GuidWebMenu) {
                        guids.push(link.GuidWebMenu);
                    }
                });
            }

            if (!guids.length) return;

            var filter = {
                FilterModel: {
                    Condition: "and",
                    Rules: [
                        {
                            Property: "Guid",
                            Operator: "in",
                            Value: guids
                        }
                    ]
                }
            };

            repository.GetPaginated(repository.apiData.webMenu.url, 0, -1, null, filter).then(function (result) {
                result.List.forEach(function (webMenu) {
                    // Store webmenus into storage for later retrieval in navigation.
                    webmenus[webMenu.Guid] = repository.parseAndStoreWebMenu(webMenu);
                });
            });
        };
        loadWebmenus();

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
                $scope.saveFunction(false).then(function () {
                    loadWebmenus();
                });
			});
        };

        // Get the stored webmenu from loadWebmenus
        var getInternalWebMenu = function (link) {
            if (link.Type === 'WebMenu' && link.GuidWebMenu && webmenus[link.GuidWebMenu])
                return webmenus[link.GuidWebMenu];
            return null;
        };

        $scope.getHref = function (link) {
            var webmenu = getInternalWebMenu(link);
            if (webmenu) {
                return menuService.getHref(webmenu);
            }
            return link.Url;
        };

        $scope.onClick = function (event, link) {
            var webmenu = getInternalWebMenu(link);
            if (webmenu) {
                return menuService.navigateFromLink(event, webmenu);
            }
        };

        $scope.isDisabled = function (link) {
            var webmenu = getInternalWebMenu(link);
            if (webmenu) {
                return menuService.isDisabled(webmenu);
            }
        };

        $scope.getDisabledMessage = function (link) {
            var webmenu = getInternalWebMenu(link);
            if (webmenu && menuService.isDisabled(webmenu) && webmenu.isBuildingDependent) {
                return translationService.translate('web-webDashboard-link-webmenu-disabledFromIsBuildingDependent', 'Velg bygg');
            }
            return null;
        };
	}

    app.directive('linkTarget', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var href = element.href;
                if (scope.link.IsExternalLink) {
                    element.attr("target", "_blank");
                }

                element.href = "";
            }
        };
    });
})();
