(function () {
    angular.module('Plania').controller('TelerikController', ['$scope', 'Repository', controller]);

    function controller($scope, repository) {
        var menuParams = {};
        if ($scope.navigation.params.menuGuid)
            menuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);

        $scope.reportName = menuParams.reportName;
    }
})();
