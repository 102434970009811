(function () {
	angular.module('Plania').controller('DocumentDataAcquisitionUploadController', ['$scope', '$rootScope', 'Repository', 'ngTableParams', 'TranslationService', 'DocumentService', 'RegisteredFieldService', '$q', controller]);

	function controller($scope, $rootScope, repository, ngTableParams, translationService, documentService, registeredFieldService,  $q) {

		$scope.model = {};
		$scope.buildingFilter = {};
		$scope.missingMandatoryProperties = [];
		$scope.uploadSummary = [];

		$scope.steps = [
			{ stepIndex: 1, name: 'documentData', hidden: false, title: translationService.translate('web-dataAcquisition-wizard-stepTitle-documentData', 'Dokumentdata'), onNextStepClicked: handleDocumentData },
			{ stepIndex: 2, name: 'fileUpload', hidden: false, title: translationService.translate('web-dataAcquisition-wizard-stepTitle-chooseFiles', 'Last opp filer') }
		];

		$scope.currentStep = $scope.steps[0];

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Document).then(function (result) {
			$scope.fieldRules = result;
		});

		var building = repository.commonService.getFilterData().selectedBuilding;
		if (building.Guid) {
			$scope.buildingFilter.Id = building.Name;
			$scope.buildingFilter.Guid = building.Guid;
		}

		$scope.hasError = function (field) {
			if ($scope.missingMandatoryProperties.includes(field) && !$scope.model[field])
				return true;
			else if ($scope.missingMandatoryProperties.includes(field) && $scope.model[field]) {
				_.remove($scope.missingMandatoryProperties, function (property) { return property === field; });
				return false;
			}

			return false;
		};

		var documentDataFields = ['Description', 'GuidDocumentType', 'GuidDocumentCategory', 'GuidSupplier'];

		function handleDocumentData(){
			var deferred = $q.defer();
			if (!$scope.buildingFilter)
			{
				repository.showError("Bygg mangler");
				deferred.reject("Bygg mangler");
			}
			if (!sectionIsValid(documentDataFields))
				deferred.reject("Obligatoriske felt mangler");
			deferred.resolve(true);
			return deferred.promise;
		}

		var sectionIsValid = function (fields) {
			$scope.missingMandatoryProperties = [];
			fields.forEach(function (field) {
				if (_.find($scope.fieldRules, { DomainFieldName: field, Mandatory: true }) && !$scope.model[field]) {
					$scope.missingMandatoryProperties.push(field);
				}
			});

			if ($scope.missingMandatoryProperties.length > 0) {
				return false;
			} else {
				return true;
			}
		};
		var setCurrentStep = function (step) {
			$scope.currentStep = step;
			if ($scope.completedStepIndex === undefined || $scope.completedStepIndex < $scope.currentStep.stepIndex)
				$scope.completedStepIndex = $scope.currentStep.stepIndex;
			if ($scope.currentStep.onStepAppearing !== undefined)
				$scope.currentStep.onStepAppearing();
		};
		var onChangeStep = function (step) {
			if ($scope.currentStep.onNextStepClicked === undefined) {
				setCurrentStep(step);
				return;
			}

			$scope.currentStep.onNextStepClicked().then(function (valid) {
				if (!valid) return;
				setCurrentStep(step);
			});
		};

		$scope.onStepClick = function (step) {
			if (step.stepIndex > $scope.completedStepIndex) return;
			setCurrentStep(step);
		};

		$scope.handlePreviousStep = function () {
			var step = _.find($scope.steps, function (step) { return step.stepIndex === $scope.currentStep.stepIndex - 1; });
			if (step)
				setCurrentStep(step);
		};

		$scope.handleNextStep = function () {
			var step = _.find($scope.steps, function (step) { return step.stepIndex === $scope.currentStep.stepIndex + 1; });
			if (step)
				onChangeStep(step);
		};

		// order matters!
		$scope.documentationContextColumns = {
			RegulatoryCompliance: translationService.translate('web-enum-documentationContext-regulatoryCompliance', 'RegulatoryCompliance'),
			Operation: translationService.translate('web-enum-documentationContext-operation', 'Operation'),
			Maintenance: translationService.translate('web-enum-documentationContext-maintenance', 'Maintenance'),
			Identification: translationService.translate('web-enum-documentationContext-identification', 'Identification'),
			ProductInfo: translationService.translate('web-enum-documentationContext-ProductInfo', 'ProductInfo'),
			Attestation: translationService.translate('web-enum-documentationContext-Attestation', 'Attestation'),
			Instruction: translationService.translate('web-enum-documentationContext-Instruction', 'Instruction'),
			Description: translationService.translate('web-enum-documentationContext-Description', 'Description'),
			Drawing: translationService.translate('web-enum-documentationContext-Drawing', 'Drawing'),
			Protocol: translationService.translate('web-enum-documentationContext-Protocol', 'Protocol'),
			Image: translationService.translate('web-enum-documentationContext-Image', 'Image')
		};

		$scope.files = [];

		var selectedDataOwner = repository.commonService.getFilterData().selectedDataOwner;
		if (selectedDataOwner.GuidDefaultDocumentType) {
			$scope.model.DocumentType = { Guid: selectedDataOwner.GuidDefaultDocumentType, Caption: selectedDataOwner.DefaultDocumentTypeId };
			$scope.model.GuidDocumentType = selectedDataOwner.GuidDefaultDocumentType;
		}

		$scope.onFileSelect = function ($files) {
			$scope.files = $scope.files.concat($files);
			_.forEach($scope.files, function(row){
				addDocumentationContexts(row);
				$scope.rowState[row.$$hashKey] = false;
			});
		};

		$scope.removeFile = function (index) {
			$scope.files.splice(index, 1);
		};

		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		function addDocumentationContexts(row) {
			row.documentationContexts = {};
			for (var key in $scope.documentationContextColumns) {
				row.documentationContexts[key] = {
					Key: key,
					Value: $scope.documentationContextColumns[key],
					Checked: false,
				};
			}
		}

		function resetColumnState() {
			$scope.columnState = angular.copy($scope.documentationContextColumns);
			$scope.rowState = {}; //not filled until api data is fetched
			for(var col in $scope.columnState){
				$scope.columnState[col] = false;
			}
		}

		resetColumnState();

		$scope.rowChecked = function(row){
			return $scope.rowState[row.$$hashKey];
		};

		$scope.toggleRow = function (row){
			$scope.rowState[row.$$hashKey] = !$scope.rowState[row.$$hashKey];
			var rows = _.filter($scope.files, function(item) {
				return item.$$hashKey === row.$$hashKey;
			});
			_.forEach(rows, function(r){
				_.forEach(r.documentationContexts, function (docContext){
					docContext.Checked = $scope.rowState[r.$$hashKey];
				});
			});
		};

		$scope.columnChecked = function(column){
			return $scope.columnState[column];
		};

		$scope.toggleColumn = function(column) {
			$scope.columnState[column] = !$scope.columnState[column];
			_.forEach($scope.files, function(row){
				if (row.progressType !== 'success')
					row.documentationContexts[column].Checked = $scope.columnState[column];
			});
		};

		var getFilesToUpload = function (allFiles) {
			var files = _.reject(allFiles, function (file) {
				// If retrying upload, remove the files already uploaded
				return file.progressType === 'success';
			});

			files.forEach(function (file) {
				// Generate unique id for files used for upload progress update
				file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
				file.count = 0;
				file.progressType = null;
			});
			return files;
		};

		var hasAllFilesBeenUploaded = function (allFiles) {
			// Remove all successfull uploads
			var files = _.reject(allFiles, function (file) {
				return !!file.documentCaption;
			});

			// 0 results means that all has been uploaded.
			return files.length === 0;
		};


		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "equipment":
					filter.PropertyFilter = [];
					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					if ($scope.buildingFilter)
						filter.PropertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: $scope.buildingFilter.Guid });
					break;
				case "documentCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
			return filter;
		};

		$scope.anyInvalidFiles = function (){
			if (!$scope.files) return true;
			if ($scope.files.length === 0) return true;
			return _.some($scope.files, function(file){
				return !file.GuidEquipment;
			});
		};

		$scope.uploadDocuments = function () {
			if ($scope.isUploading) return;
			if ($scope.anyInvalidFiles()) return;

			if ($scope.files.length < 1) {
				swal(translationService.translate('web-document-error-noFiles', 'Ingen filer valgt'), translationService.translate('web-document-error-nofilesdescription', 'Du må velge en eller flere filer for å lagre'), 'info');
				return;
			}

			var filesToUpload = getFilesToUpload($scope.files);

			$scope.isUploading = true;
			$scope.model.prefix = repository.apiData.document.prefix;

			_.forEach(filesToUpload, function(file){
				var model = angular.copy($scope.model);

				var docContext = _.pluck(_.filter(file.documentationContexts, function(docContext){
					return docContext.Checked === true;
				}), 'Key');

				model.GuidEquipment = file.GuidEquipment;
				model.DocumentationContext = documentService.getDocumentationContext(docContext);

				repository.createSingleWithBinary(repository.apiData.document.urlChunk, model, [file]).then(function (result) {
					$scope.isUploading = false;
					$scope.alreadyUploaded = true;

					_.forEach(result.savedFiles, function(savedFile){
						var identifiedFile = _.find($scope.files, function (f) { return f.id === savedFile.id; });
						if (identifiedFile) {
							identifiedFile.guidDocument = savedFile.guid;
							repository.getSingle(repository.apiData.document.url, savedFile.guid).then(
								function (result){
									identifiedFile.documentCaption = result.Data.Caption;

									var allFilesUploaded = hasAllFilesBeenUploaded($scope.files);
									if (allFilesUploaded)
									{
										$scope.restrictEdit = true;
										repository.growl(translationService.translate('web-dataAcquisition-uploadDocument-success', 'Dokumenter lagret'), 'success');
									}
								}, function (error){
									repository.showError(error);
								}
							);
						}
					});

				}, function (errors) {
					$scope.isUploading = false;

					if (Array.isArray(errors)) {
						errors.forEach(function (error) {
							var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
							if (identifiedFile) {
								identifiedFile.progressType = 'danger';
								identifiedFile.errorMessage = errors[0].error;
							}
						});
					} else {
						repository.growl(errors, 'danger');
					}
				}, function (file) { //UPDATE
					var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
					if (identifiedFile) {
						identifiedFile.total = file.total;
						identifiedFile.count = file.count;
						identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
					}
				});
			});

		};

		$scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
			$scope.fromState = fromState.name;
		});
	}
})();
