(function () {
	angular.module('Plania').directive('plWeatherForecastWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-table-message', "Tabellen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-table-confirm', 'Ja, fjern tabellen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-table-success', 'Tabellen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/weatherForecastWidget.html',
			controller: ['$scope', '$modal', safeController],
		};
	}]);

	function safeController($scope, modal) {
		try {
			controller($scope, modal);
		} catch (error) {
			console.log(error);
		}
	}

	function controller ($scope, modal) {
		$scope.model = $scope.widget;
		$scope.url = "";

		if (typeof ($scope.model.WidgetData) === "string") {
			$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
		}

		// Generic Widget functions
		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false).then(function () {
					setUrl();
				});
			}, function () {
				//Dismissed
			});
		};

		if (!$scope.model.WidgetData)
			return;

		var setUrl = function () {
			var url = "";

			if ($scope.model.WidgetData && $scope.model.WidgetData.locationId) {
				url = "https://www.yr.no/";
				switch ($scope.model.WidgetData.locale) {
					case "nn":
						url += "nn/innhald/";
						break;
					case "sme":
						url += "sme/sisdoallu/";
						break;
					case "en":
						url += "en/content/";
						break;
					default:
						url += "nb/innhold/";
				}
				url += $scope.model.WidgetData.locationId + "/";

				switch ($scope.model.WidgetData.type) {
					case "meteogram":
						url += "meteogram.svg";
						break;
					case "table":
						url += "table.html";
						break;
					default:
						url += "card.html";
						break;
				}
			}

			$scope.url = url;
		};
		setUrl();
	}
})();
