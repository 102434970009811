(function () {
	angular.module('Plania').controller('EquipmentElhubStatusModalController', ['$scope', '$modalInstance', 'params', 'Constants', controller]);

	function controller($scope, $modalInstance, params, constants) {
		var status = params.elhubStatus;

		var canStart = function(status) {
			return status === constants.elhubStatus.undefined ||
				status === constants.elhubStatus.noExport ||
				status === constants.elhubStatus.syncStopped ||
				status === constants.elhubStatus.syncDenied ||
				status === constants.elhubStatus.failed;
		};

		var canStop = function(status) {
			return status === constants.elhubStatus.startedInPlania ||
				status === constants.elhubStatus.syncApproved;
		};

		if (canStart(status)) {
			$scope.canStart = true;
		} else if (canStop(status)) {
			$scope.canStop = true;
		} else {
			$scope.pendingChange = true;
		}

		$scope.start = function() {
			$modalInstance.close(constants.elhubStatus.startedInPlania);
		};

		$scope.stop = function () {
			$modalInstance.close(constants.elhubStatus.stoppedInPlania);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('Canceled');
		};
	}
})();
