(function () {
	angular.module('Plania').controller('EntityTaskController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'EnumService', controller]);
	function controller($scope, repository, $stateParams, translationService, enumService) {
		$scope.model = { Guid: $stateParams.guid };

		$scope.apiRequestLogColumns = [
			{ Position: 1, Title: translationService.translate('web-apiRequestLog-creationDate', 'Opprettet dato'), Property: 'CreationDate', PropertyType: 'date' },
			{ Position: 2, Title: translationService.translate('web-apiRequestLog-user-realName', 'Opprettet av'), Property: 'UserCreatedBy.RealName', PropertyType: 'string' },
		];

		var columns = [
			"EntityLink.Id", "EntityLink.Description"
		];

		repository.getMainModel(repository.apiData.entityTask.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
			$scope.model = response.Data;
		},
		function (error) {
			repository.growl(error, 'danger');
		});

		var setStatus = function (status) {
			repository.runAction(repository.apiData.entityTask.endpoint.setStatus + $scope.model.Guid, { EntityTaskStatus: status }).then(function (response) {
				$scope.model.Status = status;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.getContextMenu = function () {
			var options = [];

			[
				{
					text: translationService.translate('web-entityTask-dropdownOption-setStatus-created', 'Sett status til opprettet'),
					value: "Created"
				},
				{
					text: translationService.translate('web-entityTask-dropdownOption-setStatus-inProgress', 'Sett status til under arbeid'),
					value: "InProgress"
				},
				{
					text: translationService.translate('web-entityTask-dropdownOption-setStatus-interactionRequired', 'Sett status til krever handling'),
					value: "InteractionRequired"
				},
				{
					text: translationService.translate('web-entityTask-dropdownOption-setStatus-error', 'Sett status til feil'),
					value: "Error"
				},
				{
					text: translationService.translate('web-entityTask-dropdownOption-setStatus-cancelled', 'Sett status til kansellert'),
					value: "Cancelled"
				},
				{
					text: translationService.translate('web-entityTask-dropdownOption-setStatus-delivered', 'Sett status til levert'),
					value: "Delivered"
				},
			].forEach(function (status) {
				options.push({
					html: '<a href="" class="dropdown-item" tabindex= "-1"><i style="visibility: hidden" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + status.text + '</a >',
					click: function ($itemScope, event) {
						setStatus(status.value);
					}
				});
			});

			return options;
		};
	}
})();
