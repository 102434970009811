(function () {
    angular.module('Plania').controller('CustomerDeliveryAddressController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', controller]);

    function controller($scope, $modalInstance, params, repository, translationService) {
        $scope.isUpdate = params.guid;
        $scope.header = $scope.isUpdate ? translationService.translate('deliveryAddress-title-edit', 'Rediger adresse') : translationService.translate('deliveryAddress-title-create', 'Legg til leveringsadresse');
        $scope.model = {};
        $scope.model.GuidCustomer = params.guidCustomer;
        $scope.customerName = params.customerName;

        if ($scope.isUpdate) {
            repository.getSingle(repository.apiData.customerDeliveryAddress.url, params.guid).then(function (response) {
                var result = response.Data;
                $scope.model = result;
                setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
            });
        }

        $scope.saveDeliveryAdress = function () {
            if ($scope.isUpdate) {
                repository.updateSingle(repository.apiData.customerDeliveryAddress.url, $scope.model).then(function (result) {
                    repository.growl('Leveringsadresse har blitt oppdatert', 'success');
                    $modalInstance.close();
                });
            } else {
                repository.createSingle(repository.apiData.customerDeliveryAddress.url, $scope.model).then(function (result) {
                    repository.growl('Leveringsadresse har blitt lagret', 'success');
                    $modalInstance.close();
                }).catch(function(error) {
                    repository.growl(error, 'danger');
                });
            }
        };

        $scope.onPostCodeSelect = function (postalInfo) {
            if (!postalInfo) {
                $scope.model.GuidPost = null;
                $scope.model.PostalCode = '';
                $scope.model.PostalArea = '';
                $scope.model.GuidLand = '';
            } else {
                $scope.model.GuidPost = postalInfo.Guid;
                $scope.model.PostalCode = postalInfo.ZipCode;
                $scope.model.PostalArea = postalInfo.PostalArea;
                $scope.model.Country = postalInfo.GuidLand;
                $scope.postalInfo = postalInfo;
            }
        };


        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };


    }
})();
