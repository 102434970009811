(function () {
	angular.module('Plania').directive('plPaletteSelect', [function () {
		return {
			restrict: 'E',
			require: 'ngModel',
			scope: {
				ngModel: '=',
				ngDisabled: '=',
				useHex: '='
			},
			controller: ['$scope', 'ColorService', controller],
			template: '<div class="skin-switch"><span ng-repeat="color in colors" class="ss-skin pl-palette-rainbow-{{color.name}}"ng-class="{\'selected\': color.name === selected ,\'m-l-0\': $index === 0}" ng-click="selectColor(color)"></span></div>'
		};
	}]);
	function controller($scope, colorService) {
		// Internal value is name
		$scope.selected = "";
		$scope.colors = colorService.getRainbowPalette();

		$scope.selectColor = function (color) {
			if (!color) {
				$scope.ngModel = "";
				return;
			}

			$scope.ngModel = ($scope.useHex ? color.hexColor : color.name).toLowerCase();
		};

		var updateSelectedValue = function () {
			if (!$scope.ngModel) {
				$scope.selected = "";
				return;
			}

			var found = $scope.colors.find(function (color) {
				// If using hex - check for hex first
				if ($scope.useHex && color.hexColor === $scope.ngModel.toLowerCase()) {
					return true;
				}

				// fallback (or when not using hex) to name.
				return color.name === $scope.ngModel.toLowerCase();
			});

			$scope.selected = found ? found.name : "";
		};

		updateSelectedValue();

		$scope.$watch("ngModel", function () {
			updateSelectedValue();
		});
	}
})();
