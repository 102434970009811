(function () {
	angular.module('Plania').constant('Constants', {

		inspectedEntityLocation: {
			anywhere: 0,
			estate: 1,
			building: 2
		},

		requestStatus: {
			new: 'New'
		},

		priorityLevel: {
			Low: 'Low',
			Normal: 'Normal',
			High: 'High',
			Critical: 'Critical'
		},

		elhubStatus: {
			undefined: 'Undefined',
			noExport: 'NoExport',
			startedInPlania: 'StartedInPlania',
			syncPendingStartConfirmation: 'SyncPendingStartConfirmation',
			syncApproved: 'SyncApproved',
			syncDenied: 'SyncDenied',
			stoppedInPlania: 'StoppedInPlania',
			syncPendingStopConfirmation: 'SyncPendingStopConfirmation',
			syncStopped: 'SyncStopped',
			failed: 'Failed'
		},

		dateOptions: {
			"starting-day": 1
		},

		availableMaps: [
			// Note ordering of items. First item is used as if settings is null in general_options default
			{
				title: 'OpenStreetMap',
				mapData: {
					TileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
					TileLayerOptions: JSON.stringify({ "maxZoom": '19', "attribution": '&copy; OpenStreetMap' }),
					CrsCode: '',
					Proj4Def: '',
					Options: {
						Origin: JSON.stringify(''),
						Resolution: JSON.stringify('')
					}
				}
			},
			{
				title: 'NVDB',
				mapData: {
					TileLayer: 'http://m{s}.nvdbcache.geodataonline.no/arcgis/rest/services/Trafikkportalen/GeocacheTrafikkJPG/MapServer/tile/{z}/{y}/{x}',
					TileLayerOptions: JSON.stringify({ "maxZoom": '16', "minZoom": '3', "subdomains": '123456789', "continuousWorld": 'true', "attribution": 'NVDB' }),
					CrsCode: 'EPSG:25833',
					Proj4Def: '+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs ',
					Options: {
						Origin: JSON.stringify([-2500000.0, 9045984.0]),
						Resolution: JSON.stringify([21674.7100160867, 10837.35500804335, 5418.677504021675, 2709.3387520108377, 1354.6693760054188, 677.3346880027094, 338.6673440013547, 169.33367200067735, 84.66683600033868, 42.33341800016934, 21.16670900008467, 10.583354500042335, 5.291677250021167, 2.6458386250105836, 1.3229193125052918, 0.6614596562526459, 0.33072982812632296])
					}
				}
			}
		],

		dynamicProperties: {
			projectCategory: { text: 40, date: 30, numeric: 40, combo: 30, max: 20 }
		},

		weekdaysToNumber: {
			'Monday': 0,
			'Tuesday': 1,
			'Wednesday': 2,
			'Thursday': 3,
			'Friday': 4,
			'Saturday': 5,
			'Sunday': 6
		},

		numberToWeekdays: {
			0: 'Monday',
			1: 'Tuesday',
			2: 'Wednesday',
			3: 'Thursday',
			4: 'Friday',
			5: 'Saturday',
			6: 'Sunday'
		},

		months: [
			{ name: 'Januar', id: 1 },
			{ name: 'Februar', id: 2 },
			{ name: 'Mars', id: 3 },
			{ name: 'April', id: 4 },
			{ name: 'Mai', id: 5 },
			{ name: 'Juni', id: 6 },
			{ name: 'Juli', id: 7 },
			{ name: 'August', id: 8 },
			{ name: 'September', id: 9 },
			{ name: 'Oktober', id: 10 },
			{ name: 'November', id: 11 },
			{ name: 'Desember', id: 12 },
		],

		defaultSmsConfiguration: {
			ServicePlanId: '',
			AccessToken: '',
			Url: 'https://eu.sms.api.sinch.com/xms/v1',
			SenderId: 'Plania',
			TemplateText: 'Din kode for innlogging i Plania er: '
		},

		defaultAnonymizationConfiguration: {
			AnonymizationIntervalInMonths: 3,
			DeletionIntervalInMonths: 3,
			// just examples for now, depending on how this configuration is set
			// from file? from database?
			// prepopulate from all available fields?
			// hard code all of them?
			// so many options...
			FieldRules: [
				{
					FieldName: '[CUSTOMER.DESCRIPTION]',
					Action: 'Remove'
				},
				{
					FieldName: '[CUSTOMER.POSTAL_ADDRESS]',
					Action: 'Remove'
				}
			]
		},

		defaultAnonymizationConfigurationIntervals: [
			{ label: '1 måned', months: 1 },
			{ label: '3 måneder', months: 3 },
			{ label: '6 måneder', months: 6 },
			{ label: '1 år', months: 12 },
			{ label: '3 år', months: 36 },
			{ label: '5 år', months: 60 },
			{ label: '10 år', months: 120 }
		],

		defaultLikertScaleColors: {
			0: '#9E9E9E',
			1: '#2E7D32',
			2: '#4caf50',
			3: '#C8E6C9',
			4: '#FF8F00',
			5: '#FFC400',
			6: '#FFECB3',
			7: '#D84315',
			8: '#FF3D00',
			9: '#FFCCBC',
		},

		// #24601 - Hardcoded Guids used for KA
		ka: {
			guidLocalAdminUserGroup: "03f60c1f-99cc-439b-83b1-c6e8739a81a8",
			guidFdvUserGroup: "03f2fbb7-ed49-4489-9f71-cecbc5807e22",
			guidInventoryUserGroup: "03ff9d83-9508-42d4-a988-1bb16e2dc843",
			guidStandardEntityPermissionProfile: "040fdf48-9f74-5d4c-4911-ee998965e191",
			guidExternalOperatorEntityPermissionProfile: "0408553a-b38f-65e2-dbf5-7ef48a4abe89"
		}
	});
})();
