(function () {
    angular.module('Plania').controller('CacheInfoController', ['$scope', 'Repository', '$interval', 'TranslationService', controller]);

    function controller($scope, repository, $interval, translationService) {

        $scope.refreshCache = function(cacheInfo) {
            repository.deleteSingle(repository.apiData.cacheInfo.url, cacheInfo.Type).then(function (result) {
                cacheInfo.LastUpdatedTime = null;
                cacheInfo.CachedItemsCount = null;
                repository.growl(translationService.translate('web-cacheInfo-refreshCache-success', 'Cache invalidert'), 'success');
            }, function(error) {
                repository.growl(error, 'danger');
            });
        };

		var init = function () {
			repository.GetPaginated(repository.apiData.cacheInfo.url).then(function (result) {
	            $scope.cachedItems = result;
	        }, function(error) {
	            repository.growl(error, 'danger');
	        });
        };

	    init();

	    var reloadDataInterval = $interval(function () {
	        init();
	    }, 10000);

	    $scope.$on('$destroy', function () {
	        if (angular.isDefined(reloadDataInterval)) {
	            $interval.cancel(reloadDataInterval);
	        }
		});
	}
})();
