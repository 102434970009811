(function () {
	angular.module('Plania').controller('MultipleCleaningOnHoldController', ['$scope', 'Repository', 'params', '$modalInstance', 'config', 'TranslationService', '$filter', 'ListService', controller]);

	function controller($scope, repository, params, modalInstance, config, translationService, $filter, listService) {

		$scope.cleaningTasks = [];
		$scope.selectedTasks = [];
		$scope.cleaningTasksAreaGrouped = [];
		$scope.cleaningTasksCleaningTypeGrouped = [];
		$scope.cleaningTaskGroupBy = 'none';
		$scope.isAllTasksSelected = false;

		var wasUpdated = false;

		var filter = {
			PropertyFilter: [
				{ Property: 'GuidArea', Operator: 'in', Value: params.guids.join(',') },
				{ Property: 'CleaningTask.CleaningType', Operator: '<>', Value: '1' },
				{ Property: 'CleaningTask.CleaningType', Operator: '<>', Value: '3' },
			]
		};

		var columns = [
			'CleaningTask.Id', 'CleaningTask.CleaningType', 'CleaningTask.ExplanatoryText',
			'Area.Id', 'Area.Description',
			'PausedFromDate', 'PausedToDate', 'PausedReason'
		];

		var sortByCleaningType = function (left, right) {
			if (left.CleaningTask.CleaningType === 'StandardCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'StandardCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'InfectionCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'InfectionCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'ExitCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'ExitCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'AdditionalCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'AdditionalCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'PeriodicCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'PeriodicCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'Undefined') return -1;
			else if (right.CleaningTask.CleaningType === 'Undefined') return 1;
			else if (left.CleaningTask.CleaningType === right.CleaningTask.CleaningType) return 0;
		};

		var checkSelectedTasks = function (skipGroup) {
			if ($scope.selectedTasks.length === $scope.cleaningTasks)
				$scope.isAllTasksSelected = true;
			else
				$scope.isAllTasksSelected = false;
			if (!skipGroup) {
				$scope.cleaningTasksAreaGrouped.forEach(function (task) {
					var guids = task.items.map(function (item) { return item.Guid; });
					var selected = $scope.selectedTasks.filter(function (guidTask) {
						return guids.indexOf(guidTask) >= 0;
					});

					task.allTasksSelected = selected.length === guids.length;
				});

				$scope.cleaningTasksCleaningTypeGrouped.forEach(function (task) {
					var guids = task.items.map(function (item) { return item.Guid; });
					var selected = $scope.selectedTasks.filter(function (guidTask) {
						return guids.indexOf(guidTask) >= 0;
					});

					task.allTasksSelected = selected.length === guids.length;
				});
			}
		};

		function getAreaXCleaningTasks() {
			repository.GetPaginated(repository.apiData.areaXCleaningTask.url, 0, -1, {}, filter, null, JSON.stringify(columns)).then(function (result) {
				$scope.cleaningTasksAreaGrouped = [];
				$scope.cleaningTasksCleaningTypeGrouped = [];

				$scope.cleaningTasks = result.List;

				$scope.cleaningTasks.sort(sortByCleaningType);

				$scope.cleaningTasks.forEach(function (task) {
					var areaGroup = _.find($scope.cleaningTasksAreaGrouped, function (grouped) { return grouped.header === task.Area.Caption; });
					var cleaningTypeGroup = _.find($scope.cleaningTasksCleaningTypeGrouped, function (grouped) { return grouped.header === task.CleaningTask.CleaningType; });
					if (!areaGroup)
						$scope.cleaningTasksAreaGrouped.push({ header: task.Area.Caption, items: [task] });
					else
						areaGroup.items.push(task);

					if (!cleaningTypeGroup)
						$scope.cleaningTasksCleaningTypeGrouped.push({ header: task.CleaningTask.CleaningType, items: [task] });
					else
						cleaningTypeGroup.items.push(task);

					if (task.CleaningTask.CleaningType === 'StandardCleaning')
						$scope.selectedTasks.push(task.Guid);
				});

				$scope.cleaningTasksAreaGrouped.sort(function (a, b) { return ('' + a.header).localeCompare(b.header); });
				checkSelectedTasks();
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}).catch(function (error) {
				repository.growl(error, 'danger');
			});
		}

		getAreaXCleaningTasks();

		$scope.selectTask = function (task) {
			if (!_.contains($scope.selectedTasks, task.Guid)) {
				$scope.selectedTasks.push(task.Guid);
				checkSelectedTasks();
			}
			else {
				for (var i = 0; i < $scope.selectedTasks.length; i++) {
					var p = $scope.selectedTasks[i];
					if (p === task.Guid) {
						$scope.selectedTasks.splice(i, 1);
						checkSelectedTasks();
						return;
					}
				}
			}
		};

		$scope.selectMultipleTasks = function () {
			$scope.selectedTasks = [];
			if (!$scope.isAllTasksSelected)
				$scope.cleaningTasks.forEach(function (task) { $scope.selectedTasks.push(task.Guid); });

			$scope.isAllTasksSelected = !$scope.isAllTasksSelected;
			$scope.cleaningTasksAreaGrouped.forEach(function (group) { group.allTasksSelected = $scope.isAllTasksSelected; });
			$scope.cleaningTasksCleaningTypeGrouped.forEach(function (group) { group.allTasksSelected = $scope.isAllTasksSelected; });
		};

		$scope.selectMultipleGroupedTasks = function (group) {
			if (!group || $scope.cleaningTaskGroupBy === 'none') return;
			var list = $scope.cleaningTaskGroupBy === 'area' ? $scope.cleaningTasksAreaGrouped : $scope.cleaningTasksCleaningTypeGrouped;
			var guids = _.find(list, function (l) { return l.header === group.header; }).items.map(function (item) { return item.Guid; });

			if (group.allTasksSelected) {
				$scope.selectedTasks = $scope.selectedTasks.filter(function (guidTask) {
					return guids.indexOf(guidTask) < 0;
				});
			}
			else {
				guids.forEach(function (guid) {
					if ($scope.selectedTasks.indexOf(guid) < 0)
						$scope.selectedTasks.push(guid);
				});
			}
			group.allTasksSelected = !group.allTasksSelected;

			checkSelectedTasks(true);
		};

		$scope.isSelected = function (guid) {
			return _.contains($scope.selectedTasks, guid);
		};

		$scope.canSave = function() {
			return $scope.selectedTasks.length > 0 && $scope.pausedFromDate && $scope.pausedToDate;
		};

		$scope.canRemovePause = function () {
			var anyPaused = _.some($scope.selectedTasks, function(guid) {
				var task = _.find($scope.cleaningTasks, function (ct) { return ct.Guid === guid; });
				return task.PausedFromDate || task.PausedToDate;
			});
			return $scope.selectedTasks.length > 0 && anyPaused;
		};

		$scope.removePausedDates = function () {
			var changeList = [];

			_.forEach($scope.selectedTasks, function (guid) {
				var change = {
					Guid: guid,
					PausedFromDate: null,
					PausedToDate: null,
					PausedReason: null
				};
				changeList.push(change);
			});

			repository.patch(repository.apiData.areaXCleaningTask.url, null, changeList).then(function (response) {
				var success = _.every(response, function (response) {
					return response.IsSuccess;
				});

				if (success)
					repository.growl(translationService.translate('web-cleaningTask-multipleOnHold-updateSuccess', 'Oppgaver oppdatert'), 'success');
				else
					repository.growl(translationService.translate('web-cleaningTask-multipleOnHold-updateFailed', 'En eller flere oppgaver ble ikke oppdatert'), 'danger');
				getAreaXCleaningTasks();
				wasUpdated = true;
			}, function (error) {
				repository.growl(error, "danger");
			});
		};

		$scope.save = function () {
			var changeList = [];
			var pausedFromDate = new Date($scope.pausedFromDate.setHours(0, 0, 0, 0));
			var pausedToDate = new Date($scope.pausedToDate.setHours(0, 0, 0, 0));

			_.forEach($scope.selectedTasks, function (guid) {
				var change = {
					Guid: guid,
					PausedFromDate: pausedFromDate,
					PausedToDate: pausedToDate,
					PausedReason: $scope.pausedReason
				};

				changeList.push(change);
			});

			repository.patch(repository.apiData.areaXCleaningTask.url, null, changeList).then(function (response) {
				var success = _.every(response, function (response) {
					return response.IsSuccess;
				});

				if (success)
					repository.growl(translationService.translate('web-cleaningTask-multipleOnHold-updateSuccess', 'Oppgaver oppdatert'), 'success');
				else
					repository.growl(translationService.translate('web-cleaningTask-multipleOnHold-updateFailed', 'En eller flere oppgaver ble ikke oppdatert'), 'danger');

				getAreaXCleaningTasks();
				wasUpdated = true;
			}, function(error) {
				repository.growl(error, "danger");
			});
		};

		$scope.close = function () {
			if (wasUpdated) {
				modalInstance.close();
			} else {
				modalInstance.dismiss('cancel');
			}
		};
	}
})();
