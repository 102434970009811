(function () {
	var app = angular.module('Plania');
	app.directive('plEntityLinks', function () {
		return {
			restrict: 'E',
			scope: {
				guid: '=guid', // guid of the entity
				entity: '=entity', // Prefix
				displayLimit: '=displayLimit', // Number - Limits the amount of visible links. Will show a "Show more" button if totalCount is over this.
			},
			controller: ['$scope', 'Repository', controller],
			templateUrl: 'app/entityLink/views/plEntityLinksDirective.html'
		};
	});

	function controller(scope, repository) {
		scope.collapse = { isEntitiesCollapsed: false };

		repository.getWithUrlParameter(repository.apiData.entityLink.url + "linksForEntity", 'guidEntity=' + scope.guid + '&prefix=' + scope.entity).then(function (result) {
			scope.totalCount = result.length;
            scope.entityLinks = result;
		}, function (error) {
			repository.showError(error);
		});
	}
})();
