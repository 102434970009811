(function () {

	angular.module('Plania').controller('UserInfoController', ['$scope', controller]);

	function controller($scope) {
		$scope.tooltipText = $scope.userInfo.realName + ' ' + $scope.userInfo.username;
		$scope.toggled = function (open) {
			if (!open) {
				$scope.tooltipText = $scope.userInfo.realName + ' ' + $scope.userInfo.username;
				$scope.isDropdownOpen = false;
			}
			else {
				$scope.tooltipText = '';
			}
		};
	}
})();
