(function () {
    angular.module('Plania').factory('imageService', ['$http', '$q', imageService]);

    function imageService($http, $q) {
        var service = {};

        service.isImage = function (src) {
            var deferred = $q.defer();

            var image = new Image();

            image.onerror = function () {
                deferred.resolve(false);
            };

            image.onload = function () {
                deferred.resolve(true);
            };

            image.src = src;

            return deferred.promise;
        };

        return service;
    }
})
();
