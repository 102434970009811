(function () {

    angular.module('Plania').controller('MessageModalController', ['$scope', '$modalInstance', 'content', controller]);

    function controller($scope, $modalInstance, content) {

        $scope.header = content.header;
        $scope.message = content.message;
        $scope.timestamp = content.timestamp;

        $scope.close = function () {
            $modalInstance.dismiss();
        };
    }
})();
