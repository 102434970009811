(function () {

    angular.module('Plania').controller('ImageUploadModalController', ['$scope', '$modalInstance', 'params', 'Upload', controller]);

    function controller($scope, $modalInstance, params, Upload) {
        $scope.selectedImageFile = params.image;
        $scope.targetSize = params.targetSize;
		$scope.areaType = params.areaType;
        $scope.uploadImage = function () {
            $modalInstance.close(Upload.dataUrltoBlob($scope.croppedDataUrl, $scope.selectedImageFile.name));
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
