angular.module('Plania').directive('plCurrencyInput', ['$window', '$filter', function ($window, $filter) {
	return {
        restrict: 'A',
		require:'ngModel',
        link: function link(scope, element, attrs, ctrl) {
            var rawElement = element[0];

            element.on('focus', updateView.bind(null, true));
            element.on('blur', updateView.bind(null, false));

            function updateView(hasFocus) {
                if (!ctrl.$modelValue) return;

                var displayValue = hasFocus ?
                    ctrl.$modelValue :
                    $filter('number')(ctrl.$modelValue, 2);
                rawElement.value = displayValue;
            }

            scope.$watch('viewMode', function () {
                updateView(false);
            });

            // Hacky way to format the input when the page, or tab content loads.
            var loadListener = scope.$watch(attrs.ngModel, function (newValue) {
				if (newValue !== undefined && !Number.isNaN(newValue)) {
					try {
						updateView($(element).is(":focus"));
					} catch (ex) {}
                    // Unregister self
                    loadListener();
                }
            });
		}
	};
}]);
