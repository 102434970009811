(function () {
    var app = angular.module('Plania');

    app.directive('workOrderEconomyInfo', function () {
        return {
            restrict: 'E',
            scope: {
				workOrder: '=workOrder',
				reload: '=reload'
            },
            templateUrl: 'app/workOrder/directives/workOrderEconomyInfo.html',
			controller: ['$scope', '$localStorage', 'RegisteredFieldService', 'Repository','TranslationService',
			function ($scope, $localStorage, registeredFieldService, repository, translationService) {
				function isLoadedFromServer(workOrder) {
					return !!workOrder.GuidDataOwner;
				}

				$scope.$watch('reload', function(newValue, oldValue) {
					if (newValue === oldValue) return;
					if (!isLoadedFromServer($scope.workOrder)) {
						$scope.reload = false;
						return;
					}
					if ($scope.reload && $scope.reload === true) {
						init();
						$scope.reload = false;
					}
				});

				var registeredFields = null;

				function init() {
					registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrder).then(function(result) {
						registeredFields = result;
						initEconomyTable();
					});
				}
				init();

				function isVisible(domainFieldName) {
					if (!registeredFields) return false;

					var fieldRule = _.find(registeredFields, { DomainFieldName: domainFieldName });
					if (!fieldRule) return false;

					if (fieldRule.Hidden) return false;
					if ($localStorage.userData.isExternalUser) {
						return !fieldRule.ExternallyHidden;
					}

					return true;
				}

				/*	newRow method object properties
				 *	defaultTranslation: string - default translation text
				 *	translationKey: string - key for translations
				 *	propEstimated?: property from workOrder to show in Estimated column
				 *	propReal?: property from workOrder to show in Real column
				 *	subRows?: rows[] - list of rows to be shown below the current.
				 *	showAllDecimals?: boolean - toggle between decimal and integer.
				 *	infoLabel?: boolean - show extra info
				 *	highlight?: boolean - set both columns to be bold.
				 *	estimateColor?: string - color class for estimate column
				 *	realColor?: string - color class for real column
				 */

				function newRow(newRowSettings) {
					var estimateVisible = isVisible(newRowSettings.propEstimated);
					var realVisible = isVisible(newRowSettings.propReal);

					var row = {
						estimate: estimateVisible ? $scope.workOrder[newRowSettings.propEstimated] : null,
						real: realVisible ? $scope.workOrder[newRowSettings.propReal] : null,
						infoLabel: newRowSettings.infoLabel,
						systemName: 'web-workorder-economyinfo-' + newRowSettings.translationKey,
						defaultTranslation: newRowSettings.defaultTranslation,
						highlight: newRowSettings.highlight,
						estimateColor: newRowSettings.estimateColor,
						realColor: newRowSettings.realColor
					};

					if (!row.estimate && !row.real) return null;

					if (newRowSettings.subRows) {
						row.subRows = _.compact(newRowSettings.subRows);
					}

					if (!newRowSettings.showAllDecimals)
						row.decimals = 0;

					if (_.isNumber(row.real) && _.isNumber(row.estimate)) {
						row.estimateExceeded = row.real > row.estimate;
					}
					return row;
				}

				function push(array, element) {
					if (!element) return;
					array.push(element);
				}

				function initEconomyTable() {
					var rows = [];
					push(rows, newRow({
						defaultTranslation: 'Tiltak',
						translationKey: 'correctiveAction',
						propEstimated: 'SumCorrectiveActionEstimatedAmount',
						infoLabel: true
					}));

					push(rows, newRow({
						defaultTranslation: 'Timer',
						translationKey: 'time',
						propEstimated: 'EstimatedTime',
						propReal: 'RealTime',
						showAllDecimals: true
					}));

					push(rows, newRow({
						defaultTranslation: 'Interne kostnader',
						translationKey: 'sumInternalCost',
						propEstimated: 'SumInternalCostEstimatedAmount',
						propReal: 'SumInternalCostRegisteredAmount',
						subRows: [
							newRow({
								defaultTranslation: 'Timekostnader',
								translationKey: 'sumResource',
								propEstimated: 'SumResourceEstimatedAmount',
								propReal: 'SumResourceRegisteredAmount',
							}),
							newRow({
								defaultTranslation: 'Delkostnader',
								translationKey: 'sumSparePart',
								propEstimated: 'SumSparePartEstimatedAmount',
								propReal: 'SumSparePartRegisteredAmount',
							}),
							newRow({
								defaultTranslation: 'Anlegg',
								translationKey: 'sumEquipment',
								propEstimated: 'SumEquipmentEstimatedAmount',
								propReal: 'SumEquipmentRegisteredAmount',
							})
						]
					}));

					push(rows, newRow({
						defaultTranslation: 'Eksterne kostnader',
						translationKey: 'sumExternalCost',
						propEstimated: 'SumExternalCostEstimatedAmount',
						propReal: 'SumExternalCostInvoiceAmount',
						subRows: [
							newRow({
								defaultTranslation: 'Bestilt',
								translationKey: 'sumExternalCostOrdered',
								propReal: 'SumExternalCostOrderedAmount',
							}),
							newRow({
								defaultTranslation: 'Disponert',
								translationKey: 'restExternalCost',
								propReal: 'RestExternalCostAllocatedAmount',
							}),
							newRow({
								defaultTranslation: 'Anlegg',
								translationKey: 'sumEquipment',
								propEstimated: 'SumEquipmentEstimatedAmount',
								propReal: 'SumEquipmentRegisteredAmount',
							})
						]
					}));

					push(rows, newRow({
						defaultTranslation: 'Total Kostnader',
						translationKey: 'total',
						propEstimated: 'EstimatedCost',
						propReal: 'RealCost',
						highlight: true
					}));

					push(rows, newRow({
						defaultTranslation: 'Total viderefaktureringssum',
						translationKey: 'sumPaymentOrderSum',
						propReal: 'SumPaymentOrderSum',
						highlight: true,
						realColor: $scope.workOrder.SumPaymentOrderSum < $scope.workOrder.RealCost ? 'c-red' : null
					}));
					$scope.vm = {
						rows: rows,
						showContainer: _.some(rows, function (r) { return r && (r.real > 0 || r.estimate > 0); })
					};
				}
            }]
        };
    });
})();
