(function () {
	angular.module('Plania').controller('CreateUserDataOwnerController', ['$scope', 'Repository', 'params', '$modalInstance', 'Constants', '$rootScope', 'CommonService', controller]);

	function controller($scope, repository, params, $modalInstance, constants, $rootScope, commonService) {
		$scope.model = {};
		$scope.model.GuidUser = params.guidUser;
		$scope.model.IsDefaultDataOwner = params.isFirst ? true : false;

		var isKaLocalAdmin = $scope.customization.isCustomer("KA") && $rootScope.userInfo.guidUserGroup === constants.ka.guidLocalAdminUserGroup;

		if (isKaLocalAdmin) {
			$scope.model.GuidLinkedDataOwner = commonService.getFilterData().selectedDataOwner.Guid;

			$scope.model.LinkedDataOwner = {
				Guid: commonService.getFilterData().selectedDataOwner.Guid,
				Description: commonService.getFilterData().selectedDataOwner.Description,
			};
			$scope.dataOwnerSelectionLocked = true;
		}
		
		$scope.save = function () {
			$modalInstance.close();
		};

		$scope.saveAction = function () {
			var sendModel = angular.copy($scope.model);
			delete sendModel.User;

			repository.createSingle(repository.apiData.userProfile.url, $scope.model).then(function (result) {
				repository.growl('Brukerprofil oppdatert', 'success');
				$modalInstance.close($scope.model);
			}).catch(function (error) {
				var message = '';
				if (error.indexOf('invalid_license_defined_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}
				if (error.indexOf('invalid_license_simple_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}

				if (message !== '') {
					swal({
						title: "Bekreft utvidelse av lisens",
						text: message,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "Ja, utvid lisens",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.ConfirmIncreaseInLicense = true;
							$scope.saveAction();
						} else {
							repository.growl('Endringene ble avbrutt!', 'success');
							$modalInstance.close('ok');
						}
					});
				} else {
					swal({title: "Feil ved lagring",text: error,type: "warning",closeOnConfirm: true, closeOnCancel: true});
				}
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "dataOwner":
					return { ExcludeUserDataOwners: $scope.model.GuidUser };
				case "entityPermissionProfile":
					if (!isKaLocalAdmin)
						return {};
					return {
						FilterModel: {
							Condition: "and",
							Rules: [{ Property: "Guid", Operator: "in", Value: [constants.ka.guidStandardEntityPermissionProfile, constants.ka.guidExternalOperatorEntityPermissionProfile] }]
						}
					};
			}
		};
	}
})();
