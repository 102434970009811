(function () {

	angular.module('Plania').controller('ConfirmationBoxController', ['$scope', '$modalInstance', 'confirmation', controller]);

	function controller($scope, $modalInstance, confirmation) {

		$scope.header = confirmation.header;
		$scope.message = confirmation.message;

	    $scope.ok = function() {
	        $modalInstance.close();
	    };
	    $scope.cancel = function() {
	        $modalInstance.dismiss();
	    };
	}
})();
