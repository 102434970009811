(function () {
	angular.module('Plania').controller('ProjectMilestoneModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', 'TranslationService', '$modal', 'ngTableParams', 'ListService', controller]);
	function controller($scope, repository, modalParams, modalInstance, translationService, $modal, ngTableParams, listService) {

        $scope.model = {};
        $scope.isUpdate = false;
        $scope.isTemplate = modalParams.IsTemplate;

        if (modalParams.Milestone) {
			$scope.model = angular.copy(modalParams.Milestone);
            $scope.isUpdate = true;
        } else {
            $scope.model.GuidProject = modalParams.GuidProject;
        }

        $scope.saveMilestone = function () {
			if ($scope.isUpdate) {
				var changeSet = {
					Id: $scope.model.Id,
					Description: $scope.model.Description,
					DueDate: $scope.model.DueDate,
					EndDate: $scope.model.EndDate,
				};

				repository.patch(repository.apiData.projectMilestone.url, $scope.model.Guid, changeSet).then(function (result) {
                    modalInstance.close();
                }).catch(function (error) {
                    swal('Feil ved lagring', error, 'error');
                });
            }
            else {
                repository.createSingle(repository.apiData.projectMilestone.url, $scope.model).then(function (result) {
                    modalInstance.close();
                }).catch(function (error) {
                    swal('Feil ved lagring', error, 'error');
                });
            }
        };
    }

})();
