(function () {
	angular.module('Plania').controller('MapController', ['$scope', 'Repository', 'TranslationService', '$timeout', '$localStorage', 'Constants', '$compile', '$location', 'IconService', controller]);

	function controller($scope, repository, translationService, $timeout, $localStorage, constants, $compile, $location, iconService) {
		var urlParams = $location.search();
		var coordinatesInUrlParams = !!urlParams.lat;
		var shouldCenterOnMarkers = !coordinatesInUrlParams;

		$scope.iconMap = iconService.getIconsForMapUsage();
		$scope.showFilter = true;
		$scope.panelFilter = { showBasicData: true };
		$scope.selectedTheme = urlParams.theme ? urlParams.theme : "basicData";

		$scope.dropdownFilters = {
			estateCategory: {
				list: [],
			},
			buildingCategory: {
				list: [],
			},
			equipmentCategory: {
				list: [],
			},
			unoXCoLocation: {
				list: []
			}
		};

		$scope.themeSelections = [
			{
				label: translationService.translate('web-map-toolbar-selectTheme-Basicdata', 'Grunndata'),
				value: 'basicData'
			},
			{
				label: translationService.translate('web-map-toolbar-selectTheme-request', 'Meldinger'),
				value: 'request'
			},
			{
				label: translationService.translate('web-map-toolbar-selectTheme-workOrder', 'Arbeidsordre'),
				value: 'workOrder'
			}
		];

		// $apply is unhappy to run while it is rendering - check the current render phase and check if we should $apply the changes or it is safe to do it during render cycle.
		function safeApply(fn) {
			var phase = $scope.$root.$$phase;
			if (phase === '$apply' || phase === '$digest') {
				if (fn && (typeof (fn) === 'function')) {
					fn();
				}
			} else {
				$scope.$apply(fn);
			}
		}

		// Update the url params, without pushing new states in history.
		// bool to control if we should add a timeout - useful only on first mount, since this might modify previous state (bug in AngularJs)
		var delayParams = true;
		function updateUrlParams(data) {
			var newParams = {};

			// Keep existing values
			Object.entries($location.search()).forEach(function (entry) {
				var key = entry[0];
				var value = entry[1];
				newParams[key] = value;
			});

			// Add new params, update existing, and remove (if null).
			Object.entries(data).forEach(function (entry) {
				var key = entry[0];
				var value = entry[1];
				if (value === null && newParams[key]) {
					delete newParams[key];
				} else {
					newParams[key] = value;
				}
			});

			if (delayParams) {
				$timeout(function () { $location.search(newParams).replace(); delayParams = false; }, 0);
			} else {
				$location.search(newParams).replace();
			}
		}

		function getSettings() {
			var settings = $localStorage.generalOptions.MapSettings ? JSON.parse($localStorage.generalOptions.MapSettings) : {};

			if (settings.Options) {
				if (settings.Options.Origin)
					settings.Options.Origin = JSON.parse(settings.Options.Origin);
				if (settings.Options.Resolution)
					settings.Options.Resolution = JSON.parse(settings.Options.Resolution);
			}

			if (!settings.TileLayer)
				settings.TileLayer = constants.availableMaps[0].mapData.TileLayer;

			if (settings.TileLayerOptions) {
				settings.TileLayerOptions = JSON.parse(settings.TileLayerOptions);
			} else {
				settings.TileLayerOptions = constants.availableMaps[0].mapData.TileLayerOptions;
			}

			if (settings.CrsCode && settings.Proj4Def)
				settings.TileLayerOptions.crs = new L.Proj.CRS(settings.CrsCode, settings.Proj4Def, settings.Options);

			return settings;
		}

		var settings = getSettings();
		$scope.entityInfos = settings.entityInfo || {};

		// Event when clicking on a clustered marker, and it cannot ungroup more.
		function onClickSpiderified(a) {
			var message;
			if ($scope.selectedTheme === "workOrder") {
				var guids = {
					GuidBuilding: [],
					GuidEstate: [],
					GuidEquipment: []
				};

				a.markers.forEach(function (mark) {
					if (mark.feature.properties.entity === "Building") {
						guids.GuidBuilding.push(mark.feature.properties.entityGuid);
					}
					else if (mark.feature.properties.entity === "Estate") {
						guids.GuidEstate.push(mark.feature.properties.entityGuid);
					}
					else if (mark.feature.properties.entity === "Equipment") {
						guids.GuidEquipment.push(mark.feature.properties.entityGuid);
					}
				});

				guids.GuidBuilding = guids.GuidBuilding.join(",");
				guids.GuidEstate = guids.GuidEstate.join(",");
				guids.GuidEquipment = guids.GuidEquipment.join(",");

				var caption = translationService.translate('web-map-toolbar-selectTheme-workOrder', 'Arbeidsordre');

				message = $compile('<div ng-init="init(\'' + a.markers[0].feature.properties.entity.toLowerCase() + '\',\'' + a.markers[0].feature.properties.entityGuid + '\',\'' + caption + '\',\'' + false + '\',\'' + true + '\',\'' + guids.GuidBuilding + '\',\'' + guids.GuidEstate + '\',\'' + guids.GuidEquipment + '\')" ng-controller="WorkOrderMapMessageController" > <div ng-include="\'app/map/views/message.html\'"></div></div > ')($scope);
			}
			else {
				$scope.clustermarkers = a.markers.map(function (mark) {
					return ({
						entity: mark.feature.properties.entity,
						entityGuid: mark.feature.properties.entityGuid,
						caption: mark.feature.properties.caption
					});
				});
				message = $compile('<map-cluster-popup></map-cluster-popup>')($scope);
			}

			a.cluster.closePopup();
			a.cluster.bindPopup(message[0]);
			// Wait for the leaflet app to fully render the content. Placement of popup will be misaligned without this, or using update.
			setTimeout(function () {
				a.cluster.openPopup();
			}, 100);
		}

		function createMarkerClusterGroup(clusterRadius) {
			if (clusterRadius === null || clusterRadius === undefined || typeof clusterRadius !== "number")
				clusterRadius = 20;

			var markerGroup = L.markerClusterGroup({
				spiderfyDistanceMultiplier: 1,
				showCoverageOnHover: false,
				removeOutsideVisibleBounds: true,
				maxClusterRadius: clusterRadius <= 0 ? 1 : clusterRadius,
				chunkedLoading: true,
			});

			markerGroup.on('spiderfied', onClickSpiderified);
			return markerGroup;
		}

		// Create base map with starting view on Norway
		var map = L.map('map').setView([63.43, 10.40], 5);
		L.tileLayer(settings.TileLayer,
			settings.TileLayerOptions
		).addTo(map);
		var markerGroup = createMarkerClusterGroup();
		map.addLayer(markerGroup);

		// Generating new markers almost doubles the time to render - Cache similar setups.
		// Simple test made the map go from 4s to 2s for 50k markers. Where 2s is more or less the lowest time managed.
		var markerCache = {};
		function getMarker(feature) {
			var key = feature.properties.icon + feature.properties.markerColor + feature.properties.shape;
			if (markerCache[key]) return markerCache[key];

			var mark = L.ExtraMarkers.icon({
				icon: iconService.getMapIcon(feature.properties.icon),
				markerColor: feature.properties.markerColor,
				shape: feature.properties.shape,
				prefix: 'fa',
				type: "extraMarker",
				svg: feature.properties.markerColor && feature.properties.markerColor.startsWith("#")
			});

			markerCache[key] = mark;
			return mark;
		}

		// Callback to create the icon that should be on the map
		function pointToLayer(feature, latlng) {
			var mark = getMarker(feature);

			// Generate template for the popup, but do not compile it since it will add a major increase in render time.
			var message = "";
			if ($scope.selectedTheme === 'workOrder') {
				message = '<div ng-init="init(\'' + feature.properties.entity + '\',\'' + feature.properties.entityGuid + '\',\'' + feature.properties.caption + '\')" ng-controller="WorkOrderMapMessageController" > <div ng-include="\'app/map/views/message.html\'"></div></div > ';
			} else {
				message = '<div class="map-message-info">' +
					'<h4 class="p-b-5 p-r-10 separator-line c-teal clickable" ng-click="navigation.go(\'' + feature.properties.entity.toLowerCase() + '.edit\', {guid:\'' + feature.properties.entityGuid + '\'})">' +
					'<i class="fas fa-pin"></i> ' + feature.properties.caption +
					'</h4></div>';
			}

			var marker = L.marker(latlng, { icon: mark }).bindPopup(message);

			marker.on("popupopen", function () {
				safeApply(function () {
					// Inspired from angularLeafletDirective._manageOpenPopup
					marker.openPopup();
					var popup = marker.getPopup();

					// Since we are using angularJS templating, we need to compile it to run angularJs html. We are doing this on click, rather than doing it on creating the marker to reduce the initial load. (Went from 30s to 2s for 50k rows)
					$compile(popup._contentNode)($scope);

					// Since we are using ng-init, we need to wait for the content to load before showing it.
					var unregister = $scope.$on('$includeContentLoaded', function () {
						popup._updateLayout();
						popup._updatePosition();
						unregister();
					});
				});

			});
			return marker;
		}

		function style(feature) {
			if (feature.geometry.type === "LineString") {
				return { color: feature.properties.color, weight: feature.properties.weight, opacity: feature.properties.opacity };
			}
			return {};
		}

		function centerOnMarkers(features) {
			var latLngs = [];
			features.forEach(function (feature) {
				if (feature.geometry.type === "Point") {
					if (Array.isArray(feature.geometry.coordinates) && feature.geometry.coordinates.length === 2) {
						latLngs.push(new L.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
					}
				} else if (feature.geometry.type === "LineString") {
					if (Array.isArray(feature.geometry.coordinates)) {
						feature.geometry.coordinates.forEach(function (coordinate) {
							if (Array.isArray(coordinate)) {
								latLngs.push(new L.LatLng(coordinate[1], coordinate[0]));
							}
						});
					}
				}
			});

			if (latLngs.length) {
				var bounds = new L.LatLngBounds(latLngs);
				var existingZoom = map.getZoom();
				//padding should force one zoom level earler if any markers are on the border
				map.fitBounds(bounds, { padding: [50, 50] });

				//hack to fix bug in angular leaflet directive setting wrong zoom level
				var zoom = map.getZoom();
				if (zoom === 0) {
					map.setZoom(existingZoom);
				}
			}

			shouldCenterOnMarkers = false;
		}

		function onReloadData() {
			// Remount new markergroup layers when reloading in case we change the cluster radius
			map.removeLayer(markerGroup);
			markerGroup.clearLayers();

			var filters = {};
			for (var property in $scope.panelFilter)
				filters[property] = $scope.panelFilter[property];

			repository.GetPaginated(repository.apiData.gisManagement.url, 0, -1, {}, filters).then(function (result) {
				if (shouldCenterOnMarkers && !coordinatesInUrlParams) {
					centerOnMarkers(result.features);
				}
				else if (urlParams.lat) {
					coordinatesInUrlParams = false;
					map.setView({ lat: urlParams.lat, lng: urlParams.lng }, urlParams.zoom);
				}

				var clusterRadius = 1;
				if (result.features.length >= 100000)
					clusterRadius = 80;
				else if (result.features.length >= 50000)
					clusterRadius = 60;
				else if (result.features.length >= 10000)
					clusterRadius = 40;
				else if (result.features.length >= 500)
					clusterRadius = 10;
				markerGroup = createMarkerClusterGroup(clusterRadius);
				map.addLayer(markerGroup);

				geoJsonLayer = L.geoJson(result, {
					pointToLayer: pointToLayer,
					style: style
				});

				markerGroup.addLayer(geoJsonLayer);
			});
		}

		$scope.requestFilterStatusChange = function (newValue) {
			$scope.panelFilter.request.status = newValue;
			if (!shouldCenterOnMarkers) {
				shouldCenterOnMarkers = true;
				onReloadData();
			}
		};

		var filterWatcher = function () { };
		function addFilterWatcher(watch) {
			filterWatcher = $scope.$watch(watch,
				function(newValue, oldValue) {
					if (newValue === oldValue) return;
					onReloadData();
					updateUrlParams({
						theme: $scope.selectedTheme,
						panelFilter: JSON.stringify($scope.panelFilter)
					});
                }, true);
		}

        $scope.themeChanged = function (panelFilter) {
            filterWatcher();

            if ($scope.selectedTheme === 'basicData') {
				$scope.panelFilter = panelFilter ? panelFilter : {
					showBasicData: true,
					basicData: {
						showEstate: true,
						showBuilding: true,
						showEquipment: false,
						guidEstateCategories: [],
						guidBuildingCategories: [],
						guidEquipmentCategories: [],
						unoXCoLocations: [],
					}
				};
				addFilterWatcher('panelFilter.basicData');
			}
			if ($scope.selectedTheme === 'workOrder') {
                $scope.panelFilter = panelFilter ? panelFilter : { showWorkOrder: true };
				addFilterWatcher('panelFilter.workOrder');
			}
			if ($scope.selectedTheme === 'request') {
                $scope.panelFilter = panelFilter ? panelFilter : { showRequest: true, request: { status: [0, 1, 6] } };
				addFilterWatcher('panelFilter.request');
            }

			updateUrlParams({
				theme: $scope.selectedTheme,
				panelFilter: JSON.stringify($scope.panelFilter)
			});

            shouldCenterOnMarkers = true;
			onReloadData();
		};
		
        //this will also call reloaddata and populate the map with markers based on current theme
		$scope.themeChanged(urlParams.panelFilter ? JSON.parse(urlParams.panelFilter) : null);

		var loadDropdownFilters = function () {
			var commonFilter = {
				FilterModel: {
					Condition: "and",
					Rules: [
						{
							Property: "IsAbstract",
							Operator: "=",
							Value: false
						}
					]
				}
			};

			if ($scope.hasReadAccess('EstateCategory')) {
				repository.GetPaginated(repository.apiData.estateCategory.url, 0, -1, { Description: "asc" }, commonFilter).then(function (response) {
					$scope.dropdownFilters.estateCategory.hasError = false;
					$scope.dropdownFilters.estateCategory.list = response.List;
				}, function () {
					$scope.dropdownFilters.estateCategory.hasError = true;
				});
			}

			if ($scope.hasReadAccess('BuildingCategory')) {
				repository.GetPaginated(repository.apiData.buildingCategory.url, 0, -1, { Description: "asc" }, commonFilter).then(function (response) {
					$scope.dropdownFilters.buildingCategory.hasError = false;
					$scope.dropdownFilters.buildingCategory.list = response.List;

					if ($scope.customization.isCustomer('UnoX')) {
						var getAllItems = function (category) {
							var items = [];
							if (!category || !category.Groups) return items;

							category.Groups.forEach(function (group) {
								if (group.Items) {
									group.Items.forEach(function (item) {
										if (item.Type === 'Category') {
											if (item.Template) {
												getAllItems(item.Template).forEach(function (i) {
													items.push(i);
												});
											}
										} else {
											items.push(item);
										}
									});
								}
							});

							return items;
						};
						var coLocationOptions = [];
						response.List.forEach(function (buildingCategory) {
							var items = getAllItems(buildingCategory.DynamicProperty);
							var coLocation = items.find(function (item) {
								return item.Type === 'Combo' && item.Field === 0;
							});

							if (coLocation && coLocation.NamedSelectionOptions) {
								coLocation.NamedSelectionOptions.forEach(function (option) {
									if (!coLocationOptions.includes(option))
										coLocationOptions.push(option);
								});
							}
						});
						$scope.dropdownFilters.unoXCoLocation.list = coLocationOptions.sort();
					}
				}, function () {
					$scope.dropdownFilters.buildingCategory.hasError = true;
				});
			}

			if ($scope.hasReadAccess('EquipmentCategory')) {
				repository.GetPaginated(repository.apiData.equipmentCategory.url, 0, -1, { Description: "asc" }, commonFilter).then(function (response) {
					$scope.dropdownFilters.equipmentCategory.hasError = false;
					$scope.dropdownFilters.equipmentCategory.list = response.List;
				}, function () {
					$scope.dropdownFilters.equipmentCategory.hasError = true;
				});
			}
		};

		loadDropdownFilters();
		
		map.on("moveend", function () {
			safeApply(function () {
				updateUrlParams({
					zoom: map.getZoom(),
					lat: map.getCenter().lat,
					lng: map.getCenter().lng,
				});
			});
		});

		$scope.$on($scope.events.newSelection, function () {
			shouldCenterOnMarkers = true;
			onReloadData();
		});
	}
})();
