(function () {
	angular.module('Plania').controller('BuildingController', ['$scope', 'Repository', '$stateParams', '$modal', '$state', 'TranslationService', '$location', 'ListService', '$sce', controller]);

	function controller($scope, repository, $stateParams, $modal, $state, translationService, $location, listService, $sce) {
		$scope.isUpdate = $scope.navigation.current.name === 'building.edit';
		$scope.activateAutocomplete = false;
		$scope.reloadDocumentTable = false;
		$scope.reloadDeviations = false;
		$scope.reloadOperationalMessages = false;
		$scope.barcode = {};
		$scope.model = { Guid: $stateParams.guid };
		$scope.dropdownValues = {};

		var availableHashLocations = ["#info", "#area", "#equipment", "#maintenance", "#image"];
		var updateLocation = function () {
			var hash = window.location.hash;
			if (hash && availableHashLocations.includes(hash)) {
				$('#buildingTabs a[data-target="' + hash + '"]').tab('show');
				$scope.currentTab = hash.substr(1);
			}
			else {
				$('#buildingTabs a[data-target="#info"]').tab('show');
				$scope.currentTab = 'info';
			}
		};

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			updateLocation();
		});
		$scope.setTab = function (tab) {
			$location.hash(tab).replace();
		};

		if ($scope.isUpdate && $stateParams.tab) {
			$scope.setTab($stateParams.tab);
			updateLocation();
		} else {
			updateLocation();
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Building, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Building, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Building);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Building);
			}
		};

		updateAccess();

		if ($scope.isUpdate) {
			var columns = [
				"Estate.Id", "Estate.Description", 'Estate.Guid',
				'BuildingCategory.Description', 'BuildingCategory.Icon', 'BuildingCategory.Color',
				"CostCenter.Description", "CostCenter.Id",
				'Region.Id',
				'BuildingType.Id', 'BuildingType.Description', 'BuildingType.IndexPosition', 'BuildingType.ExplanatoryText1', 'BuildingType.ExplanatoryText2', 'BuildingType.GuidParent',
				'BuildingType.ReferenceType.Id', 'BuildingType.ReferenceType.Description', 'BuildingType.ReferenceType.ExplanatoryText',
				'BusinessUnit.Id', 'BusinessUnit.Description', 'BusinessUnit.IndexPosition', 'BusinessUnit.ExplanatoryText1', 'BusinessUnit.ExplanatoryText2', 'BusinessUnit.GuidParent',
				'BusinessUnit.ReferenceType.Id', 'BusinessUnit.ReferenceType.Description', 'BusinessUnit.ReferenceType.ExplanatoryText',
				'OperationsManager.Guid', 'OperationsManager.FirstName', 'OperationsManager.LastName',
				"UserAreaComputedBy.RealName",
				"UserCafComputedBy.RealName",
				'Owner.Id', 'Owner.Description',
				'ExternalFacilityManager.Id', 'ExternalFacilityManager.Description',
				'FacilityManager.FirstName', 'FacilityManager.LastName',
				'ManagerPerson1.FirstName', 'ManagerPerson1.LastName',
				'ManagerPerson2.FirstName', 'ManagerPerson2.LastName',
			];
			repository.getMainModel(repository.apiData.building.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
				$scope.reload = true;
				$scope.model = response.Data;

				if ($scope.model.IsTemplate) {
					// move to correct edit page, and use location "replace" to avoid having this page in browser history
					$state.go('buildingTemplate.edit', { guid: $scope.model.Guid, model: $scope.model }, { location: 'replace' });
					return;
				}

				updateAccess();
				$scope.reloadDocumentTable = true;
				$scope.reloadDeviations = true;
				$scope.reloadOperationalMessages = true;
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			},
				function (error) {
					repository.growl(error, 'danger');
				});
		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidBuildingCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.buildingCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Estate':
								$scope.model.Estate = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidEstate = prefill.Value.Guid;
								break;
							case 'BuildingCategory':
								$scope.model.BuildingCategory = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidBuildingCategory = prefill.Value.Guid;
								break;
							case 'CostCenter':
								$scope.model.CostCenter = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidCostCenter = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.building.url, $stateParams.guidEntityToCopy).then(function (result) {
					delete result.Data.Guid;
					delete result.Data.GuidGisEntity;

					$scope.model = result.Data;
					$scope.model.Id += '-N';
					$scope.reload = true;
				});
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('building.create');
				if (persistentData) {
					$scope.model.Estate = { Guid: persistentData.GuidEstate, Caption: persistentData.Estate };
					$scope.model.GuidEstate = persistentData.GuidEstate;
				}
			}

			if (!$stateParams.guidEntityToCopy)
				getCategoryPrefill();

			if (!$scope.model.CountryCode) {
				$scope.model.CountryCode = repository.commonService.getFilterData().selectedDataOwner.CountryCode;
			}

			$scope.activateAutocomplete = true;
		}

		$scope.updateBuilding = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? "Bygg har blitt oppdatert." : 'Bygg har blitt opprettet', 'success');
				if (repository.commonService.getFilterData().selectedSelection.Guid) {
					repository.growl('Bygget har blitt lagt til gjeldende utvalg.', 'success');
				}
				var buildingGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, buildingGuid);
				var hash = window.location.hash;
				if (hash) hash = hash.substr(1);
				$scope.goBack(destination, { guid: buildingGuid, menuGuid: $scope.navigation.params.menuGuid, tab: hash });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				if ($scope.barcode.Id) {
					repository.createSingle(repository.apiData.barcode.url, $scope.barcode)
						.then(function (result) {

						},
							function (error) {
								repository.growl(error, 'danger');
							});
				}

				repository.updateSingle(repository.apiData.building.url, $scope.model).then(success).catch(error);
			} else {
				$scope.model.BuildingXBuildingSelectionsToCreate = [
					{ GuidBuildingSelection: repository.commonService.getFilterData().selectedSelection.Guid }
				];
				repository.createSingle(repository.apiData.building.url, $scope.model).then(success, error);
			}
		};

		$scope.changeId = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/changeIdModal.html',
				controller: 'ChangeIdModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							id: $scope.model.Id
						};
					}
				}
			}).result.then(function (id) {
				if (id) {
					repository.patch(repository.apiData.building.url, $scope.model.Guid, { Id: id }).then(function (result) {
						repository.growl(translationService.translate('web-building-changeId-success', 'Id ble oppdatert'), 'success');
						$scope.model.Id = id;
					}, function (error) {
						repository.showError(error);
					});
				}
			});
		};

		$scope.removeBuilding = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-building-message', "Bygningen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-building-button-confirm', 'Ja, fjern bygningen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.building.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-building-success', 'Bygningen ble fjernet!'), result, "success");
						repository.growl('Bygningen har blitt fjernet', 'success');
						$scope.goBack('building.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal({
							title: "Kunne ikke slette bygget!",
							text: error.Message + "<br/><br/> Vil du deaktivere bygget?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						},
							function (isConfirm) {
								window.onkeydown = null;
								window.onfocus = null;
								if (isConfirm) {
									$scope.model.Id = '#' + $scope.model.Id;
									$scope.model.IsDeactivated = true;
									$scope.updateBuilding('building.list');
								}
							}
						);
					});
			});
		};

		$scope.cancel = function () {
			$scope.goBack('building.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
