(function () {
	angular.module('Plania').controller('AddNewListColumnController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, repository, translationService) {
        var locale = translationService.getLocale();
        var prefix = params.prefix;
        if (prefix === repository.apiData.userGroup.prefix)
			prefix = repository.apiData.user.prefix;

		$scope.showAdvancedMode = params.showAdvancedMode;

		if (prefix) {
			repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=' + prefix + '&locale=' + locale).then(function (result) {
				var resultCopy = angular.copy(result);
				var guidProperties = angular.copy(_.filter(resultCopy, function (col) { return col.GuidProperty; }));
				guidProperties.forEach(function (col) { col.IsExpandable = false; col.Title = col.GuidProperty; col.isAdvanced = true; col.Property = col.GuidProperty; col.PropertyType = 'string'; });

				$scope.model = resultCopy.concat(guidProperties);
			});
		}

        $scope.getNextLevel = function (column) {
			repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=' + column.PropertyType + '&locale=' + locale).then(function (result) {
				var resultCopy = angular.copy(result);
				var guidProperties = angular.copy(_.filter(resultCopy, function (col) { return col.GuidProperty; }));

                column.Properties = [];
                result.forEach(function(childColumn) {
                    childColumn.Property = column.Property + '.' + childColumn.Property;
                    column.Properties.push(childColumn);
				});

				guidProperties.forEach(function (childColumn) {
					childColumn.Property = column.Property + '.' + childColumn.GuidProperty;
					childColumn.IsExpandable = false;
					childColumn.Title = childColumn.GuidProperty;
					childColumn.PropertyType = 'string';
					childColumn.isAdvanced = true;
					column.Properties.push(childColumn);
				});
            });
        };

        $scope.selectedColumns = [];

        $scope.ok = function () {
            $modalInstance.close($scope.selectedColumns);
		};

        var match = function(textToSearch, searchValue) {
	        if (!searchValue) return true;
	        if (!textToSearch) return false;
	        return textToSearch.toLowerCase().includes(searchValue.toLowerCase());
        };

        var searchMatch = function(col, searchString) {
			if ($scope.showPropertyNames)
		        return match(col.Title, searchString) || match(col.Property, searchString);

	        return match(col.Title, searchString);
		};

        var hideExpandableColumns = params.hideExpandableColumns;

        var alreadyExist = function (column) {
	        if (hideExpandableColumns && column.IsExpandable)
		        return true;
	        if (_.filter(params.existingColumns, { Property: column.Property }).length > 0)
		        return true;
	        return false;
        };

		$scope.columnFilter = function(col) {
	        if (alreadyExist(col)) return false;
	        if (!searchMatch(col, $scope.searchString)) return false;
			return $scope.identityColumnsFilter(col);
        };

		$scope.identityColumnsFilter = function (col) {
			if ($scope.showIdentityColumns)
				return true;
			else
				return !col.isAdvanced;
		};

		$scope.selectColumn = function (column, event) {
            event.stopPropagation();
            if (column.IsExpandable) {
                column.isExpanded = column.isExpanded ? false : true;
                if (column.isExpanded && column.Properties && column.Properties.length === 0) {
                        $scope.getNextLevel(column);
                }
            } else {
                if (!_.contains($scope.selectedColumns, column)) {
                    $scope.selectedColumns.push(column);
                } else {
                    for (var i = 0; i < $scope.selectedColumns.length; i++) {
                        var p = $scope.selectedColumns[i];
                        if (p === column) {
                            $scope.selectedColumns.splice(i, 1);
                            return;
                        }
                    }
                }
            }
        };

		$scope.isColumnSelected = function (column) {
            return _.contains($scope.selectedColumns, column);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
