(function () {
	angular.module('Plania').factory('AreaService', ["Repository", "$q", '$modal', 'TranslationService', '$rootScope', areaService]);

	function areaService(repository, $q, $modal, translationService, $rootScope) {
		var service = {};

		// Apply functions required for the form (editAreaShared.html).
		// This is used to have common logic between AreaController (when in Modal) and AreaInfoDirective
		service.enrichScopeWithFormFunctions = function ($scope) {
			$scope.getProgressType = function () {
				return ($scope.model.RealCapacity <= $scope.model.CalculatedCapacity) ? 'success' : 'danger';
			};

			$scope.onBuildingSelect = function (building) {
				if (!building) {
					$scope.model.GuidBuilding = "";
					return;
				}

				if ($scope.model.GuidBuilding !== building.Guid) {
					$scope.model.GuidDrawing = "";
				}

				$scope.model.Address = building.Address;
				$scope.model.GuidBuilding = building.Guid;
			};

			$scope.onDrawingSelect = function (drawing) {
				if (!drawing) {
					$scope.model.GuidDrawing = "";
					return;
				}

				$scope.model.GuidDrawing = drawing.Guid;
				$scope.model.GuidBuilding = drawing.GuidBuilding;
			};

			$scope.onAreaCategorySelect = function (category) {
				if (!category) {
					$scope.newDynamicProperty = null;
					$scope.model.GuidAreaCategory = "";
					return;
				}
				if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
					$scope.newDynamicProperty = category.DynamicProperty;
				} else {
					$scope.newDynamicProperty = null;
				}
				$scope.model.GuidAreaCategory = category.Guid;
			};

			$scope.autoCompleteFilter = function (filterName) {
				var filter = {};
				switch (filterName) {
					case "person":
						filter.PropertyFilter = [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }];
						if ($scope.model.GuidCleaningTeam)
							filter.PropertyFilter.push({ Property: 'GuidResourceGroup', Value: $scope.model.GuidCleaningTeam, Operator: '=' });
						return filter;
					case "resourceGroup":
						filter.PropertyFilter = [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }];
						return filter;
					case "drawing":
						return { GuidBuilding: $scope.model.GuidBuilding };
					case "areaCategory":
						filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
						return filter;
				}
			};
		};

		// Find an active Customer on the requested area. This will either return the first customer if only one, or a modal if multiple is found.
		service.getActiveCustomer = function (guidArea, skipModal) {
			var deferred = $q.defer();

			if (!guidArea || !$rootScope.hasReadAccess('Customer'))
				return $.Deferred().resolve(null).promise();

			var filter = {
				GuidArea: guidArea,
				AreaContext: "ActiveHousing",
			};

			var customerColumns = [];
			repository.GetPaginated(repository.apiData.customer.url, 0, 1, null, filter, "", JSON.stringify(customerColumns)).then(function (result) {
				if (result.TotalCount === 1) {
					deferred.resolve(result.List[0]);
					return;
				} else if (!skipModal && result.TotalCount > 1) {
					$modal.open({
						templateUrl: 'app/common/views/singleSelectModal.html',
						controller: 'SingleSelectModalController',
						size: 'md',
						resolve: {
							modalParams: function () {
								return {
									url: repository.apiData.customer.url,
									sorting: { Id: "asc" },
									title: translationService.translate('web-area-getActiveCustomer-title', 'Velg kunde'),
									filter: filter,
									columns: [
										{ title: translationService.translate('web-customer-description'), property: 'Description' },
									]
								};
							}
						}
					}).result.then(function (item) {
						deferred.resolve(item);
					}, function () {
						deferred.resolve(null);
					});
				} else {
					deferred.resolve(null);
				}
			}, function (error) {
				deferred.reject(error);
			});

			return deferred.promise;
		};

		return service;
	}
})();
