(function () {
	angular.module('Plania').factory('SignalRconfig', ['authService', 'config', '$httpParamSerializer', 'GeneralOptionsService', 'TranslationService', '$rootScope', '$localStorage', '$state', 'SignalR', SignalRconfig]);

	function SignalRconfig(authService, config, $httpParamSerializer, generalOptionsService, translationService, $rootScope, $localStorage, $state, signalR) {
		var factory = {};
		signalR.on('reloadPage', function () {
			$state.reload();
		});

		signalR.on('refreshGeneralOptions', function () {
			generalOptionsService.refreshGeneralOptions();
		});

		signalR.on('refreshTranslations', function (msg) {
			// Only one translation have been updated
			if (msg) {
				var data = JSON.parse(msg);
				translationService.setLanguageEntry(data.Locale + data.SystemName.toLowerCase(), data.TranslatedValue);
			}
			// Multiple translations have been updated
			else {
				translationService.getTranslations();
			}
		});

		factory.setEditPageListener = function (to, toParams, from, fromParams) {
			if (!$localStorage.generalOptions.NotifyObjectUpdated) return;

			$rootScope.editPageHasBeenUpdatedMessage = null;
			if (from.name.includes('.edit')) {
				signalR.off('updatedPage' + fromParams.guid);
			}
			if (to.name.includes('.edit')) {
				signalR.on('updatedPage' + toParams.guid, function (msg) {
					var data = JSON.parse(msg);
					$rootScope.editPageHasBeenUpdatedMessage = data;
				});
			}
		};

		signalR.connection.disconnected(function () {
			if (!authService.getUserData().isAuthenticated) return;
			setTimeout(function () {
				if (!authService.getUserData().isAuthenticated) return;
				authService.refreshAuthentication().then(function (result) {
					if (result !== 'invalid') {
						signalR.setQs({
							guidUser: authService.getUserData().guidUser,
							fingerprint: config.fingerprint
						});
						signalR.connection.start();
					}
				});
			}, 5000);
		});


        return factory;
    }
})();
