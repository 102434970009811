(function () {
	angular.module('Plania').controller('SparePartWithdrawalController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', 'ListService', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'sparePartWithdrawal.edit';

		var columns = [
			'SparePart.ComponentXSupplier.PartNumber',
			'SparePart.Component.Id',
			'SparePart.Component.Description',
			'SparePart.Component.Unit',
			'SparePart.Inventory',
			'WorkOrderXSparePart.WorkOrder.Id',
			'Building.Id',
			'Building.Description',
			'Equipment.Id',
			'Equipment.Description',
			'UserCreatedBy.RealName'
		];

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.sparePartWithdrawal.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.fieldRules = response.FieldRules;
					$scope.reload = true;
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		} else {
			repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Condition).then(function (result) {
				$scope.fieldRules = result;
				$scope.reload = true;
			});
		}

		$scope.updateWithdrawal = function (destination) {
			if (!$scope.isUpdate) {
				$scope.model.GuidDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			}
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-sparePartWithdrawal-success', 'Uttak ble oppdatert.') : translationService.translate('create-withdrawal-success', 'Uttak ble opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};
			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};
			if ($scope.isUpdate)
				repository.updateSingle(repository.apiData.sparePartWithdrawal.url, $scope.model).then(success).catch(error);
			else
				repository.createSingle(repository.apiData.sparePartWithdrawal.url, $scope.model).then(success).catch(error);
		};

		$scope.removeWithdrawal = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-withdrawal-message', "Uttak vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-withdrawal-button-confirm', 'Ja, fjern uttaket!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.sparePartWithdrawal.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-withdrawal-success', 'Uttaket ble fjernet!'), result, "success");
					repository.growl('Uttaket har blitt fjernet', 'success');
					$scope.goBack('sparePartWithdrawal.list', { menuGuid: $scope.navigation.params.menuGuid });
				});
			});
		};
	}
})();
