(function () {
	angular.module('Plania').factory('CustomerService', ["Repository", "$q", '$modal', 'TranslationService', '$rootScope', customerService]);

	function customerService(repository, $q, $modal, translationService, $rootScope) {
		var service = {};

		// Find an active Customer on the requested area. This will either return the first customer if only one, or a modal if multiple is found.
		service.getActiveHousing = function (guidCustomer, skipModal) {
			var deferred = $q.defer();

			if (!guidCustomer || !$rootScope.hasReadAccess('Area'))
				return $.Deferred().resolve(null).promise();

			var filter = {
				GuidCustomer: guidCustomer,
				AreaContext: "ActiveHousing",
			};

			var areaColumns = [];
			repository.GetPaginated(repository.apiData.area.url, 0, 1, null, filter, "", JSON.stringify(areaColumns)).then(function (result) {
				if (result.TotalCount === 1) {
					deferred.resolve(result.List[0]);
					return;
				} else if (!skipModal && result.TotalCount > 1) {
					$modal.open({
						templateUrl: 'app/common/views/singleSelectModal.html',
						controller: 'SingleSelectModalController',
						size: 'lg',
						resolve: {
							modalParams: function () {
								return {
									url: repository.apiData.area.url,
									sorting: { Id: "asc" },
									title: translationService.translate('web-customer-getActiveHousing-title', 'Velg bolig'),
									filter: filter,
									columns: [
										{ title: translationService.translate('web-area-id'), property: 'Id' },
										{ title: translationService.translate('web-area-description'), property: 'Description' },
										{ title: translationService.translate('web-area-address'), property: 'Address' },
										{ title: translationService.translate('web-areaCategory-description'), property: 'AreaCategory.Description' },
										{ title: translationService.translate('web-area-rentalStatus'), property: 'RentalStatus' },
										{ property: 'Building.Id', hidden: true },
										{ property: 'Building.Description', hidden: true },
										{ property: 'Building.GuidEstate', hidden: true },
										{ property: 'Building.Estate.Id', hidden: true },
										{ property: 'Building.Estate.Description', hidden: true },
									]
								};
							}
						}
					}).result.then(function (item) {
						deferred.resolve(item);
					}, function () {
						deferred.resolve(null);
					});
				} else {
					deferred.resolve(null);
				}
			}, function (error) {
				deferred.reject(error);
			});

			return deferred.promise;
		};

		return service;
	}
})();
