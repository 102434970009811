(function () {
	angular.module('Plania').controller('DataBimImportController', ['$scope', 'TranslationService', 'Repository', 'RegisteredFieldService', '$q', '$stateParams', '$modal', '$location', 'SignalR', '$timeout', 'config', '$window', controller]);

	function controller($scope, translationService, repository, registeredFieldService, $q, $stateParams, $modal, $location, signalR, $timeout, config, $window) {
		$scope.isEdit = !!$stateParams.guid;

		$scope.entityTypes = [
			{ key: repository.apiData.estate.prefix, value: translationService.translate('web-enum-prefix-estate') },
			{ key: repository.apiData.building.prefix, value: translationService.translate('web-enum-prefix-building') },
			{ key: repository.apiData.equipment.prefix, value: translationService.translate('web-enum-prefix-equipment') },
			{ key: repository.apiData.area.prefix, value: translationService.translate('web-enum-prefix-area') },
			{ key: repository.apiData.component.prefix, value: translationService.translate('web-enum-prefix-component') },
			{ key: repository.apiData.supplier.prefix, value: translationService.translate('web-enum-prefix-supplier') },
			{ key: repository.apiData.person.prefix, value: translationService.translate('web-enum-prefix-person') },
			{ key: repository.apiData.customer.prefix, value: translationService.translate('web-enum-prefix-customer') }
		];

		$scope.steps = [
			{ stepIndex: 1, name: 'chooseObject', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-chooseObject', 'Velg objekt'), onNextStepClicked: handleChooseObjectStep },
			{ stepIndex: 2, name: 'chooseSource', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-chooseSource', 'Velg kilde'), onNextStepClicked: handleChooseSourceStep, onStepAppearing: onChooseSourceAppearing },
			{ stepIndex: 3, name: 'editFieldMappings', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-editFieldMappings', 'Rediger knytninger'), onNextStepClicked: updateDataImport, onStepAppearing: onFieldMappingsAppearing },
			{ stepIndex: 4, name: 'validateImport', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-validateImport', 'Validering'), onNextStepClicked: updateDataImport },
			{ stepIndex: 5, name: 'startImport', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-startImport', 'Start import') },
		];

		if (!$scope.isEdit) {
			$scope.currentStep = $scope.steps[0];
			$scope.completedStepIndex = $scope.currentStep.stepIndex;
		}

		var setCurrentStep = function (step) {
			$location.hash(step.name).replace();
			$scope.currentStep = step;
			if ($scope.completedStepIndex === undefined || $scope.completedStepIndex < $scope.currentStep.stepIndex)
				$scope.completedStepIndex = $scope.currentStep.stepIndex;
			if ($scope.currentStep.onStepAppearing !== undefined)
				$scope.currentStep.onStepAppearing();
		};

		var onChangeStep = function (step) {
			if ($scope.currentStep.onNextStepClicked === undefined) {
				setCurrentStep(step);
				return;
			}

			$scope.currentStep.onNextStepClicked().then(function (valid) {
				if (!valid) return;
				setCurrentStep(step);
			});
		};

		$scope.onStepClick = function (step) {
			if (step.stepIndex > $scope.completedStepIndex) return;
			setCurrentStep(step);
		};

		$scope.handlePreviousStep = function () {
			var step = _.find($scope.steps, function (step) { return step.stepIndex === $scope.currentStep.stepIndex - 1; });
			if (step)
				setCurrentStep(step);
		};

		$scope.handleNextStep = function () {
			var step = _.find($scope.steps, function (step) { return step.stepIndex === $scope.currentStep.stepIndex + 1; });
			if (step)
				onChangeStep(step);
		};

		$scope.model = {
			Guid: $stateParams.guid,
			FieldMapping: [],
			InputConfig: {
				Type: 'Bim',
				BimConfig: {
					Filter: [
					]
				}
			}
		};

		$scope.showSelectFile = true;

		if ($stateParams.guid){
			getModel();
		} else if ($stateParams.guidEntityToCopy) {
			repository.getSingle(repository.apiData.dataImportBim.url, $stateParams.guidEntityToCopy).then(function (result) {
				delete result.Data.Guid;
				delete result.Data.GuidBinaryData;

				$scope.model = result.Data;
				$scope.model.Id += ' - kopi';
			});
		}

		$scope.currentNumberOfDataOwners = 0;
		function getNumberOfDataOwnersInLicense(){
			repository.getSingleWithNoParameter(repository.apiData.planiaSystem.url).then(function (result){
				if (result) {
					$scope.currentNumberOfDataOwners = result.CurrentNumberOfDataOwners;
				}
			}, function(error){
				repository.showError(error);
			});
		}
		getNumberOfDataOwnersInLicense();

		function getModel(){
			repository.getSingle(repository.apiData.dataImportBim.url, $scope.model.Guid).then(function(result){
				$scope.model = result.Data;

				$scope.getBuildings();
				if ($scope.model.InputConfig.BimConfig.GuidBuilding){
					$scope.getBimModels();
				}
				if ($scope.model.InputConfig.BimConfig.GuidBimFile) {
					$scope.getIfcTypes();
					$scope.getPreview();
				}
				if ($scope.model.Summary){
					$scope.runSummary = JSON.parse($scope.model.Summary);
				}

				if ($scope.model.ValidationSummary)
					$scope.validationSummary = JSON.parse($scope.model.ValidationSummary);

				var hash = $location.hash();
				if (hash) {
					setCurrentStep(_.find($scope.steps, function (step) { return step.name === hash; }));
				} else {
					if ($scope.model.Status === 'Completed')
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'startImport'; }));
					else if ($scope.model.FieldMapping && $scope.model.FieldMapping.length > 0)
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'validateImport'; }));
					else if ($scope.model.GuidBinaryData)
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'editFieldMappings'; }));
					else if ($scope.model.EntityType)
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'chooseSource'; }));
				}

			}, function (error){
				repository.growl(error, 'danger');
			});
		}

		registeredFieldService.getRegisteredField(repository.commonService.prefix.DataImport).then(function (result) {
			$scope.fieldRules = result;
		});

		function handleChooseObjectStep(){
			var deferred = $q.defer();

			if ($scope.model.Guid){
				repository.updateSingle(repository.apiData.dataImportBim.url, $scope.model).then(function(response) {
					$scope.model = response;
					deferred.resolve(true);
				}, function(error){
					repository.growl(error, 'danger');
					deferred.reject(error);
				});
			} else {
				delete($scope.model.Guid); // Cannot have empty string guid anymore, huh
				repository.createSingle(repository.apiData.dataImportBim.url, $scope.model).then(function (response) {
					$scope.model = response;
					$location.path($location.path()  + response.Guid); // cannot have slash between path and response.guid anymore
					$location.replace();
					deferred.resolve(true);
				}, function (error) {
					repository.growl(error, 'danger');
					deferred.reject(error);
				});
			}

			return deferred.promise;
		}

		function handleChooseSourceStep(){
			var deferred = $q.defer();
			repository.updateSingle(repository.apiData.dataImportBim.url, $scope.model).then(function (response) {
				$scope.model = response;

				repository.runAction('dataImport/bim/' + $scope.model.Guid + '/GetInitialFieldMappings').then(function(result){
					$scope.model.FieldMapping = result.FieldMapping;
					deferred.resolve(true);
				}, function(error){
					repository.growl(error, 'danger');
					deferred.reject(error);
				});
			}, function (error) {
				repository.growl(error, 'danger');
				deferred.reject(error);
			});

			return deferred.promise;
		}

		function onChooseSourceAppearing() {
			if ($scope.model.GuidBinaryData && !$scope.tempTable){
				$scope.getPreview();
			}
		}

		function onFieldMappingsAppearing() {
			if (!$scope.tempTable){
				$scope.getPreview();
			}
		}

		function updateDataImport() {
			var deferred = $q.defer();
			repository.updateSingle(repository.apiData.dataImportBim.url, $scope.model).then(function (response) {
				$scope.model = response;
				deferred.resolve(true);
			}, function (error) {
				repository.growl(error, 'danger');
				deferred.reject(error);
			});

			return deferred.promise;
		}

		$scope.getBuildings = function (){
			var filter = {
				PropertyFilter: [
					{ Property: 'GuidBuilding', Operator: '<>', Value: null },
				]
			};
			var columns = [
				'Building.Id',
				'Building.Description'
			];
			repository.GetPaginated(repository.apiData.buildingXBimFile.url, 0, -1, {'Building.Id': 'asc'}, filter, null, JSON.stringify(columns)).then(function (result){
					$scope.buildingOptions = _.map(_.uniq(result.List, false, function(a){
						return a.Building.Guid;
					}), function (ifc){
						return {
							Guid: ifc.Building.Guid,
							Caption: ifc.Building.Caption
						};
					});
				});
		};

		$scope.getIfcTypes = function(){
			var guidBimFile = $scope.model.InputConfig.BimConfig.GuidBimFile;
			if (!guidBimFile) return;
			repository.getSingleWithNoParameter('dataImport/bim/' + guidBimFile + '/ifcTypes').then(function(result){
				$scope.bimIfcTypes = result;
			}, function(error){
				repository.growl(error, 'danger');
			});
		};

		$scope.getBimModels = function() {
			var guidBuilding = $scope.model.InputConfig.BimConfig.GuidBuilding;
			if (!guidBuilding) return;
			repository.getWithUrlParameter('dataImport/bim/models', 'guidBuilding=' + guidBuilding).then(function (result) {
				if (result)
					$scope.bimModelOptions = result.Models;
				else
					$scope.bimModelOptions = [];

			}, function(error){
				repository.growl(error, 'danger');
			});
		};

		$scope.getPreview = function () {
			var guidBimFile = $scope.model.InputConfig.BimConfig.GuidBimFile;
			var ifcType = $scope.model.InputConfig.BimConfig.IfcType;
			var guidDataImport = $scope.model.Guid;

			repository.getWithUrlParameter('dataImport/bim/' + guidBimFile + '/products', 'guidDataImport=' + guidDataImport + '&ifcType=' + ifcType + '&limitTo=10').then(function(result){
				$scope.tempTable = result.Table;
				$scope.totalCount = result.TotalCount;
			}, function(error){
				$scope.tempTable = [];
				repository.growl(error, 'danger');
			});
		};


		$scope.addFilter = function(){
			var guidBimFile = $scope.model.InputConfig.BimConfig.GuidBimFile;
			var ifcType = $scope.model.InputConfig.BimConfig.IfcType;

			var modalInstance = $modal.open({
				templateUrl: 'app/dataImport/views/addImportFieldMappingModal.html',
				controller: 'AddImportFieldMappingModalController',
				resolve: {
					params: function () {
						return {
							guidBimFile: guidBimFile,
							guidDataImport: $scope.model.Guid,
							ifcType: ifcType,
							enableConstantValue: false
						};
					}
				}
			});

			modalInstance.result.then(function (modalResult) {
				if (modalResult.type === 'column') {
					var selectedColumn = modalResult.value[0];
					var filter = {
						Operation: "=",
						Value: "",
						Type: ""
					};

					filter.Field = selectedColumn.PropertyName;
					filter.Title = selectedColumn.Title;
					filter.Type = selectedColumn.Type;
					$scope.model.InputConfig.BimConfig.Filter.push(filter);
				}
			});
		};

		$scope.removeFilter = function(filterElement){
			var index = $scope.model.InputConfig.BimConfig.Filter.indexOf(filterElement);
			if (index > -1){
				$scope.model.InputConfig.BimConfig.Filter.splice(index, 1);
			}
		};

		$scope.updateFilter = function (){
			updateDataImport().then(function (){
				$scope.getPreview();
			});
		};

		$scope.setMappingColumn = function (mapping) {
			var existingColumns = [];

			$scope.model.FieldMapping.forEach(function (map) {
				if (map.PlaniaField)
					existingColumns.push({ Property: map.PlaniaField });
			});

			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: $scope.model.EntityType,
							existingColumns: existingColumns,
							showAdvancedMode: true
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				if (selectedColumns && selectedColumns.length) {
					var splittedProperties = selectedColumns[0].Property.split(".");
					if (splittedProperties.length >= 3) return;

					mapping.PlaniaField = selectedColumns[0].Property;
					mapping.PlaniaFieldTitle = selectedColumns[0].Title;
				}
			});
		};

		$scope.setImportMappingColumn = function(mapping){
			$scope.addImportFields(mapping);
		};

		$scope.resetImportMappingColumn = function(mapping){
			mapping.ImportField = null;
		};

		$scope.resetMappingColumn = function (mapping){
			if (!mapping) return;
			var mappingToChange = _.find($scope.model.FieldMapping, function(fieldMapping){
				return fieldMapping.ImportField === mapping.ImportField;
			});
			if (mappingToChange.PlaniaField === $scope.model.PrimaryField)
				$scope.model.PrimaryField = null;
			mappingToChange.PlaniaField = null;
			mappingToChange.PlaniaFieldTitle = null;
		};

		$scope.setPrimaryField = function (mapping) {
			if (!mapping.PlaniaField || mapping.PlaniaField.split(".").length >= 2) return;
			$scope.model.PrimaryField = mapping.PlaniaField;
		};

		$scope.addMapping = function (){
			$scope.model.FieldMapping.push({
				ImportField: '',
				PlaniaField: '',
				PlaniaFieldTile: '',
			});
		};

		$scope.removeMapping = function(mapping){
			var index = $scope.model.FieldMapping.indexOf(mapping);
			$scope.model.FieldMapping.splice(index, 1);
		};

		$scope.addImportFields = function(mapping){
			var guidBimFile = $scope.model.InputConfig.BimConfig.GuidBimFile;
			var ifcType = $scope.model.InputConfig.BimConfig.IfcType;

			var modalInstance = $modal.open({
				templateUrl: 'app/dataImport/views/addImportFieldMappingModal.html',
				controller: 'AddImportFieldMappingModalController',
				resolve: {
					params: function () {
						return {
							guidBimFile: guidBimFile,
							guidDataImport: $scope.model.Guid,
							ifcType: ifcType,
							enableConstantValue: true,
							existingColumns: _.map($scope.model.FieldMapping, 'ImportField')
						};
					}
				}
			});

			modalInstance.result.then(function (modalResult) {
				if (modalResult.type === 'column'){
					var selectedColumns = modalResult.value;
					if (mapping){
						mapping.ImportField = selectedColumns[0].Title;
						return;
					}
					_.forEach(selectedColumns, function (col){
						$scope.model.FieldMapping.push({
							ImportField: col.Title,
							PlaniaField: '',
							PlaniaFieldTitle: ''
						});
					});
				}
				if (modalResult.type === 'constant'){
					var fieldMapping = {
						ImportField: "[Constant]",
						IsConstant: true,
						ConstantValue: modalResult.value,
						PlaniaField: '',
						PlaniaFieldTitle: ''
					};
					if (mapping){
						mapping.ImportField = fieldMapping.ImportField;
						mapping.IsConstant = fieldMapping.IsConstant;
						mapping.ConstantValue = fieldMapping.ConstantValue;
					} else{
						$scope.model.FieldMapping.push(fieldMapping);
					}
				}

			});
		};

		$scope.mappingPrimaryFieldTooltipText = {
			isPrimaryField: translationService.translate('web-dataImport-fieldMapping-isPrimaryField-popover', 'Denne kolonnen er valgt som hovedkolonne. Denne blir da brukt for å sjekke om et import objekt eksisterer i Plania.'),
			isRelationField: translationService.translate('web-dataImport-fieldMapping-isRelationField-popover', 'Denne kolonnen er en knytningskolonne. Denne blir brukt for å knytte et import objekt til et annet relatert objekt som allerede eksisterer i Plania.'),
			isNotSelected: translationService.translate('web-dataImport-fieldMapping-isNotSelected-popover', 'Klikk for å velge denne kolonnen som hovedkolonne. Denne blir da brukt for å sjekke om et import objekt eksisterer i Plania.'),
			isOwnedField: translationService.translate('web-dataImport-fieldMapping-isOwnedField-popover', 'Denne kolonnen kan opprettes eller oppdateres likt som egenskaper på import objektet.'),
		};

		function getRelationType (mapping){
			if (!mapping.PlaniaField) return "Direct";
			if (mapping.PlaniaField.startsWith("NonsReference."))
				return "Owned";
			else if (mapping.PlaniaField.includes("."))
				return "Relation";
			return "Direct";
		}

		$scope.getTooltipText = function(mapping, model) {
			var relationType = getRelationType(mapping);
			if (model.PrimaryField === mapping.PlaniaField) {
				return $scope.mappingPrimaryFieldTooltipText.isPrimaryField;
			}
			else if (relationType === "Relation") {
				return $scope.mappingPrimaryFieldTooltipText.isRelationField;
			} else if (relationType === "Owned") {
				return $scope.mappingPrimaryFieldTooltipText.isOwnedField;
			}
			else {
				return $scope.mappingPrimaryFieldTooltipText.isNotSelected;
			}
		};

		$scope.getClasses = function(mapping, model) {
			var relationType = getRelationType(mapping);
			var classes = {
				'fas fa-star c-amber': model.PrimaryField === mapping.PlaniaField,
				'fas fa-star c-bluegray': relationType === "Relation",
				'far fa-star': model.PrimaryField !== mapping.PlaniaField && (relationType === "Direct" || relationType === "Owned")
			};

			// Convert the dictionary to a space-separated string
			var classString = '';
			for (var key in classes) {
				if (classes[key]) {
					classString += key + ' ';
				}
			}
			// Remove trailing whitespace
			return classString.trim();
		};
		initializeImportJobValidatingSignalR(getValidatingSignalRMethodName());

		$scope.runValidation = function(){
			updateDataImport().then(function(){
				$scope.validationSummary = {
					RowSummaries: []
				};
				repository.runAction(repository.apiData.dataImportBim.url + $scope.model.Guid + '/RunValidation').then(function(result){
				}, function(error){
					repository.growl(error, 'danger');
				});
			});

		};

		initializeImportJobProcessingSignalR(getProcessingSignalRMethodName());

		$scope.startImport = function(){
			$scope.runSummary = {
				RowSummaries: []
			};
			repository.runAction(repository.apiData.dataImportBim.url + $scope.model.Guid + '/RunImport').then(function(result){
			}, function(error){
				repository.growl(error, 'danger');
			});
		};

		function getProcessingSignalRMethodName() {
			return 'processingDataImportRun_' + $scope.model.Guid;
		}

		function getValidatingSignalRMethodName() {
			return 'validatingDataImportRun_' + $scope.model.Guid;
		}

		function initializeImportJobValidatingSignalR(methodName) {

			signalR.on(methodName, function(progress) {
				if (progress.Finished === true){
					$scope.importJobValidating = false;
					return;
				}
				$scope.importJobValidating = true;
				$scope.importJobValidationProgress = progress;

				$scope.validationSummary.RowSummaries.push(progress.RowSummary);
				$scope.validationSummary.RunStatistics = progress.RunStatistics;

			});

			$scope.$on('$destroy', function () {
				signalR.off(methodName);
			});
		}

		function initializeImportJobProcessingSignalR(methodName) {
			signalR.on(methodName, function(progress) {
				if (progress.Finished === true){
					$timeout(function () {
						$scope.importJobProcessing = false;
						getModel();
					}, 250);
					return;
				}

				$scope.importJobProcessing = true;
				$scope.importJobProgress = progress;

				$scope.runSummary.RowSummaries.push(progress.RowSummary);
				$scope.runSummary.RunStatistics = progress.RunStatistics;
			});

			$scope.$on('$destroy', function () {
				signalR.off(methodName);
			});
		}

		$scope.createNewImport = function() {
			$scope.navigation.go('dataImport.bim', { guidEntityToCopy: $scope.model.Guid }, { inherit: false });
		};

		$scope.deleteImport = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-dataImport-wizard-startImport-deleteMessage', "Dataimport vil bli fjernet"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-button-confirm', 'Ja'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.dataImportBim.url, $scope.model.Guid).then(
					function() {
						repository.growl("Dataimport slettet", "success");
						$scope.goBack('dataImport.list', { menuGuid: $scope.navigation.params.menuGuid });
						swal.close();
					}
				);
			});
		};

		$scope.summaryStatusFilter = '';
		$scope.validationSummaryStatusFilter = '';
		$scope.rowStatuses = [
			{key: 'Created', value: 'Opprettet'},
			{key: 'Updated', value: 'Oppdatert'},
			{key: 'Error', value: 'Feilet'},
		];

		$scope.summaryFilter = function(status){
			return function(row){
				return !status || row.RowStatus === status;
			};
		};
	}
})();
