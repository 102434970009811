(function () {
	//Programatic scrolling to elements not using the entire window scroll
	angular.module('Plania').factory('ScrollService', ['$window', '$timeout', function ($window, $timeout) {
		var service = {};

		service.scrollToElement = function (elementId) {
			if (typeof (elementId) !== 'string') elementId = elementId.toString();
			$timeout(function () {
				var element = $window.document.getElementById(elementId);
				var parentScrollElement = $(element).parents().filter(function () {
					return $(this).css('overflow') === 'auto';
				}).first();

				if (parentScrollElement[0]){
					parentScrollElement[0].scrollTop = element.offsetTop;
				}
				else {
					// No containing element - scroll the window.
					window.scrollTo({
						behavior: 'smooth',
						top: element.offsetTop
					});
				}
			});
		};

		return service;
	}]);
})();
