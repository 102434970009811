(function () {
	angular.module('Plania').controller('ChangeIdModalController', ['$scope', '$modalInstance', 'params', controller]);

	function controller($scope, $modalInstance, params) {
		$scope.model = {
			id: params.id
		};
		$scope.save = function () {
			$modalInstance.close($scope.model.id);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
