(function () {
	angular.module('Plania').controller('MustChangePasswordController', ['$scope', '$state', 'Repository', 'authService', 'TranslationService', '$modal', controller]);
	function controller($scope, $state, repository, authService, translationService, $modal) {
		var params = $state.params;
		var returnToState;
		if (params)
			returnToState = params.returnToState;

		$modal.open({
			templateUrl: 'app/common/modal/views/changePasswordModal.html',
			controller: 'ChangePasswordModalController',
			size: 'sm',
			keyboard: false,
			resolve: {
				params: function () {
					return {
						user: {},
						mustChangePasswordProcess: true,
						saveOverride: function (viewModel, modalInstance) {
							repository.createSingle(repository.apiData.user.urlChangePassword, viewModel).then(function () {
								repository.growl('Passord har blitt oppdatert', 'success');

								if (returnToState)
									$state.go(returnToState.state, returnToState.params, returnToState.options);
								else
									$state.go('login');
							}, function (error) {
								repository.growl(error, 'danger');
							});
						}
					};
				}
			}
		});
	}
})();
