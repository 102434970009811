(function () {
    'use strict';

    angular.module('Plania')
        .directive('planiaVideoControls', [directive]);

    function directive() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
            	var timeoutId = 0;
            	timeoutId = setTimeout(function () {
			            $('.mejs-controls').addClass('hideControls');
		            },
		            3000);

	            var isMouseInsideVideoElement = false;
	            $('body').on('mousemove', function () {
	            	clearTimeout(timeoutId);
	            	if (isMouseInsideVideoElement) {
	            		$('.mejs-controls').removeClass('hideControls');
	            	}

	            	timeoutId = setTimeout(function () {
	            		$('.mejs-controls').addClass('hideControls');
	            	},
			            2500);
	            });

            	element.on('mouseenter', function () {
		            isMouseInsideVideoElement = true;
            		$('.mejs-controls').removeClass('hideControls');
            	});


            	element.on('mouseleave', function () {
            		isMouseInsideVideoElement = false;
            		$('.mejs-controls').addClass('hideControls');
            	});
            }
        };
    }
})();
