angular.module('Plania')
	// =========================================================================
	// INPUT FEILDS MODIFICATION
	// =========================================================================

	//Add blue animated border and remove with condition when focus and blur

	.directive('fgLine', function () {
		return {
			restrict: 'C',
			link: function (scope, element) {
				$(element[0]).on('focus', '.form-control', function () {
					$(this).closest('.fg-line').addClass('fg-toggled');
				});

				$(element[0]).on('blur', '.form-control', function () {
					var p = $(this).closest('.form-group');
					var i = p.find('.form-control').val();


					if (p.hasClass('fg-float')) {
						$(this).closest('.fg-line').removeClass('fg-toggled');

						if (i.length > 0) {
							$(this).closest('.fg-line').addClass('floated-label');
						} else {
							$(this).closest('.fg-line').removeClass('floated-label');
						}
					}
					else {
						$(this).closest('.fg-line').removeClass('fg-toggled');
					}
				});
			}
		};
	})



	// =========================================================================
	// AUTO SIZE TEXTAREA
	// =========================================================================
	// EDITED FROM INITIAL VERSION
	.directive('autoSize', function ($timeout) {
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: false,
			link: function (scope, element, attrs, ngModel) {
				$timeout(function () {
					autosize(element[0]);

					scope.$watch(function () {
						return ngModel.$modelValue;
					}, function (newValue, oldValue) {
						if (newValue === oldValue) return;
						autosize.update(element[0]);
					});

					$(element[0]).on('focus', function () {
						autosize.update(element[0]);
					});
				});
			}
		};
	})


	// =========================================================================
	// BOOTSTRAP SELECT
	// =========================================================================

	.directive('selectPicker', [
		function () {
			return {
				restrict: 'A',
				scope: { items: '=selectPickerData', selected: '=ngModel', onChange: '=selectPickerOnChange' },
				link: function (scope, element, attrs) {
					element.selectpicker();

					scope.$watch('items', function (newValue, oldValue) {
						element.selectpicker('refresh');
					}, true);

					scope.$watch('selected', function (newValue, oldValue) {
						if (scope.onChange) {
							if (typeof (scope.onChange) === "function") {
								scope.onChange(scope.selected);
							} else {
								scope.onChange = scope.selected;
							}
						}
						element.selectpicker('refresh');
					}, true);

				}
			};
		}
	])

	// =========================================================================
	// INPUT MASK
	// =========================================================================

	.directive('angularMask', function () {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function ($scope, el, attrs, model) {
				$scope.$watch(function () { return attrs.angularMask; }, function (value) {
					if (model.$viewValue !== null) {
						model.$viewValue = mask(String(model.$viewValue).replace(/\D/g, ''));
						el.val(model.$viewValue);
					}
				});

				model.$formatters.push(function (value) {
					return value === null ? '' : mask(String(value).replace(/\D/g, ''));
				});

				model.$parsers.push(function (value) {
					model.$viewValue = mask(value);
					var modelValue = $scope.isModelValueEqualViewValues ? model.$viewValue : String(value).replace(/\D/g, '');
					el.val(model.$viewValue);
					return modelValue;
				});

				function mask(val) {
					var format = attrs.angularMask,
						arrFormat = format.split('|');

					if (arrFormat.length > 1) {
						arrFormat.sort(function (a, b) {
							return a.length - b.length;
						});
					}

					if (val === null || val === '') {
						return '';
					}
					var value = String(val).replace(/\D/g, '');
					if (arrFormat.length > 1) {
						for (var a in arrFormat) {
							if (value.replace(/\D/g, '').length <= arrFormat[a].replace(/\D/g, '').length) {
								format = arrFormat[a];
								break;
							}
						}
					}
					var newValue = '';
					for (var nmI = 0, mI = 0; mI < format.length;) {
						if (!value[nmI]) {
							break;
						}
						if (format[mI].match(/\D/)) {
							newValue += format[mI];
						} else {
							newValue += value[nmI];
							nmI++;
						}
						mI++;
					}
					return newValue;
				}
			}
		};
	})


	// =========================================================================
	// COLOR PICKER
	// =========================================================================

	.directive('colordPicker', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				$(element).each(function () {
					var colorOutput = $(this).closest('.cp-container').find('.cp-value');
					$(this).farbtastic(colorOutput);
				});

			}
		};
	})

	// =========================================================================
	// PLACEHOLDER FOR IE 9 (on .form-control class)
	// =========================================================================

	.directive('formControl', function () {
		return {
			restrict: 'C',
			link: function (scope, element, attrs) {
				if (angular.element('html').hasClass('ie9')) {
					$('input, textarea').placeholder({
						customClass: 'ie9-placeholder'
					});
				}
			}

		};
	});
