(function () {
    angular.module('Plania').controller('InvoicingTermController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', 'ListService', controller]);

    function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, listService) {
        $scope.isUpdate = $scope.navigation.current.name === 'invoicingTerm.edit';
        $scope.model = {};


        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.InvoicingTerm, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.InvoicingTerm, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.InvoicingTerm);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.InvoicingTerm);
            }
        };

        if ($scope.isUpdate) {
            repository.getSingle(repository.apiData.invoicingTerm.url, $stateParams.guid).then(function (response) {
                $scope.model = response.Data;
                $scope.reload = true;
                updateAccess();

            }, function (error) {
                repository.growl(error, 'danger');
            }
            );
        }
        else {
            $scope.model.Type = 0;

        }

        $scope.update = function (destination) {
            var success = function (result) {
                repository.growl($scope.isUpdate ? translationService.translate('update-invoicing-term-success', 'Fakturering har blitt oppdatert.') : translationService.translate('create-invoicing-term-success', 'Fakturering har blitt opprettet.'), 'success');
                var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
                repository.commonService.setLastRegisterGuid(destination, guid);
                $scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
            };

            var error = function (error) {
                if (typeof (error) === "string") {
                    repository.growl(error, 'danger');
                } else {
                    repository.growl(error.Data.Message, 'danger');
                }
            };

            if ($scope.isUpdate) {
                repository.updateSingle(repository.apiData.invoicingTerm.url, $scope.model).then(success, error);
            } else {
                repository.createSingle(repository.apiData.invoicingTerm.url, $scope.model).then(success, error);
            }

        };

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-invoicingTerm-message', "Fakturering vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-invoicingTerm-button-confirm', 'Ja, fjern fakturering'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.invoicingTerm.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-invoicingTerm-success', 'Fakturering ble fjernet!'), result, "success");
						$scope.goBack('invoicingTerm.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

	}
})();
