(function () {
    angular.module('Plania').controller('CustomerLogController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', controller]);

    function controller($scope, $modalInstance, params, repository, translationService) {
        $scope.isUpdate = params.guid;
        $scope.header = $scope.isUpdate ? translationService.translate('customerLog-title-edit', 'Rediger log') : translationService.translate('customerLog-title-create', 'Legg til log');
        $scope.model = {};
        $scope.model.GuidCustomer = params.guidCustomer;
        //$scope.customerName = params.customerName;

        if ($scope.isUpdate) {
            repository.getSingle(repository.apiData.customerLog.url, params.guid).then(function (response) {
                var result = response.Data;
                $scope.model = result;
                setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
            });
		} else {
			$scope.model.LogDate = new Date().toISOString();
		}

        $scope.saveLog = function () {
            if ($scope.model.LogDate)
                $scope.model.LogDate = new Date($scope.model.LogDate).toISOString();
            if ($scope.isUpdate) {
                repository.updateSingle(repository.apiData.customerLog.url, $scope.model).then(function (result) {
                    repository.growl('Log har blitt oppdatert', 'success');
                    $modalInstance.close();
                });
            } else {
                repository.createSingle(repository.apiData.customerLog.url, $scope.model).then(function (result) {
                    repository.growl('Log har blitt lagret', 'success');
                    $modalInstance.close();
                }).catch(function (error) {
                    repository.growl(error, 'danger');
                });
            }
        };



        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };


    }
})();
