(function () {
	angular.module('Plania').controller('VideoPlayerModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'config', controller]);

	function controller($scope, $modalInstance, params, repository, config) {
		$scope.videoSrc = config.baseUrlApi + 'videodata/' + params.guidVideoBinary;
		$scope.video = params.video;

		$scope.loadMediaElement = function () {
			var player = new MediaElementPlayer('#videoPlayer', {});
			player.pause();
			player.setSrc($scope.videoSrc);
			player.features = [];
			player.load();
			player.play();
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
