(function () {
	angular.module('Plania').controller('ControlListCopyController', ['$scope', '$modalInstance', 'params', controller]);

	function controller($scope, $modalInstance, params) {
		$scope.model = {
			Color: params.model.Color,
			Icon: params.model.Icon,
			IsMandatory: params.model.IsMandatory,
			UseImage: params.model.UseImage,
			UseWithArea: params.model.UseWithArea,
			UseWithEquipment: params.model.UseWithEquipment,
			UseWithWorkOrder: params.model.UseWithWorkOrder,
			GuidReferenceTypeNotExecuted: params.model.GuidReferenceTypeNotExecuted,
			Conditions: params.model.Conditions,
		};

		$scope.ok = function () {
			$modalInstance.close($scope.model);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}
})();
