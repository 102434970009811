(function () {
	angular.module('Plania').controller('UpdateReportController', ['$scope', 'Repository', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, $modalInstance, translationService) {

		$scope.onFileSelect = function (file) {
			if (file.length > 0)
				$scope.file = file[0];
		};

		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		$scope.removeFile = function (index) {
			delete $scope.file;
		};

		$scope.uploadDocument = function (destination) {
			if ($scope.isUploading) return;
			if (!$scope.file) {
				swal(translationService.translate('web-report-error-noFiles', 'Ingen fil valgt'), translationService.translate('web-report-error-nofiledescription', 'Du må velge en fil for å laste opp'), 'info');
				return;
			}

			$scope.file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
			$scope.file.count = 0;
			$scope.file.progressType = null;

			var files = [];
			files.push($scope.file);
			$scope.isUploading = true;
			repository.createSingleWithBinary(repository.apiData.reportMetadata.url, {}, files).then(function (result) {
				$modalInstance.close("Oppdatert");
				$scope.isUploading = false;
			}, function (error) {
				repository.growl(error[0].error, 'danger');
				$scope.isUploading = false;
				$scope.file.progressType = 'danger';
			}, function (file) { //UPDATE
				$scope.file.total = file.total;
				$scope.file.count = file.count;
				$scope.file.progressType = file.count >= file.total ? 'success' : 'info';
			});
		};

		$scope.downloadReportFromPlania = function() {
			repository.updateSingle(repository.apiData.reportMetadata.url, { Guid: 'UpdateFromPlania' }).then(
				function(result) {
					$modalInstance.close("Oppdatert");
					$scope.isUploading = false;
				}, function (error) {
					repository.showError(error);
					$scope.isUploading = false;
				});
		};

		$scope.ok = function () {
			$modalInstance.close();
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};




		//	<dl class="dl-horizontal" ng-show="userInfo.isSystemAdministrator">
		//		<dt>
		//		<label>
		//		{{ 'Rapporter' | translate:'planiaSystem-label-reports'}}
		//</label>
		//	</dt>
		//	<dd>
		//	<a href="" ng-click="UpdateReports()">
		//	<span pl-translate="planiaSystem-report-update">Oppdater rapporter</span>
		//	</a>
		//	</dd>
		//	</dl>
	}
})();
