// Set focus on a element when a variable is changed
// To use it on an input element => set-focus="entityCommentSearch.focus" <- The variable to listen to for change, then change the variable to true
angular.module('Plania').directive('setFocus', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            var model = $parse(attrs.setFocus);
            scope.$watch(model, function (value) {
                if (value === true) {
                    $timeout(function () {
                        element[0].focus();
                    });
                }
            });
            element.bind('blur', function () {
                scope.$apply(model.assign(scope, false));
            });
        }
    };
}]);
