(function () {
	angular.module('Plania').controller('DwgController', ['$scope', 'Repository', '$stateParams', '$rootScope', '$location', '$timeout', 'SignalR', controller]);

	function controller($scope, repository, stateParams, $rootScope, $location, $timeout, signalR) {
        $scope.showNetAreal = true;
        $scope.guidDrawing = stateParams.guid;
        $scope.alertDays = 15;
        $scope.themeSelections = [];
		$scope.guidQualityControl = stateParams.guidQualityControl;
		$scope.guidArea = stateParams.guidArea;
		$scope.update = false;
		var urlParams = $location.search();

		var preparingUpdate = false;

        var menuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);

		if ($scope.userInfo.guidPerson) {
			repository.getSingle(repository.apiData.person.url, $scope.userInfo.guidPerson).then(function (result) {
				$scope.doesCleaningTasks = result.Data.DoesCleaningTasks;

				if ($scope.doesCleaningTasks) {
					$scope.isStaticTheme = true;
					$scope.selectedTheme = 'cleaning';

					$timeout(function () { $location.search('theme', null); $location.search('selectedTheme', $scope.selectedTheme).replace(); }, 150);
				}
			});
		}

		if (menuParams.selectedTheme || stateParams.selectedTheme || urlParams.selectedTheme) {
			$scope.isStaticTheme = true;
			$scope.selectedTheme = menuParams.selectedTheme ? menuParams.selectedTheme : stateParams.selectedTheme ? stateParams.selectedTheme : urlParams.selectedTheme;

			// Seems like the previous url is affected without an timeout.
			// Use timeout to replace the correct location.
			$timeout(function () { $location.search('selectedTheme', $scope.selectedTheme).replace(); }, 50);
		}
		else if (stateParams.theme) {
			$scope.selectedTheme = stateParams.theme;
			$timeout(function () { $location.search('theme', $scope.selectedTheme).replace(); }, 50);
			
		}
		else if (urlParams && urlParams.theme) {
			$scope.selectedTheme = urlParams.theme;
		}

		$scope.$watch('selectedTheme', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			$timeout(function () { $location.search('theme', newVal).replace(); }, 50);
			
		});

		var updateDwg = function () {
			if (preparingUpdate) return;
			preparingUpdate = true;

			$timeout(function () {
				if ($scope.selectedTheme === 'cleaning' || $scope.selectedTheme === 'cleaningMaintenance' || $scope.selectedTheme === 'request') {
					$scope.update = !$scope.update;
				}
				preparingUpdate = false;
			}, 500);

		};

		signalR.on('UpdatedAreaXCleaningTask' + $scope.guidDrawing, function () {
			updateDwg();
		});

		signalR.on('UpdatedCleaningCompletion' + $scope.guidDrawing, function () {
			updateDwg();
		});

		$scope.$on('$destroy', function () {
			signalR.off('UpdatedAreaXCleaningTask' + $scope.guidDrawing);
			signalR.off('UpdatedCleaningCompletion' + $scope.guidDrawing);
		});


        window.scrollTo(0,0);
    }
})();
