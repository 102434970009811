(function () {
	angular.module('Plania').controller('EditHelpTextModalController', ['$scope', 'Repository', 'TranslationService', 'params', '$modalInstance', controller]);

	function controller($scope, repository, translationService, params, $modalInstance) {
		$scope.model = angular.copy(params.languageXWebText);

		$scope.systemName = params.systemName;

		if ($scope.model.TranslatedHelpText)
			$scope.HelpTextHtml = $scope.model.TranslatedHelpText;
		else
			$scope.HelpTextHtml = $scope.model.DefaultTranslatedHelpText;


		$scope.save = function () {
			$scope.model.TranslatedHelpText = $scope.HelpTextHtml;
			repository.updateSingle(repository.apiData.translate.url, $scope.model).then(function (result) {
				repository.growl("Hjelpetekst oppdatert", 'success');
				translationService.setHelpTextEntry(($scope.model.Language.Locale + params.systemName).toLowerCase(), !!($scope.model.TranslatedHelpText || $scope.model.DefaultTranslatedHelpText));
				$modalInstance.close(result);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.setEmpty = function () {
			$scope.HelpTextHtml = "";
		};

		$scope.tinymceOptions = {
			language: 'nb_NO',
			branding: false,
			plugins: [
				'autolink link autoresize textcolor'
			],
			menubar: 'edit insert format',
			toolbar: 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright lineheightselect | bullist numlist outdent indent ',
			image_advtab: false,
			relative_urls: false,
			convert_urls: false,
			remove_script_host: false,
			paste_data_images: false,
			autoresize_min_height: 400,
			autoresize_max_height: 600,
			forced_root_block: false,
			lineheight_formats: "14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
			inline_styles: true,
			font_formats: 'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Calibri=calibri;Comic Sans MS=comic sans ms,sans-serif;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;'
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
