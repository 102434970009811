(function () {
	var app = angular.module('Plania');
	app.directive('areaInfoDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				reloads: '=reloads',
				reloadDocumentTable: '=reloadDocumentTable',
				update: '=update',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				formReference: '=',
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', 'AreaService', '$localStorage', controller],
			templateUrl: 'app/area/views/areaInfo.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout, areaService, $localStorage) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.userInfo = $rootScope.userInfo;
		$scope.customization = $rootScope.customization;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.imageApiUrl = $rootScope.imageApiUrl;
		$scope.navigation = $rootScope.navigation;

		$scope.isDynamicPropertiesCollapsed = false;
		$scope.isDocumentsCollapsed = true;
		$scope.viewMode = $scope.update;
		$scope.hasPageLoaded = $scope.currentTab === 'info';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'info' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		areaService.enrichScopeWithFormFunctions($scope);
		
		if ($scope.update) {
			$scope.isPaused = function (task) {
				var today = moment().startOf('day');
				return task.PausedFromDate && task.PausedToDate && today.isBetween(task.PausedFromDate, task.PausedToDate, 'days', '[]');
			};

			$scope.getPropertyValue = function (item, column) {
				if (item.Prefix === repository.commonService.prefix.AreaXCleaningTask && column.Property === "IsPaused") {
					return $scope.isPaused(item) ? translationService.translate('web-areaxcleaningtask-pausedStatusColumn-paused', 'Pauset') : translationService.translate('web-areaxcleaningtask-pausedStatusColumn-active', 'Aktiv');
				}
				return listService.GetPropertyValue(item, column);
			};

			$scope.personColumns = [
				{ Position: 1, Title: translationService.translate('web-person-firstname'), Property: 'FirstName', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-person-lastname'), Property: 'LastName', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-person-email'), Property: 'Email', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-person-position'), Property: 'Position', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-person-telephonework'), Property: 'TelephoneWork', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-person-cellphone'), Property: 'Cellphone', PropertyType: 'string' }
			];

			$scope.cleaningTaskInfo = new function () {
				var me = this;
				this.isLoading = false;

				this.columns = [
					//Start with 2, since we might dynmaically add a column dependent on cleaning status.
					{ Position: 2, Title: translationService.translate('web-areaxcleaningtask-cleaningTask-id', 'Renholdsoppgave'), Property: 'CleaningTask.Id', PropertyType: 'string' },
					{ Position: 3, Title: translationService.translate('web-areaxcleaningtask-ResourceGroup-id', 'Renholdsteam'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
					{ Position: 4, Title: translationService.translate('web-areaxcleaningtask-AverageCleaningPerYear', 'Minutter neste år'), Property: 'AverageCleaningPerYear', PropertyType: 'number' },
					{ Position: 5, Title: translationService.translate('web-areaxcleaningtask-Recurrence', 'Gjentakelsesregel'), Property: 'Recurrence', PropertyType: 'recurrence' },
					{ Position: 6, Title: translationService.translate('web-areaxcleaningtask-NextCleaningDate', 'Utføres'), Property: 'NextCleaningDate', PropertyType: 'date' }
				];
				
				this.initTable = function () {
					if (me.table) return;
					me.table = new ngTableParams(
						{
							page: 1,
							count: 10,
							sorting: { 'CleaningTask.Id': 'asc' },
							filter: {
								PropertyFilter: [{ Property: 'GuidArea', Operator: '=', Value: $scope.model.Guid }],
								CleaningStatus: 'Undefined'
							}
						},
						{
							total: 0,
							counts: [10, 20, 50],
							filterDelay: 10,
							paginationMaxBlocks: 6,
							getData: function ($defer, params) {
								me.isLoading = true;

								var columns = [];
								me.columns.forEach(function (col) {
									if (col.Property !== "IsPaused")
										columns.push(col.Property);	
								});

								columns.push('PausedFromDate');
								columns.push('PausedToDate');

								repository.GetPaginated(repository.apiData.areaXCleaningTask.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
									me.isLoading = false;
									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;

									// Add or hide IsPaused column dependent on if there are any tasks that are paused.
									var hasPausedTask = false;
									result.List.forEach(function (task) {
										if ($scope.isPaused(task))
											hasPausedTask = true;
									});

									if (hasPausedTask) {
										if (me.columns[0].Property !== "IsPaused") {
											me.columns.unshift({ Position: 1, SortingDisabled: true, Title: translationService.translate('web-areaxcleaningtask-pausedStatusColumn', 'Status'), Property: 'IsPaused' });
										}
									} else {
										if (me.columns[0].Property === "IsPaused") {
											me.columns.shift();
										}
									}

									$defer.resolve(result.List);
								}, function (error) {
									me.isLoading = false;
									repository.showError(error);
								});
							}
						});
				};

				this.add = function () {
					var modalInstance = $modal.open({
						templateUrl: 'app/cleaningTask/views/cleaningTaskWizardModal.html',
						controller: 'CleaningTaskWizardModalController',
						resolve: {
							modalParams: function () {
								return {
									columns: [
										{ Title: translationService.translate('web-cleaningTask-id'), Property: 'Id' },
										{ Title: translationService.translate('web-CleaningTask-estimatedtime'), Property: 'EstimatedTime' }
									],
									url: repository.apiData.cleaningTask.url,
									sorting: { Id: 'asc' },
									filter: {},
									area: $scope.model
								};
							}
						}
					});
					
					modalInstance.result.then(function (result) {
						var cleaningTask = result.cleaningTask, recurrenceRule = result.recurrenceRule, attributeValues = result.attributeValues;

						var areaXCleaningTasks = [{
							GuidCleaningTask: cleaningTask.Guid,
							RecurrenceRule: recurrenceRule,
							GuidArea: $scope.model.Guid,
							AttributeValues: attributeValues
						}];

						repository.createMultiple(repository.apiData.areaXCleaningTask.url, areaXCleaningTasks).then(function (result) {
							me.table.reload();
						}, function (error) {
							repository.showError(error);
						});
					});
				};

				this.update = function (item) {
					if (!me.canEdit(item)) return;

					$modal.open({
						templateUrl: 'app/area/views/EditAreaXCleaningTaskModal.html',
						controller: 'AreaXCleaningTaskController',
						resolve: {
							modalParams: function () {
								return { Guid: item.Guid };
							}
						}
					}).result.then(function (result) {
						repository.growl(result, 'info');
						me.table.reload();
					});
				};

				this.remove = function (item) {
					if (!me.canDelete(item)) return;
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-area-cleaningtask-message', "Renholdsoppgaven vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-area-cleaningtask-button-confirm', 'Ja, fjern renholdsoppgaven'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;

						repository.deleteSingle(repository.apiData.areaXCleaningTask.url, '', 'guids=' + JSON.stringify([item.Guid])).then(function (result) {
							swal(translationService.translate('web-swal-area-cleaningTask-success', 'Renholdsoppgaven ble fjernet!'), result, "success");
							repository.growl('Renholdsoppgaven har blitt fjernet', 'success');
							me.table.reload();
						});
					});
				};

				this.pause = function (item) {
					$modal.open({
						templateUrl: 'app/area/views/editAreaXCleaningTaskStartDateModal.html',
						controller: 'AreaXCleaningTaskController',
						size: 'md',
						resolve: {
							modalParams: function () {
								return {
									Guid: item.Guid,
									SuggestedPause: {
										From: new Date(),
										To: new Date().setDate(new Date().getDate() + 1),
									}
								};
							}
						}
					}).result.then(function (result) {
						repository.growl(result, 'info');
						me.table.reload();
					});
				};

				this.contextMenuOptions = function (item) {
					var options = [];

					if (item.CleaningTask && item.CleaningTask.CleaningType !== 'ExitCleaning' && item.CleaningTask.CleaningType !== 'InfectionCleaning') {
						var text = $scope.isPaused(item) ?
							translationService.translate('web-areaXCleaningTask-cleaningTaskMenuOptions-setCleaningPauseDate-isPaused', 'Start renhold') :
							translationService.translate('web-areaXCleaningTask-cleaningTaskMenuOptions-setCleaningPauseDate-isNotPaused', 'Sett renhold på pause');

						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""> ' + text + '</a >',
							click: function () {
								me.pause(item);
							}
						});
					}

					if (me.canEdit(item)) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""> ' + translationService.translate('web-areaXCleaningTask-cleaningTaskMenuOptions-edit', 'Rediger') + '</a >',
							click: function () {
								me.update(item);
							}
						});
					}

					if (me.canDelete(item)) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""> ' + translationService.translate('web-areaXCleaningTask-cleaningTaskMenuOptions-delete', 'Slett') + '</a >',
							click: function () {
								me.remove(item);
							}
						});
					}
					
					return options;
				};

				this.canEdit = function (item) {
					return item &&
						$scope.hasEditAccess('AreaXCleaningTask');
				};

				this.canDelete = function (item) {
					return item &&
						$scope.hasDeleteAccess('AreaXCleaningTask');
				};

				me.initTable();
			}();

			$scope.addPerson = function () {
				var filter = {};
				var columns = [];

				columns.push({ Title: translationService.translate('web-area-person-firstName', 'Fornavn'), Property: 'FirstName' });
				columns.push({ Title: translationService.translate('web-area-person-lastName', 'Etternavn'), Property: 'LastName' });

				columns.push({ Title: translationService.translate('web-area-person-area-id', 'Areal Id'), Property: 'Area.Id' });
				columns.push({ Title: translationService.translate('web-area-person-area-description', 'Arealbeskrivelse'), Property: 'Area.Description' });

				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					resolve: {
						modalParams: function () {
							return {
								title: 'Velg personer',
								columns: columns,
								useObjects: false,
								url: repository.apiData.person.url,
								sorting: { FirstName: 'asc' },
								filter: filter
							};
						}
					}
				}).result.then(function (selectedEntities) {
					if (!selectedEntities) return;

					var changeList = [];
					selectedEntities.forEach(function (entity) {
						changeList.push({ Guid: entity, GuidArea: $scope.model.Guid });
					});

					repository.patch(repository.apiData.person.url, null, changeList).then(function (response) {
						var success = _.every(response, function (response) {
							return response.IsSuccess;
						});

						if (success)
							repository.growl(translationService.translate('web-area-person-connection-success', 'Personene har blitt lagt til arealet'), "success");
						else
							repository.growl(translationService.translate('web-area-person-connection-failed', 'En eller flere personer ble ikke lagt til arealet'), "danger");
						$scope.reloads.persons = !$scope.reloads.persons;
					}, function (error) {
						repository.showError(error);
					});
				});
			};

			$scope.$watchCollection('model.Images', function () {
				if ($scope.model && $scope.model.Images && Array.isArray($scope.model.Images)) {
					var defaultImage = $scope.model.Images.find(function (img) {
						return img.IsDefault;
					});
					if (defaultImage)
						$scope.model.GuidDefaultImage = defaultImage.Guid;
					else
						delete $scope.model.GuidDefaultImage;
				}
			});
		}
	}
})();
