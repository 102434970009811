(function () {
	angular.module('Plania').controller('EquipmentDataAcquisitionController', ['$scope', 'Repository', 'ngTableParams', controller]);

	function controller($scope, repository, ngTableParams) {
		$scope.model = {};
		
		function initDataAcquisitionTable(resultRows) {
			var rows = [];
			for (var i = 0; i < resultRows.length; i++) {
				var row = newRow(resultRows[i]);
				rows.push(row);
			}
			$scope.vm = {
				rows: rows
			};
		}

		function newRow(item) {
			var row = {
				id: item.Id,
				description: item.Description,
				guidEquipment: item.GuidEquipment,
				dataAcquisitionStatus: item.DataAcquisitionStatus
		};
			row = addDocumentationContexts(row, item);
			return row;
		}

		function addDocumentationContexts(row, item) {
			var fulfilledItems = _.intersection(item.RequiredDocumentationContexts, item.DocumentationContexts);
			_.forEach(fulfilledItems, function (fulfilledItem) {
				row[fulfilledItem] = { fulfilledClass: 'success', checked: true };
			});

			var missingRows = _.difference(item.RequiredDocumentationContexts, item.DocumentationContexts);
			_.forEach(missingRows, function (missingItem) {
				row[missingItem] = { fulfilledClass: 'danger', checked: false };
			});

			var surplusRows = _.difference(item.DocumentationContexts, item.RequiredDocumentationContexts);
			_.forEach(surplusRows, function (surplusItem) {
				row[surplusItem] = { fulfilledClass: '', checked: true };
			});
			return row;
		}

		$scope.onRowClicked = function (row) {
			$scope.navigation.go('equipment.edit', { guid: row.guidEquipment }, { reload: true });
		};

		$scope.$watch('selectedItem', function (newValue, oldValue) {
			if (!newValue) return;
			if (oldValue === newValue) return;

			switch (newValue.Prefix) {
				case 'Building':
					repository.getSingleWithNoParameter(repository.apiData.equipment.url + 'dataacquisition/' + newValue.Prefix + '/' + newValue.Guid)
						.then(function (result) {
							initDataAcquisitionTable(result.Rows);
						});
					break;
				case 'Equipment':
					repository.getSingleWithNoParameter(repository.apiData.equipment.url + 'dataacquisition/' + newValue.Prefix + '/' + newValue.Guid)
						.then(function (result) {
							initDataAcquisitionTable(result.Rows);
						});
					break;
			}
		});

		$scope.items = new ngTableParams({
				page: 1,
				count: 20,
				sorting: {},
				filter: {}
			},
			{
				total: 0,
				counts: [20, 50, 100, 200],
				filterDelay: 10,
				paginationMaxBlocks: 8,
				getData: function ($defer, params) {
					$defer.promise.id = Math.random().toString(36).substr(2);
					items.newestPromise = $defer.promise;

					repository.GetPaginated(repository.apiData.equipment.url, params.page() - 1, params.count(), {}, {}, null, JSON.stringify(['Id', 'Description'])).then(
						function (result) {
							if (items.newestPromise.id !== $defer.promise.id) {
								$defer.reject('not the most recent promise, ignoring result');
								return;
							}

							items.settings().total = result.TotalCount;
							items.settings().filterDelay = 500;

							if (result.List.length === 0 && result.TotalCount > 0) {
								items.page(1);
							}

							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});
	}
})();
