(function () {
	angular.module('Plania').controller('CreateBuildingXBimFileModalController', ['$scope', '$modalInstance', 'Repository', 'params', 'ListService', 'TranslationService', 'ngTableParams', controller]);

	function controller($scope, $modalInstance, repository, params, listService, translationService, ngTableParams) {
		var guidBimFile = params.guidBimFile;
		var guidBimProject = params.guidBimProject;

		$scope.selectedItems = [];
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.addSelectedItem = function (object) {
			if (!$scope.isItemSelected(object)) {
				$scope.selectedItems.push(object);
			} else {
				for (var i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i].Guid === object.Guid) {
						$scope.selectedItems.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isItemSelected = function (object) {
			return _.find($scope.selectedItems, function (o) { return o.Guid === object.Guid; });
		};

		$scope.buildingColumns = [
			{ Position: 1, Title: translationService.translate('web-createBuildingXBimFileModal-buildingTable-id', 'Id'), Property: "Id", PropertyType: "string" },
			{ Position: 2, Title: translationService.translate('web-createBuildingXBimFileModal-buildingTable-description', 'Beskrivelse'), Property: "Description", PropertyType: "string" },
			{ Position: 3, Title: translationService.translate('web-createBuildingXBimFileModal-buildingTable-buildingCategory', 'Kategori'), Property: "BuildingCategory.Description", PropertyType: "string" },
		];

		$scope.buildingTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: "asc" },
			filter: {
				GuidBimFileAlreadyConnected: guidBimFile,
				FilterModel: {
					Condition: "and",
					Rules: [
						{
							Property: "IsTemplate",
							Operator: "=",
							Value: false,
						},
						{
							Property: "Estate.GuidBimProject",
							Operator: "=",
							Value: guidBimProject
						}
					],
				}
			}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var columns = _.map($scope.buildingColumns, 'Property');

					repository.GetPaginated(repository.apiData.building.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
						$scope.buildingTable.settings().total = result.TotalCount;
						$scope.buildingTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.showError(error, 'danger');
					});
				}
			});

		$scope.connectToSelectedBuildings = function () {
			var buildingXBimFiles = [];

			$scope.selectedItems.forEach(function (building) {
				buildingXBimFiles.push({
					GuidBuilding: building.Guid,
					GuidBimFile: guidBimFile
				});
			});

			if (!buildingXBimFiles.length)
				return;

			repository.createMultiple(repository.apiData.buildingXBimFile.url, buildingXBimFiles).then(function () {
				repository.growl(translationService.translate('web-createBimFileModal-connectToSelectedBuildings-success', 'Bim filer er knyttet til valgte bygg'), "success");
				$modalInstance.close();
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
