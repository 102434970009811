(function () {
	angular.module('Plania').controller('HousingContractController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', '$localStorage', 'ListService', '$sce', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, $localStorage, listService) {
		$scope.isUpdate = RegExp("edit$").test($scope.navigation.current.name);
		$scope.showCustomer = true;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.validCharacters = '^(?!.*<[a-zA-ZæøåÆØÅ]+).*';

		$scope.isDynamicPropertiesCollapsed = false;
		$scope.isDocumentsCollapsed = true;
		$scope.reloadDocumentTable = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Contract, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Contract, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Contract);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Contract);
			}
		};

		updateAccess();

		var contractColumns = [
			'Invoicing.Id',
			'Department.Id', 'Department.Description',
			'ContractCategory.Description',
			'ContractAdjustment.ExplanatoryText', 'ContractAdjustment.PeriodicityNumber', 'ContractAdjustment.PeriodicityUnit', 'ContractAdjustment.AdjustmentDate',
			'ContractAdjustment.Adjustment.Id',
			'PaymentTerm.Id', 'PaymentOrderForm.Id', 'PeriodOfNotice.Id', 'Invoicing.Id',
			'Customer.Description', 'Customer.Telephone', 'Customer.Email', 'Customer.PostalAddress', 'Customer.StreetAddress', 'Customer.PostalCode', 'Customer.PostalArea', 'Customer.Country', 'Customer.CellPhone',
			'Area.Id', 'Area.Description', 'Area.Address',
			'Area.Building.PostalCode', 'Area.Building.PostalArea', 'Area.Building.Country',
			'Area.AreaCategory.Description'
		];

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.contract.url, $stateParams.guid, JSON.stringify(contractColumns)).then(
				function (response) {
					var responseData = response.Data;
					$scope.model = responseData;
					//$scope.showDynamicProperties = $scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0;
					$scope.reloadDocumentTable = true;
					updateAccess();
					$scope.newDynamicProperty = $scope.model.DynamicProperty;

					$scope.reload = true;
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		}
		else {
			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('contract.create');

				if (persistentData) {
					$scope.model = persistentData.model || {};

				}
				$scope.model.RentalType = 2;
			}
			$scope.activateAutocomplete = true;
			$scope.model.RentalType = 2;
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? "Kontrakt ble oppdatert" : 'Kontrakt ble lagret', 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				if (destination === 'housingContract.create') {
					$scope.navigation.go(destination, { reload: true });
				} else {
					repository.persistedData.clearPersistedData();
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.contract.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingle(repository.apiData.contract.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.onContractCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidContractCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidContractCategory = category.Guid;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "contractCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
		};

		$scope.cancel = function () {
			$scope.goBack('housingContract.list', { menuGuid: $scope.navigation.params.menuGuid });
		};
	}
})();
