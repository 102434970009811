(function () {
	angular.module('Plania').controller('WorkOrderXResourceGroupController', ['$scope', '$modalInstance', 'params', 'Repository', 'RegisteredFieldService', 'WorkOrderService', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, repository, registeredFieldService, workOrderService, translationService) {
		$scope.isEdit = !!params.GuidWorkOrderXResourceGroup;
		$scope.isLoaded = false;
		$scope.projectHourTypeCount = 0;
		$scope.model = { HourType: {} };
		$scope.restrictEdit = false;
		$scope.restrictEditMessage = '';

		$scope.activateAutocomplete = false;

		var costPrHour = 0;

		var setCost = function (newEstimatedTime, newRealTime) {
			if (!newEstimatedTime)
				newEstimatedTime = $scope.model.EstimatedTime;
			if (!newRealTime)
				newRealTime = $scope.model.RealTime;

			if ($scope.model.HourType && $scope.model.HourType.PriceType === 'FixedAmount') {
				costPrHour = $scope.model.HourType.Price;
			} else if ($scope.model.HourType && $scope.model.HourType.PriceType === 'AddedAmount') {
				costPrHour = $scope.model.ResourceGroup.CostPrHour + $scope.model.HourType.Price;
			} else if ($scope.model.HourType && $scope.model.HourType.PriceType === 'AddedFactor') {
				var cost = $scope.model.ResourceGroup.CostPrHour;
				costPrHour = cost + (cost * $scope.model.HourType.Price / 100);
			}

			if (newEstimatedTime)
				$scope.model.EstimatedCost = costPrHour * newEstimatedTime;
			else
				$scope.model.EstimatedCost = 0;

			if (newRealTime)
				$scope.model.RealCost = costPrHour * newRealTime;
			else
				$scope.model.RealCost = 0;
		};


		$scope.onResourceGroupSelect = function (resourceGroup) {
			if (!resourceGroup) {
				costPrHour = 0;
				$scope.model.EstimatedCost = 0;
				$scope.model.RealCost = 0;
				$scope.model.GuidResourceGroup = "";
				$scope.resourceGroup = null;
				$scope.onPersonSelect(null);
				return;
			}
			$scope.model.GuidResourceGroup = resourceGroup.Guid;
			$scope.model.ResourceGroup = resourceGroup;

			if (resourceGroup.CostPrHour || resourceGroup.CostPrHour === 0) {
				costPrHour = resourceGroup.CostPrHour;
				setCost();
			}
		};

		$scope.onPersonSelect = function (person) {
			if (!person) {
				$scope.model.GuidPerson = "";
				$scope.model.GuidPersonResourceGroup = null;
				$scope.person = null;
				return;
			}
			$scope.model.GuidPerson = person.Guid;
			$scope.model.GuidPersonResourceGroup = person.GuidResourceGroup;
			$scope.model.Person = person;
			if (person.GuidResourceGroup) {
				$scope.onResourceGroupSelect({
					Guid: person.GuidResourceGroup,
					Caption: person.ResourceGroup.Caption,
					CostPrHour: person.ResourceGroup.CostPrHour
				});
			}
		};

		$scope.onHourTypeSelect = function (hourType) {
			if (!hourType) {
				$scope.model.GuidHourType = '';
				$scope.model.HourType.Caption = null;
			} else {
				$scope.model.GuidHourType = hourType.Guid;
				$scope.model.HourType = hourType;
			}
			setCost();
		};

		var columns = [
			'ResourceGroup.Id', 'ResourceGroup.Description', 'ResourceGroup.CostPrHour',
			'Person.FirstName', 'Person.LastName', 'HourType.Id', 'HourType.Description', 'HourType.PriceType', 'HourType.Price',
			'WorkOrder.Stage', 'WorkOrder.EndDate', 'WorkOrder.GuidProject', 'WorkOrder.Project.ClosedDate',
			'UserUpdatedBy.RealName'
		];

		if (params.GuidWorkOrderXResourceGroup) {
			repository.getSingle(repository.apiData.workOrderXResourceGroup.url, params.GuidWorkOrderXResourceGroup, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;
				if (!$scope.model.HourType)
					$scope.model.HourType = {};
				if ($scope.model.ResourceGroup)
					costPrHour = $scope.model.ResourceGroup.CostPrHour;

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				$scope.isLoaded = true;

				if ($scope.isEdit && !$scope.restrictEdit) {
					if ($scope.model.WorkOrder && $scope.model.WorkOrder.Stage === 'History') {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-workOrderXResourceGroup-restrictEditMessage-woIsHistory', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne ressursen er koblet til er hisorisk.');
					} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.EndDate) {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-workOrderXResourceGroup-restrictEditMessage-woHasEndDate', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne ressursen er koblet til er fullført.');
					} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.Project && $scope.model.WorkOrder.Stage === 'Project' && $scope.model.WorkOrder.Project.ClosedDate) {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-workOrderXResourceGroup-restrictEditMessage-projectIsCompleted', 'Redigering og sletting er ikke tilgjengelig når prosjektet via tilknyttet arbeidsordre er fullført.');
					} else if ($scope.model.IsInvoiced) {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-workOrderXResourceGroup-restrictEditMessage-isInvoiced', 'Redigering og sletting er ikke tilgjengelig fordi denne ressursen er fakturert.');
					}
				}
			},	function (error) {
				repository.growl(error, 'danger');
			});
		} else {
			if (params.GuidWorkOrder) $scope.model.GuidWorkOrder = params.GuidWorkOrder;
			if (params.GuidProject) {
				$scope.model.WorkOrder = {GuidProject: params.GuidProject};
			}
			if (params.ResourceGroup) {
				$scope.onResourceGroupSelect(params.ResourceGroup);
			}

			$scope.model.RegisteredDate = new Date();
			$scope.activateAutocomplete = true;
			$scope.isLoaded = true;
		}

		$scope.saveResource = function () {
			$scope.model.RegisteredDate = typeof $scope.model.RegisteredDate === "string" ? new Date($scope.model.RegisteredDate) : $scope.model.RegisteredDate.toISOString();

			var sendModel = angular.copy($scope.model);
			delete sendModel.WorkOrder;
			delete sendModel.Person;
			delete sendModel.ResourceGroup;

			var hasActivatedWorkOrder = false;

			var errorCallback = function (error) {
				if (error && workOrderService.isWorkOrderStage0(error)) {
					workOrderService.shouldActivateWorkOrder($scope.model.GuidWorkOrder, error)
						.then(function (result) {
							hasActivatedWorkOrder = true;
							if (sendModel.Guid)
								updateResource();
							else
								createResource();
						}, function (error) {
							if (error) {
								repository.growl(error, 'danger');
							}
						});
				}
				else
					repository.growl(error.Message || error, 'danger');
			};

			var createResource = function () {
				return repository.createSingle(repository.apiData.workOrderXResourceGroup.url, sendModel).then(function (result) {
					repository.growl('Ressurs har blitt knyttet til arbeidsordre', 'success');
					$modalInstance.close({ realCost: $scope.model.RealCost, estimatedCost: $scope.model.EstimatedCost, realTime: $scope.model.RealTime, estimatedTime: $scope.model.EstimatedTime });
					if (hasActivatedWorkOrder)
						$scope.goBack('workOrder.edit', { guid: $scope.model.GuidWorkOrder, menuGuid: null });
				}, errorCallback);
			};

			var updateResource = function () {
				repository.updateSingle(repository.apiData.workOrderXResourceGroup.url, sendModel).then(function (result) {
					repository.growl('Ressurs oppdatert', 'success');
					$modalInstance.close({ realCost: $scope.model.RealCost, estimatedCost: $scope.model.EstimatedCost, realTime: $scope.model.RealTime, estimatedTime: $scope.model.EstimatedTime });
					if (hasActivatedWorkOrder)
						$scope.goBack('workOrder.edit', { guid: $scope.model.GuidWorkOrder, menuGuid: null });
				}, errorCallback);
			};

			if (sendModel.Guid) {
				updateResource();
			} else {
				createResource();
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		var getProjectHourTypeCount = function () {
			var hourTypeColumns = ['Guid'];
			repository.GetPaginated(repository.apiData.hourType.url, 0, 10, { 'HourType.Guid': 'asc' }, { PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.WorkOrder.GuidProject }] }, null, JSON.stringify(hourTypeColumns)).then(function (result) {
				$scope.projectHourTypeCount = result.TotalCount;
			});
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "person":
					return {
						GuidResourceGroup: $scope.model.GuidResourceGroup,
						DoesMaintenanceTasks: true,
						PropertyFilter: [{ Property: 'ResourceGroup.DoesMaintenanceTasks', Operator: '=', Value: '1' }]
					};
				case "resourceGroup":
					return { PropertyFilter: [{ Property: 'DoesMaintenanceTasks', Operator: '=', Value: '1' }] };
				case "hourType":
					var filter =  {PropertyFilter:[]};

					if($scope.model.WorkOrder && $scope.model.WorkOrder.GuidProject){
						filter.GuidProject = $scope.model.WorkOrder.GuidProject;
						filter.InResourceRegistration = true;
					}
					else{
						filter.PropertyFilter.push({ Property: 'GuidProject', Value: "", Operator: '=' });
					}
					return filter;
			}
		};

		$scope.$watch('model.EstimatedTime', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			setCost();
		}, true);

		$scope.$watch('model.RealTime', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			setCost();
		}, true);

		$scope.$watch('model.GuidPersonResourceGroup', function (newValue, oldValue) {
			if (!newValue) {
				$scope.disableResourceGroupSelection = false;
			} else {
				$scope.disableResourceGroupSelection = true;
			}
		}, true);
	}

})();
