(function () {

	var app = angular.module('Plania');

	app.directive('conditionDirective', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				conditions: '=conditions',
				estate: '=estate',
				building: '=building',
				area: '=area',
				equipment: '=equipment',
				parentEntity: '=parentEntity',
				guid: '=guid',
				restrictEdit: "=restrictEdit",
				useList: '=useList', //Set to true if using directive in small width container
				inline: '=inline', //Set to true if used inline in modal
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', '$localStorage', 'ListService', 'Constants', '$q', 'CommonService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/condition/views/conditionDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, $localStorage, listService, constants, $q, commonService) {
		var isSVV = $localStorage.generalOptions.CustomerId === 'SvvTunnel' || $localStorage.generalOptions.CustomerId === 'Fylkeskommuner';
		$scope.conditionsIsLoading = true;
		$scope.search = {};
		var contextId = $scope.parentEntity ? $scope.parentEntity + '-Condition' : null;

		$scope.hasEditAccess = $rootScope.hasEditAccess('Condition');

		$scope.$watch('reload', function (newValue, oldValue) {
			if ($scope.reload && $scope.reload === true) {
				if ($scope.conditionTable) {
					$scope.conditionTable.reload();
				}
				$scope.reload = false;
			}
		});

		$scope.defaultListSetup = {
			Id: 'WorkOrderCondition',
			Sorting: { 'Id': 'desc' },
			EntityType: repository.apiData.condition.prefix,
			ContextId: contextId,
			IsPublicView: true,
			Columns: [
				{ Position: 1, Title: translationService.translate('web-condition-id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-conditionType-id'), Property: 'ConditionType.Id', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-consequence-id'), Property: 'Consequence.Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-condition-conditiondate', 'Tilstandsdato'), Property: 'ConditionDate', PropertyType: 'date' },
				{ Position: 5, Title: translationService.translate('web-condition-actioncomment-measures', 'Tiltak'), Property: 'ActionComment', PropertyType: 'string' },
			]
		};

		$scope.menuOptions = function (condition) {
			var options = [];

			if ($scope.hasEditAccess) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-workorder-condition-edit', 'Rediger tilstand') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.createOrUpdate(condition);
					}
				});
			}

			if ($scope.hasEditAccess && $scope.hasAvailableDefaultCondition) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-check fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-workorder-condition-approve', 'Godkjenn tilstand') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.approveCondition(condition);
					}
				});
			}

			if ($scope.hasEditAccess && $scope.canCloseCorrectiveAction(condition)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-square-down fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-button-close-correctiveAction', 'Lukk tiltak') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.closeCorrectiveAction(condition);
					}
				});
			}

			if ($rootScope.hasDeleteAccess('Condition')) {
				options.push({
					enabled: !condition.ActionComment,
					hasTopDivider: true,
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.remove(condition);
					}
				});
			}

			return options;
		};

		var originalListSetup = angular.copy($scope.defaultListSetup);

		$scope.columns = [
			'Estate.Id', 'Estate.Description',
			'Building.Id', 'Building.Description',
			'Area.Id', 'Area.Description',
			'Equipment.Id', 'Equipment.Description'
		];

		var hasAccessToCreateNewWebList = function () {
			return $scope.$root.userInfo.isSystemAdministrator &&
				repository.authService.hasCreateAccess(repository.commonService.prefix.WebListView, false);
		};

		var getModelForChange = function () {
			var modelCopy = _.cloneDeep($scope.defaultListSetup);

			modelCopy.WebColumnsToSynchronize = modelCopy.Columns;
			modelCopy.Columns = [];
			delete modelCopy.VisibleColumns;

			for (var i = 0; i < modelCopy.WebColumnsToSynchronize.length; i++) {
				var filter = modelCopy.WebColumnsToSynchronize[i].Filter;
				if (filter) {
					filter.forEach(handleDateFilter);
					modelCopy.WebColumnsToSynchronize[i].Filter = JSON.stringify(filter);
				}

				modelCopy.WebColumnsToSynchronize[i].Position = i + 1;
			}

			return modelCopy;
		};

		var handleDateFilter = function (filter) {
			if (filter.Property.indexOf("Date") >= 0 && filter.Value && Object.prototype.toString.call(filter.Value) === '[object Date]')
				filter.Value = new Date(filter.Value).toISOString();
		};

		repository.GetPaginated(repository.apiData.webListView.url, 0, 10, {}, { Prefix: repository.apiData.condition.prefix, ContextId: contextId }).then(function (result) {
			var guidCurrentDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
			var webListCurrentDataOwner = _.find(result.List, function (x) { return x.GuidDataOwner === guidCurrentDataOwner; });

			if (webListCurrentDataOwner) {
				$scope.defaultListSetup = webListCurrentDataOwner;
			} else if (hasAccessToCreateNewWebList()) {
				// Simplify logic for a sysadmin by autogenerating the weblist if it is missing on the current dataOwner.
				repository.createSingle(repository.apiData.webListView.url, getModelForChange()).then(function (result) {
					// Fetch the created webListView, since the response from createSingle is not complete, and to make sure that all expected values are set.
					repository.getSingle(repository.apiData.webListView.url, result.Guid).then(function (result) {
						$scope.defaultListSetup = result.Data;
					});
				});
			} else if (result.List.length) {
				$scope.defaultListSetup = result.List[0];
			}

			// No need to wait for generation, since the layout should be the same.
			getTable();
		});

		var getTable = function () {
			$scope.conditionTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Id': 'desc' },
				filter: {}
			}, {
				total: 0,
				counts: [5, 10, 20],
				filterDelay: 50,
				paginationMaxBlocks: $scope.useList ? 6 : 8,
				getData: function ($defer, params) {
					var filter = angular.copy(params.filter());

					filter.FilterModel = {
						Condition: "and",
						Rules: [
							{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }
						]
					};

					if ($scope.parentEntity === "WorkOrder") {
						filter.FilterModel = {
							Condition: "or",
							Rules: [
								{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid },
								{ Property: 'GuidInspectionWorkOrder', Operator: '=', Value: $scope.guid }
							]
						};
					}

					$scope.conditionsIsLoading = true;

					var columns = [];
					$scope.columns.forEach(function (prop) {
						columns.push(prop);
					});

					$scope.defaultListSetup.Columns.forEach(function (col) {
						columns.push(col.Property);
					});

					columns.push('EndDate');
					columns.push('Type');
					if (!columns.includes('ActionComment'))
						columns.push('ActionComment');

					if ($scope.conditions) {
						while ($scope.conditions.length > 0)
							$scope.conditions.pop();
					}

					repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns))
						.then(function (result) {
							$scope.conditionTable.settings().total = result.TotalCount;
							$scope.conditionTable.settings().filterDelay = 500;

							if (result.List.length > 0) {
								var conditionTypeColumns = ['Id', 'IsApproved'];
								repository.GetPaginated(repository.apiData.conditionType.url, 0, 20, { 'Id': 'asc' }, { PropertyFilter: [{ Property: 'IsApproved', Operator: '=', Value: 1 }] }, null, JSON.stringify(conditionTypeColumns))
									.then(function (result) {
										if (result.TotalCount === 1) {
											$scope.hasAvailableDefaultCondition = true;
										}

										if (result.TotalCount > 1) {
											if ($localStorage.generalOptions.GuidCommonDataOwner && _.some(result.List, { GuidDataOwner: $localStorage.generalOptions.GuidCommonDataOwner })) {
												$scope.hasAvailableDefaultCondition = true;
											}
										}
									});
							}

							$defer.resolve(result.List);
							$scope.conditionsIsLoading = false;

						}, function (error) {
							$scope.conditionsIsLoading = false;
							repository.growl(error, 'danger');
						});
				}
			});
		};

		//Returns the entity connected to the the deviation that is the lowest in the hierarchy
		$scope.getLowestEntityCaption = function (deviation) {
			if (deviation.Equipment) return $rootScope.getEntityCaption(deviation.Equipment);
			if (deviation.Area) return $rootScope.getEntityCaption(deviation.Area);
			if (deviation.Building) return $rootScope.getEntityCaption(deviation.Building);
			if (deviation.Estate) return $rootScope.getEntityCaption(deviation.Estate);
		};

		$scope.getEntityCaption = function (object) {
			return $rootScope.getEntityCaption(object);
		};

		$scope.getEntityIcon = function (deviation) {
			if (deviation.Equipment) return 'fa-gear c-bluegray';
			if (deviation.Area) return 'fa-layer-group c-lightblue';
			if (deviation.Building) return 'fa-building-columns c-brown';
			if (deviation.Estate) return 'fa-city c-teal';
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.selectedConditions = [];

		$scope.conditionIsSelected = function (guid) {
			return _.contains($scope.selectedConditions, guid);
		};

		$scope.addCondition = function (guid) {
			if (!_.contains($scope.selectedConditions, guid))
				$scope.selectedConditions.push(guid);
			else {
				for (var i = 0; i < $scope.selectedConditions.length; i++) {
					if ($scope.selectedConditions[i] === guid) {
						$scope.selectedConditions.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.allRowsSelected = false;

		$scope.selectAllRows = function () {
			if ($scope.allRowsSelected) {
				$scope.conditionTable.data.forEach(function (row) {
					var index = _.findIndex($scope.selectedConditions, function (o) { return o === row.Guid; });
					$scope.selectedConditions.splice(index, 1);
				});

				$scope.allRowsSelected = false;
			} else {
				$scope.conditionTable.data.forEach(function (row) {
					if (!_.contains($scope.selectedConditions, row.Guid)) {
						$scope.selectedConditions.push(row.Guid);
					}
				});
				$scope.allRowsSelected = true;
			}
		};

		$scope.$watch('conditionTable.data', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue.length === 0) {
				$scope.allRowsSelected = false;
				return;
			}

			for (var i = 0; i < newValue.length; i++) {
				if (!_.contains($scope.selectedConditions, newValue[i].Guid)) {
					$scope.allRowsSelected = false;
					return;
				}
			}

			$scope.allRowsSelected = true;
		});

		$scope.addEntityToCheck = function () {
			$modal.open({
				templateUrl: 'app/condition/views/createConditionForInspection.html',
				controller: 'CreateConditionForInspectionController',
				size: isSVV ? 'lg' : 'md',
				resolve: {
					params: function () {
						var object = { guidWorkOrder: $scope.guid };

						switch ($localStorage.generalOptions.InspectedEntityLocation) {
							case constants.inspectedEntityLocation.estate:
								if ($scope.estate) {
									object.guidEstate = $scope.estate.Guid;
								}
								break;
							case constants.inspectedEntityLocation.building:
								if ($scope.building) {
									object.guidBuilding = $scope.building.Guid;
								}
								break;
						}

						return object;
					}
				}
			}).result.then(function () {
				$scope.conditionTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.createOrUpdate = function (condition) {
			$modal.open({
				templateUrl: 'app/condition/views/editConditionModal.html',
				controller: 'ConditionController',
				size: "lg",
				resolve: {
					$stateParams: function () {
						var params = {
							isModal: true,
							canCloseCorrectiveAction: $scope.canCloseCorrectiveAction,
							closeCorrectiveAction: $scope.closeCorrectiveAction
						};

						if (condition) {
							params.guid = condition.Guid;
						}
						else {
							params.model = {
								GuidEstate: $scope.estate ? $scope.estate.Guid : null,
								Estate: $scope.estate,
								GuidBuilding: $scope.building ? $scope.building.Guid : null,
								Building: $scope.building,
								GuidWorkOrder: $scope.guid,
								Type: 'Condition'
							};
						}

						return params;
					}
				}
			}).result.then(function () {
				$scope.conditionTable.reload();
			}, function () {
				//cancel
			});
		};

		//Approve single condition
		$scope.approveCondition = function (condition) {
			swal({
				title: translationService.translate('web-swal-condition-approve', 'Bekreft godkjenning av Tilstand'),
				text: translationService.translate('web-swal-condition-approve-message', 'Er du sikker på at du vil godkjenne tilstanden?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-condition-approve-confirm', 'Ja, Godkjenn tilstanden!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.runAction(repository.apiData.condition.endpoint.approveCondition + condition.Guid).then(function (result) {
					swal(translationService.translate('web-swal-condition-approve-success', 'Tilstanden ble godkjent!'), result.Id, "success");
					$scope.conditionTable.reload();
				}, function (error) {
					swal(translationService.translate('web-error', "Feil"), error, "error");
				});
			});
		};

		$scope.canCloseCorrectiveAction = function (condition) {
			return !condition.EndDate &&
				condition.GuidWorkOrder &&
				condition.GuidWorkOrder === $scope.guid &&
				condition.Type === 'CorrectiveAction';
		};

		//close correctiveAction
		$scope.closeCorrectiveAction = function (condition) {
			if (condition.EndDate) {
				swal(translationService.translate('web-correctiveAction-alreadyClosed',
					"Feil",
					"Tiltaket er allerede lukket",
					"error"));
				return $.Deferred().reject().promise();
			}

			var deferred = $q.defer();

			swal({
				title: translationService.translate('web-swal-correctiveAction-close', 'Bekreft lukking av tiltak'),
				text: translationService.translate('web-swal-corectiveAction-approve-message', 'Er du sikker på at du vil lukke tiltaket?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-correctiveAction-close-confirm', 'Ja, Lukk tiltaket!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.runAction(repository.apiData.condition.endpoint.close + condition.Guid).then(function (result) {
					swal(translationService.translate('web-swal-correctiveAction-close-success', 'Tiltaket ble lukket!'), result.Id, "success");
					$scope.conditionTable.reload();
					deferred.resolve(result);
				}, function (error) {
					swal(translationService.translate('web-error', "Feil"), error, "error");
					deferred.reject(error);
				});
			});
			return deferred.promise;
		};

		//Approve multiple conditions
		$scope.approveSelectedConditions = function () {
			if ($scope.selectedConditions.length === 0) {
				swal(translationService.translate('web-no-choice-header', 'Ingen valg'),
					translationService.translate('workorder-condition-list-noSelect', 'Ingen tilstander valgt. Velg en eller flere fra listen for å godkjenne'),
					'error');
				return;
			}

			var approveCondition = function (guid) {
				var deferred = $q.defer();
				repository.runAction(repository.apiData.condition.endpoint.approveCondition + guid).then(function (response) {
					deferred.resolve(response);
				}, function (error) {
					deferred.reject({
						Message: error,
						Guid: guid
					});
				});
				return deferred.promise;
			};

			swal({
				title: translationService.translate('web-swal-conditions-approve', 'Bekreft godkjenning av Tilstander'),
				text: translationService.translate('web-swal-conditions-approve-message', 'Er du sikker på at du vil godkjenne tilstandene?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-conditions-approve-confirm', 'Godkjenn tilstandene'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				var conditionsInPage = $scope.selectedConditions.filter(function (guid) {
					return !!_.find($scope.conditionTable.data, { Guid: guid });
				});

				Promise.allSettled(conditionsInPage.map(function (guid) {
					return approveCondition(guid);
				})).then(function (promises) {
					var failed = promises.filter(function (promise) {
						return promise.status === "rejected";
					}).map(function (promise) {
						return promise.reason;
					});

					if (failed.length === promises.length || failed.length > 0) {
						swal(failed.length + ' ' + translationService.translate('web-condition-approveSelectedConditions-failed', 'ble ikke satt som standardtilstand'), _.uniq(_.map(failed, 'Message')).join("\r\n"), "error");
						$scope.selectedConditions = _.map(failed, 'Guid');
					} else {
						swal(translationService.translate('web-swal-conditions-approve-success', 'Tilstandene ble godkjent!'), '', "success");
						$scope.selectedConditions = [];
					}

					$scope.conditionTable.reload();
				});
			});
		};

		//Delete multiple conditions
		$scope.deleteSelectedConditions = function () {
			var deleteCondition = function (guid) {
				var deferred = $q.defer();
				var condition = _.find($scope.conditionTable.data, { Guid: guid });

				if (condition.ActionComment)
					return $.Deferred().reject(condition).promise();

				repository.deleteSingle(repository.apiData.condition.url, condition.Guid).then(function () {
					deferred.resolve(condition);
				}, function () {
					deferred.reject(condition);
				});

				return deferred.promise;
			};

			swal({
				title: translationService.translate('web-swal-conditions-delete', 'Bekreft sletting av Tilstander'),
				text: translationService.translate('web-swal-conditions-delete-message', 'Er du sikker på at du vil slette alle tilstandene?'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-conditions-delete-confirm', 'Slett tilstandene'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true,
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				var conditionsInPage = $scope.selectedConditions.filter(function (guid) {
					return !!_.find($scope.conditionTable.data, { Guid: guid });
				});

				Promise.allSettled(conditionsInPage.map(function (guid) {
					return deleteCondition(guid);
				})).then(function (promises) {
					var failed = promises.filter(function (promise) {
						return promise.status === "rejected";
					}).map(function (promise) {
						return promise.reason;
					});

					if (failed.length === promises.length) {
						swal(
							translationService.translate('web-swal-conditions-delete-failed', 'Kunne ikke slette tilstandene'),
							translationService.translate('web-swal-conditions-delete-notDeleted', 'Ikke slettet:') + ' ' + _.map(failed, 'Id'), "error");
					} else if (failed.length > 0) {
						swal(
							translationService.translate('web-swal-conditions-delete-partialSuccess', 'Kunne ikke slette alle tilstandene'),
							translationService.translate('web-swal-conditions-delete-notDeleted', 'Ikke slettet:') + ' ' + _.map(failed, 'Id'), "info");
						$scope.selectedConditions = _.map(failed, 'Guid');
					} else {
						swal(translationService.translate('web-swal-conditions-delete-success', 'Tilstandene ble slettet!'), '', "success");
						$scope.selectedConditions = [];
					}

					$scope.conditionTable.reload();
				});
			});
		};

		$scope.remove = function (condition) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-condition-message', 'Tilstanden vil bli permanent fjernet'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-condition-button-confirm', 'Ja, fjern tilstanden'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.condition.url, condition.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-condition-success', 'Tilstanden ble fjernet'), result, "success");
						$scope.conditionTable.reload();
					});
			});
		};

		$scope.editColumns = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/editTableColumnsModal.html',
				controller: 'EditTableColumnsModalController',
				resolve: {
					params: function () {
						return {
							defaultListSetup: originalListSetup,
							listSetup: $scope.defaultListSetup,
							entity: 'Condition'
						};
					}
				}
			}).result.then(function (result) {
				$scope.conditionTable.reload();
			}, function (dismiss) {
			});
		};

		$scope.$on($rootScope.events.newSelection, function () {
			$scope.conditionTable.reload();
		});
	}
})();
