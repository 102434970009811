angular.module('Plania').factory('CleaningQualityControlThemeService', ['$modal', 'Repository', '$q', 'TranslationService', 'DwgThemeService', function ($modal, repository, $q, translationService, dwgThemeService) {
	var service = {};

	var areas = [];

	var areaNotApproved = '#d9534f';
	var areaToBeApproved = '#428bca';
	var areaApproved = '#5cb85c';

	var updateIndicatorColours = function () {
		areas.forEach(function (area) {
			var elements = $('#' + area.GuidArea).children();

			if (elements[0]) {
				var path = elements[0];

				if (area.Status === 'NotAvailable')
					return;

				$(path).attr('stroke-width', '100');
				$(path).attr('stroke-opacity', '1');
				$(path).attr('fill-opacity', '0.3');
				$(path).attr('stroke-dasharray', '');
				$(path).attr('stroke-linecap', '');

				if (area.Status === 'New') {
					$(path).attr('stroke', areaToBeApproved);
					$(path).attr('fill', areaToBeApproved);
				}

				if (area.Status === 'Approved') { // sjekk oversettelser
					$(path).attr('stroke', areaApproved);
					$(path).attr('fill', areaApproved);
				}

				if (area.Status === 'Rejected') {
					$(path).attr('stroke', areaNotApproved);
					$(path).attr('fill', areaNotApproved);
				}
			}
		});
	};

	service.getLegends = function () {
		return [
			{ label: 'Rom til kontroll', legendClass: 'legend-primary' },
			{ label: 'Ikke godkjent', legendClass: 'legend-danger' },
			{ label: 'Godkjent', legendClass: 'legend-success' },
		];
	};

	service.onClick = function (guidArea) {
		var deferred = $q.defer();

		var cleaningQualityControlArea = _.find(areas, function (o) { return o.GuidArea === guidArea; });
		if (!cleaningQualityControlArea)
			return $.Deferred().reject().promise();

		$modal.open({
			templateUrl: 'app/cleaningQualityControl/views/cleaningQualityControlAreaModal.html',
			controller: 'CleaningQualityControlAreaModalController',
			animation: false,
			size: 'lg',
			resolve: {
				params: function () {
					return {
						cleaningQualityControlArea: cleaningQualityControlArea
					};
				}
			}
		}).result.then(function () {
			deferred.resolve({ update: true });
		}, function (error) {
			if (error === 'cancel') deferred.resolve({});
			else deferred.reject();
		});

		return deferred.promise;
	};

	service.getStatus = function (guidQualityControl, oldAreas) {
		if (oldAreas && Array.isArray(oldAreas)) {
			dwgThemeService.removePeriodicMarkers(oldAreas);
			dwgThemeService.removeRequestMarkers(oldAreas);
			dwgThemeService.removeCategoryLabels(oldAreas);
		}


		return repository.GetPaginated(repository.apiData.cleaningQualityControlArea.url, 0, 500, {}, { PropertyFilter: [{ Property: 'GuidCleaningQualityControl', Value: guidQualityControl, Operator: '=' }] }, "", JSON.stringify(['Status'])).then(function (result) {
			if (oldAreas && Array.isArray(oldAreas))
				dwgThemeService.removeAreaIndicators(oldAreas);

			areas = result.List;
			updateIndicatorColours();
			return result;
		});
	};

	return service;
}]);
