(function () {
	var app = angular.module('Plania');
	app.controller('ListAccountingController', ['$scope', 'Repository', 'TranslationService', '$modal',
		function ($scope, repository, translationService, $modal) {
			// Grouped list of dimensions
			$scope.dimensions = [];

			var updateAccess = function () {
				$scope.restrictEdit = !$scope.hasEditAccess(repository.apiData.accounting.prefix);
				$scope.restrictDelete = !$scope.hasDeleteAccess(repository.apiData.accounting.prefix);
			};

			updateAccess();

			var columns = [
				"Id", "Description", "DimensionNumber",
				"DataOwner.Dimension1", "DataOwner.Dimension2", "DataOwner.Dimension3", "DataOwner.Dimension4", "DataOwner.Dimension5",
			];

			var getItems = function () {
				$scope.dimensions = [];
				var filter = {};

				repository.GetPaginated(repository.apiData.accounting.url, 0, -1, { Id: 'asc' }, filter, null, JSON.stringify(columns)).then(function (result) {
					var dimensions = [];

					var accountings = result.List;

					// Remove Dimensions that has not been activated i dataOwner
					accountings = accountings.filter(function (accounting) {
						return accounting.DataOwner && accounting.DataOwner['Dimension' + (accounting.DimensionNumber + 1)] && accounting.DataOwner['Dimension' + (accounting.DimensionNumber + 1)].length > 0;
					});

					// Group dimensions by their dimensionsNumber
					accountings.forEach(function (dimension) {
						var existingDimension = _.find(dimensions, function (dim) { return dim.dimensionNumber === dimension.DimensionNumber; });
						if (existingDimension)
							existingDimension.data.push(dimension);
						else {
							dimensions.push({
								dimensionNumber: dimension.DimensionNumber,
								dimensionTitle: dimension.DataOwner['Dimension' + (dimension.DimensionNumber + 1)],
								data: [dimension]
							});
						}
					});

					$scope.dimensions = _.sortBy(dimensions, function (dim) { return dim.dimensionNumber; });
				});
			};

			getItems();

			$scope.addAccounting = function (dimensionNumber) {
				$modal.open({
					templateUrl: "app/accounting/views/editAccounting.html",
					controller: "AccountingModalController",
					resolve: {
						modalParams: function () {
							return { DimensionNumber: dimensionNumber };
						}
					}
				}).result.then(function (responseResult) {
					getItems();
				});
			};

			$scope.updateAccounting = function (dim) {
				var childDimensionTitle = "";
				for (var i = 0; i < $scope.dimensions.length; i++) {
					if ($scope.dimensions[i].dimensionNumber === dim.DimensionNumber + 1) {
						childDimensionTitle = $scope.dimensions[i].dimensionTitle;
						break;
					}
				}

				$modal.open({
					templateUrl: "app/accounting/views/editAccounting.html",
					controller: "AccountingModalController",
					resolve: {
						modalParams: function () {
							return {
								dim: dim,
								isLastDimension: !_.some($scope.dimensions, { 'dimensionNumber': (dim.DimensionNumber + 1) }),
								childDimensionTitle: childDimensionTitle
							};
						}
					}
				}).result.then(function (responseResult) {
					getItems();
				});
			};

			$scope.removeAccounting = function (guid) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-accounting-message', 'Dimensjon vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-accounting-button-confirm', 'Ja, fjern dimensjon!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.accounting.url, guid).then(function (result) {
						swal(translationService.translate('web-swal-accounting-success', 'Dimensjonen ble fjernet!'), result, "success");
						getItems();
					});
				});
			};

			$scope.contextMenuOptions = function (dimension) {
				var options = [];

				if (!$scope.restrictEdit) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-accounting-contextMenu-dimension-edit', 'Rediger dimensjonsverdi') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.updateAccounting(dimension);
						}
					});
				}

				if (!$scope.restrictDelete) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-accounting-contextMenu-dimension-delete', 'Slett dimensjonsverdi') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.removeAccounting(dimension.Guid);
						}
					});
				}
				return options;
			};
		}
	]);
})();
