(function () {
    angular.module('Plania').factory('DocumentService', ['config', 'Repository', '$window', '$q', documentService]);

    function documentService(config, repository, $window, $q) {
        var service = {};

		var docContextMap = {
			RegulatoryCompliance: 1 << 0,
			Attestation: 1 << 1,
			Description: 1 << 2,
			Operation: 1 << 3,
			Maintenance: 1 << 4,
			ProductInfo: 1 << 5,
			Instruction: 1 << 6,
			Protocol: 1 << 7,
			Identification: 1 << 8,
			Drawing: 1 << 9,
			Image: 1 << 10
		};

		// Summarizes a list of DocumentationContexts into its corresponding Enum Flag value
		// Ideally the api could accept a list of DocumentationContexts and handle the enum parsing
		service.getDocumentationContext = function (documentationContextList){
			var summarizedDocContext = 0;
			_.forEach(documentationContextList, function(docContext) {
				var docContextValue = docContextMap[docContext];
				summarizedDocContext |= docContextValue;
			});
			return summarizedDocContext;
		};

        //Takes a document, not a documentXEntity, so if you have documentXEntity, just insert documentXEntity.Document
        service.openDocument = function (document) {
            var deferred = $q.defer();
            if (document.FilePath) {
                if (document.FilePath.indexOf("http") === 0) {
                    $window.open(document.FilePath);
                    return $.Deferred().resolve().promise();
                }
            }
            repository.createSingle(repository.apiData.documentData.url, { GuidDocument: document.Guid }).then(function (result) {
                var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
                $window.open(config.baseUrlApi + repository.apiData.documentData.url + result.Data.GuidDocumentResponse + '?dataOwner=' + dataOwner + '&token=' + result.Data.Token, '_blank');
                return deferred.resolve(result);
            }, function (error) {
                return deferred.reject(error);
            });
            return deferred.promise;
        };
        return service;
    }
})();
