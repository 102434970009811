(function () {
	angular.module('Plania').controller('MultipleDwgRequestController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'ListService', 'ngTableParams', controller]);

	function controller($scope, $modalInstance, params, repository, translationService, listService, ngTableParams) {
		$scope.selectedCleaningTask = null;
		$scope.selectedAreaXCleaningTask = {};
		$scope.areaXCleaningTasks = [];
		$scope.areas = [];
		$scope.errorDuplicateExitCleaningOnRoom = false; // Used to display message to user since rooms with exit cleaning will be hidden if it would duplicate.
		$scope.errorNoAreaXCleaningTasks = false;
		$scope.showMultiSelectView = false;
		$scope.multiSelectedAreaXCleaningTasks = [];
		$scope.templateCleaningTask = { };

		//Wizard Setup
		$scope.tabs = [
			{ heading: translationService.translate('web-dwg-multipleRequest-chooseCleaningTask', 'Velg renhold'), step: 0 },
			{ heading: translationService.translate('web-dwg-multipleRequest-confirmRooms', 'Fyll ut og bekreft valgte rom'), step: 1 }
		];
		$scope.step = 0;

		$scope.isCurrentStep = function (step) {
			return $scope.step === step;
		};

		$scope.setCurrentStep = function (step) {
			if ($scope.step === 0 && $scope.areaXCleaningTasks.length === 0) return;
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		$scope.isLastStep = function () {
			return $scope.step === ($scope.tabs.length - 1);
		};

		$scope.getNextLabel = function () {
			return $scope.isLastStep() ? translationService.translate('web-button-confirm', 'Bekreft') : translationService.translate('web-button-next', 'Neste');
		};

		$scope.handlePrevious = function () {
			$scope.setCurrentStep($scope.step - ($scope.step === 0 ? 0 : 1));
		};

		$scope.handleNext = function () {
			if ($scope.isLastStep()) {
				$scope.create();
			} else {
				if ($scope.step === 0 && $scope.areaXCleaningTasks.length === 0) return;
				$scope.setCurrentStep($scope.step += 1);
			}
		};
		//End Wizard setup

		var areaColumns = ["Id", "Description", "HasExitCleaning"];
		var areaFilter = { PropertyFilter: [{ Property: 'Guid', Operator: 'in', Value: params.guids.join(',') }] };
		repository.GetPaginated(repository.apiData.area.url, 0, -1, {}, areaFilter, null, JSON.stringify(areaColumns))
			.then(function (result) {
				$scope.areas = result.List;
				$scope.cleaningTaskInfo.initTable();
			}, function (error) {
				repository.growl(error, "danger");
			});

		$scope.cleaningTaskInfo = new function () {
			var me = this;
			this.apiData = repository.apiData.cleaningTask;

			this.filter = {
				PropertyFilter: [
					{ Property: 'IsTemplate', Operator: '=', Value: '1' }
				]
			};
			this.columns = [
				{ Position: 1, Title: translationService.translate('web-cleaningTask-Id', 'Renhold'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-cleaningTask-CleaningType', 'Renholdstype'), Property: 'CleaningType', PropertyType: 'enum.cleaningType' },
			];

			this.dataColumns = ['Id', 'EstimatedTime', 'EstimatedCost', 'ExplanatoryText', 'NumericFieldName', 'CleaningType', 'ExitCleaningDecisionDate', 'ExitCleaningConfirmedDate'];

			this.initTable = function () {
				if (me.table)
					return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Id': 'asc' },
					filter: me.filter
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) { columns.push(col.Property); });
							me.dataColumns.forEach(function (col) { if (!columns.includes(col)) columns.push(col); });

							repository.GetPaginated(me.apiData.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(function (result) {
									result.List.forEach(function (task) {
										if (task.CleaningType === 'ExitCleaning') {
											task.ExitCleaningRequestStatus = 'Possible';
											task.ExitCleaningDecisionDate = getCurrentIsoTime(task.ExitCleaningDecisionDate);
											task.ExitCleaningConfirmedDate = getCurrentIsoTime(task.ExitCleaningConfirmedDate);
										}
									});

									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;
									$defer.resolve(result.List);
								}, function (error) {
									repository.growl(error, 'danger');
								});
						}
					});
			};

			this.selectCleaningTask = function (cleaningTask) {
				if ($scope.selectedCleaningTask) {
					if ($scope.selectedCleaningTask.Guid === cleaningTask.Guid) {
						$scope.handleNext();
						return;
					}
					else
						$scope.areaXCleaningTasks = [];
				}

				$scope.selectedCleaningTask = cleaningTask;
				$scope.errorDuplicateExitCleaningOnRoom = false;
				$scope.errorNoAreaXCleaningTasks = false;
				$scope.areas.forEach(function (area) {
					if ($scope.selectedCleaningTask.CleaningType === 'ExitCleaning' && area.HasExitCleaning) {
						$scope.errorDuplicateExitCleaningOnRoom = true;
					}
					else {
						var cleaningTaskCopy = angular.copy($scope.selectedCleaningTask);
						var areaXCleaningTask = {
							GuidArea: area.Guid,
							GuidCleaningTask: cleaningTaskCopy.Guid,
							AttributeValues: cleaningTaskCopy.AttributeValues,
							Area: area,
							CleaningTask: cleaningTaskCopy,
						};
						if (cleaningTaskCopy.CleaningType === 'ExitCleaning') {
							areaXCleaningTask.ExitCleaningConfirmedDate = cleaningTaskCopy.ExitCleaningConfirmedDate;
							areaXCleaningTask.ExitCleaningDecisionDate = cleaningTaskCopy.ExitCleaningDecisionDate;
							areaXCleaningTask.ExitCleaningRequestStatus = cleaningTaskCopy.ExitCleaningRequestStatus;
						}
						areaXCleaningTask.AttributeValues.forEach(function (attribute) {
							if (attribute.ValueType === 'Date' && attribute.DateValue)
								attribute.DateValue = attribute.DateValue.toISOString();
						});
						$scope.areaXCleaningTasks.push(areaXCleaningTask);
					}
				});
				if ($scope.areaXCleaningTasks.length > 0) {
					$scope.areaXCleaningTasks[0].hasBeenViewed = true;
					$scope.selectedAreaXCleaningTask = $scope.areaXCleaningTasks[0];
					$scope.handleNext();
				}
				else {
					$scope.errorNoAreaXCleaningTasks = true;
				}
			};
		}();

		$scope.create = function () {
			var copyAreaXCleaningTasks = angular.copy($scope.areaXCleaningTasks);
			var postAreaXCleaningTask = [];
			copyAreaXCleaningTasks.forEach(function (task) {
				if (task.CleaningTask.CleaningType === 'ExitCleaning') {
					if (task.ExitCleaningRequestStatus === 'Possible') {
						task.ExitCleaningConfirmedDate = null;
						task.ExitCleaningDecisionDate = getCurrentIsoTime(task.ExitCleaningDecisionDate);
					}
					else if (task.ExitCleaningRequestStatus === 'Discharged') {
						task.ExitCleaningDecisionDate = null;
						task.ExitCleaningConfirmedDate = new Date().toISOString();
					}
					else {
						task.ExitCleaningDecisionDate = null;
						task.ExitCleaningConfirmedDate = getCurrentIsoTime(task.ExitCleaningConfirmedDate);
					}
				}

				task.AttributeValues.forEach(function (attribute) {
					if (attribute.ValueType === 'Date' && attribute.DateValue)
						attribute.DateValue = attribute.DateValue.toISOString();
				});

				delete task.Area;
				delete task.CleaningTask;
				postAreaXCleaningTask.push(task);
			});

			repository.createMultiple(repository.apiData.areaXCleaningTask.url, postAreaXCleaningTask).then(function (result) {
				repository.growl(translationService.translate('web-dwg-multipleRequest-create-success', 'Renholdsoppgavene har blitt registrert til rommet'));
				$scope.close();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.selectAreaXCleaningTask = function (task) {
			$scope.multiSelectView = false;
			task.hasBeenViewed = true;
			$scope.selectedAreaXCleaningTask = task;
		};

		var attributeHasValue = function (attribute) {
			switch (attribute.ValueType) {
				case "Combo":
				case "Text":
					return attribute.TextValue;
				case "Numeric":
					return attribute.NumericValue;
				case "Date":
					return attribute.DateValue;
				case "CheckBox":
					return attribute.BitValue;
			}
		};

		$scope.$watch('multiSelectView', function(newValue, oldValue) {
			if (oldValue === newValue) return;
			if (newValue === true) {
				$scope.templateCleaningTask = angular.copy($scope.selectedAreaXCleaningTask);
			} else {
				$scope.multiSelectedAreaXCleaningTasks = [];
			}
		});

		$scope.multiSelectTask = function(areaXCleaningTask) {
			if (_.some($scope.multiSelectedAreaXCleaningTasks, { $$hashKey: areaXCleaningTask.$$hashKey })) {
				_.remove($scope.multiSelectedAreaXCleaningTasks, { $$hashKey: areaXCleaningTask.$$hashKey });
			} else {
				$scope.multiSelectedAreaXCleaningTasks.push(areaXCleaningTask);
			}
		};

		$scope.copyPropertiesFromTemplateToTasks = function() {
			_.forEach($scope.multiSelectedAreaXCleaningTasks, function(templateAreaXCleaningTask) {
				var originalTask = _.find($scope.areaXCleaningTasks, { $$hashKey: templateAreaXCleaningTask.$$hashKey });

				originalTask.ExitCleaningConfirmedDate = $scope.templateCleaningTask.ExitCleaningConfirmedDate;
				originalTask.ExitCleaningDecisionDate = $scope.templateCleaningTask.ExitCleaningDecisionDate;
				originalTask.ExitCleaningRequestStatus = $scope.templateCleaningTask.ExitCleaningRequestStatus;
				originalTask.AttributeValues = angular.copy($scope.templateCleaningTask.AttributeValues);
			});
			$scope.multiSelectView = false;
		};

		$scope.isTaskMultiSelected = function(areaXCleaningTask) {
			return _.some($scope.multiSelectedAreaXCleaningTasks, { $$hashKey: areaXCleaningTask.$$hashKey });
		};

		$scope.getStatusIcon = function(areaXCleaningTask) {
			if ($scope.hasMissingProperties(areaXCleaningTask)) return 'fa-circle-exclamation c-red';
			if ($scope.hasWarnings(areaXCleaningTask)) return 'fa-triangle-exclamation c-orange';
			return 'fa-check-circle c-green';
		};

		$scope.hasMissingProperties = function (task) {
			var mandatoryFields = task.AttributeValues.filter(function (attr) { return attr.ValidityRules === "Mandatory"; });
			if (!mandatoryFields) return false;

			for (var i = 0; i < mandatoryFields.length; i++) {
				if (!attributeHasValue(mandatoryFields[i]))
					return true;
			}

			return false;
		};

		$scope.allFieldAreValid = function () {
			for (var i = 0; i < $scope.areaXCleaningTasks.length; i++) {
				if ($scope.hasMissingProperties($scope.areaXCleaningTasks[i]))
					return false;
			}
			return true;
		};

		$scope.hasWarnings = function(areaXCleaningTask) {
			if (areaXCleaningTask.ExitCleaningRequestStatus === 'Possible') {
				return $scope.isTimeInPast(areaXCleaningTask.ExitCleaningDecisionDate);
			}
			if (areaXCleaningTask.ExitCleaningRequestStatus === 'Confirmed') {
				return $scope.isTimeInPast(areaXCleaningTask.ExitCleaningConfirmedDate);
			}
			return false;
		};

		$scope.isTimeInPast = function (dateProperty) {
			if (!dateProperty) return false;
			var time = dateProperty;

			var currentTime = new Date().getTime();
			var selectedTime = new Date(time).getTime();
			if (currentTime > selectedTime)
				return true;
			return false;
		};

		var getCurrentIsoTime = function (input) {
			var date = new Date();
			if (!input) {
				date.setHours(12, 0, 0, 0);
			} else {
				var selectedDate = new Date(input);
				date.setHours(selectedDate.getHours(), selectedDate.getMinutes(), 0, 0);
			}
			return date.toISOString();
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.close = function () {
			$modalInstance.close();
		};
	}
})();
