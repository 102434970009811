(function () {
	angular.module('Plania').controller('ReplaceDocumentController', ['$scope', 'Repository', '$modalInstance', 'params', 'TranslationService', controller]);

	function controller($scope, repository, $modalInstance, params, translationService) {

		var model = params.model;

		$scope.files = [];
		$scope.onFileSelect = function ($files) {
			$scope.files = $scope.files.concat($files);
		};

		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		repository.getSingle(repository.apiData.webClientSettings.url, "").then(function (result) {
			$scope.maxFileLength = result.MaxRequestLength;
		});

		var getFile = function (allFiles) {
			var files = _.reject(allFiles, function (file) {
				// If retrying upload, remove the files already uploaded
				return file.progressType === 'success';
			});

			files.forEach(function (file) {
				// Generate unique id for files used for upload progress update
				file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
				file.count = 0;
				file.progressType = null;
			});
			return files[0];
		};


		$scope.filesizeToLarge = false;

		$scope.removeFile = function (index) {
			$scope.files.splice(index, 1);
		};

		var savedFiles = [];

		$scope.uploadDocument = function (destination) {
			if ($scope.isUploading) return;

			if ($scope.files.length < 1) {
				swal(translationService.translate('web-document-error-noFile', 'Ingen fil valgt'), translationService.translate('web-document-error-nofiledescription', 'Du må velge en fil for å laste opp'), 'info');
				return;
			}

			if (!model) {
				swal(translationService.translate('web-document-error-invalidGuidDocument', 'Ikke gyldig dokument å laste opp på'), translationService.translate('web-document-error-invalidGuidDocument-description', 'Det har skjedd en feil med referanse til Guid, prøv igjen'), 'info');
				return;
			}

			var file = getFile($scope.files);

			$scope.isUploading = true;
			repository.updateSingleWithBinary(repository.apiData.document.urlChunk, model, file).then(function (result) {
				repository.commonService.setLastRegisterGuid(destination, result);
				repository.growl('Dokumentet har blitt oppdatert', 'info');
				$scope.alreadyUploaded = true;
				$modalInstance.close(file.name);

			}, function (error) {
				repository.growl(error[0].error, 'danger');
				$scope.isUploading = false;
				error.forEach(function (error) {
					var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
					if (identifiedFile) {
						identifiedFile.progressType = 'danger';
					}
				});
			}, function (file) { //UPDATE
				var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
				if (identifiedFile) {
					identifiedFile.total = file.total;
					identifiedFile.count = file.count;
					identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
				}
			});
		};

		$scope.ok = function () {
			$modalInstance.close(null);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
