angular.module('Plania').directive('dragDropPreventDefault', ['$window', function ($window) {
	return {
		restrict: 'A',
		link: function link(scope, element, attrs) {
		    $window.addEventListener("dragover", function (e) {
		        e = e || event;
		        e.preventDefault();
		    }, false);
		    $window.addEventListener("drop", function (e) {
		        e = e || event;
		        e.preventDefault();
		    }, false);
		},
	};
}]);
