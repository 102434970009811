angular.module('Plania').factory('DwgThemeService', ['$modal', 'Repository', '$rootScope', 'TranslationService', '$localStorage', function ($modal, repository, rootScope, translationService, $localStorage) {
	var service = {};

	var getAreaGuids = function (areas) {
		var guids = [];
		if (!areas || areas.length === 0) return guids;

		if (areas[0].GuidArea)
			guids = areas.map(function (areaObject) { return areaObject.GuidArea; });
		else
			guids = areas;
		return guids;
	};

	// Remove color and border styling from cleaning
	service.removeAreaIndicators = function (areas) {
		getAreaGuids(areas).forEach(function (guidArea) {
			var path = $('#' + guidArea).children()[0];
			$(path).attr('fill', 'rgb(0,255,0)');
			$(path).attr('stroke-width', '0');
			$(path).attr('stroke-opacity', '0');
			$(path).attr('fill-opacity', '0');

			// Reset highlight transition
			$(path).attr('style', 'transition: none;');
		});
	};

	// Remove countdown clock indication for periodic cleaning task
	service.removePeriodicMarkers = function (areas) {
		getAreaGuids(areas).forEach(function (guidArea) {
			var markerGroup = $('#' + guidArea + '_periodicMarker')[0];
			if (markerGroup)
				$('#dwgDrawing').svg('get').remove(markerGroup);
		});
	};

	// Remove request markers
	service.removeRequestMarkers = function (areas) {
		getAreaGuids(areas).forEach(function (guidArea) {
			var markerGroup = $('#' + guidArea + '_marker')[0];
			if (markerGroup)
				$('#dwgDrawing').svg('get').remove(markerGroup);
		});
	};

	// Remove areaId labels from areas.
	service.removeAreaIdLabels = function (areas) {
		getAreaGuids(areas).forEach(function (guidArea) {
			var markerGroup = $('#' + guidArea + '_areaIdLabel')[0];
			if (markerGroup)
				$('#dwgDrawing').svg('get').remove(markerGroup);
		});
	};

	// Remove labels about equipmentCategory
	service.removeEquipmentCategoryLabels = function (areas) {
		getAreaGuids(areas).forEach(function (guidArea) {
			var markerGroup = $('#' + guidArea + '_equipmentLabel')[0];
			if (markerGroup)
				$('#dwgDrawing').svg('get').remove(markerGroup);
		});
	};

	// Remove labels about doorLocks
	service.removeDoorLockLabels = function (areas) {
		getAreaGuids(areas).forEach(function (guidArea) {
			var markerGroup = $('#' + guidArea + '_doorLockLabel')[0];
			if (markerGroup)
				$('#dwgDrawing').svg('get').remove(markerGroup);
		});
	};

	service.removeCategoryLabels = function (areas) {
		service.removeEquipmentCategoryLabels(areas);
		service.removeDoorLockLabels(areas);
	};

	service.getAvailableThemes = function (selectedTheme, isStaticTheme) {
		var themes = [];

		if (selectedTheme && isStaticTheme) {
			switch (selectedTheme) {
				case 'activity':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-activity', 'Vedlikehold'),
						value: 'activity'
					});
					break;
				case 'controlList':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-ControlList', 'Kontrolliste'),
						value: 'controlList'
					});
					break;
				case 'cleaning':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-Cleaning', 'Renhold'),
						value: 'cleaning'
					});
					break;
				case 'cleaningQualityControl':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-CleaningQualityControl', 'INSTA-kontroll'),
						value: 'cleaningQualityControl'
					});
					break;
				case 'cleaningMaintenance':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-CleaningMaintenance', 'Renholdsvedlikehold'),
						value: 'cleaningMaintenance'
					});
					break;
				case 'request':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-CleaningRequest', 'Innmelding'),
						value: 'request'
					});
					break;
				case 'equipment':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-equipment', 'Anlegg'),
						value: 'equipment'
					});
					break;
				case 'doorLock':
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-doorLock', 'Sylinder'),
						value: 'doorLock'
					});
					break;
				default:
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-Standard', 'Standard'),
						value: 'standard'
					});
			}
		} else {
			themes.push({
				label: translationService.translate('web-dwg-toolbar-selectTheme-Standard', 'Standard'),
				value: 'standard'
			});
			if (rootScope.hasModule('Activity')) {
				if (rootScope.hasReadAccess(repository.apiData.workOrder.prefix) && rootScope.hasReadAccess(repository.apiData.request.prefix))
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-activity', 'Vedlikehold'),
						value: 'activity'
					});

				if (rootScope.hasReadAccess(repository.apiData.deviation.prefix)) {
					themes.push(
						{
							label: translationService.translate('web-dwg-toolbar-selectTheme-ControlList', 'ControlList'),
							value: 'controlList'
						});
				}

				if (rootScope.hasReadAccess(repository.apiData.equipment.prefix)) {
					themes.push(
						{
							label: translationService.translate('web-dwg-toolbar-selectTheme-equipment', 'Anlegg'),
							value: 'equipment'
						});
				}

				if (rootScope.hasReadAccess(repository.apiData.doorLockXArea.prefix)) {
					themes.push({
						label: translationService.translate('web-dwg-toolbar-selectTheme-doorLock', 'Sylinder'),
						value: 'doorLock'
					});
				}
			}

			if (rootScope.hasModule('CleaningWeb') && rootScope.hasReadAccess(repository.apiData.area.prefix)) {
				themes.push({
					label: translationService.translate('web-dwg-toolbar-selectTheme-Cleaning', 'Renhold'),
					value: 'cleaning'
				});

				themes.push({
					label: translationService.translate('web-dwg-toolbar-selectTheme-CleaningQualityControl', 'INSTA-kontroll'),
					value: 'cleaningQualityControl'
				});

				themes.push({
					label: translationService.translate('web-dwg-toolbar-selectTheme-CleaningMaintenance', 'Renholdsvedlikehold'),
					value: 'cleaningMaintenance'
				});

				themes.push({
					label: translationService.translate('web-dwg-toolbar-selectTheme-CleaningRequest', 'Innmelding'),
					value: 'request'
				});
			}
		}

		return themes;
	};

	return service;
}]);
