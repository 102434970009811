(function () {
	angular.module('Plania').directive('plReportWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-report-message', "Rapporten vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-report-confirm', 'Ja, fjern rapporten'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-report-success', 'Rapporten ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/reportWidget.html',
			controller: ['$scope', '$modal', safeController],
		};
	}]);

	function safeController($scope, modal) {
		try {
			controller($scope, modal);
		} catch (error) {
			console.log(error);
		}
	}

	function controller ($scope, modal) {
		$scope.model = $scope.widget;

		$scope.sharedController = {};

		$scope.refreshWidget = function () {
			$scope.sharedController.refresh();
		};

		$scope.toggleReportFilters = function () {
			$scope.sharedController.toggleFilters();
		};

		$scope.toggleZoomMode = function () {
			$scope.sharedController.toggleZoomMode();
		};

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false);
			}, function () {
				//Dismissed
			});
		};
	}
})();
