(function () {
	angular.module('Plania').controller('LogoutController', ['$scope', 'Repository', '$state', 'SignalR', controller]);

	function controller($scope, repository, $state, signalR) {
		signalR.stop();
		signalR.setQs({});
	    repository.authService.removeAuthentication().then(function (result) {
	        repository.growl('Du har blitt logget ut', 'success');
			$state.go('login', { useSSO: false });
		},
        function (error) {
            $state.go('login', { useSSO: false });
        });
	}
})();
