(function () {

	var app = angular.module('Plania');

	app.directive('projectResourceGroupTableWidget', function () {
		return {
			restrict: 'E',
			scope: {
				guid: '=',
			},
			controller: ['$scope', 'Repository', 'TranslationService', 'ngTableParams', 'ListService', 'RegisteredFieldService', controller],
			templateUrl: 'app/project/widgets/projectResourceGroupTableWidget.html'
		};
	});

	function controller($scope, repository, translationService, ngTableParams, listService, registeredFieldService) {
		$scope.firstPage = true; // False is HourType view
		$scope.tableColumns = [];
		$scope.showHourType = false;
		$scope.changingPage = false;

		var resourceGroupPage = {
			kpiObject: {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.guid }],
				Properties: [{
					Property: 'EstimatedTime',
					Function: 'sum'
				}, {
					Property: 'RealTime',
					Function: 'sum'
				}],
				RowGroup: 'ResourceGroup.Id'
			},
			columns: [
				{ Position: 1, Title: translationService.translate('web-project-resource-resourceTable-resourcegroup-Id', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-project-resource-resourceTable-estimatedTime', 'Estimert'), Property: 'EstimatedTime', PropertyType: 'double' },
				{ Position: 3, Title: translationService.translate('web-project-resource-resourceTable-realtime', 'Registrert'), Property: 'RealTime', PropertyType: 'double' },
			],
			sorting: { 'ResourceGroup.Id': 'asc' }
		};

		var hourTypePage = {
			kpiObject: {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.guid }],
				Properties: [{
					Property: 'EstimatedTime',
					Function: 'sum'
				}, {
					Property: 'RealTime',
					Function: 'sum'
				}, {
					Property: 'EstimatedCost',
					Function: 'sum'
				}, {
					Property: 'RealCost',
					Function: 'sum'
				}],
				RowGroup: 'HourType.Id'
			},
			columns: [
				{ Position: 1, Title: translationService.translate('web-project-resource-resourceTable-hourType-Id', 'Timetype'), Property: 'HourType.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-project-resource-resourceTable-estimatedTime', 'Est. tid'), Property: 'EstimatedTime', PropertyType: 'double' },
				{ Position: 3, Title: translationService.translate('web-project-resource-resourceTable-realtime', 'Reg. tid'), Property: 'RealTime', PropertyType: 'double' },
				{ Position: 4, Title: translationService.translate('web-project-resource-resourceTable-estimatedCost', 'Est. kost'), Property: 'EstimatedCost', PropertyType: 'double' },
				{ Position: 5, Title: translationService.translate('web-project-resource-resourceTable-realCost', 'Reg. kost'), Property: 'RealCost', PropertyType: 'double' },
			],
			sorting: { 'HourType.Id': 'asc' }
		};

		$scope.switchView = function () {
			if (!$scope.showHourType) return;
			$scope.changingPage = true;
			$scope.firstPage = !$scope.firstPage;
			$scope.table.reload();
		};

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrderXResourceGroup).then(function (registeredFields) {
			if (registeredFields) {
				$scope.showHourType = !_.filter(registeredFields, { DomainFieldName: 'GuidHourType' })[0].Hidden;
			}
		});

		$scope.table = new ngTableParams({
			count: 0,
			sorting: resourceGroupPage.sorting
		},
		{
			counts: [],
			getData: function ($defer, params) {
				var kpiObject = {};
				var sorting = params.sorting();

				if ($scope.firstPage) {
					$scope.tableColumns = resourceGroupPage.columns;
					kpiObject = resourceGroupPage.kpiObject;

					if ($scope.changingPage)
						sorting = resourceGroupPage.sorting;
				}
				else {
					$scope.tableColumns = hourTypePage.columns;
					kpiObject = hourTypePage.kpiObject;
					if ($scope.changingPage)
						sorting = hourTypePage.sorting;
				}

				$scope.table.$params.sorting = sorting;

				repository.createSingle(repository.apiData.kpi.url, kpiObject).then(function (result) {
					$scope.totalEstimatedTime = 0;
					$scope.totalRealTime = 0;
					$scope.totalRealCost = 0;
					$scope.totalEstimatedCost = 0;

					if (result === null || result === undefined)
						return;

					result.forEach(function (group) {
						if (group.GroupCaption === 'Undefined')
							group.GroupCaption = translationService.translate('web-project-resource-resourceTable-undefined', 'Udefinert');

						group.Result.forEach(function (property) {
							var value = parseFloat(property.Value);
							if (!value)
								value = 0;

							if (property.Property === "EstimatedTime")
								$scope.totalEstimatedTime += value;
							if (property.Property === "RealTime")
								$scope.totalRealTime += value;

							if (property.Property === "EstimatedCost")
								$scope.totalEstimatedCost += value;
							if (property.Property === "RealCost")
								$scope.totalRealCost += value;
						});
					});

					var data = sortTable(result, params.sorting());

					$scope.table.settings().count = result.length;
					$scope.changingPage = false;

					if ($scope.firstPage)
						resourceGroupPage.sorting = params.sorting();
					else
						hourTypePage.sorting = params.sorting();

					$defer.resolve(data);
				});
			}
		});

		var sortTable = function (tableData, sorting) {
			if (!sorting) return tableData;

			var isNum = false;
			$scope.tableColumns.forEach(function (col) {
				if (col.Property === Object.keys(sorting)[0]) {
					isNum = col.PropertyType === 'double' || col.PropertyType === 'number';
				}
			});


			// Check and sort by the Grouped column
			var groupKey = $scope.firstPage ? resourceGroupPage.kpiObject.RowGroup : hourTypePage.kpiObject.RowGroup;
			if (sorting[groupKey]) {
				if (sorting[groupKey] === 'asc') {
					if (isNum)
						tableData.sort(function (a, b) { return a.GroupCaption - b.GroupCaption;});
					else
						tableData.sort(function (a, b) {
							if (a.GroupCaption < b.GroupCaption) return -1;
							if (a.GroupCaption > b.GroupCaption) return 1;
							return 0;
						});
				}
				else {
					if (isNum)
						tableData.sort(function (a, b) { return b.GroupCaption - a.GroupCaption; });
					else
						tableData.sort(function (a, b) {
							if (a.GroupCaption < b.GroupCaption) return -1;
							if (a.GroupCaption > b.GroupCaption) return 1;
							return 0;
						}).reverse();
				}
			}
			else {
				// Else sort by the inner list
				tableData.sort((function (a, b) {
					var valA, valB;
					a.Result.forEach(function (property) { if (sorting[property.Property]) valA = property.Value; });
					b.Result.forEach(function (property) { if (sorting[property.Property]) valB = property.Value; });
					if (isNum) {
						if (valA - valB > 0)
							return -1;
						else if (valA - valB < 0)
							return 1;
						else
							return 1;
					}
					else {
						if (valA > valB)
							return -1;
						else if (valA < valB)
							return 1;
						else
							return 1;
					}
				}));
				if (sorting[Object.keys(sorting)[0]] === 'asc')
					tableData.reverse();
			}

			return tableData;
		};

		$scope.getPropertyValue = function (item, column) {
			if (item.Property === column.Property)
				return item.Value;
		};
	}
})();
