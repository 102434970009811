(function () {
	angular.module('Plania').controller('OperationalMessageModalController', ['$scope', 'Repository', 'params', '$modalInstance', '$modal', '$localStorage', 'TranslationService', 'OperationalMessageService', controller]);
	function controller($scope, repository, params, $modalInstance, $modal, $localStorage, translationService, operationalMessageService) {
		$scope.isUpdate = !!params.guid;
		$scope.activateAutocomplete = false;

		// Extract parameters
		$scope.model = { canCreateDowntime: false };
		$scope.guid = params.guid;
		$scope.parentGuid = params.parentGuid;
		$scope.parentEntityType = params.parentEntityType;
		$scope.parentModel = params.parentModel;

		$scope.timepickerOptions = {
			hstep: 1,
			mstep: 5
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner && $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.OperationalMessage, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.OperationalMessage, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.OperationalMessage);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.OperationalMessage);
			}
		};
		updateAccess();

		$scope.onWorkOrderSelect = function (workOrder) {
			if (!workOrder) {
				$scope.model.GuidWorkOrder = "";
				return;
			}

			$scope.model.GuidWorkOrder = workOrder.Guid;
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipment = "";
				$scope.model.Type = "Information";
				$scope.model.canCreateDowntime = false;
				return;
			}

			$scope.model.GuidEquipment = equipment.Guid;
			$scope.model.canCreateDowntime = equipment.CanHaveDowntime;
			if (!$scope.model.canCreateDowntime)
				$scope.model.Type = "Information";
			else {
				$scope.model.Type = "Downtime";
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = equipment.GuidEstate || equipment.Building.Estate.Guid;
			}

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
			}
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.model.Type = "Information";
				$scope.model.canCreateDowntime = false;
			}

			$scope.model.GuidBuilding = building.Guid;
			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
			}
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.model.GuidEquipment = "";
				$scope.model.Type = "Information";
				$scope.model.canCreateDowntime = false;
			}
			$scope.model.GuidEstate = estate.Guid;
		};

		var updateDateTexts = function () {
			$scope.model.startDateDisplayText = operationalMessageService.getStartDateText($scope.model);
			$scope.model.dueDateDisplayText = operationalMessageService.getDueDateText($scope.model);
		};

		var columns = [
			'Estate.Id', 'Estate.Description',
			'Building.Id', 'Building.Description',
			'Equipment.Id', 'Equipment.Description', 'Equipment.CanHaveDowntime',
			'WorkOrder.Id', 'WorkOrder.Description',
			'ContactPerson.FirstName', 'ContactPerson.LastName',
			'UserUpdatedBy.RealName', 'UserUpdatedBy.RealName'
		];

		$scope.setupAutoSelect = function () {
			angular.element(document.querySelectorAll('.time-picker-container input')).on('focus', function () {
				this.select();
			});
		};

		$scope.$watch('model.StartDate', function (newValue) {
			if (newValue) {
				setTimeout(function () { $scope.setupAutoSelect(); }, 250);
			}
		});

		$scope.$watch('model.DueDate', function (newValue) {
			if (newValue) {
				setTimeout(function () { $scope.setupAutoSelect(); }, 250);
			}
		});

		var getMessage = function () {
			repository.getSingle(repository.apiData.operationalMessage.url, params.guid, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;

				if ($scope.model.Equipment)
					$scope.model.canCreateDowntime = $scope.model.Equipment.CanHaveDowntime;

				if ($scope.model.StartDate) {
					$scope.model.StartTime = new Date($scope.model.StartDate);
				}

				if ($scope.model.DueDate) {
					$scope.model.DueTime = new Date($scope.model.DueDate);
				}

				updateDateTexts();
				updateAccess();
				setTimeout(function () { $scope.activateAutocomplete = true; $scope.setupAutoSelect(); }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		if (params.guid) {
			getMessage();
		} else {
			$scope.model = {};

			// Set guid and display parent info if sent from caller.
			if ($scope.parentGuid && $scope.parentEntityType) {
				$scope.model['Guid' + $scope.parentEntityType] = $scope.parentGuid;
				if ($scope.parentModel && $scope.parentModel.Caption)
					$scope.model[$scope.parentEntityType] = $scope.parentModel;
			}

			if ($scope.parentEntityType === "WorkOrder") {
				// Set guid, display info and rule about downtime from incomming WorkOrder models reference to equipment. This is dependent that the caller actually sends a correct model.
				if ($scope.parentModel && $scope.parentModel.GuidEquipment) {
					$scope.model.GuidEquipment = $scope.parentModel.GuidEquipment;
					if ($scope.parentModel.Equipment && $scope.parentModel.Equipment.Caption) {
						$scope.model.canCreateDowntime = $scope.parentModel.Equipment.CanHaveDowntime;
						$scope.model.Equipment = $scope.parentModel.Equipment;
					}
				}

				// Set guid and display info about building.
				if ($scope.parentModel && $scope.parentModel.GuidBuilding) {
					$scope.model.GuidBuilding = $scope.parentModel.GuidBuilding;
					if ($scope.parentModel.Building && $scope.parentModel.Building.Caption) {
						$scope.model.Building = $scope.parentModel.Building;
					}
				}

				// Set guid and display info about estate.
				if ($scope.parentModel && $scope.parentModel.GuidEstate) {
					$scope.model.GuidEstate = $scope.parentModel.GuidEstate;
					if ($scope.parentModel.Estate && $scope.parentModel.Estate.Caption) {
						$scope.model.Estate = $scope.parentModel.Estate;
					}
				}

			} else if ($scope.parentEntityType === "Equipment") {
				$scope.model.canCreateDowntime = $scope.parentModel && $scope.parentModel.CanHaveDowntime;

				// Set guid and display info about building.
				if ($scope.parentModel && $scope.parentModel.GuidBuilding) {
					$scope.model.GuidBuilding = $scope.parentModel.GuidBuilding;
					if ($scope.parentModel.Building && $scope.parentModel.Building.Caption) {
						$scope.model.Building = $scope.parentModel.Building;
					}
				}

				// Set guid and display info about estate.
				if ($scope.parentModel && $scope.parentModel.Building && ($scope.parentModel.Building.GuidEstate || $scope.parentModel.Building.Estate)) {
					$scope.model.GuidEstate = $scope.parentModel.Building.GuidEstate ? $scope.parentModel.Building.GuidEstate : $scope.parentModel.Building.Estate.Guid;
					if ($scope.parentModel.Building.Estate && $scope.parentModel.Building.Estate.Caption) {
						$scope.model.Estate = $scope.parentModel.Building.Estate;
					}
				}
			} else if ($scope.parentEntityType === "Building") {
				// Set guid and display info about estate.
				if ($scope.parentModel && $scope.parentModel.GuidEstate) {
					$scope.model.GuidEstate = $scope.parentModel.GuidEstate;
					if ($scope.parentModel.Estate && $scope.parentModel.Estate.Caption) {
						$scope.model.Estate = $scope.parentModel.Estate;
					}
				}
			}

			// Set default values.
			$scope.model.Type = $scope.model.canCreateDowntime ? "Downtime" : "Information";
			$scope.model.StartDate = new Date().setMinutes(0);
			$scope.model.StartTime = new Date().setMinutes(0);

			updateAccess();
			$scope.activateAutocomplete = true;
		}

		$scope.save = function (action) {
			var saveDto = angular.copy($scope.model);

			if (saveDto.DueDate) {
				var dueDate = new Date(saveDto.DueDate);

				if (saveDto.DueTime) {
					var dueTime = new Date(saveDto.DueTime);
					dueDate.setHours(dueTime.getHours(), dueTime.getMinutes(), dueTime.getSeconds());
				}
				saveDto.DueDate = dueDate.toISOString();
			}

			if (saveDto.StartDate) {
				var startDate = new Date(saveDto.StartDate);

				if (saveDto.StartTime) {
					var startTime = new Date(saveDto.StartTime);
					startDate.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
				}
				saveDto.StartDate = startDate.toISOString();
			}

			if (params.guidEquipment) saveDto.GuidEquipment = params.guidEquipment;

			delete saveDto.Estate;
			delete saveDto.Building;
			delete saveDto.Area;
			delete saveDto.Equipment;
			delete saveDto.ContactPerson;
			delete saveDto.WorkOrder;

			if (params.guid) {
				repository.updateSingle(repository.apiData.operationalMessage.url, saveDto).then(function (result) {
					repository.growl(translationService.translate('web-operationalMessage-updated', 'Driftsmelding oppdatert'), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingle(repository.apiData.operationalMessage.url, saveDto).then(function (result) {
					repository.growl(translationService.translate('web-operationalMessage-created', "Driftsmeldingen ble lagt til"), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "building":
					if ($scope.model.GuidEstate)
						return { PropertyFilter: [{ Property: "GuidEstate", Operator: "=", Value: $scope.model.GuidEstate }] };
					return filter;
				case "equipment":
					filter.PropertyFilter = [{ Property: 'IsTemplate', Operator: '=', Value: false }];
					if ($scope.model.GuidBuilding)
						filter.PropertyFilter.push({ Property: "GuidBuilding", Operator: "=", Value: $scope.model.GuidBuilding });
					else if ($scope.model.GuidEstate)
						filter.PropertyFilter.push({ Property: "Building.GuidEstate", Operator: "=", Value: $scope.model.GuidEstate });
					return filter;
				case "workorder":
					filter.PropertyFilter = [];
					if ($scope.model.GuidBuilding)
						filter.PropertyFilter.push({ Property: "GuidBuilding", Operator: "=", Value: $scope.model.GuidBuilding });
					else if ($scope.model.GuidEstate)
						filter.PropertyFilter.push({ Property: "GuidEstate", Operator: "=", Value: $scope.model.GuidEstate });
					filter.PropertyFilter.push({ Property: "EndDate", Operator: "=", Value: null });
					return filter;
				default:
					return filter;
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
