(function () {
    angular.module('Plania').factory('PersistentDataService', ['$localStorage', persistentDataService]);

	function persistentDataService($localStorage) {
		var getPersistedData = function (key) {
			if (!$localStorage.persistedData) return;
            return $localStorage.persistedData[key];
        };

        var setPersistedData = function (key, data) {
            if (!$localStorage.persistedData)
                $localStorage.persistedData = {};
            $localStorage.persistedData[key] = data;
        };

		var clearPersistedData = function (key) {
			if (key) {
				delete $localStorage.persistedData[key];
			} else {
				delete $localStorage.persistedData;
			}
        };

        return {
            getPersistedData: getPersistedData,
            setPersistedData: setPersistedData,
            clearPersistedData: clearPersistedData
        };
    }
})();
