(function () {
	angular.module('Plania').directive('reactBimViewer', ['$rootScope', function ($rootScope) {
		return {
			restrict: 'E',
			scope: {

			},
			link: function (scope, element, attrs) {
				scope.props = {
					reload: true,
				};
				scope.$on($rootScope.events.newSelection, function () {
					scope.props.reload = !scope.props.reload;
				});

			},
			template: '<react-component props="props" component-name="ViewBimPage"></react-component>'
		};
	}]);
})();
