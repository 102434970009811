/*
 * Directive for displaying a toolbar on a page containing a plania table. Contains the search field and the select list-setup dropdown as well as its edit button.
 */
(function () {

	var app = angular.module('Plania');

	app.directive('plListToolbar', function () {
		return {
			restrict: 'E',
			scope: {
				listTable: '=listTable',
				listOptions: '=listOptions',
				selectedListSetupBinding: '=selectedListSetupBinding',
				updateListView: '=updateListView',
				prefix: '=prefix',
				hideSettings: '=hideSettings',
				hideSearch: '=hideSearch',
			},
			controller: ['$scope', '$modal', '$rootScope', 'Repository', 'TranslationService', '$localStorage', 'ListService', '$state', 'ListCompressionService', controller],
			link: function (scope, element, attrs) {

			},
			templateUrl: 'app/common/directives/views/planiaListToolbar.html'
		};
	});

	function controller($scope, $modal, $rootScope, repository, translationService, $localStorage, listService, $state, listCompressionService) {
		$scope.userData = repository.authService.getUserData();
		$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebListView, false);
		$scope.restrictDefaultViewChange = !repository.authService.hasEditAccess("UserXWebListView");
		$scope.runningAction = false;
		$scope.isSelectedListSetupOverridenByWebMenu = false;
		$scope.isSelectedListSetupOverridenByUrlFilter = false;

		var selectedListSetupWatcher = $scope.$watch('selectedListSetupBinding', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			if (newVal) {
				var filterString = repository.location.search().filter;
				if ($scope.isSelectedListSetupOverridenByWebMenu || $scope.isSelectedListSetupOverridenByUrlFilter) {
					$scope.isSelectedListSetupOverridenByWebMenu = false;
					$scope.isSelectedListSetupOverridenByUrlFilter = false;
					selectedListSetupWatcher();
				}
				else if ($rootScope.navigation && $rootScope.navigation.params && $rootScope.navigation.params.menuGuid) {
					menuParams = repository.getMenuParameters($rootScope.navigation.params.menuGuid);
					if (menuParams && menuParams.selectedWebListView) {
						if (menuParams.selectedWebListView.Guid === newVal.Guid)
							$scope.isSelectedListSetupOverridenByWebMenu = true;
					}
				}
				else if (filterString) {
					try {
						var urlFilter = listCompressionService.decodeListUri(filterString);
						if (urlFilter && urlFilter.selectedListSetup && urlFilter.selectedListSetup.Guid === newVal.Guid) {
							$scope.isSelectedListSetupOverridenByUrlFilter = true;
						}
					} catch (e) {
					}
				}
			}
		});

		$scope.$watch('listTable.filter().searchString', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (!$localStorage.listFilters)
				$localStorage.listFilters = {};

			$localStorage.listFilters[$scope.selectedListSetupBinding.EntityType] = newValue;
		});

		$scope.$watch('listTable.filter().ActivityStatus', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			var prefix = $scope.prefix;
			if (!prefix) prefix = $state.params.entityData.prefix;
			switch (prefix) {
				case 'WorkOrder':
					$localStorage.workOrderActivityStatus = newValue;
					break;
				case 'Project':
					if ($state.current.name !== 'projectTemplate.list')
						$localStorage.projectActivityStatus = newValue;
					break;
			}

		});

		$scope.getSelectDefaultViewTooltipText = function () {
			if (!$scope.selectedListSetupBinding) return;

			var tooltipText = '';

			if ($scope.selectedListSetupBinding.IsDefaultView) {
				tooltipText += translationService.translate('web-planiaListToolbar-tooltip-isDefaultView', 'Dette er din standard visning.') + '\n';
				tooltipText += translationService.translate('web-planiaListToolbar-tooltip-removeDefaultView', 'Trykk for å tilbakestille til Plania Standard.') + '\n';
			}
			else
				tooltipText += translationService.translate('web-planiaListToolbar-tooltip-addDefaultView', 'Sett som standardvisning.') + '\n';

			if ($scope.isSelectedListSetupOverridenByWebMenu && !$scope.selectedListSetupBinding.IsDefaultView)
				tooltipText += translationService.translate('web-planiaListToolbar-tooltip-selectedListSetupOverridenByWebMenu', 'Visning ble overstyrt av webmeny.');
			if ($scope.isSelectedListSetupOverridenByUrlFilter && !$scope.selectedListSetupBinding.IsDefaultView)
				tooltipText += translationService.translate('web-planiaListToolbar-tooltip-selectedListSetupOverridenByUrlFilter', 'Visning ble overstyrt av systemet.');
			return tooltipText;
		};

		$scope.setSelectedListSetupAsDefaultView = function () {
			if (!$scope.selectedListSetupBinding) return;
			$scope.runningAction = true;

			var selectedListCopy = _.cloneDeep($scope.selectedListSetupBinding);
			selectedListCopy.Columns = [];
			delete selectedListCopy.VisibleColumns;
			repository.runAction(repository.apiData.webListView.url + "setDefaultView/", selectedListCopy).then(function (result) {
				$scope.runningAction = false;
				$scope.isSelectedListSetupOverridenByWebMenu = false;
				$scope.isSelectedListSetupOverridenByUrlFilter = false;
				if (!result) return;

				$scope.selectedListSetupBinding.IsDefaultView = result.IsDefaultView;

				$scope.listOptions.forEach(function (option) {
					// Guid empty is Plania Standard
					if (result.Id === 'Plania standard' && option.Id === result.Id)
						option.IsDefaultView = result.IsDefaultView;
					else if (result.Guid === option.Guid)
						option.IsDefaultView = result.IsDefaultView;
					else
						option.IsDefaultView = false;
				});

				if (_.every($scope.listOptions, { IsDefaultView: false })) {
					$scope.listOptions[0].IsDefaultView = true;
				}
			}, function (error) {
				repository.growl(error, 'danger');
				$scope.runningAction = false;
			});
		};

		$scope.openListSetupModal = function () {
			var size = $scope.restrictEdit ? 'md' : 'lg';
			$modal.open({
				size: size,
				controller: 'ListSetupModalController',
				templateUrl: 'app/common/directives/views/editListSetupModal.html',
				resolve: {
					params: function () { return { listSetup: $scope.selectedListSetupBinding, hideFilters: false }; }
				}
			}).result.then(function (result) {
				if (result === 'saved' || result === 'ok') {
					$scope.listTable.$params.sorting = $scope.selectedListSetupBinding.Sorting;

					$scope.selectedListSetupBinding.Columns.forEach(function (column) {
						if (column.Property === 'CleaningEstimateYear' || column.Property === 'EstimateTimeYear' || column.Property === 'NextCleaning')
							column.SortingDisabled = true;
					});

					if ($scope.selectedListSetupBinding.GroupBy) {
						$scope.listTable.settings().groupBy = function (obj) {
							var prefix = $scope.prefix;
							if (!prefix) prefix = $state.params.entityData.prefix;
							return listService.getGroupName(prefix, obj);
						};
					} else {
						$scope.listTable.settings().groupBy = null;
					}
					// if we use the list without saving a new, VisibleColumns won't be updated from listController.js
					$scope.selectedListSetupBinding.VisibleColumns = _.filter($scope.selectedListSetupBinding.Columns, function (c) {
						if (c.Filter && c.Filter.some(function (f) { return f.Hidden; }))
							return false;
						return true;
					});

					if ($scope.selectedListSetupBinding.IsPublicView) {
						$scope.selectedListSetupBinding.isPublicViewText = translationService.translate('web-webListView-isPublicViewText-common', 'Felles');
					} else {
						$scope.selectedListSetupBinding.isPublicViewText = translationService.translate('web-webListView-isPublicViewText-personal', 'Personlig');
					}

					// Sync some options locally
					if ($scope.selectedListSetupBinding.Id !== 'Plania standard') {
						$scope.listOptions.forEach(function (option) {
							if (option.Guid && option.Guid === $scope.selectedListSetupBinding.Guid) {
								option.IsDefaultView = $scope.selectedListSetupBinding.IsDefaultView;
								option.IsPublicView = $scope.selectedListSetupBinding.IsPublicView;
								option.isPublicViewText = $scope.selectedListSetupBinding.isPublicViewText;
								option.VisibleColumns = $scope.selectedListSetupBinding.VisibleColumns;
								option.Columns = $scope.selectedListSetupBinding.Columns;
								option.Id = $scope.selectedListSetupBinding.Id;
								option.Sorting = $scope.selectedListSetupBinding.Sorting;
								option.GuidDataOwner = $scope.selectedListSetupBinding.GuidDataOwner;
							}
						});
					}

					$scope.listTable.reload();
					return;
				} else if (result === 'deleted') {
					$scope.updateListView(null, true);
					$scope.listTable.reload();
					return;
				} else if (result === 'savedNew') {
					$scope.updateListView($scope.selectedListSetupBinding, true);
					$scope.listTable.reload();
					return;
				}
			}, function () {
				//closed
			});
		};
	}
})();
