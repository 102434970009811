(function () {
    angular.module('Plania').controller('CreatePeriodicTaskXSparePartController', ['$scope', 'ngTableParams', '$modalInstance', 'params', 'Repository', 'ListService', 'TranslationService', controller]);

    function controller($scope, ngTableParams, $modalInstance, params, repository, listService, translationService) {
        $scope.model = { Quantity: 0 };
        $scope.update = params.update;
        $scope.tabs = [
            { heading: translationService.translate('web-sparepart-chooseSparePart', "Velg reservedel"), step: 1 },
            { heading: translationService.translate('web-sparepart-setNumber', "Sett antall"), step: 2 }
        ];

        //Used on sparePartWithdraw
        if (params.withdraw) {
            $scope.modelProperties = { quantityToWithdraw: 0 };
        }

        $scope.title = $scope.update ? translationService.translate('web-sparepart-edit-title', "Rediger reservedel") : translationService.translate('web-sparepart-new-title', "Legg til ny reservedel");

        $scope.step = $scope.update ? 2 : 1;

        $scope.setCurrentStep = function (step) {
            $('.tab-content').css('overflow', 'hidden');
            setTimeout(function () {
                $('.tab-content').css('overflow', 'visible');
            }, 500);
            $scope.step = step;
        };

        var sparePartColumns = [
            { Title: translationService.translate('web-component-id', 'Id'), Property: 'Component.Id' },
            { Title: translationService.translate('web-component-description', 'Beskrivelse'), Property: 'Component.Description' },
            { Title: translationService.translate('web-componentXSupplier-partnumber', 'Delenummer'),Property: 'ComponentXSupplier.PartNumber' },
            { Title: translationService.translate('web-componentXSupplier-Manufacturer', 'Fabrikat'), Property: 'ComponentXSupplier.Manufacturer' },
            { Title: translationService.translate('web-componentXSupplier-Supplier', 'Leverandør'), Property: 'ComponentXSupplier.Supplier.Description' },
            { Property: 'ComponentXSupplier.Price', hidden: true },
            { Property: 'Inventory', hidden: true },
            { Property: 'Component.Unit', hidden: true },
            { Property: 'Component.GuidImage', hidden: true }
        ];

        $scope.columns = _.filter(sparePartColumns, function (c) { return !c.hidden; });

        $scope.getPropertyValue = function (item, column) {
            return listService.GetPropertyValue(item, column);
        };

        if ($scope.update) {
            var columns = [
                'SparePart.ComponentXSupplier.PartNumber', 'SparePart.Inventory', 'SparePart.Component.Unit',
                'SparePart.Component.GuidImage', 'SparePart.Component.Id', 'SparePart.Component.Description'
            ];

            repository.getSingle(repository.apiData.periodicTaskXSparePart.url, params.guid, JSON.stringify(columns))
                .then(
                function (result) {
                    $scope.model = result.Data;
                    $scope.model.Inventory = result.Data.SparePart.Inventory;
                    $scope.model.Unit = result.Data.SparePart.Component.Unit;
                    $scope.model.GuidImage = result.Data.SparePart.Component.GuidImage;
                    if ($scope.modelProperties)
                        $scope.modelProperties.quantityToWithdraw = $scope.model.Quantity - $scope.model.QuantityWithdrawn;
                });
        } else {
            $scope.sparePartTable = new ngTableParams({
                page: 1,
                count: 10,
                sorting: {},
                filter: {}
            },
                {
                    total: 0,
                    counts: [10, 50, 100],
                    paginationMaxBlocks: 8,
                    getData: function ($defer, params) {
                        var columns = _.map(sparePartColumns, 'Property');


                        repository.GetPaginated(repository.apiData.sparePart.url,
                            params.page() - 1,
                            params.count(),
                            params.sorting(),
                            params.filter(),
                            null,
                            JSON.stringify(columns))
                            .then(
                            function (result) {
                                params.total(result.TotalCount);
                                $defer.resolve(result.List);
                            },
                            function (error) {
                                repository.growl(error, 'danger');
                            });
                    }
                });
        }

        $scope.chooseSparePart = function (item) {
            if ($scope.itemExcluded(item.Guid)) {
                return;
            }

            $scope.model = {
                GuidSparePart: item.Guid,
                Id: item.Component.Id,
                Description: item.Component.Description,
                Inventory: item.Inventory, //transient property for ui
                Unit: item.Component.Unit, //transient property for ui
                GuidImage: item.Component.GuidImage,
                Quantity: 0
            };
            if (item.ComponentXSupplier) {
                $scope.model.PartNumber = item.ComponentXSupplier.PartNumber;
                $scope.model.Price = item.ComponentXSupplier.Price;
            }
            $scope.setCurrentStep(2);
        };

        $scope.itemExcluded = function (guid) {
            return _.contains(params.excludeGuids, guid);
        };

        $scope.save = function () {
            delete $scope.model.Inventory;
            delete $scope.model.Unit;
            delete $scope.model.GuidUmage;

            if (params.withdraw) {
                $scope.model.QuantityWithdrawn = $scope.model.QuantityWithdrawn + $scope.modelProperties.quantityToWithdraw;
            }

            $modalInstance.close($scope.model);
        };

        $scope.cancel = function () {
            $modalInstance.close();
        };
    }

})();
