(function () {

    var app = angular.module('Plania');

    app.directive('plDatepicker', function () {
        return {
            restrict: 'E',
            scope: {
                id: '@',
                name: '@',
                ngModel: '=',
                ngDisabled: '=',
                floatingLabel: '@', // Allow for fg-label, buggy if the input field is clicked
                calendarMode: '@' // Predefined calendar modes
            },
            require: '^ngModel',
            controller: ['$scope', 'Constants', controller],
            templateUrl: 'app/common/directives/views/plDatepickerDirective.html',
            link: linkFunction,
        };
    });

    function controller($scope, constants) {
        $scope.datepickerProps = {
            isOpen: false,
            focus: false
        };

        if (!$scope.id) $scope.id = $scope.name;
        if (!$scope.name) $scope.name = $scope.id;

        $scope.dateOptions = constants.dateOptions;


        if ($scope.calendarMode === 'month') {
            $scope.placeholder = 'mm.yyyy';
            $scope.mode = "'month'";
            $scope.popup = "MMMM yyyy";
            $scope.dateOptions.minMode = 'month';
        }
        else {
            $scope.placeholder = 'dd.mm.yyyy';
            $scope.mode = "'day'";
            $scope.popup = "dd.MM.yyyy";
            $scope.dateOptions.minMode = 'day';
        }
        if ($scope.floatingLabel) {
            $scope.placeholder = "";
        }

        $scope.dateOptions.plIgnoreFocus = true;

        $scope.open = function ($event) {
            if ($scope.ngDisabled) {
                return;
            }
            $event.preventDefault();
            $event.stopPropagation();
            $scope.datepickerProps.isOpen = !$scope.datepickerProps.isOpen;

        };
    }

    var linkFunction = function (scope, element, attrs, ngModelCtrl) {

        var attr = '';

        scope.attrs = {
            "class": "",
            "id": "",
            "inputclass": "",
            "inputid": "",
        };
        for (var a in attrs) {
            attr = a.replace('attr', '').toLowerCase();
            if (a.indexOf('attr') === 0) {
                scope.attrs[attr] = attrs[a];
            }
        }

        scope.updateModel = function (item) {
            ngModelCtrl.$setViewValue(item);
        };
    };
})();
