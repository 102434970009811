(function () {
	var app = angular.module('Plania');
	app.directive('childEntitiesListSetup', function () {
		return {
			restrict: 'E',
			scope: {
				prefix: '=prefix', //mandatory prefix of the entity to be fetched

				titleSystemName: '=titleSystemName',//optional override for the system name translation key
				titleDefaultName: '=titleDefaultName',//optional override for the default name for the title
				title: '=title', //otional string for the title that is already translated (do not use together with titleDefault and System name)
				subtitleSystemName: '=subtitleSystemName',//optional override for the system name translation key
				subtitleDefaultName: '=subtitleDefaultName',//optional override for the default name for the subtitle
				subtitle: '=subtitle', //otional string for the subtitle that is already translated (do not use together with subtitleDefault and System name)

				reloadTable: '=reloadTable', //optional boolean to allow fetching all data for each object.
				columns: '=columns', //optional list of strings to identity columns to be fetched (included with columns from selected list setup)
				filter: '=filter', //optional filter to be applied to the query
				selectedDropdownFilter: '=selectedDropdownFilter', //optional dropdown filter that is currently selected. filter: {  value: any, name: string }
				dropdownFilters: '=dropdownFilters', //optional list of dropdown filters. filter: { value: any, name: string }
				applyDropdownFilter: '=applyDropdownFilter', //optional function to run when a dropdown filter is selected. used to modify filter object.

				listContext: '=listContext', //optional string to activate usage of listOptions
				contextMenuOptions: '=contextMenuOptions', //optional override for contextMenuOptions
				onClick: '=onClick', //optional function to allow custom handling of clicks on rows
				actions: '=actions', //optional list of actions to be shown in header. action: { icon: string, onClick: function, tooltip: string }
				canPrint: '=canPrint', //optional boolean to control if the list is able to be exported
			},
			controller: ['$scope', 'Repository', 'TranslationService', 'NgTableParams', 'ListService', '$rootScope', controller],
			templateUrl: 'app/common/directives/views/childEntitiesListSetup.html'
		};
	});

	function controller($scope, repository, translationService, ngTableParams, listService, $rootScope) {
		$scope.search = {};
		$scope.collapse = { isEntitiesCollapsed: false };
		$scope.apiData = _.find(repository.apiData, function (o) { return o.prefix === $scope.prefix; });

		if (!$scope.title && (!$scope.titleSystemName || !$scope.titleDefaultName)) {
			$scope.titleSystemName = 'web-childEntities-' + $scope.prefix + '-title';
			$scope.titleDefaultName = translationService.translate('web-' + $scope.prefix, $scope.prefix).replace(':', '');
		}

		$scope.onEntityClick = function (entity, event) {
			if ($scope.onClick && typeof ($scope.onClick) === "function")
				$scope.onClick(entity, event);
		};

		$scope.onDropdownFilterClick = function (filter) {
			$scope.selectedDropdownFilter = filter;
			if ($scope.entityTable)
				$scope.entityTable.reload();
		};

		var initTable = function () {
			if ($scope.entityTable) return;

			$scope.entityTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: $scope.selectedListSetup.Sorting,
				filter: $scope.filter
			}, {
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 50,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					$scope.entitiesAreLoading = true;

					var columns = [];

					$scope.selectedListSetup.VisibleColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					if ($scope.columns) {
						$scope.columns.forEach(function (col) {
							var prop = col;
							if (prop.Property)
								prop = prop.Property;

							if (!columns.includes(prop))
								columns.push(prop);
						});
					}

					// Grouped data should filter first on grouped value.
					var sorting = params.sorting();

					if ($scope.selectedListSetup.GroupBy) {
						var groupSort = {};
						groupSort[$scope.selectedListSetup.GroupBy] = 'asc';

						// The sorted group need the first value to be the group column.
						for (var attrname in sorting) { groupSort[attrname] = sorting[attrname]; }
						sorting = groupSort;
						$scope.entityTable.$params.sorting = groupSort;
					}

					var filter = angular.copy(params.filter());
					if ($scope.applyDropdownFilter && typeof ($scope.applyDropdownFilter) === "function")
						$scope.applyDropdownFilter($scope.selectedDropdownFilter, filter);

					repository.GetPaginated($scope.apiData.url, params.page() - 1, params.count(), sorting, filter, null, JSON.stringify(columns)).then(function (result) {
						$scope.entityTable.settings().total = result.TotalCount;
						$scope.entityTable.settings().filterDelay = 500;
						$scope.totalCount = result.TotalCount;

						$defer.resolve(result.List);
						$scope.entitiesAreLoading = false;

					}, function (error) {
						$scope.entitiesAreLoading = false;
						repository.growl(error, 'danger');
					});
				}
			});
		};

		$scope.getGroupedByHeaderValue = function (groupByColumnValue) {
			if ($scope.selectedListSetup.GroupBy) {
				var column = _.find($scope.selectedListSetup.Columns,
					function (c) {
						return c.Property === $scope.selectedListSetup.GroupBy;
					});
				if (column) {
					if (column.PropertyType && column.PropertyType.startsWith('enum.')) {
						var enumType = column.PropertyType.split('.')[1];
						if (enumType) {
							return translationService.translate('web-enum-' + enumType + '-' + groupByColumnValue);
						}
					}
				}
			}
			return groupByColumnValue;
		};

		$scope.getWebListViews = function (selectedSetup, overwriteSelected) {
			listService.getWebListViews(selectedSetup, $scope.apiData.prefix, false, $scope.listContext, {}).then(function (result) {
				$scope.listOptions = result.listOptions;
				$scope.selectedListSetup = result.selectedListSetup;
				if ($scope.entityTable) {
					$scope.entityTable.reload();
				} else {
					initTable();
				}
			});
		};
		$scope.getWebListViews();

		$scope.$watch('selectedListSetup', function (newValue, oldValue) {
			if (oldValue === newValue || !$scope.entityTable)
				return;

			$scope.entityTable.$params.sorting = $scope.selectedListSetup.Sorting;
			if ($scope.selectedListSetup.GroupBy) {
				$scope.entityTable.settings().groupBy = function (obj) {
					if (!$scope.selectedListSetup.GroupBy) {
						return false;
					}

					var attrs = $scope.selectedListSetup.GroupBy.split('.');
					attrs.forEach(function (attr) {
						if (!obj) {
							obj = "";
						} else {
							obj = obj[attr];
						}
					});

					return obj;
				};
			} else {
				$scope.entityTable.settings().groupBy = null;
			}
			listService.updateSelectedListSetup($scope.apiData.prefix, $scope.selectedListSetup);
			$scope.entityTable.reload();
		});

		$scope.$watch('reloadTable', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			$scope.entityTable.reload();
		});

		$scope.getPropertyValue = function (column, row) {
			return listService.GetPropertyValue(column, row);
		};
	}
})();
