(function () {
	angular.module('Plania').controller('ControlListCreateDraftModalController', ['$scope', '$modalInstance', 'Repository', 'params', controller]);

	function controller($scope, $modalInstance, repository, params) {
		$scope.VersionComment = "";
		$scope.isRunningAction = false;
		$scope.guids = params.guids;

		// Select only one for now, in case we will allow for multiple controlLists to be generated.
		var firstGuid = $scope.guids[0];

		$scope.createDraft = function () {
			$scope.isRunningAction = true;

			var dto = {
				VersionComment: null
			};

			if ($scope.VersionComment.trim() !== "")
				dto.VersionComment = $scope.VersionComment;

			repository.runAction(repository.apiData.controlList.endpoint.takeDraft + firstGuid, dto, null, true).then(function (response) {
				$scope.isRunningAction = false;
				$modalInstance.close();
			}, function (error) {
				$scope.isRunningAction = false;
				repository.showError(error);
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
