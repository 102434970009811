(function () {
	angular.module('Plania').directive('filterOperatorSelect', ["TranslationService", function (translationService) {
		return {
			restrict: 'E',
			scope: {
				ngModel: '=',
				ngDisabled: '=',
				propertyType: '=',
				property: '=',
				useSearch: '=',
				callbackObject: '=', // optional, used to be sent back with the onUpdateOperators callback (ex. WebColumn object)
				onUpdateOperators: '=', // options, function callback to modify operators when setOperators run.
			},
			link: function link(scope, element, attrs) {
				scope.operators = [];

				var availableOperators = [
					"=",
					"<>",
					">",
					"<",
					">=",
					"<=",
					"&",
					"startswith",
					"in",
					"notin",
					"contains"
				];

				var isOperatorSupported = function (operator, propertyType) {
					var isSupported = false;

					switch (propertyType) {
						case 'string':
							isSupported = ["=", "<>", "startswith", "in", "notin", "contains"].includes(operator);
							break;
						case 'boolean':
						case 'bool':
							isSupported = ["=", "<>"].includes(operator);
							break;
						case 'double':
						case 'int':
						case 'number':
						case 'decimal':
							isSupported = ["=", "<>", ">", "<", ">=", "<="].includes(operator);
							break;
						case 'date':
							isSupported = ["=", "<>", ">", "<", ">=", "<="].includes(operator);
							break;
						case 'autocomplete':
							isSupported = operator !== "&";
							break;
						case 'enum':
							isSupported = ["=", "<>", ">", "<", ">=", "<=", "in", "notin"].includes(operator);
							break;
						default:
							isSupported = operator !== "&";
							break;
					}

					// If not natively supported, check property for overidden
					if (!isSupported) {
						if (operator === "&" && (scope.property === 'DocumentationContext' || scope.property === 'AccessType')) {
							isSupported = true;
						}
					}

					return isSupported;
				};

				var getOperatorName = function (operator, propertyType) {
					var name = 'Ukjent funksjon'; // No translation since we should pick this up during development of a new condition.
					switch (operator) {
						case "=":
							name = translationService.translate('web-filterOperatorSelect-operator-equal', '=');
							break;
						case "<>":
							name = translationService.translate('web-filterOperatorSelect-operator-notEqual', '<>');
							break;
						case ">":
							name = translationService.translate('web-filterOperatorSelect-operator-greaterThan', '>');
							break;
						case "<":
							name = translationService.translate('web-filterOperatorSelect-operator-lessThan', '<');
							break;
						case ">=":
							name = translationService.translate('web-filterOperatorSelect-operator-greaterThanOrEqual', '>=');
							break;
						case "<=":
							name = translationService.translate('web-filterOperatorSelect-operator-lessThanOrEqual', '<=');
							break;
						case "&":
							name = translationService.translate('web-filterOperatorSelect-operator-bitwiseAnd', '&');
							break;
						case "startswith":
							name = translationService.translate('web-filterOperatorSelect-operator-startswith', 'startswith');
							break;
						case "in":
							name = translationService.translate('web-filterOperatorSelect-operator-in', 'in');
							break;
						case "notin":
							name = translationService.translate('web-filterOperatorSelect-operator-notin', 'notin');
							break;
						case "contains":
							name = translationService.translate('web-filterOperatorSelect-operator-contains', 'contains');
							break;
					}
					return name;
				};

				var setOperators = function () {
					var operators = [];
					var basePropertyType = scope.propertyType.split(".")[0];

					availableOperators.forEach(function (operator) {
						if (isOperatorSupported(operator, basePropertyType)) {
							operators.push({
								value: operator,
								name: getOperatorName(operator, basePropertyType)
							});
						}
					});

					if (scope.onUpdateOperators && typeof (scope.onUpdateOperators) === "function")
						scope.onUpdateOperators(operators, scope.callbackObject);

					if (scope.ngModel && !_.find(operators, { value: scope.ngModel })) {
						var notSupportedOperatorName = translationService.translate('web-filterOperatorSelect-operator-notSupportedForProperty', 'Ikke støttet for felttype') + ": " + scope.ngModel;

						operators.unshift({
							value: scope.ngModel,
							name: notSupportedOperatorName
						});
					}

					scope.operators = operators;
				};

				setOperators();

				scope.$watch('propertyType', function (newVal, oldVal) {
					if (newVal === oldVal) return;
					setOperators();
				});
			},
			// Currently we use plain select inside these filters.
			// Adding chosen as a option - Check if ngModel is changes. Did not work with an ngIf to choose between two select components.
			template: '<select ng-disabled="ngDisabled" class="form-control clickable" ng-model="ngModel" ng-options="operator.value as operator.name for operator in operators"> </select>'
		};
	}]);
})();
