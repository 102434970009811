(function () {
	angular.module('Plania').controller('DwgWorkOrderController', ['$scope', 'Repository', 'params', 'NgTableParams', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, params, ngTableParams, modalInstance, translationService) {
		$scope.model = {};

		var columns = [
			'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid', 'Building.GuidEstate'
		];

		repository.getMainModel(repository.apiData.area.url, params.guid, JSON.stringify(columns)).then(function (result) {
			$scope.model = result.Data;

			$scope.workOrderTable = new ngTableParams({
				page: 1,
				count: 10,
				filter: {
					ActivityStatus: 'Active',
					PropertyFilter: [{
						Property: 'GuidArea',
						Operator: '=',
						Value: params.guid
					}]
				},
				sorting: { 'Id': 'asc' }
			}, {
				total: 0,
				counts: [],
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					repository.GetPaginated(repository.apiData.workOrder.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(['Id', 'Description', 'DueDate'])).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						},
						function (error) {
							$scope.isLoading = false;
							repository.growl(error, 'danger');
						});
				}
			});
		});

		$scope.addNewWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create', {
				guidEstate: $scope.model.Building.Estate.Guid,
				estate: $scope.model.Building.Estate.Id + ' - ' + $scope.model.Building.Estate.Description,
				guidBuilding: $scope.model.GuidBuilding,
				building: $scope.model.Building.Id + ' - ' + $scope.model.Building.Description,
				guidArea: $scope.model.Guid,
				area: $scope.model.Id + ' - ' + $scope.model.Description,
			});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
			$scope.ok();
		};

		$scope.navigate = function (destination, guid) {
			$scope.ok({});
			$scope.navigation.transitionTo(destination, { guid: guid });
		};

		$scope.ok = function (result) {
			modalInstance.close(result);
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};
	}
})();
