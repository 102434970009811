(function () {
	angular.module('Plania').controller('CreatePeriodicTaskXResourceGroupController', ['$scope', '$modalInstance', 'TranslationService', 'params', 'Repository', controller]);

	function controller($scope, $modalInstance, translationService, params, repository) {
		$scope.disableResourceGroupSelection = false;
		$scope.isLoaded = false;
		var costPrHour = 0;

		var setCost = function (newEstimatedTime) {
			if (!newEstimatedTime)
				newEstimatedTime = $scope.model.EstimatedTime;


			if ($scope.model.HourType && $scope.model.HourType.PriceType === 'Fixed') {
				costPrHour = $scope.model.HourType.Price;

			} else if ($scope.model.HourType && $scope.model.HourType.PriceType === 'Addition') {
				costPrHour = $scope.model.ResourceGroup.CostPrHour + $scope.model.HourType.Price;

			} else if ($scope.model.HourType && $scope.model.HourType.PriceType === 'Adjustment') {
				var cost = $scope.model.ResourceGroup.CostPrHour;
				costPrHour = cost + (cost * $scope.model.HourType.Price / 100);
			}

			if (newEstimatedTime)
				$scope.model.EstimatedCost = costPrHour * newEstimatedTime;
			else
				$scope.model.EstimatedCost = 0;


		};

		$scope.onResourceGroupSelect = function (resourceGroup) {
			if (!resourceGroup) {
				costPrHour = 0;
				$scope.model.EstimatedCost = 0;
				$scope.model.RealCost = 0;
				$scope.model.GuidResourceGroup = "";
				$scope.resourceGroup = null;
				$scope.onPersonSelect(null);
				return;
			}

			$scope.model.GuidResourceGroup = resourceGroup.Guid;

			$scope.model.ResourceGroup = resourceGroup;
			if (resourceGroup.CostPrHour) {
				costPrHour = resourceGroup.CostPrHour;
				if ($scope.model.EstimatedTime) {
					$scope.model.EstimatedCost = costPrHour * $scope.model.EstimatedTime;
				}
				$scope.model.RealCost = costPrHour * $scope.model.RealTime;
			}
		};

		$scope.onPersonSelect = function (person) {
			if (!person) {
				$scope.model.GuidPerson = "";
				$scope.model.GuidPersonResourceGroup = null;
				$scope.person = null;
				return;
			}
			$scope.model.GuidPerson = person.Guid;
			$scope.model.GuidPersonResourceGroup = person.GuidResourceGroup;
			if (person.GuidResourceGroup) {
				$scope.disableResourceGroupSelection = true;
				$scope.onResourceGroupSelect(person.ResourceGroup);
			}
		};

		$scope.onHourTypeSelect = function (hourType) {

			if (!hourType) {
				$scope.model.GuidHourType = '';
				$scope.model.HourType.Caption = null;
			} else {
				$scope.model.GuidHourType = hourType.Guid;
				$scope.model.HourType = hourType;
			}

			setCost();

		};

		$scope.model = {HourType: {}};
		// $scope.model.GuidWorkOrder = params.GuidWorkOrder;
		$scope.model.GuidPeriodicTask = params.GuidPeriodicTask;
		if (params.ResourceGroup) {
			if (params.ResourceGroup.Id)
				$scope.onResourceGroupSelect(params.ResourceGroup);
		}

		$scope.model.RegisteredDate = new Date();
		$scope.saveResource = function () {
			$scope.model.RegisteredDate = $scope.model.RegisteredDate.toISOString();
			//if (!$scope.model.RealCost) $scope.model.RealCost = 0;
			if (!$scope.model.EstimatedCost) $scope.model.EstimatedCost = 0;
			repository.createSingle(repository.apiData.periodicTaskXResourceGroup.url, $scope.model).then(function (result) {
				repository.growl(translationService.translate('create-periodic-task-resource-success', 'Ressursen har blitt knyttet til den periodiske rutinen'), 'success');
				$modalInstance.close('Resource have been addded to periodictask');
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "person":
					return { GuidResourceGroup: $scope.model.GuidResourceGroup, DoesMaintenanceTasks: true };
				case "resourceGroup":
					return { PropertyFilter: [{ Property: 'DoesMaintenanceTasks', Operator: '=', Value: '1' }] };
				case "hourType":
					return { PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: "" }] };
			}
		};

		$scope.$watch('model.EstimatedTime', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			setCost();
		}, true);

		$scope.$watch('model.RealTime', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			$scope.model.RealCost = (costPrHour * newValue) || 0;
		}, true);

		$scope.$watch('model.GuidPersonResourceGroup', function (newValue, oldValue) {
			$scope.disableResourceGroupSelection = !!newValue;
		}, true);
	}
})();
