(function () {
	angular.module('Plania').controller('SmsProviderController', ['$scope', 'Repository', 'Constants', 'TranslationService', '$localStorage', 'ngTableParams', controller]);

	function controller($scope, repository, constants, translationService, $localStorage, ngTableParams) {
		var smsTokenLength = 6;
		var smsUnitLength = 70;
		$scope.maxRecommendedSmsLength = smsUnitLength - smsTokenLength;

		$scope.maxSenderLength = 11;

		function initSmsConfiguration() {
			$scope.model = {};
			$scope.smsConfiguration = {};

			repository.getSingleWithNoParameter(repository.apiData.smsProvider.url).then(function (result) {
				$scope.smsConfiguration = result;
			}, function (error) {
				if (error === 'Ingen data funnet') {
					$scope.newConfiguration = true;
				} else {
					repository.growl(error, 'danger');
				}
			});
		}


		initSmsConfiguration();

		$scope.setDefaultConfiguration = function () {
			$scope.newConfiguration = false;
			var defaultConfig = constants.defaultSmsConfiguration;

			$scope.smsConfiguration.ServicePlanId = defaultConfig.ServicePlanId;
			$scope.smsConfiguration.AccessToken = defaultConfig.AccessToken;
			$scope.smsConfiguration.SenderId = defaultConfig.SenderId;
			$scope.smsConfiguration.Url = defaultConfig.Url;
			$scope.smsConfiguration.TemplateText = defaultConfig.TemplateText;
		};

		function isValid() {
			var valid = true;
			if ($scope.smsConfiguration.SenderId.length > $scope.maxSenderLength) {
				valid = false;
				repository.growl(translationService.translate('web-smsprovider-sendertoolong', 'Lengde på avsender navn kan ikke være lenger enn') + ' ' + $scope.maxSenderLength, 'danger');
			}
			return valid;
		}

		$scope.save = function () {
			$scope.newConfiguration = false;
			$scope.model = $scope.smsConfiguration;

			if (!isValid()) return;

			repository.updateSingle(repository.apiData.smsProvider.url, $scope.model)
				.then(function (result) {
						repository.growl('Lagring fullført', 'success');
				}, function (error) {
						repository.growl(error, 'danger');
				}
			);
		};

		$scope.delete = function () {
			if ($localStorage.generalOptions.TwoFactorAuthenticationOption > 0) {
				swal(translationService.translate('web-smsprovider-cannotdisable','Kan ikke slette konfigurasjon når tofaktor er påskrudd, vennligst skru den av i kundeprofilen først.'), "", "error");
				return;
			}
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-smsprovider-deletemessage', 'Konfigurasjonen slettes permanent!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-smsprovider-deletemessage-confirm', 'Ja, fjern konfigurasjon'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.smsProvider.url, $scope.model.Provider)
					.then(function (result) {
						repository.growl('Sletting fullført', 'success');
						initSmsConfiguration();
					}, function (error) {
							repository.growl(error, 'danger');
						}
					);
			});
		};

		if ($scope.hasReadAccess('TwoFactorToken')) {
			$scope.smsTable = new ngTableParams({
				page: 1,
				count: 50,
				sorting: { 'CreationDate': 'desc' }
			}, {
				total: 0,
				filterDelay: 10,
				counts: [50, 100, 200],
				getData: function ($defer, params) {
					var columns = ['PhoneNumber', 'CreationDate', 'Status', 'User.Username', 'DeviceRemembered'];

					repository.GetPaginated(repository.apiData.twoFactorToken.url, params.page() - 1, params.count(),
						params.sorting(), params.filter(), '', JSON.stringify(columns)).then(function (result) {

							$scope.smsTable.settings().total = result.TotalCount;
							$scope.smsTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}
					);
				}
			});
		}

		$scope.smsTableGetStatusColor = function(status) {
			switch (status) {
				case 'New':
					return 'text-info';
				case 'Used':
					return 'text-success';
				case 'NotUsed':
					return 'text-warning';
				case 'Failed':
					return 'text-danger';
			}
			return null;
		};
	}
})();
