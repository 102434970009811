(function () {
    angular.module('Plania').controller('PaymentTermController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', 'ListService', controller]);

    function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, listService) {
        $scope.isUpdate = $scope.navigation.current.name === 'paymentTerm.edit';
        $scope.model = {};


        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.PaymentTerm, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.PaymentTerm, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.PaymentTerm);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.PaymentTerm);
            }
        };

        if ($scope.isUpdate) {
            repository.getSingle(repository.apiData.paymentTerm.url, $stateParams.guid).then(function (response) {
                $scope.model = response.Data;
                $scope.reload = true;
                updateAccess();

            }, function (error) {
                repository.growl(error, 'danger');
            }
            );
        }
        else {
            $scope.model.Type = 0;

        }

        $scope.update = function (destination) {
            var success = function (result) {
                repository.growl($scope.isUpdate ? translationService.translate('update-payment-term-success', 'Betalingsbetingelse har blitt oppdatert.') : translationService.translate('create-payment-term-success', 'Betalingsbetingelse har blitt opprettet.'), 'success');
                var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
                repository.commonService.setLastRegisterGuid(destination, guid);
                $scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
            };

            var error = function (error) {
                if (typeof (error) === "string") {
                    repository.growl(error, 'danger');
                } else {
                    repository.growl(error.Data.Message, 'danger');
                }
            };

            if ($scope.isUpdate) {
                repository.updateSingle(repository.apiData.paymentTerm.url, $scope.model).then(success, error);
            } else {
                repository.createSingle(repository.apiData.paymentTerm.url, $scope.model).then(success, error);
            }

        };
    }
})();
