(function () {
	angular.module('Plania').controller('CleaningCompletionOverviewController', ['$scope', 'Repository', 'TranslationService', 'ListCompressionService', '$state', 'NgTableParams', 'ListService', '$sessionStorage', controller]);

	function controller($scope, repository, translationService, listCompressionService, $state, ngTableParams, listService, $sessionStorage) {
		$scope.selectedTreeViewItem = null;
		$scope.cleaningCompletionHistoryList = [];
		$scope.cleaningCompletionSummary = {};
		$scope.search = {
			history: false
		};


		function getSummaryData () {
			var filter = {
				PropertyFilter: []
			};
			if ($scope.selectedTreeViewItem) {
				filter.PropertyFilter.push({
					Property: $scope.selectedTreeViewItem.Prefix === 'Building' ? 'Area.GuidBuilding' : 'GuidArea',
					Value: $scope.selectedTreeViewItem.Guid,
					Operator: '='
				});
			}
			repository.GetPaginated(repository.apiData.cleaningCompletion.url + 'getSummary/', 0, -1, null, filter, null).then(
				function (result) {
					$scope.cleaningCompletionSummary = result;
				},
				function (error) {
					repository.growl(error, 'danger');
				}
			);
		}
		getSummaryData();

		$scope.getHref = function (status) {
			var propertyFilter = [];
			switch (status) {
				case 'expired':
					propertyFilter.push({ Property: 'CompletionStatus', Value: 0, Operator: '=' });
					break;
				case 'completed':
					propertyFilter.push({ Property: 'CompletionStatus', Value: 1, Operator: '=', Operand: 'OR' });
					propertyFilter.push({ Property: 'CompletionStatus', Value: -1, Operator: '=', Operand: 'OR' });
					break;
				case 'delayed':
					propertyFilter.push({ Property: 'CompletionStatus', Value: 2, Operator: '=' });
					break;
				case 'cancelled':
					propertyFilter.push({ Property: 'CompletionStatus', Value: 3, Operator: '=' });
					break;
				case 'completedByExitCleaning':
					propertyFilter.push({ Property: 'CompletionStatus', Value: 4, Operator: '=' });
					break;
				case 'paused':
					propertyFilter.push({ Property: 'CompletionStatus', Value: 5, Operator: '=' });
					break;
			}
			if ($scope.selectedTreeViewItem) {
				if ($scope.selectedTreeViewItem.Prefix === 'Building') {
					propertyFilter.push({ Property: 'Area.GuidBuilding', Value: $scope.selectedTreeViewItem.Guid, Operator: '=' });
				}
				else if ($scope.selectedTreeViewItem.Prefix === 'Area') {
					propertyFilter.push({ Property: 'GuidArea', Value: $scope.selectedTreeViewItem.Guid, Operator: '=' });
				}
			}
			var filters = listCompressionService.encodeListUri({ filter: { PropertyFilter: propertyFilter } });
			return $state.href('cleaningCompletion.list', { filter: filters });
		};

		if (!loadHistoryTableSetup()) {
			$scope.completionHistoryColumns = [
				{ Position: 1, Title: translationService.translate('web-cleaningCompletionHistory-year', 'År'), Property: 'Year', PropertyType: 'number', groupable: true, isGrouping: true, isVisible: false, sortDir: 'desc' },
				{ Position: 2, Title: translationService.translate('web-cleaningCompletionHistory-month', 'Måned'), Property: 'Month', PropertyType: 'enum.month', groupable: true, isGrouping: false, isVisible: true },
				{ Position: 3, Title: translationService.translate('web-cleaningCompletionHistory-estate', 'Eiendom'), Property: 'Estate', PropertyType: 'string', groupable: true, isGrouping: false, isVisible: false },
				{ Position: 4, Title: translationService.translate('web-cleaningCompletionHistory-building', 'Bygg'), Property: 'Building', PropertyType: 'string', groupable: true, isGrouping: false, isVisible: false },
				{ Position: 5, Title: translationService.translate('web-cleaningCompletionHistory-cleaningTask', 'Oppgave'), Property: 'CleaningTask', PropertyType: 'string', groupable: true, isGrouping: false, isVisible: true },
				{ Position: 6, Title: translationService.translate('web-cleaningCompletionHistory-cleaningType', 'Type'), Property: 'CleaningType', PropertyType: 'enum.cleaningType', groupable: true, isGrouping: false, isVisible: true },
				{ Position: 7, Title: translationService.translate('web-cleaningCompletionHistory-dataOwner', 'Klient'), Property: 'DataOwner', PropertyType: 'string', groupable: true, isGrouping: false, isVisible: false },
				{ Position: 8, Title: translationService.translate('web-cleaningCompletionHistory-areaClassification', 'Spesifikasjon'), Property: 'AreaClassification', PropertyType: 'string', groupable: true, isGrouping: false, isVisible: false },
				{ Position: 9, Title: translationService.translate('web-cleaningCompletionHistory-areaCleaningQuality', 'Kvalitet'), Property: 'AreaCleaningQuality', PropertyType: 'string', groupable: true, isGrouping: false, isVisible: false },
				{ Position: 10, Title: translationService.translate('web-cleaningCompletionHistory-sumCancelled', 'Kansellert'), Property: 'SumCancelled', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 11, Title: translationService.translate('web-cleaningCompletionHistory-sumCompleted', 'Fullført'), Property: 'SumCompleted', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: true },
				{ Position: 12, Title: translationService.translate('web-cleaningCompletionHistory-sumCompletedByExitCleaning', 'Fullført via utreisevask'), Property: 'SumCompletedByExitCleaning', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 13, Title: translationService.translate('web-cleaningCompletionHistory-sumDelayed', 'Utsatt'), Property: 'SumDelayed', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 14, Title: translationService.translate('web-cleaningCompletionHistory-sumEstimatedTime', 'Estimert tid'), Property: 'SumEstimatedTime', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 15, Title: translationService.translate('web-cleaningCompletionHistory-sumExitCleaningRequestStatusConfirmed', 'Bekreftet utreise'), Property: 'SumExitCleaningRequestStatusConfirmed', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 16, Title: translationService.translate('web-cleaningCompletionHistory-sumExitCleaningRequestStatusDischarged', 'Utreist'), Property: 'SumExitCleaningRequestStatusDischarged', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 17, Title: translationService.translate('web-cleaningCompletionHistory-sumExitCleaningRequestStatusPossible', 'Mulig utreise'), Property: 'SumExitCleaningRequestStatusPossible', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 18, Title: translationService.translate('web-cleaningCompletionHistory-sumExpired', 'Utgått'), Property: 'SumExpired', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: true },
				{ Position: 19, Title: translationService.translate('web-cleaningCompletionHistory-sumExtraTime', 'Ekstra tid'), Property: 'SumExtraTime', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
				{ Position: 19, Title: translationService.translate('web-cleaningCompletionHistory-sumPaused', 'Pauset'), Property: 'SumPaused', PropertyType: 'number', groupable: false, isGrouping: false, isVisible: false },
			];
		}

		$scope.visibleColumns = $scope.completionHistoryColumns.filter(function (col) { return col.isVisible && !col.isGrouping; });
		$scope.currentGroupColumn = $scope.completionHistoryColumns.find(function (col) { return col.isGrouping; });

		$scope.historyTable = new ngTableParams({
			page: 1,
			count: 20,
			sorting: { 'Month': 'desc' },
			filter: {}
		}, {
			total: 0,
			counts: [10, 20, 50],
			filterDelay: 10,
			groupBy: $scope.currentGroupColumn.Property,
			getData: function ($defer, params) {
				var sorting = {};
				var filter = angular.copy(params.filter());
				if (!filter.PropertyFilter)
					filter.PropertyFilter = [];

				var secondarySortKey = Object.keys(params.sorting())[0];
				var secondarySortDir = params.sorting()[secondarySortKey];
				if (!$scope.currentGroupColumn.sortDir)
					$scope.currentGroupColumn.sortDir = 'asc';

				if ($scope.currentGroupColumn.Property === secondarySortKey) {
					var firstVisibleColumn = $scope.visibleColumns[0];
					sorting[$scope.currentGroupColumn.Property] = $scope.currentGroupColumn.sortDir;
					sorting[firstVisibleColumn.Property] = 'asc';
					params.sorting(firstVisibleColumn.Property, 'asc');
				}
				else {
					sorting[$scope.currentGroupColumn.Property] = $scope.currentGroupColumn.sortDir;
					sorting[secondarySortKey] = secondarySortDir;
				}

				if ($scope.selectedTreeViewItem) {
					filter.PropertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: $scope.selectedTreeViewItem.Prefix === 'Building' ? $scope.selectedTreeViewItem.Guid : $scope.selectedTreeViewItem.GuidBuilding });
				}

				var columns = $scope.completionHistoryColumns.filter(function (col) {
					if (col.isVisible)
						return col;
				}).map(function (col) { return col.Property; });

				repository.GetPaginated(repository.apiData.cleaningCompletionHistory.url, params.page() - 1, params.count(), sorting, filter, "", JSON.stringify(columns))
					.then(function (result) {
						if (!result) return;
						saveHistoryTableSetup();
						$scope.historyTable.settings().total = result.TotalCount;
						$scope.historyTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
			}
		});

		$scope.updateGroupedColumns = function (column) {
			$scope.completionHistoryColumns.forEach(function (col) {
				if (col.Property === column.Property) {
					$scope.currentGroupColumn = col;
					col.isGrouping = true;
				}
				else
					col.isGrouping = false;
			});
			$scope.visibleColumns = $scope.completionHistoryColumns.filter(function (col) { return col.isVisible && !col.isGrouping; });

			$scope.historyTable.settings().groupBy = $scope.currentGroupColumn.Property;
			$scope.historyTable.reload();
		};

		$scope.getGroupHeaderValue = function (group, currentGroupColumn) {
			// quick fix to get translated group header values
			// since we know group.data all contain data grouped by currentGroupColumn, we return the translated value from the first entry's property
			if (group.data.length > 0 && group.data[0][currentGroupColumn.Property]) {
				return listService.GetPropertyValue(group.data[0], currentGroupColumn);
			} else {
				return header.value;
			}
		};

		$scope.sortGroupColumn = function () {
			$scope.currentGroupColumn.sortDir = $scope.currentGroupColumn.sortDir === 'asc' ? 'desc' : 'asc';
			$scope.historyTable.reload();
		};

		// Fix for NgTable which changes the order of groups
		$scope.groupSorter = function (groups) {
			if (!groups) return;
			var asc = $scope.currentGroupColumn.sortDir === 'asc';
			groups.sort(function (a, b) {
				if (asc)
					return a.value - b.value;
				return b.value - a.value;
			});
			return groups;
		};

		function saveHistoryTableSetup() {
			if (!$sessionStorage.storedCleaningHistoryTable)
				$sessionStorage.storedCleaningHistoryTable = {};
			if (!$sessionStorage.storedCleaningHistoryTable[$state.current.name])
				$sessionStorage.storedCleaningHistoryTable[$state.current.name] = {};

			$sessionStorage.storedCleaningHistoryTable[$state.current.name].columnSetup = $scope.completionHistoryColumns;
		}

		function isTreeViewStored() {
			return $sessionStorage.storedCleaningHistoryTable && $sessionStorage.storedCleaningHistoryTable[$state.current.name];
		}

		function loadHistoryTableSetup() {
			if (isTreeViewStored()) {
				$scope.completionHistoryColumns = $sessionStorage.storedCleaningHistoryTable[$state.current.name].columnSetup;
				return true;
			}
			return false;
		}

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.$watch('selectedTreeViewItem', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			getSummaryData();

			if ($scope.historyTable) {
				if (newVal && oldVal) {
					var newGuidBuilding = newVal.GuidBuilding ? newVal.GuidBuilding : newVal.Guid;
					var oldGuidBuilding = oldVal.GuidBuilding ? oldVal.GuidBuilding : oldVal.Guid;
					if (newGuidBuilding !== oldGuidBuilding)
						$scope.historyTable.reload();
				}
				else
					$scope.historyTable.reload();
			}
		});

		$scope.$watch('currentGroupColumn', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			$scope.updateGroupedColumns(newVal);
		});

		$scope.$watch('visibleColumns', function (newVal, oldVal) {
			if (newVal === oldVal || !newVal) return;
			$scope.completionHistoryColumns.forEach(function (col) {
				if (!col.isGrouping) {
					if (newVal.includes(col))
						col.isVisible = true;
					else
						col.isVisible = false;
				}
			});
			$scope.historyTable.reload();
		});

		$scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
			$scope.fromState = fromState.name;
		});
	}
})();
