(function () {
	angular.module('Plania').service('TelerikService', ['TranslationService', '$localStorage', telerikService]);
	function telerikService(translationService, $localStorage) {
		var service = {};

		service.setTelerikCulture = function (locale) {
			if (kendo && kendo.cultures && kendo.culture && typeof kendo.cultures === "object") {
				var selectedCultureKey;

				var cultureKeys = Object.keys(kendo.cultures);
				for (var i = 0; i < cultureKeys.length; i++) {
					var cultureKey = cultureKeys[i];
					if (cultureKey === "current") continue;
					if (locale && locale.toLowerCase() === cultureKey.toLowerCase()) {
						selectedCultureKey = cultureKey;
						break;
					}
				}

				if (!selectedCultureKey)
					selectedCultureKey = "nb-NO"; // Will default to english if the norwegian culture does not exist

				if (kendo.cultures.current && selectedCultureKey === kendo.cultures.current.name)
					return;

				kendo.culture(selectedCultureKey);
			}
		};

		service.setTelerikLocalization = function (locale) {
			var localizations = angular.copy(telerikReportViewer.sr);
			var localizationKeys = Object.keys(localizations);
			var shouldUpdate = false;

			for (var i = 0; i < localizationKeys.length; i++) {
				var key = localizationKeys[i];
				var originalText = localizations[key];

				localizations[key] = translationService.translate('web-telerik-localization-' + key, originalText);

				if (!shouldUpdate && localizations[key] !== originalText)
					shouldUpdate = true;
			}

			if (shouldUpdate)
				telerikReportViewer.sr = $.extend(telerikReportViewer.sr, localizations);
		};

		// Common function for both culture and localization
		service.setTelerikLanguage = function (locale) {
			if (!locale)
				locale = $localStorage.locale;

			if (!locale)
				locale = "nb-no";

			service.setTelerikCulture(locale);
			service.setTelerikLocalization();
		};

		return service;
	}
})();
