(function () {
	var app = angular.module('Plania');
	app.directive('buildingTemplatePeriodicTaskDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate'
				
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', controller],
			templateUrl: 'app/buildingTemplate/views/buildingTemplatePeriodicTask.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		$scope.periodicTaskColumns = [
			{ Position: 1, Title: translationService.translate('web-periodicTask-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
			{ Position: 3, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
			{ Position: 4, Title: translationService.translate('web-periodicTask-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-periodictask-interval'), Property: 'Interval', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-periodictask-period'), Property: 'Period', PropertyType: 'enum.timeunit' },
			{ Position: 7, Title: translationService.translate('web-periodictask-must-link-instances'), Property: 'MustLinkInstances', PropertyType: 'bool' },
			{ Position: 8, Title: translationService.translate('web-periodictask-is-fire-related'), Property: 'IsFireRelated', PropertyType: 'bool' },
			{ Position: 9, Title: translationService.translate('web-periodictask-is-electro-related'), Property: 'IsElectroRelated', PropertyType: 'bool' },
		];

		$scope.createPeriodicTask = function () {
			repository.persistedData.setPersistedData('periodicTask.create', {
				model: { IsTemplate: true, GuidBuilding: $scope.model.Guid },
				guidBuilding: $scope.model.Guid,
				building: $scope.model.Caption
			});
			$scope.navigation.go('periodicTask.create', { showPersistantData: true }, { reload: true });
		};
	}
})();
