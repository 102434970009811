(function () {
	var app = angular.module('Plania');
	app.directive('buildingTemplateInfoDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				reloadDocumentTable: '=reloadDocumentTable',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				formReference: '=',
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', controller],
			templateUrl: 'app/buildingTemplate/views/buildingTemplateInfo.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;

		$scope.viewMode = $scope.isUpdate;

		$scope.hasPageLoaded = $scope.currentTab === 'info';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'info' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.onBuildingCategorySelect = function (category) {
			if (!category) {
				$scope.model.GuidBuildingCategory = "";
				return;
			}
			
			$scope.model.GuidBuildingCategory = category.Guid;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "buildingCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
		};
	}
})();


