(function () {
	angular.module('Plania').controller('GeneralOptionsController', ['$scope', '$window', 'Repository', '$localStorage', 'Constants', 'TranslationService', 'ColumnFormatService', '$modal', 'IconService', controller]);

	function controller($scope, $window, repository, $localStorage, constants, translationService, columnFormatService, $modal, iconService) {
		$scope.model = { UserAuthentication: 0 };
		$scope.times = {};
		$scope.activateAutocomplete = false;
		$scope.generateQrCodeJobInfo = {};

		var columns = ['SystemOwner.UserName', 'SystemOwner.RealName', 'SystemOwner.PhoneNumber', 'SystemOwner.CellPhone', 'SystemOwner.Email', 'SystemOwner.IsDeactivated'];
		repository.getSingle(repository.apiData.generalOptions.url, '', JSON.stringify(columns)).then(function (result) {

			repository.getSingleWithNoParameter(repository.apiData.generalOptions.url + "disabledProperties").then(function (response) {
				$scope.model.DisabledProperties = response.Data;
			});

			repository.getSingleWithNoParameter(repository.apiData.generalOptions.url + "generateQrCodeJobInfo").then(function (response) {
				$scope.generateQrCodeJobInfo = response.Data || {};
			});

			$scope.model = result.Data;

			if ($scope.model.SystemOwner && !$scope.model.SystemOwner.Caption) {
				$scope.model.SystemOwner.Caption = $scope.model.SystemOwner.Username;
			}

			$scope.selectedInspectedEntityLocation = $scope.enumInspectedEntityLocation[$scope.model.InspectedEntityLocation];
			$scope.selectedLogSecurityEventLevel = _.find($scope.enumLogSeverity, { value: $scope.model.LogSecurityEventLevel });
			$scope.selectedLogSecurityDbLevel = _.find($scope.enumLogSeverity, { value: $scope.model.LogSecurityDbLevel });
			$scope.selectedTwoFactorAuthenticationOption = _.find($scope.twoFactorAuthenticationOption, { value: $scope.model.TwoFactorAuthenticationOption });

			if ($scope.model.MapSettings)
				$scope.mapSettings = JSON.parse($scope.model.MapSettings);

			if (!$scope.mapSettings) {
				$scope.mapSettings = { Options: {} };
				setDefaultEntityInfo();
			}

			if (!$scope.mapSettings.entityInfo) {
				setDefaultEntityInfo();
			}

			$scope.EquipmentFieldNameForMeteringIdentifierOptions = [
				"Id",
				"AlternateId",
			];
			for (var i = 1; i <= 40; i++) {
				var key = i;
				if (i < 10) {
					key = 0 + "" + i;
				}
				$scope.EquipmentFieldNameForMeteringIdentifierOptions.push("Text" + key);
			}

			if ($scope.mapSettings.entityInfo && !$scope.mapSettings.entityInfo.request) {
				$scope.mapSettings.entityInfo.request = {
					entity: 'Request',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'fa-envelope'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8,
				};
			}
			if ($scope.mapSettings.entityInfo && !$scope.mapSettings.entityInfo.workOrder) {
				$scope.mapSettings.entityInfo.workOrder = {
					entity: 'WorkOrder',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'fa-suitcase'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8
				};
			}
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		});

		$scope.poEntityTypes = [
			{ text: translationService.translate('web-enum-prefix-article'), value: repository.commonService.prefix.Article },
			{ text: translationService.translate('web-enum-prefix-component'), value: repository.commonService.prefix.Component },
			{ text: translationService.translate('web-enum-prefix-sparePart'), value: repository.commonService.prefix.SparePart },
			{ text: translationService.translate('web-enum-prefix-equipment'), value: repository.commonService.prefix.Equipment }
		];

		$scope.save = function () {
			delete $scope.BarcodeEquipmentFormatError;
			delete $scope.BarcodeComponentFormatError;
			delete $scope.BarcodeBuildingFormatError;
			delete $scope.BarcodeAreaFormatError;
			$scope.model.MapSettings = JSON.stringify($scope.mapSettings);
			$localStorage.generalOptions = $scope.model;

			repository.updateSingle(repository.apiData.generalOptions.url, $scope.model).then(function (result) {
				repository.growl('Klientprofilen har blitt oppdatert', 'success');
			}, function (error) {
				if (Array.isArray(error)) {
					var isBarcodeError = false;
					error.forEach(function (err) {
						if (err === 'BarcodeEquipmentFormat') {
							isBarcodeError = true;
							$scope.BarcodeEquipmentFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeAreaFormat',
								'QR kode format for anlegg er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
						if (err === 'BarcodeComponentFormat') {
							isBarcodeError = true;
							$scope.BarcodeComponentFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeComponentFormat',
								'QR kode format for komponent er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
						if (err === 'BarcodeBuildingFormat') {
							isBarcodeError = true;
							$scope.BarcodeBuildingFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeBuildingFormat',
								'QR kode format for bygg er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
						if (err === 'BarcodeAreaFormat') {
							isBarcodeError = true;
							$scope.BarcodeAreaFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeAreaFormat',
								'QR kode format for areal er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
					});

					if (isBarcodeError) {
						repository.growl(
							'Feil i generering av QR-koder, fiks feilene før du kan lagre endringer på kundeprofilen', 'danger');
					} else if (error.length > 0)
						repository.growl(error[0], 'danger');

				}
				else if (typeof (error) === 'string') {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'error');
				}
			});
		};

		//MAP_SETTINGS

		$scope.setMapData = function (item) {
			$scope.mapSettings.TileLayer = item.mapData.TileLayer;
			$scope.mapSettings.TileLayerOptions = item.mapData.TileLayerOptions;
			$scope.mapSettings.CrsCode = item.mapData.CrsCode;
			$scope.mapSettings.Proj4Def = item.mapData.Proj4Def;
			$scope.mapSettings.Options.Origin = item.mapData.Options.Origin;
			$scope.mapSettings.Options.Resolution = item.mapData.Options.Resolution;
			$scope.mapSettings.Options.Resolution = item.mapData.Options.Resolution;
		};

		$scope.generateGisEntities = function () {
			repository.runAction(repository.apiData.gisManagement.url).then(function (result) {
				swal(translationService.translate('web-swal-generaloptions-generateGisEntities-success', 'Genering av kartpunkt er startet. Dette kan ta litt tid.'), "", "success");
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.updateCleaningEstimate = function () {
			repository.updateSingle(repository.apiData.cleaningStatus.url, { Guid: 'UpdateEstimatedTime' }).then(
				function (result) {
					repository.growl(result, 'success');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
		};

		//$scope.getKpiMapData = function () {
		//	var estateKpi = {
		//		Prefix: 'Estate',
		//		PropertyFilter: [],
		//		Properties: [{
		//			Property: 'Guid',
		//			Function: 'count',
		//		}]
		//	};

		//	repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {

		//	});
		//};

		$scope.verifyUniqueBarcode = function (typeFormat) {
			var payload = { type: typeFormat, Guid: $scope.model.Guid };

			switch (typeFormat) {
				case repository.commonService.prefix.Building:
					payload.format = $scope.model.BarcodeBuildingFormat ? $scope.model.BarcodeBuildingFormat : null;
					break;
				case repository.commonService.prefix.Equipment:
					payload.format = $scope.model.BarcodeEquipmentFormat ? $scope.model.BarcodeEquipmentFormat : null;
					break;
				case repository.commonService.prefix.Area:
					payload.format = $scope.model.BarcodeAreaFormat ? $scope.model.BarcodeAreaFormat : null;
					break;
				case repository.commonService.prefix.Component:
					payload.format = $scope.model.BarcodeComponentFormat ? $scope.model.BarcodeComponentFormat : null;
					break;
			}

			if (payload.format) {
				repository.updateSingle(repository.apiData.generalOptions.url + "VerifyUniqueBarcode/", payload).then(
					function (result) {
						if (result.IsSuccess) {
							repository.growl("Formatet er unikt", "success");
						} else {
							$modal.open({
								templateUrl: 'app/generalOptions/views/showDuplicateQrCodeModal.html',
								controller: function ($scope, params) {
									$scope.listOfDuplicates = params.failList;
								},
								resolve: {
									params: function () {
										return {
											failList: result.FailList
										};
									}
								}
							});
						}
					},
					function (error) {
						repository.growl(error, 'danger');
					});
			}
		};

		$scope.setPlaniaWebUrl = function () {
			$scope.model.WebBaseUrl = $window.location.origin + "/";
		};

		$scope.testCatendaConnection = function () {

			repository.getSingleWithNoParameter(repository.apiData.bim.endpoint.ping).then(function (response) {
				if (response) {
					repository.growl(translationService.translate('web-catendaConnection-success', 'Koblingen fungerer'), 'success');
				} else {
					repository.growl(translationService.translate('web-catendaConnection-error', 'Koblingen fungerer ikke'), 'danger');
				}
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.sendTestEmail = function () {
			$modal.open({
				templateUrl: 'app/generalOptions/views/sendTestEmailModal.html',
				controller: function ($scope, modalParams) {
					$scope.emailTo = modalParams.userEmail;
					$scope.sendTestEmail = function (emailTo) {
						var param = "recipient=" + emailTo;
						repository.getWithUrlParameter(repository.apiData.email.endpoint.sendTestEmail, param).then(function (response) {
							if (response) {
								repository.growl(translationService.translate('web-generaloptions-sendmail-test-success', 'E-post er sendt. Verifiser at denne kommer frem hos mottaker.'), 'success');
							} else {
								repository.growl(translationService.translate('web-generaloptions-sendmail-test-error', 'Feil ved sending. Se i systemlogg for mer informasjon.'), 'danger');
							}
						}, function (error) {
							repository.showError(error);
						});
					};
				},
				resolve: {
					modalParams: function () {
						return { userEmail: repository.authService.getUserData().email };
					}
				}
			});
		};

		$scope.resetTermsOfUseAcceptedDates = function () {

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-generalOptions-reset-termsOfUse-confirmationMessage', 'Alle brukere vil måtte godkjenne brukervilkår på nytt'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-generalOptions-reset-termsOfUse-confirm', 'Ja, nullstill!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.runAction(repository.apiData.generalOptions.endpoint.resetTermsOfUseAcceptedDates).then(function (response) {
					swal(translationService.translate('web-swal-generaloptions-resetTermsOfUseAcceptedDates-success', 'Alle brukeres brukervilkår er nullstillet'), "", "success");
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};


		$scope.availableColors = ['blue', 'red', 'green', 'orange', 'cyan', 'pink', 'black'];

		var setDefaultEntityInfo = function () {
			$scope.mapSettings.entityInfo = {
				estate: {
					entity: 'Estate',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'city'
					},
					iconAnchor: [10, 33],
					popupAnchor: [0, -19],
					weight: 8,
				},
				building: {
					entity: 'Building',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'home'
					},
					iconAnchor: [10, 33],
					popupAnchor: [0, -19],
					weight: 8,
				},
				equipment: {
					entity: 'Equipment',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'gear'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8,
				},
				request: {
					entity: 'Request',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'envelope'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8,
				},
				workOrder: {
					entity: 'WorkOrder',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'briefcase-blank'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8
				}
			};
		};

		$scope.iconMap = iconService.getAvailableMapIcons();

		$scope.availableMaps = constants.availableMaps;

		$scope.enumInspectedEntityLocation =
			[
				{ value: constants.inspectedEntityLocation.anywhere, text: translationService.translate('web-enum-inspectedentity-any', 'Hvor som helst') },
				{ value: constants.inspectedEntityLocation.estate, text: translationService.translate('web-enum-inspectedentity-property', 'Eiendom') },
				{ value: constants.inspectedEntityLocation.building, text: translationService.translate('web-enum-inspectedentity-building', 'Bygg') }
			];

		$scope.enumLogSeverity = [
			{ value: -1, text: translationService.translate('web-enum-logseverity-none', 'Ingen logging') },
			{ value: 0, text: translationService.translate('web-enum-logseverity-info', 'Info (alle requests, avslått og vellyket)') },
			{ value: 1, text: translationService.translate('web-enum-logseverity-warning', 'Advarsel (alle avslåtte forespørsler)') },
			{ value: 2, text: translationService.translate('web-enum-logseverity-error', 'Feil (forsøk på innlogging etc)') },
		];

		$scope.twoFactorAuthenticationOption = [
			{ value: 0, text: translationService.translate('web-enum-twofactorauthenticationoption-disabled', 'Avslått') },
			{ value: 1, text: translationService.translate('web-enum-twofactorauthenticationoption-activeperuser', 'Aktiv per bruker') },
			{ value: 2, text: translationService.translate('web-enum-twofactorauthenticationoption-activeforall', 'Aktiv for alle') }
		];

		$scope.canChangeTwoFactorAuthenticationOption = function () {
			return $scope.model.SmsConfiguration;
		};

		$scope.addNewBarcodeFormatColumn = function (modelProperty, entityName) {
			columnFormatService.chooseColumn($scope.model, modelProperty, entityName);
		};

		$scope.navigateTo = function (url, guid) {
			$scope.navigation.go(url, { guid: guid });
		};

		$scope.onUseDynamicCategoryCommonLabelsClick = function () {
			if (!$scope.model.UseDynamicCategoryCommonLabels) {
				$modal.open({
					templateUrl: 'app/generalOptions/views/useDynamicCategoryCommonLabelsModal.html',
					controller: 'UseDynamicCategoryCommonLabelsModalController'
				}).result.then(function () {
					$scope.model.UseDynamicCategoryCommonLabels = true;
				}, function () {
					$scope.model.UseDynamicCategoryCommonLabels = false;
				});
			}
		};

		$scope.onSelectSystemOwner = function (user) {
			if (!user) {
				$scope.model.GuidSystemOwner = null;
				$scope.model.SystemOwner = null;
				return;
			}

			if ($scope.model.GuidSystemOwner !== user.Guid) {
				$scope.model.GuidSystemOwner = user.Guid;
				$scope.model.SystemOwner = user;
				if (!$scope.model.SystemOwner.Caption)
					$scope.model.SystemOwner.Caption = user.Username;
			}
		};
	}
})();
