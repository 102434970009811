angular.module('Plania').directive("scrollToolbar", function ($window, $timeout) {
    return function (scope, element, attrs) {
        angular.element($window).bind("scroll", function () {
            var isAnimating = false;
            var animation = "fadeInUp";
            var animationDuration = 1200;
            if (this.pageYOffset >= attrs.toolbarMinHeight) {
                scope.showToolbar = true;

                if (element.context.classList.contains('ng-hide')) {
                    $timeout.cancel();

                    element.addClass('animated ' + animation);
                    $timeout(function () {
                        isAnimating = false;
                        element.removeClass(animation);
                    }, animationDuration);
                }
            } else {
                if (!element.context.classList.contains('ng-hide')) {
                    $timeout.cancel();

                    animation = "fadeOutDown";
                    animationDuration = 1200;

                    element.addClass('animated ' + animation);

                    $timeout(function () {
                        element.removeClass(animation);
                        scope.showToolbar = false;
                    }, animationDuration);
                }
            }
            scope.$apply();
        });
    };
});
