(function () {
	var app = angular.module('Plania');
	app.directive('buildingMaintenanceDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				reloadDeviations: '=reloadDeviations'
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', controller],
			templateUrl: 'app/building/views/buildingMaintenance.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-workorder-equipment'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuildingCategory', AutocompleteService: 'buildingCategory' },
			{ Position: 4, Title: translationService.translate('web-workOrder-resourceGroup'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 5, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 6, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' }
		];

		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create', {
				model: { GuidEstate: $scope.model.GuidEstate, GuidBuilding: $scope.model.GuidBuilding },
				guidEstate: $scope.model.Estate ? $scope.model.Estate.Guid : null,
				estate: $scope.model.Estate ? $scope.model.Estate.Caption : null,
				guidBuilding: $scope.model.Guid,
				building: $scope.model.Caption
			});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};
	}
})();
