(function () {
	angular.module('Plania').controller('ComponentSupplierController', ['$scope', 'Repository', 'params', '$modalInstance', controller]);

	function controller($scope, repository, params, $modalInstance) {
		$scope.isUpdate = params.isUpdate;
		$scope.activateAutocomplete = false;
		if (params.supplier) {
			var columns = ['Supplier.Id', 'Supplier.Description', 'Supplier.Guid'];
			repository.getSingle(repository.apiData.componentSupplier.url, params.supplier.Guid, JSON.stringify(columns)).then(function (response) {
				$scope.model = response.Data;

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			},
			function (error) {
				repository.growl(error, 'danger');
			});
		} else {
			$scope.model = {
				GuidComponent: params.guidComponent,
				DeliveryTimePeriodUnit: "Undefined"
			};
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		}

		$scope.saveAction = function () {
			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.componentSupplier.url, $scope.model).then(function (result) {
					repository.growl('Leverandør oppdatert', 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingle(repository.apiData.componentSupplier.url, $scope.model).then(function (result) {
					repository.growl('Leverandør opprettet', 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
