(function () {
	angular.module('Plania').controller('bimFileFieldMappingModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, repository, translationService) {
		var existingColumns = params.existingColumns;
		$scope.enableConstantValue = params.enableConstantValue;
		$scope.inputMode = 'column';
		$scope.model = params.PropertyData;
		$scope.constantValue = '';

		$scope.selectedColumns = null;

		$scope.ok = function () {
			if ($scope.inputMode === 'column')
				$modalInstance.close({
					type: 'column',
					value: $scope.selectedColumns
				});
			else if ($scope.inputMode === 'constant')
				$modalInstance.close({
					type: 'constant',
					value: $scope.constantValue
				});
		};

		var match = function (textToSearch, searchValue) {
			if (!searchValue) return true;
			if (!textToSearch) return false;
			return textToSearch.toLowerCase().includes(searchValue.toLowerCase());
		};

		var searchMatch = function (col, searchString) {
			return match(col.Title, searchString);
		};

		$scope.columnFilter = function (col) {
			if (!searchMatch(col, $scope.searchString)) return false;
			return col;
		};

		$scope.selectColumn = function (column, event) {
			event.stopPropagation();
			if (column.Children.length > 0) {
				column.isExpanded = !column.isExpanded;
			} else {
				event.stopPropagation();
				$modalInstance.close(column
				);
			}
		};

		$scope.alreadyMapped = function (column) {
			if (_.contains(existingColumns, column.Title)) return true;
			if (_.contains(existingColumns, column.Children.Title)) return true;
			return false;
		};

		$scope.isColumnSelected = function (column) {
			return _.contains($scope.selectedColumns, column);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
