(function () {
	angular.module('Plania').controller('LoginController', ['$scope', 'Repository', 'MenuService', "GeneralOptionsService", '$stateParams', '$modal', '$location', 'TranslationService', '$state', '$localStorage', 'SignalR', 'config', '$q', loginController]);
	function loginController($scope, repository, menuService, generalOptionsService, $stateParams, $modal, $location, translationService, $state, $localStorage, signalR, config, $q) {
		$scope.status = null;

		$scope.model = {
			username: "",
			password: ""
		};

		$scope.externalLoginMethods = null;
		$scope.loginMethods = [];
		$scope.focusedLoginMethod = null;

		repository.getSingleWithNoParameter('/PlaniaStatus').then(function (result) {
			$scope.status = result;
			$scope.showMobileApp = result.ShowMobileApp;
			if (result.ShowUsernamePasswordLogin)
				$scope.loginMethods.push({
					Type: 'UsernamePassword',
					Id: 'UsernamePassword',
					Name: translationService.translate('web-login-method-usernamePassword', 'Brukernavn og passord'),
					Icon: 'far fa-lock',
					OnFocusClick: function() {
						$scope.focusLoginMethod('UsernamePassword');
					},
					OnClickFunc: function(){
						$localStorage.loginHint = 'UsernamePassword';
						$scope.focusLoginMethod('UsernamePassword');
					}
				});

			if (result.ShowSamlLogin)
				$scope.loginMethods.push({
					Type: 'Saml',
					Id: 'Saml',
					Icon: 'far fa-id-card',
					Name: translationService.translate('web-login-method-saml', 'Logg inn med SAML'),
					OnFocusClick: function (){
						$scope.focusLoginMethod('Saml');
					},
					OnClickFunc: function() {
						$localStorage.loginHint = 'Saml';
						$scope.samlLogin();
					}
				});

			if (result.ShowExternalLogin)
			{
				_.forEach(result.ExternalLoginMethods, function(item){
					$scope.loginMethods.push({
						Type: 'ExternalLogin',
						Id: item.Name,
						Icon: getIconFromLoginType(item.Type),
						Name: item.Name,
						OnFocusClick: function (){
							$scope.focusLoginMethod(item.Name);
						},
						OnClickFunc: function() {
							$localStorage.loginHint = item.Name;
							$scope.loginWithProvider(item.Name);
						}
					});
				});
			}

			var loginHint = $location.search().loginHint;
			if (!loginHint)
				loginHint = $localStorage.loginHint;

			if (loginHint){
				var loginMethod = _.find($scope.loginMethods, function(item){
					return item.Id === loginHint;
				});
				if (loginMethod && loginMethod.OnFocusClick)
					loginMethod.OnFocusClick();
			}

			$scope.showChooseLogin = !$scope.focusedLoginMethod && $scope.loginMethods.length > 1;
			if (!$scope.focusedLoginMethod && $scope.loginMethods.length === 1)
				$scope.loginMethods[0].OnFocusClick();

			$localStorage.planiaStatus = result;


			if ($location.search().initExternalLoginRegistration){
				var startExternalLoginRegistration = $location.search().initExternalLoginRegistration;
				var externalLoginProvider = $location.search().externalLoginProvider;
				var provider = _.find(result.ExternalLoginMethods, function(item){
					return item.Name === externalLoginProvider;
				});
				var token = $location.search().token;
				var email = $location.search().email;
				var cellPhone = $location.search().cellPhone;
				if (startExternalLoginRegistration){
					$modal.open({
						controller: 'RegisterExternalLoginModalController',
						templateUrl: 'app/login/views/registerExternalLoginModal.html',
						resolve: {
							params: function () { return { externalLoginProvider: provider, token: token, email: email, cellPhone: cellPhone }; }
						}
					}).result.then(function(provider){
						if (provider === externalLoginProvider) {
							$scope.loginWithProvider(provider);
						}
					});

				}
			}
		});

		if ($location.search().error) {
			$scope.alertMessage = $location.search().error;
		}

		if ($stateParams.message) {
			$scope.alertMessage = $stateParams.message;
		}

		if ($stateParams.refreshtoken || $location.search().refreshToken) {
			var refreshToken = $stateParams.refreshtoken || $location.search().refreshToken;
			$scope.hideLoginBox = true;
			repository.authService.authenticateSamlRefresh(refreshToken).then(function () {
				afterLogonNavigate();
			}).catch(function (error) {
				$scope.hideLoginBox = false;
				$scope.alertMessage = "Kunne ikke logge inn, prøv igjen.";
			});
		}

		function getIconFromLoginType(loginType){
			if (loginType === "azure-ad") return "microsoft-logo";
			if (loginType === "id-porten") return "idporten-logo";
			return "far fa-id-card";
		}


		$scope.openQRCodeModal = function () {
			$modal.open({
				controller: 'QrCodeModalController',
				templateUrl: 'app/common/views/qrCodeModal.html'
			});
		};

		var sessionStorage = window.sessionStorage;
		var logoutError = sessionStorage.getItem('logoutError');

		if (logoutError) {
			$scope.alertMessage = logoutError;
			sessionStorage.removeItem('logoutError');
		}

		var getNavigationStateAfterLogin = function () {
			var defer = $q.defer();
			var stateObject = {
				state: "",
				params: {},
				options: {}
			};

			if ($stateParams.returnToState) {
				var returnTo = JSON.parse($stateParams.returnToState);
				stateObject.state = returnTo.name;
				stateObject.params = returnTo.params;

				return $.Deferred().resolve(stateObject).promise();
			}

			var userData = repository.authService.getUserData();
			menuService.getShortcuts().then(function () { //trigger fetching menus
				if (userData.GuidWebMenu && userData.MenuContext) {
					var filter = userData.MenuParameters.replace(/(\r\n|\n|\r)/gm, "");
					repository.setMenuParameters(userData.GuidWebMenu, filter, 0);

					stateObject.state = userData.MenuContext;
					stateObject.params = { menuGuid: userData.GuidWebMenu };
					stateObject.options = { reload: true };
				} else {
					stateObject.state = 'building.list'; //todo: create a default dashboard to show for users without startpage
				}
				defer.resolve(stateObject);
			}, function (error) {
				$scope.isLoginActive = false;
				if (error === translationService.translate('api-accessDomain-hasEntityAccess-hasNoAccess', 'Bruker har ikke tilgang'))
					repository.growl(translationService.translate('web-login-userXWebProfile-access-error', 'Bruker mangler rettighet til brukerprofil (UserXWebProfile), vennligst kontakt en Plania adminstrator for å fikse problemet'), 'danger');
				else
					repository.growl(translationService.translate('web-login-userXWebProfile-generic-error', 'Noe gikk galt ved henting av brukerprofil, vennligst kontakt en Plania adminstrator, for å fikse problemet'), 'danger');

				defer.reject(error);
			});

			return defer.promise;
		};

		var afterLogonNavigate = function (userData) {
			repository.setLocale(repository.authService.getUserData().language);
			translationService.getTranslations();
			generalOptionsService.refreshGeneralOptions();

			signalR.setQs({
				fingerprint: config.fingerprint,
				guidUser: repository.authService.getUserData().guidUser
			});
			signalR.start();

			repository.GetPaginated(repository.apiData.userAccess.url, 0, 0, {}, {}).then(function (response) {
				response.forEach(function (row) {
					repository.authService.updateUserAccess(row);
				});

				getNavigationStateAfterLogin().then(function (navigationState) {
					if (!$stateParams.returnToState && $scope.$parent) {
						// Why this?
						$scope.$parent.home = {
							state: navigationState.state,
							params: navigationState.params || { menuGuid: null }
						};
					}

					var mustChangePassword = !!userData && userData.MustChangePassword === "1";
					if (mustChangePassword) {
						$scope.navigation.go('mustChangePassword', { returnToState: navigationState });
					} else {
						$scope.navigation.go(navigationState.state, navigationState.params, navigationState.options);
					}
				});
			}, function (error) {
				$scope.isLoginActive = false;
				swal('Feil ved henting av brukerens tilganger', error, 'error');
			});
		};

		//If user is already logged in redirect to correct page
		if (repository.authService.getUserData().isAuthenticated) {
			repository.authService.refreshAuthentication().then(function (result) {
				if (result !== 'invalid')
					afterLogonNavigate();
			});
		}

		$scope.samlLogin = function () {
			repository.authService.authenticateSaml(); //saml will redirect pages. code after this line will not be executed.
		};

		$scope.model.username = '';
		$scope.model.password = '';
		$scope.isLoginActive = false;

		var afterLogonRememberTwoFactor = function (twoFactor) {
			if (!twoFactor || !twoFactor.rememberMe)
				return;

			var daysToRemember = $scope.status.TwoFactorExpirationPeriodInDays;
			if (daysToRemember > 0) {
				var rememberMeInfo = {
					username: $scope.model.username,
					identifier: twoFactor.identifier,
					secret: twoFactor.newSecret,
					expires: moment().add(daysToRemember, 'days').format()
				};
				$localStorage.twoFactorRememberMe = rememberMeInfo;
			}
		};

		var getRememberedTwoFactorForUser = function(username) {
			var rememberMeInfo = $localStorage.twoFactorRememberMe;
			if (rememberMeInfo && rememberMeInfo.expires) {
				var expiration = moment(rememberMeInfo.expires);
				if (expiration.isBefore(moment())) {
					$localStorage.twoFactorRememberMe = null;
					rememberMeInfo = null;
				}
			}
			return rememberMeInfo && rememberMeInfo.username === username ? rememberMeInfo : null;
		};

		$scope.performLogin = function () {
			$scope.isLoginActive = true;

			if (!$scope.model.username) {
				repository.growl('Du må fylle ut brukernavn', 'danger');
				$scope.isLoginActive = false;
				return;
			}
			if (!$scope.model.password) {
				repository.growl('Du må fylle ut passord', 'danger');
				$scope.isLoginActive = false;
				return;
			}
			var logoutOtherLocation = $scope.model.confirmLogout === true;

			var twoFactorRemembered = getRememberedTwoFactorForUser($scope.model.username);
			if (!twoFactorRemembered) {
				if ($scope.twoFactor && $scope.twoFactor.rememberMe) {
					// user wants to be remembered, generate a random string and pass to the server. If successful login, we store it for further use
					$scope.twoFactor.newSecret = planiaUtils.randomString(100);
				}
			}

			repository.authService.authenticate($scope.model.username, $scope.model.password, logoutOtherLocation, $scope.twoFactor, twoFactorRemembered,
				function (result) {
					afterLogonRememberTwoFactor($scope.twoFactor);
					afterLogonNavigate(result);
				}, function (result) {
					if (result.status === 'error') {
						repository.growl(result.message, 'danger');
					}
					else if (result.status === 'confirm_logout') {
						$scope.displayConfirmation = true;
					}
					else if (result.status === 'two_factor_required') {
						handleTwoFactor(result);
					}
					else if (result.status === 'two_factor_failed') {
						repository.growl(result.message, 'danger');
					}
					else if (result.status === 'two_factor_cancelled') {
						repository.growl(result.message, 'danger');
						$scope.cancelTwoFactor();
					}
					$scope.isLoginActive = false;
				});
		};

		function handleTwoFactor(result) {
			// No feasible way to return result.message as json from owin, therefore key:value pairs separated by comma
			var messageParams = result.message.split(', ');
			var twoFactorParams = {};
			messageParams.forEach(function (val) {
				var sep = val.split(':');
				twoFactorParams[sep[0]] = sep[1];
			});

			$scope.twoFactor = {
				identifier: twoFactorParams.Id,
				phoneNumber: twoFactorParams.PhoneNumber,
				code: '',
				rememberMe: $scope.status && $scope.status.TwoFactorExpirationPeriodInDays && $scope.status.TwoFactorExpirationPeriodInDays > 0
			};
			$scope.focusedLoginMethod = null;
			$scope.showTwoFactor = true;
		}

		$scope.submitTwoFactor = function () {
			$scope.twoFactor.error = null;
			$scope.performLogin();
		};

		$scope.cancelTwoFactor = function () {
			$scope.twoFactor = null;
			$scope.focusLoginMethod('UsernamePassword');
			$scope.showTwoFactor = false;
		};

		$scope.ignoreStatusNotOk = function() {
			if ($scope.status)
				$scope.status.IsStatusOk = true;
		};

		$scope.loginWithProvider = function(loginProvider) {
			repository.authService.authenticateExternalLogin(loginProvider);
		};

		$scope.goToChooseLogin = function() {
			$scope.showChooseLogin = true;
			$scope.focusedLoginMethod = null;
		};

		$scope.focusLoginMethod = function(loginMethodId){
			var loginMethod = _.find($scope.loginMethods, function(item){ return item.Id === loginMethodId;});
			$scope.focusedLoginMethod = loginMethod;
			$scope.showChooseLogin = false;
		};
	}
})();
