(function () {

	angular.module('Plania').directive('plPrintDetails', function () {
		return {
			restrict: 'A',
			scope: {
				guids: '=guids', //selectedGuids
				domainName: '=domainName', //domainname of object to be printed
				onPrint: '=onPrint', // function to call when printed (should normally be called from the renderingEnd function of the telerikReport directive)
			},
			controller: ['$scope', '$rootScope', '$window', 'TranslationService', '$localStorage', printController],
			template: '<a href="" tooltip-append-to-body="true" tooltip="{{\'Print detaljer\' | translate: \'print-tooltip-details\' }}" ng-click="printDetails()"><i class="fas fa-print-magnifying-glass"></i></a>'
		};
	});

	var printController = function ($scope, $rootScope, $window, translationService, $localStorage) {
		$scope.printDetails = function () {

			if ($scope.guids.length === 0) {
				swal(
					'Ingen valg',
					translationService.translate('list-printdetails-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å skrive ut detaljert rapport'),
					'error');
				return;
			}

			var params = {};

			for (var i = 0; i < $scope.guids.length; i++) {
				params["reports(" + i + ").Guid"] = $scope.guids[i];
			}

			$localStorage.reportParams = JSON.stringify(params);

			var url = $rootScope.navigation.href('report.print', {
				reportName: JSON.stringify({ prefix: $scope.domainName, numberOfReports: $scope.guids.length }),
				reportParams: JSON.stringify(params)
			});

			$window.open(url, '_blank');
		};
	};

})();
