(function () {
	angular.module('Plania').controller('WebListViewController', ['$scope', 'TranslationService', 'Repository', '$modal', '$stateParams', 'ngTableParams', 'ListService', '$localStorage', controller]);
	function controller($scope, translationService, repository, $modal, $stateParams, ngTableParams, listService, $localStorage) {
		$scope.model = { Guid: $stateParams.guid };
		$scope.isUpdate = true;
		$scope.columnView = {
			raw: false
		};

		$scope.toggleRawColumn = function () {
			$scope.columnView.raw = !$scope.columnView.raw;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebListView, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebListView, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.WebListView);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebListView);
			}
		};

		updateAccess();

		repository.getSingle(repository.apiData.webListView.url, $stateParams.guid, JSON.stringify(["User.RealName", "User.Username", "UserUpdatedBy.RealName", "UserUpdatedBy.Username"])).then(function (result) {
			$scope.model = result.Data;
			if ($scope.model.Sorting) {
				try {
					$scope.model.Sorting = JSON.parse($scope.model.Sorting);
				} catch(ex) { }
			}
			if ($scope.model.Columns) {
				$scope.model.Columns.forEach(function (col) {
					if (col.Filter) {
						try {
							col.Filter = JSON.parse(col.Filter);
						} catch(ex) { }
					}
						
				});
			}

			$scope.reload = true;
			updateAccess();
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		}, function (error) {
			repository.growl(error, 'danger');
		});

		$scope.getOperatorName = function (operator) {
			var name = 'Ukjent funksjon'; // No translation since we should pick this up during development of a new condition.
			switch (operator) {
				case "=":
					name = translationService.translate('web-filterOperatorSelect-operator-equal', '=');
					break;
				case "<>":
					name = translationService.translate('web-filterOperatorSelect-operator-notEqual', '<>');
					break;
				case ">":
					name = translationService.translate('web-filterOperatorSelect-operator-greaterThan', '>');
					break;
				case "<":
					name = translationService.translate('web-filterOperatorSelect-operator-lessThan', '<');
					break;
				case ">=":
					name = translationService.translate('web-filterOperatorSelect-operator-greaterThanOrEqual', '>=');
					break;
				case "<=":
					name = translationService.translate('web-filterOperatorSelect-operator-lessThanOrEqual', '<=');
					break;
				case "&":
					name = translationService.translate('web-filterOperatorSelect-operator-bitwiseAnd', '&');
					break;
				case "startswith":
					name = translationService.translate('web-filterOperatorSelect-operator-startswith', 'startswith');
					break;
				case "in":
					name = translationService.translate('web-filterOperatorSelect-operator-in', 'in');
					break;
				case "notin":
					name = translationService.translate('web-filterOperatorSelect-operator-notin', 'notin');
					break;
				case "contains":
					name = translationService.translate('web-filterOperatorSelect-operator-contains', 'contains');
					break;
			}
			return name;
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
