(function () {
	angular.module('Plania').controller('ChangeSetModalController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, params, modalInstance, translationService) {
		var guid = params.guid;
		var entityType = params.entityType;

		var columns = [
			'UserCreatedBy.RealName'
		];

		repository.getSingle(repository.apiData.changeSet.url, guid, JSON.stringify(columns)).then(function (result) {
			$scope.model = result.Data;
			entityType = $scope.model.EntityType;
			var apiDataEntry = entityType.charAt(0).toLowerCase() + entityType.slice(1);
			var guidEntity = $scope.model.GuidEntity;

			repository.getSingle(repository.apiData[apiDataEntry].url, guidEntity).then(function (result) {
				$scope.planiaEntity = result.Data;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		}, function (error) {
			repository.growl(error, 'danger');
		});

		$scope.getTranslatedPropertyName = function (planiaPropertyName) {
			if (planiaPropertyName.startsWith('Text') || planiaPropertyName.startsWith('Number') || planiaPropertyName.startsWith('Combo')) {
				var translatedValue = $scope.getTranslatedPropertyNameFromDynamicCategory(planiaPropertyName);
				return translatedValue;
			}
			return translationService.translate('web-' + entityType + '-' + planiaPropertyName, planiaPropertyName);
		};

		$scope.getTranslatedPropertyNameFromDynamicCategory = function (planiaPropertyName) {
			if (!$scope.planiaEntity || !$scope.planiaEntity.DynamicProperty) return;
			var translatedValue = "";
			$scope.planiaEntity.DynamicProperty.Groups.forEach(function (group) {
				group.Items.forEach(function (item) {
					if (item.Template) {
						item.Template.Groups.forEach(function (templateGroup) {
							templateGroup.Items.forEach(function (templateItem) {
								if (templateItem.PropertyName === planiaPropertyName) {
									translatedValue = templateItem.LabelPropertyValue;
									return translatedValue;
								}
							});
						});
					} else {
						if (item.PropertyName === planiaPropertyName) {
							translatedValue = item.LabelPropertyValue;
							return translatedValue;
						}
					}
				});
			});

			return translatedValue;
		};

		$scope.getPlaniaValue = function (planiaProperty) {
			return $scope.planiaEntity[planiaProperty];
		};

		$scope.setProcessed = function () {
			$scope.model.Status = 'Approved';
			repository.updateSingleDictionary(repository.apiData.changeSet.url, $scope.model).then(function (result) {
				modalInstance.close();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.ok = function () {
			modalInstance.close();
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};
	}
})();
