(function () {
	angular.module('Plania').factory('SignalR', ['config', '$rootScope', SignalR]);

	function SignalR(config, $rootScope) {
		var factory = {
			isInitialized: false
		};

		factory.connection = $.hubConnection(config.baseUrl + "signalr");
		factory.proxy = factory.connection.createHubProxy('planiaHub');

		factory.on = function (eventName, callback) {
			factory.proxy.on(eventName, function (result) {
				$rootScope.$apply(function () {
					if (callback)
						callback(result);
				});
			});
		};

		factory.off = function (eventName) {
			factory.proxy.off(eventName);
		};

		factory.invoke = function (methodName, callback) {
			factory.proxy.invoke(methodName).done(function (result) {
				$rootScope.$apply(function () {
					if (callback) {
						callback(result);
					}
				});
			});
		};

		factory.setQs = function (parameters) {
			factory.connection.qs = parameters;
		};

        // Starts the connection
		factory.start = function (success, fail, params) {
			if (factory.isInitialized) return;

            if (params) {
                factory.connection.qs = params;
			}
			return factory.connection.start().done(function () {
				factory.isInitialized = true;
				if (success)
					success();
			}).fail(function () {
				factory.isInitialized = false;
				if (fail)
					fail();
			});
        };

		factory.stop = function () {
			factory.isInitialized = false;
			factory.connection.stop();
		};
        return factory;
    }
})();
