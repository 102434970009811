(function () {
	angular.module('Plania').controller('DataImportController', ['$scope', 'TranslationService', 'Repository', 'RegisteredFieldService', '$q', '$stateParams', '$modal', '$location', 'SignalR', '$timeout', 'config', '$window', controller]);

	function controller($scope, translationService, repository, registeredFieldService, $q, $stateParams, $modal, $location, signalR, $timeout, config, $window) {
		$scope.isEdit = !!$stateParams.guid;

		$scope.entityTypes = [
			{ key: repository.apiData.estate.prefix, value: translationService.translate('web-enum-prefix-estate') },
			{ key: repository.apiData.building.prefix, value: translationService.translate('web-enum-prefix-building') },
			{ key: repository.apiData.equipment.prefix, value: translationService.translate('web-enum-prefix-equipment') },
			{ key: repository.apiData.area.prefix, value: translationService.translate('web-enum-prefix-area') },
			{ key: repository.apiData.component.prefix, value: translationService.translate('web-enum-prefix-component') },
			{ key: repository.apiData.supplier.prefix, value: translationService.translate('web-enum-prefix-supplier') },
			{ key: repository.apiData.person.prefix, value: translationService.translate('web-enum-prefix-person') },
			{ key: repository.apiData.customer.prefix, value: translationService.translate('web-enum-prefix-customer') },
			{ key: repository.apiData.cost.prefix, value: translationService.translate('web-enum-prefix-cost') },
			{ key: repository.apiData.doorKey.prefix, value: translationService.translate('web-enum-prefix-doorKey') },
			{ key: repository.apiData.doorLock.prefix, value: translationService.translate('web-enum-prefix-doorLock') },
			{ key: repository.apiData.condition.prefix, value: translationService.translate('web-enum-prefix-condition') },
		];

		$scope.entityTypes.sort(function (a, b) {
			if (a.value < b.value) {
				return -1;
			}
			if (a.value > b.value) {
				return 1;
			}
			return 0;
		});

		$scope.steps = [
			{ stepIndex: 1, name: 'chooseObject', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-chooseObject', 'Velg objekt'), onNextStepClicked: handleChooseObjectStep },
			{ stepIndex: 2, name: 'chooseSource', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-chooseSource', 'Velg kilde'), onNextStepClicked: handleChooseSourceStep, onStepAppearing: onChooseSourceAppearing },
			{ stepIndex: 3, name: 'editFieldMappings', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-editFieldMappings', 'Rediger knytninger'), onNextStepClicked: updateDataImport, onStepAppearing: onFieldMappingsAppearing },
			{ stepIndex: 4, name: 'validateImport', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-validateImport', 'Validering'), onNextStepClicked: updateDataImport },
			{ stepIndex: 5, name: 'startImport', hidden: false, title: translationService.translate('web-dataImport-wizard-stepTitle-startImport', 'Start import') },
		];

		if (!$scope.isEdit) {
			$scope.currentStep = $scope.steps[0];
			$scope.completedStepIndex = $scope.currentStep.stepIndex;
		}

		var setCurrentStep = function (step) {
			$location.hash(step.name).replace();
			$scope.currentStep = step;
			if ($scope.completedStepIndex === undefined || $scope.completedStepIndex < $scope.currentStep.stepIndex)
				$scope.completedStepIndex = $scope.currentStep.stepIndex;
			if ($scope.currentStep.onStepAppearing !== undefined)
				$scope.currentStep.onStepAppearing();
		};

		var onChangeStep = function (step) {
			if ($scope.currentStep.onNextStepClicked === undefined) {
				setCurrentStep(step);
				return;
			}

			$scope.currentStep.onNextStepClicked().then(function (valid) {
				if (!valid) return;
				setCurrentStep(step);
			});
		};

		$scope.onStepClick = function (step) {
			if (step.stepIndex > $scope.completedStepIndex) return;
			setCurrentStep(step);
		};

		$scope.handlePreviousStep = function () {
			var step = _.find($scope.steps, function (step) { return step.stepIndex === $scope.currentStep.stepIndex - 1; });
			if (step)
				setCurrentStep(step);
		};

		$scope.handleNextStep = function () {
			var step = _.find($scope.steps, function (step) { return step.stepIndex === $scope.currentStep.stepIndex + 1; });
			if (step)
				onChangeStep(step);
		};

		$scope.model = {
			Guid: $stateParams.guid,
			InputConfig: {
				Type: 'Excel',
				ExcelConfig: {}
			},
			FieldMapping: []
		};

		$scope.showSelectFile = true;

		if ($stateParams.guid) {
			getModel();
		} else if ($stateParams.guidEntityToCopy) {
			repository.getSingle(repository.apiData.dataImport.url, $stateParams.guidEntityToCopy).then(function (result) {
				delete result.Data.Guid;

				$scope.model = result.Data;
				$scope.model.Id += ' - kopi';
			});
		}

		$scope.currentNumberOfDataOwners = 0;
		function getNumberOfDataOwnersInLicense(){
			repository.getSingleWithNoParameter(repository.apiData.planiaSystem.url).then(function (result){
				if (result) {
					$scope.currentNumberOfDataOwners = result.CurrentNumberOfDataOwners;
				}
			}, function(error){
				repository.showError(error);
			});
		}
		getNumberOfDataOwnersInLicense();

		function getModel() {
			repository.getSingle(repository.apiData.dataImport.url, $scope.model.Guid).then(function (result) {
				$scope.model = result.Data;
				if ($scope.model.GuidBinaryData) {
					$scope.showSelectFile = false;
					$scope.getSheetOptions();
				}
				if ($scope.model.Summary) {
					$scope.runSummary = JSON.parse($scope.model.Summary);
				}

				if ($scope.model.ValidationSummary)
					$scope.validationSummary = JSON.parse($scope.model.ValidationSummary);

				if ($scope.model.Status === 'Started')
					checkJobStatus();

				var hash = $location.hash();
				if (hash) {
					setCurrentStep(_.find($scope.steps, function (step) { return step.name === hash; }));
				} else {
					if ($scope.model.Status === 'Completed')
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'startImport'; }));
					else if ($scope.model.FieldMapping && $scope.model.FieldMapping.length > 0)
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'validateImport'; }));
					else if ($scope.model.GuidBinaryData)
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'editFieldMappings'; }));
					else if ($scope.model.EntityType)
						setCurrentStep(_.find($scope.steps, function (step) { return step.name === 'chooseSource'; }));
				}

			}, function (error) {
				repository.growl(error, 'danger');
			});
		}

		registeredFieldService.getRegisteredField(repository.commonService.prefix.DataImport).then(function (result) {
			$scope.fieldRules = result;
		});

		function handleChooseObjectStep() {
			var deferred = $q.defer();

			if ($scope.model.Guid) {
				repository.updateSingle(repository.apiData.dataImport.url, $scope.model).then(function (response) {
					$scope.model = response;
					deferred.resolve(true);
				}, function (error) {
					repository.growl(error, 'danger');
					deferred.reject(error);
				});
			} else {
				repository.createSingle(repository.apiData.dataImport.url, $scope.model).then(function (response) {
					$scope.model = response;
					$location.path($location.path() + response.Guid); // cannot have slash between path and response.guid anymore
					$location.replace();
					deferred.resolve(true);
				}, function (error) {
					repository.growl(error, 'danger');
					deferred.reject(error);
				});
			}

			return deferred.promise;
		}

		function handleChooseSourceStep() {
			var deferred = $q.defer();
			repository.updateSingle(repository.apiData.dataImport.url, $scope.model).then(function (response) {
				$scope.model = response;

				repository.runAction(repository.apiData.dataImport.url + $scope.model.Guid + '/GetInitialFieldMappings').then(function (result) {
					$scope.model.FieldMapping = result.FieldMapping;
					deferred.resolve(true);
				}, function (error) {
					repository.growl(error, 'danger');
					deferred.reject(error);
				});
			}, function (error) {
				repository.growl(error, 'danger');
				deferred.reject(error);
			});

			return deferred.promise;
		}

		function onChooseSourceAppearing() {
			if (!$scope.sheetOptions) return;
			if ($scope.model.GuidBinaryData && !$scope.tempTable) {
				$scope.getPreview();
			}
		}

		function onFieldMappingsAppearing() {
			if (!$scope.tempTable) {
				$scope.getPreview();
			}
		}

		function updateDataImport() {
			var deferred = $q.defer();
			repository.updateSingle(repository.apiData.dataImport.url, $scope.model).then(function (response) {
				$scope.model = response;
				deferred.resolve(true);
			}, function (error) {
				repository.growl(error, 'danger');
				deferred.reject(error);
			});

			return deferred.promise;
		}

		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		$scope.getSheetOptions = function () {
			repository.getSingleWithNoParameter(repository.apiData.dataImport.url + $scope.model.Guid + '/SheetOptions').then(function (result) {
				$scope.sheetOptions = result.SheetOptions;

				if ($scope.previousConfig) {
					var sheetOption = _.find($scope.sheetOptions, function (option) {
						return option.SheetIndex === $scope.previousConfig.SheetNumber;
					});
					if (sheetOption)
						$scope.model.InputConfig.ExcelConfig = angular.copy($scope.previousConfig);
					else
						$scope.model.InputConfig.ExcelConfig.SheetNumber = 0;
				}

				if (!$scope.tempTable) $scope.getPreview();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.getPreview = function () {
			var queryParameters = "sheetNumber=" + $scope.model.InputConfig.ExcelConfig.SheetNumber + "&headerrow=" + $scope.model.InputConfig.ExcelConfig.HeaderRowPosition + "&datarow=" + $scope.model.InputConfig.ExcelConfig.DataRowStartPosition;

			repository.getWithUrlParameter(repository.apiData.dataImport.url + $scope.model.Guid + '/Preview', queryParameters).then(function (result) {
				$scope.tempTable = result.Table;
			}, function (error) {
				$scope.tempTable = [];
				repository.growl(error, 'danger');
			});
		};

		$scope.files = [];
		$scope.onFileSelect = function ($files) {
			if (!$files || $files.length === 0) return;

			$scope.file = $files[0];
			$scope.uploadFile();
		};

		$scope.uploadFile = function () {
			if (!$scope.file) return;
			$scope.isUploading = true;
			repository.updateSingleWithBinary(repository.apiData.document.urlChunk, $scope.model, $scope.file).then(
				function (result) {
					repository.growl('Filen er lastet opp', 'info');
					$scope.isUploading = false;
					$scope.showSelectFile = false;
					$scope.file = null;
					$scope.tempTable = null;
					if ($scope.model.InputConfig)
						$scope.previousConfig = $scope.model.InputConfig.ExcelConfig;
					getModel();
				}, function (error) {
					repository.growl(error[0].error, 'danger');
					$scope.isUploading = false;
					$scope.file.progressType = 'danger';
				}, function (file) { //UPDATE
					$scope.file.total = file.total;
					$scope.file.count = file.count;
					$scope.file.progressType = file.count >= file.total ? 'success' : 'info';
				});
		};

		$scope.setMappingColumn = function (mapping) {
			var existingColumns = [];

			$scope.model.FieldMapping.forEach(function (map) {
				if (map.PlaniaField)
					existingColumns.push({ Property: map.PlaniaField });
			});

			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: $scope.model.EntityType,
							existingColumns: existingColumns,
							showAdvancedMode: true
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				if (selectedColumns && selectedColumns.length) {
					var splittedProperties = selectedColumns[0].Property.split(".");
					if (splittedProperties.length >= 3) return;

					mapping.PlaniaField = selectedColumns[0].Property;
					mapping.PlaniaFieldTitle = selectedColumns[0].Title;
				}
			});
		};

		$scope.resetMappingColumn = function (mapping) {
			if (!mapping) return;
			var mappingToChange = _.find($scope.model.FieldMapping, function (fieldMapping) {
				return fieldMapping.ImportField === mapping.ImportField;
			});
			if (mappingToChange.PlaniaField === $scope.model.PrimaryField)
				$scope.model.PrimaryField = null;
			mappingToChange.PlaniaField = null;
			mappingToChange.PlaniaFieldTitle = null;
		};

		$scope.setPrimaryField = function (mapping) {
			if (!mapping.PlaniaField || mapping.PlaniaField.split(".").length >= 2) return;
			$scope.model.PrimaryField = mapping.PlaniaField;
		};

		$scope.mappingPrimaryFieldTooltipText = {
			isPrimaryField: translationService.translate('web-dataImport-fieldMapping-isPrimaryField-popover', 'Denne kolonnen er valgt som hovedkolonne. Denne blir da brukt for å sjekke om et import objekt eksisterer i Plania.'),
			isRelationField: translationService.translate('web-dataImport-fieldMapping-isRelationField-popover', 'Denne kolonnen er en knytningskolonne. Denne blir brukt for å knytte et import objekt til et annet relatert objekt som allerede eksisterer i Plania.'),
			isNotSelected: translationService.translate('web-dataImport-fieldMapping-isNotSelected-popover', 'Klikk for å velge denne kolonnen som hovedkolonne. Denne blir da brukt for å sjekke om et import objekt eksisterer i Plania.'),
			isOwnedField: translationService.translate('web-dataImport-fieldMapping-isOwnedField-popover', 'Denne kolonnen kan opprettes eller oppdateres likt som egenskaper på import objektet.'),
		};

		function getRelationType (mapping){
			if (!mapping.PlaniaField) return "Direct";
			if (mapping.PlaniaField.startsWith("NonsReference."))
				return "Owned";
			else if (mapping.PlaniaField.includes("."))
				return "Relation";
			return "Direct";
		}

		$scope.getTooltipText = function(mapping, model) {
			var relationType = getRelationType(mapping);
			if (model.PrimaryField === mapping.PlaniaField) {
				return $scope.mappingPrimaryFieldTooltipText.isPrimaryField;
			}
			else if (relationType === "Relation") {
				return $scope.mappingPrimaryFieldTooltipText.isRelationField;
			} else if (relationType === "Owned") {
				return $scope.mappingPrimaryFieldTooltipText.isOwnedField;
			}
			else {
				return $scope.mappingPrimaryFieldTooltipText.isNotSelected;
			}
		};

		$scope.getClasses = function(mapping, model) {
			var relationType = getRelationType(mapping);
			var classes = {
				'fas fa-star c-amber': model.PrimaryField === mapping.PlaniaField,
				'fas fa-star c-bluegray': relationType === "Relation",
				'far fa-star': model.PrimaryField !== mapping.PlaniaField && (relationType === "Direct" || relationType === "Owned"),
			};

			// Convert the dictionary to a space-separated string
			var classString = '';
			for (var key in classes) {
				if (classes[key]) {
					classString += key + ' ';
				}
			}
			// Remove trailing whitespace
			return classString.trim();
		};
		initializeImportJobValidatingSignalR(getValidatingSignalRMethodName());

		$scope.runValidation = function () {
			updateDataImport().then(function () {
				$scope.validationSummary = {
					RowSummaries: []
				};
				repository.runAction(repository.apiData.dataImport.url + $scope.model.Guid + '/RunValidation').then(function (result) {
					$scope.importJobId = result;
					$scope.importJobValidating = true;
					$scope.importJobValidationProgress = { Current: 0, Total: 100 };
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});

		};

		$scope.cancelValidation = function() {
			if (!$scope.importJobId) return;
			repository.runAction(repository.apiData.dataImport.url + $scope.model.Guid + '/CancelValidation/' + $scope.importJobId).then(function (result) {
				repository.growl('Validering avbrutt', 'info');
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		function checkJobStatus() {
			repository.getSingleWithNoParameter(repository.apiData.dataImport.url + $scope.model.Guid + '/JobStatus').then(function (result) {
				if (result)
					$scope.importJobId = result;
			});
		}

		initializeImportJobProcessingSignalR(getProcessingSignalRMethodName());

		$scope.startImport = function () {
			$scope.runSummary = {
				RowSummaries: []
			};
			repository.runAction(repository.apiData.dataImport.url + $scope.model.Guid + '/RunImport').then(function (result) {
				$scope.importJobId = result;
				$scope.importJobProcessing = true;
				$scope.importJobProgress = { Current: 0, Total: 100 };
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.cancelImport = function() {
			if (!$scope.importJobId) return;
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cancelImport-message', "Importen vil avbrytes"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-button-yes', 'Ja'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.runAction(repository.apiData.dataImport.url + $scope.model.Guid + '/CancelImport/' + $scope.importJobId).then(function (result) {
					repository.growl('Import avbrutt', 'info');
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};

		function getProcessingSignalRMethodName() {
			return 'processingDataImportRun_' + $scope.model.Guid;
		}

		function getValidatingSignalRMethodName() {
			return 'validatingDataImportRun_' + $scope.model.Guid;
		}

		function initializeImportJobValidatingSignalR(methodName) {
			if (!$scope.validationSummary)
				$scope.validationSummary = {
					RowSummaries: []
				};

			signalR.on(methodName, function (progress) {
				if (progress.Finished === true) {
					$scope.importJobValidating = false;
					return;
				}
				$scope.importJobValidating = true;
				$scope.importJobValidationProgress = progress;

				$scope.validationSummary.RowSummaries.push(progress.RowSummary);
				$scope.validationSummary.RunStatistics = progress.RunStatistics;

			});

			$scope.$on('$destroy', function () {
				signalR.off(methodName);
			});
		}

		function initializeImportJobProcessingSignalR(methodName) {
			if (!$scope.runSummary)
				$scope.runSummary = {
					RowSummaries: []
				};

			signalR.on(methodName, function (progress) {
				if (progress.Finished === true) {
					$timeout(function () {
						$scope.importJobProcessing = false;
						getModel();
					}, 250);
					return;
				}

				$scope.importJobProcessing = true;
				$scope.importJobProgress = progress;

				$scope.runSummary.RowSummaries.push(progress.RowSummary);
				$scope.runSummary.RunStatistics = progress.RunStatistics;
			});

			$scope.$on('$destroy', function () {
				signalR.off(methodName);
			});
		}

		$scope.downloadSummaryReport = function () {
			var url = config.baseUrlApi + repository.apiData.dataImport.url + $scope.model.Guid + '/ExportExcelSummary';
			downloadSummaryReport(url);
		};

		$scope.downloadValidationReport = function () {
			var url = config.baseUrlApi + repository.apiData.dataImport.url + $scope.model.Guid + '/ExportExcelValidationSummary';
			downloadSummaryReport(url);
		};

		function downloadSummaryReport(url) {
			url += "?access_token=" + repository.authService.getPlaniaToken().access;
			url += repository.getUrlParameters('&');

			$window.open(url, '_self');
		}

		$scope.createNewImport = function() {
			$scope.navigation.go('dataImport.edit', { guidEntityToCopy: $scope.model.Guid }, { inherit: false });
		};

		$scope.deleteImport = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-dataImport-wizard-startImport-deleteMessage', "Dataimport vil bli fjernet"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-button-confirm', 'Ja'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.dataImport.url, $scope.model.Guid).then(
					function () {
						repository.growl("Dataimport slettet", "success");
						$scope.goBack('dataImport.list', { menuGuid: $scope.navigation.params.menuGuid });
						swal.close();
					}
				);
			});
		};

		$scope.summaryStatusFilter = '';
		$scope.validationSummaryStatusFilter = '';
		$scope.rowStatuses = [
			{ key: 'Created', value: 'Opprettet' },
			{ key: 'Updated', value: 'Oppdatert' },
			{ key: 'Error', value: 'Feilet' },
		];

		$scope.summaryFilter = function (status) {
			return function (row) {
				return !status || row.RowStatus === status;
			};
		};
	}
})();
