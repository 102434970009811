(function () {
	angular.module('Plania').directive('imageGalleryItem', [function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.on('load', function (event) {
					var image = $(element);
					if (image[0].height + 44 > image[0].width) {
						image.css('height', '100%');
						image.css('width', 'auto');
					} else {
						image.css('position', 'absolute');
						image.css('transform', 'translate(0%, -50%)');
						image.css('top', '50%');
						image.css('height', 'auto');
						image.css('width', '100%');
					}
				});
			}
		};
	}
	]);
})();
