(function () {
	angular.module('Plania').factory('ColumnFormatService', ['$modal', columnFormatService]);

	function columnFormatService($modal) {
		var service = {};

		service.chooseColumn = function(model, modelProperty, entityName) {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () { return { prefix: entityName }; }
				}
			});
			if (!model[modelProperty])
				model[modelProperty] = '';

			modalInstance.result.then(function (selectedColumns) {
				selectedColumns.forEach(function (col) {
					var splitted = col.Property.split('.');
					var propertyString = (entityName.charAt(0).toLowerCase() + entityName.slice(1)).replace(/([A-Z])/g, function (value) { return '_' + value; }).toUpperCase();
					splitted.forEach(function (colPart, index) {
						colPart = colPart.charAt(0).toLowerCase() + colPart.slice(1);
						var snakeCaseCol = colPart.replace(/([A-Z])/g, function (value) { return '_' + value; }).toUpperCase();
						if (index === (splitted.length - 1)) {
							propertyString += '.' + snakeCaseCol;
							return;
						}
						propertyString += '#GUID_' + snakeCaseCol + '\\' + snakeCaseCol;
					});
					model[modelProperty] += '[' + propertyString + ']';
				});
			});
		};

		return service;
	}
})();
