(function () {
	angular.module('Plania').factory('WorkOrderService', ['Repository', 'TranslationService', '$q', workOrderService]);

	function workOrderService(repository, translationService, $q) {
		var service = {};

		var errorMessagesStage0 = [
			{
				system: 'web-workOrderXResourceGroup-realTime-workOrder-stage0',
				default: "Kan ikke registrere timer på en planlagt arbeidsordre"
			},
			{
				system: 'web-workOrder-stage0-completionRate',
				default: "Kan ikke sette fullføringsgrad på en planlagt arbeidsordre"
			},
			{
				system: 'web-workOrder-stage0-endDate',
				default: "Kan ikke sette fullføringsgrad på en planlagt arbeidsordre"
			},
			{
				system: 'web-purchaseOrder-CanActivateWorkOrder',
				default: 'Kan ikke bestille med en eller flere bestillingsobjekter koblet til en planlagt arbeidsordre, men arbeidsordren kan bli aktivert ved å fortsette'
			}
		];

		service.activateWorkOrders = function (workOrderGuids) {
			return repository.runAction(repository.apiData.workOrder.endpoint.activateWorkOrders, workOrderGuids);
		};

		service.shouldActivateWorkOrder = function (workOrderGuid, errorMessage) {
			var deferred = $q.defer();

			if (!repository.authService.hasEditAccess(repository.commonService.prefix.WorkOrder))
				return $.Deferred().reject(errorMessage).promise();

			var swalText = errorMessage;
			if (errorMessage)
				swalText += '\n\n';
			swalText += translationService.translate('web-swal-workOrder-activate-message', 'Arbeidsordren må bli aktivert for å utføre denne handlingen, ønsker du å aktivere nå?');

			swal({
				title: translationService.translate('web-swal-workOrder-activate', 'Arbeidsordren er ikke aktiv'),
				text: swalText,
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-button-yes', 'Ja'),
				cancelButtonText: translationService.translate('web-button-no', 'Nei'),
				closeOnConfirm: false
			}, function (isSuccess) {
				window.onkeydown = null;
				window.onfocus = null;
				if (isSuccess) {
					if (workOrderGuid)
						service.activateWorkOrders([workOrderGuid])
							.then(function (result)
							{
								swal.close();
								deferred.resolve(result);
							}, function (error)
							{
								swal.close();
								var workOrderError = translationService.translate('web-workOrder', 'Arbeidsordre') + ' - ' + error;
								deferred.reject(workOrderError);
							});
					else {
						deferred.resolve();
						swal.close();
					}
				}
				else {
					swal.close();
					deferred.reject();
				}
			});

			return deferred.promise;
		};

		service.isWorkOrderStage0 = function (errorMessage) {
			if (errorMessage) {
				for (var i = 0; i < errorMessagesStage0.length; i++) {
					var msg = translationService.translate(errorMessagesStage0[i].system, errorMessagesStage0[i].default);
					if (msg === errorMessage)
						return true;
				}
			}

			return false;
		};


		return service;
	}
})();
