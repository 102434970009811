(function () {
	angular.module('Plania').controller('RegisterExternalLoginModalController', ['$scope', '$modalInstance', '$http', 'Repository', 'config', 'params', '$location',  controller]);

	function controller($scope, $modalInstance, $http, repository, config, params, $location) {
		$scope.model = {
			email: params.email,
			cellPhone: params.cellPhone,
			token: params.token,
			verificationCode: null,
		};
		$scope.externalLoginProvider = params.externalLoginProvider;
		$scope.showTokenInput = !!$scope.model.token;
		$scope.isLoading = false;

		if (!$scope.externalLoginProvider.SendVerificationCodeUsingEmail && !$scope.externalLoginProvider.SendVerificationCodeUsingSms){
			$scope.externalLoginProvider.SendVerificationCodeUsingEmail = true;
			$scope.VerificationMode = "Email";
		}
		else if (!$scope.externalLoginProvider.SendVerificationCodeUsingEmail && $scope.externalLoginProvider.SendVerificationCodeUsingSms) {
			$scope.VerificationMode = "Sms";
		}
		else{
			$scope.VerificationMode = "Email";
		}


		$scope.send = function(){
			if (!$scope.model.email && !$scope.model.cellPhone)
				return;
			$scope.isLoading = true;
			var postConfig = {
				method: 'POST',
				url: encodeURI(config.baseUrl + 'login/external/register'),
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
				},
				data: {
					CellPhone: $scope.model.cellPhone,
					EmailAddress: $scope.model.email,
					ExternalLoginProvider: $scope.externalLoginProvider.Name
				}
			};
			$http(postConfig).success(function (response) {
				$scope.model.token = response.Token;
				$location.search('token', $scope.model.token);
				$location.search('email', $scope.model.email);
				$location.search('cellPhone', $scope.model.cellPhone);
				$scope.showTokenInput = true;
				$scope.isLoading = false;
			})
				.error(function (message) {
					repository.showError(message);
					$scope.isLoading = false;
				});

		};
		$scope.verify = function(){
			if (!$scope.model.verificationCode)
				return;
			$scope.isLoading = true;

			var postConfig = {
				method: 'POST',
				url: encodeURI(config.baseUrl + 'login/external/verify'),
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
				},
				data: {
					VerificationCode: $scope.model.verificationCode,
					Token: $scope.model.token
				}
			};
			$http(postConfig).success(function (response) {
				repository.growl(response.Message, 'success');
				$location.search({});
				$modalInstance.close($scope.externalLoginProvider.Name);
				$scope.isLoading = false;
			})
				.error(function (message) {
					repository.showError(message);
					$scope.isLoading = false;
				});
		};
		$scope.cancel = function () {
			$location.search({});
			$modalInstance.dismiss('cancelled');
		};
	}
})();
