(function () {
	var app = angular.module('Plania');
	app.directive('equipmentMaintenanceDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				update: '=update',
				reloadDeviations: '=reloadDeviations'
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', 'ListService', 'NgTableParams', controller],
			templateUrl: 'app/equipment/views/equipmentMaintenance.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope, listService, ngTableParams) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		if (!$scope.update) return;

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};
		
		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 1, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-equipment-resourcegroup-description', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 6, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 7, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
		];
		
		$scope.periodicTaskColumns = [
			{ Position: 1, Title: translationService.translate('web-periodicTask-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
			{ Position: 5, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
			{ Position: 5, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
			{ Position: 7, Title: translationService.translate('web-periodicTask-description'), Property: 'Description', PropertyType: 'string' },
		];

		var ptFilter = {
			PropertyFilter: [{ Property: 'GuidEquipment', Operator: '=', Value: $scope.model.Guid }]
		};

		$scope.periodicTaskTable = new ngTableParams(
			{
				page: 1,
				count: 20,
				sorting: { 'Id': 'desc' },
				filter: ptFilter
			}, {
				total: 0,
				filterDelay: 10,
				counts: [10, 20, 50],
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					var columns = [];
					$scope.periodicTaskColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.periodicTask.url, params.page() - 1, params.count(), params.sorting(), params.filter(), '', JSON.stringify(columns)).then(function (result) {
						$scope.periodicTaskTable.settings().total = result.TotalCount;
						$scope.periodicTaskTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.showError(error);
					});
				}
			});

		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create',
				{
					guidEstate: $scope.model.Building ? $scope.model.Building.Estate.Guid : null,
					estate: $scope.model.Building ? $scope.model.Building.Estate.Caption : '',
					guidBuilding: $scope.model.GuidBuilding,
					guidEquipment: $scope.model.Guid,
					building: $scope.model.Building ? $scope.model.Building.Caption : '',
					guidArea: $scope.model.GuidArea,
					area: $scope.model.Area ? $scope.model.Area.Caption : '',
					equipment: $scope.model.Id + ' - ' + $scope.model.Description,
					fromEquipment: true
				});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		$scope.createPeriodicTask = function () {
			repository.persistedData.setPersistedData('periodicTask.create',
				{
					guidEstate: $scope.model.Building ? $scope.model.Building.Estate.Guid : null,
					estate: $scope.model.Building ? $scope.model.Building.Estate.Caption : '',
					guidBuilding: $scope.model.GuidBuilding,
					building: $scope.model.Building ? $scope.model.Building.Caption : '',
					guidEquipment: $scope.model.Guid,
					equipment: $scope.model.Id + ' - ' + $scope.model.Description,
					guidArea: $scope.model.GuidArea,
					area: $scope.model.Area ? $scope.model.Area.Caption : '',
					fromEquipment: true
				});
			$scope.navigation.go('periodicTask.create', { showPersistantData: true }, { reload: true });
		};
	}
})();
