(function() {

	angular.module('Plania').directive('plPrintLabel', function() {
		return {
			restrict: 'A',
			scope: {
				guids: '=guids', //selectedGuids
				domainName: '=domainName', //domainname of object to be printed
				onPrint: '=onPrint', // function to call when printed (should normally be called from the renderingEnd function of the telerikReport directive)
			},
			controller: ['$scope', '$rootScope', '$window', 'TranslationService', '$localStorage', printController],
            templateUrl: 'app/common/directives/views/printLabelDirective.html'
		};
	});

	var printController = function($scope, $rootScope, $window, translationService, $localStorage) {
		$scope.printDetails = function() {

			if($scope.guids.length === 0) {
				swal(
					'Ingen valg',
					translationService.translate('list-printlabel-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å skrive ut etikett'),
					'error');
				return;
			}

            var params = {
				Guids: JSON.stringify($scope.guids),
				GuidBarcode: null
            };

			$localStorage.reportParams = JSON.stringify(params);


			if ($scope.domainName === 'Component') {
				$scope.domainName = 'SparePart';
			}

			var reportName = 'PrintLabel' + $scope.domainName;

			var url = $rootScope.navigation.href('report.print', {
				reportName: reportName,
				reportParams: JSON.stringify(params)
			});

			$window.open(url, '_blank');
		};
	};

})();
