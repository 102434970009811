angular.module('Plania').factory('DoorLockThemeService', ['$modal', 'Repository', '$q', 'TranslationService', 'DwgMarkerService', '$filter', 'DwgThemeService', function ($modal, repository, $q, translationService, markerService, $filter, dwgThemeService) {
	var service = {};

	var hasCleaningToday = '#2196F3';

	service.getLegends = function () {
		var legends = [
			{ label: translationService.translate('web-doorLockTheme-legend-hasDoorLock', 'Rom med sylindre'), legendClass: 'legend-primary' },
		];

		return legends;
	};

	var updateIndicatorColours = function (groupedAreas) {
		if (!groupedAreas) return;

		var groupKeys = Object.keys(groupedAreas);

		groupKeys.forEach(function (key) {
			var elements = $('#' + key).children();
			if (elements[0]) {
				var path = elements[0];
				$(path).attr('stroke-dasharray', '');
				$(path).attr('stroke-linecap', '');
				$(path).attr('stroke', hasCleaningToday);
				$(path).attr('fill', hasCleaningToday);
				$(path).attr('stroke-width', '100');
				$(path).attr('stroke-opacity', '1');
				$(path).attr('fill-opacity', '0.3');
			}
		});
	};

	service.onClick = function (guid) {
		var deferred = $q.defer();
		var tabs = {
			area: { visible: repository.authService.hasReadAccess(repository.apiData.area.prefix), active: true }
		};

		$modal.open({
			templateUrl: 'app/dwg/views/dwgSelectionModal.html',
			size: 'lg',
			controller: 'DwgSelectionModalController',
			animation: false,
			resolve: {
				params: function () { return { guid: guid, tabInfo: tabs }; }
			}
		}).result.then(function (result) {
			deferred.resolve({});
		}, function (error) {
			if (error === 'cancel') deferred.resolve({});
			else deferred.reject();
		});
		return deferred.promise;
	};

	var drawLabelOnAreas = function (groupedAreas, categoryStyle) {
		if (!groupedAreas) return;

		var groupKeys = Object.keys(groupedAreas);

		groupKeys.forEach(function (key) {
			var elements = $('#' + key).children();
			if (elements[0]) {
				var path = elements[0];
				markerService.addDoorLockLabels(path, groupedAreas[key], categoryStyle);
			}
		});
	};

	service.getStatus = function (areas, guidDrawing, selectedDoorLocks, doorLockColors) {
		dwgThemeService.removeAreaIndicators(areas);
		dwgThemeService.removeRequestMarkers(areas);
		dwgThemeService.removePeriodicMarkers(areas);
		dwgThemeService.removeEquipmentCategoryLabels(areas);
		var defer = $q.defer();

		if (!selectedDoorLocks || selectedDoorLocks.length === 0) {
			dwgThemeService.removeDoorLockLabels(areas);
			return $.Deferred().resolve().promise();
		}

		var doorLockXAreaFilter = {
			PropertyFilter: [
				{ Property: 'GuidDoorLock', Operator: 'in', Value: selectedDoorLocks.map(function (cat) { return cat.Guid; }).join(',') },
				{ Property: 'Area.GuidDrawing', Operator: '=', Value: guidDrawing }
			]
		};

		repository.GetPaginated(repository.apiData.doorLockXArea.url, 0, -1, { 'Area.Id': 'asc' }, doorLockXAreaFilter, null, JSON.stringify(['Id', 'Description', 'Area.Id', 'DoorLock.Id'])).then(function (result) {
			dwgThemeService.removeDoorLockLabels(areas);
			drawLabelOnAreas($filter('groupBy')(result.List, 'GuidArea'), doorLockColors);
			updateIndicatorColours($filter('groupBy')(result.List, 'GuidArea'));
			defer.resolve();
		}, function (error) {
			defer.reject();
		});
		return defer.promise;
	};

	service.selectDoorLocks = function (selectedDoorLocks, guidDrawing) {
		var defer = $q.defer();

		var filterModel = {
			Condition: "and",
			Rules: [
				{
					Property: "DoorLockXAreas.Area.GuidDrawing", Operator: "=", Value: guidDrawing
				}
			]
		};

		$modal.open({
			templateUrl: 'app/common/views/multipleSelectModal.html',
			controller: 'MultipleSelectModalController',
			animation: false,
			resolve: {
				modalParams: function () {
					return {
						title: translationService.translate('web-doorLock-id', 'Velg sylinder'),
						columns: [
							{ Title: translationService.translate('web-doorLock-id'), Property: 'Id' },
							{ Title: translationService.translate('web-doorLock-Description'), Property: 'Description' }
						],
						useObjects: true,
						url: repository.apiData.doorLock.url,
						sorting: { Description: 'asc' },
						filter: { FilterModel: filterModel },
						alreadySavedGuids: function () {
							var selectedGuids = [];
							if (selectedDoorLocks) {
								selectedDoorLocks.forEach(function (entity) {
									selectedGuids.push(entity.Guid);
								});
							}
							return selectedGuids;
						}()
					};
				}
			}
		}).result.then(function (newlySelected) {
			var returnArray = [];

			newlySelected.forEach(function (type) {
				if (!_.find(selectedDoorLocks, function (o) { return o.Guid === type.Guid; }))
					returnArray.push(type);
			});

			defer.resolve(returnArray);
		}, function (error) {
			defer.reject();
		});

		return defer.promise;
	};
	return service;
}]);
