(function () {
	var app = angular.module('Plania');
	app.controller('RuleModalController', ['$scope', '$modalInstance', 'Repository', 'params', 'TranslationService', '$modal', controller]);

	function controller($scope, $modalInstance, repository, params, translationService, $modal) {
		$scope.activateAutocomplete = false;
		$scope.isArray = angular.isArray;
		$scope.controlListItems = params.controlListItems;
		$scope.selectedFormItem = params.selectedFormItem;
		$scope.prefillKeywords = repository.prefillKeyWords;

		$scope.operators = ['=', '<>', '>', '<', '>=', '<=']; //'in', 'contains', 'startsWith'
		$scope.parameterColumns = [];
		$scope.groupedControlListItems = [];

		$scope.availableActions = [
			{ Value: 'Show', icon: 'eye' },
			{ Value: 'Hide', icon: 'eye-slash' }
		];

		if (repository.moduleService.hasModule(repository.moduleService.moduleFlags.Deviation)) {
			$scope.availableActions.push({ Value: 'Deviation', icon: 'octagon-exclamation' });
			$scope.availableActions.push({ Value: 'ClosedDeviation', icon: 'octagon-exclamation' });
		}

		if (repository.moduleService.hasModule(repository.moduleService.moduleFlags.Activity)) {
			$scope.availableActions.push({ Value: 'Request', icon: 'envelope' });
		}

		// Group the dropdown by the section header. Should we find a way to make the groupkey be unique, since equal section header names will group into same?
		var currentSection = null;
		params.controlListItems.forEach(function (controlListItem) {
			if (controlListItem.Type === 'SectionHeader') {
				currentSection = controlListItem.Name;
			} else if (controlListItem.Type !== 'Info') {
				if (currentSection) controlListItem.Section = currentSection;
				$scope.groupedControlListItems.push(controlListItem);
			}
		});

		var parseConditions = function (condition) {
			if (condition && condition.Filter) {
				_.each(condition.Filter, function (filter) {
					filter.GuidControlListItem = filter.GuidControlListItem.toLowerCase();
				});
			}
			return condition;
		};

		if (params.rule) {
			$scope.rule = angular.copy(params.rule);
			$scope.rule.Conditions = parseConditions($scope.rule.Conditions);
			$scope.selectedAction = $scope.rule.Action;
			removeAutocompleteLabelValues();
		} else {
			$scope.rule = {
				GuidControlListItem: $scope.selectedFormItem ? $scope.selectedFormItem.Guid : null,
				Action: null,
				Conditions: {
					Filter: [{
						Operator: '='
					}]
				},
				IsMandatory: false,
				Parameters: []
			};
		}

		setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

		function removeAutocompleteLabelValues() {
			$scope.rule.Parameters.forEach(function (parameter) {
				if (parameter.IsExpandable && parameter.Value) {
					delete parameter.Value.Label;
				}

				// Data is set from searching in autocomplete. This can contain a lot of data. 
				if (parameter.data)
					delete parameter.data;
			});
		}

		function removeEmptyParameterValueFields () {
			$scope.rule.Parameters.forEach(function (parameter) {
				if (parameter.Value && $scope.isArray(parameter.Value)) {
					parameter.Value = _.filter(parameter.Value, function (val) { return !!val; });
				}
			});
		}

		$scope.selectAction = function (action) {
			if (action.Value === $scope.selectedAction) return;

			$scope.selectedAction = action.Value;

			switch ($scope.selectedAction) {
				case 'Request':
					$scope.parameterColumns = requestColumns;
					break;
				case 'Deviation':
				case 'ClosedDeviation':
					$scope.parameterColumns = deviationColumns;
					break;
			}

			$scope.rule = {
				GuidControlListItem: $scope.selectedFormItem ? $scope.selectedFormItem.Guid : null,
				Action: action.Value,
				Conditions: {
					Filter: [{
						GuidControlListItem: ($scope.selectedAction === 'Deviation' || $scope.selectedAction === 'Request' || $scope.selectedAction === 'ClosedDeviation') && $scope.selectedFormItem ? $scope.selectedFormItem.Guid : null,
						Operator: '='
					}]
				},
				IsMandatory:false,
				Parameters: []
			};
		};

		// Some actions expects selectedFormItem to be selected, but we need to handle situations where it is not selected at start.
		// These actions is able to select an item if selectedFormItem is missing, but fails to actually update the GuidControlListItem reference
		$scope.changedGuidControlListItem = function () {
			if ($scope.selectedFormItem)
				return;

			if ($scope.selectedAction !== 'Deviation' && $scope.selectedAction !== 'Request' && $scope.selectedAction !== 'ClosedDeviation')
				return;

			var hasConditionsGuidReference = $scope.rule && $scope.rule.Conditions && $scope.rule.Conditions && $scope.rule.Conditions.Filter && $scope.rule.Conditions.Filter[0] && $scope.rule.Conditions.Filter[0].GuidControlListItem;
			if (hasConditionsGuidReference) {
				$scope.rule.GuidControlListItem = $scope.rule.Conditions.Filter[0].GuidControlListItem;
			}
		};

		//Get deviation columns for parameter prefill
		var locale = translationService.getLocale();
		var deviationColumns, requestColumns;
		repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=Deviation_RuleSelection' + '&locale=' + locale).then(function (result) {
			deviationColumns = result;
			if ($scope.rule.Action === 'Deviation' || $scope.rule.Action === 'ClosedDeviation')
				$scope.parameterColumns = deviationColumns;
		});

		repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=Request_RuleSelection' + '&locale=' + locale).then(function (result) {
			requestColumns = result;
			if ($scope.rule.Action === 'Request')
				$scope.parameterColumns = requestColumns;
		});

		$scope.changedParameter = function (parameter) {
			parameter.Value = null;
			var parameterColumn = _.find($scope.parameterColumns, function (o) { return o.Property === parameter.Property; });
			parameter.PropertyType = parameterColumn.PropertyType;
			parameter.IsExpandable = parameterColumn.IsExpandable;
			if (parameter.PropertyType === 'bool') {
				parameter.Value = true;
            }
            if (parameterColumn.GuidProperty)
                parameter.GuidProperty = parameterColumn.GuidProperty;
		};

		$scope.changedAutoCompleteValue = function (value) {
			if (!value)
				value = {};
		};

		$scope.getTypeOf = function (value) {
			if (value === null) {
				value = {};
			}

			return typeof (value);
		};

		$scope.getPrefillOptionsForAutocomplete = function (parameter) {
			if (!parameter || !parameter.PropertyType)
				return [];

			switch (parameter.PropertyType.toLowerCase()) {
				case "person":
					return repository.prefillKeyWords.filter(function (keyword) {
						return keyword === "@LoggedInPerson";
					});
			}

			return [];
		};

		$scope.getItemFromGuid = function (guid) {
			if (!guid) return;
			var item = _.find($scope.controlListItems, { Guid: guid.toLowerCase() });
			return item;
		};

		$scope.isGuid = function (stringToTest) {
			if (!stringToTest) return false;

			var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
			return regexGuid.test(stringToTest);
		};

		$scope.showParameterTextInput = function (parameter) {
			return (!$scope.isArray(parameter.Value) || parameter.Value.length === 0) && !$scope.isGuid(parameter.Value);
		};

		$scope.addParameterValue = function (parameter) {
			if (!$scope.isArray(parameter.Value)) return;
			if (!parameter.Value[parameter.Value.length - 1]) return;
			parameter.Value.push("");
		};

		$scope.removeParameterValue = function (parameter, index) {
			if ($scope.isArray(parameter.Value)) {
				parameter.Value.splice(index, 1);
				if (parameter.Value.length === 0)
					parameter.Value = null;
			} else {
				parameter.Value = null;
			}
		};

		$scope.onPrefillControlListItemClick = function (parameter, item, index) {
			if ((($scope.rule.Action === 'Deviation' || $scope.rule.Action === 'ClosedDeviation') && parameter.Property === 'ActionComment') || ($scope.rule.Action === 'Request' && parameter.Property === 'ExplanatoryText')) {
				// Allow for multiple select
				if (!parameter.Value)
					parameter.Value = [];
				else if (!$scope.isArray(parameter.Value) && $scope.isGuid(parameter.Value))
					parameter.Value = [parameter.Value];
				else if (!$scope.isArray(parameter.Value))
					parameter.Value = [];

				if (index === undefined || index === null) index = 0;
				parameter.Value[index] = item.Guid;

			} else {
				parameter.Value = item.Guid;
			}
		};

		$scope.openControlListItemSelectionModal = function (parameter, index) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListItemSelectionModal.html',
				controller: 'ControlListItemSelectionModalController',
				resolve: {
					params: function () {
						return {
							controlListItems: params.controlListItems
						};
					}
				}
			}).result.then(function (item) {
				$scope.onPrefillControlListItemClick(parameter, item, index);
			});
		};

		$scope.ok = function () {
			removeAutocompleteLabelValues();
			removeEmptyParameterValueFields();
			$modalInstance.close($scope.rule);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
        };

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "priority":
					if ($scope.selectedAction === 'Deviation' || $scope.selectedAction === 'ClosedDeviation')
						return { PropertyFilter: [{ Property: 'EntityType', Operator: '=', Value: "Deviation" }] };
			}
			return {};
		};
	}
})();
