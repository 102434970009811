(function () {
	angular.module('Plania').controller('EditTableColumnsModalController', ['$scope', '$modalInstance', '$modal', 'params', 'Repository', 'TranslationService', controller]);

	function controller($scope, $modalInstance, $modal, params, repository, translationService) {
		$scope.model = params.listSetup;
		$scope.user = repository.authService.getUserData();

		var originalColumnSetup = params.listSetup.Columns.slice();

		$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebListView, false);

		$scope.addNewColumn = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () { return { prefix: params.entity, existingColumns: $scope.model.Columns }; }
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				selectedColumns.forEach(function (column) {
					$scope.model.Columns.push(column);
				});
			});
		};

		$scope.removeColumn = function (index) {
			var setNewSorting = false;
			if ($scope.model.Columns[index].Property === $scope.sortingProperty) setNewSorting = true;

			$scope.model.Columns.splice(index, 1);

			if (setNewSorting) $scope.sortingProperty = $scope.model.Columns[0].Property;
		};

		var setColumnPositionOnModel = function () {
			for (var i = 0; i < $scope.model.Columns.length; i++) {
				var filter = $scope.model.Columns[i].Filter;
				if (filter) {
					filter.forEach(handleDateFilter);
					$scope.model.Columns[i].Filter = JSON.stringify(filter);
				}

				$scope.model.Columns[i].Position = i + 1;
			}
		};

		$scope.cancel = function () {
			$scope.model.Columns = originalColumnSetup;
			$modalInstance.dismiss('canceled');
		};

		$scope.revertToDefault = function () {
			if (!params.defaultListSetup) return;

			$scope.model.Columns = params.defaultListSetup.Columns;
			$scope.save();
		};

		$scope.save = function () {
			//Todo does the user have access to save this view ? if it's public limit to systemuser
			setColumnPositionOnModel();

			// For now we add the correct DTO model for columns directly to the reference, since the whole controller (and outside caller) expects that the direct reference is modified.
			// Should later change to this does not modify the direct reference from params.
			$scope.model.WebColumnsToSynchronize = $scope.model.Columns;

			repository.updateSingle(repository.apiData.webListView.url, $scope.model).then(function (result) {
				repository.growl('Listeoppsettet har blitt lagret', 'success');
				delete $scope.model.WebColumnsToSynchronize;
				$modalInstance.close('saved');
			});
		};
	}
})();
