(function () {
	var app = angular.module('Plania');
	app.directive('changeSet', function () {
		return {
			restrict: 'E',
			scope: {
				reload: '=reload',
				entityType: '=entityType',
				guidEntity: '=guidEntity',
				disableActions: '=disableActions'
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$modal', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/changeSet/views/changeSetDirective.html'
		};
	});

	function controller($scope, repository, translationService, $modal) {
		if (!$scope.guidEntity || !$scope.entityType) return;

		var filterTypes = [
			{ value: 'all', name: translationService.translate('web-changeSet-filterType-all', 'Alle'), longName: translationService.translate('web-changeSet-filterType-showAll', 'Vis alle') },
			{ value: 'Pending', enumValue: 0, name: translationService.translate('web-changeSet-filterType-pending', 'Klare'), longName: translationService.translate('web-changeSet-filterType-showPending', 'Vis endringer klare til behandling') },
			{ value: 'Approved', enumValue: 1, name: translationService.translate('web-changeSet-filterType-approved', 'Godkjente'), longName: translationService.translate('web-changeSet-filterType-showApproved', 'Vis godkjente endringer') },
			{ value: 'Rejected', enumValue: 2, name: translationService.translate('web-changeSet-filterType-rejected', 'Avslåtte'), longName: translationService.translate('web-changeSet-filterType-showRejected', 'Vis avslåtte endringer') }
		];

		$scope.currentSelectedFilterType = filterTypes[1];

		$scope.changeSets = [];
		getChangeSets();

		function getChangeSets() {
			var columns = [
				'EntityType', 'GuidEntity', 'CreationDate', 'Status', 'UserCreatedBy.RealName'
			];

			var changeSetFilter = {
				FilterModel: {
					Condition: "and",
					Rules: [
						{
							Property: 'EntityType',
							Operator: '=',
							Value: $scope.entityType
						},
						{
							Property: 'GuidEntity',
							Operator: '=',
							Value: $scope.guidEntity
						}
					]
				}
			};

			if ($scope.currentSelectedFilterType.value !== 'all') {
				changeSetFilter.FilterModel.Rules.push({
					Property: 'Status',
					Operator: '=',
					Value: $scope.currentSelectedFilterType.enumValue
				});
			}
			repository.GetPaginated(repository.apiData.changeSet.url, 0, 10000, { 'CreationDate': 'desc' }, changeSetFilter, null, JSON.stringify(columns)).then(function (result) {
				$scope.totalCount = result.TotalCount;
				$scope.changeSets = result.List;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		}

		$scope.setFilter = function (filterTypeValue) {
			var selectedFilterType = _.find(filterTypes, { value: filterTypeValue });
			if (selectedFilterType && filterTypeValue !== $scope.currentSelectedFilterType.value) {
				$scope.currentSelectedFilterType = selectedFilterType;
				getChangeSets();
			}
		};

		$scope.getStatusColor = function (status) {
			if (status === 'Pending') return 'default';
			switch (status) {
				case 'Approved':
					return 'success';
				case 'Rejected':
					return 'danger';
			}
		};

		$scope.getStatusIcon = function (status) {
			if (status === 'Pending') return 'zmdi-circle-o';
			switch (status) {
				case 'Approved':
					return 'zmdi-check-circle';
				case 'Rejected':
					return 'zmdi-minus-circle';
			}
		};

		$scope.openChangeSet = function (set) {
			$modal.open({
				templateUrl: "app/changeSet/views/changeSetModal.html",
				controller: "ChangeSetModalController",
				resolve: {
					params: function () {
						return {
							guid: set.Guid,
							entityType: set.EntityType,
						};
					}
				}
			}).result.then(function () {
				getChangeSets();
			});
		};
	}
})();
