(function () {

    var app = angular.module('Plania');
	app.directive('entityComment', function () {
		return {
			restrict: 'E',
			scope: {
				entity: '=entity',
				guid: '=guid',
                entityComments: '=entityComments',
                entitySearch: '='
			},
			controller: ['$scope', 'Repository', '$modal', 'SignalR', controller],
			templateUrl: 'app/common/directives/views/entityComment.html'
		};
	});

	function controller($scope, repository, $modal, signalR) {

		var fetchData = function () {
			var columns = ['UserCreatedBy.RealName', 'UserCreatedBy.UserName', 'UserCreatedBy.GuidImage', 'Comment', 'ReadDate', 'CreationDate'];
			repository.GetPaginated(repository.apiData.entityComment.url, -1, -1, { CreationDate: 'desc' }, { PropertyFilter: [{ Property: 'Guid' + $scope.entity, Operator: '=', Value: $scope.guid }] }, '', JSON.stringify(columns)).then(function (result) {
				$scope.entityComments = result.List;
				$scope.entityCommentCount = result.TotalCount;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};
		fetchData();

        $scope.newComment = { text: '' };
        $scope.searchString = "";

		$scope.addComment = function () {
			if (!$scope.newComment.text) {
				swal('Mangler kommentartekst', 'Skriv inn en kommentar i tekstfeltet for å få lagt til en kommentar', 'info');
				return;
			}

			var newComment = { Comment: $scope.newComment.text };
			newComment['Guid' + $scope.entity] = $scope.guid;
			repository.createSingle(repository.apiData.entityComment.url, newComment).then(function (result) {
				$scope.newComment = { text: '' };
				fetchData();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.readComment = function (comment) {
			if (!comment.ReadDate && comment.GuidUserCreatedBy !== repository.authService.getUserData().guidUser) {
				comment.ReadDate = new Date().toISOString();

				repository.updateSingle(repository.apiData.entityComment.url, comment).then(function (result) {

				}, function (error) {
					repository.growl('Kunne ikke oppdatere kommentaren', 'danger');
					comment.ReadDate = null;
				});
			}

			var date = new Date(comment.CreationDate);
			var header = "Kommentar";

			$modal.open({
				templateUrl: 'app/common/views/messageModal.html',
				controller: 'MessageModalController',
				resolve: {
					content: function () {
						return {
							header: header,
							message: comment.Comment,
							timestamp: date
						};
					}
				}
			});
		};

		signalR.on('EntityCommentChangedForEntityGuid' + $scope.guid, function () {
			fetchData();
		});


		$scope.$on('$destroy', function () {
			signalR.off('EntityCommentChangedForEntityGuid' + $scope.guid);
        });

        $scope.$watch('entitySearch', function (newVal, oldVal) {
            if (newVal === oldVal) return;
            $scope.searchString = newVal;
        });

		$scope.filterComment = function (comment) {
			if ($scope.searchString === undefined || $scope.searchString === null || $scope.searchString.trim() === "") return true;
			var searchString = $scope.searchString.toLowerCase();

			var match = false;
			if (!match && comment.Comment !== undefined && comment.Comment !== null)
				match = comment.Comment.toLowerCase().includes(searchString);

			if (!match && comment.UserCreatedBy && comment.UserCreatedBy.RealName !== undefined && comment.UserCreatedBy.RealName !== null)
				match = comment.UserCreatedBy.RealName.toLowerCase().includes(searchString);

			if (!match && comment.UserCreatedBy && comment.UserCreatedBy.Username !== undefined && comment.UserCreatedBy.Username !== null)
				match = comment.UserCreatedBy.Username.toLowerCase().includes(searchString);
				
            return match;
        };
	}
})();
