(function () {
	angular.module('Plania').controller('SynchronizeBuildingsForTemplateModalController', ['$scope', 'params', 'Repository', 'ngTableParams', '$modalInstance', 'TranslationService', 'SignalR', '$timeout', '$rootScope', '$window', controller]);
	function controller($scope, params, repository, ngTableParams, $modalInstance, translationService, signalR, $timeout, $rootScope, $window) {
		// params: {guidTemplate: string, guidBuildings: string[], count: number}
		var guidTemplate = params.guidTemplate;
		var guidBuildings = _.cloneDeep(params.guidBuildings);
		var count = params.count === undefined ? guidBuildings.length : params.count;

		$scope.currentTab = 'info';
		$scope.isRunningAction = false;
		$scope.progress = {};

		var startSignalRListener = function () {
			$scope.progress = {};

			var progressMethodName = "synchronizeBuildingsOnTemplateFor_" + guidTemplate;
			signalR.on(progressMethodName, function (progress) {
				if (progress.Finished) {
					$timeout(function () {
						$scope.progress = progress;
					}, 1000);
				} else {
					$scope.progress = progress;
				}
			});

			$scope.$on('$destroy', function () {
				signalR.off(progressMethodName);
			});
		};

		$scope.responses = [];
		$scope.resultTable = new ngTableParams({
			page: 1,
			count: 10
		}, {
			total: $scope.responses.length,
			counts: [5, 10, 20],
			getData: function ($defer, params) {
				$scope.resultTable.settings().total = $scope.responses.length;
				var paginated = $scope.responses.slice((params.page() - 1) * params.count(), params.page() * params.count());
				$defer.resolve(paginated);
			}
		});

		$scope.startSync = function () {
			if ($scope.isRunningAction) return;
			$scope.isRunningAction = true;
			startSignalRListener();
			$scope.currentTab = "result";
			repository.runAction(repository.apiData.building.url + "template/" + guidTemplate + "/synchronize/", guidBuildings ? { GuidBuildings: guidBuildings } : null).then(function (response) {
				$scope.responses = response.BuildingResponses;
				if (response.IsSuccess === false)
					repository.showError(response.ErrorMessage);

				$scope.resultTable.reload();
				$scope.isRunningAction = false;
			}, function (error) {
				repository.showError(error);
				$scope.isRunningAction = false;
			});
		};

		$scope.takeTopN = function (list, amount) {
			if (amount === undefined) amount = 5;
			return list.slice(0, amount);
		};

		$scope.contextMenuOptions = function (item) {
			var options = [];
			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-synchronizeBuildingsForTemplateModal-viewBuilding', 'Åpne bygg i ny tab/fane i nettleseren') + '</a >',
				click: function () {
					$window.open($rootScope.navigation.href('building.edit', { guid: item.Guid }), '_blank');
				}
			});
			return options;
		};

		$scope.cancel = function () {
			if ($scope.isRunningAction) {
				swal({
					title: translationService.translate('web-synchronizeBuildingsForTemplateModal-swal-title', 'Vil du lukke dette vinduet?'),
					text: translationService.translate('web-synchronizeBuildingsForTemplateModal-swal-text', 'Plania vil fortsette å synkronisere bygg, men du vil ikke kunne se potensielle feilmeldinger hvis du lukker dette vinduet.'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					$modalInstance.dismiss('canceled');
				});
			} else {
				$modalInstance.dismiss('canceled');
			}
		};
	}
})();
