(function () {

	angular.module('Plania').controller('ShortcutController', ['$scope', 'MenuService', '$modal', controller]);

	function controller($scope, service, $modal) {
        $scope.shortcuts = [];
        service.getShortcuts().then(function (webMenus) {
            $scope.shortcuts = webMenus;
        });

        $scope.createDocument = function () {
            $modal.open({
                templateUrl: 'app/document/views/createDocumentModal.html',
                controller: 'CreateDocumentController',
                size:'lg'
            }).result.then(function () {
            }, function () {
            });
		};

        $scope.activeShortcut = null;

        $scope.ChangeView = function (event, shortcut) {
            if (!shortcut.state) {
                shortcut.open = !shortcut.open;
                return;
            }
			if (service.navigateFromLink(event, shortcut)) {
				$scope.activeShortcut = shortcut;
			}
        };

        $scope.isDisabled = function (shortcut) {
            return service.isDisabled(shortcut);
        };

        $scope.getHref = function (shortcut) {
            return service.getHref(shortcut);
        };
    }
})();
