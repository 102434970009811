(function () {
	angular.module('Plania').controller('EntityEmailListModalController', ['$scope', 'Repository', 'ngTableParams', 'TranslationService', '$modalInstance', 'modalParams', controller]);

	function controller($scope, repository, ngTableParams, translationService, $modalInstance, modalParams) {
		$scope.prefill = {
			Type: 'Person'
		};
		$scope.isEdit = !!modalParams.guid;
		$scope.guidSupplier = modalParams.guidSupplier;

		$scope.model = {
			GuidPurchaseOrder: modalParams.guidPurchaseOrder
		};

		if ($scope.isEdit) {
			repository.getSingle(repository.apiData.entityEmailList.url, modalParams.guid).then(function(result){
				$scope.model = result.Data;
			}, function (error){
				repository.showError(error);
			});
		}

		$scope.onContactPersonSelected = function (entity){
			if (!entity) {
				$scope.model.GuidContactPerson = null;
				return;
			}
			$scope.model.GuidContactPerson = entity.Guid;
			$scope.model.RecipientName = entity.Caption;
			$scope.model.RecipientEmailAddress = entity.Email;
			$scope.model.RecipientPhoneNumber = entity.Cellphone;
		};

		$scope.onPersonSelected = function (entity){
			if (!entity) {
				$scope.model.GuidPerson = null;
				return;
			}
			$scope.model.GuidPerson = entity.Guid;
			$scope.model.RecipientName = entity.Caption;
			$scope.model.RecipientEmailAddress = entity.Email;
			$scope.model.RecipientPhoneNumber = entity.Cellphone;
		};

		$scope.saveEmailEntityList = function (){
			if ($scope.isEdit){
				repository.updateSingle(repository.apiData.entityEmailList.url, $scope.model).then(function(result){
					repository.growl(translationService.translate('web-emailEntityListModal-updateSuccess', 'Epostmottaker oppdatert'), 'success');
					$modalInstance.close(result);
				}, function (error){
					repository.showError(error);
				});
			} else {
				repository.createSingle(repository.apiData.entityEmailList.url, $scope.model).then(function(result){
					repository.growl(translationService.translate('web-emailEntityListModal-createSuccess', 'Epostmottaker lagt til'), 'success');
					$modalInstance.close(result);
				}, function (error){
					repository.showError(error);
				});
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "ContactPerson":
					filter.PropertyFilter = [{ Property: 'GuidSupplier', Operator: "=", Value: $scope.guidSupplier }];
					return filter;
			}
			return filter;
		};
	}
})();
