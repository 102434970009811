//This directive displays a table of videos and a add button for adding to specified videoxentity with correct column data.
(function () {

	var app = angular.module('Plania');

	app.directive('videos', function () {
		return {
			restrict: 'E',
			scope: {
				reload: '=reload',
				defaultCategory: '=defaultCategory',
				disableActions: '=disableActions',
				guid: '=guid',
				prefix: '=prefix',
				printView: '=print'
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'config', 'Repository', '$filter', '$window', 'TranslationService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/videoDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, config, repository, $filter, $window, translationService) {
		$scope.videosIsLoading = true;

		$scope.$watch('reload', function (newValue, oldValue) {
			if (newValue === oldValue && $scope.videoTable.filter().PropertyFilter) return;

			if ($scope.reload) {
				$scope.videoTable.filter().PropertyFilter = [
					{
						Property: 'Guid' + $scope.prefix,
						Operator: '=',
						Value: $scope.guid
					}
				];
				$scope.reload = false;
			}
		});

		$scope.columns = [
           { Position: 1, Title: translationService.translate('web-video-id'), Property: 'Video.Id', PropertyType: 'string' },
           { Position: 2, Title: translationService.translate('web-video-description'), Property: 'Video.Description', PropertyType: 'string' },
           //{ Position: 3, Title: translationService.translate('web-video-filename'), Property: 'Document.FilePath', PropertyType: 'string' },
		];

		var countArray = [];
		var countNumber = 1000;
		if ($scope.printView === undefined) {
			countArray = [10, 20, 50];
			countNumber = 10;
		}

		$scope.videoTable = new ngTableParams({
			page: 1,
			count: countNumber,
			sorting: { 'Video.Id': 'asc' },
		},
		{
			total: 0,
			counts: countArray,
			filterDelay: 50,
			getData: function ($defer, params) {
				if (!params.filter().PropertyFilter)
					return;
				$scope.videosIsLoading = true;

				var columns = ['Video.GuidVideoBinary'];
				$scope.columns.forEach(function (col) {
					columns.push(col.Property);
				});

				repository.GetPaginated(repository.apiData.videoXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
			        .then(function (result) {
			        	$scope.videoTable.settings().total = result.TotalCount;
			        	$scope.videoTable.settings().filterDelay = 500;
			        	$scope.videos = result.TotalCount;

			        	$defer.resolve(result.List);
			        	$scope.videosIsLoading = false;

			        },
			        function (error) {
			        	repository.growl(error, 'danger');
			        	$scope.videosIsLoading = false;

			        });
			}
		});

		$scope.getPropertyValue = function (row, column) {
			var properties = column.Property.split('.');
			var temp = row;

			properties.forEach(function (prop) {
				if (temp) {
					temp = temp[prop];
				}
			});

			if (column.PropertyType === 'date' && temp) {
				var date = new Date(temp);
				return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
			}

			return temp;
		};

		var resolveParams = function () {
			var params = { defaultCategory: $scope.defaultCategory };
			params['guid' + $scope.prefix] = $scope.guid;
			return params;
		};

		$scope.addNewVideo = function () {
			$modal.open({
				templateUrl: 'app/video/views/createVideoModal.html',
				controller: 'CreateVideoXEntityController',
				resolve: {
					params: resolveParams
				}
			}).result.then(function () {
				$scope.videoTable.reload();
			});
		};

		$scope.addExistingVideo = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til eksisterende video',
							columns: [
								{ Title: 'Id', Property: 'Id' },
								{ Title: 'Beskrivelse', Property: 'Description' },
							],
							url: repository.apiData.video.url,
							sorting: { 'Id': 'asc' },
							filter: { }
						};
					}
				}
			}).result.then(function (selectedVideos) {
				var videoXEntities = [];
				selectedVideos.forEach(function (guid) {
					var xentity = { GuidVideo: guid };
					xentity['Guid' + $scope.prefix] = $scope.guid;
					videoXEntities.push(xentity);
				});

				repository.createMultiple(repository.apiData.videoXEntity.url, videoXEntities).then(function (result) {
					$scope.videoTable.reload();
				});
			},
			function (msg) {
				//cancel
			});
		};

		$scope.removeVideo = function (video) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-video-message', "Videoen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-video-button-confirm', 'Ja, fjern videoen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.videoXEntity.url, video.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-video-success', 'Videoen ble fjernet!'), result, "success");
						repository.growl('Videoen har blitt fjernet', 'success');
						$scope.videoTable.reload();
					});
			});
		};

		$scope.editVideo = function (video) {
			$modal.open({
				templateUrl: 'app/video/views/editVideoMetadataModal.html',
				controller: 'EditVideoXEntityController',
				resolve: {
					params: function () { return { guid: video.GuidVideo, isModal: true }; }
				}
			}).result.then(function () {
				$scope.videoTable.reload();
			},
			function () {
				//cancel
			});
		};

		$scope.playVideo = function (video) {
			var modalInstance = $modal.open({
				size: 'lg',
				controller: 'VideoPlayerModalController',
				templateUrl: 'app/video/views/videoPlayerModal.html',
				resolve: {
					params: function () {
						return {
							video: video.Video,
							guidVideoBinary: video.Video.GuidVideoBinary
						};
					}
				}
			});
		};
	}
})();
