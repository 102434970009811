(function () {
	angular.module('Plania').controller('CreateBimFileModalController', ['$scope', '$modalInstance', 'Repository', 'modalParams', 'ListService', 'TranslationService', 'ngTableParams', controller]);
	function controller($scope, $modalInstance, repository, modalParams, listService, translationService, ngTableParams) {
		$scope.isUpdate = !!modalParams.model;
		$scope.bimFile = angular.copy(modalParams.model || {});
		$scope.bimFile.RevisionComment = "";

		$scope.step = {
			index: 0
		};

        $scope.files = [];

        $scope.removeFile = function (index) {
            $scope.files.splice(index, 1);
        };

		$scope.onFileSelect = function ($files) {
			if ($scope.isUpdate && $files.length) {
				$scope.files = [];
			}

			// Set filename to be Id, if not already specified
			if (!$scope.isUpdate && !$scope.bimFile.Description && $files && $files.length && $files[0].name) {
				var newId = $files[0].name;
				var splitted = newId.split(".");
				newId = splitted.slice(0, -1).join("");
				$scope.bimFile.Description = newId;
			}

			$scope.files = $scope.files.concat($files);
        };

        $scope.dragOverClass = function ($event) {
            var items = $event.dataTransfer.items;
            var hasFile = false;
            if (items) {
                for (var i = 0; i < items.length; i++) {
                    if (items[i].kind === 'file') {
                        hasFile = true;
                        break;
                    }
                }
            } else {
                hasFile = true;
            }
            return hasFile ? "drop-box-dragover" : "";
        };

        var addFileId = function () {
            $scope.files.forEach(function (r) {
                //Generate unique id for files used for upload progress update
                r.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 16);
            });
        };

		var savedFiles = [];

		$scope.upload = function () {
			if ($scope.isUpdate && !$scope.bimFile.GuidBimProject) {
				swal(translationService.translate('web-createBimFileModal-missingProject-title', 'Mangler bim prosjekt'), translationService.translate('web-createBimFileModal-missingProject-text', 'Opplastning av ny revision krever at bim filen er knyttet til en eiendom via ett bim prosjekt.'), "error");
				return;
			}

			if (!$scope.isUpdate && !$scope.bimFile.GuidEstate) {
				swal(translationService.translate('web-createBimFileModal-missingEstate-title', 'Mangler eiendom'), translationService.translate('web-createBimFileModal-missingEstate-text', 'Opprettelse av nye bim filer krever ett valgt eiendom. Dette er for å knytte opp bim filen til ett bim prosjekt.'), "error");
				return;
			}
			
            $scope.isUploading = true;
			$scope.bimFile.prefix = repository.apiData.bimFile.prefix;
			if (!$scope.bimFile.GuidDataOwner) {
				$scope.bimFile.GuidDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			}

            addFileId();

			var onSuccess = function (result) {
				$scope.isUploading = false;
				$scope.alreadyUploaded = true;

				savedFiles = _.map(result.savedFiles, 'guid');
				if ($scope.isUpdate)
					$modalInstance.close(savedFiles);
				else {
					$scope.step.index = 1;
					$scope.buildingTable.reload();
				}
			};

			var onFail = function (error) {
				repository.growl(error[0].error, 'danger');

				$scope.isUploading = false;
				error.forEach(function (error) {
					var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
					if (identifiedFile) {
						identifiedFile.progressType = 'danger';
					}
				});
			};

			var onUpdate = function (file) {
				var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
				if (identifiedFile) {
					identifiedFile.total = file.total;
					identifiedFile.count = file.count;
					identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleWithBinary(repository.apiData.bimFile.urlChunk, $scope.bimFile, $scope.files[0]).then(onSuccess, onFail, onUpdate);
			} else {
				repository.createSingleWithBinary(repository.apiData.bimFile.urlChunk, $scope.bimFile, $scope.files).then(onSuccess, onFail, onUpdate);
			}
		};

		$scope.selectedItems = [];
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.addSelectedItem = function (object) {
			if (!$scope.isItemSelected(object)) {
				$scope.selectedItems.push(object);
			} else {
				for (var i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i].Guid === object.Guid) {
						$scope.selectedItems.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isItemSelected = function (object) {
			return _.find($scope.selectedItems, function (o) { return o.Guid === object.Guid; });
		};

		$scope.buildingColumns = [
			{ Position: 1, Title: translationService.translate('web-createBimFileModal-buildingTable-id', 'Id'), Property: "Id", PropertyType: "string" },
			{ Position: 2, Title: translationService.translate('web-createBimFileModal-buildingTable-description', 'Beskrivelse'), Property: "Description", PropertyType: "string" },
			{ Position: 3, Title: translationService.translate('web-createBimFileModal-buildingTable-buildingCategory', 'Kategori'), Property: "BuildingCategory.Description", PropertyType: "string" },
		];

		$scope.buildingTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: "asc" },
			filter: {
				
			}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					if (!$scope.bimFile.GuidEstate) {
						return;
					}
						
					var columns = _.map($scope.buildingColumns, 'Property');

					var filter = angular.copy(params.filter());
					filter.FilterModel = {
						Condition: "and",
						Rules: [
							{
								Property: "IsTemplate",
								Operator: "=",
								Value: false,
							},
							{
								Property: "GuidEstate",
								Operator: "=",
								Value: $scope.bimFile.GuidEstate
							}
						]
					};

					repository.GetPaginated(repository.apiData.building.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(function (result) {
						$scope.buildingTable.settings().total = result.TotalCount;
						$scope.buildingTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.showError(error, 'danger');
					});
				}
			});

		var connectToSelectedBuildings = function () {
			if (!$scope.selectedItems.length) {
				$modalInstance.close(savedFiles);
				return;
			}

			var buildingXBimFiles = [];

			$scope.selectedItems.forEach(function (building) {
				savedFiles.forEach(function (guidBimFile) {
					buildingXBimFiles.push({
						GuidBuilding: building.Guid,
						GuidBimFile: guidBimFile
					});
				});
			});

			repository.createMultiple(repository.apiData.buildingXBimFile.url, buildingXBimFiles).then(function () {
				repository.growl(translationService.translate('web-createBimFileModal-connectToSelectedBuildings-success', 'Bim filer er knyttet til valgte bygg'), "success");
				$modalInstance.close(savedFiles);
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.ok = function () {
			if ($scope.isUpdate)
				$modalInstance.close();
			else {
				if ($scope.selectedItems.length) {
					connectToSelectedBuildings();
				} else {
					$modalInstance.close(savedFiles);
				}
			}
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };
    }
})();
