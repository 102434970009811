(function () {
	angular.module('Plania').controller('DashboardSettingsModalController', ['$scope', 'Repository', '$modalInstance', '$modal', 'TranslationService', 'params', controller]);

	function controller($scope, repository, modalInstance, $modal, translationService, params) {
	    $scope.model = params.model;
	    $scope.edit = params.edit;

	    $scope.save = function () {
	        modalInstance.close($scope.model);
	    };

	    $scope.delete = function () {
	        swal({
	            title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
	            text: translationService.translate('web-swal-dashboard-message', 'Dashboardet vil bli fjernet!'),
	            type: "warning",
	            showCancelButton: true,
	            confirmButtonColor: "#f44336",
	            confirmButtonText: translationService.translate('web-swal-dashboard-button-confirm', 'Ja, fjern dashboardet!'),
	            cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
	            closeOnConfirm: false
	        }, function () {
	            window.onkeydown = null;
	            window.onfocus = null;
	            repository.deleteSingle(repository.apiData.webDashboard.url, $scope.model.Guid).then(function (result) {
                        swal(translationService.translate('web-swal-dashboard-success', 'Dashboardet ble fjernet!'), result, "success");
                        $scope.goBack('dashboard.list', { menuGuid: $scope.navigation.params.menuGuid });
                        modalInstance.dismiss('deleted');
                    });
	        });
	    };

	    $scope.cancel = function (reason) {
	        modalInstance.dismiss('cancel');
	    };
	}
})();
