(function () {
	angular.module('Plania').controller('WorkOrderMapMessageController', ['$scope', '$modal', 'Repository', 'ngTableParams', '$stateParams', workOrderMapMessageController]);

	function workOrderMapMessageController($scope, $modal, repository, ngTableParams, $stateParams) {
		$scope.workOrders = [];
		$scope.modalInstance = {};
		$scope.isLoading = true;

		var propertyFilter = repository.mapService.getPropertyFilter('workOrder', $stateParams);

		$scope.workOrderPopover = {
			content: 'test',
			templateUrl: 'workOrderPopover.html',
		};

		$scope.init = function (entityType, guid, caption, activityStatus, isCluster, buildingGuids, estateGuids, equipmentGuids) {

			$scope.entityGuid = guid;
			$scope.caption = caption;
			$scope.navigationState = entityType;

			if (isCluster) {
				if (activityStatus !== 'History')
					activityStatus = 'Active';
				if (buildingGuids) propertyFilter.push({ Property: 'GuidBuilding', Operator: 'in', value: buildingGuids });
				if (estateGuids) propertyFilter.push({ Property: 'GuidEstate', Operator: 'in', value: estateGuids, Operand: 'or' });
				if (equipmentGuids) propertyFilter.push({ Property: 'GuidEquipment', Operator: 'in', value: equipmentGuids, Operand: 'or' });
			}
			else {
				propertyFilter.push({
					Property: entityType + ".Guid",
					Operator: "=",
					Value: guid
				});
			}
			var columns = ["Id", "Description", "Building.Id", "Building.Description", "DueDate", "Estate.Id", "Estate.Description", "Equipment.Id", "Equipment.Description"];
			$scope.workOrderTableSmall = new ngTableParams({
				page: 1,
				count: 10,
				filter: {
					ActivityStatus: activityStatus,
					PropertyFilter: propertyFilter
				},
				sorting: { 'Id': 'asc' }
			},
				{
					total: 0,
					counts: [],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.workOrder.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
							function (result) {
								if (result.TotalCount === 0)
									$scope.listIsEmpty = true;
								else {
									$scope.listIsEmpty = false;
								}

								$scope.isLoading = false;
								params.total(result.TotalCount);
								$defer.resolve(result.List);
							},
							function (error) {
								$scope.isLoading = false;
								repository.growl(error, 'danger');
							});
					}
				});
		};

		$scope.openWOEdit = function (workOrderGuid) {
			$modal.open({
				templateUrl: 'app/workOrder/views/editWorkOrderModal.html',
				size: 'lg',
				animation: false,
				controller: 'WorkOrderController',
				resolve: {
					$stateParams: function () {
						return { guid: workOrderGuid, isModal: true };
					},
				}
			});
		};

		$scope.dateExpired = function (dueDate) {
			if (!dueDate) return false;
			return new Date(dueDate).getTime() < new Date().getTime();
		};
	}
})();
