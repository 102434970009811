(function () {
	angular.module('Plania').directive('scaleFontToContainer', [function () {
		return {
			restrict: 'A',
			scope: {
				scaleFontToContainer: '@'
			},
			link: function (scope, element, attrs) {
				if (!scope.scaleFontToContainer) return;

				switch (scope.scaleFontToContainer.length) {
					case 4:
						element.css('font-size', '16px');
						break;
					case 5:
						element.css('font-size', '14px');
						break;
					case 6:
						element.css('font-size', '12px');
						break;
					case 7:
						element.css('font-size', '10px');
						break;
					default:
						element.css('font-size', '20px');
						break;
				}
			}
		};
	}]);
})();
