(function () {
	angular.module('Plania').controller('IntegrationDataController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'authService', '$window', 'config', controller]);
	function controller($scope, repository, $stateParams, translationService, authService, $window, config) {
		$scope.model = {};
		/**
		 *	ExtractedData {
		 *		value: any; // Underlying value
		 *		label: string; // Label to be shown with caption
		 *		caption: string; // Caption to show in UI
		 *		type: "string" | "autocomplete"
		 *		entityType: prefix
		 *	}
		*/
		$scope.extractedData = [];
		$scope.showExtractedJsonData = true;

		var getJsonValue = function (property) {
			var value = $scope.model.JsonData;
			var splittedProperty = property.split(".");

			for (var i = 0; i < splittedProperty.length; i++) {
				var prop = splittedProperty[i];

				while (true) {
					if (!prop || value === undefined || value === null) break;

					var indexOfStartingBracket = prop.indexOf("[");
					var indexOfEndingBracket = prop.indexOf("]");

					// No more brackets in prop, work as normal
					if (indexOfStartingBracket === -1) {
						if (prop !== undefined && prop !== null)
							value = value[prop];
						break;
					} else if (indexOfStartingBracket !== 0) {
						// If starting bracket is not first, then we have another property to use first.
						var preProp = prop.substr(0, indexOfStartingBracket);
						value = value[preProp];
						prop = prop.substr(indexOfStartingBracket);
					} else {
						var indexOfArrayIndex = prop.substr(indexOfStartingBracket + 1, indexOfEndingBracket - indexOfStartingBracket - 1);
						value = value[indexOfArrayIndex];

						prop = prop.substring(indexOfEndingBracket + 1);
					}
				}
			}

			return value;
		};

		var extractJsonData = function () {
			var extractedData = [];
			switch ($scope.model.JobId) {
				case "BoligbyggKomboExport":
					var guidExportContract = getJsonValue("contractGuid");
					var guidExportCustomer = getJsonValue("customerGuid");
					var guidExportArea = getJsonValue("areaGuid");

					if (guidExportContract) {
						repository.getSingle(repository.apiData.contract.url, guidExportContract).then(function (response) {
							extractedData.push({
								value: response.Data.Guid,
								label: translationService.translate('web-integrationData-boligbyggKomboImport-housingContract', 'Boligkontrakt'),
								type: "autocomplete",
								entityType: "Contract",
								caption: response.Data.Caption || response.Data.Guid,
								context: planiaUtils.contextIds.housingContract
							});
						});
					}

					if (guidExportCustomer) {
						repository.getSingle(repository.apiData.customer.url, guidExportCustomer).then(function (response) {
							extractedData.push({
								value: response.Data.Guid,
								label: translationService.translate('web-integrationData-boligbyggKomboImport-housingCustomer', 'Leietaker'),
								type: "autocomplete",
								entityType: "Customer",
								caption: response.Data.Caption || response.Data.Guid,
								context: planiaUtils.contextIds.housingCustomer
							});
						});
					}

					if (guidExportArea) {
						repository.getSingle(repository.apiData.area.url, guidExportArea).then(function (response) {
							extractedData.push({
								value: response.Data.Guid,
								label: translationService.translate('web-integrationData-boligbyggKomboImport-housing', 'Bolig'),
								type: "autocomplete",
								entityType: "Area",
								caption: response.Data.Caption || response.Data.Guid,
								context: planiaUtils.contextIds.housing
							});
						});
					}
					break;
				case "BoligbyggKomboImport":
					var guidImportContract = getJsonValue("contractGuid");
					var guidImportCustomer = getJsonValue("customerGuid");

					if (guidImportContract) {
						repository.getSingle(repository.apiData.contract.url, guidImportContract).then(function (response) {
							extractedData.push({
								value: response.Data.Guid,
								label: translationService.translate('web-integrationData-boligbyggKomboImport-housingContract', 'Boligkontrakt'),
								type: "autocomplete",
								entityType: "Contract",
								caption: response.Data.Caption || response.Data.Guid,
								context: planiaUtils.contextIds.housingContract
							});
						});
					}

					if (guidImportCustomer) {
						repository.getSingle(repository.apiData.customer.url, guidImportCustomer).then(function (response) {
							extractedData.push({
								value: response.Data.Guid,
								label: translationService.translate('web-integrationData-boligbyggKomboImport-housingCustomer', 'Leietaker'),
								type: "autocomplete",
								entityType: "Customer",
								caption: response.Data.Caption || response.Data.Guid,
								context: planiaUtils.contextIds.housingCustomer
							});
						});
					}
					
					break;
				case "Bitmesh":
					var entityKey = getJsonValue("EntityKey");
					if (entityKey) {
						repository.GetPaginated(repository.apiData.equipment.url, 0, 1, {}, { PropertyFilter: [{ Property: "AlternateId", Operator: "=", Value: entityKey }] }, null, JSON.stringify(["Id", "Description"])).then(function (response) {
							if (response && response.List && response.List[0]) {
								var equipment = response.List[0];
								extractedData.push({
									value: equipment.Guid,
									label: "Anlegg",
									type: "autocomplete",
									entityType: "Equipment",
									caption: equipment.Caption
								});
							}
						});
					}
					break;
				default:
					break;
			}
			$scope.extractedData = extractedData;
		};

		if ($stateParams.guid) {
			repository.getSingle(repository.apiData.integrationData.url, $stateParams.guid).then(function (response) {
				$scope.model = response.Data;
				extractJsonData();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		}

		$scope.downloadFile = function() {
			if (!$scope.model || !$scope.model.Guid) {
				repository.growl(translationService.translate('web-integrationdata-download-missingGuid',
						'Kunne ikke laste ned integrasjonsdata'),
					'danger');
				return;
			}
			var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			$window.open(config.baseUrlApi +
				repository.apiData.integrationData.url +
				'downloadFile/' +
				$scope.model.Guid +
				'?dataOwner=' +
				dataOwner +
				'&access_token=' +
				authService.getPlaniaToken().access,
				'_self');
		};

		$scope.$watch("extractedData", function (newVal, oldVal) {
			$scope.showExtractedJsonData = newVal && !!newVal.length;
		}, true);
	}
})();
