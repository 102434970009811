(function () {
	angular.module('Plania').factory('ColorService', [colorService]);

	function colorService() {
		var service = {};

		// Helper to get variable keys. Check variables.less for known keys.
		var getColorFromVariables = function (key) {
			var style = getComputedStyle(document.body);
			return style.getPropertyValue(key);
		};

		var getPrimaryText = function () {
			return getColorFromVariables("--pl-text-primary");
		};

		var getWhiteText = function () {
			return getColorFromVariables("--pl-text-white");
		};

		//Found logic from - https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
		service.getContrastText = function (bgColor) {
			var lightColor = getWhiteText();
			var darkColor = getPrimaryText();
			try {
				var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
				var r = parseInt(color.substring(0, 2), 16); // hexToR
				var g = parseInt(color.substring(2, 4), 16); // hexToG
				var b = parseInt(color.substring(4, 6), 16); // hexToB
				var uicolors = [r / 255, g / 255, b / 255];
				var c = uicolors.map(function(col) {
					if (col <= 0.03928) {
						return col / 12.92;
					}
					return Math.pow((col + 0.055) / 1.055, 2.4);
				});
				var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
				return (L > 0.179) ? darkColor : lightColor;
			}
			catch (error) {
				return darkColor;
			}
		};

		// 8.18 changed a lot of themes in the application. Some still require convertion during runtime. This is to help with that.
		service.convertToRainbow = function (color) {
			if (!color || typeof color !== "string") return "";

			// If working with hex, return hex value.
			if (color.startsWith("#")) {
				switch (color.toLowerCase()) {
					case "#00bcd4".toLowerCase(): //cyan
					case "#2196F3".toLowerCase(): //lightblue
						return getColorFromVariables("--pl-palette-rainbow-blue");
					case "#f44336".toLowerCase():
						return getColorFromVariables("--pl-palette-rainbow-red");
					case "#000000".toLowerCase(): //black
						return getColorFromVariables("--pl-palette-rainbow-black");
					case "#9E9E9E".toLowerCase():
					case "#607D8B".toLowerCase():
						return getColorFromVariables("--pl-palette-rainbow-gray");
					case "#673AB7".toLowerCase():
						return getColorFromVariables("--pl-palette-rainbow-purple");
					case "#ff9800".toLowerCase():
						return getColorFromVariables("--pl-palette-rainbow-orange");
					case "#4caf50".toLowerCase():
						return getColorFromVariables("--pl-palette-rainbow-green");
					case "#009688".toLowerCase():
						return getColorFromVariables("--pl-palette-rainbow-cyan");
					default:
						return color;
				}
			} else {
				// Else return names.
				switch (color.toLowerCase()) {
					case 'bluegray':
						return "gray";
					case 'lightblue':
						return "blue";
					case 'teal':
						return "cyan";
					case 'lightorange':
						return "orange";
					case 'deeppurple':
						return "purple";
					default:
						return color;
				}
			}
		};

		// Return a list of colors. Used primarily for color picker.
		service.getRainbowPalette = function () {
			var names = ["blue", "purple", "cyan", "green", "orange", "yellow", "gray", "red", "black"];
			
			var palette = [];

			names.forEach(function (name) {
				palette.push({
					name: name,
					hexColor: getColorFromVariables("--pl-palette-rainbow-" + name).toLowerCase()
				});
			});

			return palette;
		};
		
		return service;
	}
})();
