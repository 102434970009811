(function () {
	angular.module('Plania').controller('SelectAttachmentModalController', ['$scope', '$modalInstance', 'params', 'Repository', '$modal', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, repository, $modal, translationService) {
		$scope.selectedAttachments = [];
		$scope.entityType = params.entityType;
		var guidEntity = params.guid;

		$scope.canAddDocument = true;
		$scope.canAddImage = $scope.entityType === 'WorkOrder' || $scope.entityType === 'Request';

		$scope.availableAttachments = _.map(params.availableAttachments, function (attachment) {
			var copy = angular.copy(attachment);
			copy.isAlreadySelected = _.contains(params.selectedAttachmentGuids, attachment.Guid);
            copy.translatedName = translationService.translate("web-" + copy.ParentEntityType, copy.ParentEntityType);
			return copy;
		});

		$scope.selectAttachment = function (attachment) {
			if (attachment.isAlreadySelected) return;

			if (!_.find($scope.selectedAttachments, { Guid: attachment.Guid }))
				$scope.selectedAttachments.push(attachment);
			else {
				for (var i = 0; i < $scope.selectedAttachments.length; i++) {
					if ($scope.selectedAttachments[i].Guid === attachment.Guid) {
						$scope.selectedAttachments.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isSelected = function (attachment) {
			if (attachment.isAlreadySelected) return true;
			return _.find($scope.selectedAttachments, { Guid: attachment.Guid });
		};

		// Events to notify that new documents and images have been created. on event is only to notify, while reload event expects the new entities in a list.
		var onNewDocument = params.onNewDocument;
		var onNewImage = params.onNewImage;

		var resolveParams = function () {
			var params = {};
			params["guid" + $scope.entityType] = guidEntity;
			return params;
		};

		//var onNewAttachment = params.onNewAttachment;
		var onNewAttachment = function (guids) {
			var onNewAttachment = params.onNewAttachment;
			if (typeof onNewAttachment !== "function") return;

			onNewAttachment().then(function (attachments) {
				attachments.forEach(function (attachment) {
					if (!_.find($scope.availableAttachments, { Guid: attachment.Guid })) {
						var copy = angular.copy(attachment);
                        copy.translatedName = translationService.translate("web-" + copy.ParentEntityType, copy.ParentEntityType);
						$scope.availableAttachments.push(copy);
						// Add the newly created attachment - Check if it is in list of guids, in case an unknown attachment arrives.
						if (guids.includes(copy.Guid))
							$scope.selectAttachment(copy);
					}
				});
			});
		};

		$scope.addNewDocument = function () {
			$modal.open({
				templateUrl: 'app/document/views/createDocumentModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: resolveParams
				}
			}).result.then(function (response) {
				// Guids of Documents.
				var guids = [];
				if (response && response.savedFiles && Array.isArray(response.savedFiles)) {
					response.savedFiles.forEach(function (obj) {
						if (obj.guid)
							guids.push(obj.guid);
					});
				}
				
				onNewAttachment(guids);

				if (onNewDocument && typeof onNewDocument === "function")
					onNewDocument(guids);
			});
		};

		$scope.addNewImage = function () {
			$modal.open({
				templateUrl: 'app/document/views/createImageModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: resolveParams
				}
			}).result.then(function (images) {
				var guids = [];
				if (images && Array.isArray(images)) {
					images.forEach(function (image) {
						if (image.Guid)
							guids.push(image.Guid);
					});
				}
				
				onNewAttachment(guids);

				if (onNewImage && typeof onNewImage === "function")
					onNewImage(images);
			});
		};

		$scope.currentEntityTypeFirst = function(attachment) {
			return attachment.ParentEntityType !== $scope.entityType;
		};

		$scope.ok = function () {
			$modalInstance.close($scope.selectedAttachments);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
