(function () {
	angular.module('Plania').controller('ContactPersonController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, repository, translationService) {
		$scope.isUpdate = params.guid;
		$scope.header = $scope.isUpdate ? translationService.translate('contactPerson-title-edit', 'Rediger kontaktperson') : translationService.translate('contactPerson-title-create', 'Legg til kontaktperson');
		$scope.model = {};
		$scope.model.GuidSupplier = params.guidSupplier;
		$scope.model.GuidCustomer = params.guidCustomer;

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.contactPerson.url, params.guid).then(function (response) {
				var result = response.Data;
				$scope.model = result;
				$scope.model.PostalCode = result.PostalCode + "\n";
				$scope.reload = true;
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			});
		}

		$scope.saveContactPerson = function () {
			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.contactPerson.url, $scope.model).then(function (result) {
					repository.growl('Kontakt har blitt oppdatert', 'success');
					$modalInstance.close();
				});
			} else {
				repository.createSingle(repository.apiData.contactPerson.url, $scope.model).then(function (result) {
					repository.growl('Kontakt har blitt lagret', 'success');
					$modalInstance.close();
				});
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.PostalCode = '';
				$scope.model.PostalArea = '';
				$scope.model.GuidPost = '';
			} else {
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.postalInfo = postalInfo;
			}
		};
	}
})();
