(function () {
	angular.module('Plania').controller('SendWorkOrderToHistoryModalController', ['$scope', 'params', 'Repository', 'ngTableParams', '$modalInstance', 'TranslationService', 'ListService', '$filter', '$window', '$rootScope', controller]);
	function controller($scope, params, repository, ngTableParams, $modalInstance, translationService, listService, $filter, $window, $rootScope) {
		// params: {SelectedListSetup: object}
		$scope.initialListSetup = _.cloneDeep(params.SelectedListSetup);
		if ($scope.initialListSetup)
			$scope.initialListSetup.inMemory = true;
		$scope.allRowsSelected = false;
		$scope.selectedGuids = [];
		$scope.firstLoadList = false;
		$scope.isRunningAction = false;
		$scope.currentTab = 'workOrders';
		$scope.dataOwnerActivityPeriodDate = null;
		// Toggle to reload the main workOrder list table outside modal.
		$scope.reloadMainTable = false;
		$scope.errorMessage = null;

		$scope.workOrderResponses = [];
		$scope.totalFailedWorkOrders = 0;


		var guidDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;

		if (guidDataOwner) {
			repository.getSingle(repository.apiData.dataOwner.url, guidDataOwner).then(
				function (response) {
					$scope.dataOwnerActivityPeriodDate = $filter('date')(response.Data.ActivityPeriodDate, 'dd.MM.yyyy');
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		listService.getWebListViews($scope.initialListSetup, repository.apiData.workOrder.prefix, false, null, {}).then(function (result) {
			$scope.workOrderListOptions = result.listOptions;
			$scope.workOrderListSetup = result.selectedListSetup;

			if (!$scope.workOrderTable) {
				$scope.workOrderTable = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { Id: 'desc' },
					filter: { CanBeSentToHistory: true }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						paginationMaxBlocks: 8,
						getData: function ($defer, params) {
							var columns = [];
							$scope.workOrderListSetup.Columns.forEach(function (col) {
								columns.push(col.Property);
							});
							if (!columns.includes('DataOwner.ActivityPeriodDate'))
								columns.push('DataOwner.ActivityPeriodDate');

							repository.GetPaginated(repository.apiData.workOrder.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
								function (result) {
									$scope.workOrderTable.settings().total = result.TotalCount;
									$scope.workOrderTable.settings().filterDelay = 500;
									$scope.firstLoadList = true;
									params.total(result.TotalCount);
									$defer.resolve(result.List);
								},
								function (error) {
									repository.growl(error, 'danger');
								});
						}
					});
			}
		});

		$scope.$watch('workOrderListSetup', function (newValue, oldValue) {
			if (oldValue === newValue)
				return;

			if ($scope.workOrderTable) {
				$scope.workOrderTable.$params.sorting = $scope.workOrderListSetup.Sorting;
				if ($scope.workOrderListSetup.GroupBy) {
					$scope.workOrderTable.settings().groupBy = function (obj) {
						if (!$scope.workOrderListSetup.GroupBy) {
							return false;
						}
						var attrs = $scope.workOrderListSetup.GroupBy.split('.');
						attrs.forEach(function (attr) {
							if (!obj) {
								obj = "";
							} else {
								obj = obj[attr];
							}
						});
						return obj;
					};
				} else {
					$scope.workOrderTable.settings().groupBy = null;
				}
				$scope.workOrderTable.reload();
			}
		});

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.addSelectedGuid = function (guid) {
			if (!$scope.isItemSelected(guid)) {
				$scope.selectedGuids.push(guid);
			} else {
				for (var i = 0; i < $scope.selectedGuids.length; i++) {
					if ($scope.selectedGuids[i] === guid) {
						$scope.selectedGuids.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isItemSelected = function (guid) {
			return _.find($scope.selectedGuids, function (g) { return g === guid; });
		};

		$scope.sendToHistory = function () {
			if ($scope.selectedGuids.length === 0) return;
			$scope.isRunningAction = true;
			repository.runAction(repository.apiData.workOrder.endpoint.generateHistory, $scope.selectedGuids).then(function (result) {
				$scope.isRunningAction = false;
				$scope.workOrderResponses = result;

				$scope.workOrderResponses.forEach(function (wo) {
					if (wo.ErrorMessages.length === 0 && $scope.selectedGuids.includes(wo.Guid))
						$scope.addSelectedGuid(wo.Guid);
				});

				$scope.totalFailedWorkOrders = $scope.workOrderResponses.filter(function (wo) { return wo.ErrorMessages.length > 0; }).length;
				$scope.errorMessage = '';
				if ($scope.totalFailedWorkOrders > 0) {
					if ($scope.totalFailedWorkOrders !== $scope.workOrderResponses.length && !$scope.reloadMainTable) {
						$scope.reloadMainTable = true;
					}

					$scope.errorMessage = translationService.translate('web-sendWorkOrderToHistoryModal-generateHistory-failed', 'En eller flere arbeidsordre ble ikke sendt til historikk. Sjekk listen for feil.');
					repository.growl($scope.errorMessage, "warning");
					$scope.currentTab = 'errorMessages';
					if ($scope.workOrderTable)
						$scope.workOrderTable.reload();
					if ($scope.errorMessageTable)
						$scope.errorMessageTable.reload();
				} else {
					repository.growl(translationService.translate('web-sendWorkOrderToHistoryModal-generateHistory-success', 'Arbeidsordrene har blitt sendt til historikk.'), "success");
					$modalInstance.close();
				}

				// Result back means that some errors existed
				if (result) {
					$scope.errorDictionary = {};
					$scope.totalErrorMessages = 0;

					var keys = Object.keys(result);
					keys.forEach(function (key) {
						$scope.errorDictionary[key] = {
							errorMessages: result[key],
							collapse: false
						};
						$scope.totalErrorMessages += result[key].length;
					});
					$scope.currentTab = 'errorMessages';
				} else {
					$scope.totalErrorMessages = 0;
					$scope.errorDictionary = {};
					
					$modalInstance.dismiss();
				}
			}, function (error) {
				$scope.isRunningAction = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.errorMessageTable = new ngTableParams({
			page: 1,
			count: 10
		}, {
			total: $scope.workOrderResponses.length,
			counts: [5, 10, 20],
			getData: function ($defer, params) {
				$scope.errorMessageTable.settings().total = $scope.workOrderResponses.length;
				$defer.resolve($scope.workOrderResponses.filter(function (i) { return i.ErrorMessages.length !== 0; }).slice((params.page() - 1) * params.count(), params.page() * params.count()));
			}
		});

		$scope.contextMenuOptions = function (item) {
			var options = [];
			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-sendWorkOrderToHistoryModal-viewWorkorder', 'Åpne arbeidsordre i nytt vindu') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$window.open($rootScope.navigation.href('workOrder.edit', { guid: item.Guid }), '_blank');
				}
			});
			return options;
		};

		$scope.cancel = function () {
			if ($scope.isRunningAction) {
				swal({
					title: translationService.translate('web-sendWorkOrdersToHistoryModal-swal-title', 'Vil du lukke dette vinduet?'),
					text: translationService.translate('web-sendWOrkOrdersToHistoryModal-swal-text', 'Plania vil fortsette å sende valgte arbeidsordre til historikk, men du vil ikke kunne se potensielle feilmeldinger hvis du lukker dette vinduet.'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					if ($scope.reloadMainTable)
						$modalInstance.close();
					else
						$modalInstance.dismiss('canceled');
				});
			} else {
				if ($scope.reloadMainTable)
					$modalInstance.close();
				else
					$modalInstance.dismiss('canceled');
			}
		};
	}
})();
