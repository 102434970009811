(function () {
	angular.module('Plania').controller('CreateWoFromConditionModalController', ['$scope', 'params', 'Repository', 'ngTableParams', '$modalInstance', 'TranslationService', 'ListService', '$localStorage', '$window', '$rootScope', 'RegisteredFieldService', 'SignalR', '$timeout', controller]);
	function controller($scope, params, repository, ngTableParams, $modalInstance, translationService, listService, $localStorage, $window, $rootScope, registeredFieldService, signalR, $timeout) {
		// params: {SelectedListSetup: object}
		$scope.initialListSetup = _.cloneDeep(params.SelectedListSetup);
		if ($scope.initialListSetup)
			$scope.initialListSetup.inMemory = true;
		$scope.allRowsSelected = false;
		$scope.selectedEntities = [];
		$scope.isRunningAction = false;
		$scope.currentTab = 'conditions';
		$scope.InspectedEntityLocation = $localStorage.generalOptions.InspectedEntityLocation;
		$scope.newWorkOrders = [];
		$scope.prefill = {
			StartDate: new Date().toISOString(),
		};

		$scope.rules = { creation: 'oneToOne' };
		$scope.predictedAmountOfWorkOrders = 0;
		// Toggle to reload the main workOrder list table outside modal.
		$scope.reloadMainTable = false;
		$scope.errorMessage = null;

		$scope.workOrderResponses = [];
		$scope.totalPredictedWorkOrders = 0;
		$scope.createOneWoPerCondition = false;
		$scope.totalFailedWorkOrders = 0;

		$scope.createWorkOrderProgress = {};

		var startSignalRListener = function () {
			var progressMethodName = "createWorkOrdersWithoutValidationProgress";
			signalR.on(progressMethodName, function (progress) {
				if (progress.Finished) {
					$timeout(function () {
						$scope.createWorkOrderProgress = progress;
					}, 1000);
				} else {
					$scope.createWorkOrderProgress = progress;
				}
			});

			$scope.$on('$destroy', function () {
				signalR.off(progressMethodName);
			});
		};

		var workOrderDescriptionMaxLength = 50;
		registeredFieldService.getFieldRule(repository.commonService.prefix.WorkOrder, 'Description').then(function (result) {
			workOrderDescriptionMaxLength = result.MaxLength;
		});

		listService.getWebListViews($scope.initialListSetup, repository.apiData.condition.prefix, false, null, {}).then(function (result) {
			$scope.listOptions = result.listOptions;
			$scope.selectedListSetup = result.selectedListSetup;

			if (!$scope.conditionTable) {
				$scope.conditionTable = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { Id: 'desc' },
					filter: { PropertyFilter: [{ Property: "Status", Operator: "=", Value: 0 }, { Property: "Type", Operator: "=", Value: 1 }, { Property: "GuidCorrectiveWorkOrder", Operator: "=", Value: null }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						paginationMaxBlocks: 8,
						getData: function ($defer, params) {
							var columns = [];
							$scope.selectedListSetup.Columns.forEach(function (col) {
								columns.push(col.Property);
							});

							if (!columns.includes("Id")) columns.push("Id");
							if (!columns.includes("ActionComment")) columns.push("ActionComment");

							repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
								function (result) {
									$scope.conditionTable.settings().total = result.TotalCount;
									$scope.conditionTable.settings().filterDelay = 500;
									params.total(result.TotalCount);
									$defer.resolve(result.List);
								},
								function (error) {
									repository.growl(error, 'danger');
								});
						}
					});
			}
		});

		$scope.$watch('selectedListSetup', function (newValue, oldValue) {
			if (oldValue === newValue) return;
			if ($scope.conditionTable) {
				$scope.conditionTable.$params.sorting = $scope.selectedListSetup.Sorting;
				if ($scope.selectedListSetup.GroupBy) {
					$scope.conditionTable.settings().groupBy = function (obj) {
						if (!$scope.selectedListSetup.GroupBy) {
							return false;
						}
						var attrs = $scope.selectedListSetup.GroupBy.split('.');
						attrs.forEach(function (attr) {
							if (!obj) {
								obj = "";
							} else {
								obj = obj[attr];
							}
						});
						return obj;
					};
				} else {
					$scope.conditionTable.settings().groupBy = null;
				}
				$scope.conditionTable.reload();
			}
		});

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.addSelectedEntity = function (entity) {
			if (!$scope.isItemSelected(entity)) {
				$scope.selectedEntities.push(entity);
			} else {
				for (var i = 0; i < $scope.selectedEntities.length; i++) {
					if ($scope.selectedEntities[i].Guid === entity.Guid) {
						$scope.selectedEntities.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isItemSelected = function (entity) {
			return _.find($scope.selectedEntities, function (e) { return e.Guid === entity.Guid; });
		};

		$scope.resultTable = new ngTableParams({
			page: 1,
			count: 10
		}, {
			total: $scope.workOrderResponses.length,
			counts: [5, 10, 20],
			getData: function ($defer, params) {
				$scope.resultTable.settings().total = $scope.workOrderResponses.length;
				var paginated = $scope.workOrderResponses.slice((params.page() - 1) * params.count(), params.page() * params.count());
				$defer.resolve(paginated);
			}
		});

		$scope.getNewWorkOrders = function () {
			var newWorkOrders = [];

			if ($scope.rules.creation === 'oneWorkOrder') {
				newWorkOrders.push({});
				$scope.newWorkOrders = newWorkOrders;
				return;
			}

			var groupedConditions = _.groupBy($scope.selectedEntities, function (entity) {
				if ($scope.rules.creation === 'oneToOne')
					return entity.Guid;
				else if ($scope.rules.creation === 'perBuilding')
					return entity.GuidBuilding;
				else if ($scope.rules.creation === 'perEstate')
					return entity.GuidEstate;
				else
					return null;
			});

			Object.keys(groupedConditions).forEach(function (grpKey) {
				var conditions = groupedConditions[grpKey];
				var newWorkOrder = {
					GuidEstate: null,
					GuidBuilding: null,
					GuidEquipment: null,
					GuidArea: null,
					Description: translationService.translate('correctiveAction-new-workOrder-defaultDescription', 'Gjennomføring av tiltak'),
					ExplanatoryText: '',
					ConditionGuids: [],
					GeneratedFrom: repository.commonService.prefix.Condition,
				};

				conditions.forEach(function (condition) {
					newWorkOrder.ConditionGuids.push(condition.Guid);
				});

				if ($scope.rules.creation === 'oneToOne') {
					newWorkOrder.GuidBuilding = conditions[0].GuidBuilding;
					newWorkOrder.GuidEstate = conditions[0].GuidEstate;
					newWorkOrder.GuidEquipment = conditions[0].GuidEquipment;
					newWorkOrder.GuidArea = conditions[0].GuidArea;
					newWorkOrder.Description = conditions[0].ActionComment;
					newWorkOrder.ExplanatoryText = conditions[0].ActionComment;
				} else if ($scope.rules.creation === 'perBuilding') {
					newWorkOrder.GuidBuilding = grpKey;
					newWorkOrder.GuidEstate = conditions[0].GuidEstate;
				} else {
					newWorkOrder.GuidEstate = grpKey;
				}

				if (newWorkOrder.Description !== null && newWorkOrder.Description !== undefined)
					newWorkOrder.Description = newWorkOrder.Description.substr(0, workOrderDescriptionMaxLength);

				newWorkOrders.push(newWorkOrder);
			});

			$scope.newWorkOrders = newWorkOrders;
		};

		$scope.createWorkOrders = function () {
			if ($scope.isRunningAction) return;

			if ($scope.rules.creation === 'oneWorkOrder') {
				var workOrder = {
					fromCondition: true,
					guidSelections: _.map($scope.selectedEntities, function (entity) { return entity.Guid; }),
					defaultDescription: translationService.translate('correctiveAction-new-workOrder-defaultDescription', 'Gjennomføring av tiltak'),
					defaultStartDate: $scope.prefill.StartDate
				};
				repository.persistedData.setPersistedData('workOrder.create', workOrder);
				$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
			} else {
				$scope.getNewWorkOrders();
				var workOrders = [];
				$scope.newWorkOrders.forEach(function (wo) {
					wo.StartDate = $scope.prefill.StartDate;
					workOrders.push(wo);
				});
				$scope.isRunningAction = true;

				$scope.reloadMainTable = true;
				startSignalRListener();
				$scope.currentTab = 'creationResults';
				repository.createSingle(repository.apiData.workOrder.endpoint.createWorkOrdersWithoutValidation, workOrders).then(function (result) {
					$scope.isRunningAction = false;
					$scope.selectedEntities = [];
					$scope.conditionTable.reload();

					result.forEach(function (workOrder) {
						workOrder.EntityCaption = workOrder.EntityId;
						if (workOrder.EntityDescription) {
							if (workOrder.EntityId)
								workOrder.EntityCaption += " - ";
							workOrder.EntityCaption += workOrder.EntityDescription;
						}
					});

					$scope.workOrderResponses = result;
					$scope.resultTable.reload();
				}, function (error) {
					$scope.isRunningAction = false;
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.$watchCollection('selectedEntities', function (newSelections, oldSelections) {
			$scope.getNewWorkOrders();
		});

		$scope.openWorkOrderInNewWindow = function (workOrder) {
			if (!workOrder.CreationDate) return;
			$window.open($rootScope.navigation.href('workOrder.edit', { guid: workOrder.Guid }), '_blank');
		};

		$scope.contextMenuOptions = function (item) {
			var options = [];
			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-createWoFromConditionModal-viewWorkorder', 'Åpne arbeidsordre i ny tab/fane i nettleseren') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.openWorkOrderInNewWindow(item);
				}
			});
			return options;
		};

		$scope.cancel = function () {
			if ($scope.isRunningAction) {
				swal({
					title: translationService.translate('web-createWoFromConditionModal-swal-title', 'Vil du lukke dette vinduet?'),
					text: translationService.translate('web-createWoFromConditionModal-swal-text', 'Plania vil fortsette å opprette arbeidsordre fra tiltak, men du vil ikke kunne se potensielle feilmeldinger hvis du lukker dette vinduet.'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					if ($scope.reloadMainTable)
						$modalInstance.close();
					else
						$modalInstance.dismiss('canceled');
				});
			} else {
				if ($scope.reloadMainTable)
					$modalInstance.close();
				else
					$modalInstance.dismiss('canceled');
			}
		};
	}
})();
