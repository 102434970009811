(function () {
	angular.module('Plania').controller('bimFileMappingModalController', ['$scope', '$modal', '$modalInstance', 'Repository', 'TranslationService', 'params', controller]);

	function controller($scope, $modal, $modalInstance, repository, translationService, params) {

		$scope.model = {};

		$scope.PropertyData = [];

		getMapping();

		repository.getWithUrlParameter(repository.apiData.bimFile.url + params.GuidBimFile + "/properties").then(function (result) {
			_.forEach(result, function (col) {
				$scope.PropertyData.push(col);
			});
		});

		function getMapping() {
			repository.getWithUrlParameter(repository.apiData.bimFile.url + params.GuidBimFile + "/getMapping").then(function (result) {
				$scope.model = result;
			});

		}

		$scope.update = function () {
			var success = function (result) {
				repository.growl(
					translationService.translate(
						"web-bimFile-mapping-update-success",
						"Mappingen har blitt oppdatert"
					),
					"success"
				);
				$modalInstance.close();
			};

			var error = function (e) {
				repository.showError(e);
			};

			repository
				.updateSingle(repository.apiData.bimFile.url + params.GuidBimFile + "/changeMapping", $scope.model)
				.then(success)
				.catch(error);
		};

		$scope.clearAttribute = function (field) {
			$scope.model[field] = undefined;
		};

		$scope.openAttributeSelectModal = function (field) {
			$modal
				.open({
					templateUrl: "app/bimFile/views/bimFileFieldMappingModal.html",
					controller: "bimFileFieldMappingModalController",
					resolve: {
						params: function () {
							return {
								PropertyData: $scope.PropertyData,
							};
						},
					},
				})
				.result.then(
					function (result) {
						$scope.model[field] = { Title: result.Title, PropertyName: result.PropertyName };
					},
					function () {
						//cancel
					}
				);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('Canceled');
		};

	}
})();
