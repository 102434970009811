(function () {
	angular.module('Plania').controller('EditProjectBudgetModalController', ['$scope', '$modalInstance', 'modalParams', 'Repository', 'TranslationService', '$rootScope', '$filter', controller]);

	function controller($scope, $modalInstance, modalParams, repository, translationService, $rootScope, $filter) {
		$scope.budget = modalParams.budget;
		$scope.rootScope = $rootScope;
		$scope.newComment = { text: '' };

		$scope.budgetHasError = false;
		$scope.budgetErrorMsg = '';

		var regex = /[.]/g;

		$scope.isBudgetValid = function () {
			if ($scope.budget === null) {
				$scope.budgetHasError = true;
				$scope.budgetErrorMsg = translationService.translate('web-project-budget-error-empty', 'Budsjettfeltet kan ikke være tomt');
				return false;
			}
			else if ($scope.budget === undefined) {
				$scope.budgetHasError = true;
				$scope.budgetErrorMsg = translationService.translate('web-project-budget-error-undefined', 'Feil i budsjettfelt');
				return false;
			}
			else if ($scope.budget === modalParams.budget) {
				$scope.budgetHasError = true;
				$scope.budgetErrorMsg = translationService.translate('web-project-budget-error-unchanged', 'Budsjett er uendret');
				return false;
			}
			$scope.budgetHasError = false;
			$scope.budgetErrorMsg = '';
			return true;
		};

		$scope.update = function () {
			// validate budget
			if (!$scope.hasEditAccess('Project')) return;
			if (!$scope.isBudgetValid())
				return;

			repository.updateSingle(repository.apiData.project.endpoint.updateBudget,
				{ Guid: modalParams.guid, BudgetAmount: $scope.budget, GuidUser: repository.authService.getUserData().guidUser }).then(function () {
					$scope.addComment();
					repository.growl("Budsjett har blitt oppdatert", 'success');
					$scope.$close($scope.budget);
				}).catch(function (err) {
					repository.growl(err, 'danger');
			});
		};

		$scope.addComment = function () {
			var comment = translationService.translate('web-project-budget-edit', 'Budsjettendring') + " " + $filter('number')($scope.budget, 2) + " " + translationService.translate('web-project-budget-currency', 'kr');

			if ($scope.newComment.text !== "") {
				comment += " - " + $scope.newComment.text;
			}

			var newComment = { Comment: comment };
			newComment.GuidProject = modalParams.guid;
			repository.createSingle(repository.apiData.entityComment.url, newComment).then(function (result) {
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

	}
})();
