(function () {
	var app = angular.module('Plania');

	app.directive('suggestion', function () {
		return {
			restrict: 'A',
			require: '^autocomplete',
			link: function (scope, element, attrs, autoCtrl) {
				element.bind('mouseenter', function () {
					autoCtrl.setIndex(attrs.index);
				});
			}
		};
	});

	app.directive('autocomplete', function () {
		return {
			restrict: 'E',
			scope: {
				searchParam: '=ngModel', //Word to search for in autocomplete
				suggestions: '=data', //list of possible matches - optional
				ngDisabled: '=ngDisabled', //condition if autocomplete/input field should be disabled or not
				onType: '=onType', //autocomplete type (api service to be called in order to get suggestions (mandatory)
				onSelect: '=onSelect', //function or property to call or set when
				singleValue: '=isSingleValue', //true/false based on if you want to use both id and description in result or just one of them (id or description is set in autocompleteservice and should not be changed without checking all autocompletes using that service)
				descriptionModel: '=descriptionModel', //set description on another property (optional)
				filter: '=filter', //function to retrieve filters used in search query (will be called with onType value in parameter)
				validation: '=validation', //property to validate against (optional, if not set onSelect value will be used)
				validate: '=validate', //watch property (bool), can be used to run validation from controller/view
				active: '=active', //should autocomplete be activated?
				floatingLabel: '=floatingLabel', //The label if the autocomplete should have floating labels
				createHandler: '=createHandler', //if defined, handler object whose methods are called by selection list (tableModal) to handle access and behaviour (view/controller) when creating a new entity
				displayColumnsHandler: '=displayColumnsHandler', //optional, can be set to a function returning a custom array of columns
				fillIfMandatory: '=fillIfMandatory', //optional, if set autcomplete can fill the field if there is only one result
				defaultSort: '=defaultSort', //optional, set the default sorting direction. Usually used with displayColumnsHandler
			},
			controller: ['$scope', '$timeout', 'AutoCompleteService', '$modal', 'TranslationService', 'Repository', '$log', 'RegisteredFieldService', '$localStorage', planiaAutoCompleteController],
			link: linkFunction,
			templateUrl: 'app/common/directives/views/planiaAutocomplete.html'
		};
	});

	var planiaAutoCompleteController = function ($scope, $timeout, autoCompleteService, $modal, translationService, repository, $log, registeredFieldService, $localStorage) {
		$scope.selectedIndex = -1;
		$scope.modalSize = "md";
		var initializing = false;
		$scope.setIndex = function (i) {
			$scope.selectedIndex = parseInt(i);
		};
		//public method for suggestion directive to set correct index when clicking on a item.
		this.setIndex = function (i) {
			$scope.setIndex(i);
			$scope.$apply();
		};

		$scope.getIndex = function (i) {
			return $scope.selectedIndex;
		};

		$scope.completing = false;
		$scope.apiDataType = null;

		var getParams = function () {
			var params;
			if ($scope.filter && typeof ($scope.filter) === "function")
				params = $scope.filter($scope.onType);

			if (!params)
				params = {};

			params.dropdown = $scope.searchParam;
			params.onType = $scope.onType;
			return params;
		};

		var autoCompleteResult = function (service) {

			var params = getParams();

			service(params).then(function (result) {
				$scope.suggestions = result.dropdown;
				$scope.totalCount = result.totalCount;

				if (result.dropdown.length === 1 && $scope.onType === 'postal') {
					if (result.dropdown[0].id !== $scope.searchParam) {
						$scope.completing = true;
					} else {
						$scope.select($scope.suggestions[0]);
					}
				}
				else if ($scope.onType === 'controlListLogItem') {
					if (_.some($scope.suggestions, { 'id': $scope.searchParam.toLowerCase().replace('\n', '') })) {
						$scope.completing = false;
					}
				}
				else if (result.dropdown.length === 1) {
					var dropdown = $scope.suggestions[0];
					var id = dropdown.id.toString().toLowerCase();
					var description = dropdown.description ? dropdown.description.toLowerCase() : null;
					var strippedSearchParam = $scope.searchParam.toLowerCase();
					if (id === strippedSearchParam || description === strippedSearchParam || getSearchParamValue(dropdown).toLowerCase() === strippedSearchParam) {
						$scope.completing = false;
						$scope.select($scope.suggestions[0]);
					}
				}
			}).catch(function (error) {
				repository.growl(error, 'danger');
			});
		};

		var lookupId = translationService.translate('autocomplete-lookup-header-id', 'Id');
		var displayColumns = null;

		$scope.openDialog = function () {
			var params = getParams();
			params.dropdown = '';
			$scope.isValid = true;
			$modal.open({
				templateUrl: 'app/common/views/tableModal.html',
				controller: 'TableModalController',
				size: $scope.modalSize,
				resolve: {
					content: function () {
						return {
							header: translationService.translate('web-lookup-title', 'Oppslagsliste'),
							idTitle: lookupId,
							displayColumns: $scope.displayColumnsHandler ? $scope.displayColumnsHandler() : displayColumns,
							data: getService(),
							params: params,
							singleValue: $scope.singleValue,
							onType: $scope.onType,
							createHandler: $scope.createHandler,
							defaultSort: $scope.defaultSort
						};
					}
				}
			}).result.then(function (selected) {
				$scope.select(selected);
				setTimeout($scope.checkValid(), 200);
			},
				function () {
					setTimeout(function () {
						$scope.checkValid();
						if (!$scope.isValid)
							$scope.showError = true;
					},
						200);
				});
		};

		//todo rewrite to use prefix instead of hardcoded string ?
		var getService = function () {
			switch ($scope.onType.toLowerCase()) {
				case "estate":
					return autoCompleteService.estates;
				case "area":
					return autoCompleteService.areas;
				case "article":
					return autoCompleteService.article;
				case "accounting0":
				case "accounting1":
				case "accounting2":
				case "accounting3":
				case "accounting4":
					return autoCompleteService.accounting;
				case "building":
					return autoCompleteService.buildings;
				case "buildingcategory":
					return autoCompleteService.buildingCategory;
				case "component":
					return autoCompleteService.component;
				case "componentcategory":
					return autoCompleteService.componentCategory;
				case "contactperson":
					return autoCompleteService.contactPerson;
				case "costcenter":
					return autoCompleteService.costCenter;
				case "rentalgroup":
					return autoCompleteService.rentalGroup;
				case "constructiontype":
					return autoCompleteService.constructionType;
				case "deliveryterm":
					return autoCompleteService.deliveryTerm;
				case "workorderdescription":
					lookupId = translationService.translate('autocomplete-lookup-header-description', 'Beskrivelse');
					return autoCompleteService.workOrderDescriptions;
				case "periodictaskdescription":
					lookupId = translationService.translate('autocomplete-lookup-header-description', 'Beskrivelse');
					return autoCompleteService.periodicTaskDescriptions;
				case "equipment":
					// SVV
					if ($localStorage.generalOptions.CustomerId === 'SvvTunnel' || $localStorage.generalOptions.CustomerId === 'Fylkeskommuner') {
						displayColumns = [
							{ Property: 'Id', Title: translationService.translate('autocomplete-lookup-header-id', 'Id') },
							{ Property: 'Description', Title: translationService.translate('autocomplete-lookup-header-description', 'Beskrivelse') },
							{ Property: 'Text20', Title: translationService.translate('autocomplete-lookup-header-equipment-svv-text20', 'Driftsmerking') },
						];
					}

					return autoCompleteService.equipments;
				case "equipmentcategories":
				case "equipmentcategory":
					return autoCompleteService.equipmentCategories;
				case "paymentterm":
					return autoCompleteService.paymentTerm;
				case "invoicingterm":
					return autoCompleteService.invoicingTerm;
				case "contracttype":
					return autoCompleteService.contractType;
				case "contractcategory":
					return autoCompleteService.contractCategory;
				case "person":
				case "responsibleperson":
					lookupId = translationService.translate('autocomplete-lookup-header-person', 'Navn');
					$scope.apiDataType = 'person';
					return autoCompleteService.persons;
				case "purchaseorder":
					return autoCompleteService.purchaseOrder;
				case "purchaseorderitem":
					return autoCompleteService.purchaseOrderItem;
				case "report":
					return autoCompleteService.report;
				case "resourceresponsible":
					lookupId = translationService.translate('autocomplete-lookup-header-person', 'Navn');
					return autoCompleteService.resourceResponsibles;
				case "supplier":
					return autoCompleteService.suppliers;
				case "resourcegroup":
					return autoCompleteService.resourceGroups;
				case "cause":
					return autoCompleteService.cause;
				case "cleaningquality":
					return autoCompleteService.cleaningQuality;
				case "cleaningtask":
					return autoCompleteService.cleaningTask;
				case "cleaningtype":
					return autoCompleteService.cleaningType;
				case "department":
					return autoCompleteService.department;
				case "document":
					return autoCompleteService.document;
				case "documentcategory":
					return autoCompleteService.documentCategory;
				case "documenttype":
					return autoCompleteService.documentType;
				case "drawing":
					return autoCompleteService.drawing;
				case "priority":
					return autoCompleteService.priority;
				case "account":
					return autoCompleteService.account;
				case "postal":
					displayColumns = [
						{ Property: 'ZipCode', Title: translationService.translate('autocomplete-lookup-header-postal-zipcode', 'Postnummer') },
						{ Property: 'PostalArea', Title: translationService.translate('autocomplete-lookup-header-postal-PostalArea', 'Poststed') },
						{ Property: 'MunicipalityName', Title: translationService.translate('autocomplete-lookup-header-postal-MunicipalityName', 'Kommune') },
						{ Property: 'County', Title: translationService.translate('autocomplete-lookup-header-postal-County', 'Fylke') },
					];
					return autoCompleteService.postal;
				case "service":
					return autoCompleteService.service;
				case "supplierlineofbusiness":
					return autoCompleteService.supplierLineOfBusiness;
				case "areacategory":
					return autoCompleteService.areaCategory;
				case "areatype":
					return autoCompleteService.areaType;
				case "status":
					return autoCompleteService.statusCategory;
				case "activitycategory":
					return autoCompleteService.activityCategory;
				case "organization":
					return autoCompleteService.organization;
				case "organizationunit":
					return autoCompleteService.organizationUnit;
				case "organizationsection":
					return autoCompleteService.organizationSection;
				case "personrole":
					return autoCompleteService.PersonRole;
				case "deviationtype":
					return autoCompleteService.deviationType;
				case "hourtype":
					return autoCompleteService.hourType;
				case "checklist":
					return autoCompleteService.checklist;
				case "estatecategory":
					return autoCompleteService.EstateCategory;
				case "dataowner":
					return autoCompleteService.dataOwner;
				case "entitypermissionprofile":
					return autoCompleteService.entityPermissionProfile;
				case "mobilemenuprofile":
					return autoCompleteService.mobileMenuProfile;
				case "buildingselection":
					return autoCompleteService.buildingSelection;
				case "systemadministrator":
					displayColumns = [
						{ Property: 'Username', Title: translationService.translate('web-user-username') },
						{ Property: 'RealName', Title: translationService.translate('web-user-realname') },
					];
					$scope.apiDataType = 'user';
					return autoCompleteService.systemAdministrator;
				case "usergroup":
					lookupId = translationService.translate('autocomplete-lookup-header-userGroup', 'Navn');
					return autoCompleteService.userGroup;
				case "customer":
					return autoCompleteService.customer;
				case "customergroup":
					return autoCompleteService.customerGroup;
				case "customercategory":
					return autoCompleteService.customerCategory;
				case "customerlineofbusiness":
					return autoCompleteService.customerLineOfBusiness;
				case "activitygroup":
					return autoCompleteService.activityGroup;
				case "entityattribute":
					return autoCompleteService.entityAttribute;
				case "purchaseorderform":
					return autoCompleteService.purchaseOrderForm;
				case "paymentorderform":
					return autoCompleteService.paymentOrderForm;
				case "sparepart":
					return autoCompleteService.sparePart;
				case "workorder":
					return autoCompleteService.workOrder;
				case "consumable":
					return autoCompleteService.consumable;
				case "region":
					return autoCompleteService.region;
				case "controllistlogitem":
					return autoCompleteService.controlListLogItem;
				case "projecttype":
					return autoCompleteService.projectType;
				case "projectcategory":
					return autoCompleteService.projectCategory;
				case "project":
					return autoCompleteService.project;
				case "equipmentoperatinghourtype":
					return autoCompleteService.equipmentOperatingHourType;
				case 'referencedata':
				case 'buildingtype':
				case 'businessunit':
				case 'deviationclassification':
					return autoCompleteService.referenceData;
				case 'referencetype':
					return autoCompleteService.referenceType;
				case 'periodictask':
					return autoCompleteService.periodicTask;
				case 'workordertemplate':
					return autoCompleteService.workOrderTemplate;
				case "webmenu":
					$scope.modalSize = "lg";
					if (!$scope.defaultSort)
						$scope.defaultSort = { Name: "asc" };
					displayColumns = [
						{ Property: 'Name', Title: translationService.translate('web-webmenu-name') },
						{ Property: 'Description', Title: translationService.translate('web-webmenu-description') },
						{ Property: 'Context', Title: translationService.translate('web-webmenu-context') },
					];
					return autoCompleteService.webmenu;
			}
			return "";
		};

		getService();

		var getSearchParamValue = function (suggestion) {
			if ($scope.singleValue)
				return suggestion.id;
			else {
				if ($scope.onType === 'postal')
					return suggestion.id;
				if (suggestion.description)
					return suggestion.id + " - " + suggestion.description;
				else
					return suggestion.id;
			}
		};

		var getGuid = function () {
			if ($scope.onSelect && typeof $scope.onSelect !== "function") {
				return $scope.onSelect;
			}
			return $scope.validation;
		};

		var getCaptionTextIfNull = function () {
			if ($scope.searchParam) return;

			var guid = getGuid();
			if (!guid) return;

			var apiDataType = $scope.apiDataType ? $scope.apiDataType : $scope.onType;
			var apiData = repository.apiData.getApiDataByName(apiDataType);
			if (apiData) {
				repository.getSingle(apiData.url, guid).then(function (result) {
					//deregister and register watcher again in order to change value without doing a search
					searchParamWatcher();
					$scope.searchParam = result.Data.Caption;
					addSearchParamWatcher();
				});
			} else {
				$log.warn("Could not find apiData for " + $scope.onType + ". Check why object is missing in getApiDataByName");
			}
		};

		$scope.checkValid = function () {
			if ($scope.onSelect) {
				if (typeof ($scope.onSelect) === "function") {
					$scope.isValid = $scope.validation ? $scope.validation.length > 0 : false;
				} else {
					$scope.isValid = $scope.onSelect.length > 0;
				}
			} else {
				$scope.isValid = $scope.validation ? $scope.validation.length > 0 : false;
			}
		};

		$scope.$watch('validation', function (newValue, oldValue) {
			if (!newValue) return;

			if (newValue !== oldValue) {
				$scope.checkValid();
				$scope.showError = !$scope.isValid && ($scope.searchParam && $scope.searchParam.length > 0);
			}

			if ($scope.active)
				getCaptionTextIfNull();
		});

		$scope.$watch('validate', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue) {
				$scope.checkValid();
				$scope.validate = false;
				$scope.showError = !$scope.isValid && ($scope.searchParam && $scope.searchParam.length > 0);
			}
		});

		var searchParamWatcher = function () { };

		var forceInitActive = !$scope.fillIfMandatory; // Usually this component is called by setting activateAutocomplete after some
		// loading is done from the API. However if creating a new object activateAutocomplete might already be set to true
		// and this watcher will always compare true to true. Only enabled if "fillIfMandatory" is set to prevent possible regression
		$scope.$watch('active',
			function (newValue, oldValue) {
				if (newValue === oldValue) {
					if (forceInitActive)
						return;
				}
				forceInitActive = true;

				if (newValue === true) {

					addSearchParamWatcher();
					getCaptionTextIfNull();

					if ($scope.fillIfMandatory && !$scope.completing) {
						fillIfMandatory();
					}
				}

				else
					$scope.completing = false;
			});

		function fillIfMandatory() {
			registeredFieldService.getFieldRuleByEntityAndColumn($scope.fillIfMandatory).then(function (fieldrule) {
				if (fieldrule && fieldrule.Mandatory) tryFillSingleResult();
			});
		}

		function tryFillSingleResult() {
			var service = getService();
			var params = getParams();
			service(params).then(function (result) {
				if (result.dropdown && result.dropdown.length === 1) {
					$scope.completing = false;
					$scope.select(result.dropdown[0]);
				}
			});
		}

		function addSearchParamWatcher() {
			initializing = true;
			searchParamWatcher = $scope.$watch('searchParam',
				function (newValue, oldValue) {
					if (initializing) {
						initializing = false;
						return;
					}
					if (!newValue) newValue = "";
					if (!oldValue) oldValue = "";
					if (oldValue === newValue) {
						return;
					}

					if ($scope.active === false) {
						return;
					}

					$scope.checkValid();

					if ($scope.searchParam) {
						$scope.completing = true;
						$scope.searchFilter = $scope.searchParam;
						$scope.selectedIndex = -1;
						autoCompleteResult(getService());
					} else {
						$scope.searchParam = "";
						$scope.searchFilter = "";
						$scope.select(null);

						$scope.setIndex(-1);
					}
				});
		}

		addSearchParamWatcher();

		$scope.select = function (suggestion) {
			searchParamWatcher();
			if (suggestion) {
				$scope.searchParam = getSearchParamValue(suggestion);
				$scope.searchFilter = suggestion;
				if (typeof ($scope.onSelect) === "function") {
					$scope.onSelect(suggestion.object);
				} else {
					$scope.onSelect = suggestion.guid;
				}
				if ($scope.descriptionModel)
					$scope.descriptionModel = suggestion.description;

				$scope.checkValid();
			} else {
				if ($scope.onSelect) {
					if (typeof ($scope.onSelect) === "function") {
						$scope.onSelect(null);
					} else {
						$scope.onSelect = "";
					}
				}
				if ($scope.descriptionModel)
					$scope.descriptionModel = "";
			}
			$scope.completing = false;
			$scope.setIndex(-1);
			addSearchParamWatcher();
		};

		var key = { left: 37, up: 38, right: 39, down: 40, enter: 13, esc: 27, tab: 9 };

		$scope.keydown = function ($event) {
			if (!$scope.suggestions)
				return;
			var keycode = $event.keyCode || $event.which;
			var l = $scope.suggestions.length;
			switch (keycode) {
				case key.esc:
					$scope.select();
					$scope.setIndex(-1);
					break;
				case key.up:
					index = $scope.getIndex() - 1;
					if (index < -1) {
						index = l - 1;
					} else if (index >= l) {
						index = -1;
						$scope.setIndex(index);
						break;
					}
					$scope.setIndex(index);
					break;
				case key.down:
					index = $scope.getIndex() + 1;
					if (index < -1) {
						index = l - 1;
					} else if (index >= l) {
						index = -1;
						$scope.setIndex(index);
						break;
					}
					$scope.setIndex(index);
					break;
				case key.left:
					break;
				case key.right:
				case key.tab:
				case key.enter:
					index = $scope.getIndex();
					if (index !== -1) {
						$scope.select($scope.suggestions[index]);
					}
					$scope.setIndex(-1);
					break;
				default:
					return;
			}
		};
	};

	var linkFunction = function (scope, element, attrs) {
		var attr = '';

		scope.attrs = {
			"class": "",
			"id": "",
			"inputclass": "",
			"inputid": "",
		};

		for (var a in attrs) {
			attr = a.replace('attr', '').toLowerCase();
			if (a.indexOf('attr') === 0) {
				scope.attrs[attr] = attrs[a];
			}
		}
		if (attrs.clickactivation) {
			element[0].onclick = function (e) {
				if (!scope.searchParam) {
					scope.completing = true;
					scope.$apply();
				}
			};
		}

		element[0].addEventListener("blur", function (e) {
			if (scope.completing && scope.suggestions && scope.suggestions.length === 1) {
				scope.select(scope.suggestions[0]);
			}

			setTimeout(function () {
				scope.setIndex(-1);
				if (scope.onType === 'postal') scope.showError = false;
				else {
					if (scope.isValid === undefined) {
						scope.checkValid();
					}
					scope.showError = !scope.isValid && (scope.searchParam && scope.searchParam.length > 0);
				}
				scope.$apply();
			}, 200);
		}, true);
	};
})();
