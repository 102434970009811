(function () {
    angular.module('Plania').controller('EditComponentEquipmentController', ['$scope', 'Repository', 'params', '$modalInstance', '$timeout', controller]);
    function controller($scope, repository, params, $modalInstance, $timeout) {
        $scope.model = angular.copy(params.equipment);
        $scope.reload = false;

        $timeout(function () {
            $scope.reload = true;
        }, 100);

        $scope.save = function () {
            $modalInstance.close();
        };

		$scope.saveAction = function () {

			repository.updateSingle(repository.apiData.componentEquipment.url, $scope.model).then(function (result) {
                repository.growl('Anlegg oppdatert', 'success');
                $modalInstance.close('ok');
			}, function (error) {
				repository.growl(error, 'danger');
			});
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
