angular.module('Plania').factory('ControlListThemeService', ['$modal', 'Repository', '$q', '$rootScope', 'TranslationService', 'DwgThemeService', function ($modal, repository, $q, $rootScope, translationService, dwgThemeService) {
	var service = {};

	var overDeadline = '#ff9800';
	var beforeDeadline = '#ffc107';
	var hasActiveWorkOrder = '#428bca';
	var alertDays = 15;
	var statuses = [];

	var openWorkOrderModal = function () {
		$modal.open({
			templateUrl: 'app/workOrder/views/editWorkOrderModal.html',
			size: 'lg',
			controller: 'WorkOrderController',
			resolve: {
				$stateParams: function () { return { guid: result.guid, isModal: true }; },
			}
		});
	};

	service.getLegends = function () {
		return [
			{ label: translationService.translate('web-dwg-legend-controlList-overdeadline', 'Kontrolliste på arbeidsordre over tidsfrist'), legendClass: 'legend-warning-2' },
			{ label: translationService.translate('web-dwg-legend-controlList-beforedeadline', 'Kontrolliste på arbeidsordre nær tidsfrist'), legendClass: 'legend-warning' },
			{ label: translationService.translate('web-dwg-legend-controlList-activeworkorder', 'Kontrolliste på aktiv arbeidsordre'), legendClass: 'legend-primary' }
		];
	};

	var updateIndicatorColours = function () {
		if (!statuses) return;

		statuses.forEach(function (status) {
			var elements = $('#' + status.GuidArea).children();

			if (elements[0]) {
				var path = elements[0];

				$(path).attr('stroke', hasActiveWorkOrder);
				$(path).attr('fill', hasActiveWorkOrder);
				$(path).attr('stroke-width', '100');
				$(path).attr('stroke-opacity', '1');
				$(path).attr('fill-opacity', '0.3');
				$(path).attr('stroke-dasharray', '');
				$(path).attr('stroke-linecap', '');

				var overDeadlineDate = new Date(status.DueDate);
				var timespan = alertDays * 24 * 60 * 60 * 1000;
				var beforeDeadlineDate = new Date(overDeadlineDate.getTime() - timespan);

				if (new Date() > beforeDeadlineDate) {
					$(path).attr('stroke', beforeDeadline);
					$(path).attr('fill', beforeDeadline);
					$(path).attr('fill-opacity', '0.2');
				}

				if (new Date() > overDeadlineDate) {
					$(path).attr('stroke', overDeadline);
					$(path).attr('fill', overDeadline);
					$(path).attr('fill-opacity', '0.3');
				}
			}
		});
	};

	service.setAlertDays = function (newAlertDays) {
		alertDays = newAlertDays;
		updateIndicatorColours();
	};

	service.onClick = function (guid) {
		var deferred = $q.defer();
		var tabs = {
			area: { visible: $rootScope.hasReadAccess(repository.apiData.area.prefix), active: false },
			controlList: { visible: true, active: true },
		};

		$modal.open({
			templateUrl: 'app/dwg/views/dwgSelectionModal.html',
			size: 'lg',
			animation: false,
			controller: 'DwgSelectionModalController',
			resolve: {
				params: function () { return { guid: guid, tabInfo: tabs }; }
			}
		}).result.then(function (result) {
			deferred.resolve({}); //resolve this modal here and process other modals later
		}, function () {
			deferred.resolve({ update: true });
		});
		return deferred.promise;
	};

	service.getStatus = function (areas, guidDrawing) {
		dwgThemeService.removePeriodicMarkers(areas);
		dwgThemeService.removeRequestMarkers(areas);
		dwgThemeService.removeCategoryLabels(areas);

		var areaGuids = areas.map(function (area) {
			return area.GuidArea;
		});

		return repository.runAction(repository.apiData.controlListXEntity.endpoint.getEarliestControlListDueDates, areaGuids).then(function (result) {
			dwgThemeService.removeAreaIndicators(areas);

			statuses = result;
			updateIndicatorColours();
			return result;
		});
	};

	return service;
}]);
