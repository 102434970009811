(function () {
    angular.module('Plania').controller('SparePartCountingController', ['$scope', 'Repository', '$stateParams', 'TranslationService', '$modal', '$timeout', '$q', controller]);

    function controller($scope, repository, $stateParams, translationService, $modal, $timeout, $q) {
        $scope.isUpdate = $scope.navigation.current.name === 'sparePartCounting.edit';
        $scope.model = {};
        $scope.viewMode = $scope.isUpdate ? true : false;

        $scope.toggleViewMode = function () {
            $scope.viewMode = !$scope.viewMode;
            $scope.reload = true;
        };

        /*X-ref list properties*/
        $scope.search = {};
        $scope.selectedDataType = 'SparePartCountingItem';
        $scope.sortingProperty = 'SparePart.Location';
        $scope.sortingDirection = 'desc';
        $scope.sorting = {};
        $scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
        $scope.countingItemCount = 25;

        $scope.sparePartCountingLists = [];

        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.SparePartCounting, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.SparePartCounting, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.SparePartCounting);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.SparePartCounting);
            }
        };

        $scope.getGraphColor = function (list, type) {
            var progress = $scope.getPercentCompleted(list);
            if (progress === 100) {
                return type === 'color' ? '#4CAF50' : 'c-green';
            } else if (progress < 20) {
                return type === 'color' ? '#F44336' : 'c-gray';
            } else {
                return type === 'color' ? '#00bcd4' : 'c-gray';
            }
        };

        $scope.setSelectedCountingList = function (list) {
            $scope.selectedCountingList = list;
            $scope.loadCountingListItems();
            $scope.loadCountingListUsers();
        };

        $scope.setSelectedDataType = function (type) {
            $scope.selectedDataType = type;
        };

        var getCountingItemComments = function () {
            var commentFilter = { PropertyFilter: [{ Property: 'SparePartCountingList.GuidSparePartCounting', Operator: '=', Value: $stateParams.guid }, { Property: 'Comment', Operator: '<>', Value: '' }] };

            repository.GetPaginated(repository.apiData.sparePartCountingItem.url, 0, 50, {}, commentFilter, '', JSON.stringify(['SparePart.Component.GuidImage', 'SparePart.Component.Id', 'SparePart.Component.Description', 'CountedDate', 'Comment'])).then(function (result) {
                $scope.commentCount = result.TotalCount;
                $scope.commentItems = result.List;
            });
        };

        $scope.sortingProperties = [
            { value: 'SparePart.Location', label: 'Plassering', type: 'string' },
            { value: 'SparePart.Component.Id', label: 'ID', type: 'string' },
            { value: 'QuantityDeviation', label: 'Avvik', type: 'number' },
            { value: 'CountedDate', label: 'Tidspunkt', type: 'date' },
        ];

        $scope.setSorting = function (item) {
            if ($scope.sortingProperty === item.value) {
                $scope.sortingDirection = $scope.sortingDirection === 'desc' ? 'asc' : 'desc';
                $scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
            } else {
                $scope.sorting = {};
                $scope.sortingProperty = item.value;
                $scope.sortingDirection = 'desc';
                $scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
            }

            $scope.loadCountingListItems();
        };

        if ($scope.isUpdate) {
            repository.getSingle(repository.apiData.sparePartCounting.url, $stateParams.guid).then(
                function (response) {
                    $scope.model = response.Data;
                    updateAccess();
                }, function (error) {
                    repository.growl(error, 'danger');
                }
            );

            // load child lists
            var params = {
                page: 1,
                count: 25,
                sorting: { CreationDate: 'asc' },
                filter: { PropertyFilter: [{ Property: 'GuidSparePartCounting', operator: '=', value: $stateParams.guid }] }
            };

            repository.GetPaginated(repository.apiData.sparePartCountingList.url, params.page - 1, params.count, params.sorting, params.filter)
                .then(function (result) {
                    $scope.sparePartCountingLists = result.List;
                    if ($scope.sparePartCountingLists.length > 0){
                        $scope.setSelectedCountingList($scope.sparePartCountingLists[0]);
                    }
                },
                function (error) {
                    repository.growl(error, 'danger');
                });

            getCountingItemComments();
        }

        $scope.update = function (destination) {
            var success = function (result) {
                repository.growl($scope.isUpdate ? translationService.translate('update-sparepartcounting-success', 'Varetellingen har blitt oppdatert.') : translationService.translate('create-sparePartcounting-success', 'Varetellingen har blitt opprettet.'), 'success');
                var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
                repository.commonService.setLastRegisterGuid(destination, guid);
                $scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
            };

            var error = function (error) {
                if (typeof (error) === "string") {
                    repository.growl(error, 'danger');
                } else {
                    repository.growl(error.Data.Message, 'danger');
                }
            };

            if ($scope.isUpdate) {
                repository.updateSingle(repository.apiData.sparePartCounting.url, $scope.model).then(success, error);
            } else {
                repository.createSingle(repository.apiData.sparePartCounting.url, $scope.model).then(success, error);
            }

        };

        $scope.completeCounting = function () {
            swal({
                title: translationService.translate('web-swal-sparePartcounting-complete-title', 'Fullfør varetellingen?'),
                text: translationService.translate('web-swal-sparePartcounting-complete-message', "Varetellingen vil bli merket som ferdig, og kan ikke lenger endres!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#4caf50",
                confirmButtonText: translationService.translate('web-swal-sparePartcounting-complete-button-confirm', 'Ja, fullfør Varetellingen!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                $scope.model.EndDate = new Date().toISOString();

                repository.updateSingle(repository.apiData.sparePartCounting.url, $scope.model).then(function (result) {
                    swal(translationService.translate('web-swal-sparePartcounting-complete-success', 'Varetellingen ble fullført!'), null, "success");
                }, function (error) {
                    $scope.model.EndDate = '';
                });
            });
        };

        $scope.reopenCounting = function () {
            var oldEndDate = $scope.model.EndDate;

            $scope.model.EndDate = null;

            repository.updateSingle(repository.apiData.sparePartCounting.url, $scope.model).then(function (result) {
                $scope.model = result;
            }, function (error) {
                $scope.model.EndDate = oldEndDate;
            });
        };

        $scope.delete = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-sparePartcounting-message', "Varetellingen vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-sparePartcounting-button-confirm', 'Ja, fjern Varetellingen'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.sparePartCounting.url, $scope.model.Guid)
                    .then(function (result) {
                        swal(translationService.translate('web-swal-sparePartcounting-success', 'Varetellingen ble fjernet!'), result, "success");
                        $scope.goBack('sparePartCounting.list', { menuGuid: $scope.navigation.params.menuGuid });
                    });
            });
        };

        $scope.copy = function () {
            repository.createSingle(repository.apiData.sparePartCounting.url, { CopyCounting: true, CopyFromGuid: $stateParams.guid }).then(function (result) {
                $scope.goBack('sparePartCounting.edit', { guid: result.Guid });
            }, function (error) {
                repository.showError(error);
            });
        };

        /*
		 * Counting List functions
		 */

        $scope.hideSearch = function () {
            $scope.search = {};
            $scope.search.listviewSearchStat = false;
            $scope.searchChanged();
        };

        var searchTimeOutPromise;

        $scope.searchChanged = function () {
            $timeout.cancel(searchTimeOutPromise);
            searchTimeOutPromise = $timeout(function () {
                $scope.loadCountingListItems();
                $scope.loadCountingListUsers();
            }, 500);
        };

        $scope.reloadSparePartCounting = function (sparePartCounting) {
            if (!sparePartCounting || !sparePartCounting.Guid)
                return;
            repository.getSingle(repository.apiData.sparePartCounting.url, sparePartCounting.Guid)
                .then(function (response) {
                    sparePartCounting = angular.merge(sparePartCounting, response.Data);
                },
                function (error) {
                    repository.growl(error, 'danger');
                });
        };

        $scope.reloadList = function (list) {
            if (!list || !list.Guid)
                return;
            repository.getSingle(repository.apiData.sparePartCountingList.url, list.Guid)
                .then(function (response) {
                    list = angular.merge(list, response.Data);
                },
                function (error) {
                    repository.growl(error, 'danger');
                });
        };

        $scope.addCountingList = function () {
            $modal.open({
                templateUrl: 'app/sparePartCounting/views/createSparePartCountingListModal.html',
                controller: 'CreateSparePartCountingListModalController',
                resolve: {
                    params: function () {
                        return {
                            sparePartCounting: $scope.model,
                            listNames: _.map($scope.sparePartCountingLists, 'Id'),
                        };
                    }
                }
            }).result.then(function (guid) {
                // reload the full entity from its GUID
                repository.getSingle(repository.apiData.sparePartCountingList.url, guid).then(
                    function (response) {
                        $scope.sparePartCountingLists.push(response.Data);
                        $scope.setSelectedCountingList($scope.sparePartCountingLists[$scope.sparePartCountingLists.length - 1]);
                    }, function (error) {
                        repository.growl(error, 'danger');
                    }
                );
            }, function () {
                //cancel
            });
        };

        $scope.editCountingList = function (countingList) {
            $modal.open({
                templateUrl: 'app/sparePartCounting/views/createSparePartCountingListModal.html',
                controller: 'CreateSparePartCountingListModalController',
                resolve: {
                    params: function () { return { sparePartCountingList: countingList }; }
                }
            }).result.then(function (newCountingList) {
                countingList = newCountingList;
            }, function () {
                //cancel
            });
        };

        $scope.deleteCountingList = function (guid) {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-sparePartCountingList-message', 'Tellelisten vil bli fjernet!'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-sparePartCountingList-button-confirm', 'Ja, fjern tellelisten!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;


                repository.deleteSingle(repository.apiData.sparePartCountingList.url, guid)
                    .then(function (result) {
                        swal(translationService.translate('web-swal-sparePartCountingList-success', 'Tellelisten ble fjernet!'), result, "success");
                        // remove the list from the array

                        if ($scope.selectedCountingList.Guid === guid) {
                            $scope.selectedCountingList = null;
                        }

                        for (var i = 0; i < $scope.sparePartCountingLists.length; i++) {
                            if ($scope.sparePartCountingLists[i].Guid === guid) {
                                $scope.sparePartCountingLists.splice(i, 1);
                                break;
                            }
                        }
                    });
            });
        };

        $scope.showMoreCountingItems = function () {
            $scope.countingItemCount += 25;
            $scope.loadCountingListItems();
        };

        $scope.loadCountingListItems = function () {
            var params = {
                columns: ['SparePart.Component.GuidImage', 'SparePart.Component.Id', 'SparePart.Component.Description', 'SparePart.Location', 'PhysicalQuantity', 'RecordQuantity', 'QuantityDeviation', 'CountedDate'],
                page: 1,
                count: $scope.countingItemCount,
                sorting: $scope.sorting,
                filter: { PropertyFilter: [{ Property: 'GuidSparePartCountingList', operator: '=', value: $scope.selectedCountingList.Guid }], searchString: $scope.search.searchString }
            };

            repository.GetPaginated(repository.apiData.sparePartCountingItem.url, params.page - 1, params.count, params.sorting, params.filter, null, JSON.stringify(params.columns)).then(function (result) {
                $scope.selectedCountingList.SparePartCountingItems = result.List;
                $scope.canLoadMoreCountingItems = result.TotalCount > $scope.countingItemCount;
            }, function (error) {
                repository.growl(error, 'danger');
            });
        };

        $scope.loadCountingListUsers = function () {
            var params = {
                columns: ['User.Guid', 'User.RealName', 'User.Email', 'User.GuidImage', 'User.PhoneNumber'],
                page: 1,
                count: 25,
                sorting: { 'User.RealName': 'asc' },
                filter: { PropertyFilter: [{ Property: 'GuidSparePartCountingList', operator: '=', value: $scope.selectedCountingList.Guid }], searchString: $scope.search.searchString }
            };

            repository.GetPaginated(repository.apiData.userXSparePartCountingList.url, params.page - 1, params.count, params.sorting, params.filter, null, JSON.stringify(params.columns))
                .then(function (result) {
                    $scope.selectedCountingList.UserXSparePartCountings = result.List;
                    $scope.userCount = result.TotalCount;
                },
                function (error) {
                    repository.growl(error, 'danger');
                });
        };

        $scope.addCountingListItems = function () {
            $modal.open({
                templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: "xl",
				resolve: {
                    modalParams: function () {
                        return {
                            title: translationService.translate('web-sparePartCounting-addCountingListItems-title', 'Legg til varetelling poster'),
							columns: [
								{ Title: translationService.translate('web-component-id'), Property: 'Component.Id' },
								{ Title: translationService.translate('web-component-description'), Property: 'Component.Description' },
								{ Title: translationService.translate('web-sparePart-location'), Property: 'Location' },
								{ Title: translationService.translate('web-sparePart-inventory'), Property: 'Inventory' },
								{ Title: translationService.translate('web-supplier-description'), Property: 'ComponentXSupplier.Supplier.Description' },
								{ Title: translationService.translate('web-componentXSupplier-PartNumber'), Property: 'ComponentXSupplier.PartNumber' },
                            ],
                            url: repository.apiData.sparePart.url,
                            sorting: { 'Component.Id': 'asc' },
                            filter: { filterActiveCounting: true }
                        };
                    }
                }
            }).result.then(function (selectedGuids) {
                // create one counting item for each selected spare part
                var sparePartCountingItems = [];
                selectedGuids.forEach(function (guid) {
                    // if already added, skip it
                    if (!_.find($scope.selectedCountingList.SparePartCountingItems, function (obj) { return obj.GuidSparePart === guid; }))
                        sparePartCountingItems.push({
                            GuidSparePart: guid,
                            GuidSparePartCountingList: $scope.selectedCountingList.Guid
                        });
                });

                repository.createMultiple(repository.apiData.sparePartCountingItem.url, sparePartCountingItems, 'Prefix=' + repository.apiData.sparePartCountingItem.prefix).then(function (result) {
					repository.growl(translationService.translate('web-sparePartCounting-addCountingListItems-create-success', 'Varetelling postene har blitt lagt til'), 'success');

                    // reload list to update aggregated values
                    $scope.reloadList($scope.selectedCountingList);
                    $scope.reloadSparePartCounting($scope.model);
                    // (re) load child counting items
                    $scope.loadCountingListItems();
                });
            }, function (error) {
            });
        };

        $scope.addCountingListUsers = function () {
            $modal.open({
                templateUrl: 'app/common/views/multipleSelectModal.html',
                controller: 'MultipleSelectModalController',
                resolve: {
                    modalParams: function () {
                        return {
                            title: 'Legg til brukere',
                            columns: [
                                { Title: translationService.translate('web-user-username'), Property: 'Username', PropertyType: 'string' },
                                { Title: translationService.translate('web-user-realname'), Property: 'RealName', PropertyType: 'string' },
                            ],
                            url: repository.apiData.user.url,
                            sorting: { 'Username': 'asc' },
                            alreadySavedGuids: function () {
                                var selectedGuids = [];
                                $scope.selectedCountingList.UserXSparePartCountings.forEach(function (userXSparePartCounting) {
                                    selectedGuids.push(userXSparePartCounting.User.Guid);
                                });
                                return selectedGuids;
                            }(),
                            filter: {}
                        };
                    }
                }
            }).result.then(function (selectedGuids) {
                // create one record for each selected user
                var userXSparePartCountingList = [];
                selectedGuids.forEach(function (guid) {
                    // if already added, skip it
                    if (!_.find($scope.selectedCountingList.UserXSparePartCountings, function (obj) { return obj.GuidUser === guid; }))
                        userXSparePartCountingList.push({
                            GuidUser: guid,
                            GuidSparePartCountingList: $scope.selectedCountingList.Guid
                        });
                });

                repository.createMultiple(repository.apiData.userXSparePartCountingList.url, userXSparePartCountingList, 'Prefix=' + repository.apiData.userXSparePartCountingList.prefix).then(function (result) {
					repository.growl(translationService.translate('web-sparePartCounting-addCountingListUsers-create-success', 'Bruker har blitt lagt til'), 'success');

                    // (re) load child counting items
                    $scope.loadCountingListUsers();
                });
            }, function (error) {
            });
        };

        $scope.deleteCountingListItem = function (guid) {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-sparePartCountingItem-message', 'Reservedelen vil bli fjernet fra tellelisten!'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-sparePartCountingItem-button-confirm', 'Ja, fjern reservedelen!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.sparePartCountingItem.url, guid).then(function (result) {
                    $scope.loadCountingListItems();
                    swal(translationService.translate('web-swal-sparePartCountingItem-success', 'Reservedelen ble fjernet ble fjernet fra tellelisten!'), result, "success");
                });
            });

        };

        $scope.deleteCountingListUser = function (userXSparePartCounting) {
            repository.deleteSingle(repository.apiData.userXSparePartCountingList.url, userXSparePartCounting.Guid).then(function (result) {
                $scope.loadCountingListUsers();
            });
        };

        $scope.addCountingListContent = function () {
            if ($scope.selectedDataType === 'User')
                $scope.addCountingListUsers();
            else if ($scope.selectedDataType === 'SparePartCountingItem')
                $scope.addCountingListItems();
        };

        $scope.getPercent = function (number, total) {
            if (!total)
                return 0.0;
            return parseInt(number / total * 100);
        };

        $scope.getPercentCompleted = function (list) {
            if (!list || !list.TotalQuantity)
                return 0.0;
            return $scope.getPercent(list.TotalCountedQuantity, list.TotalQuantity);
        };

        $scope.openUserInfoModal = function (userXSparePartCounting) {
            $modal.open({
                templateUrl: 'app/common/views/userInformationModal.html',
                controller: 'UserInformationModalController',
                size: 'sm',
                resolve: {
                    params: function () {
                        return { user: userXSparePartCounting.User };
                    }
                }
            });
        };

        $scope.showCountingItemComment = function (countingItem) {
            $modal.open({
                templateUrl: 'app/common/views/messageModal.html',
                controller: 'MessageModalController',
                size: 'sm',
                resolve: {
                    content: function () {
                        return {
                            header: 'Kommentar',
                            timestamp: countingItem.SparePart.Component.Id + ' - ' + countingItem.SparePart.Component.Description,
                            message: countingItem.Comment,
                        };
                    }
                }
            });
        };

        $scope.openSparePartInfoModal = function (sparePart) {
            $modal.open({
                templateUrl: 'app/sparePart/views/sparePartInformationModal.html',
                controller: 'SparePartController',
                size: 'sm',
                resolve: {
                    params: function () {
                        return { sparePart: sparePart };
                    }
                }
            });
        };

        $scope.$on($scope.events.newSelection, function () {
            updateAccess();
        });
    }
})();
