//This directive displays a table of documents and a add button for adding to specified document_x_object with correct column data.
(function () {

	var app = angular.module('Plania');

	app.directive('alarmLog', function () {
		return {
			restrict: 'E',
			scope: {
				reload: '=reload',
				alarmLogs: '=alarmLogs',
				guid: '=guid',
				activateProjectFilter: '=activateProjectFilter'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/alarmLogDirective.html'
		};
	});

	function controller($scope, ngTableParams, repository, translationService) {
		$scope.alarmLogsIsLoading = true;
		$scope.showAlarmLog = false;
		$scope.search = { focus: false };
		$scope.$watch('reload', function (newValue, oldValue) {
			if (newValue === oldValue) {
				if (newValue === true)
					$scope.reload = false;
				return;
			}

			if ($scope.reload) {
				$scope.alarmLogTable.reload();
				$scope.reload = false;
			}
		});

		$scope.useList = true;

		$scope.alarmLogTable = new ngTableParams({
			page: 1,
			count: 5,
			sorting: { 'CreationDate': 'desc' },
			filter: {},
		}, {
				total: 0,
				counts: [5, 10, 20],
				filterDelay: 50,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					if (!$scope.guid)
						return;

					$scope.alarmLogsIsLoading = true;

					if($scope.activateProjectFilter) {
						params.filter().GuidProject = $scope.guid;
						params.filter().ChildEmailsOnProject = [];
						if ($scope.showProjectEmails)
							params.filter().ChildEmailsOnProject.push(repository.commonService.prefix.Project);
						if ($scope.showWorkOrderEmails)
							params.filter().ChildEmailsOnProject.push(repository.commonService.prefix.WorkOrder);
						if ($scope.showPurchaseOrderEmails)
							params.filter().ChildEmailsOnProject.push(repository.commonService.prefix.PurchaseOrder);
						params.filter().PropertyFilter = [{ Property: 'Alarm.Action', Operator: '<>', Value: '1' }];
					} else {
						params.filter().PropertyFilter = [{ Property: 'GuidEntity', Operator: '=', Value: $scope.guid }, { Property: 'Alarm.Action', Operator: '<>', Value: '1' }];
					}

					var columns = ['EmailTo', 'EmailCopyTo', 'EmailSubject', 'Status', 'CreationDate', 'EmailBody'];

					repository.GetPaginated(repository.apiData.alarmLog.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
						if (result === null)
							return;
						$scope.alarmLogTable.settings().total = result.TotalCount;
						$scope.alarmLogTable.settings().filterDelay = 500;

						if (!$scope.alarmLogs)
							$scope.alarmLogs = [];

						while ($scope.alarmLogs.length > 0) {
							$scope.alarmLogs.pop();
						}

						result.List.forEach(function (alarmLog) {
							$scope.alarmLogs.push(alarmLog);
						});

						$defer.resolve(result.List);
						$scope.alarmLogsIsLoading = false;

						$scope.showAlarmLog = result.List.length > 0 || !!params.filter().searchString || !!$scope.activateProjectFilter;

					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});

		$scope.showProjectEmails = true;
		$scope.showWorkOrderEmails = true;
		$scope.showPurchaseOrderEmails = true;

		var setFiltername = function () {
			var activeFilters = [];
			if ($scope.showProjectEmails)
				activeFilters.push(translationService.translate('web-enum-prefix-project', 'Prosjekt'));
			if ($scope.showWorkOrderEmails)
				activeFilters.push(translationService.translate('web-enum-prefix-workorder', 'Arbeidsordre'));
			if ($scope.showPurchaseOrderEmails)
				activeFilters.push(translationService.translate('web-enum-prefix-purchaseorder', 'Bestilling'));

			$scope.selectedFilterName = activeFilters.join(', ');
		};

		setFiltername();
		$scope.SetChildOptions = function (option) {
			switch (option) {
				case 'Project':
					$scope.showProjectEmails = !$scope.showProjectEmails;
					break;
				case 'WorkOrder':
					$scope.showWorkOrderEmails = !$scope.showWorkOrderEmails;
					break;
				case 'PurchaseOrder':
					$scope.showPurchaseOrderEmails = !$scope.showPurchaseOrderEmails;
					break;
			}
			setFiltername();
			$scope.alarmLogTable.reload();
		};

		$scope.getStatusIcon = function (status) {
			switch (status) {
				case 'Outbox':
					return 'fa-inbox c-blue';
				case 'Sent':
					return 'fa-paper-plane-top c-green';
				default:
					return 'fa-circle-x c-red';
			}
		};
	}
})();
