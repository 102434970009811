(function () {
    angular.module('Plania').controller('CompleteWorkOrderModalController', ['$scope', '$modalInstance', 'TranslationService', 'RegisteredFieldService', 'Repository', controller]);
    // this controller relies heavily on parent workOrderController, including $scope.model
	function controller($scope, $modalInstance, translationService, registeredFieldService, repository) {
        $scope.model.EndDate = $scope.model.EndDate ? $scope.model.EndDate : new Date();
        $scope.workOrderXResourceGroup = {};
		$scope.viewMode = false;
		$scope.registeredTime = {};

		if ($scope.userInfo.guidPerson && $scope.hasReadAccess("WorkOrderXResourceGroup")) {
			var kpiPropertyTotal = {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				PropertyFilter: [
					{ Property: 'GuidWorkOrder', Value: $scope.model.Guid, Operator: '=' },
					{ Property: 'GuidPerson', Value: $scope.userInfo.guidPerson, Operator: '=' }
				],
				Properties: [{
					Property: 'RealTime',
					Function: 'sum'
				}]
			};

			var kpiPropertyToday = {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				PropertyFilter: [
					{ Property: 'GuidWorkOrder', Value: $scope.model.Guid, Operator: '=' },
					{ Property: 'GuidPerson', Value: $scope.userInfo.guidPerson, Operator: '=' },
					{ Property: 'RegisteredDate', Value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(), Operator: '='}
				],
				Properties: [{
					Property: 'RealTime',
					Function: 'sum'
				}]
			};

			repository.createSingle(repository.apiData.kpi.url, kpiPropertyTotal).then(function (result) {
				$scope.registeredTime.Total = result[0].Result[0].Value;
			});

			repository.createSingle(repository.apiData.kpi.url, kpiPropertyToday).then(function (result) {
				$scope.registeredTime.Today = result[0].Result[0].Value;
			});
		}

		var getGroupMandatoryDynamicProperties = function (group) {
			var mandatoryProperties = [];
			group.Items.forEach(function (item) {
				if (item.Mandatory && !$scope.model[item.PropertyName]) {
					mandatoryProperties.push(item);
				}
				if (item.Type === 'Category') {
					item.Template.Groups.forEach(function (templateGroup) {
						var groupProps = getGroupMandatoryDynamicProperties(templateGroup);
						mandatoryProperties = mandatoryProperties.concat(groupProps);
					});
				}
			});
			return mandatoryProperties;
		};

		var getMandatoryDynamicProperties = function () {
			var mandatoryProperties = [];
			if ($scope.model.DynamicProperty) {
				$scope.model.DynamicProperty.Groups.forEach(function (group) {
					var groupProps = getGroupMandatoryDynamicProperties(group);
					mandatoryProperties = mandatoryProperties.concat(groupProps);
				});
			}
			return mandatoryProperties;
		};

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrderXResourceGroup).then(function (result) {
			$scope.workOrderXResourceGroupNoteIsMandatory = false;
			$scope.workOrderXResourceGroupGuidHourTypeIsMandatory = false;

			result.forEach(function (o) {
				if (o.DomainFieldName === "Note")
					$scope.workOrderXResourceGroupNoteIsMandatory = o.Mandatory;
				if (o.DomainFieldName === "GuidHourType")
					$scope.workOrderXResourceGroupGuidHourTypeIsMandatory = o.Mandatory;
			});
		});

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrder).then(function (result) {
			var mandatoryFields = _.filter(result, function (o) {
				if (o.DomainFieldName === 'Id') //don't validate id since it will be set in API on create, updating existing should have a valid id
					return false;

				if (o.DomainFieldName === 'RealTime' ||
					o.DomainFieldName === 'RealCost' ||
					o.DomainFieldName === 'EstimatedTime' ||
					o.DomainFieldName === 'EstimatedCost') {
					if ($scope.resourceGroupInfo.table.total() > 0)
						return false;
					return o.Mandatory;
				}
				return o.Mandatory && !$scope.model[o.DomainFieldName];
			});

			$scope.mandatoryDynamicProperties = getMandatoryDynamicProperties();

			var mappedLabels = _.map(mandatoryFields, function (o) {
				return {
					FieldName: o.DomainFieldName,
					translatedValue: translationService.translate('web-workorder-' + o.DomainFieldName.replace('Guid', '')).replace(':', '')
				};
			});

			mappedLabels.push.apply(mappedLabels, _.map($scope.mandatoryDynamicProperties, function (o) {
				return {
					FieldName: o.PropertyName,
					translatedValue: o.LabelPropertyValue.replace(':', '')
				};
			}));

			$scope.mandatoryProperties = mappedLabels;
		});

		$scope.isMandatory = function (property) {
			return _.find($scope.mandatoryProperties, function (o) { return o.FieldName === property; });
		};

		var getSwalPopup = function (title, text, confirmText, cancelText, onConfirm) {
			swal({
				title: title,
				text: text,
				type: "warning",
				showCancelButton: !!cancelText,
				confirmButtonColor: "#f44336",
				confirmButtonText: confirmText,
				cancelButtonText: cancelText,
				closeOnConfirm: true,
				showLoaderOnConfirm: false
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				// Cheat a bit to allow for chaining
				setTimeout(function () {
					if (onConfirm && typeof onConfirm === "function")
						onConfirm();
				}, 100);
			});
		};

		$scope.saveAction = function () {
			if (!$scope.model.EndDate) {
				getSwalPopup(
					translationService.translate('web-workOrderCompletionModal-swal-missingEndDate-title', 'Sluttdato er ikke satt.'),
					translationService.translate('web-workOrderCompletionModal-swal-missingEndDate-text', 'Ønsker du å sette sluttdato til dagens dato? Sluttdato er påkrevd for fullføring.'),
					translationService.translate('web-workOrderCompletionModal-swal-missingEndDate-confirm', 'Ja'),
					translationService.translate('web-workOrderCompletionModal-swal-missingEndDate-cancel', 'Nei'),
					function () {
						$scope.model.EndDate = new Date();
						$scope.saveAction();
					}
				);
				return;
			}

			// Has registered time
			if ($scope.workOrderXResourceGroup.RealTime > 0) {
				// Note is mandatory, but missing.
				if ($scope.workOrderXResourceGroupNoteIsMandatory && !$scope.workOrderXResourceGroup.Note) {
					getSwalPopup(
						translationService.translate('web-workOrderCompletionModal-swal-missingWoXResourceNote-title', 'Mangler kommentar til timeføring.'),
						translationService.translate('web-workOrderCompletionModal-swal-missingWoXResourceNote-text', 'Vennligst skriv en kommentar til timeføringen.'),
						translationService.translate('web-workOrderCompletionModal-swal-missingWoXResourceNote-confirm', 'Ok')
					);
					return;
				}

				// HourType is mandatory, but missing.
				if ($scope.workOrderXResourceGroupGuidHourTypeIsMandatory && !$scope.workOrderXResourceGroup.GuidHourType) {
					getSwalPopup(
						translationService.translate('web-workOrderCompletionModal-swal-missingWoXResourceHourType-title', 'Mangler timetype til timeføring.'),
						translationService.translate('web-workOrderCompletionModal-swal-missingWoXResourceHourType-text', 'Vennligst velg en timetype til timeføringen.'),
						translationService.translate('web-workOrderCompletionModal-swal-missingWoXResourceHourType-confirm', 'Ok')
					);
					return;
				}
			}
			$modalInstance.close($scope.workOrderXResourceGroup);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.$watch('model.DynamicProperty', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			$scope.mandatoryDynamicProperties = getMandatoryDynamicProperties();
		});
	}
}) ();
