(function () {
	var app = angular.module('Plania');
	app.directive('plColorPickerDirective', function () {
		return {
			restrict: 'E',
			scope: {
				ngModel: '=',
				ngDisabled: '='
			},
			controller: ['$scope', 'Constants', controller],
			templateUrl: 'app/common/directives/views/plColorPickerDirective.html',
		};
	});

	function controller($scope, constants) {
		$scope.colors = constants.defaultLikertScaleColors;

		$scope.setColor = function (num) {
			$scope.ngModel = num;
		};
	}
})();
