// jshint ignore: start
//https://cdnjs.cloudflare.com/ajax/libs/angular-filter/0.5.17/angular-filter.js
/**
 * @ngdoc filter
 * @name groupBy
 * @kind function
 *
 * @description
 * Create an object composed of keys generated from the result of running each element of a collection,
 * each key is an array of the elements.
 */
(function () {
	angular.module('Plania').filter('groupBy', ['$parse', 'filterWatcher', filter]);

	function filter($parse, filterWatcher ) {
		return function(collection, property) {

			var isObject = function(value) { // This function will not be needed in newest angularjs
				return value !== null && typeof value === 'object';
			};

			var isUndefined = function(value) { // This function will not be needed in newest angularjs
				return typeof value === 'undefined';
			};

			if (!isObject(collection) || isUndefined(property)) {
				return collection;
			}

			return filterWatcher.isMemoized('groupBy', arguments) ||
				filterWatcher.memoize('groupBy',
					arguments,
					this,
					_groupBy(collection, $parse(property)));

			function _groupBy(collection, getter) {
				var result = {};
				var prop;

				_.forEach(collection,
					function(elm) {
						prop = getter(elm);

						if (!result[prop]) {
							result[prop] = [];
						}
						result[prop].push(elm);
					});
				return result;
			}
		}
	}
})();
// jshint ignore: end



