(function () {
	angular.module('Plania').controller('BudgetController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', '$localStorage', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService, $localStorage) {
		$scope.isUpdate = $scope.navigation.current.name === 'budget.edit';
		$scope.model = {};
		$scope.modelDto = {};
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.prefix = repository.commonService.prefix.Budget;

		//$scope.toggleViewMode = function () {
		//    $scope.viewMode = !$scope.viewMode;
		//    $scope.reload = true;
		//};

		var yearRangeStart = $localStorage.generalOptions.YearRangeStart;
		var yearRangeEnd = $localStorage.generalOptions.YearRangeEnd;
		var year = yearRangeStart;

		$scope.years = [];

		$scope.search = {};

		while ($scope.years.length <= yearRangeEnd - yearRangeStart) {
			$scope.years.push(year);
			year++;
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Budget, checkOtherDo);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Budget, checkOtherDo);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Budget);
			}
		};

		var setProperties = function () {
			if ($scope.model.Service)
				$scope.service = $scope.getEntityCaption($scope.model.Service);
		};

		var columns = [
			'Service.Id', 'Service.Description',
			'Account.Id', 'Account.Description',
			'Accounting0.Id', 'Accounting0.Description',
			'Accounting1.Id', 'Accounting1.Description',
			'Accounting2.Id', 'Accounting2.Description',
			'Accounting3.Id', 'Accounting3.Description',
			'Accounting4.Id', 'Accounting4.Description',
			'Department.Id', 'Department.Description',
			'CostCenter.Id', 'CostCenter.Description'
		];

		if ($scope.isUpdate) {
			repository.getMainModel(repository.apiData.budget.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
				$scope.model = response.Data;
				$scope.reload = true;

				updateAccess();
				setProperties();
				getCardTables();
			}, function (error) {
				repository.growl(error, 'danger');
				if (error === 'Ingen data funnet') {
					$scope.notFoundError = true;
				}
			}
			);
		} else {
			$scope.model.Year = moment().year();
		}

		$scope.purchaseOrderItemColumns = [
			{ Position: 1, Title: translationService.translate('web-purchaseOrder-Id'), Property: 'PurchaseOrder.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-purchaseOrderItem-Id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-purchaseOrderItem-Description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-supplier-Description', 'Leverandør navn'), Property: 'Supplier.Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-purchaseOrderItem-OrderedQuantity'), Property: 'OrderedQuantity', PropertyType: 'number' },
			{ Position: 6, Title: translationService.translate('web-purchaseOrderItem-UnitPrice'), Property: 'UnitPrice', PropertyType: 'double' },
			{ Position: 7, Title: translationService.translate('web-purchaseOrderItem-OrderedAmount'), Property: 'OrderedAmount', PropertyType: 'double' },
			{ Position: 8, Title: translationService.translate('web-purchaseOrderItem-Status'), Property: 'Status', PropertyType: 'enum.purchaseorderitemstatus' },
		];

		$scope.costColumns = [
			{ Position: 1, Title: translationService.translate('web-cost-InvoiceNumber'), Property: 'InvoiceNumber', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-cost-Description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-account-id'), Property:'Account.Id', PropertyType:'string'},
			{ Position: 4, Title: translationService.translate('web-account-description'), Property: 'Account.Description', PropertyType:'string'},
			{ Position: 5, Title: translationService.translate('web-cost-amount'), Property: 'Amount', PropertyType:'double'},
			{ Position: 6, Title: translationService.translate('web-cost-InvoiceDate'), Property: 'InvoiceDate', PropertyType:'date'},
			{ Position: 7, Title: translationService.translate('web-supplier-description'), Property: 'Supplier.Description', PropertyType:'string'},
			{ Position: 8, Title: translationService.translate('web-cost-vouchernumber'), Property: 'VoucherNumber', PropertyType:'string'},
		];

		var getCardTables = function () {
			var purchaseOrderItemTableFilter = {
				GuidBudget: $scope.model.Guid
			};

			$scope.purchaseOrderItemTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Id': 'asc' },
				filter: purchaseOrderItemTableFilter
			}, {
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.purchaseOrderItemColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.purchaseOrderItem.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(), '', JSON.stringify(columns))
							.then(function (result) {
								$scope.purchaseOrderItemTable.settings().total = result.TotalCount;
								$scope.purchaseOrderItemTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							});
					}
				});

			$scope.purchaseOrderItemAllocatedTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Id': 'asc' },
				filter: {
					GuidBudget: $scope.model.Guid,
					PropertyFilter: [
						{ Property: 'Status', Operator: '>', Value: '0' }
					]
				}
			}, {
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.purchaseOrderItemColumns.forEach(function (col) {
							columns.push(col.Property);
						});


						repository.GetPaginated(repository.apiData.purchaseOrderItem.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(), '', JSON.stringify(columns))
							.then(function (result) {
								$scope.purchaseOrderItemAllocatedTable.settings().total = result.TotalCount;
								$scope.purchaseOrderItemAllocatedTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							});
					}
				});

			var costTableFilter = {
				GuidBudget: $scope.model.Guid
			};

			$scope.costTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'InvoiceNumber': 'asc' },
				filter: costTableFilter
			}, {
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.costColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.cost.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(), '', JSON.stringify(columns))
							.then(function (result) {
								$scope.costTable.settings().total = result.TotalCount;
								$scope.costTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							});
					}
				});
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.$watch('model.SumBudget', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			$scope.model.SumNotSpecified = newValue - $scope.model.SumSpecified;
			$scope.model.SumForDisposal = newValue - $scope.model.SumAllocated - $scope.model.SumCost;
			$scope.model.SumRestNotSpecified = $scope.model.SumForDisposal - $scope.model.SumRestSpecified;
			if ($scope.isDirtyBudgetSum === undefined)
				$scope.isDirtyBudgetSum = false;
			else
				$scope.isDirtyBudgetSum = true;
		}, true);

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-budget-success', 'Budsjett har blitt oppdatert.') : translationService.translate('create-budget-success', 'Budsjett har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.budget.url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData.budget.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-priority-message', "Prioritet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-budget-button-confirm', 'Ja, fjern budsjett'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.budget.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-budget-success', 'Budsjett ble fjernet!'), result, "success");
						$scope.goBack('budget.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.goToPurchaseOrder = function (item) {
			$scope.navigation.go('purchaseOrder.edit', { guid: item.GuidPurchaseOrder });
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};

			switch (filterName) {
				case "service":
					filter.FilterModel = {
						Condition: "and",
						Rules: [
							{ Property: 'IsBudgetable', Operator: '=', Value: true }
						]
					};
					break;
			}
			return filter;
		};
	}
})();
