(function () {
	angular.module('Plania').controller('EditUserDataOwnerController', ['$scope', 'Repository', 'params', '$modalInstance', 'Constants', '$rootScope', controller]);
	function controller($scope, repository, params, $modalInstance, constants, $rootScope) {
		$scope.isDocumentsCollapsed = true;
		$scope.activateAutocomplete = false;

		var isKaLocalAdmin = $scope.customization.isCustomer("KA") && $rootScope.userInfo.guidUserGroup === constants.ka.guidLocalAdminUserGroup;

		if (isKaLocalAdmin) {
			$scope.dataOwnerSelectionLocked = true;
		}

		repository.getSingle(repository.apiData.userProfile.url, params.guidUserProfile).then(
			function (result) {
				$scope.model = result.Data;
				$scope.isUpdateProfile = true;

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			},
			function (error) {
				repository.growl(error, 'danger');
			});

		$scope.save = function () {
			$modalInstance.close();
		};

		$scope.saveAction = function () {
			var sendModel = angular.copy($scope.model);
			delete sendModel.User;


			repository.updateSingle(repository.apiData.userProfile.url, sendModel).then(function (result) {
				repository.growl('Brukerprofil oppdatert', 'success');
				$modalInstance.close($scope.model);
			}, function (error) {
				var message = '';
				if (error.indexOf('invalid_license_defined_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}
				if (error.indexOf('invalid_license_simple_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}

				if (message !== '') {
					swal({
						title: "Bekreft utvidelse av lisens",
						text: message,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "Ja, utvid lisens",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.ConfirmIncreaseInLicense = true;
							$scope.saveAction();
						} else {
							repository.growl('Endringene ble avbrutt!', 'success');
							$modalInstance.close('ok');
						}
					});
				} else {
					swal({ title: "Feil ved lagring", text: error, type: "warning", closeOnConfirm: true, closeOnCancel: true });
				}
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "entityPermissionProfile":
					if (!isKaLocalAdmin)
						return {};
					return {
						FilterModel: {
							Condition: "and",
							Rules: [{ Property: "Guid", Operator: "in", Value: [constants.ka.guidStandardEntityPermissionProfile, constants.ka.guidExternalOperatorEntityPermissionProfile] }]
						}
					};
			}
		};
	}
})();
