/*
 * Directive for displaying an icon for a file based on the file-extension from the file-path
 */
(function () {

    var app = angular.module('Plania');

    app.directive('plFileTypeIcon', function () {
        return {
            restrict: 'E',
            scope: {
                filePath: '=filePath'
            },
            link: function (scope, element, attrs) {
                if (!scope.filePath) {
                    scope.iconClass = 'fa fa-file-text-o';
                    return;
                }

                var extension = scope.filePath.split('.').pop();
                switch (extension.toLowerCase()) {
                    case 'doc':
                    case 'docx':
                        scope.iconClass = 'far fa-file-word c-blue';
                        break;
                    case 'pdf':
                        scope.iconClass = 'far fa-file-pdf c-red';
                        break;
                    case 'txt':
                        scope.iconClass = 'far fa-file-text c-gray';
                        break;
                    case 'wma':
                    case 'mp3':
                        scope.iconClass = 'far fa-file-audio c-purple';
                        break;
                    case 'csv':
                    case 'xls':
                    case 'xlsx':
                        scope.iconClass = 'far fa-file-excel c-green';
                        break;
                    case 'zip':
                        scope.iconClass = 'far fa-file-archive c-brown';
                        break;
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                        scope.iconClass = 'far fa-file-image c-teal';
                        break;
                    case 'eml':
                        scope.iconClass = 'far fa-envelope c-blue';
                        break;
                    case 'ppt':
                    case 'pptx':
                        scope.iconClass = 'far fa-file-powerpoint c-teal';
                        break;
                    case 'mp4':
                        scope.iconClass = 'far fa-play-circle c-deeporange';
                        break;
                    default:
                        scope.iconClass = 'far fa-file-text';
                        break;
                }

                if (scope.filePath.indexOf("http") === 0)
                    scope.iconClass = 'fa fa-link-simple c-blue';
            },
            template: '<i class="{{iconClass}}"></i>'
        };
    });
})();
