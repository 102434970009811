(function () {
	angular.module('Plania').factory('ReactService', reactService);

	function reactService() {
		var service = {};
		var prefix = "react-migration-";

		service.uniqueReactIds = [];

		service.generateSuffix = function () {
			return "" + Math.random().toString(36).substr(2, 9);
		};

		service.generateId = function () {
			var suffix = service.generateSuffix();
			var id = "";
			while (true) {
				id = prefix + suffix;
				if (!service.uniqueReactIds.includes(id)) {
					service.uniqueReactIds.push(id);
					break;
				}
			}

			return id;
		};

		service.removeId = function (id) {
			var index = service.uniqueReactIds.indexOf(id);
			if (index !== -1) {
				service.uniqueReactIds.splice(index, 1);
			}
		};


		return service;
	}
})();
