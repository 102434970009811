(function () {
	var app = angular.module('Plania');
	app.directive('buildingAreaDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				activateAutocomplete: '=activateAutocomplete'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$stateParams', '$rootScope', 'ListService', controller],
			templateUrl: 'app/building/views/buildingArea.html'
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $stateParams, $rootScope, listService) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		$scope.viewMode = true;
		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-area-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-area-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-area-areaCategory'), Property: 'AreaCategory.Description', PropertyType: 'string' }
		];
	}
})();
