(function () {
	angular.module('Plania').controller('AcceptTermsOfUseModalController', ['$scope', '$modalInstance', 'Repository', 'DocumentService', '$state', 'authService', 'TranslationService', controller]);
	function controller($scope, $modalInstance, repository, documentService, $state, authService, translationService) {
		$scope.model = {
			isLoadingDocument: true,
			isUpdatingUser: false,
			document: null,
			hasConfirmed: false,
			hasOpenedDocument: false,
		};

		repository.getSingleWithNoParameter(repository.apiData.generalOptions.endpoint.getTermsOfUseDocument).then(function (result) {
			$scope.model.document = result.Data;
			$scope.model.isLoadingDocument = false;
		}, function (error) {
			repository.showError(error);
			$scope.model.isLoadingDocument = false;
		});

		$scope.openDocument = function (document) {
			$scope.model.hasOpenedDocument = true;
			documentService.openDocument(document);
		};

		$scope.ok = function () {
			if ($scope.model.document && !$scope.model.hasOpenedDocument) {
				swal(translationService.translate('web-acceptTermsOfUseModal-cannotConfirm-hasNotReadDocument-title','Du må åpne dokumentet før du kan trykke Godkjenn.'), "", "info");
				return;
			}

			$scope.model.isUpdatingUser = true;
			repository.runAction(repository.apiData.user.endpoint.acceptTermsOfUse).then(function () {
				$scope.model.isUpdatingUser = false;
				authService.hasAcceptedTermsOfUse();
				$modalInstance.close();
			}, function (error) {
				$scope.model.isUpdatingUser = false;
				repository.showError(error);
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
			$state.go('logout');
		};
	}
})();
