(function () {
	angular.module('Plania').controller('CleaningQualityControlController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'cleaningQualityControl.edit';
		$scope.model = {};
		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.CleaningQualityControl, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.CleaningQualityControl, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.CleaningQualityControl);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.CleaningQualityControl);
			}
		};

		if ($scope.isUpdate) {
			var columns = ['Drawing.Id', 'Drawing.Description', 'ResourceGroup.Id', 'ResourceGroup.Description'];

			repository.getSingle(repository.apiData.cleaningQualityControl.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.reload = true;
					updateAccess();
					if ($scope.model.Drawing)
						$scope.drawing = $scope.model.Drawing.Id + " - " + $scope.model.Drawing.Description;

					if ($scope.model.ResourceGroup) {
						$scope.resourceGroup = $scope.model.ResourceGroup.Id;

						if ($scope.model.ResourceGroup.Description)
							$scope.resourceGroup = $scope.resourceGroup + " - " + $scope.model.ResourceGroup.Description;
					}
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);

			//Should we get all areas attached through cleaning_quality_control_x_area ?

			$scope.areaColumns = [
				{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Area.Building.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Area.Building.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-area-id'), Property: 'Area.Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-area-description'), Property: 'Area.Description', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-areaCategory-Description'), Property: 'Area.AreaCategory.Description', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-area-ComputedGrossArea'), Property: 'Area.ComputedGrossArea', PropertyType: 'string' },
				{ Position: 7, Title: translationService.translate('web-area-ComputedNetArea'), Property: 'Area.ComputedNetArea', PropertyType: 'string' },
				{ Position: 8, Title: translationService.translate('web-cleaningqualitycontrolxarea-status', 'Status'), Property: 'Status', PropertyType: 'string' },
			];

			$scope.areaTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Area.Id': 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidCleaningQualityControl', Operator: '=', Value: $stateParams.guid }] }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						$scope.loadingAreas = true;
						var columns = _.map($scope.areaColumns, 'Property');
						repository.GetPaginated(repository.apiData.cleaningQualityControlArea.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.loadingAreas = false;
								$scope.areaTable.settings().total = result.TotalCount;
								$scope.areaTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								$scope.loadingAreas = false;
								repository.growl(error, 'danger');
							});
					}
				});
		} else {
			$scope.activateAutocomplete = true;
			$scope.model.QualityLevel = 6.5;
			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Drawing':
								$scope.drawing = prefill.Value.Label;
								$scope.model.GuidDrawing = prefill.Value.Guid;
								break;
							case 'ResourceGroup':
								$scope.resourceGroup = prefill.Value.Label;
								$scope.model.GuidResourceGroup = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}
		}

		$scope.goToDrawing = function () {
			$scope.navigation.go('dwg.view', { guid: $scope.model.GuidDrawing, theme: 'cleaningQualityControl', guidQualityControl: $scope.model.Guid });
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.autocompleteFilter = function (filterName) {
			switch (filterName) {
				case "resourceGroup":
					return { PropertyFilter: [{ Property: 'DoesCleaningTasks', operator: '=', Value: '1' }] };
			}
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-cleaningQualityControl-success', 'Kontrollen har blitt oppdatert.') : translationService.translate('create-cleaningQualityControl-success', 'Kontrollen har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};
			delete $scope.model.Drawing;

			if ($scope.isUpdate) {
				delete $scope.model.NumberOfAreasToCheck;
				delete $scope.model.ResourceGroup;
				repository.updateSingle(repository.apiData.cleaningQualityControl.url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData.cleaningQualityControl.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningQualityControl-delete-message', "Kontrollen og Historikk på tilhørende rom vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningQualityControl-delete-button-confirm', 'Ja, fjern kontrollen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.cleaningQualityControl.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cleaningQualityControl-success', 'Kontrollen ble fjernet!'), result, "success");
						$scope.goBack('cleaningQualityControl.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.OpenArea = function (cleaningQualityControlArea) {
			$modal.open({
				templateUrl: 'app/cleaningQualityControl/views/cleaningQualityControlAreaModal.html',
				controller: 'CleaningQualityControlAreaModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							cleaningQualityControlArea: cleaningQualityControlArea
						};
					}
				}
			}).result.then(function () {
				$scope.areaTable.reload();
			}, function () {
				$scope.areaTable.reload();
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}

})();
