(function () {
	angular.module('Plania').controller('ApiRequestLogController', ['$scope', 'Repository', '$stateParams', controller]);
	function controller($scope, repository, $stateParams) {
		$scope.model = { Guid: $stateParams.guid };

		var columns = [
			"EntityTask.Id", "EntityTask.Description",
			"ScheduledJob.Id", "ScheduledJob.Description",
			"UserCreatedBy.RealName"
		];

		var isJson = function (text) {
			try {
				JSON.parse(text);
				return true;
			} catch (ex) {
				return false;
			}
		};

		var tryParse = function (text) {
			if (!isJson(text)) return null;
			var parsed = JSON.parse(text);
			if (parsed.Content && isJson(parsed.Content)) {
				parsed.Content = JSON.parse(parsed.Content);
			}
			return parsed;
		};

		repository.getMainModel(repository.apiData.apiRequestLog.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
			$scope.model = response.Data;

			if ($scope.model && $scope.model.Request) {
				$scope.parsedRequest = tryParse($scope.model.Request);
			}

			if ($scope.model && $scope.model.Response) {
				$scope.parsedResponse = tryParse($scope.model.Response);
			}
		},
		function (error) {
			repository.growl(error, 'danger');
		});

	}
})();
