(function () {
	angular.module('Plania').controller('AddControlListModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', 'TranslationService', 'ListService', '$q', '$localStorage', controller]);

	function controller($scope, $modalInstance, params, repository, ngTableParams, translationService, listService, $q, $localStorage) {
		var isSVV = $localStorage.generalOptions.CustomerId === 'SvvTunnel' || $localStorage.generalOptions.CustomerId === 'Fylkeskommuner';
		$scope.tabs = [
			{ heading: translationService.translate('web-controlList-chooseControlList', "Velg Kontrolliste"), step: 1 },
			{ heading: translationService.translate('web-controlList-chooseObject', "Velg Objekt"), step: 2 }
		];

		$scope.step = $scope.update ? 2 : 1;

		$scope.setCurrentStep = function (step) {
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		$scope.equipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Description' },
			{ Position: 5, Title: translationService.translate('web-equipment-equipmentcategory'), Property: 'EquipmentCategory.Description' },
		];

		if (isSVV) {
			$scope.equipmentColumns.push({ Position: 6, Title: translationService.translate('web-equipment-text20-svv', 'Driftsmerking'), Property: 'Text20' });
		}

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-area-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-area-description'), Property: 'Description' },
			{ Position: 5, Title: translationService.translate('web-area-areacategory'), Property: 'AreaCategory.Description' },
			{ Position: 6, Title: translationService.translate('web-area-rentalStatus'), Property: 'RentalStatus', PropertyType: 'enum.AreaRentalStatus' },
		];

		$scope.controlListColumns = [
			{ Position: 1, Title: translationService.translate('web-controlList-id', 'Id'), Property: 'Id' },
			{ Position: 2, Title: translationService.translate('web-controlList-name', 'Name'), Property: 'Name' },
			{ Position: 3, Title: translationService.translate('web-controlList-description', 'Beskrivelse'), Property: 'Description' },
		];

		$scope.columns = [];
		$scope.selectedEntities = [];
		var paramsFromWorkOrder = angular.copy(params);

		var controlListFilter = {
			VersionContext: "PublishedVersions",
			FilterModel: {
				Condition: "or",
				Rules: [
					{ Property: 'UseWithArea', Operator: '=', Value: true },
					{ Property: 'UseWithWorkOrder', Operator: '=', Value: true },
					{ Property: 'UseWithEquipment', Operator: '=', Value: true }
				]
			}
		};

		$scope.controlListTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'desc' },
			filter: controlListFilter
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var columns = [];
					$scope.controlListColumns.forEach(function (column) {
						columns.push(column.Property);
					});

					columns.push('UseWithWorkOrder', 'UseWithArea', 'UseWithEquipment');

					repository.GetPaginated(repository.apiData.controlList.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		var getDefaultEntityType = function (selectedControlList, params) {
			if (params.guidEquipment && selectedControlList.UseWithEquipment)
				return "Equipment";

			if (params.guidArea && selectedControlList.UseWithArea)
				return "Area";

			if (!selectedControlList.UseWithWorkOrder) {
				if (selectedControlList.UseWithEquipment)
					return 'Equipment';
				if (selectedControlList.UseWithArea)
					return 'Area';
			}

			return "Workorder";
		};

		$scope.chooseControlList = function (item) {
			if ($scope.isSaving) return;

			$scope.selectedControlList = item;
			// clearing selections, in case we are navigating back and forth between different control lists
			$scope.selectedEntities = [];
			paramsFromWorkOrder = angular.copy(params);

			if (item.UseWithWorkOrder && !item.UseWithArea && !item.UseWithEquipment) {
				$scope.save(true);
			} else {
				$scope.selectedEntityType = getDefaultEntityType(item, params);
				$scope.setCurrentStep(2);
			}
		};

		var getFilter = function (baseFilter) {
			var filter = {
				searchString: baseFilter.searchString,
				PropertyFilter: []
			};

			if (params.guidBuilding) {
				filter.PropertyFilter = [{ Property: 'GuidBuilding', Operator: '=', Value: params.guidBuilding }];
			} else if (params.guidEstate) {
				filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: params.guidEstate }];
			}

			if ($scope.selectedEntityType === 'Equipment') {
				filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
			}

			return filter;
		};

		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50, 200],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					if (!$scope.selectedEntityType) {
						// nothing selected yet, we are still on tab step 1
						return;
					}

					$scope.columns = [];
					$scope.includeGuid = null;

					var filter = getFilter(params.filter());
					var url = repository.apiData.equipment.url;
					var columnProperties = [];

					switch ($scope.selectedEntityType) {
						case 'Area':
							url = repository.apiData.area.url;
							$scope.areaColumns.forEach(function (column) {
								$scope.columns.push(column);
							});
							if (paramsFromWorkOrder.guidArea) {
								$scope.includeGuid = paramsFromWorkOrder.guidArea;
								// we only select from WO on the first load
								paramsFromWorkOrder.guidArea = null;
							}
							break;
						case 'Equipment':
							url = repository.apiData.equipment.url;
							$scope.equipmentColumns.forEach(function (column) {
								$scope.columns.push(column);
							});
							if (paramsFromWorkOrder.guidEquipment) {
								$scope.includeGuid = paramsFromWorkOrder.guidEquipment;
								// we only select from WO on the first load
								paramsFromWorkOrder.guidEquipment = null;
							}
							break;
					}

					$scope.columns.forEach(function (col) {
						columnProperties.push(col.Property);
					});

					repository.GetPaginated(url, params.page() - 1, params.count(), params.sorting(), filter, $scope.includeGuid, JSON.stringify(columnProperties)).then(
						function (result) {
							params.total(result.TotalCount);

							if ($scope.includeGuid) {
								$scope.selectedEntities = [];
								// we select it after retrieving the list, so that we have all properties
								var selectionFromParams = _.find(result.List, function (o) { return o.Guid === $scope.includeGuid; });
								if (selectionFromParams)
									$scope.selectedEntities.push(selectionFromParams);
							}

							$defer.resolve(result.List);

						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});



		$scope.$watch('selectedEntityType', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			$scope.selectedEntities = [];
			if (newValue && newValue !== "Workorder") {
				$scope.items.reload();
			}
		});


		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};


		var getEntityMapping = function (entity) {
			var newEntity = { GuidWorkOrder: params.guidWorkOrder, GuidPeriodicTask: params.guidPeriodicTask, GuidControlList: $scope.selectedControlList.Guid };

			if (entity.Prefix) {
				switch (entity.Prefix) {
					case 'Area':
						newEntity.GuidArea = entity.Guid;
						break;
					case 'Equipment':
						newEntity.GuidEquipment = entity.Guid;
						break;
				}
			}
			return newEntity;
		};

		$scope.addEntity = function (entity) {
			if (!_.find($scope.selectedEntities, function (o) { return entity.Guid === o.Guid; }))
				$scope.selectedEntities.push(entity);
			else {
				for (var i = 0; i < $scope.selectedEntities.length; i++) {
					if ($scope.selectedEntities[i].Guid === entity.Guid) {
						$scope.selectedEntities.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.save = function (savingDirectlyToWo) {
			var model = [];

			if ($scope.selectedEntities.length > 0) {
				$scope.selectedEntities.forEach(function (entity) {
					model.push(getEntityMapping(entity));
				});
			} else {
				if (!savingDirectlyToWo && $scope.selectedEntityType !== "Workorder") {
					repository.growl(translationService.translate('controlList-addMultiple-noneselected', 'Velg en eller flere rader i listen'), "danger");
					return;
				}

				model.push({
					GuidWorkOrder: params.guidWorkOrder,
					GuidPeriodicTask: params.guidPeriodicTask,
					GuidControlList: $scope.selectedControlList.Guid
				});
			}

			var promises = [];

			model.forEach(function (m) {
				promises.push(repository.createSingle(repository.apiData.controlListXEntity.url, m));
			});

			$scope.isSaving = true;
			$q.all(promises).then(function () {
				repository.growl(translationService.translate('controlList-addMultiple-success', 'Kontrollistene har blitt lagt til'), "success");
				$scope.isSaving = false;
				$modalInstance.close('OK');
			}, function (error) {
				$scope.isSaving = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.isEntitySelected = function (guid) {
			return _.find($scope.selectedEntities, function (o) { return guid === o.Guid; });
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}

})();
