(function () {
	angular.module('Plania').controller('AreaController', ['$scope', 'Repository', '$stateParams', '$modalStack', '$modal', 'TranslationService', '$location', '$state', 'AreaService', controller]);

	function controller($scope, repository, $stateParams, $modalStack, $modal, translationService, $location, $state, areaService) {
		$scope.update = $scope.navigation.current.name !== 'area.create';
		$scope.isModal = $stateParams.isModal;
		if ($scope.isModal) {
			$scope.viewMode = true;
			areaService.enrichScopeWithFormFunctions($scope);
		}

		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.reloads = {
			deviations: false,
			controlLists: false,
			persons: false,
			bimModels: false,
		};
		$scope.reloadDocumentTable = false;

		var availableHashLocations = ["#info", "#maintenance", "#equipment", "#image"];
		var updateLocation = function () {
			var hash = window.location.hash;
			if (hash && availableHashLocations.includes(hash)) {
				$('#areaTabs a[data-target="' + hash + '"]').tab('show');
				$scope.currentTab = hash.substring(1);
			}
			else {
				$('#areaTabs a[data-target="#info"]').tab('show');
				$scope.currentTab = 'info';
			}
		};

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			updateLocation();
		});
		$scope.setTab = function (tab) {
			$location.hash(tab).replace();
		};

		if ($scope.isUpdate && $stateParams.tab) {
			$scope.setTab($stateParams.tab);
			updateLocation();
		} else {
			updateLocation();
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Area, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Area, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Area);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Area);
			}
		};
		updateAccess();

		if ($scope.update) {
			var columns = [
				'Building.Guid',
				'Building.Id',
				'Building.Description',
				'Building.Estate.Id',
				'Building.Estate.Description',
				'Building.Estate.Guid',
				'Drawing.Guid',
				'Drawing.Id',
				'Drawing.Description',
				'AreaCategory.Description',
				'AreaType.Id',
				'AreaType.Description',
				'CleaningQuality.Id',
				'CleaningQuality.Description',
				'ResourceGroup.Id',
				'ResourceGroup.Description',
				'Person.FirstName',
				'Person.LastName',
				'Organization.Id',
				'Organization.Description',
			];

			repository.getMainModel(repository.apiData.area.url, $scope.model.Guid, JSON.stringify(columns)).then(function (response) {
				$scope.model = response.Data;
				if ($scope.model.IsHousing) {
					// move to correct edit page, and use location "replace" to avoid having this page in browser history
					$state.go('housing.edit', { guid: $scope.model.Guid, model: $scope.model }, { location: 'replace' });
					return;
				}

				updateAccess();
				$scope.model.ComputedGrossArea = JSON.stringify($scope.model.ComputedGrossArea).replace('.', ',');
				$scope.model.ComputedNetArea = JSON.stringify($scope.model.ComputedNetArea).replace('.', ',');

				$scope.reloads.documents = true;
				$scope.reloads.deviations = true;
				$scope.reloads.controlLists = true;
				$scope.reloads.bimModels = true;

				$scope.reloadDocumentTable = true;

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.showError(error);
			});
		} else {
			$scope.model.CommonStatus = "None";
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidAreaCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.areaCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var building = repository.commonService.getFilterData().selectedBuilding;
			if (building.Guid) {
				$scope.model.Building = { Guid: building.Guid, Caption: building.Name };
				$scope.model.GuidBuilding = building.Guid;
			}

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Building':
								$scope.model.Building = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidEstate = prefill.Value.Guid;
								break;
							case 'AreaCategory':
								$scope.model.GuidAreaCategory = prefill.Value.Guid;
								$scope.model.AreaCategory = { Guid: prefill.Value.Guid, Description: prefill.Value.Label, Caption: prefill.Value.Label };
								break;
							case 'CleaningQuality':
								$scope.model.CleaningQuality = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidCleaningQuality = prefill.Value.Guid;
								break;
							case 'ResourceGroup':
								$scope.model.ResourceGroup = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidCleaningTeam = prefill.Value.Guid;
								break;
							case 'Person':
								$scope.model.Person = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
								$scope.model.GuidCleaner = prefill.Value.Guid;
								break;
						}
					} else if (prefill.Value === '@CurrentBuilding') {
						if (building.Guid) {
							$scope.model.Building = { Guid: building.Guid, Caption: building.Name };
							$scope.model.GuidBuilding = building.Guid;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('area.create');

				if (persistentData) {
					$scope.model.GuidBuilding = persistentData.GuidBuilding;

					if (typeof persistentData.Building === "object")
						$scope.model.Building = persistentData.Building;
					else
						$scope.model.Building = { Guid: persistentData.GuidBuilding, Caption: persistentData.Building };
				}
			}
			getCategoryPrefill();
			$scope.activateAutocomplete = true;
		}

		$scope.updateArea = function (destination) {
			if (!$scope.model.ComputedGrossArea)
				$scope.model.ComputedGrossArea = "0";
			if (!$scope.model.ComputedNetArea)
				$scope.model.ComputedNetArea = "0";

			$scope.model.ComputedGrossArea = $scope.model.ComputedGrossArea.replace(',', '.');
			$scope.model.ComputedNetArea = $scope.model.ComputedNetArea.replace(',', '.');

			var success = function (result) {
				repository.growl($scope.update ? 'Rom har blitt oppdatert' : 'Rom har blitt lagret', 'success');
				var areaGuid = $scope.update ? $scope.model.Guid : result.Guid;

				repository.commonService.setLastRegisterGuid(destination, areaGuid);
				if ($scope.isModal && destination === 'area.edit')
					return;
				else if (destination === 'area.edit')
					$scope.navigation.go(destination, { guid: areaGuid }, { reload: true });
				else
					$scope.cancel();
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update) {
				repository.updateSingle(repository.apiData.area.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingle(repository.apiData.area.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.cancel = function () {
			if ($scope.isModal) {
				$modalStack.dismissAll();
			} else {
				$scope.goBack('area.list', { menuGuid: $scope.navigation.params.menuGuid });
			}
		};

		$scope.removeArea = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-area-message', "Rommet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-area-button-confirm', 'Ja, fjern rommet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.area.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-area-success', 'Rommet ble fjernet!'), result, "success");
						repository.growl('Rommet har blitt fjernet', 'success');
						$scope.goBack('area.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.changeId = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/changeIdModal.html',
				controller: 'ChangeIdModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							id: $scope.model.Id
						};
					}
				}
			}).result.then(function (id) {
				if (id) {
					repository.patch(repository.apiData.area.url, $scope.model.Guid, { Id: id }).then(function (result) {
						repository.growl(translationService.translate('web-area-changeId-success', 'Id ble oppdatert'), 'success');
						$scope.model.Id = id;
					}, function (error) {
						repository.showError(error);
					});
				}
			});
		};
	}
})();
