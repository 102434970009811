/*********************************************************
	- Author: Sebastian Cubillos
	- Github: @tianes
	- More Gists: https://gist.github.com/tianes/
	- Contact: sebastian@cubillos.org
	- Article: http://tianes.logdown.com/posts/2015/12/08/rounding-a-number-to-the-nearest-neighbour-up-and-down
**********************************************************/
(function () {
	angular.module('Plania').filter('round', function () {
		/* Use this $filter to round Numbers UP, DOWN and to his nearest neighbour.
		   You can also use multiples */

		/* Usage Examples:
			- Round Nearest: {{ 4.4 | round }} // result is 4
			- Round Up: {{ 4.4 | round:'':'up' }} // result is 5
			- Round Down: {{ 4.6 | round:'':'down' }} // result is 4
			** Multiples
			- Round by multiples of 10 {{ 5 | round:10 }} // result is 10
			- Round UP by multiples of 10 {{ 4 | round:10:'up' }} // result is 10
			- Round DOWN by multiples of 10 {{ 6 | round:10:'down' }} // result is 0
		*/
		return function (value, mult, dir) {
			dir = dir || 'nearest';
			mult = mult || 1;
			value = !value ? 0 : Number(value);
			if (dir === 'up') {
				return Math.ceil(value / mult) * mult;
			} else if (dir === 'down') {
				return Math.floor(value / mult) * mult;
			} else {
				return Math.round(value / mult) * mult;
			}
		};
	});
})();
