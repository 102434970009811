angular.module('Plania').directive('inlinePdf', ['$window', function ($window) {
	return {
	    restrict: 'E',
	    replace: true,
	    scope: {
	        url: '=',
	    },
	    link: function link(scope, element, attrs) {

	        scope.$watch('url', function (newValue, oldValue) {
	            if (newValue === oldValue) return;
	            element[0].innerHTML = '<embed style="width: 100%; height: 100%;" src="' + scope.url + '" type="application/pdf"/>';
	        });

	        $(element[0]).height($('html').height() - 70);

	    },
	    template: '<div><embed src="{{url}}" type="application/pdf"/></div>'

	};
}]);
