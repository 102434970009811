(function () {
	var app = angular.module('Plania');
	app.controller('ControlListItemSelectionModalController', ['$scope', '$modalInstance', 'params', 'TranslationService', controlListItemSelectionModalController]);

	function controlListItemSelectionModalController($scope, $modalInstance, params, translationService) {
		$scope.filter = {};

		var isNullOrEmpty = function (value) {
			return _.isUndefined(value) || _.isNull(value) || value === "";
		};

		$scope.filterGroup = function (group) {
			if (isNullOrEmpty($scope.filter.searchString))
				return true;
			var lowerCaseSearch = $scope.filter.searchString.toLowerCase();
			return _.some(group.items, function (item) {
				var name = item.Name;
				if (!name) name = "";
				name = name.toLowerCase();
				return name.includes(lowerCaseSearch);
			});
		};

		$scope.groups = function () {
			var groups = [{ items: [] }];

			params.controlListItems.forEach(function (item) {
				// Use " and variable for typename to make sure that it is not sent to actual translations
				item.typename = translationService.translate("web-enum-controllistitemtype-" + item.Type, item.Type);

				if (item.Type === "SectionHeader")
					groups.push({ guid: item.Guid, name: item.Name ? item.Name : "", items: [] });
				else if (item.Type !== "Info")
					groups[groups.length - 1].items.push(item);
			});

			// add text on ungrouped section. This happens for the first items that has not yet found a SectionHeader
			if (groups.length > 1 && !groups[0].name)
				groups[0].name = translationService.translate('web-controlListItemSelectionModal-ungrouped-name', 'Ugruppert');

			// Remove ungrouped section if it is empty. This happens when first item is a SectionHeader
			if (groups[0].items.length === 0) {
				groups.shift();
			}

			return groups;
		}();

		$scope.selectItem = function (item) {
			$modalInstance.close(item);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}
}) ();

