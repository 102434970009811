(function () {
	angular.module('Plania').controller('UserGroupController', ['$scope', 'TranslationService', 'Repository', '$modal', '$stateParams', 'ngTableParams', 'ListService', '$localStorage', controller]);

	function controller($scope, translationService, repository, $modal, $stateParams, ngTableParams, listService, $localStorage) {
		$scope.update = $scope.navigation.current.name === 'usergroup.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = $scope.update ? false : true;
		$scope.webProfiles = [];

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.userColumns = [
			{ Position: 1, Title: translationService.translate('web-userGroup-RealName', "Navn"), Property: 'RealName', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-userGroup-UserName', "Brukernavn"), Property: 'Username', PropertyType: 'string' },
		];

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.User, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.User, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.User);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.User);
			}

			$scope.hasProjectManagementModule = $scope.hasModule('ProjectManagement');
		};

		updateAccess();
		setTwoFactorStatus();

		/*user*/
		$scope.AddUsers = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg brukere',
							columns: [
								{ Title: translationService.translate('web-user-username'), Property: 'Username' },
								{ Title: translationService.translate('web-user-realName'), Property: 'RealName' },
							],
							useObjects: false,
							url: repository.apiData.user.url,
							sorting: { Username: 'asc' },
							filter: {},
							alreadySavedGuids: _.map($scope.userTable.data, 'Guid')
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var editedRows = [];
				selectedEntities.forEach(function (guid) {
					editedRows.push({ Guid: guid, GuidUserGroup: $scope.model.Guid });
				});

				repository.patch(repository.apiData.user.url, null, editedRows).then(function (response) {
					var success = _.every(response, function (response) {
						return response.IsSuccess;
					});

					if (success)
						repository.growl(translationService.translate('web-userGroup-addUser-success', 'Brukere har blitt lagt til brukergruppen'), "success");
					else
					{
						repository.growl(translationService.translate('web-userGroup-addUser-failed', 'En eller flere brukere ble ikke lagt til i brukergruppen'), "danger");
						var errorMessages = _.pluck(response, 'ErrorMessage').join(', ');
						swal({ title: "Feil ved lagring", text: errorMessages, type: "warning", closeOnConfirm: true, closeOnCancel: true });
					}

					$scope.userTable.reload();
				}, function (error) {
					repository.showError(error);
				});
			});
		};


		$scope.removeUser = function (user) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-usergroup-user-message', 'Brukeren vil bli fjernet fra gruppen!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-usergroup-user-button-confirm', 'Ja, fjern brukeren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.patch(repository.apiData.user.url, user.Guid, { GuidUserGroup: null }).then(function (response) {
					repository.growl(translationService.translate('web-swal-usergroup-user-success', 'Brukeren ble fjernet!'), 'success');
					$scope.userTable.reload();
					swal.close();
				}, function (error) {
					repository.showError(error);
				});
			});
		};

		/*end user*/

		$scope.userTable = new ngTableParams(
			{
				page: 1,
				count: 10,
				sorting: { 'RealName': 'asc' },
				filter: { PropertyFilter: [{ Property: "GuidUserGroup", Operator: "=", value: $scope.model.Guid }, { Property: "IsUserGroup", Operator: "=", value: 0 }] }
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					var columns = [];
					$scope.userColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.user.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
						.then(function (result) {
							$scope.userTable.settings().total = result.TotalCount;
							$scope.userTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.access = {
			Desktop: false,
			Web: false,
			Mobile: false,
			ReadOnlyWeb: false
		};

		var setUserAccess = function (access) {
			if (access.indexOf("Desktop") >= 0)
				$scope.access.Desktop = true;
			if (access.indexOf("Web") >= 0)
				$scope.access.Web = true;
			if (access.indexOf("Mobil") >= 0)
				$scope.access.Mobile = true;
			if (access.indexOf("ReadOnlyWeb") >= 0)
				$scope.access.ReadOnlyWeb = true;
		};

		var GetUserAccess = function () {
			var access = 0;
			if ($scope.access.Desktop)
				access = access | 1 << 0;
			if ($scope.access.Web)
				access = access | 1 << 1;
			if ($scope.access.Mobile)
				access = access | 1 << 2;
			if ($scope.access.ReadOnlyWeb)
				access = access | 1 << 3;

			return access;
		};

		function setTwoFactorStatus() {
			$scope.twoFactor = {};
			if ($localStorage.generalOptions.TwoFactorAuthenticationOption <= 0) {
				$scope.twoFactor.isEnabled = false;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-usergroup-twofactor-disabled', 'Tofaktor er avskrudd fra system');
			} else if ($localStorage.generalOptions.TwoFactorAuthenticationOption === 2) {
				$scope.twoFactor.isEnabled = true;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-usergroup-twofactor-enabled', 'Tofaktor er påskrudd fra system');
			} else {
				$scope.twoFactor.canChange = true;
			}
		}

		repository.GetPaginated(repository.apiData.webMenu.url, 0, 1000, { Name: "asc" }, {}, '', JSON.stringify(['Name'])).then(function (result) {
            var availableMenus = [];
            availableMenus.push({ text: 'Ingen', guid: null });
			if (result.List.length > 0) {
				result.List.forEach(function (webMenu) {

					if (!webMenu.IsBuildingDependent) {
						availableMenus.push({
							text: webMenu.Name,
							guid: webMenu.Guid
						});
					}
                });
            }
			$scope.availableStartPages = availableMenus;
			if ($scope.model.GuidWebmenu)
				$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || availableMenus[0];
		});


		if ($scope.update) {
			repository.getSingle(repository.apiData.user.url, $stateParams.guid).then(function (result) {
				$scope.model = result.Data;
				$scope.reload = true;
				updateAccess();
				setUserAccess($scope.model.AccessType);

				if ($scope.availableStartPages)
					$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || $scope.availableStartPages[0];

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		} else {
			$scope.model.IsUserGroup = true;
		}

		$scope.GuidChangeLanguage = function (selectedLanguage) {
			$scope.model.GuidLanguage = selectedLanguage.Guid;
		};

		$scope.changeStartPage = function (selected) {
			$scope.model.GuidWebMenu = selected.guid;
		};

		$scope.deleteUserGroup = function (destination, userGroup) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-usergroup-message', 'Brukergruppen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-usergroup-button-confirm', 'Ja, fjern brukergruppen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.user.url, userGroup.Guid)
					.then(function (result) {
						repository.growl(translationService.translate('delete-usergroup-success-message', 'brukergruppen ble slettet fra systemet'), 'success');
						swal.close();
						$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.updateUserGroup = function (destination) {
			$scope.model.AccessType = GetUserAccess();
			var success = function (result) {
				repository.growl($scope.update ? 'Brukergruppe har blitt oppdatert' : translationService.translate('create-userGroup-success', 'Brukergruppen har blitt registrert.'), 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				repository.growl(error, 'danger');
			};

			if ($scope.update)
				repository.updateSingle(repository.apiData.user.url, $scope.model).then(success).catch(error);
			else
				repository.createSingle(repository.apiData.user.url, $scope.model).then(success).catch(error);
		};

		$scope.userProfileInfo = new function () {
			var me = this;

			this.data = [];
			this.totalCount = 0;
			this.search = {};

			this.columns = [
				{ Position: 1, Title: translationService.translate('user-clientTable-dataOwnerName', 'Klient'), Property: 'LinkedDataOwner.Description', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('user-clientTable-userProfileName', 'Profil'), Property: 'EntityPermissionProfile.Id', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('user-clientTable-userProfileDescription', 'Profilbeskrivelse'), Property: 'EntityPermissionProfile.Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('user-clientTable-updatedDate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },
			];

			this.defaultDataOwner = {
				profile: null,
				hasError: false,
				title: "",
				message: "",
			};

			this.getData = function () {
				var columns = [];
				me.columns.forEach(function (col) {
					columns.push(col.Property);
				});
				columns.push("IsDefaultDataOwner");
				var filter = { FilterModel: { Property: 'GuidUser', Operator: '=', Value: $scope.model.Guid } };
				repository.GetPaginated(repository.apiData.userProfile.url, 0, -1, null, filter, null, JSON.stringify(columns)).then(function (result) {
					me.data = result.List;
					me.totalCount = result.TotalCount;
					me.table.reload();

					var defaultDataOwner = _.filter($scope.userProfileInfo.data, { IsDefaultDataOwner: true })[0];
					if (defaultDataOwner) {
						if (!defaultDataOwner.GuidEntityPermissionProfile) {
							me.defaultDataOwner = {
								hasError: true,
								status: "missingEntityPermission"
							};
						} else {
							var caption = "";
							if (defaultDataOwner.LinkedDataOwner.Description)
								caption += defaultDataOwner.LinkedDataOwner.Description;
							if (defaultDataOwner.EntityPermissionProfile.Id) {
								if (caption)
									caption += " - ";
								caption += defaultDataOwner.EntityPermissionProfile.Id;
							}

							me.defaultDataOwner = {
								profile: defaultDataOwner,
								hasError: false,
								status: "ok",
								caption: caption,
							};
						}
					} else if (me.data.length) {
						me.defaultDataOwner = {
							hasError: true,
							status: "missingUserProfiles"
						};
					} else {
						me.defaultDataOwner = {
							hasError: true,
							status: "missingDefaultDataOwner"
						};
					}
				}, function (error) {
					repository.showError(error);
				});
			};

			var getValue = function (entity, property) {
				var value = entity;
				var properties = property.split(".");
				properties.forEach(function (prop) {
					if (value !== null && value !== undefined) {
						value = value[prop];
					}
				});
				return value;
			};

			var getFilteredData = function (filter) {
				var data = me.data;

				if (filter.searchString === undefined) return data;
				var filterText = filter.searchString.toLowerCase();

				data = data.filter(function (item) {
					if (filterText === undefined || filterText === null || filterText.trim() === "")
						return true;

					var state = false;

					me.columns.forEach(function (column) {
						var value = getValue(item, column.Property);
						if (("" + value).toLowerCase().includes(filterText))
							state = true;
						if (state)
							return;
					});

					return state;
				});

				return data;
			};

			this.initTable = function () {
				if (me.table) return;

				me.table = new ngTableParams({
					page: 1,
					count: 20,
					filter: { searchParams: "" },
					sorting: { "LinkedDataOwner.Description": "asc" }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [20, 50, 100, 200],
						getData: function ($defer, params) {
							var data = getFilteredData(params.filter());
							var sortingKey = Object.keys(params.sorting())[0];
							if (sortingKey) {
								data = _.sortBy(data, sortingKey);
								var direction = params.sorting()[sortingKey];
								if (direction.toLowerCase() === "desc")
									data = data.reverse();
							}

							me.table.settings().total = data.length;
							me.table.settings().filterDelay = 500;

							data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());

							$defer.resolve(data);
						}
					});
			};

			this.add = function () {
				if (!me.canAdd()) return;

				$modal.open({
					templateUrl: 'app/user/views/editUserDataOwner.html',
					controller: 'CreateUserDataOwnerController',
					resolve: {
						params: function () {
							return {
								guidUser: $scope.model.Guid,
								isFirst: me.data.length < 1
							};
						}
					}
				}).result.then(function (userProfile) {
					if (userProfile && userProfile.IsDefaultDataOwner) {
						$scope.model.GuidDefaultDataOwner = userProfile.GuidLinkedDataOwner;
					}
					me.getData();
				});
			};

			this.update = function (entity) {
				if (!me.canEdit(entity)) return;

				if ($scope.restrictEdit || $scope.model.GuidUserGroup) return;

				$modal.open({
					templateUrl: 'app/user/views/editUserDataOwner.html',
					controller: 'EditUserDataOwnerController',
					resolve: {
						params: function () {
							return {
								guidUserProfile: entity.Guid
							};
						}
					}
				}).result.then(function (userProfile) {
					if (userProfile && userProfile.IsDefaultDataOwner) {
						$scope.model.GuidDefaultDataOwner = userProfile.GuidLinkedDataOwner;
					}
					me.getData();
				});
			};

			this.remove = function (entity) {
				if (!me.canDelete(entity)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-usergroup-dataOwner-message', 'Klienten vil bli fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-usergroup-dataOwner-button-confirm', 'Ja, fjern klienten!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.userProfile.url, entity.Guid).then(function (result) {
						swal.close();
						repository.growl(translationService.translate('usergroup-removeDataOwner-Success', 'Klient ble fjernet fra brukergruppen.'), 'success');
						me.getData();
					});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-user-userProfile-edit', 'Rediger klienttilgang') + '</a >',
						click: function () {
							me.update(item);
						}
					});
				}

				if ($scope.hasReadAccess('DataOwner')) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href="' + $scope.navigation.href('dataOwner.edit', {guid: item.GuidLinkedDataOwner}) + '" >' +
							'<i class="fas fa-suitcase fa-hc-fw fa-hc-lg" style="visibility: hidden"></i> ' + translationService.translate('web-user-viewDataOwner', 'Gå til klient') + '</a>',
						isHref: true
					});
				}

				if ($scope.hasReadAccess('EntityPermissionProfile')) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href="' + $scope.navigation.href('entityPermissionProfile.edit', {guid: item.GuidEntityPermissionProfile}) + '" >' +
							'<i class="fas fa-suitcase fa-hc-fw fa-hc-lg" style="visibility: hidden"></i> ' + translationService.translate('web-user-viewEntityPermissionProfile', 'Gå til rettighetsprofil') + '</a>',
						isHref: true
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-user-dataOwner-delete', 'Slett klient') + '</a >',
						click: function () {
							me.remove(item);
						},
						hasTopDivider: true
					});
				}


				return options;
			};

			this.canAdd = function () {
				return !$scope.restrictEdit && !$scope.model.GuidUserGroup;
			};

			this.canEdit = function (entity) {
				return entity && !$scope.restrictEdit && !$scope.model.GuidUserGroup;
			};

			this.canDelete = function (entity) {
				return entity && !$scope.restrictEdit && !$scope.model.GuidUserGroup;
			};

			if ($scope.update) {
				me.initTable();
				me.getData();
			}
		}();

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
