(function () {

	var app = angular.module('Plania');

	app.directive('projectProgress', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				viewMode: '=viewMode',
				update: '=update',
				restrictEdit: '=restrictEdit',
				isModelLoading: '=isModelLoading',
				currentPage: '=currentPage'
			},
			controller: ['$scope', '$rootScope', 'Repository', 'TranslationService', 'ListService', 'ngTableParams', '$modal', '$timeout', 'WorkOrderService', '$q', controller],
			templateUrl: 'app/project/views/projectProgressDirective.html'
		};
	});

	function controller($scope, $rootScope, repository, translationService, listService, ngTableParams, $modal, $timeout, workOrderService, $q) {
		$scope.rootScope = $rootScope;
		$scope.workOrderSetting = {};
		$scope.periodicTaskSettings = {};
		$scope.search = {};
		$scope.isWorkOrderTableLoading = true;
		$scope.hasVisitedTab = $scope.currentPage === 'progress';
		var user = repository.authService.getUserData();

		//todo dyplicated with other project directives
		var getKpis = function () {

			var totalWorkOrderKpi = {
				Prefix: 'WorkOrder',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }],
				Properties: [{
					Property: 'EndDate',
					Function: 'count'
				}, {
					Property: 'CompletionRate',
					Function: 'average'
				}]
			};

			repository.createSingle(repository.apiData.kpi.url, totalWorkOrderKpi).then(function (result) {
				$scope.totalWorkOrders = result[0].Result[0].Value;
				$scope.totalAverageCompletionPercent = Number((result[0].Result[1].Value * 100).toFixed(2));
			});

		};

		getKpis();
		//todo duplicated with other project directives

		$scope.getProgressType = function (percent) {
			var type = 'gray';
			if (percent < 10) {
				type = 'red';
			} else if (percent < 50) {
				type = 'yellow';
			} else if (percent < 100) {
				type = 'cyan';
			} else if (percent === 100) {
				type = 'green';
			}

			return type;
		};

		$scope.workOrderFilters = {
			active: true,
			project: true,
			history: false
		};

		var setFiltername = function () {
			var activeFilters = [];
			if ($scope.workOrderFilters.active)
				activeFilters.push(translationService.translate('web-enum-WorkOrderStage-Active', 'Aktiv'));
			if ($scope.workOrderFilters.project)
				activeFilters.push(translationService.translate('web-enum-WorkOrderStage-project', 'Prosjekt'));
			if ($scope.workOrderFilters.history)
				activeFilters.push(translationService.translate('web-enum-WorkOrderStage-history', 'Historikk'));

			$scope.selectedFilterName = activeFilters.join(', ');
		};
		setFiltername();

		$scope.setStageOptions = function (stage) {

			switch (stage) {
				case 'active':
					$scope.workOrderFilters.active = !$scope.workOrderFilters.active;
					break;
				case 'project':
					$scope.workOrderFilters.project = !$scope.workOrderFilters.project;
					break;
				case 'history':
					$scope.workOrderFilters.history = !$scope.workOrderFilters.history;
					break;
			}
			setFiltername();
			$scope.workOrderTable.reload();
		};

		var verifyWithUser = function () {
			var deferred = $q.defer();

			swal({
				title: translationService.translate('web-swal-project-modify-workOrder-warning', 'Dette kan påvirke prosjektets økonomi, ønsker du å fortsette?'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-button-yes', 'Ja'),
				cancelButtonText: translationService.translate('web-button-no', 'Nei'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function (isConfirm) {
				window.onkeydown = null;
				window.onfocus = null;
				if (isConfirm) {
					swal.close();
					deferred.resolve();
				}
				else
					deferred.reject();
			});

			return deferred.promise;
		};

		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create', {
				model: {
					DueDate: $scope.model.EndDate
				},
				guidEstate: $scope.model.Estate ? $scope.model.Estate.Guid : null,
				estate: $scope.model.Estate ? $scope.model.Estate.Caption : null,
				guidBuilding: $scope.model.GuidBuilding,
				building: $scope.model.Building ? $scope.model.Building.Caption : null,
				guidArea: $scope.model.GuidArea,
				area: $scope.model.Area ? $scope.model.Area.Caption : null,
				guidEquipment: $scope.model.GuidEquipment,
				equipment: $scope.model.Equipment ? $scope.model.Equipment.Caption : null,
				guidProject: $scope.model.Guid,
				project: $scope.model.Caption
			});
			$rootScope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		$scope.copyWorkOrder = function (guidWorkOrder) {

			if (!guidWorkOrder) {
				if ($scope.selectedWorkOrders.length < 1) {
					swal('Ingen valg', translationService.translate('web-project-progrss-copyWorkOrder-missingSelect', 'Velg en arbeidsordre i listen for å kopiere.'), 'error');
					return;
				} else if ($scope.selectedWorkOrders.length > 1) {
					swal('Ingen valg', translationService.translate('web-project-progrss-copyWorkOrder-toomanyselected', 'Du kan kun kopiere én arbeidsordre om gangen.'), 'error');
					return;
				}
				guidWorkOrder = $scope.selectedWorkOrders[0];
			}

			repository.persistedData.setPersistedData('workOrder.create', {
				model: {
					DueDate: $scope.model.EndDate
				},
				guidEstate: $scope.model.Estate ? $scope.model.Estate.Guid : null,
				estate: $scope.model.Estate ? $scope.model.Estate.Caption : null,
				guidBuilding: $scope.model.GuidBuilding,
				building: $scope.model.Building ? $scope.model.Building.Caption : null,
				guidArea: $scope.model.GuidArea,
				area: $scope.model.Area ? $scope.model.Area.Caption : null,
				guidEquipment: $scope.model.GuidEquipment,
				equipment: $scope.model.Equipment ? $scope.model.Equipment.Caption : null,
				guidProject: $scope.model.Guid,
				project: $scope.model.Caption
			});

			$rootScope.navigation.go('workOrder.create', { guidEntityToCopy: guidWorkOrder, showPersistantData: true }, { reload: true });
		};

		$scope.moveWorkOrders = function () {
			if (!$scope.isProjectAdministrator) return;
			if (!$scope.selectedWorkOrders || $scope.selectedWorkOrders.length === 0) {
				swal(
					'Ingen valg',
					translationService.translate('web-list-connectProject-edit-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å koble til prosjekt'),
					'error');
			} else {
				var filter = {
					FilterModel: {
						Condition: "and",
						Rules: [
							{ Property: 'ClosedDate', Operator: '=', value: null },
							{ Property: 'IsTemplate', Operator: '=', value: 0 },
							{ Property: 'Guid', Operator: '<>', value: $scope.model.Guid },
						]
					}
				};

				if (!user.isSystemAdministrator && !user.isProjectAdministrator) {
					filter.FilterModel.Rules.push({
						Condition: "or",
						Rules: [
							{ Property: 'GuidOwnerPerson', Operator: '=', value: user.guidPerson },
							{ Property: 'GuidManagerPerson', Operator: '=', value: user.guidPerson },
						]
					});
				}

				verifyWithUser().then(function () {
					$modal.open({
						templateUrl: 'app/common/views/singleSelectModal.html',
						controller: 'SingleSelectModalController',
						size: 'lg',
						resolve: {
							modalParams: function () {
								return {
									title: translationService.translate('web-workOrder-connect-project', 'Velg prosjekt å koble arbeidsordrene til'),
									url: repository.apiData.project.url,
									sorting: { 'CreationDate': 'desc' },
									filter: filter,
									columns: [
										{ Title: translationService.translate('web-project-Id', 'Prosjekt Nr'), Property: 'Id' },
										{ Title: translationService.translate('web-project-Description', 'Beskrivelse'), Property: 'Description' },
										{ Title: translationService.translate('web-project-Building-Id', 'Bygg Id'), Property: 'Building.Id' },
										{ Title: translationService.translate('web-project-Building-Description', 'Byggbeskrivelse'), Property: 'Building.Description' },
									],
								};
							}
						}
					}).result.then(function (selectedEntity) {
						repository.updateMultipleWithGuid(repository.apiData.project.endpoint.addWorkOrders, selectedEntity.Guid, $scope.selectedWorkOrders)
							.then(function (result) {
								repository.growl(translationService.translate('web-project-connect-workOrder-success', 'Arbeidsordrene har blitt koblet til prosjektet'), 'success');
								$scope.workOrderTable.reload();
							}, function (error) {
								swal(translationService.translate('web-project-connectWorkOrders-error-title', 'En eller flere feiler oppstod under kobling til prosjekt'), error, 'error');
								$scope.workOrderTable.reload();
							});
					});

				});
			}
		};

		$scope.connectWorkOrders = function () {
			if (!$scope.isProjectAdministrator) return;

			var filter = {
				FilterModel: {
					Condition: "and",
					Rules: [
						{ Property: 'GuidProject', Value: $scope.model.Guid, Operator: '<>' },
						{ Property: 'Stage', Value: 2, Operator: '<>' }
					]
				},
				ActivityStatus: 'Undefined'
			};

			if (!user.isSystemAdministrator && !user.isProjectAdministrator) {
				filter.FilterModel.Rules.push({
					Condition: "or",
					Rules: [
						{ Property: 'GuidProject', Value: null, Operator: '=' },
						{ Property: 'Project.GuidOwnerPerson', Operator: '=', value: user.guidPerson },
						{ Property: 'Project.GuidManagerPerson', Operator: '=', value: user.guidPerson },
					]
				});
			}

			verifyWithUser().then(function () {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					size: 'lg',
					resolve: {
						modalParams: function () {
							return {
								title: 'Velg arbeidsordrer',
								columns: [
									{ Title: translationService.translate('web-workOrder-id', 'AO Nr'), Property: 'Id' },
									{ Title: translationService.translate('web-workOrder-description', 'Ao Beskrivelse'), Property: 'Description' },
									{ Title: translationService.translate('web-workOrder-Building-Id', 'Bygg Id'), Property: 'Building.Id' },
									{ Title: translationService.translate('web-workOrder-Building-Description', 'Byggbeskrivelse'), Property: 'Building.Description' },
									{ Title: translationService.translate('web-workOrder-Project-Id', 'Prosjekt Id'), Property: 'Project.Id' },
									{ Title: translationService.translate('web-workOrder-Project-Description', 'Prosjektbeskrivelse'), Property: 'Project.Description' }
								],
								url: repository.apiData.workOrder.url,
								sorting: { Id: 'desc' },
								filter: filter
							};
						}
					}
				}).result.then(function (selectedEntities) {
					repository.updateMultipleWithGuid(repository.apiData.project.endpoint.addWorkOrders, $scope.model.Guid, selectedEntities)
						.then(function (result) {
							repository.growl(translationService.translate('web-project-connect-workOrder-success', 'Arbeidsordrene har blitt koblet til prosjektet'), 'success');
							$scope.workOrderTable.reload();
						}, function (error) {
							swal(translationService.translate('web-project-connectWorkOrders-error-title', 'En eller flere feiler oppstod under kobling til prosjekt'), error, 'error');
							$scope.workOrderTable.reload();
						});
				});
			});
		};

		$scope.disconnectWorkOrders = function () {
			if (!$scope.isProjectAdministrator) return;
			if (!$scope.selectedWorkOrders || $scope.selectedWorkOrders.length === 0) {
				swal(
					'Ingen valg',
					translationService.translate('list-noselectedrows', 'Ingen valg. Velg en eller flere rader fra listen for å utføre handlingen'),
					'error');
				return;
			}

			verifyWithUser().then(function () {
				repository.updateMultipleWithGuid(repository.apiData.project.endpoint.removeWorkOrders, $scope.model.Guid, $scope.selectedWorkOrders)
					.then(function (result) {
						repository.growl(translationService.translate('web-project-disconnect-workOrder-success', 'Arbeidsordrene har blitt koblet fra prosjektet'), 'success');
						$scope.workOrderTable.reload();
					}, function (error) {
						swal(translationService.translate('web-project-connectWorkOrders-error-title', 'En eller flere feiler oppstod under kobling til prosjekt'), error, 'error');
						$scope.workOrderTable.reload();
					});
			});
		};

		$scope.updateCompletionRate = function (workOrder) {
			if (workOrder.Stage === 'History' || $scope.model.ClosedDate) return;

			var updateCompletionRate = function () {
				repository.runAction(repository.apiData.workOrder.endpoint.setCompletionRate + workOrder.Guid, null, "completionRate=" + (workOrder.CompletionRate / 100)).then(function (result) {
					repository.growl(translationService.translate('web-project-workorder-completionrateupdated', 'Arbeidsordren ble oppdatert'), 'success');
					workOrder.showEditDialog = false;
					$scope.workOrderTable.reload();
					getKpis();
				}).catch(errorCallback);
			};


			var errorCallback = function (error) {
				if (error && workOrderService.isWorkOrderStage0(error)) {
					workOrderService.shouldActivateWorkOrder(workOrder.Guid, error)
						.then(function (result) {
							updateCompletionRate();
						}, function (error) {
							if (error)
								repository.growl(error);
						});
				}
				else
					repository.growl(error.Message || error, 'danger');
			};

			updateCompletionRate();
		};

		var originalPercent = 0;
		$scope.showWorkOrderEditDialog = function (guid) {
			var workOrders = [];
			if ($scope.workOrderSetting.workOrderListSetup.GroupBy) {
				$scope.workOrderTable.data.forEach(function (grp) {
					if (grp && grp.data)
						grp.data.forEach(function (d) { workOrders.push(d); });
				});
			} else {
				workOrders = $scope.workOrderTable.data;
			}

			workOrders.forEach(function (workOrder) {
				if (workOrder.EndDate || !$rootScope.hasEditAccess('WorkOrder')) return;
				if (workOrder.Stage === 'History' || $scope.model.ClosedDate) return;

				if (workOrder.Guid === guid) {
					workOrder.showEditDialog = true;
					originalPercent = angular.copy(workOrder.CompletionRate);
				} else
					workOrder.showEditDialog = false;
			});
		};

		$scope.cancelEditDialog = function (workOrder) {
			workOrder.CompletionRate = originalPercent;
			originalPercent = 0;
			workOrder.showEditDialog = false;
		};

		$scope.selectedWorkOrders = [];
		$scope.allWorkOrdersSelected = false;
		$scope.activateWorkOrders = function (guid) {
			if ($scope.model.ClosedDate)
				return false;
			if (!guid && (!$scope.selectedWorkOrders || $scope.selectedWorkOrders.length === 0)) {
				swal(
					'Ingen valg',
					translationService.translate('list-noselectedrows', 'Ingen valg. Velg en eller flere rader fra listen for å utføre handlingen'),
					'error');
				return;
			}

			workOrderService.activateWorkOrders(guid ? [guid] : $scope.selectedWorkOrders)
				.then(function (result) {
					$scope.workOrderTable.reload();
					repository.growl(translationService.translate('web-workorder-activate-success', 'Arbeidsordren er aktivert'), 'success');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
		};

		//WorkOrder
		listService.getWebListViews(null, repository.apiData.workOrder.prefix, false, null, {}).then(function (result) {
			$scope.workOrderSetting.workOrderListOptions = result.listOptions;
			$scope.workOrderSetting.workOrderListSetup = result.selectedListSetup;

			var filter = {
				PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }]
			};

			$scope.workOrderTable = new ngTableParams(
				{ page: 1, count: -1, sorting: { 'Id': 'desc' }, filter: filter },
				{
					total: 0,
					filterDelay: 10,
					counts: [],
					getData: function ($defer, params) {
						var columns = [];
						$scope.workOrderSetting.workOrderListSetup.Columns.forEach(function (col) {
							columns.push(col.Property);
						});

						var filter = angular.copy(params.filter());
						var propertyFilter = filter.PropertyFilter;
						if (propertyFilter)
							_.remove(propertyFilter, function (obj) { return obj.Property === 'Stage'; });
						filter.PropertyFilter = propertyFilter;
						filter.ActivityStatus = 'Undefined';
						filter.Stages = [];
						if ($scope.workOrderFilters.active)
							filter.Stages.push("Active");
						if ($scope.workOrderFilters.project)
							filter.Stages.push("Project");
						if ($scope.workOrderFilters.history)
							filter.Stages.push("History");

						if (!_.some(columns, 'CompletionRate'))
							columns.push('CompletionRate');
						if (!_.some(columns, 'Stage'))
							columns.push('Stage');

						repository.GetPaginated(repository.apiData.workOrder.url, params.page() - 1, -1, params.sorting(), filter, null, JSON.stringify(columns)).then(
							function (result) {
								$scope.workOrderTable.settings().total = result.TotalCount;
								$scope.workOrderTable.settings().filterDelay = 500;

								$scope.averageCompletionPercent = 0;
								result.List.forEach(function (workOrder) {
									workOrder.CompletionRate = Number((workOrder.CompletionRate * 100).toFixed(0));
									$scope.averageCompletionPercent += workOrder.CompletionRate;
								});

								$scope.averageCompletionPercent = $scope.averageCompletionPercent / result.List.length;

								var dates = result.List.filter(function (row) { return row.DueDate; }).map(function (row) {
									if (row.DueDate)
										return new Date(row.DueDate);
									else return new Date();
								});

								if (dates.length > 0) {
									var latestRegisteredDate = new Date(Math.max.apply(null, dates));
									if (latestRegisteredDate.getTime() > $scope.telerikInfo.endDate.getTime())
										$scope.telerikInfo.endDate = latestRegisteredDate;
								}

								$scope.isWorkOrderTableLoading = false;

								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		});

		// Periodic Tasks
		if ($scope.model.IsTemplate) {
			$scope.periodicTaskColumns = [
				{ Position: 1, Title: translationService.translate('web-projectTemplate-periodicTask-id', 'Id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-projectTemplate-periodicTask-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-projectTemplate-periodicTask-resourceGroup-Id', 'Ressurs Id'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-projectTemplate-periodicTask-department-Id', 'Avdeling Id'), Property: 'Department.Id', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-projectTemplate-periodicTask-activityCategory-Description', 'Kategori'), Property: 'ActivityCategory.Description', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-projectTemplate-periodicTask-activityCategory-NbPurchaseOrderItem', 'Bestillingsobjekter'), Property: 'NbPurchaseOrderItem', PropertyType: 'bool' }
			];

			var periodicTaskFilter = {
				IncludeOnProject: true,
				PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }],
				PeriodicTaskTemplateType: "WorkOrderTemplate"
			};

			$scope.periodicTaskTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Description': 'asc' },
				filter: periodicTaskFilter
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 30],
					getData: function ($defer, params) {
						var columns = [];
						$scope.periodicTaskColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.periodicTask.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
							function (result) {
								$scope.periodicTaskTable.settings().total = result.TotalCount;
								$scope.periodicTaskTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});

			$scope.createPeriodicTask = function () {
				repository.persistedData.setPersistedData('periodicTask.create', {
					model: {
						DueDate: $scope.model.EndDate
					},
					guidProject: $scope.model.Guid,
					project: $scope.model.Caption
				});

				$rootScope.navigation.go('workOrderTemplate.create', { showPersistantData: true }, { reload: true });
			};
		}

		$scope.selectAllRows = function (table, selectedList, allSelectedProperty) {
			if ($scope[allSelectedProperty]) {
				table.data.forEach(function (row) {
					var index = _.findIndex(selectedList, function (o) { return o === row.Guid; });
					selectedList.splice(index, 1);
				});

				$scope[allSelectedProperty] = false;
			} else {
				table.data.forEach(function (row) {
					if (!_.contains(selectedList, row.Guid)) {
						selectedList.push(row.Guid);
					}
				});
				$scope[allSelectedProperty] = true;
			}
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.itemIsSelected = function (guid, selectedList) {
			return _.contains(selectedList, guid);
		};

		$scope.selectItem = function (guid, selectedList) {
			if (!_.contains(selectedList, guid))
				selectedList.push(guid);
			else {
				for (var i = 0; i < selectedList.length; i++) {
					if (selectedList[i] === guid) {
						selectedList.splice(i, 1);
						return;
					}
				}
			}
		};

		// Watchers
		$scope.$watch('currentPage', function (newVal, oldVal) {
			if (newVal === oldVal || newVal !== 'progress') return;
			if (newVal === 'progress') {
				$scope.telerikInfo.reloadReports = !$scope.telerikInfo.reloadReports;
				$scope.hasVisitedTab = true;
			}
		});

		var loadingListener = $scope.$watchGroup(['isWorkOrderTableLoading', 'isModelLoading'],
			function () {
				if ($scope.isModelLoading === false && $scope.isWorkOrderTableLoading === false) {
					$scope.telerikInfo.updateTelerikParameters();
					loadingListener(); // Clear watcher

					if (($scope.model.ClosedDate || $scope.model.ClosedDate) && !$scope.model.IsTemplate && $scope.workOrderFilters.history === false)
						$scope.setStageOptions('history');

					$scope.isProjectAdministrator =
						$scope.model.GuidOwnerPerson === user.guidPerson ||
						$scope.model.GuidManagerPerson === user.guidPerson ||
						user.isSystemAdministrator ||
						user.isProjectAdministrator;
				}
			}, true);

		$scope.$watch('workOrderSetting.workOrderListSetup', function (newValue, oldValue) {
			if (oldValue === newValue)
				return;

			if ($scope.workOrderTable) {
				$scope.workOrderTable.$params.sorting = $scope.workOrderSetting.workOrderListSetup.Sorting;

				if ($scope.workOrderSetting.workOrderListSetup.GroupBy) {
					$scope.workOrderTable.settings().groupBy = function (obj) {
						if (!$scope.workOrderSetting.workOrderListSetup.GroupBy) {
							return false;
						}

						var attrs = $scope.workOrderSetting.workOrderListSetup.GroupBy.split('.');
						attrs.forEach(function (attr) {
							if (!obj) {
								obj = "";
							} else {
								obj = obj[attr];
							}
						});

						return obj;
					};
				} else {
					$scope.workOrderTable.settings().groupBy = null;
				}

				$scope.workOrderTable.reload();
			}
		});

		// Infos
		$scope.telerikInfo = new function () {
			var me = this;
			this.parameters = { GuidProject: $scope.model.Guid };
			this.displayReports = false;
			this.endDate = new Date();
			this.reloadReports = false;

			this.updateTelerikParameters = function () {
				if ($scope.model.IsTemplate) return;
				var endDate = $scope.model.EndDate ? new Date($scope.model.EndDate) : null;
				var startDate = $scope.model.StartDate ? new Date($scope.model.StartDate) : new Date();
				if (endDate === null || endDate === undefined) {
					endDate = $scope.telerikInfo.endDate;
				}

				if (endDate.getTime() < startDate.getTime())
					endDate = $scope.model.StartDate;

				$scope.telerikInfo.parameters.StartDate = startDate;
				$scope.telerikInfo.parameters.EndDate = endDate;
				$scope.telerikInfo.displayReports = true;
			};
		}();

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		$scope.menuOptions = function (workOrder) {
			var options = [];

			options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: workOrder.Guid }), 'fa-suitcase', translationService.translate('web-open-workorder', 'Åpne arbeidsordre')));

			if ($scope.rootScope.hasCreateAccess('WorkOrder')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="fas fa-copy fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-project-workOrderContextMenu-copy', 'Kopier arbeidsordre') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.copyWorkOrder(workOrder.Guid);
					}
				});
			}

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="fas fa-file fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('print-tooltip-details', 'Print detaljer') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.selectItem(workOrder.Guid, $scope.selectedWorkOrders);
					// Apparently we meed to break out of the current $apply cycle to trigger a click programatically
					$timeout(function () {
						angular.element('#printWorkOrderDetails a').triggerHandler('click');
						$scope.selectItem(workOrder.Guid, $scope.selectedWorkOrders);
					}, 0);
				}
			});

			if (workOrder.Stage === 'Project' && !$scope.model.IsTemplate && !$scope.model.ClosedDate) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-paper-plane-top fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-workorder-list-activateWorkOrders', 'Aktiver arbeidsordre') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.activateWorkOrders(workOrder.Guid);
					}
				});
			}

			if (workOrder.Stage !== 'Project' &&
				$scope.rootScope.hasCreateAccess('Cost') &&
				workOrder.Status !== 'Proposal' &&
				workOrder.Status !== 'Closed') {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-receipt fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-createCost', 'Legg til kostnad') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						var persistedData = {};
						persistedData.GuidWorkOrder = workOrder.Guid;
						repository.persistedData.setPersistedData('cost.create', persistedData);
						$scope.$parent.navigation.go('cost.create', { showPersistantData: true }, { reload: true });
					}
				});
			}
			return options;
		};

	}
})();
