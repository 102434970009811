(function () {
	angular.module('Plania').controller('PersonRoleController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'personRole.edit';

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.personRole.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-personRole-success', 'Personrolle har blitt oppdatert.') : translationService.translate('create-personRole-success', 'Personrolle har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.personRole.url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData.personRole.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-personrole-message', "Personrollen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-personrole-button-confirm', 'Ja, fjern Personrollen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.personRole.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-personrole-success', 'Personrollen ble fjernet!'), result, "success");
						repository.growl('Personrollen har blitt fjernet', 'success');
						$scope.goBack('personRole.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};
	}

})();
