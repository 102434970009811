/*
	step: {
		title: string // Title to be shown for the step
		onClick?: (step) => void // Click handler for specific step. Will override onStepClick, if it is used.
		stepIndex: number // Indexing for position and what will show in bubble
		hidden?: boolean
	}
*/
(function () {
	angular.module('Plania').directive('plWizardStepper', function () {
		return {
			restrict: 'E',
			scope: {
				steps: '=steps', // List of steps (se above for properties). Can send custom properties, which will be sent back on clicks.
				currentStep: '=currentStep', // Object of current step.
				onStepClick: '=onStepClick', // Common click handler for steps. Will return what step was clicked, except if the clicked step was same as currentStep.stepIndex.
				completedStepIndex: '=completedStepIndex', // Number, what index the wizard has come to. If currentStep.stepIndex is lower, then this will be used to color active steps.
			},
			controller: ['$scope', 'Repository', '$window', 'TranslationService', 'config', controller],
			templateUrl: 'app/common/directives/views/plWizardStepper.html'
		};
	});

	var controller = function ($scope, repository, $window, translationService, config) {
		$scope.stepClick = function (step) {
			if (step.stepIndex === $scope.currentStep.stepIndex) return;
			if (step.onClick && typeof (step.onClick) === "function") step.onClick(step);
			else if ($scope.onStepClick && typeof ($scope.onStepClick) === "function") $scope.onStepClick(step);
		};

		$scope.stepFilter = function (step) {
			return !step.hidden;
		};

	};
})();
