(function () {
	angular.module('Plania').controller('PlaniaSystemController', ['$scope', 'Repository', 'GeneralOptionsService', controller]);

	function controller($scope, repository, generalOptionsService) {

		$scope.model = {};
		repository.getSingleWithNoParameter(repository.apiData.planiaSystem.url).then(
			function (response) {
				$scope.reload = true;
				$scope.model = response;

				$scope.packageArray = $scope.model.License.Packages.split(', ');
				$scope.moduleArray = $scope.model.License.Modules.split(', ');
			},
			function (error) {
				repository.growl(error, 'danger');
			});

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Undefined)
			.then(function (result) {
				$scope.modules = result;
			});

		$scope.hasPackage = function (plPackage) {
			return _.contains($scope.packageArray, plPackage);
		};

		$scope.hasModule = function (plModule) {
			return _.contains($scope.moduleArray, plModule);
		};

		$scope.getModuleIcon = function (moduleName) {
			return repository.moduleService.getModuleIcon(moduleName);
		};

		generalOptionsService.refreshGeneralOptions();

		$scope.hasModule = $scope.$root.hasModule;

		$scope.exceededLicense = function (usedAmount, licensedAmount) {
			if (!usedAmount) return false;
			return parseInt(usedAmount) > parseInt(licensedAmount);
		};
	}
})();
