(function () {
    'use strict';

	angular.module('Plania').directive('planiaDecimal', ['$filter', planiaDecimal]);

	// Makes an input field type="text" behave as type="number", but supporting both comma and punctuation which is buggy in Chrome
	function planiaDecimal($filter) {
        return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				maxDecimals: '=maxDecimals'
			},
			link: function (scope, element, attrs, ngModelController) {

				function updateView(hasFocus) {
					if (!ngModelController.$modelValue) return;

					var displayValue = hasFocus ?
						ngModelController.$modelValue :
						$filter('number')(ngModelController.$modelValue, scope.maxDecimals);
					rawElement.value = displayValue;
				}

				if (scope.maxDecimals !== undefined && scope.maxDecimals >= 0) {
					var rawElement = element[0];

					element.on('focus', updateView.bind(null, true));
					element.on('blur', updateView.bind(null, false));

					scope.$watch('viewMode', function () {
						updateView(false);
					});

					// Hacky way to format the input when the page, or tab content loads.
					var loadListener = scope.$watch(attrs.ngModel, function (newValue) {
						if (newValue !== undefined && !Number.isNaN(newValue)) {
							try {
								updateView($(element).is(":focus"));
							} catch (ex) { }
							// Unregister self
							loadListener();
						}
					});
				}

				//convert data from view format to model format
				ngModelController.$parsers.push(function (input) {
					if (!input) return input;

					// Remove all charachters except: numeric, comma, punctuation and minus sign
					var transformedInput = input.replace(/[^\d.,-]/g, '');

					if (transformedInput !== input) {
						ngModelController.$setViewValue(transformedInput);
						ngModelController.$render();
					}

					// replacing comma with punctuation only for the ngModel object, so we can still display comma
					var ret = transformedInput.toString().trim().replace(',', '.');

					return parseFloat(ret);
				});

				//convert data from model format to view format
				ngModelController.$formatters.push(function (input) {
					var ret = (input) ? input.toString().replace('.', ',') : null;
					return ret;
				});
			}
        };
    }
})();
