(function () {

    angular.module('Plania').controller('EditWorkOrderXAreaController', ['$scope', 'Repository', 'params', '$modalInstance', controller]);

    function controller($scope, repository, params, $modalInstance) {
        $scope.model = params.WorkOrderXArea;

        if ($scope.model.WorkOrderXAreaDto && $scope.model.WorkOrderXAreaDto.AreaInfo.Id) {
            var areaId = $scope.model.WorkOrderXAreaDto.AreaInfo.Id ?
                $scope.model.WorkOrderXAreaDto.AreaInfo.Id + ' - ' : null;
            $scope.area = areaId + ($scope.model.WorkOrderXAreaDto.AreaInfo.Description || '') + '\n';
        }

        $scope.building = params.buildingInfo;

        $scope.saveAction = function () {
            if ($scope.model.DeadlineDate)
                $scope.model.DeadlineDate = new Date($scope.model.DeadlineDate).toISOString();
            if ($scope.model.GuidDeviation)
                $scope.model.Type = 2;
            repository.updateSingle(repository.apiData.WorkOrderXArea.url, $scope.model).then(function (result) {
                repository.growl('Avvik på rom er oppdatert', 'success');
                $modalInstance.close('ok');
            });
        };



        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
