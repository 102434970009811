//This directive displays a menu list for my profile, plania mobile qr code, logout etc
(function () {

    var app = angular.module('Plania');

    app.directive('userProfileMenu', function () {
		return {
			templateUrl: function (elem, attr) {
				return '/app/common/directives/views/userProfileMenuList.html';
			},
			controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
				$scope.toggleTranslationMode = function () {
					$rootScope.translationMode = !$rootScope.translationMode;
					$rootScope.$broadcast($rootScope.events.translationMode);
				};
			}]
        };
    });
})();
