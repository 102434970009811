(function () {
    var app = angular.module('Plania');

    // =========================================================================
    // LAYOUT
    // =========================================================================
    app.directive('changeLayout', function () {

        return {
            restrict: 'A',
            scope: {
                changeLayout: '='
            },

            link: function (scope, element) {

                //Default State
                if (scope.changeLayout === '1') {
                    element.prop('checked', true);
                }

                //Change State
                element.on('change', function () {
                    if (element.is(':checked')) {
                        localStorage.setItem('ma-layout-status', 1);
                        scope.$apply(function () {
                            scope.changeLayout = '1';
                        });
                    } else {
                        localStorage.setItem('ma-layout-status', 0);
                        scope.$apply(function () {
                            scope.changeLayout = '0';
                        });
                    }
                });
            }
        };
    });


    // =========================================================================
    // MAINMENU COLLAPSE
    // =========================================================================
    app.directive('toggleSidebar', function () {

        return {
            restrict: 'A',
            scope: {
                modelLeft: '=',
                modelRight: '='
            },

            link: function (scope, element) {
                element.on('click', function () {

                    if (element.data('target') === 'mainmenu') {
                        if (scope.modelLeft === false) {
                            scope.$apply(function () {
                                scope.modelLeft = true;
                            });
                        } else {
                            scope.$apply(function () {
                                scope.modelLeft = false;
                            });
                        }
                    }

                    if (element.data('target') === 'chat') {
                        if (scope.modelRight === false) {
                            scope.$apply(function () {
                                scope.modelRight = true;
                            });
                        } else {
                            scope.$apply(function () {
                                scope.modelRight = false;
                            });
                        }

                    }
                });
            }
        };

    });


    // =========================================================================
    // SUBMENU TOGGLE
    // =========================================================================
    app.directive('toggleSubmenu', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    element.next().slideToggle(200);
                    element.parent().toggleClass('toggled');
                });
            }
        };
    });

    // =========================================================================
    // STOP PROPAGATION
    // =========================================================================
    app.directive('stopPropagate', function () {
        return {
            restrict: 'C',
            link: function (scope, element) {
                element.on('click', function (event) {
                    event.stopPropagation();
                });
            }
        };
    });
    app.directive('aPrevent', function () {
        return {
            restrict: 'C',
            link: function (scope, element) {
                element.on('click', function (event) {
                    event.preventDefault();
                });
            }
        };
    });


    // =========================================================================
    // PRINT
    // =========================================================================
    app.directive('print', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    window.print();
                });
            }
        };
    });
    app.directive('toggle', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var x = attrs.toggle;

                //Tooltip
                if (x === 'tooltip') {
                    element.tooltip();
                }

                //Popover
                if (x === 'popover') {
                    element.popover();
                }
            }
        };
    });


    //-----------------------------------------------------
    // COLLAPSE
    //-----------------------------------------------------
    app.directive('collapse', function () {
        return {
            restrict: 'C',
            link: function (scope, element) {
                element.on('show.bs.collapse', function () {
                    $(this).closest('.panel').find('.panel-heading').addClass('active');
                });

                element.on('hide.bs.collapse', function () {
                    $(this).closest('.panel').find('.panel-heading').removeClass('active');
                });

                //Add active class for pre opened items
                $('.collapse.in').each(function () {
                    $(this).closest('.panel').find('.panel-heading').addClass('active');
                });
            }
        };
    });


    //-----------------------------------------------------
    // ANIMATED DROPDOWN MENU
    //-----------------------------------------------------
    app.directive('maDropdown', function () {
        return {
            restrict: 'C',
            link: function (scope, element, attrs) {
                var $animation = attrs.animation;
                var $animArray = $animation.split(',');
                var $animationIn = 'animated ' + $animArray[0];
                var $animationOut = 'animated ' + $animArray[1];
                var $animationDuration = 500;

                if ($animArray[2]) {
                    $animationDuration = $animArray[2];
                }

                element.on('shown.bs.dropdown', function () {
                    if ($(this).data('animation')) {
                        $(this).find('.dropdown-menu').removeClass($animationOut);
                        $(this).find('.dropdown-menu').addClass($animationIn);
                    }
                });

                element.on('hide.bs.dropdown', function (e) {
                    if ($(this).data('animation')) {
                        e.preventDefault();
                        var $this = $(this);
                        var $dropdownMenu = $this.find('.dropdown-menu');

                        $dropdownMenu.addClass($animationOut);
                        setTimeout(function () {
                            $this.removeClass('open');

                        }, $animationDuration);

                    }
                });
            }
        };
    });


    // =========================================================================
    // WEATHER WIDGET
    // =========================================================================
    app.directive('weatherWidget', function () {
        return {
            restrict: 'A',
            link: function () {
                $.simpleWeather({
                    location: 'Austin, TX',
                    woeid: '',
                    unit: 'f',
                    success: function (weather) {
                        var html = '<div class="weather-status">' + weather.temp + '&deg;' + weather.units.temp + '</div>';
                        html += '<ul class="weather-info"><li>' + weather.city + ', ' + weather.region + '</li>';
                        html += '<li class="currently">' + weather.currently + '</li></ul>';
                        html += '<div class="weather-icon wi-' + weather.code + '"></div>';
                        html += '<div class="dash-widget-footer"><div class="weather-list tomorrow">';
                        html += '<span class="weather-list-icon wi-' + weather.forecast[2].code + '"></span><span>' + weather.forecast[1].high + '/' + weather.forecast[1].low + '</span><span>' + weather.forecast[1].text + '</span>';
                        html += '</div>';
                        html += '<div class="weather-list after-tomorrow">';
                        html += '<span class="weather-list-icon wi-' + weather.forecast[2].code + '"></span><span>' + weather.forecast[2].high + '/' + weather.forecast[2].low + '</span><span>' + weather.forecast[2].text + '</span>';
                        html += '</div></div>';
                        $("#weather-widget").html(html);
                    },
                    error: function (error) {
                        $("#weather-widget").html('<p>' + error + '</p>');
                    }
                });
            }
        };
    });

    // =========================================================================
    // UI - ANIMATION
    // =========================================================================
    app.directive('animDemo', [
        '$timeout', function ($timeout) {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    element.click(function () {
                        var animation = $(this).text();
                        var cardImg = $(this).closest('.card').find('img');

                        var animationDuration = 1200;
                        if (animation === "hinge") {
                            animationDuration = 2100;
                        }

                        cardImg.removeAttr('class');
                        cardImg.addClass('animated ' + animation);

                        $timeout(function () {
                            cardImg.removeClass(animation);
                        }, animationDuration);
                    });
                }
            };
        }
    ]);

    // =========================================================================
    // UI - DIALOG
    // =========================================================================

    //Basic
    app.directive('swalBasic', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal("Here's a message!");
                });
            }
        };
    });

    //A title with a text under
    app.directive('swalText', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal("Here's a message!", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem erat, tincidunt vitae ipsum et, pellentesque maximus enim. Mauris eleifend ex semper, lobortis purus sed, pharetra felis");
                });
            }
        };
    });

    //Success Message
    app.directive('swalSuccess', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal("Good job!", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem erat, tincidunt vitae ipsum et, pellentesque maximus enim. Mauris eleifend ex semper, lobortis purus sed, pharetra felis", "success");

                });
            }
        };
    });

    //Warning Message
    app.directive('swalWarning', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal({
                        title: "Er du sikker?",
                        text: "Dataen vil bli permanent slettet!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f44336",
                        confirmButtonText: "Slett",
                        closeOnConfirm: false
                    }, function () {
                        swal("Slettet!", "Your imaginary file has been deleted.", "success");
                    });
                });
            }
        };
    });

    //Parameter
    app.directive('swalParams', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal({
                        title: "Are you sure?",
                        text: "You will not be able to recover this imaginary file!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        cancelButtonText: "No, cancel plx!",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    }, function (isConfirm) {
                        if (isConfirm) {
                            swal("Deleted!", "Your imaginary file has been deleted.", "success");
                        } else {
                            swal("Cancelled", "Your imaginary file is safe :)", "error");
                        }
                    });
                });
            }
        };
    });

    //Custom Image
    app.directive('swalImg', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal({
                        title: "Sweet!",
                        text: "Here's a custom image.",
                        imageUrl: "img/thumbs-up.png"
                    });
                });
            }
        };
    });

    //Auto Close Timer
    app.directive('swalTimer', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.click(function () {
                    swal({
                        title: "Auto close alert!",
                        text: "I will close in 2 seconds.",
                        timer: 2000,
                        showConfirmButton: false
                    });
                });
            }
        };
    });

    // =========================================================================
    // UI - NOTIFICATION  -- -REmoved  by AHA due to release (minify)
    // =========================================================================
    app.directive('growlDemo', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                function notify(from, align, icon, type, animIn, animOut) {
                    $.growl({
                        icon: icon,
                        title: ' Bootstrap Growl ',
                        message: 'Turning standard Bootstrap alerts into awesome notifications',
                        url: ''
                    }, {
                            element: 'body',
                            type: type,
                            allow_dismiss: true,
                            placement: {
                                from: from,
                                align: align
                            },
                            offset: {
                                x: 20,
                                y: 85
                            },
                            spacing: 10,
                            z_index: 1031,
                            delay: 2500,
                            timer: 1000,
                            url_target: '_blank',
                            mouse_over: false,
                            animate: {
                                enter: animIn,
                                exit: animOut
                            },
                            icon_type: 'class',
                            template: '<div data-growl="container" class="alert" role="alert">' +
                            '<button type="button" class="close" data-growl="dismiss">' +
                            '<span aria-hidden="true">&times;</span>' +
                            '<span class="sr-only">Close</span>' +
                            '</button>' +
                            '<span data-growl="icon"></span>' +
                            '<span data-growl="title"></span>' +
                            '<span data-growl="message"></span>' +
                            '<a href="#" data-growl="url"></a>' +
                            '</div>'
                        });
                }

                element.on('click', function (e) {
                    e.preventDefault();

                    var nFrom = attrs.from;
                    var nAlign = attrs.align;
                    var nIcons = attrs.icon;
                    var nType = attrs.type;
                    var nAnimIn = attrs.animationIn;
                    var nAnimOut = attrs.animationOut;

                    notify(nFrom, nAlign, nIcons, nType, nAnimIn, nAnimOut);

                });


            }
        };
    });

    // =========================================================================
    // COMPONENTS - MODAL  --- REMOVED BY AHA due to release
    // =========================================================================
    app.directive('modalColor', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.click(function () {
                    var color = attrs.targetColor;
                    $('#modalColor').attr('data-modal-color', color);
                });
            }
        };
    });

    // =========================================================================
    // Media Element
    // =========================================================================
    app.directive('mediaElement', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.mediaelementplayer({
                    success: function (mediaElement, originalNode) {
                        mediaElement.play();
                    }
                });
            }
        };
    })

        // =========================================================================
        // LIGHTBOX
        // =========================================================================
        .directive('lightgallery', function () {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    if (scope.$last) {
                        // ng-repeat is completed
                        var lg = element.parent();
                        if (lg.data && lg.data('lightGallery')) {
                            lg.data('lightGallery').destroy(true);
                        }
                        lg.lightGallery();
                    }
                }
            };
        });
})();
