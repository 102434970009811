(function () {
	angular.module('Plania').controller('MaintenancePlanController', ['$scope', 'Repository', 'TranslationService', '$filter', '$timeout', controller]);

	function controller($scope, repository, translationService, $filter, $timeout) {
		// Variable to check if we have initialized the first time.
		// Used to block call on first load (since user should select filters first)
		$scope.hasInitialized = false;
		$scope.showFilters = true;
		$scope.activateAutocomplete = false;

		$scope.tasks = [];
		$scope.columnData = [];
		$scope.totalCount = 0;

		$scope.sortingProperty = 'StartDate';
		$scope.sortingDirection = 'desc';
		$scope.sorting = {};
		$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;

		$scope.viewMode = 'days';

		//Contain the filterValues
		$scope.filterValues = {
			dueDateInterval: "nextThreeMonths",
			showWorkordersWithoutStartDate: false,
			searchString: "",
			autocomplete: {},
			types: []
		};

		// Contain the captions from each autocomplete field
		$scope.autocompleteModel = {};
		$scope.loading = false;

		$scope.count = 100;
		$scope.totalCount = 0;

		var search = repository.location.search();
		if (search) {
			if (search.viewMode) {
				$scope.viewMode = rison.decode_uri(search.viewMode);
			}
				
			if (search.count) {
				$scope.count = rison.decode_uri(search.count);
			}
				
			if (search.filterValues) {
				var storedValues = rison.decode_uri(search.filterValues);
				if (typeof storedValues === "object") {
					$scope.filterValues.dueDateInterval = storedValues.dueDateInterval || $scope.filterValues.dueDateInterval;
					$scope.filterValues.autocomplete = typeof storedValues.autocomplete === "object" ? storedValues.autocomplete : $scope.filterValues.autocomplete;
					$scope.filterValues.showWorkordersWithoutStartDate = !!storedValues.showWorkordersWithoutStartDate;
					$scope.filterValues.searchString = storedValues.searchString || $scope.filterValues.searchString;
					$scope.filterValues.types = Array.isArray(storedValues.types) ? storedValues.types : $scope.filterValues.types;
				}
				$scope.hasInitialized = true;
				$scope.showFilters = false;

				$timeout(function () {
					$scope.activateAutocomplete = !!Object.keys($scope.filterValues.autocomplete).length;
				}, 250);
			}
				
			if (search.sorting) {
				$scope.sorting = rison.decode_uri(search.sorting);
				$scope.sortingProperty = Object.keys($scope.sorting)[0];
				$scope.sortingDirection = $scope.sorting[$scope.sortingProperty];
			}
		}

		$scope.sortingProperties = [
			{ value: 'Description', label: translationService.translate('web-maintenancePlan-sorting-description', 'Beskrivelse'), type: 'string' },
			{ value: 'StartDate', label: translationService.translate('web-maintenancePlan-sorting-startDate', 'Startdato'), type: 'date' },
			{ value: 'EstimatedCost', label: translationService.translate('web-maintenancePlan-sorting-estimatedCost', 'Estimert kostnad'), type: 'string' },
			{ value: 'EstimatedTime', label: translationService.translate('web-maintenancePlan-sorting-estimatedTime', 'Estimert tid'), type: 'string' },
			{ value: 'Building.Id', label: translationService.translate('web-maintenancePlan-sorting-buildingId', 'Bygning ID'), typee: 'string' },
			{ value: 'Building.Description', label: translationService.translate('web-maintenancePlan-sorting-buildingDescription', 'Bygningsbeskrivelse'), type: 'string' },
			{ value: 'Equipment.Id', label: translationService.translate('web-maintenancePlan-sorting-equipmentId', 'Anlegg ID'), type: 'string' },
			{ value: 'Equipment.Description', label: translationService.translate('web-maintenancePlan-sorting-equipmentDescription', 'Anleggsbeskrivelse'), type: 'string' },
			{ value: 'Area.Id', label: translationService.translate('web-maintenancePlan-sorting-areaId', 'Areal ID'), type: 'string' },
			{ value: 'ResourceGroup.Id', label: translationService.translate('web-maintenancePlan-sorting-resourceGroupId', 'Ressurs') },
			{ value: 'Type', label: translationService.translate('web-maintenancePlan-sorting-type', 'Arbeidsordretype') },
			{ value: 'ActivityCategory.Description', label: translationService.translate('web-maintenancePlan-sorting-activityCategoryDescription', 'Kategori') },
			{ value: 'Supplier.Description', label: translationService.translate('web-maintenancePlan-sorting-supplierDescription', 'Leverandør') },
			{ value: 'ResponsiblePerson.LastName', label: translationService.translate('web-maintenancePlan-sorting-responsiblePersonLastName', 'Ansvarlig person - Etternavn') },
		];

		$scope.workOrderTypes = [
			{ title: translationService.getEnumTranslation('WorkOrderType', 'KO'), value: 0 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'PR'), value: 1 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'FV'), value: 2 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'ME'), value: 3 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'TE'), value: 4 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'TK'), value: 5 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'AV'), value: 6 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'Alarm'), value: 7 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'VL'), value: 8 },
			{ title: translationService.getEnumTranslation('WorkOrderType', 'TT'), value: 9 },
		];

		$scope.dueDateIntervals = [
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextMonth', 'Neste måned'),
				value: "nextMonth"
			},
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextThreeMonths', 'Neste 3 måneder'),
				value: "nextThreeMonths"
			},
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextHalfYear', 'Neste halvår'),
				value: "nextHalfYear"
			},
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextYear', 'Neste år'),
				value: "nextYear"
			},
		];
		
		var getDueDateFilters = function (dueDateInterval) {
			switch (dueDateInterval) {
				case "nextMonth":
					return {
						Property: "DueDate",
						Operator: "<=",
						Value: moment().utc().startOf('day').add(1, 'month').toISOString()
					};
				case "nextThreeMonths":
					return {
						Property: "DueDate",
						Operator: "<=",
						Value: moment().utc().startOf('day').add(3, 'month').toISOString()
					};
				case "nextHalfYear":
					return {
						Property: "DueDate", Operator: "<=", Value: moment().utc().startOf('day').add(6, 'month').toISOString()
					};
				case "nextYear":
					return {
						Property: "DueDate", Operator: "<=", Value: moment().utc().startOf('day').add(1, 'year').toISOString()
					};
				default:
					return null;
			}
		};

		var updateUrl = function (count, sorting, filterValues, viewMode) {
			repository.location.search('viewMode', rison.encode_uri(viewMode)).replace();
			repository.location.search('count', rison.encode_uri(count)).replace();
			repository.location.search('sorting', rison.encode_uri(sorting)).replace();
			repository.location.search('filterValues', rison.encode_uri(filterValues)).replace();
		};

		$scope.getData = function () {
			// User is required to "activate" thhe maintnanceplan
			if (!$scope.hasInitialized) return;
			
			updateUrl($scope.count, $scope.sorting, $scope.filterValues, $scope.viewMode);
			
			var filters = {
				ActivityStatus: 'Active',
				Interval: $scope.viewMode === "months" ? 3 : $scope.viewMode === "weeks" ? 2 : 1,
				NumberOfYearsToCalculate: $scope.viewMode === "months" ? 3 : undefined,
				FilterModel: {
					Condition: "and",
					Rules: []
				},
				SearchString: $scope.filterValues.searchString
			};

			if (Array.isArray($scope.filterValues.types) && $scope.filterValues.types.length)
				filters.FilterModel.Rules.push({ Property: "Type", Operator: "in", Value: $scope.filterValues.types.join(",") });

			var dueDateRule = getDueDateFilters($scope.filterValues.dueDateInterval);
			if (dueDateRule)
				filters.FilterModel.Rules.push(dueDateRule);

			for (var key in $scope.filterValues.autocomplete) {
				var currentFilterValue = $scope.filterValues.autocomplete[key];
				if (currentFilterValue)
					filters.FilterModel.Rules.push({ Property: key, Operator: "=", Value: currentFilterValue });
			}
			
			$scope.loading = true;

			repository.GetPaginated(repository.apiData.maintenance.url, 0, $scope.count, $scope.sorting, filters, null).then(function (result) {
				var data = [];
				result.List.forEach(function (item) {
					var selectedSorting = _.find($scope.sortingProperties, function (o) { return o.value === $scope.sortingProperty; });
					$scope.loading = false;
					var object = {
						id: item.GuidWorkOrder ? item.GuidWorkOrder : item.GuidPeriodicTask,
						name: item.Title,
						filterValue: item.Subtitle,
						filterLabel: selectedSorting.label,
						//Object containing the values for the detailed view of the row
						detailProperties: [],
						dataObj: item,
						values: []
					};

					var subtitle = "";
					if (item.Equipment)
						subtitle += item.Equipment;
					if (item.Area)
						subtitle += subtitle ? " / " + item.Area : item.Area;
					if (item.Building && !repository.commonService.getFilterData().selectedBuilding.Guid)
						subtitle += subtitle ? " / " + item.Building : item.Building;

					object.subtitle = subtitle;

					if (item.Building && !repository.commonService.getFilterData().selectedBuilding.Guid) object.detailProperties.push({ name: 'Bygg', value: item.Building });
					if (item.Equipment) object.detailProperties.push({ name: 'Anlegg', value: item.Equipment });
					if (item.Area) object.detailProperties.push({ name: 'Areal', value: item.Area });
					if (item.ResourceGroup) object.detailProperties.push({ name: 'Ressurs', value: item.ResourceGroup });
					if (item.WorkOrderType) object.detailProperties.push({ name: 'Type', value: item.WorkOrderType.Description });
					if (item.ActivityCategory) object.detailProperties.push({ name: 'Kategori', value: item.ActivityCategory });
					if (item.Supplier) object.detailProperties.push({ name: 'Leverandør', value: item.Supplier });
					if (item.StartDate) object.detailProperties.push({ name: 'Start dato', value: $filter('date')(item.StartDate, 'dd.MM.yyyy') });
					if (item.EstimatedCost) object.detailProperties.push({ name: 'Est. kost', value: item.EstimatedCost });
					if (item.EstimatedTime) object.detailProperties.push({ name: 'Est. tid', value: item.EstimatedTime });

					item.MaintenanceValues.forEach(function (value) {
						var className = value.IsCompleted ? "ganttGreen" : value.IsPastDueDate ? "ganttRed" : "";
						if (value.GuidPeriodicTask) className = "ganttGray transparent-bar";
						object.values.push({
							from: "/Date(" + new Date(value.StartDate).getTime() + ")/",
							to: "/Date(" + new Date(value.EndDate).getTime() + ")/",
							customClass: className,
							dataObj: value
						});
					});


					data.push(object);
				});

				$scope.tasks = data;
				$scope.columnData = result.ColumnData;
				$scope.totalCount = result.TotalCount;
			}, function (error) {
				$scope.loading = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.getData();

		$scope.initialize = function () {
			if ($scope.hasInitialized) return;
			$scope.hasInitialized = true;
			$scope.getData();
		};

		$scope.addMoreData = function () {
			if ($scope.loading) return;
			$scope.count += 1000;
			$scope.getData();
		};

		$scope.lessData = function () {
			if ($scope.loading) return;
			$scope.count -= 1000;
			if ($scope.count < 100)
				$scope.count = 100;
			$scope.getData();
		};

		$scope.setSorting = function (item) {
			if ($scope.sortingProperty === item.value) {
				$scope.sortingDirection = $scope.sortingDirection === 'desc' ? 'asc' : 'desc';
				$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
			} else {
				$scope.sorting = {};
				$scope.sortingProperty = item.value;
				$scope.sortingDirection = 'desc';
				$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
			}

			$scope.getData();
		};

		$scope.setViewMode = function (viewMode) {
			$scope.viewMode = viewMode;
			$scope.getData();
		};
		
		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "ActivityCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
			return filter;
		};

		var watchKeys = [
			'dueDateInterval',
			'showWorkordersWithoutStartDate',
			'searchString',
			'types',
			'autocomplete.GuidBuilding',
			'autocomplete.GuidEquipment',
			'autocomplete.GuidArea',
			'autocomplete.GuidResourceGroup',
			'autocomplete.GuidPriority',
			'autocomplete.GuidActivityCategory',
			'autocomplete.GuidResponsiblePerson',
			'autocomplete.GuidSupplier',
		].map(function (key) { return 'filterValues.' + key; });
		
		$scope.$watchGroup(watchKeys, function (newValues, oldValues) {
			var hasChanged = false;
			for (var i = 0; i < watchKeys.length; i++) {
				if (newValues[i] !== oldValues[i]) {
					hasChanged = true;
					break;
				}
			}
			if (!hasChanged) return;

			$scope.getData();
		});
		
		$scope.$on($scope.events.newSelection, function () {
			$scope.getData();
		});
	}
})();
