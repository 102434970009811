(function () {
	angular.module('Plania').controller('EditQrCodeModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', '$localStorage', '$rootScope', '$window', controller]);

	function controller($scope, $modalInstance, params, repository, translationService, $localStorage, $rootScope, $window) {
		$scope.qrCode = angular.copy(params.qrCode);
		$scope.guidSparePart = params.guidSparePart;
		$scope.isUpdate = params.isUpdate;
		$scope.domainName = params.domainName;
		$scope.hidePrint = params.hidePrint;
		$scope.reloads = {
			reload: false,
			reloadQrCode: false
		};

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Barcode)
			.then(function (result) {
				$scope.fieldRules = result;
				$scope.reload = true;
			});

		$scope.generateBarcode = function () {
			repository.createSingle(repository.apiData.barcode.url, $scope.qrCode).then(function (result) {
				repository.growl('QR-kode generert', 'success');
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-barcode-message', "Qr-koden vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-barcode-button-confirm', 'Ja, fjern qr-koden'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {

				repository.deleteSingle(repository.apiData.barcode.url, $scope.qrCode.Guid)
					.then(function (result) {
						repository.growl('Qr-koden har blitt fjernet', 'success');
						$modalInstance.close('deleted');
					});
			});
		};

		$scope.save = function () {
			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.barcode.url, $scope.qrCode).then(function (result) {
					repository.growl('QR-kode oppdatert', 'success');
					$modalInstance.close(result);
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingle(repository.apiData.barcode.url, $scope.qrCode).then(function (result) {
					repository.growl('QR-kode opprettet', 'success');
					$modalInstance.close('ok');
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.printLabel = function () {
			if ($scope.guidSparePart) $scope.domainName = 'SparePart';

			var params = {
				Guids: $scope.guidSparePart ? JSON.stringify([$scope.guidSparePart]) : JSON.stringify([$scope.qrCode['Guid' + $scope.domainName]]),
				GuidBarcode: $scope.qrCode.Guid
			};

			$localStorage.reportParams = JSON.stringify(params);

			var reportName = 'PrintLabel' + $scope.domainName;

			var url = $rootScope.navigation.href('report.print', {
				reportName: reportName,
				reportParams: JSON.stringify(params)
			});

			$window.open(url, '_blank');
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancelled');
		};
	}
})();
