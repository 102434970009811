(function () {
	angular.module('Plania').controller('CreateDataImportController', ['$scope', 'TranslationService', 'Repository', 'RegisteredFieldService', '$q', '$stateParams', '$rootScope', controller]);

	function controller($scope, translationService, repository, registeredFieldService, $q, $stateParams, $rootScope) {
		$scope.model = [
			{
				id: 'excel',
				enabled: $rootScope.hasModule('DataImport') || $rootScope.hasModule('DataAcquisition'),
				title: translationService.translate('web-dataImport-create-excel-title', 'EXCEL'),
				description: translationService.translate('web-dataImport-create-excel-description', 'Importer data fra Excel ark.'),
				link: function () {
					$scope.navigation.transitionTo('dataImport.edit');
				},
				color: 'green',
				disabledReason: translationService.translate('web-dataImport-create-excel-disabledReason', 'Forutsetter DataImport lisens.')
			},
			{
				id: 'bim',
				enabled: $rootScope.hasModule('IFCBimsyncHosting'),
				title: translationService.translate('web-dataImport-create-bim-title', 'BIM'),
				description: translationService.translate('web-dataImport-create-bim-description', 'Importer data fra Bim model.'),
				link: function () {
					$scope.navigation.transitionTo('dataImport.bim');
				},
				color: 'blue',
				disabledReason: translationService.translate('web-dataImport-create-bim-disabledReason', 'Forutsetter IFCBimsyncHosting lisens.')
			}
		];
	}
})();
