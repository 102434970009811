(function () {
    angular.module('Plania').controller('CountingItemCommentModalController', ['$scope', 'params', '$modalInstance', controller]);

    function controller($scope, params, $modalInstance) {
        $scope.countingItem = params.countingItem;

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
