(function () {
	angular.module('Plania').directive('plKpiGroupTableWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-table-message', "Tabellen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-table-confirm', 'Ja, fjern tabellen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-table-success', 'Tabellen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/kpiGroupTableWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', 'NgTableParams', '$modal', '$rootScope', '$filter', '$interval', '$localStorage', safeController],
		};
	}]);

	function safeController($scope, translationService, repository, ngTableParams, modal, $rootScope, $filter, $interval, $localStorage) {
		try {
			controller($scope, translationService, repository, ngTableParams, modal, $rootScope, $filter, $interval, $localStorage);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, translationService, repository, ngTableParams, modal, $rootScope, $filter, $interval, $localStorage) {
		$scope.model = $scope.widget;
		$scope.kpiResult = [];
		$scope.rowTotals = {}; // key: GroupColumn, value: { value: number, stringValue: string }
		$scope.columnTotals = {}; // key: ValueColumnIndex, value: { value: number, stringValue: string }
		$scope.grandTotal = { value: 0, stringValue: '0' };
		$scope.hasReadAccess = false;
		var refreshInterval;

		if (typeof ($scope.model.WidgetData) === "string") {
			$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
		}

		// Generic Widget functions
		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false).then(function () {
					initializeData();
					getKpiData();
					updateAutoRefresh();
				});
			}, function () {
				//Dismissed
			});
		};

		if (!$scope.model.WidgetData)
			return;

		var initializeData = function () {
			$scope.visibleColumns = [];
			$scope.visibleColumns.push($scope.model.WidgetData.kpiGroupTable.groupColumn);
			$scope.model.WidgetData.kpiGroupTable.valueColumns.forEach(function (valCol) {
				var valColCopy = angular.copy(valCol);
				var functionTitle = '';
				if (valCol.Function === 'sum') functionTitle = translationService.translate('web-widget-function-sum-headerTitle', 'Sum');
				else if (valCol.Function === 'count') functionTitle = translationService.translate('web-widget-function-count-headerTitle', 'Antall');
				else if (valCol.Function === 'average') functionTitle = translationService.translate('web-widget-function-average-headerTitle', 'Snitt');
				else if (valCol.Function === 'max') functionTitle = translationService.translate('web-widget-function-max-headerTitle', 'Maks');
				else if (valCol.Function === 'min') functionTitle = translationService.translate('web-widget-function-min-headerTitle', 'Min');
				valColCopy.Title = functionTitle + " " + valCol.Title;
				$scope.visibleColumns.push(valColCopy);
			});

			if ($scope.model.WidgetData.kpiGroupTable.showRowTotal)
				$scope.visibleColumns.push({ Title: translationService.translate('web-dashboard-kpiGroupTableWidget-rowTotal', 'Total'), PropertyType: 'double', Property: 'RowTotal' });
		};
		initializeData();

		function getPropertyFilters() {
			var filters = [];
			// Add raw filters from configuration
			if ($scope.model.WidgetData.columns && $scope.model.WidgetData.columns.length > 0) {
				$scope.model.WidgetData.columns.forEach(function (col) {
					if (col.Filter) {
						filters.push(angular.copy(col.Filter[0]));
					}
				});
			}
			return repository.GetKeywordFilters(filters);
		}

		var getKpiData = function () {
			if ($scope.model.WidgetData && $scope.model.WidgetData.prefix)
				$scope.hasReadAccess = $rootScope.hasReadAccess($scope.model.WidgetData.prefix);
			else
				$scope.hasReadAccess = false;

			if (!$scope.hasReadAccess) return;

			var kpi = {
				Prefix: $scope.model.WidgetData.prefix,
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: getPropertyFilters(),
				Properties: [],
				RowGroup: $scope.model.WidgetData.kpiGroupTable.groupColumn.Property
			};

			$scope.model.WidgetData.kpiGroupTable.valueColumns.forEach(function (valCol) {
				kpi.Properties.push({ Property: valCol.Property, Function: valCol.Function });
			});

			var filter = repository.commonService.getFilterData();
			if (filter.selectedBuilding && filter.selectedBuilding.Guid)
				kpi.GuidBuilding = filter.selectedBuilding.Guid;
			if (filter.selectedEstate && filter.selectedEstate.Guid)
				kpi.GuidEstate = filter.selectedEstate.Guid;
			if (filter.selectedSelection && filter.selectedSelection.Guid)
				kpi.GuidSelection = filter.selectedSelection.Guid;

			repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {
				$scope.kpiResult = result;
				var totalColumnSum = {}; // key: ValueColumnIndex, value: number
				var grandTotal = 0;
				var showRowDecimal = $scope.model.WidgetData.kpiGroupTable.valueColumns.some(function (col) { return col.PropertyType === "double" || col.PropertyType === "decimal"; });

				$scope.kpiResult.forEach(function (res) {
					var totalRowSum = 0;

					res.Result.forEach(function (kpiResult, index) {
						totalRowSum += parseFloat(kpiResult.Value);
						if (!totalColumnSum[index]) totalColumnSum[index] = 0;
						totalColumnSum[index] += parseFloat(kpiResult.Value);
					});
					grandTotal += totalRowSum;
					$scope.rowTotals[res.GroupCaption] = {
						value: totalRowSum,
						stringValue: $filter('number')(totalRowSum, showRowDecimal ? 2 : 0)
					};
				});

				$scope.grandTotal = {
					value: grandTotal,
					stringValue: $filter('number')(grandTotal, showRowDecimal ? 2 : 0)
				};

				$scope.model.WidgetData.kpiGroupTable.valueColumns.forEach(function (col, index) {
					var showColumnDecimal = col.PropertyType === "double" || col.PropertyType === "decimal";
					$scope.columnTotals[index] = {
						value: totalColumnSum[index],
						stringValue: $filter('number')(totalColumnSum[index], showColumnDecimal ? 2 : 0)
					};
				});

				$scope.widgetTable.reload();
			});
		};
		getKpiData();

		if (!$scope.model.WidgetData.kpiGroupTable.sortingColumn) {
			$scope.model.WidgetData.kpiGroupTable.sortingColumn = {
				Property: $scope.model.WidgetData.kpiGroupTable.groupColumn.Property,
				Direction: 'asc'
			};
		}

		$scope.widgetTable = new ngTableParams({
			count: $scope.model.WidgetData.defaultPaginationCount ? $scope.model.WidgetData.defaultPaginationCount : 10,
			page: 1,
			sorting: $scope.model.WidgetData.kpiGroupTable.sortingColumn
		},
			{
				counts: [10, 25, 50, 100, translationService.translate('web-table-pagination-all', 'Alle')],
				getData: function ($defer, params) {
					var list = sortTable($scope.kpiResult, params.sorting());
					if (params.count() !== translationService.translate('web-table-pagination-all', 'Alle'))
						list = list.slice((params.page() - 1) * params.count(), params.page() * params.count());
					$defer.resolve(list);
					params.total($scope.kpiResult.length);
				}
			});


		var updateAutoRefresh = function () {
			if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
				if (refreshInterval) {
					cancelInterval(refreshInterval);
				}

				refreshInterval = $interval(function () {
					getKpiData();
				}, $scope.model.WidgetData.RefreshInterval * 1000);

				$scope.$on('$destroy', function () {
					cancelInterval(refreshInterval);
				});
			} else {
				if (refreshInterval) {
					cancelInterval(refreshInterval);
				}
			}
		};
		updateAutoRefresh();

		function cancelInterval(interval) {
			$interval.cancel(interval);
			interval = undefined;
		}

		$scope.formatValueField = function (valueField) {
			var propertyColumn = $scope.visibleColumns.find(function (col) { return col.Property === valueField.Property; });
			return $filter('number')(valueField.Value, propertyColumn && (propertyColumn.PropertyType === 'double' || propertyColumn.PropertyType === 'decimal') ? 2 : 0);
		};

		$scope.formatGroupCaption = function (text) {
			if ($scope.model.WidgetData.kpiGroupTable && $scope.model.WidgetData.kpiGroupTable.groupColumn && $scope.model.WidgetData.kpiGroupTable.groupColumn.PropertyType === 'date')
				return $filter('date')(new Date(text), 'dd.MM.yyyy');
			else
				return text;
		};

		var sortTable = function (tableData, sortingColumn) {
			if (!sortingColumn) return tableData;
			if (!sortingColumn.Function) {
				if (sortingColumn.Property === "RowTotal") {
					tableData.sort((function (a, b) {
						var valA = $scope.rowTotals[a.GroupCaption].value;
						var valB = $scope.rowTotals[b.GroupCaption].value;
						if (valA - valB > 0)
							return 1;
						else if (valA - valB < 0)
							return -1;
						else
							return 0;
					}));
				} else {
					if (sortingColumn.PropertyType === 'number' || sortingColumn.PropertyType === 'double')
						tableData.sort(function (a, b) { return a.GroupCaption - b.GroupCaption; });
					else if (sortingColumn.PropertyType === 'date') {
						tableData.sort(function (a, b) {
							if (a.GroupCaption === 'Undefined' || b.GroupCaption === 'Undefined') return -1;
							return new Date(a.GroupCaption) - new Date(b.GroupCaption);
						});
					}
					else {
						tableData.sort(function (a, b) {
							if (a.GroupCaption < b.GroupCaption) return -1;
							if (a.GroupCaption > b.GroupCaption) return 1;
							return 0;
						});
					}
				}
			} else {
				tableData.sort((function (a, b) {
					var valA, valB;
					a.Result.forEach(function (property) { if (property.Property === sortingColumn.Property && property.Function === sortingColumn.Function) valA = property.Value; });
					b.Result.forEach(function (property) { if (property.Property === sortingColumn.Property && property.Function === sortingColumn.Function) valB = property.Value; });
					if (valA - valB > 0)
						return 1;
					else if (valA - valB < 0)
						return -1;
					else
						return 0;
				}));
			}

			if (sortingColumn.Direction === 'desc')
				tableData.reverse();
			return tableData;
		};

		$scope.sortByColumn = function (sortColumn) {
			sortColumn.Direction = sortColumn.Direction === 'asc' ? 'desc' : 'asc';
			$scope.widgetTable.sorting(sortColumn);
		};

		$scope.$on($rootScope.events.newSelection, function () {
			getKpiData();
		});
	}
})();
