// Common logic for accounting. Usually used for AccountingSectionDirective, but allows for custom implementation while using common functions.
(function () {
	angular.module('Plania').factory('AccountingService', factory);

	function factory() {
		var service = {};

		service.isMandatory = function (dataOwner, accountingColumnProperty, parentEntity) {
			if (!dataOwner || !dataOwner.Guid)
				return false;

			// All entities that support accounting will follow Cost rules, except for budget.
			if (accountingColumnProperty === 'GuidDepartment') {
				return parentEntity === "Budget" ? dataOwner.DepartmentBudget : dataOwner.DepartmentCost;
			}

			if (accountingColumnProperty.startsWith('GuidAccounting')) {
				var lastDimensionValue = parentEntity === "Budget" ? dataOwner.LastDimensionBudget : dataOwner.LastDimensionCost;
				// Mandatory rules from dataOwner will be an integer between 0 and 4, where every accountingField equal or below will be mandatory.
				if (angular.isDefined(lastDimensionValue)) {
					return lastDimensionValue >= parseInt(accountingColumnProperty.slice(-1));
				}
			}
			return false;
		};

		service.onGetValidityRules = function (dataOwner, originalValidityRules) {
			if (!service.isMandatory(dataOwner, originalValidityRules.DomainFieldName, originalValidityRules.EntityType))
				return originalValidityRules;

			// work on a copy, not to alter the original rules
			var validityRules = _.clone(originalValidityRules);
			validityRules.Mandatory = true;
			return validityRules;
		};

		service.onAccountSelect = function (model, account) {
			if (!model || typeof model !== "object")
				return;

			if (account)
				model.GuidAccount = account.Guid;
			else
				delete model.GuidAccount;

			// Account might control which Accounting0 is available.
			if (model.GuidAccounting0 && account.AccountXAccountings && account.AccountXAccountings.length && !_.find(account.AccountXAccountings, { GuidAccounting: model.GuidAccounting0 })) {
				delete model.GuidAccounting0;
				delete model.GuidAccounting1;
				delete model.GuidAccounting2;
				delete model.GuidAccounting3;
				delete model.GuidAccounting4;

				delete model.Accounting0;
				delete model.Accounting1;
				delete model.Accounting2;
				delete model.Accounting3;
				delete model.Accounting4;
			}
		};

		// Generate handlers as a foreach since all the logic is similar, except for last.
		[0, 1, 2, 3, 4].forEach(function (dimNum) {
			service["onAccounting" + dimNum + "Select"] = function (model, accounting) {
				if (!model || typeof model !== "object")
					return;

				if (accounting)
					model["GuidAccounting" + dimNum] = accounting.Guid;
				else
					delete model["GuidAccounting" + dimNum];

				if (dimNum !== 4) {
					var childDimProperty = "GuidAccounting" + 1;
					// Check the other accounting fields if they are constrained to a specific set of accountings. If they don't exist, then remove all accountingvalues higher than the one that was changed.
					// ChildAccountingAccountingXAccountings is prefilled from the API.
					if (model[childDimProperty] && accounting.ChildAccountingAccountingXAccountings && accounting.ChildAccountingAccountingXAccountings.length && !_.find(accounting.ChildAccountingAccountingXAccountings, { GuidChildAccounting: model[childDimProperty] })) {
						var index = dimNum + 1;
						while (index <= 4) {
							delete model["GuidAccounting" + index];
							delete model["Accounting" + index];
							index++;
						}
					}
				}
			};
		});

		service.autoCompleteFilter = function (model, filterName) {
			var filter = {};
			switch (filterName) {
				case "accounting0":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 0, Operator: '=' }];
					if (model && typeof model === "object")
						filter.GuidParentAccount = model.GuidAccount;
					return filter;
				case "accounting1":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 1, Operator: '=' }];
					if (model && typeof model === "object")
						filter.GuidParentAccounting = model.GuidAccounting0;
					return filter;
				case "accounting2":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 2, Operator: '=' }];
					if (model && typeof model === "object")
						filter.GuidParentAccounting = model.GuidAccounting1;
					return filter;
				case "accounting3":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 3, Operator: '=' }];
					if (model && typeof model === "object")
						filter.GuidParentAccounting = model.GuidAccounting2;
					return filter;
				case "accounting4":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 4, Operator: '=' }];
					if (model && typeof model === "object")
						filter.GuidParentAccounting = model.GuidAccounting3;
					return filter;
				default:
					return filter;
			}
		};

		return service;
	}
})();
