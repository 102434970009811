(function () {
	angular.module('Plania').controller('CloseDeviationDirectlyModalController', ['$scope', 'params', '$modalInstance', 'TranslationService', 'RegisteredFieldService', 'Repository', controller]);
	// this controller relies heavily on parent workOrderController, including $scope.model
	function controller($scope,params, $modalInstance, translationService, registeredFieldService, repository) {
		$scope.guidDeviation = _.clone(params.guid);
		$scope.close = _.clone(params.close);
		$scope.model = {};


		var getDeviation = function () {
			repository.getSingle(repository.apiData.deviation.url, $scope.guidDeviation).then(
				function (result) {
					$scope.model = result.Data;

				},
				function (error) {
					repository.growl(error, 'danger');
				});
		};

		getDeviation();

		$scope.isValid = function () {
			if ($scope.close) {
				if ($scope.model.ClosedReason === 'Undefined' || $scope.model.ClosedReason === 'Processed') return false;
			}

			return true;
		};


		$scope.saveAction = function () {

			if ($scope.close) {
				$scope.model.EndDate = new Date().toISOString();
				$scope.model.GuidUserClosedBy = repository.authService.getUserData().guidUser;
			} else {
				$scope.model.Status = 'Postponed';
			}

			repository.updateSingle(repository.apiData.deviation.url, $scope.model).then(function (result) {
				swal($scope.close ? translationService.translate('web-swal-deviation-success', 'Avviket ble lukket!') : translationService.translate('web-swal-deviation-postpone-success', 'Avviket ble utsatt!'), '', "success");
				$modalInstance.close($scope.close ? "Avviket er lukket" : "Avviket er utsatt");
			}, function (error) {
				if (error) {
					repository.growl(error, 'danger');
				}
			});

		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
