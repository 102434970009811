(function () {
	var app = angular.module('Plania');
	app.controller('BimConnectionEditModalController', ['$scope', '$modalInstance', 'params', 'ngTableParams', 'Repository', 'TranslationService', controller]);

	function controller($scope, $modalInstance, params, ngTableParams, repository, translationService) {
		$scope.model = {
			GuidIfc: params.guidIfc
		};

		$scope.enableEditGlobalId = false;

		var prefix = params.prefix;
		var guid = params.guid;
		var guidBuilding = params.guidBuilding;

		var products = [];
		$scope.columns = [];

		var setColumns = function () {
			var columns = [];
			if (!products.length || !products.every(function (product) { return product.GuidIfc === products[0].GuidIfc; })) {
				columns.push({
					Position: 1,
					Title: translationService.translate('web-bimProduct-modelName', "Model"),
					Property: 'ModelName',
					PropertyType: 'string'
				});
			}

			columns.push({
				Position: 2,
				Title: translationService.translate('web-bimProduct-storeyName', "Etasje"),
				Property: 'StoreyName',
				PropertyType: 'string'
			});

			columns.push({
				Position: 3,
				Title: translationService.translate('web-bimProduct-productName', "Navn"),
				Property: 'ProductName',
				PropertyType: 'string'
			});

			$scope.columns = columns;
		};
		setColumns();

		var getProducts = function () {
			var url = repository.apiData.planiaBim.url;
			url += "building/" + guidBuilding;
			url += "/productConnections";

			var urlParms = ["ifcType=IfcSpace"].join("&");

			repository.getWithUrlParameter(url, urlParms).then(function (result) {
				products = result;
				setColumns();
				$scope.productsTable.reload();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};
		getProducts();

		var sortFunction = function (key) {
			return function (a, b) {
				var aVal = a[key];
				var bVal = b[key];

				if (aVal < bVal) { return -1; }
				if (aVal > bVal) { return 1; }
				return 0;
			};
		};

		var getFilteredData = function (filter) {
			var filterText = filter.searchString.toLowerCase();
			var data = products;

			if (!filter.showConnected || filter.showConnected === "0") {
				data = data.filter(function (product) {
					// Return product if it is not connected to anything.
					if (!product.GuidEntities || !product.GuidEntities.length)
						return true;

					// Else only return if product is already connected to the current entity on the page / caller.
					return product.GuidEntities.includes(guid);
				});
			}

			data = data.filter(function (product) {
				if (filterText === undefined || filterText === null || filterText.trim() === "")
					return true;

				var state = false;

				$scope.columns.forEach(function (column) {
					var value = product[column.Property];
					if (("" + value).toLowerCase().includes(filterText))
						state = true;
					if (state)
						return;
				});

				return state;
			});

			return data;
		};

		$scope.productsTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { 'StoreyName': 'asc' },
			filter: { searchString: "", showConnected: "0" }
		}, {
			total: 0,
			counts: [10, 20, 50],
			filterDelay: 10,
			getData: function ($defer, params) {
				var data = getFilteredData(params.filter());

				var sortingKey = Object.keys(params.sorting())[0];
				var direction = params.sorting()[sortingKey];
				data = data.sort(sortFunction(sortingKey));
				if (direction.toLowerCase() === "desc")
					data = data.reverse();

				$scope.productsTable.settings().total = data.length;
				$scope.productsTable.settings().filterDelay = 50;

				data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());

				$defer.resolve(data);
			}
		});

		$scope.selectProduct = function (product) {
			$scope.model.GuidIfc = product.GlobalId;
		};

		var getUrl = function () {
			switch (prefix) {
				case "Area":
					return repository.apiData.area.url;
				default:
					return null;
			}
		};

		$scope.toggleEditEnableGlobalId = function() {
			$scope.enableEditGlobalId = !$scope.enableEditGlobalId;
		};

		$scope.save = function () {
			var url = getUrl();
			if (!url || !guid)
				$scope.cancel();

			repository.patch(url, guid, { GuidIfc: $scope.model.GuidIfc }).then(function () {
				repository.growl(translationService.translate('web-bimConnectionEdit-saved', 'Oppdatert referanse'));
				$modalInstance.close($scope.model.GuidIfc);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
