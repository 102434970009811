(function () {
	angular.module('Plania').controller('UserController', ['$scope', 'TranslationService', 'Repository', '$modal', '$stateParams', 'ngTableParams', '$localStorage', '$rootScope', 'ListService', 'Constants', controller]);

	function controller($scope, translationService, repository, $modal, $stateParams, ngTableParams, $localStorage, $rootScope, listService, constants) {
		$scope.update = $scope.navigation.current.name === 'user.edit';
		$scope.languages = [];
		$scope.model = { Guid: $stateParams.guid };
		$scope.passwordExpirationDate = false;
		$scope.restrictEdit = false;
		$scope.activateAutocomplete = $scope.update ? false : true;
		$scope.viewMode = $scope.update ? true : false;
		$scope.hasReadAccess = $rootScope.hasReadAccess;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.open = function ($event) {
			$scope.passwordExpirationDate = !$scope.passwordExpirationDate;
			$event.preventDefault();
			$event.stopPropagation();
		};

		var updateAccess = function () {
			if ($scope.update) {
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.User, false);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.User, false);

				if (!$scope.userInfo.isUserAdministrator) {
					$scope.restrictEdit = $scope.restrictEdit || $scope.model.Guid !== $scope.userInfo.guidUser;
					$scope.restrictDelete = $scope.restrictEdit || $scope.model.Guid !== $scope.userInfo.guidUser;
				}
			} else {
				$scope.restrictEdit = !$scope.userInfo.isUserAdministrator || !repository.authService.hasCreateAccess(repository.commonService.prefix.User);
				$scope.restrictDelete = !$scope.userInfo.isUserAdministrator || !repository.authService.hasDeleteAccess(repository.commonService.prefix.User);
			}

			$scope.hasProjectManagementModule = $scope.hasModule('ProjectManagement');
		};

		function setTwoFactorStatus() {
			$scope.twoFactor = {};
			if ($localStorage.generalOptions.TwoFactorAuthenticationOption <= 0) {
				$scope.twoFactor.isEnabled = false;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-user-twofactor-disabled', 'Tofaktor er avskrudd fra system');
			} else if ($localStorage.generalOptions.TwoFactorAuthenticationOption === 2) {
				$scope.twoFactor.isEnabled = true;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-user-twofactor-enabled', 'Tofaktor er påskrudd fra system');
			} else if ($scope.model.UserGroup && $scope.model.UserGroup.IsTwoFactorAuthenticationEnabled) {
				$scope.twoFactor.isEnabled = true;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-user-twofactor-usergroup-enabled', 'Tofaktor er påskrudd fra brukergruppe');
			} else {
				$scope.twoFactor.isEnabled = $scope.model.IsTwoFactorAuthenticationEnabled;
				$scope.twoFactor.canChange = true;
			}
		}

		updateAccess();
		setTwoFactorStatus();
		$scope.access = {
			Desktop: false,
			Web: false,
			Mobile: false,
			ReadOnlyWeb: false
		};

		var setUserAccess = function (access) {
			if (access.indexOf("Desktop") >= 0)
				$scope.access.Desktop = true;
			if (access.indexOf("Web") >= 0)
				$scope.access.Web = true;
			if (access.indexOf("Mobil") >= 0)
				$scope.access.Mobile = true;
			if (access.indexOf("ReadOnlyWeb") >= 0)
				$scope.access.ReadOnlyWeb = true;
		};

		var GetUserAccess = function () {
			var access = 0;
			if ($scope.access.Desktop)
				access = access | 1 << 0;
			if ($scope.access.Web)
				access = access | 1 << 1;
			if ($scope.access.Mobile)
				access = access | 1 << 2;
			if ($scope.access.ReadOnlyWeb)
				access = access | 1 << 3;

			return access;
		};

		repository.GetPaginated(repository.apiData.webMenu.url, 0, 1000, { Name: "asc" }, {}, '', JSON.stringify(['Name'])).then(function (result) {
            var availableMenus = [];
            availableMenus.push({ text: 'Ingen', guid: null });
			if (result.List.length > 0) {
				result.List.forEach(function (webMenu) {

					if (!webMenu.IsBuildingDependent) {
						availableMenus.push({
							text: webMenu.Name,
							guid: webMenu.Guid
						});
					}
                });
            }
			$scope.availableStartPages = availableMenus;
			if ($scope.model.GuidWebMenu)
				$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || $scope.availableStartPages[0];
		});

		var getSingle = function () {
			var columns = [
				'Person.FirstName', 'Person.LastName', 'Person.Email', 'Person.TelephoneWork',
				'ResourceGroup.Id',
				'Department.Id', 'Department.Description',
				'Account.Id', 'Account.Description',
				'Supplier.Description',
				'DefaultBuildingSelection.Description',
				'Customer.Id', 'Customer.Description',
				'MobileMenuProfile.Id', 'MobileMenuProfile.Description'];

			repository.getSingle(repository.apiData.user.url, $stateParams.guid, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;

				if (!$scope.model.UserGroup) {
					$scope.model.UserGroup = { IsStartPageForced: false };
				}

				updateAccess();
				setUserAccess($scope.model.AccessType);
				setTwoFactorStatus();
				repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
					function (result) {
						$scope.languages = [];
						result.List.forEach(function (row) {
							$scope.languages.push(row);
						});
						$scope.model.GuidLanguage = $scope.model.GuidLanguage ? $scope.model.GuidLanguage : $scope.languages[0].Guid;
						$scope.selectedLanguage = _.filter($scope.languages, { Guid: $scope.model.GuidLanguage })[0];
					}
				);
				if ($scope.availableStartPages)
					$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || $scope.availableStartPages[0];

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

			},
				function (error) {
					repository.growl(error, 'danger');
				});
		};

		if ($scope.update) {
			getSingle();
		} else {
			$scope.model.UserGroup = { IsStartPageForced: false };
			repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
				function (result) {
					result.List.forEach(function (row) {
						$scope.languages.push(row);
					});
					var language = _.filter($scope.languages, { Country: 'Norway' })[0] || $scope.languages[0];
					$scope.model.GuidLanguage = language.Guid;
				}
			);
		}

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "userGroup":
					filter.FilterModel = {
						Condition: "and",
						Rules: [{ Property: 'IsUserGroup', Operator: '=', Value: true }]
					};

					// Custom for KA to limit view of user groups when user is local admin.
					if ($scope.customization.isCustomer('KA') && $rootScope.userInfo.guidUserGroup === constants.ka.guidLocalAdminUserGroup) {
						filter.FilterModel.Rules.push({ Property: "Guid", Operator: "in", Value: [constants.ka.guidFdvUserGroup, constants.ka.guidInventoryUserGroup] });
					}

					return filter;
			}
			return filter;
		};

		$scope.GuidChangeLanguage = function (selectedLanguage) {
			$scope.model.GuidLanguage = selectedLanguage.Guid;
			$scope.selectedLanguage = _.filter($scope.languages, { Guid: selectedLanguage.Guid })[0];
		};

		$scope.changeStartPage = function (selected) {
			if (selected)
				$scope.model.GuidWebMenu = selected.guid;
		};

		$scope.deleteUser = function (destination, user) {
			$scope.isDeletingUser = true;
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-user-message', 'Bruker vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-user-button-confirm', 'Ja, fjern brukeren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.user.url, user.Guid)
					.then(function (result) {
						window.onkeydown = null;
						window.onfocus = null;
						repository.growl(translationService.translate('delete-user-success-message', ' %Username% ble slettet fra systemet', user), 'success');
						$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
						swal.close();
					}, function (error) {
							swal({
								title: translationService.translate('web-user-delete-fail', 'Kunne ikke slette %Username%', user),
								text: error.Message + "<br/><br/> " + translationService.translate('web-user-deactivate-question', 'Vil du deaktivere %Username%', user),
								type: "error",
								html: true,
								showCancelButton: true,
								confirmButtonText: "Deaktiver",
								cancelButtonText: "Avbryt",
								closeOnConfirm: true,
								closeOnCancel: true
							}, function (isConfirm) {
								window.onkeydown = null;
								window.onfocus = null;
								if (isConfirm) {
									$scope.model.IsDeactivated = true;
									$scope.updateUser('user.list');
								}
							});

					});
			});
		};

		$scope.onUserGroupSelect = function (ug) {
			if (!ug) {
				$scope.model.GuidUserGroup = '';
				$scope.model.UserGroup.IsStartPageForced = false;
			}

			if ($scope.model.UserGroup && $scope.model.UserGroup.IsStartPageForced) {
				repository.GetPaginated(repository.apiData.webMenu.url, 0, 1000, { Id: "asc" }, {}, '', JSON.stringify(['Name'])).then(function (result) {
					var availableMenus = [];
					availableMenus.push({ text: 'Ingen', guid: null });
					if (result.List.length > 0) {
						result.List.forEach(function (webMenu) {

							if (!webMenu.IsBuildingDependent) {
								availableMenus.push({
									text: webMenu.Name,
									guid: webMenu.Guid
								});
							}
						});
					}
					$scope.availableStartPages = availableMenus;
					if ($scope.model.UserGroup.GuidWebMenu) {
						$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.UserGroup.GuidWebMenu })[0] || $scope.availableStartPages[0];
						$scope.model.GuidWebMenu = $scope.model.UserGroup.GuidWebMenu;
					}
				});
			}

			//only set default values if it's create new user
			if (!$scope.update) {
				if (!$scope.model.GuidPerson) {
					$scope.model.GuidPerson = ug.guidPerson;
				}

				$scope.model.GuidResourceGroup = ug.GuidResourceGroup;
				$scope.model.ResourceGroup = ug.ResourceGroup;
				$scope.model.GuidAccount = ug.GuidAccount;
				$scope.model.Account = ug.Account;
				$scope.model.GuidDepartment = ug.GuidDepartment;
				$scope.model.Department = ug.Department;
				$scope.model.GuidCustomer = ug.GuidCustomer;
				$scope.model.Customer = ug.Customer;
				$scope.model.GuidDefaultBuildingSelection = ug.GuidDefaultBuildingSelection;
				$scope.model.DefaultBuildingSelection = ug.DefaultBuildingSelection;

				$scope.model.Procuration = ug.Procuration;
				$scope.model.CanPrintPurchaseOrder = ug.CanPrintPurchaseOrder;
			}

			$scope.model.GuidDefaultDataOwner = ug.GuidDefaultDataOwner;
			$scope.model.GuidMobileMenuProfile = ug.GuidMobileMenuProfile;
			$scope.model.MobileMenuProfile = ug.MobileMenuProfile;

			$scope.model.AccessType = ug.AccessType;
			setUserAccess($scope.model.AccessType);
			setTwoFactorStatus();
			$scope.model.IsUserAdministrator = ug.IsUserAdministrator;
			$scope.model.IsSystemAdministrator = ug.IsSystemAdministrator;
			$scope.model.CanRunNewPeriod = ug.CanRunNewPeriod;
			$scope.model.CanRunNewEnergyPeriod = ug.CanRunNewEnergyPeriod;
			$scope.model.CanChangeDataOwner = ug.CanChangeDataOwner;
			$scope.model.CanOverridePermissions = ug.CanOverridePermissions;
			$scope.model.CanRunSystemFunction = ug.CanRunSystemFunction;
			$scope.model.IsBookingAdministrator = ug.IsBookingAdministrator;
			$scope.model.IsExternalUser = ug.IsExternalUser;
			$scope.model.CanChangeDataAcquisitionStatus = ug.CanChangeDataAcquisitionStatus;
			$scope.model.CanChangeRequestStatus = ug.CanChangeRequestStatus;
			$scope.model.CanSeeWebMainMenu = ug.CanSeeWebMainMenu;
			$scope.model.CanExportToExcel = ug.CanExportToExcel;
			$scope.model.SqlCustomerFilter = ug.SqlCustomerFilter;

			$scope.model.GuidUserGroup = ug.Guid;
			$scope.model.UserGroup = ug;
		};

		$scope.onPersonSelect = function (person) {
			if (!person) {
				$scope.model.GuidPerson = '';
				return;
			}

			if (person.Email) {
				$scope.model.Email = person.Email;
			}

			if (person.TelephoneWork) {
				$scope.model.PhoneNumber = person.TelephoneWork;
			}

			if (!$scope.model.CellPhone && person.Cellphone) {
				$scope.model.CellPhone = person.Cellphone;
			}

			$scope.model.RealName = person.FirstName + ' ' + person.LastName;
			$scope.model.GuidPerson = person.Guid;
		};

		$scope.updateUser = function (destination) {
			if (!$scope.userForm.newEmail.$valid) {
				repository.growl(translationService.translate('web-user-validation-email-invalidFormat', 'Epostformat er ugyldig'), 'danger');
				return;
			}

			if (!$scope.model.IsPasswordPermanent && !$scope.model.PasswordLastDayDate) {
				repository.growl(translationService.translate('user-label-passwordExpirationDate') + ' er obligatorisk', 'danger');
				return;
			}

			$scope.model.AccessType = GetUserAccess();
			if (!$scope.update) {
				$scope.model.GuidDefaultDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			}
			var success = function (result) {
				repository.growl($scope.update ? translationService.translate('update-user-success', 'Brukeren har blitt oppdatert.') : translationService.translate('create-user-success', 'Brukeren har blitt registrert.'), 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				var message = '';
				if (error.indexOf('invalid_license_defined_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}
				if (error.indexOf('invalid_license_simple_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}

				if (message !== '') {
					swal({
						title: "Bekreft utvidelse av lisens",
						text: message,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "Ja, utvid lisens",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.ConfirmIncreaseInLicense = true;
							$scope.updateUser(destination);
						} else {
							repository.growl('Endringene ble avbrutt!', 'danger');
						}
					});
				} else {
					repository.growl(error, 'danger');
				}
			};

			if ($scope.model.PasswordLastDayDate)
				$scope.model.PasswordLastDayDate = new Date($scope.model.PasswordLastDayDate).toISOString();

			if ($scope.update) {
				repository.updateSingle(repository.apiData.user.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingle(repository.apiData.user.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.userProfileInfo = new function () {
			var me = this;

			this.data = [];
			this.totalCount = 0;
			this.search = {};

			this.columns = [
				{ Position: 1, Title: translationService.translate('user-clientTable-dataOwnerName', 'Klient'), Property: 'LinkedDataOwner.Description', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('user-clientTable-userProfileName', 'Profil'), Property: 'EntityPermissionProfile.Id', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('user-clientTable-userProfileDescription', 'Profilbeskrivelse'), Property: 'EntityPermissionProfile.Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('user-clientTable-updatedDate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },
			];

			this.defaultDataOwner = {
				hasError: false,
				status: "",
				caption: ""
			};

			this.getData = function () {
				var columns = [];
				me.columns.forEach(function (col) {
					columns.push(col.Property);
				});
				columns.push("IsDefaultDataOwner");
				var filter = { FilterModel: { Property: 'GuidUser', Operator: '=', Value: $scope.model.Guid } };
				repository.GetPaginated(repository.apiData.userProfile.url, 0, -1, null, filter, null, JSON.stringify(columns)).then(function (result) {
					me.data = result.List;
					me.totalCount = result.TotalCount;
					me.table.reload();

					var defaultDataOwner = _.filter($scope.userProfileInfo.data, { IsDefaultDataOwner: true })[0];
					if (defaultDataOwner) {
						if (!defaultDataOwner.GuidEntityPermissionProfile) {
							me.defaultDataOwner = {
								hasError: true,
								status: "missingEntityPermission"
							};
						} else {
							var caption = "";
							if (defaultDataOwner.LinkedDataOwner.Description)
								caption += defaultDataOwner.LinkedDataOwner.Description;
							if (defaultDataOwner.EntityPermissionProfile.Id) {
								if (caption)
									caption += " - ";
								caption += defaultDataOwner.EntityPermissionProfile.Id;
							}

							me.defaultDataOwner = {
								profile: defaultDataOwner,
								hasError: false,
								status: "ok",
								caption: caption,
							};
						}
					} else if (me.data.length) {
						me.defaultDataOwner = {
							hasError: true,
							status: "missingUserProfiles"
						};
					} else {
						me.defaultDataOwner = {
							hasError: true,
							status: "missingDefaultDataOwner"
						};
					}
				}, function (error) {
					repository.showError(error);
				});
			};

			var getValue = function (entity, property) {
				var value = entity;
				var properties = property.split(".");
				properties.forEach(function (prop) {
					if (value !== null && value !== undefined) {
						value = value[prop];
					}
				});
				return value;
			};

			var getFilteredData = function (filter) {
				var data = me.data;

				if (filter.searchString === undefined) return data;
				var filterText = filter.searchString.toLowerCase();

				data = data.filter(function (item) {
					if (filterText === undefined || filterText === null || filterText.trim() === "")
						return true;

					var state = false;

					me.columns.forEach(function (column) {
						var value = getValue(item, column.Property);
						if (("" + value).toLowerCase().includes(filterText))
							state = true;
						if (state)
							return;
					});

					return state;
				});

				return data;
			};

			this.initTable = function () {
				if (me.table) return;

				me.table = new ngTableParams({
					page: 1,
					count: 20,
					filter: { searchParams: "" },
					sorting: { "LinkedDataOwner.Description": "asc" }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [20, 50, 100, 200],
						getData: function ($defer, params) {
							var data = getFilteredData(params.filter());
							var sortingKey = Object.keys(params.sorting())[0];
							if (sortingKey) {
								data = _.sortBy(data, sortingKey);
								var direction = params.sorting()[sortingKey];
								if (direction.toLowerCase() === "desc")
									data = data.reverse();
							}

							me.table.settings().total = data.length;
							me.table.settings().filterDelay = 500;

							data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());

							$defer.resolve(data);
						}
					});
			};

			this.add = function () {
				if (!me.canAdd()) return;

				$modal.open({
					templateUrl: 'app/user/views/editUserDataOwner.html',
					controller: 'CreateUserDataOwnerController',
					resolve: {
						params: function () {
							return {
								guidUser: $scope.model.Guid,
								isFirst: me.data.length < 1
							};
						}
					}
				}).result.then(function (userProfile) {
					if (userProfile && userProfile.IsDefaultDataOwner) {
						$scope.model.GuidDefaultDataOwner = userProfile.GuidLinkedDataOwner;
					}
					me.getData();
				});
			};

			this.update = function (entity) {
				if (!me.canEdit(entity)) return;

				if ($scope.restrictEdit || ($scope.model.GuidUserGroup && $scope.model.UserGroup && !$scope.model.UserGroup.CanOverrideUserProfiles)) return;

				$modal.open({
					templateUrl: 'app/user/views/editUserDataOwner.html',
					controller: 'EditUserDataOwnerController',
					resolve: {
						params: function () {
							return {
								guidUserProfile: entity.Guid
							};
						}
					}
				}).result.then(function (userProfile) {
					if (userProfile && userProfile.IsDefaultDataOwner) {
						$scope.model.GuidDefaultDataOwner = userProfile.GuidLinkedDataOwner;
					}
					me.getData();
				});
			};

			this.remove = function (entity) {
				if (!me.canDelete(entity)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-user-dataOwner-message', 'Klienten vil bli fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-user-dataOwner-button-confirm', 'Ja, fjern klienten!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.userProfile.url, entity.Guid).then(function (result) {
						swal.close();
						repository.growl(translationService.translate('user-removeDataOwner-Success', 'Klient ble fjernet fra Bruker.'), 'success');
						me.getData();
					});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-user-userProfile-edit', 'Rediger klienttilgang') + '</a >',
						click: function () {
							me.update(item);
						}
					});
				}

				if ($scope.hasReadAccess('DataOwner')) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href="' + $scope.navigation.href('dataOwner.edit', {guid: item.GuidLinkedDataOwner}) + '" >' +
							'<i class="fas fa-suitcase fa-hc-fw fa-hc-lg" style="visibility: hidden"></i> ' + translationService.translate('web-user-viewDataOwner', 'Gå til klient') + '</a>',
						isHref: true
					});
				}

				if ($scope.hasReadAccess('EntityPermissionProfile')) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href="' + $scope.navigation.href('entityPermissionProfile.edit', {guid: item.GuidEntityPermissionProfile}) + '" >' +
							'<i class="fas fa-suitcase fa-hc-fw fa-hc-lg" style="visibility: hidden"></i> ' + translationService.translate('web-user-viewEntityPermissionProfile', 'Gå til rettighetsprofil') + '</a>',
						isHref: true
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-user-dataOwner-delete', 'Slett klient') + '</a >',
						click: function () {
							me.remove(item);
						},
						hasTopDivider: true
					});
				}


				return options;
			};

			this.canAdd = function () {
				return !$scope.restrictEdit && ($scope.model.GuidUserGroup && $scope.model.UserGroup ? !!$scope.model.UserGroup.CanOverrideUserProfiles : true);
			};

			this.canEdit = function (entity) {
				return entity && !$scope.restrictEdit && ($scope.model.GuidUserGroup && $scope.model.UserGroup ? !!$scope.model.UserGroup.CanOverrideUserProfiles : true);
			};

			this.canDelete = function (entity) {
				return entity && !$scope.restrictEdit && ($scope.model.GuidUserGroup && $scope.model.UserGroup ? !!$scope.model.UserGroup.CanOverrideUserProfiles : true);
			};

			if ($scope.update) {
				me.initTable();
				me.getData();
			}
		}();

		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
			if (toState.name !== 'user.edit' && $scope.update && !$scope.isDeletingUser) {
				if ($scope.userProfileInfo.data.length === 0) {
					event.preventDefault();
					swal(
						translationService.translate('user-edit-no-dataowner-title', 'Ingen klienter valgt'),
						translationService.translate('user-edit-no-dataowner', 'Klient og brukerprofil må velges dersom brukeren skal kunne benyttes'),
						'error');
					$scope.userProfileInfo.add();
				}
				else if (_.filter($scope.userProfileInfo.data, { IsDefaultDataOwner: true }).length === 0) {
					event.preventDefault();
					swal(
						translationService.translate('user-edit-no-default-dataowner-title', 'Ingen Standard klient valgt'),
						translationService.translate('user-edit-no-default-dataowner', 'Det må velges en standard klient dersom brukeren skal kunne benyttes'),
						'error');
					$scope.userProfileInfo.add();
				}
				else if (_.filter($scope.userProfileInfo.data, function (profile) { return profile.GuidEntityPermissionProfile && profile.IsDefaultDataOwner; }).length === 0) {
					event.preventDefault();
					swal(
						translationService.translate('user-edit-no-entityPermission-title', 'Ingen Profil valgt'),
						translationService.translate('user-edit-no-entityPermission', 'Standard klient må ha en profil valgt'),
						'error');
					var defaultProfile = _.find($scope.userProfileInfo.data, function (profile) { return profile.IsDefaultDataOwner; });
					if (defaultProfile)
						$scope.userProfileInfo.update(defaultProfile);
					else
						$scope.userProfileInfo.add();
				}
			}
		});

		$scope.uploadImage = function (images) {
			if (images.length < 1) return;
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/imageUploadModal.html',
				controller: 'ImageUploadModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							image: images[0],
							targetSize: 300,
							areaType: 'square'
						};
					}
				}
			});

			modalInstance.result.then(function (croppedImage) {
				var files = [];
				handleFileUpload(croppedImage);
				//Read Base64 string and add to file object
				function handleFileUpload(file) {
					var reader = new FileReader();
					reader.onload = function (e) {
						var newFile = {
							ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
							Name: file.name,
							GuidUser: $scope.model.Guid
						};
						files.push(newFile);
						onLoadEndHandler();
					};
					reader.readAsDataURL(file);
				}

				function onLoadEndHandler(image) {
					repository.createMultiple(repository.apiData.image.url, files).then(function (imageResult) {
						var newGuidImage = imageResult[0].Guid;
						var changeUserImageDto = {
							GuidImage: newGuidImage,
							GuidUser: $scope.model.Guid
						};

						repository.createSingle(repository.apiData.user.urlChangeImage, changeUserImageDto).then(function (result) {
							$scope.model.GuidImage = newGuidImage;
							if ($scope.model.Guid === $rootScope.userInfo.guidUser){
								$rootScope.userInfo.guidImage = newGuidImage;
								$localStorage.userData.guidImage = newGuidImage;
							}
							repository.growl('Bilde oppdatert', 'success');
						}, function (error) {
							repository.growl(error, 'danger');
						});
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		};

		$scope.changePassword = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/changePasswordModal.html',
				controller: 'ChangePasswordModalController',
				size: 'sm',
				resolve: {
					params: function () {
						return {
							user: $scope.model
						};
					}
				}
			}).result.then(function (newPassword) {
				if (newPassword) {
					$scope.model.Password = newPassword;
					$scope.updateUser('user.edit');
				}
			});
		};
		$scope.externalLoginProviderTable = new ngTableParams(
			{
				page: 1, count: -1, filter: {}
			},
			{
				total: 0,
				filterDelay: 10,
				counts: false,
				getData: function ($defer, params) {

					repository.GetPaginated(repository.apiData.externalLoginProvider.url + 'user/' + $scope.model.Guid, params.page() - 1,
						params.count(),
						params.sorting(),
						params.filter(),
						null,
						JSON.stringify([])
					).then(
						function (result) {
							$scope.externalLoginProviderTable.settings().total = result.length;
							$scope.externalLoginProviderTable.settings().filterDelay = 500;
							$defer.resolve(result);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});
		$scope.userXExternalLoginContextMenuOptions = function(item){
			var options = [];
			if (item.UserXExternalLogins.length === 0 && $scope.userInfo.guidUser === $scope.model.Guid){
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href=""> ' + translationService.translate('web-userXExternalLogin-contextMenuOption-add', 'Legg til') + '</a >',
					click: function () {
						swal({
							title: translationService.translate('web-swal-userXExternalLogin-areyousure', 'Registrering av ekstern innlogging'),
							text: translationService.translate('web-swal-userXExternalLogin-add-message', 'Du vil nå navigeres til ekstern innlogging. Ved fullført innlogging vil brukeren din i Plania knyttes opp mot den eksterne innloggingen'),
							type: "info",
							showCancelButton: true,
							confirmButtonColor: "#337ab7",
							confirmButtonText: translationService.translate('web-swal-userXExternalLogin-add-button-confirm', 'Fortsett'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: false,
							showLoaderOnConfirm: true
						}, function () {
							window.onkeydown = null;
							window.onfocus = null;

							repository.authService.authenticateLoginProviderWhileLoggedIn(item);

						});
					}
				});
			}
			if (item.UserXExternalLogins.length > 0 ){
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href=""> ' + translationService.translate('web-userXExternalLogin-contextMenuOption-delete', 'Slett') + '</a >',
					click: function () {
						swal({
							title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
							text: translationService.translate('web-swal-userXExternalLogin-delete-message', 'Ekstern innlogging vil bli fjernet og bruker må knytte til ekstern innlogging på ny'),
							type: "warning",
							showCancelButton: true,
							confirmButtonColor: "#f44336",
							confirmButtonText: translationService.translate('web-swal-userXExternalLogin-delete-button-confirm', 'Fjern ekstern innlogging'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: false,
							showLoaderOnConfirm: true
						}, function () {
							window.onkeydown = null;
							window.onfocus = null;
							var userXExternalLogin = _.find(item.UserXExternalLogins, function (login){
								return login.GuidUser === $scope.model.Guid;
							});
							if (!userXExternalLogin) return;

							repository.deleteSingle(repository.apiData.userXExternalLogin.url, userXExternalLogin.Guid)
								.then(function (result) {
									swal.close();
									repository.growl(translationService.translate("web-swal-userXExternalLogin-delete-successToaster", "Ekstern innlogging fjernet"), 'success');
									$scope.externalLoginProviderTable.reload();
								});
						});
					}
				});
			}

			return options;
		};
	}
})();
