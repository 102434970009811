(function () {

	angular.module('Plania').controller('TableModalController', ['$scope', '$modalInstance', '$modal', 'ngTableParams', 'content', controller]);

	function controller($scope, $modalInstance, $modal, ngTableParams, content) {

		$scope.header = content.header;
		$scope.singleColumn = content.singleValue;
		$scope.idTitle = content.idTitle;
		$scope.onType = content.onType;
		$scope.createHandler = content.createHandler;
		$scope.defaultSort = content.defaultSort;

		if (content.displayColumns) {
			$scope.columns = content.displayColumns;
		} else {
			$scope.columns = [{ Title: $scope.idTitle, Property: 'id' }];
			if (!$scope.singleColumn)
				$scope.columns.push({ Title: 'Beskrivelse', Property: 'description' });
		}

		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			filter: { searchString: content.params.dropdown },
			sorting: $scope.defaultSort ? $scope.defaultSort : { id: 'asc' }
		}, {
				total: 0,
				counts: [10, 20, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					content.params.dropdown = params.filter().searchString;
					// override predefined layouts, which would usually get too much data, and not necessarily the wanted columns
					// NB: tested for the moment only on "area" (to avoid for ex. case where type does not define default columns 'id' or 'description')
					var columns = [];
					$scope.columns.forEach(function (col) {
						columns.push(col.Property);
					});
					content.data(content.params, params.page() - 1, params.count(), params.sorting(), columns).then(function (result) {
						if (result.totalCount) {
							params.total(result.totalCount);
							$defer.resolve(result.dropdown);
						} else {
							params.total(result.dropdown.length);
							$defer.resolve(result.dropdown.slice((params.page() - 1) * params.count(), params.page() * params.count()));
						}
					});
				}
			});

		var selectedItem = {};

		$scope.getProperty = function (item, property) {
			if (property === 'id' || property === 'description')
				return item[property];
			else
				return $scope.getDescendantProp(item.object, property);
		};

		$scope.getDescendantProp = function (item, property) {
			var arr = property.split(".");
			while (arr.length && (item = item[arr.shift()]));
			return item;
		};

		$scope.selectItem = function (item) {
			selectedItem = item;
		};

		$scope.isItemSelected = function (guid) {
			if (selectedItem.guid)
				return selectedItem.guid === guid;
			return false;
		};

		$scope.ok = function (item) {
			if (item) {
				$modalInstance.close(item);
			} else {
				$modalInstance.close(selectedItem);
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};

		$scope.can = function (operation) {
			if (!$scope.createHandler)
				return false;
			if ($scope.createHandler.can)
				return $scope.createHandler.can(operation);
			// if not specifically defined, use standard behaviour
			var permissionHandler = $scope['has' + operation + 'Access'];
			if (!permissionHandler)
				return false;// unknown action
			return permissionHandler($scope.onType);
		};


		var capitalizeFirstLetter = function(str) {
			if (!str) return str;
			return str.charAt(0).toUpperCase() + str.slice(1);
		};

		$scope.createEntity = function () {
			if (!$scope.createHandler)
				return false;
			var templateUrl = $scope.createHandler.templateUrl;
			// if caller defines no controller, use expected default one '<EntityType>Controller' (with $stateParams.isModal: true )
			var controller = $scope.createHandler.controller ? $scope.createHandler.controller : capitalizeFirstLetter($scope.onType) + "Controller";
			var cancelModal = $scope.createHandler.cancelModal ? $scope.createHandler.cancelModal : function () { modalInstance.dismiss('canceled'); };
			var closeModal = $scope.createHandler.closeModal ? $scope.createHandler.closeModal : function () { modalInstance.close(); };
			modalInstance = $modal.open({
				templateUrl: templateUrl,
				size: 'md',
				animation: false,
				controller: controller,
				resolve: {
					$stateParams: function () {
						return {
							isModal: true,
							isUpdate: false,
							closeModal: closeModal,
							cancelModal: cancelModal
						};
					},
				}
			});

			modalInstance.result.then(function (result) {
				$scope.items.reload();
			}, function (error) { });
		};

	}
})();
