(function () {
	angular.module('Plania').controller('HourTypeModalController', ['$scope', 'Repository', '$stateParams', 'TranslationService', '$modal', 'modalParams', 'ngTableParams', 'ListService', '$modalStack', controller]);

	function controller($scope, repository, $stateParams, translationService, $modal, modalParams, ngTableParams, listService, $modalStack) {
		$scope.project = modalParams.Project;
		var modalInstance = null;
		//$scope.hourTypeColumns = ['Id', 'Description', 'PriceType', 'Price'];

		$scope.hourTypeColumns = [];
		$scope.hourTypeColumns.AddColumn = function (translationId, defaultText, property, propertyType) {
			this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
			return this;
		};

		$scope.hourTypeColumns
			.AddColumn('web-hour-type-id', "Id", 'Id', 'string')
			.AddColumn('web-hour-type-description', "Beskrivelse", 'Description', 'string')
			.AddColumn('web-hour-type-price', "Pris", 'Price', 'number')
			.AddColumn('web-hour-type-type', "Type", 'PriceType', 'enum.PriceType');

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.can = function (operation) {
			if ($scope.project.ClosedDate)
				return false;
			var permissionHandler = $scope['has' + operation + 'Access'];
			if (!permissionHandler)
				return false;// unknown action
			return permissionHandler('HourType');
		};

		var getHourTypes = function () {
			$scope.hourTypeTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { /* 'Id': 'asc' */ },
				filter: { GuidProject: $scope.project.Guid }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						var columns = [];
						$scope.hourTypeColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.hourType.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							"",
							JSON.stringify(columns))
							.then(function (result) {
								$scope.loadingHourType = false;
								$scope.hourTypeTable.settings().total = result.TotalCount;
								$scope.hourTypeTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									$scope.loadingHourType = false;
									repository.growl(error, 'danger');
								});
					}
				});
		};

		getHourTypes();

		$scope.updateHourType = function (hourType) {
			modalInstance = $modal.open({
				templateUrl: 'app/hourType/views/editHourTypeModal.html',
				size: 'md',
				animation: false,
				controller: 'HourTypeController',
				resolve: {
					$stateParams: function () {
						return { isModal: true, isUpdate: true, guid: hourType.Guid, cancel: $scope.modalCancel, ok: $scope.modalOk };
					},
				}
			});

			modalInstance.result.then(function (result) {
				$scope.hourTypeTable.reload();
			}, function (error) { });
		};

		$scope.createHourType = function () {
			modalInstance = $modal.open({
				templateUrl: 'app/hourType/views/editHourTypeModal.html',
				size: 'md',
				animation: false,
				controller: 'HourTypeController',
				resolve: {
					$stateParams: function () {
						return { isModal: true, isUpdate: false, guidProject: $scope.project.Guid, cancel: $scope.modalCancel, ok: $scope.modalOk };
					},
				}
			});

			modalInstance.result.then(function (result) {
				$scope.hourTypeTable.reload();
			}, function (error) { });
		};

		$scope.modalCancel = function () {
			modalInstance.dismiss('canceled');
		};

		$scope.modalOk = function () {
			modalInstance.close();
		};

		$scope.deleteHourType = function (hourType) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-hourType-delete-message', 'Time typen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-hourType-delete-button-confirm', 'Ja, fjern time typen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			},
				function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.hourType.url, hourType.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-hourType-delete-success', 'Time typen ble fjernet!'), result, "success");
							repository.growl(translationService.translate('web-workorder-hourType-removed', 'Time typen er fjernet'), 'success');
							$scope.hourTypeTable.reload();
						});
				});
		};

	}
})();
