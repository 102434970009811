(function () {
	angular.module('Plania').controller('ControlListLogItemController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'controlListLogItem.edit' || ($stateParams.isModal && $stateParams.isUpdate);
		$scope.activateAutocomplete = false;
		$scope.isModal = $stateParams.isModal;
		$scope.model = {};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.HourType, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.HourType, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.HourType);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.HourType);
			}
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.controlListLogItem.url, $stateParams.guid, null).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		} else {
			$scope.activateAutocomplete = true;
		}

		$scope.cancel = function () {
			$stateParams.cancelModal();
		};

		$scope.closeModal = function () {
			$stateParams.closeModal();
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-controlListLogItem-success', 'Loggepunktet har blitt oppdatert.') : translationService.translate('create-controlListLogItem-success', 'Loggepunktet har blitt opprettet.'), 'success');
				if ($scope.isModal) {
					$scope.closeModal();
				} else {
					var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
					repository.commonService.setLastRegisterGuid(destination, guid);
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				repository.growl(typeof (error) === "string" ? error : error.Data.Message, 'danger');
			};

			($scope.isUpdate ? repository.updateSingle : repository.createSingle)(repository.apiData.controlListLogItem.url, $scope.model).then(success, error);
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListLogItem-delete-message',
					'Loggepunktet vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListLogItem-delete-button-confirm',
					'Ja, fjern loggepunktet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			},
				function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.controlListLogItem.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-controlListLogItem-delete-success', 'Loggepunktet ble fjernet!'),
							result,
							"success");
						repository.growl(translationService.translate('web-controlListLogItem-removed', 'Loggepunktet er fjernet'),
							'success');
						if ($scope.isModal) {
							$modalStack.dismissAll();
						} else {
							$scope.goBack('controlListLogItem.list', { menuGuid: $scope.navigation.params.menuGuid });
						}
					});
				});
		};
	}

})();
