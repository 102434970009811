(function () {
	angular.module('Plania').controller('ControlListPublishModalController', ['$scope', '$modalInstance', 'Repository', 'TranslationService', 'params', controller]);

	function controller($scope, $modalInstance, repository, translationService, params) {
		var model = params.model;
		$scope.rules = {
			replaceNewControlListsOnWorkOrder: true,
		};

		$scope.countRelations = null;

		repository.getSingleWithNoParameter(repository.apiData.controlList.endpoint.countRelations + model.Guid).then(function(response) {
			$scope.countRelations = response;
		}, function(error) {
			$scope.countRelations = {};
			repository.showError(error);
		});

		$scope.isRunningAction = false;

		$scope.publish = function () {
			$scope.isRunningAction = true;

			repository.runAction(repository.apiData.controlList.endpoint.publish + model.Guid, model, "replaceNewControlListsOnWorkOrder=" + $scope.rules.replaceNewControlListsOnWorkOrder, true).then(function (response) {
				repository.growl(translationService.translate('web-controlList-publishDraft-success', 'Utkastet er publisert'), "success");
				$scope.isRunningAction = false;
				$modalInstance.close();
			}, function (error) {
				$scope.isRunningAction = false;
				repository.showError(error);
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
