(function () {
	//'$http', 'config', 'genericRepository', '$q',
	angular.module('Plania').factory('ModuleService', ['$localStorage', '$rootScope', moduleService]);

	function moduleService($localStorage, $rootScope) {

		var moduleData = { isLoaded: false };

		var moduleFlag = {
			None: 0,
			Core: 1 << 0, //Grunn //1
			Activity: 1 << 1, //Aktivitet //2
			Budget: 1 << 2, //Budsjett //4
			SparePart: 1 << 3, //Reservedel //8
			Document: 1 << 4, //Dokument //16
			Energy: 1 << 5, //Energi //32
			Area: 1 << 6, //Arealforvaltning //64
			Cleaning: 1 << 7, //Renhold //128
			Key: 1 << 8, //Nøkkel //256
			Rent: 1 << 9, //Utleie Næring/bolig //512
			Invoice: 1 << 10, //ordre/faktura //1024
			InternalRent: 1 << 11, //Innleie //2048
			DutyLog: 1 << 12, //Vaktlogg //4096
			Housing: 1 << 13, //bolig //8192
			Alarm: 1 << 14, //varsling //16384
			Deviation: 1 << 15, //Avvik //32768
			Mobile: 1 << 16, //Plania mobil //65536
			Map: 1 << 17, //Kart //131072
			CleaningWeb: 1 << 18, //Renhold web //262144
			FireManual: 1 << 19, //brannbok //524288
			HSEManual: 1 << 20, //hms bok //1048576
			ElectroManual: 1 << 21, //elektro bok //2097152
			DataAcquisition: 1 << 22, //Datafangst //	4194304
			Booking: 1 << 23, //Datafangst //
			//General: 1 << 24,
			//Administration: 1 << 25,
			EntityComment: 1 << 26, //EntityComment //
			InventoryControl: 1 << 27, //134217728
			ExtendedConditionControl: 1 << 28, //268435456
			ControlList: 1 << 29, //536870912
			Api: 1 << 30, // Plania API // 1073741824
			// Bitwise operator in JS is max 32 bit
			ProjectManagement: Math.pow(2, 31), // 2147483648
			//CommonDataOwner: Math.pow(2, 32), // 4294967296
			EntityLink: Math.pow(2, 33), //8589934592 
			Sms: Math.pow(2, 34), //17179869184
			IFCBimsyncHosting: Math.pow(2, 35), //34359738368
			DataImport: Math.pow(2, 36), //68719476736
			Elhub: Math.pow(2, 37), //137438953472
			Brand: Math.pow(2, 38), //274877906944
		};

		var moduleIcons = {
			None: 'fa-cube',
			Core: 'fa-power-off',
			Activity: 'fa-tasks',
			Budget: 'fa-money-bill-alt',
			SparePart: 'fa-plug',
			Document: 'fa-file',
			Energy: 'fa-bolt',
			Area: 'fa-layer-group',
			Cleaning: 'fa-broom',
			Key: 'fa-key',
			Rent: 'fa-building',
			Invoice: 'fa-file-invoice-dollar',
			InternalRent: 'fa-store-alt',
			Housing: 'fa-home',
			DutyLog: 'fa-shield-alt',
			Alarm: 'fa-bell',
			Deviation: 'fa-exclamation-triangle',
			Mobile: 'fa-mobile-alt',
			Map: 'fa-map',
			CleaningWeb: 'fa-vacuum',
			FireManual: 'fa-fire-extinguisher',
			HSEManual: 'fa-hospital',
			ElectroManual: 'fa-battery-half',
			DataAcquisition: 'fa-download',
			Booking: 'fa-hotel',
			EntityComment: 'fa-comments',
			InventoryControl: 'fa-archive',
			ExtendedConditionControl: 'fa-tasks',
			ControlList: 'fa-list-ul',
			Api: 'fa-share-alt',
			ProjectManagement: 'fa-chart-waterfall',
			EntityLink: 'fa-link',
			Sms: 'fa-lock',
			IFCBimsyncHosting: 'fa-cube',
			DataImport: 'fa-upload',
			Elhub: 'fa-plug',
			Brand: 'fa-palette'
		};

		var resetModules = function () {
			moduleData = { isLoaded: false };
			delete $localStorage.moduleData;
		};

		var setModules = function (stringModules) {
			var modules = stringModules.split(', ');
			var mod = 0;
			var stringRepresentation = '';
			modules.forEach(function (module) {
				stringRepresentation += ', ' + module;
				mod += moduleFlag[module];
			});

			moduleData.modules = mod;
			moduleData.stringModules = stringRepresentation;
			$localStorage.moduleData = moduleData;
			moduleData.isLoaded = true;
			$rootScope.$broadcast($rootScope.events.updatedModuleData);
		};

		var bitwiseAnd = function (a, b) {
			if (b <= 2147483648) return a & b;

			// Bitwise AND on variables over 32 bits https://stackoverflow.com/a/3637790
			var w = 4294967296; // 2^32

			var aHI = a / w;
			var aLO = a % w;
			var bHI = b / w;
			var bLO = b % w;

			return (aHI & bHI) * w + (aLO & bLO);
		};

		var hasModule = function (module) {

			if (!moduleData.isLoaded) {
				var storageModuleData = angular.fromJson($localStorage.moduleData);

				if (storageModuleData) {
					moduleData = storageModuleData;
				}
				moduleData.isLoaded = true;
			}
			return !!bitwiseAnd(moduleData.modules, module);
		};

		var getModuleIcon = function (moduleName) {
			return moduleIcons[moduleName];
		};

		var getModuleData = function () {
			return angular.copy(moduleData);
		};

		return {
			moduleFlags: moduleFlag,
			resetModules: resetModules,
			setModules: setModules,
			hasModule: hasModule,
			getModuleIcon: getModuleIcon,
			getModuleData: getModuleData
		};
	}
})
	();
