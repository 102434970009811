(function () {
    angular.module('Plania').controller('SelectPersonRoleModalContreoller', ['$scope', '$modalInstance', 'params', 'Repository', controller]);

    function controller($scope, $modalInstance, params, repository) {
        $scope.persons = params.selectedPersons;


        $scope.ok = function () {
            $modalInstance.close($scope.persons);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
