(function () {
    angular.module('Plania').factory('GeneralOptionsService', ['$localStorage', 'Repository','$rootScope', '$q', generalOptionsService]);

	function generalOptionsService($localStorage, repository, $rootScope, $q) {
        var service = {};

		service.getGeneralOptionsAsync = function (skipCache) {
			var deferred = $q.defer();

			if (!skipCache && $localStorage.generalOptions) {
				deferred.resolve($localStorage.generalOptions);
			}

			repository.getSingleWithNoParameter(repository.apiData.generalOptions.url).then(function (result) {
                $localStorage.generalOptions = result.Data;
				$rootScope.customization.customerId = result.Data.CustomerId;
				deferred.resolve($localStorage.generalOptions);
			}, function (error) {
				swal('Feil ved henting av Kundeprofil', error, 'error');
				deferred.reject('Error getting general options');
			});

			return deferred.promise;
		};

		service.refreshGeneralOptions = function() {
			service.getGeneralOptionsAsync(true);
		};

        return service;
    }
})
();
