(function () {
	angular.module('Plania').controller('ArticleController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'EnumService', controller]);

	function controller($scope, repository, $stateParams, translationService, enumService) {
		$scope.isUpdate = $scope.navigation.current.name === 'article.edit';
		$scope.modelDto = {};
		$scope.model = {};
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};
		$scope.prefix = repository.commonService.prefix.Article;
		$scope.units = enumService.getUnits();

		$scope.getUnitValue = function (value) {
			var returnValue = _.find($scope.units, function (o) { return o.value === value; });
			return returnValue ? returnValue.translatedValue : '-';
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Article, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Article, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Article);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Article);
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			return filter;
		};

		if ($scope.isUpdate) {
			var columns = ['Account.Id', 'Account.Description', 'CostCenter.Id', 'CostCenter.Description', 'Department.Id', 'Department.Description', 'Accounting0.Id', 'Accounting0.Description', 'Accounting1.Id', 'Accounting1.Description', 'Accounting2.Id', 'Accounting2.Description', 'Accounting3.Id', 'Accounting3.Description', 'Accounting4.Id', 'Accounting4.Description'];
			repository.getMainModel(repository.apiData.article.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.model.isDisabled = !!$scope.model.DisabledFromDate;
					$scope.reload = true;

					updateAccess();
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		} else {
			var article = $scope.model;
			article.PriceAdjustmentType = 'Undefined';
			article.Type = 'Undefined';
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-article-success', 'Artikkel har blitt oppdatert.') : translationService.translate('create-article-success', 'Artikkel har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				delete $scope.modelDto;
				delete $scope.model.Account;
				delete $scope.model.Department;
				delete $scope.model.CostCenter;
				delete $scope.model.Accounting0;
				delete $scope.model.Accounting1;
				delete $scope.model.Accounting2;
				delete $scope.model.Accounting3;
				delete $scope.model.Accounting4;

				repository.updateSingle(repository.apiData.article.url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData.article.url, $scope.model).then(success, error);
			}
		};

		$scope.cancel = function () {
			$scope.goBack('article.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-article-message', "Artikkel vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-article-button-confirm', 'Ja, fjern artikkel'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.article.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-article-success', 'Artikkel ble fjernet!'), result, "success");
						$scope.goBack('article.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.isFieldEnabled = function (field) {
			if ($scope.restrictEdit)
				return false;

			var article = $scope.model;

			switch (field) {
				case 'UnitPrice':
					return article.PriceAdjustmentType === 'Undefined';
				case 'PriceAdjustmentType':
					return article.Type !== 'Cost';
				case 'PriceAdjustmentValue':
				case 'CostPrice':
					return article.Type !== 'Cost' && article.PriceAdjustmentType !== 'Undefined';
				case 'ShowPriceAdjustmentOnOwnLine':
					return !['Undefined', 'FixedAmount'].includes(article.PriceAdjustmentType);
			}

			return true;
		};

		$scope.isFieldVisible = function (field) {
			var article = $scope.model;

			switch (field) {
				case 'RentalPeriodNumber':
				case 'RentalPeriodUnit':
					return article.CanBeRentedOut;
				case 'PriceAdjustmentType':
					return article.Type !== 'Cost';
				case 'PriceAdjustmentValue':
				case 'CostPrice':
					return article.Type !== 'Cost' && article.PriceAdjustmentType !== 'Undefined';
				case 'MergeInInvoice':
				case 'ShowPriceAdjustmentOnOwnLine':
					return !['Undefined', 'Sale', 'Cost', 'FixedPrice'].includes(article.Type);
			}

			return true;
		};

		$scope.calculateSalePrice = function () {
			var article = $scope.model;

			switch (article.PriceAdjustmentType) {
				case 'Undefined':
					// unit price is no calculated, but given
					break;
				case 'FixedAmount':
					article.UnitPrice = article.PriceAdjustmentValue;
					break;
				case 'AddedAmount':
					article.UnitPrice = article.CostPrice + article.PriceAdjustmentValue;
					break;
				case 'AddedFactor':
					article.UnitPrice = Math.round(article.CostPrice * (1.0 + article.PriceAdjustmentValue));
					break;
				default:
					break;
			}

		};

		$scope.onChange = function (field) {
			switch (field) {
				case 'PriceAdjustmentType':
				case 'PriceAdjustmentValue':
				case 'CostPrice':
					$scope.calculateSalePrice();
			}
		};

		$scope.onDisabledFromDateCheckboxChange = function () {
			if ($scope.model.isDisabled) {
				$scope.model.DisabledFromDate = new Date().toISOString();
			} else {
				$scope.model.DisabledFromDate = null;
			}
		};

		// by waiting directive to show factors as percents, convert them manually
		$scope.formattedPriceAdjustmentValue = function (value) {
			var article = $scope.model;
			if (angular.isDefined(value))
				article.PriceAdjustmentValue = (article.PriceAdjustmentType === 'AddedFactor') ? value / 100 : value;
			else
				return (article.PriceAdjustmentType === 'AddedFactor') ? article.PriceAdjustmentValue * 100 : article.PriceAdjustmentValue;
		};
	}

})();
