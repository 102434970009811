(function () {
    angular.module('Plania').filter('translate', ['TranslationService', translationFilter]);

    function translationFilter(translationService) {
        return function (defaultValue, systemName, model) {
            var value = translationService.translate(systemName, defaultValue, model);
            var matches = value.match(/%[a-zA-Z0-9\.]*%/g);
            if (matches) {
                matches.forEach(function (match) {
                    var property = match.replace(new RegExp('%', 'g'), "");
                    value = model[property];
                    var properties = property.split('.');
                    properties.forEach(function(prop) {
                        value = model[prop];
                    });
                    //value = value.replace(new RegExp(match, 'g'), model[property]);
                });
            }
            return value;
        };
    }
})();
