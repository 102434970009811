(function () {
    var app = angular.module('Plania');

    app.directive('plHtmlTextWidget', ['TranslationService', function (translationService) {
        return {
            restrict: 'A',
            require: '^plDashboardGrid',
            scope: {
                widget: '=widget',
                edit: '=edit',
                saveFunction: '=saveFunction'
            },
            link: function (scope, element, attrs, gridCtrl) {
                scope.$parent.attachWidget(element, attrs);

                scope.removeWidget = function () {
                    swal({
                        title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                        text: translationService.translate('web-swal-dashboard-link-message', "Linkene vil bli permanent fjernet!"),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f44336",
                        confirmButtonText: translationService.translate('web-swal-dashboard-link-confirm', 'Ja, fjern linkene'),
                        cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                        closeOnConfirm: false
                    }, function () {
                        window.onkeydown = null;
                        window.onfocus = null;
                        swal(translationService.translate('web-swal-dashboard-link-success', 'Linkene ble fjernet!'), '', "success");
                        scope.$parent.removeWidget(element, attrs);
                    });
				};
            },
            templateUrl: 'app/dashboard/directives/widgets/htmlTextWidget.html',
			controller: ['$scope', 'CommonService', 'Repository', '$rootScope', '$sce', '$modal', '$filter', safeController],
        };
	}]);

	function safeController($scope, commonService, repository, $rootScope, $sce, modal, $filter) {
		try {
			controller($scope, commonService, repository, $rootScope, $sce, modal, $filter);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, commonService, repository, $rootScope, $sce, modal, $filter) {
		$scope.model = $scope.widget;
		$scope.viewModel = '';
		$scope.selectedBuildingGuid = commonService.getFilterData().selectedBuilding.Guid;
		$scope.selectedSelectionGuid = commonService.getFilterData().selectedSelection.Guid;
		var domainModel = {};

		if (typeof ($scope.model.WidgetData) === "string") {
			$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
		}

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false).then(function () {
					setHtmlBodyWithValues();
				});
			}, function () {
				//Dismissed
			});
		};

		function setHtmlBody(tmpString, properties, mappedProperties, userProperties) {
			if (properties && properties.length > 0) {
				for (var i = 0; i < properties.length; i++) {
					tmpString = tmpString.replace(properties[i], getPropertyValue(mappedProperties[i]));
				}
			}

			if (userProperties && userProperties.length > 0) {
				var mappedUserProperties = userProperties.map(function (x) { return x.match(/[\@\w\.]+/)[0]; });
				for (var j = 0; j < userProperties.length; j++) {
					tmpString = tmpString.replace(userProperties[j], $rootScope.userInfo[mappedUserProperties[j].replace('@CurrentUser.', '')]);
				}
			}

			if (domainModel && domainModel.Images && Array.isArray(domainModel.Images)) {
				var defaultImage = domainModel.Images.find(function (img) {
					return img.IsDefault;
				});
				if (defaultImage)
					domainModel.GuidDefaultImage = defaultImage.Guid;
				else
					delete domainModel.GuidDefaultImage;
				tmpString = tmpString.replace('src="/images/plania-placeholder-bordered-759x500.png"', 'src="' + $rootScope.imageApiUrl + domainModel.GuidDefaultImage + '"');
			}

			// #22293 tmpString is vulnerable to XSS, cannot use built in sanitizer as it will remove tinyMce styles
			$scope.viewModel = $sce.trustAsHtml(tmpString);
		}

		function getPropertyValue(property) {
			var properties = property.split('.');
			var propertyValue = domainModel;

			properties.forEach(function (prop) {
				if (propertyValue) {
					propertyValue = propertyValue[prop];
				}
			});

			if (typeof (propertyValue) === 'string' && isNaN(Number(propertyValue)) && moment(propertyValue).isValid()) {
				propertyValue = $filter('date')(propertyValue, 'dd.MM.yyyy');
			}

			return propertyValue ? propertyValue : '';
		}

		function setHtmlBodyWithValues() {
			if (!$scope.model.WidgetData) return;
			if ($scope.model.WidgetData.prefix && !$scope.selectedBuildingGuid && !$scope.selectedSelectionGuid) return;
			var string = $scope.model.WidgetData.htmlTextBody;
			if (!string) return;

			var properties = string.match(/{{\s*[\w\.]+\s*}}/g);
			var userProperties = string.match(/{{\@\s*[\w\.]+\s*}}/g);

			if (!properties)
				properties = [];

			var mappedProperties = properties.map(function (x) { return x.match(/[\w\.]+/)[0]; });
			var dottedColumns = _.filter(mappedProperties, function (o) { return o.indexOf('.') !== -1; });

			var tmpString = string;
			// check with prefix, but if we want to support more objects, then to separate between building and selectedBuilding there exists a property in widgetData to separate them (fromActiveBuilding)
			if ($scope.model.WidgetData.prefix && ($scope.selectedBuildingGuid || $scope.selectedSelectionGuid)) {
				// Selecting a buildingSelection with one building will prefill with that building, but will not trigger newSelection again.
				// Use getSingle if we know exactly which building is selected, since it is twice as fast as getPaginated.
				if ($scope.selectedBuildingGuid) {
					repository.getSingle(repository.apiData.building.url, $scope.selectedBuildingGuid, JSON.stringify(dottedColumns)).then(function (response) {
						domainModel = response.Data;
						setHtmlBody(tmpString, properties, mappedProperties, userProperties);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				} else if ($scope.selectedSelectionGuid) {
					repository.GetPaginated(repository.apiData.building.url, 0, 1, { id: "asc" }, null, null, JSON.stringify(mappedProperties)).then(function (response) {
						if (response && response.TotalCount === 1) {
							$scope.selectedBuildingGuid = response.List[0].Guid;
							domainModel = response.List[0];
							setHtmlBody(tmpString, properties, mappedProperties, userProperties);
						}
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			} else {
				setHtmlBody(tmpString, properties, mappedProperties, userProperties);
			}
		}
		setHtmlBodyWithValues();

		$rootScope.$on($rootScope.events.newSelection, function () {
			if ($scope.model.WidgetData && ($scope.selectedBuildingGuid !== commonService.getFilterData().selectedBuilding.Guid || $scope.selectedSelectionGuid !== commonService.getFilterData().selectedSelection.Guid)) {
				$scope.selectedBuildingGuid = commonService.getFilterData().selectedBuilding.Guid;
				$scope.selectedSelectionGuid = commonService.getFilterData().selectedSelection.Guid;
				setHtmlBodyWithValues();
			}
		});
	}
})();
