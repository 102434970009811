(function () {
	angular.module('Plania').controller('CleaningInfoController', ['$scope', 'Repository', 'TranslationService', 'NgTableParams', 'ListService','DocumentService', '$modal', controller]);

	function controller($scope, repository, translationService, ngTableParams, listService,documentService, $modal) {
		$scope.documents = [];
		$scope.showCleaningHistory = false;
		$scope.init = function (guidArea, task) {
			$scope.guidArea = guidArea;
			$scope.task = task;
			getData(guidArea);
		};

		repository.getSingle(repository.apiData.person.url, $scope.userInfo.guidPerson).then(function (result) {
			$scope.person = result.Data;
		});

		$scope.cleaningCompletionColumns = [
            { Position: 1, Title: translationService.translate('web-cleaningTask-id'), Property: 'CleaningTask.Id', PropertyType: 'string' },
            { Position: 2, Title: translationService.translate('web-cleaningCompletion-explanatoryText', 'Kommentar'), Property: 'ExplanatoryText', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-cleaningCompletion-CompletionDate', 'Utført dato'), Property: 'CompletionDate', PropertyType: 'date' },
		];

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.getTranslatedValueType = function (type) {
			return translationService.translate('web-enum-FieldType-' + type);
		};

		$scope.showDocuments = repository.authService.hasReadAccess(repository.commonService.prefix.Document);
		$scope.showVideos = repository.authService.hasReadAccess(repository.apiData.videoXEntity.prefix);

		var getData = function (guidArea) {
			var columns = ['Area.Guid', 'CleaningTask.CleaningType', 'CleaningTask.ExplanatoryText', 'CleaningTask.Id', 'Area.CleaningComment', 'ResourceGroup.Id', 'NextCleaningDate'];
			repository.GetPaginated(repository.apiData.areaXCleaningTask.url, 0, 50, {}, { PropertyFilter: [{ Property: 'GuidArea', Operator: '=', Value: guidArea }] }, null, JSON.stringify(columns))
                .then(function (result) {
                	$scope.cleaningTasks = result.List;
                	if ($scope.cleaningTasks[0] && $scope.cleaningTasks[0].Area) {
                		$scope.area = $scope.cleaningTasks[0].Area;
                	}

                	if ($scope.task) $scope.selectedTask = _.find($scope.cleaningTasks, function (o) { return o.Guid === $scope.task.Guid; });

                	if ($scope.showDocuments)
                		getDocuments();

                	if ($scope.showVideos)
                		getVideos();
                }).catch(function (error) {
                	repository.growl(error, 'danger');
                });
		};

		var getDocuments = function () {
			var documentColumns = ['Document.Id', 'Document.Description', 'Document.FilePath'];
			$scope.documents = [];

			$scope.cleaningTasks.forEach(function (cleaningTask) {
				repository.GetPaginated(repository.apiData.documentXEntity.url, 0, 100, { 'Document.Description': 'asc' }, { GuidCleaningTask: cleaningTask.GuidCleaningTask }, null, JSON.stringify(documentColumns))
                   .then(function (result) {
                   	result.List.forEach(function (document) {
                   		if (!_.some($scope.documents, { 'Guid': document.Guid }))
                   			$scope.documents.push(document);
                   	});
                   },
                   function (error) {
                   	repository.growl(error, 'danger');
                   });
			});
		};

		var getVideos = function () {
			var videoColumns = ['Video.Id', 'Video.Description', 'Video.GuidVideoBinary', 'Video.FileName'];
			$scope.videos = [];

			$scope.cleaningTasks.forEach(function (cleaningTask) {
				repository.GetPaginated(repository.apiData.videoXEntity.url, 0, 100, { 'Video.Description': 'asc' }, { PropertyFilter: [{ Property: 'GuidCleaningTask', Operator: '=', Value: cleaningTask.GuidCleaningTask }] }, null, JSON.stringify(videoColumns))
                   .then(function (result) {
                   	result.List.forEach(function (video) {
						if(!_.some($scope.videos, {'Guid': video.Guid}))
                   			$scope.videos.push(video);
                   	});
                   },
                   function (error) {
                   	repository.growl(error, 'danger');
                   });
			});
		};

		$scope.setShowCleaningHistory = function (show) {
			if (show && !$scope.cleaningCompletionTable) {
				$scope.cleaningCompletionTable = initCleaningCompletionTable($scope.guidArea);
			}
			$scope.showCleaningHistory = show;
		};

		var initCleaningCompletionTable = function(guidArea) {
			return new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'CompletionDate': 'desc' },
					filter: { PropertyFilter: [{ Property: 'GuidArea', Operator: '=', Value: guidArea }] }
				},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function($defer, params) {
						var completionColumns = [];
						$scope.cleaningCompletionColumns.forEach(function(column) {
							completionColumns.push(column.Property);
						});

						repository.GetPaginated(repository.apiData.cleaningCompletion.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(completionColumns))
							.then(function(result) {
								$defer.resolve(result.List);
							});
					}
				});
		};

		$scope.openDocument = function (document) {
			documentService.openDocument(document.Document).then(function(result){
				$scope.isCollapsed = false;
			});
		};

		$scope.playVideo = function (video) {
			var modalInstance = $modal.open({
				size: 'lg',
				controller: 'VideoPlayerModalController',
				templateUrl: 'app/video/views/videoPlayerModal.html',
				resolve: {
					params: function () {
						return {
							video: video.Video,
							guidVideoBinary: video.Video.GuidVideoBinary
						};
					}
				}
			});
		};

		$scope.undoCleaning = function (task) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaning-undo-message', "Den registrete renholdsgjennomføringen vil bli fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaning-undo-button-confirm', 'Ja, tilbakestill renholdet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.cleaningCompletion.url, task.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cleaning-undo-success', 'Renholdet ble tilbakestilt!'), result, "success");
						$scope.cleaningCompletionTable.reload();
						if ($scope.toggleParentReload) $scope.toggleParentReload();
					});
			});
		};

		$scope.isToday = function (date) {
			var momentDate = moment(date);
			var today = moment();

			return today.startOf('day').isSame(momentDate.startOf('day'));
		};

		$scope.selectTask = function (task) {
			$scope.selectedTask = task;
		};

		$scope.updateAreaCleaningComment = function () {
			repository.patch(repository.apiData.area.url, $scope.area.Guid, { CleaningComment: $scope.area.CleaningComment }).then(function (result) {
				getData($scope.area.Guid);
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.getNextCleaningDateString = function (task) {
			return listService.getNextCleaningDateString(task, true);
		};
	}
})();
