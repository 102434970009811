(function () {
	angular.module('Plania').controller('EditMultipleDwgRequestController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', 'ListService', '$q', controller]);

	function controller($scope, repository, params, modalInstance, translationService, listService, $q) {
		var user = repository.authService.getUserData();

		$scope.person = {
			Caption: user.realName,
			Guid: user.guidPerson
		};
		$scope.activateAutocomplete = false;

		$scope.cleaningTasks = [];
		$scope.cleaningTasksGrouped = [];
		$scope.cleaningTaskGroupBy = 'cleaningType';
		$scope.isAllTasksSelected = false;
		$scope.selectedTasks = [];

		$scope.showMultiSelectView = false;
		$scope.multiSelectedAreaXCleaningTasks = [];
		$scope.templateCleaningTask = {};

		$scope.cancelExitCleaning = false;

		var wasUpdated = false;

		var columns = [
			'AverageCleaningPerYear', 
			'CleaningTask.Id', 'CleaningTask.CleaningType', 'CleaningTask.AverageCleaningPerYear', 'CleaningTask.ExplanatoryText',
			'Area.Id', 'Area.Description',
			'DelayedDate', 'ExitCleaningRequestStatus', 'ExitCleaningConfirmedDate', 'ExitCleaningDecisionDate', 'NextCleaningDate'
		];

		var filter = {
			showTodaysTasks: true,
			PropertyFilter: [
				{ Property: 'GuidArea', Operator: 'in', Value: params.guids.join(',') }
			]
		};

		//Wizard Setup
		$scope.tabs = [
			{ heading: translationService.translate('web-dwg-editMultipleRequests-chooseCleaningTask', 'Velg renhold'), step: 0 },
			{ heading: translationService.translate('web-dwg-editMultipleRequests-confirmRooms', 'Rediger valgte renhold'), step: 1 }
		];
		$scope.step = 0;

		$scope.isCurrentStep = function (step) {
			return $scope.step === step;
		};

		$scope.setCurrentStep = function (step) {
			if ($scope.step === 0 && $scope.selectedTasks.length === 0) return;
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		$scope.isLastStep = function () {
			return $scope.step === ($scope.tabs.length - 1);
		};

		$scope.getNextLabel = function () {
			return $scope.isLastStep() ? translationService.translate('web-button-confirm', 'Bekreft') : translationService.translate('web-button-next', 'Neste');
		};

		$scope.handlePrevious = function () {
			$scope.setCurrentStep($scope.step - ($scope.step === 0 ? 0 : 1));
		};

		$scope.handleNext = function (closeOnSave) {
			if ($scope.isLastStep()) {
				if ($scope.cancelExitCleaning) {
					$scope.saveCancelledExitCleanings(closeOnSave);
				} else {
					$scope.save(closeOnSave);
				}
				return;
			} else {
				if ($scope.step === 0 && $scope.selectedTasks.length === 0) return;
				$scope.setCurrentStep($scope.step += 1);
			}

			if ($scope.step === 1) {
				handleEditTaskTabChanged();
			}
		};

		$scope.isCurrentStepValid = function() {
			$scope.errorMessage = null;
			if ($scope.step === 0) {
				return validateSelectedTasks();
			}
			if ($scope.step === 1) {
				return true;
			}
			return true;
		};

		function validateSelectedTasks() {
			if (!$scope.selectedTasks || $scope.selectedTasks.length === 0) {
				return false;
			}
			var firstCleaningTaskGuid = _.head($scope.selectedTasks);
			var firstCleaningTask = _.find($scope.cleaningTasks, function (task) { return task.Guid === firstCleaningTaskGuid; });

			var isAllSameCleaningTask = _.every($scope.selectedTasks, function (guid) {
				var ct = _.find($scope.cleaningTasks, function (t) {
					return t.Guid === guid;
				});
				return ct.GuidCleaningTask === firstCleaningTask.GuidCleaningTask;
			});

			if (!isAllSameCleaningTask) {
				$scope.errorMessage = translationService.translate('web-cleaningTask-multiEdit-errorMessage', 'Kan ikke endre ulike oppgaver');
				return false;
			}

			return true;
		}
		//End Wizard setup

		//Tab 1 - select cleaning tasks
		getAreaXCleaningTasks();

		function getAreaXCleaningTasks() {
			var deferred = $q.defer();
			repository.GetPaginated(repository.apiData.areaXCleaningTask.url, 0, -1, {}, filter, null, JSON.stringify(columns)).then(function (result) {
				$scope.cleaningTasks = result.List;
				$scope.cleaningTasksGrouped = [];
				$scope.cleaningTasks.forEach(function (task) {
					var cleaningTaskGroup = _.find($scope.cleaningTasksGrouped, function (grouped) { return grouped.header === task.CleaningTask.Id; });

					if (!cleaningTaskGroup)
						$scope.cleaningTasksGrouped.push({ header: task.CleaningTask.Id, items: [task] });
					else
						cleaningTaskGroup.items.push(task);
				});

				$scope.cleaningTasksGrouped.sort(function (a, b) { return ('' + a.header).localeCompare(b.header); });
				checkSelectedTasks();
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				deferred.resolve();
			}).catch(function (error) {
				repository.growl(error, 'danger');
				deferred.reject(error);
			});

			return deferred.promise;
		}

		function checkSelectedTasks (skipGroup) {
			if ($scope.selectedTasks.length === $scope.cleaningTasks)
				$scope.isAllTasksSelected = true;
			else
				$scope.isAllTasksSelected = false;
			if (!skipGroup) {
				$scope.cleaningTasksGrouped.forEach(function (task) {
					var guids = task.items.map(function (item) { return item.Guid; });
					var selected = $scope.selectedTasks.filter(function (guidTask) {
						return guids.indexOf(guidTask) >= 0;
					});

					task.allTasksSelected = selected.length === guids.length;
				});
			}
		}

		$scope.selectTask = function (task) {
			if (!_.contains($scope.selectedTasks, task.Guid)) {
				$scope.selectedTasks.push(task.Guid);
				checkSelectedTasks();
			}
			else {
				for (var i = 0; i < $scope.selectedTasks.length; i++) {
					var p = $scope.selectedTasks[i];
					if (p === task.Guid) {
						$scope.selectedTasks.splice(i, 1);
						checkSelectedTasks();
						return;
					}
				}
			}
		};

		$scope.isSelected = function (guid) {
			return _.contains($scope.selectedTasks, guid);
		};

		$scope.selectMultipleTasks = function () {
			$scope.selectedTasks = [];
			if (!$scope.isAllTasksSelected)
				$scope.cleaningTasks.forEach(function (task) { $scope.selectedTasks.push(task.Guid); });

			$scope.isAllTasksSelected = !$scope.isAllTasksSelected;
			$scope.cleaningTasksGrouped.forEach(function (group) { group.allTasksSelected = $scope.isAllTasksSelected; });
		};

		$scope.selectMultipleGroupedTasks = function (group) {
			if (!group || $scope.cleaningTaskGroupBy === 'none') return;
			var list = $scope.cleaningTasksGrouped;
			var guids = _.find(list, function (l) { return l.header === group.header; }).items.map(function (item) { return item.Guid; });

			if (group.allTasksSelected) {
				$scope.selectedTasks = $scope.selectedTasks.filter(function (guidTask) {
					return guids.indexOf(guidTask) < 0;
				});
			}
			else {
				guids.forEach(function (guid) {
					if ($scope.selectedTasks.indexOf(guid) < 0)
						$scope.selectedTasks.push(guid);
				});
			}
			group.allTasksSelected = !group.allTasksSelected;

			checkSelectedTasks(true);
		};

		$scope.getNextCleaningDateString = function (task) {
			return listService.getNextCleaningDateString(task, true);
		};

		//End Tab 1 - select cleaning tasks

		//Tab 2 - edit cleaning tasks
		$scope.changeModel = {};

		function handleEditTaskTabChanged() {
			$scope.changeModel = {};
			$scope.tasksToChange = [];

			if (!$scope.selectedTasks || $scope.selectedTasks.length === 0) return;

			var selectedTaskGuid = $scope.selectedTasks[0];
			var cleaningTask = _.find($scope.cleaningTasks, function (task) { return task.Guid === selectedTaskGuid; });

			$scope.changeModel = angular.copy(cleaningTask);
			if ($scope.changeModel.CleaningType === 'ExitCleaning') {
				if (!$scope.changeModel.ExitCleaningDecisionDate)
					$scope.changeModel.ExitCleaningDecisionDate = getCurrentIsoDate('default');
				else
					$scope.changeModel.ExitCleaningDecisionDate = getCurrentIsoDate(task.ExitCleaningDecisionDate);
				if (!$scope.changeModel.ExitCleaningConfirmedDate)
					$scope.changeModel.ExitCleaningConfirmedDate = getCurrentIsoDate('default');
				else
					$scope.changeModel.ExitCleaningConfirmedDate = getCurrentIsoDate(task.ExitCleaningConfirmedDate);
			}

			$scope.exitCleaningRequestStatuses = ['1', '2'];

			_.forEach($scope.selectedTasks, function(guid) {
				var task = _.find($scope.cleaningTasks, function (t) { return t.Guid === guid; });
				$scope.tasksToChange.push(task);
			});
		}

		$scope.getCurrentIsoDate = function (input) {
			if (!input) return null;
			var date = new Date();
			var selectedDate = new Date(input);

			if (input === 'default')
				date.setHours(12, 0, 0);
			else
				date.setHours(selectedDate.getHours(), selectedDate.getMinutes());
			return date.toISOString();
		};

		$scope.hasWarnings = function (areaXCleaningTask) {
			if (areaXCleaningTask.ExitCleaningRequestStatus === 'Possible') {
				return $scope.isTimeInPast(areaXCleaningTask.ExitCleaningDecisionDate);
			}
			if (areaXCleaningTask.ExitCleaningRequestStatus === 'Confirmed') {
				return $scope.isTimeInPast(areaXCleaningTask.ExitCleaningConfirmedDate);
			}
			return false;
		};

		$scope.isTimeInPast = function (dateProperty) {
			if (!dateProperty) return false;
			var time = dateProperty;

			var currentTime = new Date().getTime();
			var selectedTime = new Date(time).getTime();
			if (currentTime > selectedTime)
				return true;
			return false;
		};

		$scope.toggleCancelExitCleaning = function() {
			$scope.cancelExitCleaning = !$scope.cancelExitCleaning;
		};

		//End Tab 2 - edit cleaning tasks
		$scope.saveCancelledExitCleanings = function(closeOnSave) {
			var changeList = [];

			_.forEach($scope.tasksToChange, function(task) {
				var cleaningCompletion = {
					GuidCleaningTask: task.GuidCleaningTask,
					GuidArea: task.GuidArea,
					GuidPerson: user.guidPerson,
					GuidResourceGroup: user.guidPersonResourceGroup,
					ExitCleaningDecisionDate: task.ExitCleaningDecisionDate,
					ExitCleaningConfirmedDate: task.ExitCleaningConfirmedDate,
					ExitCleaningRequestStatus: task.ExitCleaningRequestStatus,
					ExitCleaningCompletionStatus: 'Cancelled'
				};
				changeList.push(cleaningCompletion);
			});

			repository.createMultiple(repository.apiData.cleaningCompletion.url, changeList).then(function (result) {
				wasUpdated = true;

				repository.growl(translationService.translate('web-cleaningTask-editMultipleRequests-success', 'Renholdsoppgaver er oppdatert'), 'success');
				if (closeOnSave) {
					modalInstance.close();
				}
				getAreaXCleaningTasks().then(function () {
					$scope.selectedTasks = [];
					$scope.setCurrentStep(0);
				});
			}, function (error) {
				repository.growl(error, 'danger');
			});
			
		};

		$scope.save = function (closeOnSave) {
			var changeList = [];
			_.forEach($scope.tasksToChange, function(task) {
				if ($scope.changeModel.CleaningTask.CleaningType === 'ExitCleaning') {
					task.ExitCleaningRequestStatus = $scope.changeModel.ExitCleaningRequestStatus;

					if (task.ExitCleaningRequestStatus === 'Possible') {
						task.ExitCleaningConfirmedDate = null;
						task.ExitCleaningDecisionDate = $scope.getCurrentIsoDate($scope.changeModel.ExitCleaningDecisionDate);
					}
					else if (task.ExitCleaningRequestStatus === 'Discharged') {
						task.ExitCleaningDecisionDate = null;
						task.ExitCleaningConfirmedDate = new Date().toISOString();
					}
					else {
						task.ExitCleaningDecisionDate = null;
						task.ExitCleaningConfirmedDate = $scope.getCurrentIsoDate($scope.changeModel.ExitCleaningConfirmedDate);
					}
				}
				if ($scope.changeModel.AttributeValues && $scope.changeModel.AttributeValues.length > 0) {
					$scope.changeModel.AttributeValues.forEach(function(attribute) {
						if (attribute.ValueType === 'Date' && attribute.DateValue)
							attribute.DateValue = attribute.DateValue.toISOString();

						var attributeToCopyTo = _.find(task.AttributeValues,
							function(av) { return av.GuidEntityXAttribute === attribute.GuidEntityXAttribute; });
						if (attributeToCopyTo) {
							attributeToCopyTo.TextValue = attribute.TextValue;
							attributeToCopyTo.BitValue = attribute.BitValue;
							attributeToCopyTo.NumericValue = attribute.NumericValue;
							attributeToCopyTo.DateValue = attribute.DateValue;
						}
					});
				}

				changeList.push(task);
			});

			repository.updateMultiple(repository.apiData.areaXCleaningTask.url, changeList).then(function (result) {
				wasUpdated = true;

				repository.growl(translationService.translate('web-cleaningTask-editMultipleRequests-success', 'Renholdsoppgaver er oppdatert'), 'success');
				if (closeOnSave) {
					modalInstance.close();
					return;
				}

				getAreaXCleaningTasks().then(function () {
					$scope.setCurrentStep(0);
				});
			}, function(error) {
				repository.growl(error, 'error');
			});
		};

		$scope.ok = function (result) {
			modalInstance.close(result);
		};

		$scope.close = function () {
			if (wasUpdated) {
				modalInstance.close();
			} else {
				modalInstance.dismiss('cancel');
			}
		};
	}
})();
