(function () {
    angular.module('Plania').controller('CreateSparePartModalController', ['$scope', 'Repository', '$modalInstance', '$localStorage', 'RegisteredFieldService', 'TranslationService', '$q', controller]);

    function controller($scope, repository, $modalInstance, $localStorage, registeredFieldService, translationService, $q) {
        $scope.sparePart = {};
        $scope.component = {};
        $scope.componentSupplier = {};
        $scope.generalOptions = $localStorage.generalOptions;

		var mandatoryComponentFields = [];

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Component).then(function (result) {
			var mandatoryFields = _.filter(result, function (o) {
				if (o.DomainFieldName === 'Id' && $scope.generalOptions.UseComponentCounter)
					return false;
				return o.Mandatory;
			});

			var mappedLabels = _.map(mandatoryFields, function (o) {
				return {
					FieldName: o.DomainFieldName,
					translatedValue: translationService.translate('web-component-' + o.DomainFieldName.replace('Guid', '')).replace(':', '')
				};
			});
			mandatoryComponentFields = mappedLabels;
		});

		var mandatoryComponentXSupplierFields = [];

		registeredFieldService.getRegisteredField(repository.commonService.prefix.ComponentXSupplier).then(function (result) {
			var mandatoryFields = _.filter(result, function (o) {
				if (o.DomainFieldName === 'GuidComponent')
					return false;
				if (0)
				return o.Mandatory;
			});

			var mappedLabels = _.map(mandatoryFields, function (o) {
				return {
					FieldName: o.DomainFieldName,
					translatedValue: translationService.translate('web-componentXSupplier-' + o.DomainFieldName.replace('Guid', '')).replace(':', '')
				};
			});

			mandatoryComponentXSupplierFields = mappedLabels;
		});

		var mandatorySparePartFields = [];
		registeredFieldService.getRegisteredField(repository.commonService.prefix.SparePart).then(function (result) {
			var mandatoryFields = _.filter(result, function (o) {
				if (o.DomainFieldName === 'GuidComponent')
					return false;
				if (o.DomainFieldName === 'GuidComponentXSupplier')
					return false;
				return o.Mandatory;
			});

			var mappedLabels = _.map(mandatoryFields, function (o) {
				return {
					FieldName: o.DomainFieldName,
					translatedValue: translationService.translate('web-sparePart-' + o.DomainFieldName.replace('Guid', '')).replace(':', '')
				};
			});

			mandatorySparePartFields = mappedLabels;
		});

		var createComponent = function() {
			var deferred = $q.defer();
			if ($scope.component.Guid) {
				return $.Deferred().resolve().promise();
			} else {
				repository.createSingle(repository.apiData.component.url, $scope.component).then(function(result) {
					$scope.component.Guid = result.Guid;
					$scope.sparePart.GuidComponent = result.Guid;
					$scope.componentSupplier.GuidComponent = result.Guid;
					deferred.resolve();
				}, function (error) {
                    repository.showError(error);
					deferred.reject();
                });
			}

			return deferred.promise;
		};

		var createComponentXSupplier = function() {
			var deferred = $q.defer();

			if ($scope.componentSupplier.Guid) {
				return $.Deferred().resolve().promise();
			} else {
				if ($scope.componentSupplier.Price) {
					$scope.componentSupplier.Price = $scope.componentSupplier.Price.replace(',', '.');
				}

				repository.createSingle(repository.apiData.componentSupplier.url, $scope.componentSupplier).then(function (result) {
					$scope.componentSupplier.Guid = result.Guid;
                    $scope.sparePart.GuidComponentXSupplier = result.Guid;
					deferred.resolve();
                }, function (error) {
                    repository.showError(error);
					deferred.reject();
                });
			}
			return deferred.promise;
		};

		var createSparePart = function() {
			repository.createSingle(repository.apiData.sparePart.url, $scope.sparePart).then(function (result) {
                repository.growl('Reservedel har blitt opprettet', 'success');
                repository.commonService.setLastRegisterGuid('sparePart.edit', result.Guid);
                $modalInstance.close(result);
            }, function (error) {
                repository.showError(error);
            });
		};

		var validateMandatoryFields = function() {
			for (var i = 0; i < mandatoryComponentFields.length; i++) {
				var mandatoryComponentField = mandatoryComponentFields[i];
				if (!$scope.component[mandatoryComponentField.FieldName]) {
					swal(translationService.translate('web-createSparePart-component-missingMandatoryField', 'Manglende felt for komponent'), mandatoryComponentField.translatedValue, 'warning');
					return false;
				}
			}

			for (var j = 0; j < mandatoryComponentXSupplierFields.length; j++) {
				var mandatoryComponentXSupplierField = mandatoryComponentXSupplierFields[j];
				if (!$scope.componentSupplier[mandatoryComponentXSupplierField.FieldName]) {
					swal(translationService.translate('web-createSparePart-componentXSupplier-missingMandatoryField', 'Manglende felt for leverandør'), mandatoryComponentXSupplierField.translatedValue, 'warning');
					return false;
				}
			}

			for (var k = 0; k < mandatorySparePartFields.length; k++) {
				var mandatorySparePartField = mandatorySparePartFields[k];
				if (!$scope.sparePart[mandatorySparePartField.FieldName]) {
					swal(translationService.translate('web-createSparePart-sparePart-missingMandatoryField', 'Manglende felt for reservedel'), mandatorySparePartField.translatedValue, 'warning');
					return false;
				}
			}

			return true;
		};

        $scope.save = function () {
			if (!validateMandatoryFields()) return;

			createComponent().then(function() {
				createComponentXSupplier().then(function() {
					createSparePart();
				});
			});
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "componentCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
			return filter;
		};

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
