angular.module('Plania')
	// =========================================================================
	// CALENDAR WIDGET
	// =========================================================================

	.directive('calendarWidget', function ($compile, Repository, $timeout, TranslationService, $rootScope) {
		return {
			restrict: 'A',
			scope: {
				select: '&',
				control: '=',
				edit: '=',
				eventSource: '=',
				viewType: '=',
				editFunction: '=',
				removeFunction: '=',
				excludeResourceGroup: '=',
				excludeCompletedWorkOrders: '=',
				excludePeriodicTasks: '=',
			},
			link: function (scope, element) {
				scope.internalControl = scope.control || {};
				var toolbarHeight = 76;
				var calendarHeight = 360;

				//Create and add Action button with dropdown in Calendar header.
				scope.actionLinks = '<ul class="actions" id="fc-widget-actions" ng-hide="edit">' +
					'<li class="dropdown" dropdown>' +
					'<a href="" dropdown-toggle><i class="far fa-ellipsis-vertical"></i></a>' +
					'<ul class="dropdown-menu dropdown-menu-right">' +
					'<li>' +
					'<a href="" ng-click="viewType = \'month\'">' + TranslationService.translate('web-calendar-monthview', 'M&aring;nedsvisning') + '</a>' +
					'</li>' +
					'<li>' +
					'<a href="" ng-click="viewType = \'basicWeek\'">' + TranslationService.translate('web-calendar-weekview', 'Ukesvisning') + '</a>' +
					'</li>' +
					'<li>' +
					'<a href="" ng-click="viewType = \'basicDay\'">' + TranslationService.translate('web-calendar-dayview', 'Dagsvisning') + '</a>' +
					'</li>' +
					'</ul>' +
					'</li>' +
					'</ul>';

				var setHeight = function (height) {
					calendarHeight = height;
					element.fullCalendar('option', 'height', calendarHeight);
					element.fullCalendar('option', 'contentHeight', calendarHeight - toolbarHeight);
				};
				
				var language = TranslationService.getLocale();
				if (!language || language === 'nb-no')
					language = 'nb';

				var toDate = function (date) {
					return new Date(date.toDateString());
				};

				var today = toDate(new Date());

				element.fullCalendar({
					popoverIgnoreWindowScroll: true,
					theme: true,
					defaultView: scope.viewType,
					eventLimit: true,
					header: {
						right: '',
						center: 'prev, title, next',
						left: ''
					},
					defaultDate: new Date(),
					editable: false,
					lang: language,
					viewRender: function (view, element) {
						setHeight(calendarHeight);
					},
					events: function (start, end, timezone, callback) {
						element.find('.fc-prev-button').css('color', '');

						var endDate = new Date(end);
						// Calendar will add 1 extra day in its enddate since it works with exclusion. Our API works with inclusive, so fix here before sending.
						endDate.setDate(endDate.getDate() - 1);

						var guidPerson = $rootScope.userInfo.guidPerson;
						var guidPersonResourceGroup = $rootScope.userInfo.guidPersonResourceGroup;

						if (!guidPerson && !guidPersonResourceGroup) return;

						var workOrderFilter = Repository.getFilter({
							StartDate: new Date(start).toISOString(),
							EndDate: endDate.toISOString(),
							GuidPerson: guidPerson,
							GuidResourceGroup: guidPersonResourceGroup,
							ExcludeResource: scope.excludeResourceGroup,
							ExcludeCompletedWorkOrders: scope.excludeCompletedWorkOrders,
							ExcludePeriodicTasks: scope.excludePeriodicTasks
						});

						Repository.getWithUrlParameter(Repository.apiData.maintenance.endpoint.calendar, "", workOrderFilter).then(function (response) {
							var result = response.Data;
							if (result.HasWorkOrdersOnOlderPage)
								element.find('.fc-prev-button').css('color', '#f44336');

							callback(result.Items.map(function (item) {
								var eventColor = "wo-active";
								if (item.IsCompleted || item.IsHistoric)
									eventColor = 'wo-completed';
								else if (item.IsOverdue)
									eventColor = 'wo-overdue';
								else if (item.IsPlanned)
									eventColor = "wo-planned";
								else {
									var startDate = item.StartDate ? toDate(new Date(item.StartDate)) : null;
									var dueDate = item.DueDate ? toDate(new Date(item.DueDate)) : null;
									if ((startDate && startDate > today) || (!startDate && dueDate && dueDate > today))
										eventColor = "wo-default";
								}

								var itemEndDate;

								if (item.DueDate) {
									var date = new Date(item.DueDate);
									// Calendar works with exclusive end, while we work with inclusive. Add one day.
									date.setDate(date.getDate() + 1);
									itemEndDate = date;
								}

								var title = '';
								var titleLabel = '';
								if (item.Building && !Repository.commonService.getFilterData().selectedBuilding.Guid) {
									title = item.Building.Id + ' - ' + item.Description;
									titleLabel = TranslationService.translate('web-calendar-building', 'Bygg:');
								}
								else if (item.Estate && !Repository.commonService.getFilterData().selectedBuilding.Guid && !Repository.commonService.getFilterData().selectedEstate.Guid) {
									title = item.Estate.Id + ' - ' + item.Description;
									titleLabel = TranslationService.translate('web-calendar-estate', 'Eiendom:');
								}

								if (!title) {
									title = item.Description;
									titleLabel = item.IsPlanned ? TranslationService.translate('web-calendar-periodicTask', 'Periodisk:') : TranslationService.translate('web-calendar-workOrder', 'AO:');
								}

								return {
									id: item.Guid,
									title: title,
									titleLabel: titleLabel,
									start: item.StartDate,
									end: itemEndDate,
									allDay: true,
									className: eventColor,
									data: item,
								};
							}));
						}, function (error) {
							Repository.showError(error);
						});
					},
					eventRender: function (event, element) {
						var content = '<div>' + event.data.Description + '</div>';

						if (event.data.Building && !Repository.commonService.getFilterData().selectedBuilding.Guid) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-building', 'Bygg:') + ' ' + event.data.Building.Caption + '</div>';
						} else if (event.data.Estate && !Repository.commonService.getFilterData().selectedBuilding.Guid && !Repository.commonService.getFilterData().selectedEstate.Guid) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-estate', 'Eiendom:') + ' ' + event.data.Estate.Caption + '</div>';
						}

						if (event.data.Area) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-area', 'Areal:') + ' ' + event.data.Area.Caption + '</div>';
						}

						if (event.data.Equipment) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-equipment', 'Anlegg:') + ' ' + event.data.Equipment.Caption + '</div>';
						}

						if (event.data.Customer) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-customer', 'Kunde:') + ' ' + event.data.Customer.Description + '</div>';
						}

						if (event.data.ResourceGroup) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-resourceGroup', 'Ressurs:') + ' ' + event.data.ResourceGroup.Caption + '</div>';
						}

						if (event.data.ResponsiblePerson) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-responsiblePerson', 'Ansvarlig:') + ' ' + event.data.ResponsiblePerson.Caption + '</div>';
						}

						if (event.data.EstimatedTime) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-estimatedTime', 'Estimert tid:') + ' ' + event.data.EstimatedTime + '</div>';
						}

						if (event.data.StartDate) {
							var startDate = new Date(event.data.StartDate);
							var formattedStartDate = ("0" + startDate.getDate()).slice(-2) + "." + ("0" + (startDate.getMonth() + 1)).slice(-2) + "." + startDate.getFullYear();
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-startDate', 'Startdato:') + ' ' + formattedStartDate + '</div>';
						}

						if (event.data.DueDate) {
							var dueDate = new Date(event.data.DueDate);
							var formattedDueDate = ("0" + dueDate.getDate()).slice(-2) + "." + ("0" + (dueDate.getMonth() + 1)).slice(-2) + "." + dueDate.getFullYear();
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-dueDate', 'Antatt sluttdato:') + ' ' + formattedDueDate + '</div>';
						}

						if (event.data.EndDate) {
							var endDate = new Date(event.data.EndDate);
							var formattedEndDate = ("0" + endDate.getDate()).slice(-2) + "." + ("0" + (endDate.getMonth() + 1)).slice(-2) + "." + endDate.getFullYear();
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-endDate', 'Ferdigdato:') + ' ' + formattedEndDate + '</div>';
						}

						$(element).popover({
							placement: 'top',
							trigger: 'mouseenter',
							container: 'body',
							html: true,
							title: '<div>' + TranslationService.translate('web-calendar-id', 'Id:') + ' ' + event.data.Id + '</div>',
							content: content
						});
					},
					eventMouseover: function (event, jsEvent, view) {
						$(this).popover('show');
					},
					eventMouseout: function (event, jsEvent, view) {
						$(this).popover('hide');
					},
					eventClick: function (event, jsEvent, view) {
						$(this).popover('hide');
						var state = event.data.IsPlanned ? 'periodicTask.edit' : 'workOrder.edit';
						scope.$root.navigation.go(state, { guid: event.data.Guid });
					}
				});

				scope.internalControl.resizeCalendar = function (height) {
					setHeight(height);
				};

				scope.$watch('viewType', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					element.fullCalendar('changeView', scope.viewType);
				});
				
				scope.$on($rootScope.events.newSelection, function () {
					element.fullCalendar('refetchEvents');
				});

				//Add action links in calendar header
				element.find('.fc-toolbar').append($compile(scope.actionLinks)(scope));
			}
		};
	})

	//Change Calendar Views
	.directive('calendarView', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.on('click', function () {
					$('#calendar-widget').fullCalendar('changeView', attrs.calendarView);
				});
			}
		};
	});
