angular.module('Plania').directive('gantt', function () {
    return {
        restrict: 'E',
        scope: {
            tasks: '=tasks',
            viewMode: '=viewMode',
            filters: '=filters',
			count: '=count',
			columnData: '=columnData',
			reload: '=reload', //Optional callback to reload the data
        },
		controller: ['$scope', '$modal', controller],
        templateUrl: '/app/maintenancePlan/gantt.html'
    };

	function controller(scope, $modal) {
        var monthArray = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"];

		var updatedModel = null;

		var windowScrollY = window.scrollY;
		var panelScrollX = 0;

		var renderGantt = function () {
			$(".gantt").gantt({
				source: scope.tasks ? scope.tasks : [],
				columnData: !!scope.columnData ? scope.columnData : [],
				scale: scope.viewMode ? scope.viewMode : "days",
				scrollToToday: true,
				maxScale: "months",
				minScale: "days",
				itemsPerPage: scope.count,
				months: monthArray,
				dow: ["S", "M", "T", "O", "T", "F", "L"],
				waitText: "",
				useCookie: true,
				onItemClick: function (data) {
					windowScrollY = window.scrollY;
					panelScrollX = $(".gantt .rightPanel").scrollLeft();

					var shouldReload = false;
					if (data.GuidWorkOrder) {
						$modal.open({
							templateUrl: 'app/workOrder/views/editWorkOrderModal.html',
							size: 'lg',
							controller: 'WorkOrderController',
							resolve: {
								$stateParams: function () {
									return {
										guid: data.GuidWorkOrder,
										isModal: true,
										viewMode: false,
										onSaved: function (model) {
											shouldReload = true;
											updatedModel = model;
										}
									};
								},
							}
						}).result.then(function () {
							if (shouldReload)
								scope.reload();
						}, function () {
							if (shouldReload)
								scope.reload();
						});
					} else {
						scope.$root.navigation.go('periodicTask.edit', { guid: data.GuidPeriodicTask, menuGuid: scope.$root.navigation.params.menuGuid });
					}
				},
				onAddClick: function (dt, rowId) {

				},
				onScrollTo: function () {
					if (updatedModel) {
						$(".gantt .rightPanel").scrollLeft(panelScrollX);
						window.scrollTo({ top: windowScrollY });

						updatedModel = null;
						return { useStandardNavigation: false };
					}
				},
				onRender: function (settings) {
					$(".gantt-task-controls, .gantt-task-footer").css('display', 'block');
					$(".gantt-task-controls").height($(".spacer").height());

					switch (settings.scale) {
						case "days":
							$(".row .date").height($(".dataPanel").height() - 24);
							$('.dataPanel').css("background-position", "24px 25px");
							break;
						case "weeks":
							$(".row .day").height($(".dataPanel").height() - 48);
							$('.dataPanel').css("background-position", "24px 25px");
							break;
						case "months":
							$(".row.year:not(.data-row)").height($(".dataPanel").height());
							$('.dataPanel').css("background-position", "24px 1px");
							break;
					}
				}
			});
		};
		if (scope.tasks && scope.tasks.length)
			renderGantt();

		scope.$watchGroup(['tasks'], function (newValues, oldValues, scope) {
			if (newValues === oldValues || !newValues) return;
			renderGantt();
		});
    }
});
