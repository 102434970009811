(function () {
	angular.module('Plania').controller('BuildingTemplateController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', '$state', '$location', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService, $state, $location) {
		$scope.isUpdate = $scope.navigation.current.name === 'buildingTemplate.edit';
		$scope.reloadDocumentTable = false;
		$scope.model = { Guid: $stateParams.guid,IsTemplate:true };

		var availableHashLocations = ["#info", "#periodicTask", "#equipment", "#building"];
		var updateLocation = function () {
			var hash = window.location.hash;
			if (hash && availableHashLocations.includes(hash)) {
				$('#buildingTemplateTabs a[data-target="' + hash + '"]').tab('show');
				$scope.currentTab = hash.substr(1);
			}
			else {
				$('#buildingTemplateTabs a[data-target="#info"]').tab('show');
				$scope.currentTab = 'info';
			}
		};

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			updateLocation();
		});
		$scope.setTab = function (tab) {
			$location.hash(tab).replace();
		};

		if ($scope.isUpdate && $stateParams.tab) {
			$scope.setTab($stateParams.tab);
			updateLocation();
		} else {
			updateLocation();
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Building, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Building, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Building);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Building);
			}
		};

		updateAccess();

		if ($scope.isUpdate) {
			repository.getMainModel(repository.apiData.building.url, $stateParams.guid).then(function (response) {
				$scope.reload = true;
				$scope.model = response.Data;

				if (!$scope.model.IsTemplate) {
					// move to correct edit page, and use location "replace" to avoid having this page in browser history
					$state.go('building.edit', { guid: $scope.model.Guid, model: $scope.model }, { location: 'replace' });
					return;
				}

				updateAccess();
				$scope.reloadDocumentTable = true;
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			},
				function (error) {
					repository.growl(error, 'danger');
				});
		} else {

			$scope.activateAutocomplete = true;
		}

		$scope.updateBuildingTemplate = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? "Byggmal har blitt oppdatert." : 'Byggmal har blitt opprettet', 'success');
				var buildingTemplateGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, buildingTemplateGuid);
				var hash = window.location.hash;
				if (hash) hash = hash.substr(1);

				$scope.goBack(destination, { guid: buildingTemplateGuid, menuGuid: $scope.navigation.params.menuGuid, tab: hash });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.building.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingle(repository.apiData.building.url, $scope.model).then(success, error);
			}
		};

		$scope.removeBuildingTemplate = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-buildingTemplate-message', "Byggmal vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-buildingTemplate-button-confirm', 'Ja, fjern byggmal'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.building.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-buildingTemplate-success', 'Byggmal ble fjernet!'), result, "success");
						repository.growl('Byggmal har blitt fjernet', 'success');
						$scope.goBack('buildingTemplate.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal({
							title: "Kunne ikke slette byggmal!",
							text: error.Message + "<br/><br/> Vil du deaktivere byggmal?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						},
							function (isConfirm) {
								window.onkeydown = null;
								window.onfocus = null;
								if (isConfirm) {
									$scope.model.Id = '#' + $scope.model.Id;
									$scope.model.IsDeactivated = true;
									$scope.updateBuildingTemplate('buildingTemplate.list');
								}
							}
						);
					});
			});
		};


		$scope.cancel = function () {
			$scope.goBack('buildingTemplate.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
