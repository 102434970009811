(function () {
	angular.module('Plania').factory('focus', ['$timeout', '$window', function ($timeout, $window) {
		return function (id) {
			// timeout makes sure that it is invoked after any other event has been triggered.
			// e.g. click events that need to run before the focus or
			// inputs elements that are in a disabled state but are enabled when those events
			// are triggered.
			$timeout(function () {
				var chosenElement = $window.document.getElementById(id).getElementsByClassName('chosen-container')[0];

				if (chosenElement) {
					chosenElement.classList.add('chosen-with-drop');
					return;
				}

				var element = $window.document.getElementById(id).getElementsByTagName('input')[0];

				if (!element) element = $window.document.getElementById(id).getElementsByTagName('textarea')[0];

				if (element) {
					element.focus();
				}
			});
		};
	}]);
})();
