(function () {
	angular.module('Plania').controller('EditPeriodicTaskXEquipmentController', ['$scope', '$modalInstance', 'params', 'Repository', 'authService', 'TranslationService', "$localStorage", 'ListService', 'Constants', controller]);

	function controller($scope, $modalInstance, params, repository, authService, translationService, $localStorage, listService, constants) {
		$scope.model = { Guid: params.guid };

		var updateAccess = function () {
			var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
			$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.PeriodicTask, checkOtherDo);
			$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.PeriodicTask, checkOtherDo);
		};
		updateAccess();

		var columns = ["Equipment.Id", "Equipment.Description", "Equipment.ConditionContextDescription"];

		repository.getSingle(repository.apiData.periodicTaskXEquipment.url, $scope.model.Guid, JSON.stringify(columns)).then(function (response) {
			$scope.model = response.Data;
			updateAccess();
		}, function (error) {
			repository.showError(error);
		});

		$scope.save = function (close) {
			repository.updateSingle(repository.apiData.periodicTaskXEquipment.url, $scope.model).then(function (response) {
				repository.growl(translationService.translate('web-periodicTaskXEquipment-modal-save-success', 'Inspeksjonsobjekt er oppdatert'), 'success');
				
				if (close)
					$modalInstance.close();

			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
