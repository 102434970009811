(function () {
	var app = angular.module('Plania');
	app.directive('reactWorkOrderTable', ['$rootScope', function ($rootScope) {
		return {
			restrict: 'E',
			scope: {},
			link: function (scope, element, attrs) {
				scope.props = {
					reload: true
				};
				$rootScope.$on($rootScope.events.newSelection, function () {
					scope.props.reload = !scope.props.reload;
				});

			},
			template: '<react-component props="props" component-name="WorkOrderTable"></react-component>'
		};
	}]);
})();

