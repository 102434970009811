(function () {
	var app = angular.module('Plania');

	app.directive('accountingSection', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel', // Model to modify. This directive will modify this directly.
				dataOwner: '=dataOwner', // Preloaded dataowner
				prefix: '=prefix', // Prefix of parent object
				viewMode: '=viewMode',
				activateAutocomplete: '=activateAutocomplete',
				restrictEdit: '=restrictEdit',
				isFieldEnabled: '=isFieldEnabled', // Function to check if the field is disabled. Sends name of property as argument and expects a truthy value back.
				useTwoColumns: '=useTwoColumns', // Allow for break on two columns
				useVerticalFields: '=useVerticalFields', // Change from horizontal to vertical layout of accounting fields
				editMode: '=editMode', // Trick to force edit mode, which hides viewmode fields. Used to "fix" error when this is contained within ngIf
			},
			controller: ['$scope', 'Repository', '$rootScope', 'AccountingService', controller],
			templateUrl: 'app/accounting/views/accountingSection.html'
		};
	});

	function controller($scope, repository, $rootScope, accountingService) {
		$scope.modelDto = {};

		var getDataOwner = function () {
			repository.getSingle(repository.apiData.dataOwner.url, repository.commonService.getFilterData().selectedDataOwner.Guid).then(function (response) {
				$scope.modelDto.dataOwner = response.Data;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		if ($scope.dataOwner)
			$scope.modelDto.dataOwner = $scope.dataOwner;
		else {
			getDataOwner();
		}

		// We need watchers for model binding, since some (SparePart) has another name for accounting field
		var accountingWatchers = {};
		var removeWatcher = function (dimNum) {
			if (dimNum === undefined) {
				[0, 1, 2, 3, 4].forEach(function (dimNum) {
					removeWatcher(dimNum);
				});
			} else {
				if (accountingWatchers['Accounting' + dimNum])
					accountingWatchers['Accounting' + dimNum]();

				if (accountingWatchers['AccountingDimension' + dimNum])
					accountingWatchers['AccountingDimension' + dimNum]();

				delete accountingWatchers['Accounting' + dimNum];
				delete accountingWatchers['AccountingDimension' + dimNum];
			}
		};
		
		$scope.onGetValidityRules = function (originalValidityRules) {
			return accountingService.onGetValidityRules($scope.modelDto.dataOwner, originalValidityRules);
		};

		$scope.onAccountSelect = function (account) {
			accountingService.onAccountSelect($scope.model, account);
		};

		[0, 1, 2, 3, 4].forEach(function (dimNum) {
			$scope["onAccounting" + dimNum + "Select"] = function (accounting) {
				removeWatcher(); // Remove all watchers since user now starts to modify
				accountingService["onAccounting" + dimNum + "Select"]($scope.model, accounting);
			};
		});

		$scope.autoCompleteFilter = function (filterName) {
			return accountingService.autoCompleteFilter($scope.model, filterName);
		};

		$scope.$on($rootScope.events.newSelection, function () {
			if ($scope.$parent && !$scope.$parent.isUpdate) {
				getDataOwner();
			}
		});

		// Register watchers for Accounting. These will unwatch when it is initialized.
		[0, 1, 2, 3, 4].forEach(function (dimNum) {
			accountingWatchers['Accounting' + dimNum] = $scope.$watch('model.Accounting' + dimNum, function (newVal) {
				if (!$scope.modelDto['Accounting' + dimNum] && newVal) {
					$scope.modelDto['Accounting' + dimNum] = newVal;
					removeWatcher(dimNum);
				}
			});
		});
	}
})();
