(function () {
	var app = angular.module('Plania');
	app.directive('buildingTemplateEquipmentDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate'
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', controller],
			templateUrl: 'app/buildingTemplate/views/buildingTemplateEquipment.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		$scope.equipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
		];

		$scope.applyEquipmentFilter = function (filter) {
			filter.TemplateContext = "All";
			return filter;
		};

		$scope.createEquipment = function () {
			var persistedData = {};

			persistedData.GuidBuilding = $scope.model.Guid;
			persistedData.Building = $scope.model;
			persistedData.IsTemplate = $scope.model.IsTemplate;

			repository.persistedData.setPersistedData('equipment.create', persistedData);

			$scope.navigation.go('equipment.create',
				{ showPersistantData: true },
				{ reload: true });
		};
	}
})();
