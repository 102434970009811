(function () {
	angular.module('Plania').controller('CreateVideoXEntityController', ['$scope', 'Repository', '$modalInstance', 'params', controller]);

	function controller($scope, repository, $modalInstance, params) {
	    $scope.isLoading = false;
		$scope.createDocumentXEntity = true;
	    $scope.isModal = true; //this controller is only invoked from modals
		$scope.video = {};
		$scope.video.GuidCleaningTask = params.guidCleaningTask || '';

        var selectedDataOwner = repository.commonService.getFilterData().selectedDataOwner;

        $scope.files = [];


		$scope.onFileSelect = function ($files) {
		    $scope.files = $scope.files.concat($files);
		};

		$scope.dragOverClass = function ($event) {
		    var items = $event.dataTransfer.items;
		    var hasFile = false;
		    if (items) {
		        for (var i = 0 ; i < items.length; i++) {
		            if (items[i].kind === 'file') {
		                hasFile = true;
		                break;
		            }
		        }
		    } else {
		        hasFile = true;
		    }
		    return hasFile ? "drop-box-dragover" : "";
		};

		var addFileId = function () {
		    $scope.files.forEach(function (r) {
		        //Generate unique id for files used for upload progress update
		        r.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
		    });
		};

		$scope.filesizeToLarge = false;

		$scope.removeFile = function (index) {
			$scope.files.splice(index, 1);
		};

		$scope.uploadVideo = function () {
		    addFileId();

		    $scope.isUploading = true;

		    repository.createSingleWithBinary(repository.apiData.videoXEntity.urlNew, $scope.video, $scope.files).then(function (result) {
		        repository.growl('Videoen har blitt lagret', 'info');
		        $modalInstance.close('Ok');
		        $scope.isUploading = false;
		        $scope.alreadyUploaded = true;
		    },
            function (error) {
                repository.growl(error[0].error, 'danger');

                $scope.isUploading = false;
                error.forEach(function (error) {
                    var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
                    if (identifiedFile) {
                        identifiedFile.progressType = 'danger';
                    }
                });
            }, function (file) { //UPDATE
                var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
                if (identifiedFile) {
                    identifiedFile.total = file.total;
                    identifiedFile.count = file.count;
                    identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
                }
            });
		};

		$scope.ok = function (result) {
			$modalInstance.close(result);
		};

		$scope.cancel = function () {
		    $modalInstance.dismiss('canceled');
		};
	}
})();
