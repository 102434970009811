(function () {
	angular.module('Plania').filter('plRecurrenceRuleFilter', ['$filter', '$rootScope', 'TranslationService', filter]);

	function filter($filter, $rootScope, translationService) {
		//TODO: need functionality to change recurrencerule before completing this. should also implement translation in order to do show multiple languages
		return function (recurrenceRule) {
			var day = {
				'Monday': 'Mandag',
				'Tuesday': 'Tirsdag',
				'Wednesday': 'Onsdag',
				'Thursday': 'Torsdag',
				'Friday': 'Fredag',
				'Saturday': 'Lørdag',
				'Sunday': 'Søndag',
			};

			var month = {
				1: 'Januar',
				2: 'Februar',
				3: 'Mars',
				4: 'April',
				5: 'Mai',
				6: 'Juni',
				7: 'Juli',
				8: 'August',
				9: 'September',
				10: 'Oktober',
				11: 'November',
				12: 'Desember',
			};

			if (!recurrenceRule.Unit || recurrenceRule.Unit === 'Undefined')
				return 'Engangsoppgave';

			if (recurrenceRule.Unit === 'Once' && recurrenceRule.PeriodNumber) {
				text = 'Aktiv';
				if (recurrenceRule.StartDate) {
					text += ' fra ' + $filter('date')(recurrenceRule.StartDate, 'dd.MM.yyyy');

					if (recurrenceRule.EndDate)
						text += ' til ' + $filter('date')(recurrenceRule.EndDate, 'dd.MM.yyyy');
				}
				return text;
			}

			var text;

			if (recurrenceRule.Unit === 'Year') {
				text = ['Hvert'];
			}
			else {
				text = ['Hver'];
			}

			if (recurrenceRule.Interval > 2)
				text.push(recurrenceRule.Interval);
			else if (recurrenceRule.Interval === 2) {
				var ending = (recurrenceRule.Unit === 'Year') ? 't' : 'n';
				text[0] = 'Anne' + ending + text[0].toLowerCase();
			}

			text.push(translationService.getEnumTranslation('TimeUnit', recurrenceRule.Unit).toLowerCase());

			if (recurrenceRule.ByDay && recurrenceRule.ByDay.length) {
				if (recurrenceRule.Unit === 'Week')
					text.push('på');
				if (recurrenceRule.Unit === 'Day')
					text.splice(text.length - 1, 1);
				var tmpDayArray = [];
				recurrenceRule.ByDay.forEach(function (byDay, index) {
					tmpDayArray.push(day[byDay]);
				});

				text.push(tmpDayArray.join(', '));
			}
			if (recurrenceRule.ByMonthDay && recurrenceRule.ByMonth && recurrenceRule.ByMonthDay.length && !recurrenceRule.ByMonth.length) {
				text[0] = text[0].toLowerCase();
				text.unshift('Dag ' + recurrenceRule.ByMonthDay[0]);
			}
			if (recurrenceRule.ByMonthDay && recurrenceRule.ByMonth && recurrenceRule.ByMonthDay.length && recurrenceRule.ByMonth.length) {
				text[0] = text[0].toLowerCase();
				text.unshift(recurrenceRule.ByMonthDay[0] + '.' + month[recurrenceRule.ByMonth[0]]);
			}
			if (recurrenceRule.Until) {
				text.push('til');
				text.push($filter('date')(recurrenceRule.Until, 'dd.MM.yyyy'));
			}
			else if (recurrenceRule.Count) {
				text.push(recurrenceRule.Count);
				text.push('gang' + recurrenceRule.Count > 1 ? 'er' : '');
			}

			if (recurrenceRule.PeriodNumber) {
				text.push('og varer i ' + recurrenceRule.PeriodNumber);
				if (recurrenceRule.PeriodUnit === 'Day')
					text.push(recurrenceRule.PeriodNumber > 1 ? ' dager' : ' dag');
				else if (recurrenceRule.PeriodUnit === 'Week')
					text.push(recurrenceRule.PeriodNumber > 1 ? ' uker' : ' uke');
				else if (recurrenceRule.PeriodUnit === 'Month')
					text.push(recurrenceRule.PeriodNumber > 1 ? ' måneder' : ' måned');
			}

			return text.join(' ');
		};
	}
})();
