(function () {

	angular.module('Plania').config([
		'$provide', function ($provide) {

			$provide.decorator('inputDirective', [
				'$delegate', function ($delegate) {
					// https://stackoverflow.com/a/24905165
					// When decorating a directive, $delegate is an array containing
					// all of the directives of the same name.
					// We're interested in the first in this case.
					var directive = $delegate[0];

					// Store a reference to the original directive linking function.
					var link = directive.link.pre;

					// Hook into the compile phase of the directive.
					directive.compile = function () {

						// The function returned by compile is your new link function.
						// Or 'postLink', to be exact.
						return function (scope, el, attrs) {

							// Run the original link function.
							link.apply(this, arguments);

							if (!attrs.autocomplete)
								el.attr('autocomplete', 'off');
						};
					};
					return $delegate;
				}]
			);
		}
	]);
})();
