(function () {

	angular.module('Plania').controller('ScheduledJobExecutionController', ['$scope', 'Repository', '$stateParams', controller]);

	function controller($scope, repository, $stateParams) {
		$scope.isUpdate = $scope.navigation.current.name === 'scheduledJob.edit';
		$scope.viewMode = $scope.isUpdate ? true : false;

		repository.getSingle(repository.apiData.scheduledJobExecution.url, $stateParams.guid).then(
			function(response) {
				$scope.model = response.Data;
			},
			function(error) {
				repository.growl(error, 'danger');
			}
		);
	}
})();
