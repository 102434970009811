(function () {
	angular.module('Plania').controller('BuildingPersonController', ['$scope', '$stateParams', 'Repository', '$localStorage', 'ngTableParams', 'TranslationService', 'params', '$modalInstance', '$filter', '$modal', controller]);

	function controller($scope, $stateParams, repository, $localStorage, ngTableParams, translationService, params, $modalInstance, $filter, $modal) {
		$scope.activateAutocomplete = false;

		if (params.modalParam && params.modalParam.isUpdate) {
			var updateAccess = function () {
				if ($scope.isUpdate) {
					var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
					$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.BuildingXPerson, checkOtherDO);
					$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.BuildingXPerson, checkOtherDO);
				} else {
					$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.BuildingXPerson);
					$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.BuildingXPerson);
				}
			};


			var columns = ['Building.Id', 'Building.Description', 'Person.FirstName', 'Person.LastName', 'PersonRole.Id'];

			repository.getSingle(repository.apiData.buildingPerson.url, params.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.fieldRules = response.FieldRules;
					$scope.reload = true;
					$scope.model = response.Data;

					updateAccess();

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

		}

		$scope.isAddingBuilding = params.GuidPerson ? true : false;
		$scope.usePersonRole = $localStorage.generalOptions.UsePersonRole;

		if ($scope.isAddingBuilding) {
			$scope.Title = translationService.translate('web-buildingXPerson-addBuilding-title', 'Legg til bygg');
		} else {
			$scope.Title = translationService.translate('web-buildingXPerson-addPerson-title', 'Legg til person');
		}


		var selectedEntities = [];

		$scope.usePersonRole = $localStorage.generalOptions.UsePersonRole;

		if ($scope.isAddingBuilding) {
			$scope.columns = [
				{
					Position: 1,
					Title: translationService.translate('web-building-Id', "Id"),
					Property: 'Id',
					PropertyType: 'string'
				},
				{
					Position: 2,
					Title: translationService.translate('web-building-Description', "Beskrivelse"),
					Property: 'Description',
					PropertyType: 'string'
				}
			];
		} else {
			$scope.columns = [
				{
					Position: 1,
					Title: translationService.translate('web-person-employeeNr', "AnsattNr"),
					Property: 'EmployeeNr',
					PropertyType: 'string'
				},
				{
					Position: 1,
					Title: translationService.translate('web-person-firstname', "Fornavn"),
					Property: 'FirstName',
					PropertyType: 'string'
				},
				{
					Position: 1,
					Title: translationService.translate('web-person-lastname', "Etternavn"),
					Property: 'LastName',
					PropertyType: 'string'
				},
				{
					Position: 1,
					Title: translationService.translate('web-PersonRole-Id', "Rolle"),
					Property: 'PersonRole.Id',
					PropertyType: 'string'
				}
			];
		}
		var selected = [];
		var filter = { PropertyFilter: [] };

		if (!$localStorage.generalOptions.UsePersonRole) {
			if ($scope.isAddingBuilding) {
				selected = _.map(params.selectedEntityGuids, function (m) { return m.GuidBuilding; });
			} else {
				selected = _.map(params.selectedEntityGuids, function (m) { return m.GuidPerson; });
			}
		}

		if ($scope.isAddingBuilding) {
			filter.PropertyFilter.push({ Property: "IsTemplate", Operator: "=", Value: false });
		}

		if (selected.length > 0) {
			filter.PropertyFilter.push({ Property: 'Guid', Operator: "notin", Value: selected.join(',') });
        }

		if (params.isOperationsManager) {
			filter.PropertyFilter.push({ Property: 'IsOperationsManager', Operator: "=", Value: true });
		}

		$scope.entities = new ngTableParams({
			page: 1,
			count: 10,
			sorting: $scope.isAddingBuilding ? { id: 'asc' } : { 'EmployeeNr': 'asc' },
			filter: filter
		},
			{
				total: 0,
				counts: [10, 25, 50],
				getData: function ($defer, params) {

					var url = $scope.isAddingBuilding ? repository.apiData.building.url : repository.apiData.person.url;
					var columns = _.map($scope.columns, 'Property');
					repository.GetPaginated(url,
						params.page() - 1,
						params.count(),
						params.sorting(),
						params.filter(),
						null,
						JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.saveEdit = function () {
			var success = function (response) {
				repository.growl(translationService.translate('web-buildingXPerson-Edit-Success', 'ByggXPerson er oppdatert'),
					'success');
				$modalInstance.close('Ok');
			};
			var error = function (error) {
				repository.growl(error, 'danger');
			};
			repository.updateSingle(repository.apiData.buildingPerson.url, $scope.model).then(success).catch(error);
		};

		$scope.save = function (fromRoleSelection) {
			var models = [];
			var duplicatePersons = [];
			var openSelectRoleModal = false;
			if (fromRoleSelection) {
				selectedEntities.forEach(function (entity) {
					var model = entity;

					if ($localStorage.generalOptions.UsePersonRole) {
						if (_.findWhere(params.selectedEntityGuids,
							{ GuidPerson: model.GuidPerson, GuidBuilding: model.GuidBuilding, GuidPersonRole: model.GuidPersonRole })) {
							duplicatePersons.push(model);
							openSelectRoleModal = true;
						} else {
							params.selectedEntityGuids.push(model);
							models.push(model);
						}
					} else {
						models.push(model);
					}
				});

			} else {
				selectedEntities.forEach(function (entity) {
					var model = {};
					if ($scope.isAddingBuilding) {
						model.GuidBuilding = entity.guid;
						model.GuidPerson = params.GuidPerson;
						model.GuidPersonRole = params.GuidPersonRole;
					} else {
						model.GuidBuilding = params.GuidBuilding;
						model.GuidPerson = entity.guid;
						model.GuidPersonRole = entity.data.GuidPersonRole;
					}

					if ($localStorage.generalOptions.UsePersonRole) {
						if (_.findWhere(params.selectedEntityGuids,
							{ GuidPerson: model.GuidPerson, GuidBuilding: model.GuidBuilding, GuidPersonRole: model.GuidPersonRole })) {
							if ($scope.isAddingBuilding) {
								model.personRoleCaption = params.personRole;
								model.personCaption = params.personCaption;
								model.buildingCaption = entity.data.Id + ' ' + entity.data.Description;
							} else {
								model.personCaption = entity.data.FirstName + ' ' + entity.data.LastName;
								model.personRoleCaption = entity.data.PersonRole.Id;
								model.buildingCaption = params.buildingCaption;
							}

							duplicatePersons.push(model);
							openSelectRoleModal = true;
						} else {
							params.selectedEntityGuids.push(model);
							models.push(model);
						}
					} else {
						models.push(model);
					}
				});
			}

			//save records which is not duplicates
			if (models.length > 0) {
				repository.createMultiple(repository.apiData.buildingPerson.url, models).then(function (result) {
					if (!openSelectRoleModal) {
						if ($scope.isAddingBuilding) {
							repository.growl('Bygg(ene) har blitt lagt til på personen', 'success');
						} else {
							repository.growl('Person(er) har blitt lagt til på bygget', 'success');
						}
						$modalInstance.close('Ok');
					}
				}).catch(function (error) {
					repository.growl(error, 'danger');
					//if error contains modal should we do a recheck or just give a message that role was not valid ?
				});
			}
			//handle duplicate records
			if (openSelectRoleModal) {
				if (fromRoleSelection)
					repository.growl(translationService.translate('web-buildingxperson-role-already-exist error', 'Rollen eksisterer allerede på person, vennligst velg ny rolle'), 'danger');
				$modal.open({
					templateUrl: 'app/building/views/selectPersonRoleModal.html',
					controller: 'SelectPersonRoleModalContreoller',
					resolve: {
						params: function () {
							return {
								selectedPersons: duplicatePersons,
							};
						}
					}
				}).result.then(function (changedEntities) {
					selectedEntities = changedEntities;
					$scope.save(true);

				}, function () {
					selectedEntities = [];
				});
			}

		};

		$scope.addSelected = function (row) {
			if (!_.findWhere(selectedEntities, { guid: row.Guid }))
				selectedEntities.push({ guid: row.Guid, data: row });
			else {
				for (var i = 0; i < selectedEntities.length; i++) {
					var guid = selectedEntities[i].guid;
					if (guid === row.Guid) {
						selectedEntities.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isSelected = function (guid) {
			return _.findWhere(selectedEntities, { guid: guid });
		};

		$scope.alreadySaved = function (guid) {
			if ($scope.isAddingBuilding) {
				return _.findWhere(params.selectedEntityGuids, { GuidBuilding: guid });
			}
			return _.findWhere(params.selectedEntityGuids, { GuidPerson: guid });
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.GetPropertyValue = function (row, column) {
			var properties = column.Property.split('.');
			var temp = row;

			properties.forEach(function (prop) {
				if (temp) {
					temp = temp[prop];
				}
			});
			return temp;
		};
	}
})();
