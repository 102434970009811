(function () {
	angular.module('Plania').controller('ReferenceDataInfoModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', 'TranslationService', '$localStorage', '$window', '$rootScope', 'NgTableParams', controller]);
	function controller($scope, repository, modalParams, $modalInstance, translationService, $localStorage, $window, $rootScope, ngTableParams) {
		$scope.referenceDatas = {}; // Dictionary of loaded referenceDatas
		$scope.referenceDatasArray = []; // List ordered by index for loaded refernceDatas, keep in sync with referenceDatas dictionary
		$scope.referenceType = {};
		$scope.currentModel = { Guid: $scope.guidReferenceData };
		$scope.initialModel = {};
		$scope.guidReferenceData = modalParams.guid;
		$scope.viewMode = true;

		var referenceDataColumns = [
			'ReferenceType.Id', 'ReferenceType.Description', 'ReferenceType.ExplanatoryText'
		];

		var addToReferenceDataArray = function (referenceData) {
			$scope.referenceDatasArray.push(referenceData);
			$scope.referenceDatasArray = _.sortBy($scope.referenceDatasArray, 'IndexPosition');
		};

		var getReferenceData = function () {
			repository.getSingle(repository.apiData.referenceData.url, $scope.currentModel.Guid, JSON.stringify(referenceDataColumns)).then(function (result) {
				$scope.referenceDatas[result.Data.Guid] = result.Data;
				addToReferenceDataArray(result.Data);
				$scope.currentModel = result.Data;
				$scope.initialModel = result.Data;

				if ($scope.currentModel.ReferenceType)
					$scope.referenceType = $scope.currentModel.ReferenceType;
				if (result.Data.GuidParent)
					getReferenceDataParent();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		var getReferenceDataParent = function () {
			if (!$scope.currentModel || !$scope.currentModel.GuidParent) return;
			if ($scope.referenceDatas[$scope.currentModel.GuidParent]) return;
			repository.getSingle(repository.apiData.referenceData.url, $scope.currentModel.GuidParent, JSON.stringify(referenceDataColumns)).then(function (result) {
				$scope.referenceDatas[result.Data.Guid] = result.Data;
				$scope.referenceDatas[result.Data.Guid].GuidChild = $scope.currentModel.Guid;
				addToReferenceDataArray(result.Data);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.onReferenceDataClick = function (model) {
			$scope.currentModel = model;
			getReferenceDataParent();
		};

		$scope.onParentClick = function (parentModel) {
			$scope.currentModel = parentModel;
			getReferenceDataParent();
		};

		$scope.goBackToChild = function (childModel) {
			$scope.currentModel = childModel;
		};

		if (modalParams.initialModel) {
			$scope.referenceDatas[modalParams.initialModel.Guid] = modalParams.initialModel;
			$scope.currentModel = modalParams.initialModel;
			$scope.initialModel = modalParams.initialModel;
			addToReferenceDataArray(modalParams.initialModel);
			if ($scope.currentModel.ReferenceType)
				$scope.referenceType = $scope.currentModel.ReferenceType;
			getReferenceDataParent();
		} else {
			getReferenceData();
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}
})();
