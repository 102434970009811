(function () {
	angular.module('Plania').controller('SelectUserInfoPropertyModal', ['$scope', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, $modalInstance, translationService) {
		var userInfo = $scope.userInfo;

		$scope.availableProperties = [
			{ Name: translationService.translate('web-userinfo-realname', 'Fullt navn'), Property: '@CurrentUser.realName' },
			{ Name: translationService.translate('web-userinfo-email', 'E-post'), Property: '@CurrentUser.email' },
			{ Name: translationService.translate('web-userinfo-phone', 'Telefonnummer'), Property: '@CurrentUser.phone' },
		];

		$scope.ok = function () {
			$modalInstance.close(_.map(_.filter($scope.availableProperties, { isSelected: true }), 'Property'));
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
