(function () {
	var app = angular.module('Plania');

	app.directive('plDashboardGrid', ['$injector', '$compile', '$parse', '$http', function ($injector, $compile, $parse, $http) {
		return {
			restrict: 'E',
			replace: true,
			scope: {
				widgets: '=widgets',
				control: '=control',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			templateUrl: '/app/dashboard/directives/dashboardGrid.html',
			controller: function ($scope, $compile, $parse, $http) {
				$scope.internalControl = $scope.control || {};

				var gridOptions = {
					alwaysShowResizeHandle: true,
					cellHeight: 75,
					animate: true,
					resizable: {
						handles: 'e, se, s, sw, w'
					}

				};

				$scope.grid = $('.grid-stack').gridstack(gridOptions).data('gridstack');

				$('.grid-stack').on('change', function (e, nodes) {

					for (var i = ($scope.widgets.length - 1); i >= 0; i--) {
						var widget = $scope.widgets[i];
						var node = _.find(nodes, { id: widget.Guid });
						if (node) {
							widget.GridHeight = node.height;
							widget.GridWidth = node.width;
							widget.GridPositionY = node.y;
							widget.GridPositionX = node.x;
						}
					}
				});

				//Function used to check where the new widget should be placed
				var isAvailable = function (newWidget, x, y) {
					return !_.find($scope.grid.grid.nodes, function (b) {
						var a = { x: x, y: y, width: newWidget.GridWidth, height: newWidget.GridHeight };
						return !(a.x + a.width <= b.x || b.x + b.width <= a.x || a.y + a.height <= b.y || b.y + b.height <= a.y);
					});
				};

				$scope.internalControl.addNewWidget = function (newWidgetModel) {
					var width = 12;

					var gridHeight = newWidgetModel.WidgetType === 'pl-kpi-widget' ? 1 : 4;

					GridStackUI.Utils.sort($scope.grid.grid.nodes, 1, width);
					var newWidget = { Guid: Math.floor((Math.random()) * 0x1000000000).toString(32), GridPositionX: 0, GridPositionY: 0, GridWidth: 4, GridHeight: gridHeight, WidgetType: newWidgetModel.WidgetType, WidgetData: newWidgetModel.WidgetData, Id: newWidgetModel.Id, Description: newWidgetModel.Description };

					for (var i = 0; ; ++i) {
						var x = i % width, y = Math.floor(i / width);
						if (x + newWidget.GridWidth > width) {
							continue;
						}

						if (isAvailable(newWidget, x, y)) {
							newWidget.GridPositionX = x;
							newWidget.GridPositionY = y;
							break;
						}
					}

					$scope.widgets.push(newWidget);
				};

				$scope.internalControl.setMode = function (isEdit) {
					$scope.edit = isEdit;
					$scope.grid.movable('.grid-stack-item', isEdit);
					$scope.grid.resizable('.grid-stack-item', isEdit);
				};

				$scope.attachWidget = function (element, attrs) {
					$scope.grid.addWidget(element, attrs.gsX, attrs.gsY, attrs.gsWidth, attrs.gsHeight, attrs.gsAutoPosition, null, null, null, null, attrs.gsId);
					$scope.internalControl.setMode($scope.edit);
				};

				$scope.removeWidget = function (element, attrs) {
					$scope.grid.remove_widget(element);
					$scope.widgets = _.reject($scope.widgets, function (widget) { return widget.Guid === attrs.gsId; });
				};
			}
		};
	}]);

	app.directive('addWidget', function ($parse, $compile) {
		return {
			compile: function compile(tElement, tAttrs) {

				var directiveGetter = $parse(tAttrs.addWidget);

				return function postLink(scope, element) {

					element.removeAttr('add-widget');

					var directive = directiveGetter(scope);
					element.attr(directive, '');

					$compile(element)(scope);
				};
			}
		};
	});
})();
