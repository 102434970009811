(function () {
	angular.module('Plania').directive('plChartWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-chart-widget-message', "Grafen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-chart-widget-confirm', 'Ja, fjern grafen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-chart-widget-success', 'Grafen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/chartWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', 'NgTableParams', '$modal', '$rootScope', '$filter', '$interval', '$localStorage', 'ListCompressionService', safeController],
		};
	}]);

	function safeController($scope, translationService, repository, ngTableParams, modal, $rootScope, $filter, $interval, $localStorage, listCompressionService) {
		try {
			controller($scope, translationService, repository, ngTableParams, modal, $rootScope, $filter, $interval, $localStorage, listCompressionService);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, translationService, repository, ngTableParams, modal, $rootScope, $filter, $interval, $localStorage, listCompressionService) {
		$scope.model = $scope.widget;
		$scope.chartData = [];
		$scope.hasReadAccess = false;
		var refreshInterval;

		if (typeof ($scope.model.WidgetData) === "string") {
			$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
		}

		// Generic Widget functions
		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false).then(function () {
					getChartData();
				});
			}, function () {
				//Dismissed
			});
		};

		var findApiData = function (widgetData) {
			var apiDataList = _.filter(repository.apiData,
				function (obj) {
					return obj.prefix === widgetData.prefix;
				});

			if (apiDataList.length === 0) return null;

			if (apiDataList.length > 1 && widgetData.SelectedListSetup) {
				var contextId = widgetData.SelectedListSetup.ContextId;
				if (contextId) {
					var apiDataWithContext = _.find(apiDataList, function (obj) { return obj.contextId === contextId; });
					if (apiDataWithContext)
						return apiDataWithContext;
				}
			}

			return apiDataList[0];
		};

		var getKpiNavState = function () {
			var state = null;
			var apiData = findApiData($scope.model.WidgetData);
			if (apiData) {
				var mainState = apiData.navigationState.split('.')[0];
				if (mainState === 'document' && apiData.prefix === 'DocumentXEntity')
					mainState = 'documentXEntity';
				state = mainState + '.list';
			}

			return state;
		};

		function getPropertyFilters() {
			var filters = [];
			// Add raw filters from configuration
			if ($scope.model.WidgetData.columns && $scope.model.WidgetData.columns.length > 0) {
				$scope.model.WidgetData.columns.forEach(function (col) {
					if (col.Filter) {
						filters.push(angular.copy(col.Filter[0]));
					}
				});
			}
			return repository.GetKeywordFilters(filters);
		}

		var getChartData = function () {
			$scope.chartData = [];

			var kpi = {
				Prefix: $scope.model.WidgetData.prefix,
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: getPropertyFilters(),
				Properties: [],
				RowGroup: $scope.model.WidgetData.kpiGroupTable.groupColumn.Property
			};

			$scope.model.WidgetData.kpiGroupTable.valueColumns.forEach(function (valCol) {
				kpi.Properties.push({ Property: valCol.Property, Function: valCol.Function });
			});

			var filter = repository.commonService.getFilterData();
			if (filter.selectedBuilding && filter.selectedBuilding.Guid)
				kpi.GuidBuilding = filter.selectedBuilding.Guid;
			if (filter.selectedEstate && filter.selectedEstate.Guid)
				kpi.GuidEstate = filter.selectedEstate.Guid;
			if (filter.selectedSelection && filter.selectedSelection.Guid)
				kpi.GuidSelection = filter.selectedSelection.Guid;

			repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {
				result.forEach(function (res) {
					$scope.chartData.push({
						Caption: res.GroupCaption,
						Value: res.Result[0].Value,
						text: res.GroupCaption + ", " + res.Result[0].Value
					});
				});
			});
		};
		getChartData();

		$scope.$on($rootScope.events.newSelection, function () {
			getChartData();
		});
	}
})();
