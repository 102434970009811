// Common wrapper for react components
(function () {
	var app = angular.module('Plania');
	app.directive('reactComponent', ['ReactService', function (reactService) {
		return {
			restrict: 'E',
			scope: {
				componentName: '@',
				props: '=props' // dictionary of props
			},
			link: function (scope) {
				scope.id = reactService.generateId();

				var component = window.AngularMigration.Components[scope.componentName];
				if (!component)
					throw Error("COMPONENT NOT FOUND");

				// Keep them cached for directive, newing will remount whole component
				var container;
				var root;

				scope.$watch(
					function (scope) {
						return { props: scope.props };
					},
					function (settings, oldSettings) {
						var properties = {};

						if (settings.props && typeof (settings.props) === "object") {
							Object.keys(settings.props).forEach(function (key) {
								properties[key] = settings.props[key];
							});
						}

						// Old render
						//window.AngularMigration.ReactDOM.render(
						//	window.AngularMigration.React.createElement(component, properties),
						//	document.getElementById(scope.id)
						//);

						if (!container)
							container = document.getElementById(scope.id);
						if (!root)
							root = window.AngularMigration.createRoot(container);
						root.render(window.AngularMigration.React.createElement(component, properties));
					},
					true
				);

				scope.$on('$destroy', function () {
					reactService.removeId(scope.id);
					if (root)
						root.unmount();
				});
			},
			template: '<div id="{{id}}"></div>'
		};
	}]);
})();
