(function () {
	angular.module('Plania').controller('NonsReferenceModalController', ['$scope', '$modal', '$modalInstance', 'Repository', 'params', 'TranslationService', '$rootScope', controller]);
	function controller($scope, $modal, $modalInstance, repository, params, translationService, $rootScope) {
		$scope.model = {};
		$scope.directiveReady = false; // ready when we can decide if nonsReference is loaded or is missing
		$scope.hasModel = false;
		$scope.hasReadAccess = $rootScope.hasReadAccess('NonsReference');
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess('NonsReference');
		$scope.hasCreateAccess = $rootScope.hasCreateAccess('NonsReference');
		$scope.hasEditAccess = $rootScope.hasEditAccess('NonsReference');

		$scope.isDeleting = false;

		$scope.entityModel = params.entityModel;

		if (params.entityModel.GuidNonsReference) {
			getNonsReference(params.entityModel.GuidNonsReference);
		} else {
			$scope.model.GuidEntity = params.entityModel.Guid;
			$scope.model.EntityType = "Equipment";
		}

		$scope.isEdit = !params.entityModel.GuidNonsReference;

		if (!$scope.hasReadAccess) {
			// Make sure we don't attempt to load anything if we don't have access
			$scope.directiveReady = true;
			return;
		}

		$scope.edit = function () {
			$scope.isEdit = true;
		};

		$scope.save = function () {
			if ($scope.model.Guid) {
				repository.updateSingle(repository.apiData.nonsReference.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-nonsReference-updated', 'Nons reference oppdatert'), 'success');
					$scope.model = result;
					$scope.isEdit = false;
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingle(repository.apiData.nonsReference.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-nonsReference-created', "Nons reference ble lagt til"), 'success');
					$scope.model = result;
					$scope.isEdit = false;
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.cancel = function () {
			if (!$scope.model.Guid) {
				$modalInstance.close("dismissed");
			} else {
				$scope.isEdit = false;
			}
		};

		function getNonsReference(guidNonsReference) {
			repository.getMainModel(repository.apiData.nonsReference.url, guidNonsReference).then(function (response) {
				$scope.model = response.Data;

				$scope.directiveReady = true;
				$scope.hasModel = true;

			}, function (error) {
				repository.growl(error, 'danger');
				$scope.directiveReady = true;
				$scope.hasModel = false;
			});
		}

		$scope.close = function () {
			$modalInstance.close($scope.model);
		};

		$scope.deleteReference = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-nonsReference-message', 'Nons referanse vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-nonsReference-button-confirm', 'Ja, fjern referansen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				$scope.isDeleting = true;
				repository.deleteSingle(repository.apiData.nonsReference.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-supplier-nonsReference-success', 'Referansen ble fjernet!'), result, "success");
					$scope.hasModel = false;
					$scope.entityModel.GuidNonsReference = undefined;
					$scope.model = {};
					$scope.isDeleting = false;
					$modalInstance.close("deleted");
				});
			});
		};
	}
})();