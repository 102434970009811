(function () {
	angular.module('Plania').directive('plCalendarWidget', ['TranslationService', '$timeout', function (translationService, $timeout) {
        return {
            restrict: 'A',
            require: '^plDashboardGrid',
            scope: {
                widget: '=widget',
                edit: '=edit',
                saveFunction: '=saveFunction'
            },
            link: function (scope, element, attrs, gridCtrl) {
                scope.$parent.attachWidget(element, attrs);

                scope.removeWidget = function () {
                    swal({
                        title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                        text: translationService.translate('web-swal-dashboard-calendar-message', "Kalenderen vil bli permanent fjernet!"),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f44336",
                        confirmButtonText: translationService.translate('web-swal-dashboard-calendar-confirm', 'Ja, fjern kalenderen'),
                        cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                        closeOnConfirm: false
                    }, function () {
                        window.onkeydown = null;
                        window.onfocus = null;
                        swal(translationService.translate('web-swal-dashboard-calendar-success', 'Kalenderen ble fjernet!'), '', "success");
                        scope.$parent.removeWidget(element, attrs);
                    });
				};

				$('.grid-stack').on('resizestop', function (event, ui) {
					if ($(event.target).find('.pl-calendar-widget').length > 0) {
						$timeout(function () {
							scope.setHeight();
						}, 350);
					}
				});

				scope.setHeight = function () {
					if (scope.sharedController && scope.sharedController.resizeCalendar) {
						var ele = $(element).find('.pl-calendar-widget');
						if (ele) {
							var bodyHeight = ele.height();
							scope.sharedController.resizeCalendar(bodyHeight);
						}
					}
				};

				// Timeout to allow for UI to be drawn slightly.
				$timeout(function () {
					scope.setHeight();
				}, 10);
            },
            templateUrl: 'app/dashboard/directives/widgets/calendarWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', '$modal', '$timeout', safeController],
        };
	}]);

	function safeController($scope, translationService, repository, modal, $timeout) {
		try {
			controller($scope, translationService, repository, modal, $timeout);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, translationService, repository, modal, $timeout) {
		$scope.model = $scope.widget;

		$scope.eventSource = repository.apiData.workOrder.url;

		$scope.sharedController = {};

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false);
			}, function () {
				//Dismissed
			});
		};
	}
})();
