(function() {
	angular.module('Plania').controller('PrintReportController', ['$scope', '$stateParams', '$localStorage',
	function controller($scope, $stateParams, $localStorage) {

		var reportParams = $stateParams.reportParams ? $stateParams.reportParams : $localStorage.reportParams;

		$scope.reportName = $stateParams.reportName;
		$scope.reportParams = JSON.parse(reportParams);

		$scope.printReport = true;
		if ($scope.reportParams && $scope.reportParams.DisableAutomaticPrint)
			$scope.printReport = false;

		delete $localStorage.reportParams;
	}]);
})();
