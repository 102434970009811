(function () {

	angular.module('Plania').directive('plTranslate', ['TranslationService', '$animate', '$modal',

		function directive(translationService, $animate, $modal) {
			return {
				restrict: 'A',
				scope: {
					model: '=plTranslateModel',
					disableTranslation: '=disableTranslation',
				},
				link: function (scope, element, attrs) {
					var systemName = attrs.plTranslate;
					var defaultValue = attrs.plDefaultTranslation ? attrs.plDefaultTranslation : element[0].textContent.trim();

					var value = translationService.translate(systemName, defaultValue, scope.model);
					var hasHelpText = translationService.hasHelpText(systemName);
					var matches = value.match(/%[a-zA-Z0-9\.]*%/g);

					var showHelpText = function (event) {
						event.stopPropagation();
						event.preventDefault();

						$modal.open({
							templateUrl: 'app/translation/views/helpTextModal.html',
							controller: 'HelpTextModalController',
							size: 'm',
							resolve: {
								params: function() {
									return {
										systemName: systemName,
										translatedLabel: value
									};
								}
							}
						});
					};

					if (matches) {
						matches.forEach(function (match) {
							var property = match.replace(new RegExp('%', 'g'), "");
							var properties = property.split('.');
							var tmpModel = scope.model ? scope.model[property] : null;

							if (tmpModel) {
								properties.forEach(function (prop) {
									tmpModel = tmpModel[prop];
								});

								value = value.replace(new RegExp(match, 'g'), tmpModel);
							}
							scope.$watch('model.' + property, function (newValue, oldValue) {
								if (newValue === oldValue) return;

								value = value.replace(new RegExp(match, 'g'), newValue);
								element[0].textContent = getTextContent(value);
							});
						});
					}
					element[0].textContent = getTextContent(value);

					if (hasHelpText) {
						appendHelpTextIcon();
					}

					var openTranslation = function (e) {
						e.preventDefault();
						e.stopPropagation();

						scope.$root.translationMode = false;
						$modal.open({
							templateUrl: 'app/translation/views/translationModal.html',
							controller: 'TranslationModalController',
							size: 'lg',
							resolve: {
								params: function () {
									return {
										systemName: systemName
									};
								}
							}
						}).result.then(function (result) {
							scope.$root.translationMode = true;
							var locale = translationService.getLocale();

							if (!result.translationUpdated && !result.helpTextUpdated)
								return;

							var languageXWebText = _.find(result.model.LanguageXWebTexts, function (o) { return o.Language.Locale === locale; });

							if (!languageXWebText) return;

							if (!result.translationUpdated)
								value = value;
							else if (languageXWebText.TranslatedValue)
								value = languageXWebText.TranslatedValue;
							else
								value = languageXWebText.DefaultTranslatedValue;
							element[0].textContent = value ? getTextContent(value) : null;

							if (languageXWebText.HasHelpText) {
								appendHelpTextIcon();
							}

							translationService.deleteLanguageEntry(languageXWebText.Language.Locale + systemName);
							translationService.getTranslations();
						}, function () {
							scope.$root.translationMode = true;
						});
					};

					function getTextContent(input){
						return input.replace(new RegExp(/%[a-zA-Z0-9\.]*%/g), '');
					}

					function appendHelpTextIcon() {
						$(element).append(function () {
							return $('<i class="fas fa-circle-info"></i >').click(showHelpText).css('cursor', 'help').css('margin-left', '5px');
						});
					}

					if (!scope.disableTranslation) {
						scope.$watch('$root.translationMode', function (newValue, oldValue) {

							if (newValue) {
								$animate.addClass(element, 'translation-mode');
								$(element).bind('click', openTranslation);
							} else {
								$animate.removeClass(element, 'translation-mode');
								$(element).unbind('click', openTranslation);
							}
						});
					}
				}
			};
		}
	]);
})();

