(function () {
	var app = angular.module('Plania');
	app.directive('cleaningTaskInfo', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$stateParams', 'AutoCompleteService', 'ListService', '$rootScope', controller],
			templateUrl: 'app/cleaningTask/views/cleaningTaskInfo.html'
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $stateParams, autoCompleteService, listService, $rootScope) {
		$scope.viewMode = true;
		$scope.isDocumentsCollapsed = false;
		$scope.isVideosCollapsed = false;
		$scope.selectedCleaningCompletions = [];
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasRecurrenceChanged = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.cleaningCompletionColumns = [
			{ Position: 1, Title: translationService.translate('web-cleaningCompletion-building', 'Bygg'), Property: 'Area.Building.Caption', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-cleaningCompletion-area'), Property: 'Area.Caption', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-cleaningCompletion-explanatoryText', 'Kommentar'), Property: 'ExplanatoryText', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-cleaningCompletion-CompletionStatus', 'Status'), Property: 'CompletionStatus', PropertyType: 'enum' },
			{ Position: 4, Title: translationService.translate('web-cleaningCompletion-CompletionDate', 'Utført dato'), Property: 'CompletionDate', PropertyType: 'date' },
		];

		var cleaningCompletionPropertyColumns = [
			'Area.Building.Id', 'Area.Building.Description', 'Area.Id',
			'Area.Description', 'ExplanatoryText', 'CompletionDate',
			'Area.Guid', 'Area.Building.Guid', 'CompletionStatus'];

		$scope.cleaningCompletionTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { 'CompletionDate': 'desc' },
			filter: { PropertyFilter: [{ Property: 'GuidCleaningTask', Operator: '=', Value: $stateParams.guid }] }
		}, {
			total: 0,
			counts: [10, 20, 50, 200],
			filterDelay: 10,
			getData: function ($defer, params) {
				$scope.loadingCleaningCompletions = true;

				var sorting = params.sorting();

				if (Object.keys(sorting)[0].indexOf('.Caption') !== -1) {
					var key = Object.keys(sorting)[0];
					var order = sorting[key];
					if (key.includes('Area') || key.includes('Area.Building'))
						key = key.replace('.Caption', '.Id');
					sorting = {};
					sorting[key] = order;
				}

				repository.GetPaginated(repository.apiData.cleaningCompletion.url, params.page() - 1, params.count(), sorting, params.filter(), "", JSON.stringify(cleaningCompletionPropertyColumns))
					.then(function (result) {
						$scope.loadingCleaningCompletions = false;
						$scope.cleaningCompletionTable.settings().total = result.TotalCount;
						$scope.cleaningCompletionTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					},
						function (error) {
							$scope.loadingCleaningCompletions = false;
							repository.growl(error, 'danger');
						});
			}
		});

		$scope.addSelected = function (key, guid) {
			if (!_.contains($scope.selectedCleaningCompletions, guid))
				$scope.selectedCleaningCompletions.push(guid);
			else {
				for (var i = 0; i < $scope.selectedCleaningCompletions.length; i++) {
					var p = $scope.selectedCleaningCompletions[i];
					if (p === guid) {
						$scope.selectedCleaningCompletions.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isSelected = function (key, guid) {
			return _.contains($scope.selectedCleaningCompletions, guid);
		};

		$scope.massDeleteCompletion = function () {
			var guidList = $scope.selectedCleaningCompletions;
			deleteCompletions(guidList);
		};

		$scope.removeCompletion = function (completion) {
			deleteCompletions([completion.Guid]);
		};

		function deleteCompletions(guidList) {
			if (!guidList || guidList.length === 0) {
				swal(
					'Ingen valg',
					translationService.translate('list-mass-edit-missingSelect-completion', 'Ingen valg. Velg en eller flere rader fra listen for å slette flere fullførte renhold'),
					'error');
			} else {
				swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-cleaningtask-completion-message', 'Fullført renhold vil ikke lenger være tilgjengelig!'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-cleaningquality-completion-button-confirm', 'Ja, fjern fullført renhold'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					},
					function () {
						window.onkeydown = null;
						window.onfocus = null;

						repository.deleteSingle(repository.apiData.cleaningCompletion.url,
							'',
							'guids=' + JSON.stringify(guidList))
							.then(function (result) {
								swal(translationService.translate('web-swal-completion-cleaningTasks-success', 'Fullført renhold ble fjernet!'),
									'',
									"success");
								repository.growl('Fullført renhold har blitt fjernet', 'success');
								$scope.selectedCleaningCompletions = [];

								$scope.cleaningCompletionTable.reload();
							});
					});
			}
		}

		var recurrenceWatcher = $scope.$watch('model.RecurrenceRule', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			if (oldVal === undefined) return;
			$scope.hasRecurrenceChanged = true;
			recurrenceWatcher();
		}, true);

		$scope.onAttributesChanged = function () {
			repository.getSingle(repository.apiData.cleaningTask.url, $stateParams.guid).then(
				function (response) {
					if ($scope.model && response.Data) {
						$scope.model.AttributeValues = response.Data.AttributeValues;
					}
				}
			);
		};
	}
})();
