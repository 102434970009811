angular.module('Plania')
    // =========================================================================
    // MALIHU SCROLL
    // =========================================================================

    ////Table
    //.directive('tableResponsive', ['scrollService', function (scrollService) {
    //    return {
    //        restrict: 'C',
    //        link: function (scope, element) {
    //            if (!$('html').hasClass('ismobile')) {
    //                scrollService.malihuScroll(element, 'minimal-dark', 'x');
    //            }
    //        }
    //    };
    //}])

    //Chosen
    .directive('chosenResults', ['scrollService', function (scrollService) {
    	return {
    		restrict: 'C',
    		link: function (scope, element) {
    			if (!$('html').hasClass('ismobile')) {
    				scrollService.malihuScroll(element, 'minimal-dark', 'y');
    			}
    		}
    	};
    }])

    //On Custom Class
    .directive('cOverflow', ['scrollService', function (scrollService) {
    	return {
    		restrict: 'C',
    		link: function (scope, element) {
    			if (!$('html').hasClass('ismobile')) {
    				scrollService.malihuScroll(element, 'minimal-dark', 'y');
    			}
    		}
    	};
    }])

	 .directive('easypieChart', function () {
	 	return {
	 		restrict: 'A',
	 		scope: {
	 			color: '=',
	 			size: '=',
	 			percent: '='
	 		},
	 		link: function (scope, element) {
	 			$(element).attr('data-percent', scope.percent ? scope.percent : 0);

	 			$(element).easyPieChart({
	 				trackColor: '#eee',
	 				scaleColor: '#ccc',
	 				barColor: scope.color ? scope.color : 'red',
	 				lineWidth: 7,
	 				lineCap: 'butt',
	 				size: scope.size ? scope.size : 148
	 			});

	 			//easyPieChart('.main-pie', 'rgba(255,255,255,0.2)', 'rgba(255,255,255,0.5)', 'rgba(255,255,255,0.7)', 7, 'butt', 148);
	 			//easyPieChart('.sub-pie-1', '#eee', '#ccc', '#2196F3', 4, 'butt', 95);
	 			//easyPieChart('.sub-pie-2', '#eee', '#ccc', '#FFC107', 4, 'butt', 95);
	 		}
	 	};
	 })


    // =========================================================================
    // WAVES
    // =========================================================================

    // For .btn classes
    .directive('btn', function () {
    	return {
    		restrict: 'C',
    		link: function (scope, element) {
    			if (element.hasClass('btn-icon') || element.hasClass('btn-float')) {
    				Waves.attach(element, ['waves-circle']);
    			}

    			else if (element.hasClass('btn-light')) {
    				Waves.attach(element, ['waves-light']);
    			}

    			else {
    				Waves.attach(element);
    			}

    			Waves.init();
    		}
    	};
    });

