(function () {
	angular.module('Plania').factory('IconService', [iconService]);

	function iconService() {
		var service = {};

		var symbolMap = {
			"money-bills": "fa-money-bills",
			"alert-circle": "fa-circle-exclamation",
			"alert-octagon": "fa-octagon-exclamation",
			"alert-polygon": "fa-seal-exclamation",
			"alert-triangle": "fa-triangle-exclamation",
			"help": "fa-circle-question",
			"info": "fa-info-circle",
			"notifications-active": "fa-bell-on",
			"notifications": "fa-bell",
			"chart": "fa-square-poll-vertical",
			"chart-donut": "fa-chart-pie",
			"assignment": "fa-clipboard-list",
			"dns": "fa-server",
			"flag": "fa-flag",
			"home": "fa-home",
			"puzzle-piece": "fa-puzzle-piece",
			"money": "fa-dollar-sign",
			"money-box": "fa-square-dollar",
			"shopping-cart": "fa-shopping-cart",
			"shopping-basket": "fa-shopping-basket",
			"store": "fa-store",
			"shield-security": "fa-shield-quartered",
			"shield-check": "fa-shield-check",
			"plus": "fa-plus",
			"plus-circle": "fa-plus-circle",
			"star": "fa-star",
			"desktop-windows": "fa-desktop-alt",
			"device-hub": "fa-sitemap",
			"trending-down": "fa-arrow-trend-down",
			"trending-flat": "fa-arrow-right",
			"trending-up": "fa-arrow-trend-up",
			"arrows": "fa-expand-arrows-alt",
			"rotate-left": "fa-undo-alt",
			"rotate-right": "fa-redo-alt",
			"my-location": "fa-location-crosshairs",
			"pin": "fa-location-dot",
			"apps": "fa-grid",
			"view-dashboard": "fa-table-layout",
			"view-column": "fa-table-columns",
			"widgets": "fa-th-large",
			"grid": "fa-th",
			"flower-alt": "fa-seedling",
			"flash": "fa-bolt-lightning",
			"file-text": "fa-file-alt",
			"file-plus": "fa-file-plus",
			"file": "fa-file",
			"copy": "fa-copy",
			"bookmark": "fa-bookmark",
			"dumpster": "fa-dumpster",
			"trash-can": "fa-trash-can",
			"fire": "fa-fire",
			"fire-smoke": "fa-fire-smoke",
			"fire-flame-curved": "fa-fire-flame-curved",
			"fire-extinguisher": "fa-fire-extinguisher",
			"fire-burner": "fa-fire-burner",
			"dumpster-fire": "fa-dumpster-fire",
			"sensor-fire": "fa-sensor-fire",
			"house-fire": "fa-house-fire",
			"block-brick-fire": "fa-block-brick-fire",
			"campfire": "fa-campfire",
			"sprinkler-ceiling": "fa-sprinkler-ceiling",
			"hose-reel": "fa-hose-reel",
			"snowflake": "fa-snowflake",
			"truck-front": "fa-truck-front",
			"plug": "fa-plug",
			"wheelchair-move": "fa-wheelchair-move",
			"vial-virus": "fa-vial-virus",
			"temperature-arrow-up": "fa-temperature-arrow-up",
			"temperature-arrow-down": "fa-temperature-arrow-down",
			"suitcase-medical": "fa-suitcase-medical",
			"oil-well": "fa-oil-well",
			"mosquito": "fa-mosquito",
			"money-bill-trend-up": "fa-money-bill-trend-up",
			"magnifying-glass": "fa-search-plus",
			"explosion": "fa-radiation-alt",
			"biohazard": "fa-biohazard",
			"pump-soap": "fa-pump-soap",
			"gauge-min": "fa-gauge-min",
			"gauge-max": "fa-gauge-max",
			"bolt": "fa-bolt",
			"water": "fa-water",
			"transformer-bolt": "fa-transformer-bolt",
			"pipe-section": "fa-pipe-section",
			"battery-low": "fa-battery-low",
			"battery-full": "fa-battery-full",
			"battery-bolt": "fa-battery-bolt",
			"percent": "fa-percent",
			"piggy-bank": "fa-piggy-bank",
			"universal-access": "fa-universal-access",
			"eye-low-vision": "fa-eye-low-vision",
			"shield-keyhole": "fa-shield-keyhole",
			"box-open-full": "fa-box-open-full",
			"fingerprint": "fa-fingerprint",
			"user-lock": "fa-user-lock",
			"burger-soda": "fa-burger-soda",
			"traffic-light": "fa-traffic-light",
			"chimney": "fa-chimney",
			"rainbow": "fa-rainbow",
			"box-heart": "fa-box-heart",
			"person-shelter": "fa-person-shelter",
			"360-degrees": "fa-360-degrees",
			"street-view": "fa-street-view",
			"hand-sparkles": "fa-hand-sparkles",
			"hands-bubbles": "fa-hands-bubbles",
			"container-storage": "fa-container-storage",
			"water-arrow-up": "fa-water-arrow-up",
			"water-arrow-down": "fa-water-arrow-down",
			"tank-water": "fa-tank-water",
			"wave": "fa-wave",
			"bridge-water": "fa-bridge-water",
			"arrow-up-from-water-pump": "fa-arrow-up-from-water-pump",
			"bridge-lock": "fa-bridge-lock",
			"recycle": "fa-recycle",
			"diamond-exclamation": "fa-diamond-exclamation",
			"leaf": "fa-leaf",
			"filter": "fa-filter",
			"a": "fa-a",
			"b": "fa-b",
			"c": "fa-c",
		};

		var objectMap = {
			"key": 'fa-key',
			"lock": 'fa-lock',
			"church": "fa-church",
			"tombstone": "fa-tombstone",
			"user": "fa-circle-user",
			"users": "fa-users-rectangle",
			"car-tunnel": "fa-car-tunnel",
			"bridge": "fa-bridge",
			"charging-station": "fa-charging-station",
			"tower-observation": "fa-tower-observation",
			"building": "fa-building",
			"school": "fa-school",
			"hotel": "fa-hotel",
			"ship": "fa-ship",
			"hospital": "fa-hospital",
			"road": "fa-road",
			"tree-city": "fa-tree-city",
			"tower-cell": "fa-tower-cell",
			"satellite-dish": "fa-satellite-dish",
			"mountain-city": "fa-mountain-city",
			"mattress-pillow": "fa-mattress-pillow",
			"house-chimney": "fa-house-chimney",
			"house-flag": "fa-house-flag",
			"gas-pump": "fa-gas-pump",
			"gas-pump-slash": "fa-gas-pump-slash",
			"bridge-water": "fa-bridge-water",
			"bed": "fa-bed",
			"garage-open": "fa-garage-open",
			"car-wash": "fa-car-wash",
			"car-side-bolt": "fa-car-side-bolt",
			"building-user": "fa-building-user",
			"apartment": "fa-apartment",
			"forklift": "fa-forklift",
			"utility-pole": "fa-utility-pole",
			"lamp-street": "fa-lamp-street",
			"house-day": "fa-house-day",
			"house-night": "fa-house-night",
			"industry": "fa-industry",
			"wind-turbine": "fa-wind-turbine",
			"solar-panel": "fa-solar-panel",
			"fan": "fa-fan",
			"vent-damper": "fa-vent-damper",
			"heat": "fa-heat",
			"shower": "fa-shower",
			"lightbulb": "fa-lightbulb",
			"elevator": "fa-elevator",
			"shelves": "fa-shelves",
			"pallet-boxes": "fa-pallet-boxes",
			"door-closed": "fa-door-closed",
			"truck-bolt": "fa-truck-bolt",
			"gears": "fa-gears",
			"folder-gear": "fa-folder-gear",
			"truck": "fa-truck",
			"car-side": "fa-car-side",
			"campground": "fa-campground",
			"monkey": "fa-monkey",
			"print": "fa-print",
			"tombstone-blank": "fa-tombstone-blank",
			"menorah": "fa-menorah",
			"chair": "fa-chair",
			"house-chimney-user": "fa-house-chimney-user",
			"house-medical": "fa-house-medical",
			"house-chimney-heart": "fa-house-chimney-heart",
			"book": "fa-book",
			"book-open-cover": "fa-book-open-cover",
			"lamp-floor": "fa-lamp-floor",
			"shop": "fa-shop",
			"mosque": "fa-mosque",
			"place-of-worship": "fa-place-of-worship",
			"circle-parking": "fa-circle-parking",
			"bench-tree": "fa-bench-tree",
			"toilet": "fa-toilet",
			"anchor": "fa-anchor",
			"anchor-lock": "fa-anchor-lock",
			"ferry": "fa-ferry",
			"lighthouse": "fa-lighthouse",
			"sailboat": "fa-sailboat",
			"buoy": "fa-buoy",
			"warehouse-full": "fa-warehouse-full",
			"bath": "fa-bath",
			"excavator": "fa-excavator",
			"bulldozer": "fa-bulldozer"
		};

		var activityMap = {
			"assignment-check": "fa-clipboard-check",
			"thumb-up": "fa-thumbs-up",
			"thumb-up-down": "fa-thumbs-down",
			"case": "fa-briefcase",
			"check-all": "fa-check-double",
			"check": "fa-check",
			"comment-alert": "fa-comment-exclamation",
			"comment": "fa-comment",
			"comments": "fa-comments",
			"camera": "fa-camera",
			"keyboard": "fa-keyboard",
			"phone": "fa-phone",
			"smartphone": "fa-mobile-alt",
			"view-list": "fa-list-ul",
			"alarm": "fa-alarm-clock",
			"alarm-check": "fa-calendar-check",
			"time-interval": "fa-timer",
			"time": "fa-clock",
			"time-countdown": "fa-stopwatch",
			"timer": "fa-hourglass-start",
			"calendar": "fa-calendar-alt",
			"format-list-bulleted": "fa-list-ul",
			"format-list-numbered": "fa-list-ol",
			"hammer": "fa-hammer",
			"person-falling": "fa-person-falling",
			"person-digging": "fa-person-digging",
			"person-chalkboard": "fa-user-tie",
			"id-card": "fa-id-card",
			"faucet-drip": "fa-faucet-drip",
			"road-lock": "fa-road",
			"wrench": "fa-wrench",
			"traffic-cone": "fa-traffic-cone",
			"user-helmet-safety": "fa-hard-hat",
			"toolbox": "fa-toolbox",
			"shovel-snow": "fa-shovel-snow",
			"wifi": "fa-wifi",
			"arrow-right-top": "fa-arrow-up-right",
			"person-to-door": "fa-person-to-door",
			"person-through-window": "fa-person-through-window",
			"siren": "fa-siren",
			"siren-on": "fa-siren-on",
			"temperature-snow": "fa-temperature-snow",
			"temperature-sun": "fa-temperature-sun",
			"light-emergency-on": "fa-light-emergency-on",
			"light-emergency": "fa-light-emergency",
			"snowplow": "fa-snowplow",
			"shovel": "fa-shovel",
			"broom": "fa-broom",
			"bucket": "fa-bucket",
			"vacuum": "fa-vacuum",
			"headset": "fa-headset",
		};

		// Used for map directive
		var mapIconMap = {
			"city": "fas fa-city",
			"home": "fas fa-home",
			"circle": "fas fa-circle",
			"star": "fas fa-star",
			"circle-dot": "far fa-circle-dot",
			"gear": "fas fa-gear",
			"envelope": "fas fa-envelope",
			"briefcase-blank": "fas fa-briefcase-blank",
		};

		var availableIcons = _.union(Object.keys(activityMap), Object.keys(symbolMap), Object.keys(objectMap));

		var iconMap = Object.assign({}, symbolMap, objectMap, activityMap);

		service.GetAvailableIcons = function () {
			return availableIcons;
		};

		service.GetTabContent = function (tab) {
			if (tab === "objects") {
				return Object.keys(objectMap);
			} else if (tab === "symbols") {
				return Object.keys(symbolMap);
			} else if (tab === "activities") {
				return Object.keys(activityMap);
			}
		};

		service.GetIconMap = function () {
			return iconMap;
		};

		service.getIcon = function (iconKey) {
			return iconMap[iconKey];
		};

		service.getAvailableMapIcons = function () {
			return Object.assign({}, mapIconMap);
		};

		// We now support more icons in map from other sources - return a combined version. Keep old getAvailable to separate functions.
		// This should only be used as a lookup for UI.
		service.getIconsForMapUsage = function () {
			var map = service.getAvailableMapIcons();

			Object.entries(iconMap).forEach(function (entry) {
				var key = entry[0];
				var value = entry[1];
				if (!map[key]) {
					map[key] = "fas " + value;
				}
			});

			return map;
		};

		service.getMapIcon = function (iconKey) {
			return service.getIconsForMapUsage()[iconKey];
		};

		return service;
	}
})();
