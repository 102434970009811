angular.module('Plania').factory('DashboardSharedDataService', [function () {
	var service = {};

	var selectedDrawingGuids = [];

	service.getDrawingGuids = function () {
		return selectedDrawingGuids;
	};

	service.setDrawingGuids = function (drawingGuids) {
		while (selectedDrawingGuids.length > 0)
			selectedDrawingGuids.pop();

		drawingGuids.forEach(function (guid) {
			selectedDrawingGuids.push(guid);
		});
	};

	return service;
}]);
