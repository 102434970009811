(function () {

    var app = angular.module('Plania');

    app.directive('mapClusterPopup', function () {
        return {
            restrict: 'E',
            controller: ['$scope', 'ngTableParams', '$filter', 'TranslationService', controller],
            templateUrl: 'app/map/views/mapClusterPopupDirective.html',
            link: function (scope, elements, attrs) {
            }
        };
    });

    function controller($scope, ngTableParams, $filter, translationService) {

        switch ($scope.selectedTheme) {
            case "basicData":
                $scope.title = translationService.translate('web-map-toolbar-selectTheme-Basicdata', 'Grunndata');
                break;
            case "workOrder":
                $scope.title = translationService.translate('web-map-toolbar-selectTheme-workOrder', 'Arbeidsordre');
                break;
            case "request":
                $scope.title = translationService.translate('web-map-toolbar-selectTheme-request', 'Meldinger');
                break;
            default:
                $scope.title = "Punkter";
        }

        $scope.mapClusterTableSmall = new ngTableParams({
            page: 1,
            count: 10
        },
            {
                total: $scope.clustermarkers.length,
                getData: function ($defer, params) {
                    var data = params.sorting() ? $filter('orderBy')($scope.clustermarkers, params.orderBy()) : $scope.clustermarkers;
                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                    $defer.resolve(data);
                }
            });
    }
})();
