(function () {
	angular.module('Plania').controller('ChangePasswordModalController', ['$scope', '$modalInstance', 'params', '$localStorage', controller]);

	function controller($scope, $modalInstance, params, $localStorage) {
        $scope.viewModel = {};
		$scope.user = params.user;
		$scope.mustChangePasswordProcess = params.mustChangePasswordProcess;
		$scope.passwordField = { focus: true };

		var generalOptions = $localStorage.generalOptions;
		$scope.passwordRules = {
			PwdMinNumberChars: generalOptions.PwdMinNumberChars,
			PwdMustContainLetters: generalOptions.PwdMustContainLetters,
			PwdMustContainDigits: generalOptions.PwdMustContainDigits,
		};

		var stringContainsANumber = function (text) {
			if (text === undefined || text === null || text === "")
				return false;

			return /\d/.test(text);
		};

		var isCharacterALetter = function (char) {
			// Trick to check if a character is a letter by using casing, since most letters have a upper and lowercase letter.
			// Will not work for Chinese or Japanese letters, but should not be a problem here.
			return char.toLowerCase() !== char.toUpperCase();
		};

		var stringContainsALetter = function (text) {
			if (text === undefined || text === null || text === "")
				return false;
			return _.some(text.split(""), function (char) { return isCharacterALetter(char); });
		};

		$scope.isValid = function () {
			var newPassword = $scope.viewModel.NewPassword;
			if (!newPassword || !$scope.viewModel.ConfirmPassword || !$scope.viewModel.OldPassword)
				return false;

			if (newPassword !== $scope.viewModel.ConfirmPassword)
				return false;

			if ($scope.mustChangePasswordProcess && newPassword.length < $scope.passwordRules.PwdMinNumberChars)
				return false;

			if ($scope.mustChangePasswordProcess && $scope.passwordRules.PwdMustContainLetters && !stringContainsALetter(newPassword))
				return false;

			if ($scope.mustChangePasswordProcess && $scope.passwordRules.PwdMustContainDigits && !stringContainsANumber(newPassword))
				return false;

			return true;
		};

		$scope.getPwdMinNumberCharsIcon = function () {
			var newPassword = $scope.viewModel.NewPassword;
			if (!newPassword)
				return "fa-help";

			if (newPassword.length < $scope.passwordRules.PwdMinNumberChars)
				return "fa-circle-exclamation c-red";
			return "fa-check-circle c-green";
		};

		$scope.getPwdMustContainLettersIcon = function () {
			var newPassword = $scope.viewModel.NewPassword;
			if (!newPassword)
				return "fa-help";

			if ($scope.passwordRules.PwdMustContainLetters && !stringContainsALetter(newPassword))
				return "fa-circle-exclamation c-red";
			return "fa-check-circle c-green";
		};

		$scope.getPwdMustContainDigitsIcon = function () {
			var newPassword = $scope.viewModel.NewPassword;
			if (!newPassword)
				return "fa-help";

			if ($scope.passwordRules.PwdMustContainDigits && !stringContainsANumber(newPassword))
				return "fa-circle-exclamation c-red";
			return "fa-check-circle c-green";
		};

		$scope.save = function () {
			if (params.saveOverride && typeof params.saveOverride === "function")
				params.saveOverride($scope.viewModel, $modalInstance);
			else
				$modalInstance.close($scope.viewModel.NewPassword);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };
	}
})();
