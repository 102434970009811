(function () {
	angular.module('Plania').controller('ApiClientController', ['$scope', 'TranslationService', 'Repository', '$modal', '$stateParams', 'ngTableParams', 'ListService', controller]);

	function controller($scope, translationService, repository, $modal, $stateParams, ngTableParams, listService) {
		$scope.isUpdate = $stateParams.guid;

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.apiClient.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		} else {
			$scope.model = {
				RefreshTokenLifetime: 6000,
				AccessTokenLifetime: 65
			};
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.ApiClient, false);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ApiClient, false);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.ApiClient);
			}
		};
		updateAccess();

		$scope.update = function (destination, model) {
			var modelToSave = model || $scope.model;

			var success = function (result) {
				repository.growl('API klienten har blitt ' + ($scope.isUpdate ? 'oppdatert.' : 'opprettet.'), 'success');
				var guid = $scope.isUpdate ? modelToSave.Guid : result.Guid;
				$scope.goBack(destination, { guid: guid });
			};

			var error = function (error) {
				repository.growl(error, 'danger');
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.apiClient.url, modelToSave).then(success).catch(error);
			} else {
				repository.createSingle(repository.apiData.apiClient.url, modelToSave).then(success).catch(error);
			}
		};

		$scope.changePassword = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/changePasswordModal.html',
				controller: 'ChangePasswordModalController',
				size: 'sm',
				resolve: {
					params: function () {
						return {
							user: {}
						};
					}
				}
			}).result.then(function (newPassword) {
				if (newPassword) {
					$scope.model.Secret = newPassword;
					$scope.update('apiClient.edit');
				}
			});
		};

		$scope.delete = function (destination, model) {
			var toSave = angular.copy(model);
			toSave.IsDeactivated = true;
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-api-client-delete-message', 'API klienten vil bli slettet'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-api-client-delete-button-confirm', 'Ja, slett API klienten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				$scope.update(destination, toSave);
			});
		};

	}
})();
