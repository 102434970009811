(function () {
	var app = angular.module('Plania');
	app.directive('buildingEquipmentDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				currentTab: '=currentTab',
			},
			controller: ['$scope', 'TranslationService', '$rootScope', '$timeout', controller],
			templateUrl: 'app/building/views/buildingEquipment.html'
		};
	});

	function controller($scope, translationService, $rootScope, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		$scope.hasPageLoaded = $scope.currentTab === 'equipment';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'equipment' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-area-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-area-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-area-areaCategory'), Property: 'AreaCategory.Description', PropertyType: 'string' }
		];
	}
})();
