(function () {
	angular.module('Plania').controller('CleaningQualityControlAreaModalController', ['$scope', 'Repository', 'params', 'TranslationService', '$modalInstance', controller]);

	function controller($scope, repository, params, translationService, $modalInstance) {
		$scope.model = params.cleaningQualityControlArea;

		$scope.dataLoaded = false;
		repository.getSingle(repository.apiData.cleaningQualityControlArea.url, params.cleaningQualityControlArea.Guid).then(function (result) {
			$scope.dataLoaded = true;
			$scope.model = result.Data;
			$scope.IsNew = $scope.model.Status === 'New';
		});

		$scope.setNotAvailableArea = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningQualityControlArea-setNotAvailableArea-message', 'Rommet vil bli fjernet fra kontrollen og et nytt rom vil bli valgt ut tilfeldig!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningQualityControlArea-setNotAvailableArea-button-confirm', 'Ja, fjern rommet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.runAction(repository.apiData.cleaningQualityControlArea.url + '/setNotAvailable/' + $scope.model.Guid).then(function (result) {
					repository.growl(result, 'success');
					$modalInstance.close('ok');
				}, function (error) {
					repository.showError(error);
				});
			});
	};

	$scope.save = function () {
		swal({
			title: translationService.translate('web-swal-cleaningQualityControl-complete-title', 'Er du sikker på at vil du fullføre kontrollen?'),
			text: translationService.translate('web-swal-cleaningQualityControl-complete-message', 'Det vil ikke være mulig å gjøre endringer etter den er blitt fullført!'),
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#2196f3",
			confirmButtonText: translationService.translate('web-swal-cleaningQualityControl-button-complete', 'Fullfør kontrollen!'),
			cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
			closeOnConfirm: true
		}, function () {
			window.onkeydown = null;
			window.onfocus = null;

			$scope.model.Status = 0;
			$scope.ok();
		});
	};

	$scope.ok = function () {
		repository.updateSingle(repository.apiData.cleaningQualityControlArea.url, $scope.model).then(function (result) {
			repository.growl(result, 'success');
			$modalInstance.close('ok');
		}, function (error) {
			repository.growl(error, 'danger');
		});
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('canceled');
	};

	$scope.getInventoryWasteLtValue = function () {
		var model = $scope.model;
		return model.InventoryWasteLt + model.InventoryDustLt + model.InventoryStainLt;
	};

	$scope.getInventoryWasteVtValue = function () {
		var model = $scope.model;
		return model.InventoryWasteVt + model.InventoryDustVt + model.InventoryStainVt;
	};

	$scope.getWallsWasteLtValue = function () {
		var model = $scope.model;
		return model.WallsWasteLt + model.WallsDustLt + model.WallsStainLt;
	};

	$scope.getWallsWasteVtValue = function () {
		var model = $scope.model;
		return model.WallsWasteVt + model.WallsDustVt + model.WallsStainVt;
	};

	$scope.getFloorWasteLtValue = function () {
		var model = $scope.model;
		return model.FloorWasteLt + model.FloorDustLt + model.FloorStainLt;
	};

	$scope.getFloorWasteVtValue = function () {
		var model = $scope.model;
		return model.FloorWasteVt + model.FloorDustVt + model.FloorStainVt;
	};

	$scope.getCeilingWasteLtValue = function () {
		var model = $scope.model;
		return model.CeilingWasteLt + model.CeilingDustLt + model.CeilingStainLt;
	};

	$scope.getCeilingWasteVtValue = function () {
		var model = $scope.model;
		return model.CeilingWasteVt + model.CeilingDustVt + model.CeilingStainVt;
	};
}

}) ();
