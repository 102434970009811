(function () {

    angular.module('Plania').controller('LanguageSelectionController', ['$scope', 'Repository', 'TranslationService', controller]);

    function controller($scope, repository, translationService) {
        $scope.selectedLanguage = {};

        repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
            function (result) {
                $scope.languages = result.List;
                $scope.selectedLanguage = _.find(result.List, function (o) { return o.Locale === translationService.getLocale(); });
            }
        );

        $scope.getFlagCssClass = function (language) {
            if (!language) return 'flag-no';

            switch (language.Locale) {
                case 'da-dk':
                    return 'flag-dk';
                case 'fr-fr':
                    return 'flag-fr';
                case 'nb-no':
                    return 'flag-no';
                case 'sv-se':
                    return 'flag-se';
                case 'en-gb':
                    return 'flag-gb';
            }
        };

        $scope.changeUserLanguage = function (language) {
            $scope.selectedLanguage = language;
            translationService.setLocale(language.Locale);
			var guidUser = repository.authService.getUserData().guidUser;

			repository.patch(repository.apiData.user.url, guidUser, { GuidLanguage: language.Guid }).then(function (response) {
                window.location.reload();
			}, function (error) {
				repository.showError(error);
			});
        };
    }
})();
