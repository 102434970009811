(function () {
	angular.module('Plania').controller('ControlListPreviewEntitySelectionModalController', ['$scope', 'Repository', 'params', 'ListService', 'TranslationService', 'ngTableParams', '$localStorage', '$modalInstance', controller]);

	function controller($scope, repository, params, listService, translationService, ngTableParams, $localStorage, modalInstance) {
		var isSVV = $localStorage.generalOptions.CustomerId === '10088' || $localStorage.generalOptions.CustomerId === '12258';
		$scope.selectedControlList = params.controlList;

		if ($scope.selectedControlList.UseWithEquipment)
			$scope.selectedEntityType = 'Equipment';
		else if ($scope.selectedControlList.UseWithArea)
			$scope.selectedEntityType = 'Area';
		else if ($scope.selectedControlList.UseWithWorkOrder)
			$scope.selectedEntityType = 'WorkOrder';


		$scope.equipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Description' },
		];

		if (isSVV) {
			$scope.equipmentColumns.push({ Position: 5, Title: translationService.translate('web-equipment-text20-svv', 'Driftsmerking'), Property: 'Text20' });
		}

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-area-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-area-description'), Property: 'Description' },
		];

		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workOrder-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-workOrder-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-workOrder-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-workOrder-description'), Property: 'Description' },
		];

		if ($scope.selectedEntityType) {
			$scope.items = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { Id: 'asc' },
				filter: {}
			},
				{
					total: 0,
					counts: [10, 25, 50],
					paginationMaxBlocks: 5,
					getData: function ($defer, params) {
						$scope.columns = [];

						var url = repository.apiData.equipment.url;
						var columnProperties = [];

						switch ($scope.selectedEntityType) {
							case 'Area':
								url = repository.apiData.area.url;
								$scope.areaColumns.forEach(function (column) {
									$scope.columns.push(column);
								});
								columnProperties.push("Id");
								columnProperties.push("Description");
								columnProperties.push("IsHousing");
								columnProperties.push("Building.Id");
								columnProperties.push("Building.Description");
								columnProperties.push("Building.Guid");
								columnProperties.push("Building.Estate.Id");
								columnProperties.push("Building.Estate.Description");
								columnProperties.push("Building.Estate.Guid");
								if ($localStorage.generalOptions.IsBBY) {
									columnProperties.push('Text01');
									columnProperties.push('AreaCategory.Description');
								}
								break;
							case 'Equipment':
								url = repository.apiData.equipment.url;
								$scope.equipmentColumns.forEach(function (column) {
									$scope.columns.push(column);
								});
								columnProperties.push("Id");
								columnProperties.push("Description");
								columnProperties.push("Manufacturer");
								columnProperties.push("Location");
								columnProperties.push("Building.Id");
								columnProperties.push("Building.Description");
								columnProperties.push("Building.Guid");
								columnProperties.push("Building.Estate.Id");
								columnProperties.push("Building.Estate.Description");
								columnProperties.push("Building.Estate.Guid");
								if ($scope.isSVV) {
									columnProperties.push('Text20');
								}
								break;
							case 'WorkOrder':
								url = repository.apiData.workOrder.url;
								$scope.workOrderColumns.forEach(function (column) {
									$scope.columns.push(column);
								});
								break;
						}

						$scope.columns.forEach(function (col) {
							columnProperties.push(col.Property);
						});

						repository.GetPaginated(url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columnProperties)).then(
							function (result) {
								params.total(result.TotalCount);
								$defer.resolve(result.List);
							}, function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		}


		$scope.$watch('selectedEntityType', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue) {
				$scope.items.reload();
			}
		});

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.selectEntity = function (entity) {
			var returnObject = {};
			if (entity.Prefix === 'WorkOrder')
				returnObject.workOrder = entity;
			else if (entity.Prefix === 'Area')
				returnObject.area = entity;
			else if (entity.Prefix === 'Equipment')
				returnObject.equipment = entity;
			modalInstance.close(returnObject);
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};
	}
})();
