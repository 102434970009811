(function () {
	angular.module('Plania').controller('ReactCommonListController', ['$scope', '$state',
		function ($scope, $state) {
			$scope.apiData = $state.params.entityData;
		}
	]);

	angular.module('Plania').directive('reactPlaniaMainTable', ['$rootScope', function ($rootScope) {
		return {
			restrict: 'E',
			scope: {
				entity: "@",
				apiData: "="
			},
			link: function (scope, element, attrs) {
				scope.props = {
					reload: true,
					entity: scope.entity,
					apiData: scope.apiData
				};
				scope.$on($rootScope.events.newSelection, function () {
					scope.props.reload = !scope.props.reload;
				});

			},
			template: '<div><react-component props="props" component-name="MainListPage"></react-component></div>'
		};
	}]);
})();
