(function () {
	angular.module('Plania').controller('HelpTextModalController', ['$scope', 'params', '$modalInstance', 'TranslationService', '$sce', controller]);

	function controller($scope, params, $modalInstance, translationService, $sce) {
		$scope.model = {
			translatedLabel: params.translatedLabel
		};

		translationService.getHelpText(params.systemName).then(function (result) {
			// #22293 html is vulnerable to XSS, cannot use built in sanitizer as it will remove tinyMce styles
			$scope.model.helpText = $sce.trustAsHtml(result);
		});

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
