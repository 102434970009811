(function () {
	angular.module('Plania').factory('genericRepository', ['$http', 'Upload', 'config', 'authService', '$q', '$cacheFactory', genericRepository]);


	function genericRepository($http, upload, config, authService, $q, $cacheFactory) {

		// avoid multiple GET requests for identical url by caching promise already in progress
		// could consider using a library such as https://github.com/chrisronline/angular-promise-cache to also cache the promises for X amount of time
		var cache = $cacheFactory('genericRepositoryHttpOnce');

		var httpConfig = function (relativeUrl, method, data) {
			return authService.refreshAuthentication().then(function (result) {
				return {
					method: method,
					url: encodeURI(config.baseUrlApi + relativeUrl),
					headers: {
						'Content-Type': 'application/json; charset=UTF-8'
					},
					data: data
				};
			});
		};

		var postCall = function (relativeUrl, data) {
			return httpConfig(relativeUrl, 'POST', data)
				.then(function (result) {
					return $http(result);
				});
		};

		var postCallFiles = function (relativeUrl, data, files) {
			return authService.refreshAuthentication().then(function (result) {
				var deferred = $q.defer();
				var url = encodeURI(config.baseUrlApi + relativeUrl);
				var filePromises = [];
				if (!files || files.length === 0) {
					data.fileId = data.FilePath;
					filePromises.push(
						upload.upload({
							url: url,
							method: 'POST',
							data: data
						}).then(function (result) {
							return result;
						}, function (error) {
							return { status: 400, data: { Data: typeof (error.data) === 'string' ? error.data : error.data.Data.Message }, config: error.config };
						})
					);
				}

				files.forEach(function (file) {
					deferred.notify({ id: file.id, count: 0, total: file.size });
					var fileData = _.cloneDeep(data);
					fileData.fileName = file.name;
					fileData.fileId = file.id;
					filePromises.push(upload.upload({
						url: url,
						method: 'POST',
						data: fileData,
						file: file,
						resumeSizeUrl: url.replace('?dataOwner', file.id + "?dataOwner"),
						resumeSizeResponseReader: function (data) {
							return data.Data.Size;
						},
						resumeChunkSize: '8MB' //should be 10MB in production
					}).then(function (result) {
						//should not close this yet, maybe create chain of upload promises and return when all is resolved ?
						deferred.notify({ id: file.id, count: 100, total: 100 });
						return result;
					},	function (error) {
							return { status: 400, data: { Data: typeof (error.data) === 'string' ? error.data : error.data.Data.Message }, config: error.config };
					},	function (progress) {
							var file = { id: progress.config.data.file.id, count: progress.loaded, total: progress.total };
							deferred.notify(file);
					}));
				});

				$q.all(filePromises).then(function (results) {
					var res = { savedFiles: [], errorFiles: [], status: 0 };
					results.forEach(function (result) {
						if (res.status === 0 || result.status !== 200)
							res.status = result.status;

						if (result.status === 200) {
							if (result.config.data.IsExternalLink)
								res.savedFiles.push({ id: result.config.data.fileId, guid: result.data.Data });
							else
								res.savedFiles.push({ id: result.config.file.id, guid: result.data.Data });
						} else {
							if (result.config.data.IsExternalLink)
								res.errorFiles.push({ id: result.config.data.fileId, guid: result.data.Data, error: result.data.Data });
							else
								res.errorFiles.push({ id: result.config.file.id, error: result.data.Data });
						}
					});

					deferred.resolve(res);
				});

				return deferred.promise;
			});
		};

		var putFile = function (relativeUrl, data, file) {
			return authService.refreshAuthentication().then(function (result) {
				var deferred = $q.defer();
				var url = encodeURI(config.baseUrlApi + relativeUrl);
				var filePromises = [];

				deferred.notify({ id: file.id, count: 0, total: file.size });
				var fileData = data || { };
				fileData.fileName = file.name;
				fileData.fileId = file.id;
				filePromises.push(upload.upload({
					url: url,
					method: 'PUT',
					data: fileData,
					file: file,
					resumeSizeUrl: url.replace('?dataOwner', file.id + "?dataOwner"),
					resumeSizeResponseReader: function (data) {
						return data.Data.Size;
					},
					resumeChunkSize: '8MB' //should be 10MB in production
				}).then(function (result) {
					deferred.notify({ id: file.id, count: 100, total: 100 });
					return result;
				},
					function (error) {
						return { status: 400, data: { Data: typeof (error.data) === 'string' ? error.data : error.data.Data.Message }, config: error.config };
					},
					function (progress) {
						var file = { id: progress.config.data.file.id, count: progress.loaded, total: progress.total };
						deferred.notify(file);
					}));


				$q.all(filePromises).then(function (results) {

					var res = { savedFiles: [], errorFiles: [], status: 0 };
					results.forEach(function (result) {
						if (res.status === 0 || result.status !== 200)
							res.status = result.status;

						if (result.status === 200) {
							if (result.config.data.IsExternalLink)
								res.savedFiles.push({ id: result.config.data.fileId, guid: result.data.Data });
							else
								res.savedFiles.push({ id: result.config.file.id, guid: result.data.Data });
						} else {
							if (result.config.data.IsExternalLink)
								res.errorFiles.push({ id: result.config.data.fileId, guid: result.data.Data });
							else
								res.errorFiles.push({ id: result.config.file.id, error: result.data.Data });
						}
					});

					deferred.resolve(res);
				});

				return deferred.promise;
			});
		};

		var putCall = function (relativeUrl, data) {
			return httpConfig(relativeUrl, 'PUT', data).then(function (result) {
				return $http(result);
			});
		};

		var getCall = function (relativeUrl) {
			return httpConfig(relativeUrl, 'GET', {}).then(function (result) {
				var promise = cache.get(relativeUrl);
				if (promise && promise.$$state.status === 0) {
					// return any pending promises for the same URL
					return promise;
				}
				return cache.put(relativeUrl, $http(result));
			});
		};

		var deleteCall = function (relativeUrl, data) {
			return httpConfig(relativeUrl, 'DELETE', data).then(function (result) {
				return $http(result);
			});
		};

		var patchCall = function (relativeUrl, data) {
			return httpConfig(relativeUrl, 'PATCH', data).then(function (result) {
				return $http(result);
			});
		};

		return {
			post: postCall,
			postFile: postCallFiles,
			putFile: putFile,
			put: putCall,
			get: getCall,
			delete: deleteCall,
			patch: patchCall
		};
	}

})();
