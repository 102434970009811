(function () {
	angular.module('Plania').controller('QrCodeModalController', ['$scope', '$modalInstance', controller]);

	function controller($scope, $modalInstance) {
		$scope.loginUrl = document.location.protocol + '//' + document.location.host;

		$scope.ok = function () {
			$modalInstance.close('ok');
		};
	}
})();
