angular.module('Plania').directive('ssrs', ['$window', function ($window) {
	return {
	    restrict: 'E',
	    replace: true,
	    scope: {
	        url: '=',
	    },
	    link: function link(scope, element, attrs) {

	        element.on('load', function () {
	            var height = document.getElementsByTagName("html")[0].scrollHeight /2 + "px ";
	            element.height(height);
	        });

		    //check height etc onload
		},
		template: '<iframe style="border: none; width: 100%"></iframe>'
	};
}]);
