(function () {
    angular.module('Plania').controller('CleaningModalController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', controller]);

    function controller($scope, repository, params, modalInstance, translationService) {
        $scope.model = params.model;

        $scope.ok = function (selectedDeviation) {
            modalInstance.close(selectedDeviation);
        };

        $scope.cancel = function () {
            modalInstance.dismiss('cancel');
        };

        $scope.select = function (tabName) {
            $scope.cleaningInfoIsActive = false;
            $scope.cleaningCompletionIsActive = false;
            switch (tabName) {
                case 'cleaningInfo':
                    $scope.cleaningInfoIsActive = true;
                    break;
                case 'cleaningCompletion':
                    $scope.cleaningCompletionIsActive = true;
                    break;
                default:
                    break;
            }
        };

        $scope.select(params.isCompletion ? 'cleaningCompletion' : 'cleaningInfo');
    }
})();
