(function () {

	angular.module('Plania').controller('UserInformationModalController', ['$scope', '$modalInstance', 'Repository', 'params', controller]);

	function controller($scope, $modalInstance, repository, params) {
	    $scope.user = params.user;


		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}
})();
