(function () {
	var app = angular.module('Plania');
	app.directive('areaMaintenanceDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				reloads: '=reloads',
				update: '=update',
				currentTab: '=currentTab',
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', '$timeout', controller],
			templateUrl: 'app/area/views/areaMaintenance.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.navigation = $rootScope.navigation;

		if (!$scope.update) return;

		$scope.hasPageLoaded = $scope.currentTab === 'maintenance';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'maintenance' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-equipment-resourcegroup-description', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 5, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 6, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
		];

		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create',
				{
					guidEstate: $scope.model.Building.Estate.Guid,
					estate: $scope.model.Building.Estate.Caption,
					guidBuilding: $scope.model.GuidBuilding,
					building: $scope.model.Building ? $scope.model.Building.Caption : '',
					guidArea: $scope.model.Guid,
					area: $scope.model.Id + ' - ' + $scope.model.Description,
					fromArea: true
				});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};
	}
})();
