(function() {
	angular.module('Plania').controller('ChangePasswordController', ['$scope', 'Repository', '$state', controller]);

	function controller($scope, repository, $state) {

		$scope.viewModel = {};
		$scope.viewModel.OldPassword = '';
		$scope.viewModel.NewPassword = '';
		$scope.viewModel.ConfirmPassword = '';

		$scope.changePassword = function () {
		    repository.createSingle(repository.apiData.user.urlChangePassword, $scope.viewModel).then(function () {
		        repository.growl('Passord har blitt oppdatert', 'success');
		        $state.go('profile.view');
		    }, function (error) {
		        repository.growl(error, 'danger');
		    });
		};
	}
})();
