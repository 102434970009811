(function () {
	angular.module('Plania').controller('ListController', ['$scope', 'Repository', '$state', 'TranslationService', 'DocumentService', '$modal', 'ListService', '$localStorage', 'ListCompressionService', '$rootScope', 'OperationalMessageService', 'config', 'RegisteredFieldService',
		function ($scope, repository, $state, translationService, documentService, $modal, listService, $localStorage, listCompressionService, $rootScope, operationalMessageService, config, registeredFieldService) {
			$scope.selected = {};
			$scope.selectedIndex = -1;
			$scope.showFilter = false;
			$scope.showViewButton = false;
			$scope.showDrawingEditButton = false;
			$scope.clickable = true;
			$scope.showTreeView = false;
			$scope.filters = { PropertyFilter: [] };
			$scope.prefix = $state.params.entityData.prefix;
			$scope.contextId = $state.params.entityData.contextId || 'List-' + $scope.prefix;
			$scope.generalOptions = $localStorage.generalOptions;

			// Simple dictionary to keep multiple loading states (ex: loading items, running functions)
			$scope.loadingStates = {
				isCreatingWebDashboardCopy: false
			};

			// Boolean to check if the controller is destroyed during navigation.
			// Usually used for async methods to know if they should not continue.
			var isViewDestroyed = false;
			var urlFilter = null;
			var menuParams = null;
			$scope.routeEntity = $scope.navigation.current.name.replace('.list', '');

			$scope.titleSystemName = 'web-' + $scope.routeEntity + '-table-title';
			$scope.titleDefaultName = translationService.translate('web-' + $scope.routeEntity);

			// Is this used somewhere?
			$scope.title = translationService.translate($scope.titleSystemName, $scope.titleDefaultName);
			$scope.treeView = {
				selectedTreeViewItem: {}
			};

			if ($localStorage['showTreeView' + $state.params.entityData.prefix]) {
				$scope.showTreeView = $localStorage['showTreeView' + $state.params.entityData.prefix];
			}

			if ($state.params.entityData.prefix === 'Document')
				$scope.showViewButton = true;

			if ($state.params.entityData.prefix === 'Drawing')
				$scope.showDrawingEditButton = true;

			if (["sparePartCountingItem", "UserXWebProfile"].includes($state.params.entityData.prefix))
				$scope.clickable = false;

			if ($state.params.entityData.prefix === 'Supplier') {
				$scope.filters.PropertyFilter.push({ Property: 'IsDeactivated', Operator: '=', Value: '0' });
			}

			if ($state.params.entityData.prefix === 'ResourceGroup') {

				if ($state.current.name === 'cleaning.resourceGroup.list')
					$scope.filters.PropertyFilter.push({ Property: 'DoesCleaningTasks', Operator: '=', Value: true });
			}

			if ($state.params.entityData.prefix === 'Contract') {
				if ($state.current.name === 'contract.list') {
					$scope.filters.PropertyFilter.push({ Property: 'RentalType', Operator: '=', Value: '0' });
				}
				else if ($state.current.name === 'housingContract.list') {
					$scope.filters.PropertyFilter.push({ Property: 'RentalType', Operator: '=', Value: '1' });
				}
				else if ($state.current.name === 'reinvoicingContract.list') {
					$scope.filters.PropertyFilter.push({ Property: 'RentalType', Operator: '=', Value: '2' });
				}
			}

			if ($state.params.entityData.prefix === 'WebListView') {
				$scope.filters.GetAll = true;
			}

			if ($state.params.entityData.prefix === 'Area') {
				$scope.filters.PropertyFilter.push({ Property: 'IsDeactivated', Operator: '=', Value: '0' });
				if ($state.current.name === 'housing.list') {
					$scope.filters.PropertyFilter.push({ Property: 'IsHousing', Operator: '=', Value: '1' });
				}
				else if ($state.current.name === 'area.list') {
					$scope.filters.PropertyFilter.push({ Property: 'IsHousing', Operator: '=', Value: '0' });
				}
			}

			if ($state.params.entityData.prefix === 'AreaXCleaningTask')
				$scope.filters.CleaningStatus = "Active";

			if ($state.params.entityData.prefix === 'Building') {
				$scope.filters.PropertyFilter.push(
					{ Property: 'IsTemplate', Operator: '=', Value: $state.current.name === 'building.list' ? '0' : '1' }
				);
			}

			if ($state.params.entityData.prefix === 'Equipment') {
				$scope.filters.PropertyFilter.push(
					{ Property: 'IsTemplate', Operator: '=', Value: $state.current.name === 'equipment.list' ? '0' : '1' }
				);
			}

			if ($state.params.entityData.prefix === 'SparePart') {
				$scope.filters.PropertyFilter.push({ Property: 'IsDeactivated', Operator: '=', Value: '0' });
			}

			if ($state.params.entityData.prefix === 'Condition') {
				$scope.filters.PropertyFilter.push({ Property: 'Type', Operator: '<>', Value: '-1' });
				$scope.filters.PropertyFilter.push({ Property: 'Type', Operator: '<>', Value: '0' });
				$scope.filters.PropertyFilter.push({ Property: 'Type', Operator: '<>', Value: '3' });

			}

			if ($state.params.entityData.prefix === 'WorkOrderXResourceGroup') {
				$scope.filters.PropertyFilter.push({ Property: 'GuidPerson', Operator: '=', Value: repository.authService.getUserData().guidPerson });
			}

			if ($state.params.entityData.prefix === 'Project') {
				$scope.filters.IsTemplate = $state.current.name === 'projectTemplate.list';
			}

			if ($state.params.entityData.prefix === 'PeriodicTask') {
				if ($state.current.name === 'periodicTaskTemplate.list') {
					$scope.filters.PropertyFilter.push({ Property: 'TemplateType', Operator: '=', Value: 'PeriodicTemplate' });
				} else if ($state.current.name === 'workOrderTemplate.list') {
					$scope.filters.PropertyFilter.push({ Property: 'TemplateType', Operator: '=', Value: 'WorkOrderTemplate' });
				} else {
					$scope.filters.PropertyFilter.push({ Property: 'TemplateType', Operator: '=', Value: 'Periodic' });
				}
			}

			if ($state.params.entityData.prefix === 'UserXExternalLogin' || $state.params.entityData.prefix === 'UserXUserNotification') {
				$scope.clickable = false;
			}

			if ($state.params.entityData.prefix === 'WorkOrder') {
				$scope.showProjectLinkButton = false;
				if ($scope.hasModule('ProjectManagement') && $scope.hasEditAccess('Project')) {
					if ($scope.userInfo.isSystemAdministrator || $scope.userInfo.isProjectAdministrator)
						$scope.showProjectLinkButton = true;
					else {
						var filterModel = {
							Condition: "and",
							Rules: [
								{ Property: 'ClosedDate', Operator: '=', value: null },
								{ Property: 'IsTemplate', Operator: '=', value: false },
								{
									Condition: "or",
									Rules: [
										{ Property: 'GuidOwnerPerson', Operator: '=', value: $scope.userInfo.guidPerson },
										{ Property: 'GuidManagerPerson', Operator: '=', value: $scope.userInfo.guidPerson },
									]
								}
							]
						};

						var anyActiveProjects = {
							Prefix: 'Project',
							FromDate: new Date(0).toISOString(),
							ToDate: null,
							DateProperty: null,
							IncludePeriodicTask: false,
							Interval: null,
							FilterModel: filterModel,
							Properties: [{
								Property: 'Id',
								Function: 'count'
							}]
						};
						repository.createSingle(repository.apiData.kpi.url, anyActiveProjects).then(function (result) {
							if (isViewDestroyed) return;
							if (result && result[0].Result) {
								if (result[0].Result[0].Value > 0)
									$scope.showProjectLinkButton = true;
							}
						});
					}
				}
			}

			//Filters
			$scope.datePickers = {
				startFromDate: false,
				startToDate: false,
				endFromDate: false,
				endToDate: false
			};

			$scope.open = function ($event, calendar) {
				$scope.datePickers[calendar] = !$scope.datePickers[calendar];
				$event.preventDefault();
				$event.stopPropagation();
			};
			//End filters

			var updateAccess = function () {
				// Temp fix for UserGroup, since we have not converted it completely to context system.
				var prefix = $state.params.entityData.prefix;
				if ($state.params.entityData.prefix === repository.apiData.userGroup.prefix) {
					prefix = repository.apiData.user.prefix;
				}
				$scope.restrictEdit = !repository.authService.hasEditAccess(prefix);
				$scope.restrictCreate = !repository.authService.hasCreateAccess(prefix);
			};
			updateAccess();

			var showPostOnTop = false;

			//If navigated from edit page of object, persist search from localStorage
			$scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
				var prefix = $state.params.entityData.prefix;
				$scope.fromState = fromState.name;

				if (fromState.name === ((prefix.charAt(0).toLowerCase() + prefix.slice(1)) + '.edit')) {
					if (prefix === 'WorkOrder')
						$scope.filters.ActivityStatus = $localStorage.workOrderActivityStatus;
					if (prefix === 'Project' && $state.current.name !== 'projectTemplate.list')
						$scope.filters.ActivityStatus = $localStorage.projectActivityStatus;
				} else {
					$localStorage.workOrderActivityStatus = 'Active';
					$localStorage.projectActivityStatus = 'Active';
				}

				//todo: why is this only on customer and housingcustomer ?
				if ((fromState.name === ((prefix.charAt(0).toLowerCase() + prefix.slice(1)) + '.create')) ||
					(prefix === "Customer" && (fromState.name === "housingCustomer.create"))) {
					showPostOnTop = true;
				}
			});

			$scope.preSelected = repository.commonService.getLastRegisterGuid($scope.navigation.current.name);

			var getItems = function () {
				$scope.items = listService.getNgTableParams($scope.selectedListSetup, $scope.navigation, $state.params.entityData, showPostOnTop ? $scope.preSelected : null, $scope.filters);
			};

			var getSelectedItems = function () {
				var tableItems = [];
				if (!$scope.items || !$scope.selectedList.length)
					return tableItems;

				if ($scope.selectedListSetup && $scope.selectedListSetup.GroupBy) {
					$scope.items.data.forEach(function (group) {
						if (group.data) {
							group.data.forEach(function (item) {
								tableItems.push(item);
							});
						}
					});
				}
				else
					tableItems = $scope.items.data;

				tableItems = _.filter(tableItems, function (item) {
					return $scope.selectedList.includes(item.Guid);
				});
				return tableItems;
			};

			$scope.getWebListViews = function (selectedSetup, overwriteSelected) {

				if ($scope.navigation.params.menuGuid) {
					menuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
					if (menuParams.selectedWebListView && menuParams.selectedWebListView.Guid)
						selectedSetup = menuParams.selectedWebListView;
				}

				var filterString = repository.location.search().filter;
				if (filterString) {
					try {
						urlFilter = listCompressionService.decodeListUri(filterString);
					} catch (e) {
						repository.growl(e, 'danger');
					}
				}

				if (urlFilter && !overwriteSelected) {
					selectedSetup = urlFilter.selectedListSetup;
					if (urlFilter.filter) {
						$scope.filters = urlFilter.filter;
					} else {
						if ($scope.selectedListSetup.Filter) {
							$scope.filters = $scope.selectedListSetup.Filter;
						}
					}
				}

				var ignoredFilterPropertiesForShowFilter = [
					'PropertyFilter', 'searchString', 'ActivityStatus', 'CleaningStatus', 'isBuildingDependent',
					'isCorrectiveAction', 'active', 'IsTemplate', 'GetAll'
				];

				if ($scope.filters) {
					for (var filter in $scope.filters) {
						if (ignoredFilterPropertiesForShowFilter.indexOf(filter) === -1)
							if ($scope.filters[filter]) $scope.showFilter = true;
					}
				}

				listService.getWebListViews(selectedSetup, $state.params.entityData.prefix, false, $scope.contextId, $scope.filters).then(function (result) {
					if (isViewDestroyed) return;

					$scope.listOptions = result.listOptions;
					$scope.selectedListSetup = result.selectedListSetup;

					var planiaStandard = $scope.listOptions.find(function (option) { return option.IsPlaniaStandard; });

					// Allow standard listview when navigating from KpiWidget
					if (urlFilter) {
						if (!selectedSetup || (!selectedSetup.Guid && !selectedSetup.inMemory))
							$scope.selectedListSetup = planiaStandard;
					}

					// Allow standard listview when using webmenu
					if (menuParams) {
						if (!selectedSetup && (!menuParams.selectedWebListView || !menuParams.selectedWebListView.Guid))
							$scope.selectedListSetup = planiaStandard;
					}

					if ($state.params.entityData.prefix === 'AreaXCleaningTask' && repository.authService.getUserData().guidPerson) {
						repository.getSingle(repository.apiData.person.url, repository.authService.getUserData().guidPerson, JSON.stringify(["ResourceGroup.Id"])).then(
							function (result) {
								if (isViewDestroyed) return;
								var person = result.Data;
								if (person.GuidResourceGroup && person.DoesCleaningTasks) {
									$scope.selectedResourceGroup = person.ResourceGroup.Id;
									if (!menuParams)
										menuParams = {};
									menuParams.guidResourceGroup = person.GuidResourceGroup;
								}
							});
					}

					if ($scope.selectedListSetup.Filter)
						$scope.filters = $scope.selectedListSetup.Filter;
					if (!$scope.items) {
						getItems();
					}
				});
			};

			$scope.resetFilter = function () {
				if ($scope.filters) {
					for (var filter in $scope.filters) {
						if (filter !== 'PropertyFilter' && filter !== 'searchString' && filter !== 'ActivityStatus' && filter !== "CleaningStatus" && filter !== "GetAll") {
							$scope.filters[filter] = null;
						}
					}
				}
			};


			$scope.getWebListViews();

			$scope.$watch('selectedListSetup', function (newValue, oldValue) {
				if (oldValue === newValue)
					return;

				$scope.selectedListSetup.VisibleColumns = _.filter($scope.selectedListSetup.Columns, function (c) {
					if (c.Filter && c.Filter.some(function (f) { return f.Hidden; }))
						return false;
					return true;
				});

				if ($scope.items) {
					$scope.items.$params.sorting = $scope.selectedListSetup.Sorting;
					listService.updateSelectedListSetup($state.params.entityData.prefix, $scope.selectedListSetup);

					if ($scope.selectedListSetup.GroupBy) {
						$scope.items.settings().groupBy = function (obj) {
							return listService.getGroupName($state.params.entityData.prefix, obj);
						};
					} else {
						$scope.items.settings().groupBy = null;
					}

					$scope.items.reload();
				}
			});

			$scope.$on($scope.events.newSelection, function () {
				$scope.getWebListViews();
				//if ($scope.items) {
				//    $scope.items.reload();
				//}
			});

			$scope.$on('$destroy', function () {
				isViewDestroyed = true;
			});

			$scope.toggleTreeView = function () {
				$scope.showTreeView = !$scope.showTreeView;

				$localStorage['showTreeView' + $state.params.entityData.prefix] = $scope.showTreeView;
			};

			$scope.selectedList = [];

			if ($scope.preSelected)
				$scope.selectedList.push($scope.preSelected);

			$scope.addSelected = function (guid) {
				if (!_.contains($scope.selectedList, guid))
					$scope.selectedList.push(guid);
				else {
					for (var i = 0; i < $scope.selectedList.length; i++) {
						var p = $scope.selectedList[i];
						if (p === guid) {
							$scope.selectedList.splice(i, 1);
							return;
						}
					}
				}
			};

			$scope.isSelected = function (guid) {
				return _.contains($scope.selectedList, guid);
			};

			$scope.getPropertyValue = function (item, column) {
				return listService.GetPropertyValue(item, column);
			};

			$scope.getGroupedByHeaderValue = function (groupByColumnValue) {
				if ($scope.selectedListSetup.GroupBy) {
					var column = _.find($scope.selectedListSetup.Columns,
						function (c) {
							return c.Property === $scope.selectedListSetup.GroupBy;
						});
					if (column) {
						if (column.PropertyType && column.PropertyType.startsWith('enum.')) {
							var enumType = column.PropertyType.split('.')[1];
							if (enumType) {
								return translationService.translate('web-enum-' + enumType + '-' + groupByColumnValue);
							}
						}
					}
				}
				return groupByColumnValue;
			};

			$scope.markRow = function (item) {
				if (!$localStorage.generalOptions) return false;
				return item.HasUnreadComments;
			};

			var openEquipmentOperatingHoursModal = function (item) {
				if (!$scope.hasEditAccess("EquipmentOperatingHours"))
					return;

				$modal.open({
					templateUrl: 'app/equipment/views/equipmentOperatingHoursModal.html',
					controller: 'EquipmentOperatingHoursController',
					size: 'sm',
					resolve: {
						params: function () { return item; }
					}
				}).result.then(function (result) {
					$scope.items.reload();
				}, function () {
				});
			};

			var openImageModal = function (item) {
				$modal.open({
					templateUrl: 'app/document/views/editImageModal.html',
					controller: 'EditImageController',
					size: 'lg',
					resolve: {
						params: function () { return { image: item, viewOnly: true }; }
					}
				}).result.then(function (result) {
				}, function () {
				});
			};

			var openImageXEntityModal = function (item) {
				var imageXEntity = angular.copy(item);
				imageXEntity.image = imageXEntity.Image;
				imageXEntity.deleteAccess = $scope.hasDeleteAccess('ImageXEntity');
				imageXEntity.guidEntity = imageXEntity['Guid' + imageXEntity.LinkedEntityType];
				$modal.open({
					templateUrl: 'app/document/views/editImageModal.html',
					controller: 'EditImageController',
					size: 'lg',
					resolve: {
						params: function () { return imageXEntity; }
					}
				}).result.then(function (result) {
					if (result) $scope.items.reload();
				}, function () {
				});
			};

			$scope.goToEdit = function (item, event) {
				var itemPrefix = $state.params.entityData.prefix;
				if (itemPrefix === 'ControlListXEntity' || itemPrefix === 'ControlListItemAnswer') {
					var params = { restrictEdit: true };
					params.workOrderCaption = itemPrefix === 'ControlListXEntity' ? item.WorkOrder.Caption : item.ControlListXEntity.WorkOrder.Caption;
					params.controlList = itemPrefix === 'ControlListXEntity' ? item.ControlList : item.ControlListXEntity.ControlList;
					params.controlListXEntity = itemPrefix === 'ControlListXEntity' ? item : { Guid: item.GuidControlListXEntity };

					$modal.open({
						templateUrl: 'app/controlList/views/controlListCompletionModal.html',
						size: 'xl',
						controller: 'ControlListCompletionModalController',
						resolve: {
							params: function () {
								return params;
							}
						}
					});
					// Return since we don't want to move away from the list.
					return;
				}

				if (itemPrefix === 'AreaXCleaningTask') {
					var tabs = {
						cleaningCompletion: { visible: repository.authService.hasCreateAccess(repository.apiData.cleaningCompletion.prefix), active: true },
						cleaningInfo: { visible: repository.authService.hasReadAccess(repository.apiData.areaXCleaningTask.prefix), active: false },
						cleaningRequest: { visible: repository.authService.hasCreateAccess(repository.apiData.areaXCleaningTask.prefix), active: false },
						technicalRequest: { visible: repository.authService.hasCreateAccess(repository.apiData.request.prefix), active: false },
					};

					$modal.open({
						templateUrl: 'app/dwg/views/dwgSelectionModal.html',
						size: 'lg',
						controller: 'DwgSelectionModalController',
						resolve: {
							params: function () { return { guid: item.GuidArea, tabInfo: tabs, task: item }; }
						}
					});
					return;
				}

				if (itemPrefix === 'PurchaseOrderItem') {
					if (!$scope.hasEditAccess("PurchaseOrderItem"))
						return;

					$modal.open({
						templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
						controller: 'PurchaseOrderItemModalController',
						size: 'xl',
						resolve: {
							params: function () {
								return {
									Guid: item.Guid
								};
							}
						}
					}).result.then(function (result) {
						$scope.items.reload();
					}, function () {
					});
					return;
				}

				if (itemPrefix === 'EquipmentOperatingHours') {
					openEquipmentOperatingHoursModal(item);
					return;
				}

				if (itemPrefix === 'Image') {
					openImageModal(item);
					return;
				}

				if (itemPrefix === 'ImageXEntity') {
					openImageXEntityModal(item);
					return;
				}

				if (itemPrefix === 'WorkOrderXResourceGroup') {
					if ($scope.hasReadAccess('WorkOrder'))
						$rootScope.go('workOrder.edit', { guid: item.GuidWorkOrder, menuGuid: $scope.navigation.params.menuGuid }, event);
					return;
				}

				if ($state.params.entityData.prefix === 'Drawing') {
					if ($state.current.name === 'cleaning.dwg.list') {
						var guid = itemPrefix === 'DocumentXEntity' ? item.GuidDocument : item.Guid;
						if ($scope.userInfo.guidPerson && $scope.hasReadAccess('Person')) {
							repository.getSingle(repository.apiData.person.url, $scope.userInfo.guidPerson).then(function (result) {
								if (result.Data.DoesCleaningTasks)
									$rootScope.go($state.params.entityData.navigationState, { guid: guid, menuGuid: $scope.navigation.params.menuGuid, selectedTheme: 'cleaning' }, event);
								else
									$rootScope.go($state.params.entityData.navigationState, { guid: guid, menuGuid: $scope.navigation.params.menuGuid, theme: 'cleaningMaintenance' }, event);
							}, function (error) {
								$rootScope.go($state.params.entityData.navigationState, { guid: guid, menuGuid: $scope.navigation.params.menuGuid, theme: 'cleaningMaintenance' }, event);
							});
						}
						else
							$rootScope.go($state.params.entityData.navigationState, { guid: guid, menuGuid: $scope.navigation.params.menuGuid, theme: 'cleaningMaintenance' }, event);
						return;
					}
				}

				if (itemPrefix === 'CleaningCompletion') {
					$modal.open({
						templateUrl: 'app/cleaningCompletion/views/cleaningCompletionModal.html',
						size: 'lg',
						controller: 'CleaningCompletionModalController',
						resolve: {
							params: function () {
								return {
									guid: item.Guid
								};
							}
						}
					});
					return;
				}

				if (itemPrefix === 'DataImport') {
					if (item.InputConfig && item.InputConfig.Type === 'Bim') {
						$rootScope.go('dataImport.bim', { guid: item.Guid, menuGuid: $scope.navigation.params.menuGuid }, event);
					}
					if (item.InputConfig && item.InputConfig.Type === 'Excel') {
						$rootScope.go('dataImport.edit', { guid: item.Guid, menuGuid: $scope.navigation.params.menuGuid }, event);
					}
					return;
				}

				if ($scope.clickable) {
					if ($state.params.modal) {
						$modal.open({
							templateUrl: $state.params.modal.templateUrl,
							controller: $state.params.modal.controller,
							resolve: {
								params: function () { return { guid: item.Guid, menuGuid: $scope.navigation.params.menuGuid, modalParam: $state.params.modal.modalParam }; }
							}
						}).result.then(function (result) {
							$scope.items.reload();
						});
					} else {
						var itemGuid = itemPrefix === 'DocumentXEntity' ? item.GuidDocument : item.Guid;
						$rootScope.go($state.params.entityData.navigationState, { guid: itemGuid, menuGuid: $scope.navigation.params.menuGuid }, event);
					}
				}
			};

			$scope.massEditEnabled = function () {
				return $scope.generalOptions.EnableMassEdit || $scope.hasModule('DataAcquisition');
			};

			$scope.massEdit = function () {
				if ($scope.selectedList.length === 0) {
					swal(
						'Ingen valg',
						translationService.translate('list-mass-edit-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å masseredigere'),
						'error');
				} else {
					$modal.open({
						templateUrl: 'app/common/views/massEditModal.html',
						controller: 'MassEditModalController',
						resolve: {
							params: function () {
								return {
									columns: $scope.selectedListSetup.Columns,
									selectedRows: $scope.selectedList,
									selectedRowsObjects: _.filter($scope.items.data, function (o) { return _.contains($scope.selectedList, o.Guid); }),
									prefix: $state.params.entityData.prefix
								};
							}
						}
					}).result.then(function (editedRows) {
						$scope.items.reload();
					},
						function () {
							//cancel
						});
				}
			};

			$scope.bulkDelete = function () {
				if ($scope.selectedList.length === 0) {
					swal('Ingen valg',
						translationService.translate('list-mass-delete-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å masseslette'),
						'error');
				}
				else {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-listService-delete-message', 'Valgte objekter vil bli permanent fjernet!'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-listService-button-confirm', 'Ja, fjern objektene!'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;

						var promises = [];
						$scope.selectedList.forEach(function (row) {
							var guid = row.Guid;
							if (guid === undefined)
								guid = row;
							promises.push(repository.deleteSingle($state.params.entityData.url, guid));
						});

						$q.all(promises).then(function (result) {
							swal(translationService.translate('web-swal-listService-success', 'Objektene ble fjernet!'), "", "success");
							$scope.items.reload();
							$scope.selectedList = [];
						});
					});
				}
			};

			$scope.copy = function (guid) {
				var selectedList = guid ? [guid] : $scope.selectedList;

				if (selectedList.length < 1) {
					swal('Ingen valg',
						translationService.translate('list-copy-missingSelect', 'Velg en rader fra listen for å kopiere.'),
						'error');
				} else if (selectedList.length > 1) {
					swal('Ingen valg',
						translationService.translate('list-copy-toomanyselected', 'Du kan kun kopiere én rad om gangen.'),
						'error');
				} else {
					var prefix = $state.params.entityData.prefix;
					var selectedGuid = selectedList[0];

					switch (prefix) {
						case "PeriodicTask":
							// by waiting that entity logic burried in periodicTaskControler can be accessed independently from the loading of related view, process specific case in general file
							repository.getSingle(repository.apiData.periodicTask.url, selectedGuid).then(function (result) {
								var periodicTask = result.Data;
								if (periodicTask.IsTemplate || periodicTask.GuidTemplate) {
									swal({
										title: translationService.translate('web-swal-create-copy-without-reference-to-template', 'Opprett en redigerbar kopi uten koblinger mot malbygg?'),
										type: "warning",
										showCancelButton: true,
										confirmButtonColor: "#f44336",
										confirmButtonText: translationService.translate('web-swal-button-confirm', 'Ja'),
										cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
										closeOnConfirm: true
									}, function () {
										window.onkeydown = null;
										window.onfocus = null;
										$scope.navigation.go(prefix.charAt(0).toLowerCase() + prefix.slice(1) + '.create', { guidEntityToCopy: selectedGuid });
									});
								}
								else
									$scope.navigation.go(prefix.charAt(0).toLowerCase() + prefix.slice(1) + '.create', { guidEntityToCopy: selectedGuid });
							});
							break;
						case "Area":
							if ($state.params.entityData.navigationState === "housing.edit") {
								$scope.navigation.go('housing.create', { guidEntityToCopy: selectedGuid });
							} else {
								$scope.navigation.go(prefix.charAt(0).toLowerCase() + prefix.slice(1) + '.create', { guidEntityToCopy: selectedGuid });
							}
							break;
						case "WebDashboard":
							if ($scope.loadingStates.isCreatingWebDashboardCopy) return;
							$scope.loadingStates.isCreatingWebDashboardCopy = true;

							registeredFieldService.getRegisteredField(repository.commonService.prefix.WebDashboard).then(function (registeredFields) {
								var idRule = _.filter(registeredFields, { DomainFieldName: 'Id' })[0];
								var maxLength = 30;
								if (idRule && idRule.MaxLength && idRule.MaxLength > 0)
									maxLength = idRule.MaxLength;

								repository.getSingle(repository.apiData.webDashboard.url, "00000000-0000-0000-0000-000000000000", "&GuidCopyFrom=" + selectedGuid).then(function (result) {
									var webDashboardToCreate = result.Data;
									var newId = webDashboardToCreate.Id + " " + translationService.translate('web-listController-webDashboard-copy-defaultIdSuffix', '(kopi)');
									newId = newId.substring(0, maxLength);
									swal({
										title: translationService.translate('web-listController-webDashboard-copy-swalTitle', 'Nytt navn for kopi'),
										type: "input",
										showCancelButton: true,
										closeOnConfirm: false,
										inputValue: newId,
									}, function (text) {
										window.onkeydown = null;
										window.onfocus = null;

										if (text === false) {
											$scope.loadingStates.isCreatingWebDashboardCopy = false;
											return false;
										}

										if (text === "") {
											swal.showInputError(translationService.translate('web-listController-webDashboard-copy-id-mandatory', 'Id er obligatorisk for dashbord'));
											return false;
										}

										if (text.length > maxLength) {
											swal.showInputError(translationService.translate('web-listController-webDashboard-copy-id-tooLong', 'Id overskrider maks antall tegn -') + " " + maxLength);
											return false;
										}

										swal.close();

										webDashboardToCreate.Id = text;
										repository.createSingle(repository.apiData.webDashboard.url, webDashboardToCreate).then(function (response) {
											$scope.navigation.go('dashboard.view', { guid: response.Guid });
											$scope.loadingStates.isCreatingWebDashboardCopy = false;
										}, function (error) {
											repository.showError(error);
											$scope.loadingStates.isCreatingWebDashboardCopy = false;
										});
									});

								}, function (error) {
									repository.showError(error);
									$scope.loadingStates.isCreatingWebDashboardCopy = false;
								});
							}, function () {
								$scope.loadingStates.isCreatingWebDashboardCopy = true;
							});
							break;
						default:
							$scope.navigation.go(prefix.charAt(0).toLowerCase() + prefix.slice(1) + '.create', { guidEntityToCopy: selectedGuid });
							break;
					}
				}
			};

			$scope.documentExport = function () {
				if (!$scope.selectedList || $scope.selectedList.length === 0) {
					swal(translationService.translate('web-swal-document-exportDocument-title', 'Dokumenteksport'),
						translationService.translate('web-swal-document-exportDocument-noItems', 'Ingen rader markert. Marker en eller flere rader for eksport'),
						'error');
					return;
				}

				repository.createSingle(repository.apiData.document.endpoint.exportSizeEstimate, $scope.selectedList, 'preFix=' + $scope.prefix).then(function (result) {
					swal({
						title: translationService.translate('web-swal-document-exportDocument-title', 'Dokumenteksport'),
						text: translationService.translate('web-swal-document-exportDocument-message', 'Eksport av dokument kan ta tid, ønsker du å fortsette?\r\n Estimert total størrelse:') + ' ' + result.TotalSize,
						type: "info",
						showCancelButton: true,
						confirmButtonColor: "#337ab7",
						confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						repository.createSingle(repository.apiData.document.endpoint.exportToken, $scope.selectedList, 'preFix=' + $scope.prefix).then(
							function (result) {
								if (!result.Token) return;
								var url = config.baseUrlApi + repository.apiData.document.endpoint.exportDownload + '?token=' + result.Token;
								window.open(url, '_self');
								swal(
									translationService.translate('web-swal-document-exportDocument-title', 'Dokumenteksport'),
									translationService.translate('web-swal-document-exportDocument-successMessage', 'Nedlasting starter innen kort tid'),
									'success');
							}, function (error) {
								swal.close();
								repository.showError(error);
							});
					});
				}, function (error) {
					repository.showError(error);
				});

			};

			//report specific function
			$scope.updateReports = function () {
				$modal.open({
					templateUrl: 'app/report/views/updateReport.html',
					controller: 'UpdateReportController',
					size: 'lg'
				}).result.then(function () {
					repository.growl('Rapporter har blitt oppdatert', 'info');
					$scope.items.reload();
				}, function () {
				});
			};

			//Document specific functions
			$scope.openDocument = function (document) {
				documentService.openDocument(document);
			};

			$scope.addNewDocument = function () {
				$modal.open({
					templateUrl: 'app/document/views/createDocumentModal.html',
					controller: 'CreateDocumentController',
					size: 'lg'
				}).result.then(function (guids) {
					if (guids.length === 1 || typeof (guids) === 'string') {
						guid = guids.length === 1 ? guids[0] : guids;
						$scope.navigation.go('document.edit', { guid: guid }, { reload: true });
					} else {
						$scope.preSelected = guids;
						guids.forEach(function (r) {
							$scope.selectedList.push(r);
						});
						$scope.items.reload();
					}
				}, function () {
					$scope.items.reload();
				});
			};

			$scope.addOperationalMessage = function () {
				$modal.open({
					templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
					controller: 'OperationalMessageModalController',
					resolve: {
						params: function () {
							return {

							};
						}
					}
				}).result.then(function () {
					$scope.items.reload();
				});
			};
			//End document

			//EntityCounter specific
			$scope.addEntityCounter = function () {
				$modal.open({
					templateUrl: 'app/entityCounter/views/entityCounterModal.html',
					controller: 'EntityCounterModalController',
					resolve: {
						params: function () {
							return {

							};
						}
					}
				}).result.then(function () {
					$scope.items.reload();
				});
			};
			//End entitycounter

			//drawing specific
			$scope.editDrawing = function (drawing) {
				$scope.navigation.go('dwg.edit', { guid: drawing.Guid }, { reload: true });
			};

			$scope.addNewDrawing = function () {
				$scope.navigation.go('dwg.create', {}, { reload: true });
			};

			//end drawing

			//BIM
			$scope.addNewBimFile = function () {
				$modal.open({
					templateUrl: 'app/bimFile/views/createBimFileModal.html',
					controller: 'CreateBimFileModalController',
					resolve: {
						modalParams: function () {
							return {};
						}
					}
				}).result.then(function (guids) {
					if (guids.length === 1 || typeof (guids) === 'string') {
						guid = guids.length === 1 ? guids[0] : guids;
						$scope.navigation.go('bimFile.edit', { guid: guid }, { reload: true });
					} else {
						$scope.preSelected = guids;
						guids.forEach(function (r) {
							$scope.selectedList.push(r);
						});
						$scope.items.reload();
					}
				}, function () {
					$scope.items.reload();
				});
			};

			//SpareParts
			$scope.createSparePart = function () {
				$modal.open({
					templateUrl: 'app/sparePart/views/createSparePartModal.html',
					controller: 'CreateSparePartModalController',
				}).result.then(function (sparePart) {
					$scope.navigation.go('sparePart.edit', { guid: sparePart.Guid }, { reload: true });
				}, function () {
					$scope.items.reload();
				});
			};

			$scope.createScheduledJob = function () {
				$modal.open({
					templateUrl: 'app/scheduledJob/views/createScheduledJobModal.html',
					controller: 'CreateScheduledJobModalController',
				}).result.then(function (result) {
					$scope.navigation.go('scheduledJob.create', { scheduledJobModel: result });
				}, function () {

				});
			};

			$scope.flushLogPerformance = function () {
				repository.createSingle('LogPerformance', {}).then(
					function (result) {
						swal('Log performance har blitt lagret', 'Log performance har blitt lagret til database', 'success');
					});
			};

			//Deviation function
			$scope.createWorkOrdersFromCondition = function () {
				$modal.open({
					templateUrl: 'app/workOrder/views/createWoFromConditionModal.html',
					controller: 'CreateWoFromConditionModalController',
					size: 'xl',
					resolve: {
						params: function () {
							return {
								SelectedListSetup: $scope.selectedListSetup
							};
						}
					}
				}).result.then(function (result) {
					$scope.items.reload();
				}, function () {
				});
			};

			$scope.closeDeviationDirectly = function (selectedGuid) {
				$modal.open({
					templateUrl: 'app/deviation/views/closeDeviationDirectlyModal.html',
					controller: 'CloseDeviationDirectlyModalController',
					resolve: {
						params: function () {
							return {
								guid: selectedGuid,
								close: true
							};
						}
					},
				}).result.then(function (result) { //Ok
					$scope.items.reload();
				});
			};

			$scope.createWorkOrder = function (isCondition, selectedGuid) {
				var workOrder = {
					fromDeviation: !isCondition,
					fromCondition: isCondition,
				};

				var type = isCondition ? 'tiltak' : 'avvik';
				var exitFunction = false;
				var errorMessage1 = '';
				var errorMessage2 = '';

				if (!selectedGuid && $scope.selectedList.length === 0) {
					var message = '';
					if (isCondition)
						message = translationService.translate('correctiveAction-list-createworkorder-missingSelect',
							'Ingen tiltak valgt. Velg en eller flere fra listen for å lage arbeidsordre.');
					else
						message = translationService.translate('deviation-list-createworkorder-missingSelect',
							'Ingen avvik valgt. Velg en eller flere fra listen for å lage arbeidsordre.');

					swal('Ingen valg', message, 'error');
					return;
				}

				var guidList = [];
				if (selectedGuid)
					guidList = [selectedGuid];
				else
					guidList = $scope.selectedList;

				workOrder.guidSelections = guidList;

				guidList.forEach(function (guid) {
					var tableItems = [];
					if ($scope.selectedListSetup && $scope.selectedListSetup.GroupBy) {
						$scope.items.data.forEach(function (group) {
							if (group.data) {
								group.data.forEach(function (item) {
									tableItems.push(item);
								});
							}
						});
					}
					else
						tableItems = $scope.items.data;

					tableItems.forEach(function (item) {
						if (item.Guid === guid) {
							if (!workOrder.guidEstate && item.GuidEstate) {
								workOrder.guidEstate = item.GuidEstate;
								if (item.Estate)
									workOrder.estate = item.Estate.Caption;
							}
							if (!workOrder.guidBuilding && item.GuidBuilding) {
								workOrder.guidBuilding = item.GuidBuilding;
								if (item.Building)
									workOrder.building = item.Building.Caption;
							}
							if (!workOrder.guidEquipment && item.GuidEquipment) {
								workOrder.guidEquipment = item.GuidEquipment;
								if (item.Equipment)
									workOrder.equipment = item.Equipment.Caption;
							}
							if (!workOrder.guidArea && item.GuidArea) {
								workOrder.guidArea = item.GuidArea;
								if (item.Area)
									workOrder.area = item.Area.Caption;
							}
							if (item.Estate && workOrder.guidEstate !== item.GuidEstate) {
								if (isCondition)
									errorMessage1 = translationService.translate('web-listview-createWorkOrder-condition-differentEstate', 'Det er bare mulig å opprette arbeidsordre fra tiltak på samme eiendom. ');
								else
									errorMessage1 = translationService.translate('web-listview-createWorkOrder-deviation-differentEstate', 'Det er bare mulig å opprette arbeidsordre fra avvik på samme eiendom. ');
								exitFunction = true;
							}
							if (item.Building && workOrder.guidBuilding !== item.GuidBuilding) {
								if (isCondition)
									errorMessage1 = translationService.translate('web-listview-createWorkOrder-condition-differentBuilding', 'Det er bare mulig å opprette arbeidsordre fra tiltak på samme bygg. ');
								else
									errorMessage1 = translationService.translate('web-listview-createWorkOrder-deviation-differentBuilding', 'Det er bare mulig å opprette arbeidsordre fra avvik på samme bygg. ');
								exitFunction = true;
							}

							if (item.Status && item.Status !== 'New') {
								if (isCondition)
									errorMessage2 = translationService.translate('web-listview-createWorkOrder-condition-statusIsNotNew', 'Du kan ikke generere arbeidsordre på et tiltak som ikke har status lik ny. ');
								else
									errorMessage2 = translationService.translate('web-listview-createWorkOrder-deviation-statusIsNotNew', 'Du kan ikke generere arbeidsordre på et avvik som ikke har status lik ny. ');
								exitFunction = true;
							}

							if (item.GuidCorrectiveWorkOrder) {
								if (isCondition)
									errorMessage2 = translationService.translate('web-listview-createWorkOrder-condition-hasGuidCorrectiveWorkOrder', 'Bare nye tiltak kan generere arbeidsordre. ');
								else
									errorMessage2 = translationService.translate('web-listview-createWorkOrder-deviation-hasGuidCorrectiveWorkOrder', 'Bare nye avvik kan generere arbeidsordre. ');
								exitFunction = true;
							}

							if (isCondition && item.Type === 'CurrentConditionInfo') {
								errorMessage2 = translationService.translate('web-listview-createWorkOrder-condition-isCurrentConditionInfo', 'Kan ikke opprette arbeidsordre fra tilstandsinformasjon.');
								exitFunction = true;
							}

							workOrder.defaultDescription = isCondition ?
								translationService.translate('correctiveAction-new-workOrder-defaultDescription', 'Gjennomføring av tiltak') :
								translationService.translate('deviation-new-workOrder-defaultDescription', 'Utbedring av avvik');

							// Selecting only one item should prefill some fields in the work order creation. Currently only for deviation
							if (guidList.length === 1) {
								if (!isCondition) {
									workOrder.defaultText = item.ActionComment;

									workOrder.defaultDescription = translationService.translate('deviation-new-workOrder-description', 'Utbedring av') + ' ' + item.Id;
									if (item.GuidDeviationType) {
										if (item.DeviationType && item.DeviationType.Id) {
											workOrder.defaultDescription += ' - ' + item.DeviationType.Id;
										} else {
											workOrder.enumTypeGuid = item.GuidDeviationType;
										}
									}
								}
							}
						}
					});
				});

				if (exitFunction) {
					swal('Feilmelding', errorMessage1 + errorMessage2, 'error');
					return;
				}

				repository.persistedData.setPersistedData('workOrder.create', workOrder);
				$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
			};

			$scope.confirmCondition = function (guid) {
				var selection = guid ? [guid] : [];
				if ($scope.selectedList.length > 0) {
					selection = [];
					$scope.selectedList.forEach(function (guid) {
						selection.push(guid);
					});
				}

				if (!selection.length) {
					swal(
						'Ingen valg',
						translationService.translate('web-list-confirmCondition-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å bekrefte tilstand/tiltak.'),
						'error');
					return;
				}

				$modal.open({
					templateUrl: 'app/condition/views/conditionConfirmVersionModal.html',
					controller: 'ConditionConfirmVersionModalController',
					size: 'md',
					resolve: {
						modalParams: function () {
							return {
								guids: selection,
							};
						}
					}
				}).result.then(function () {
					$scope.items.reload();
					$scope.selectedList = [];
				});
			};

			//#region PeriodicTask specific functions
			$scope.createWorkOrderFromPeriodicTask = function (guid) {
				if ((!$scope.userInfo.canRunSystemFunction && !$scope.userInfo.canRunNewPeriod) || !$scope.hasEditAccess('WorkOrder')) return;
				if (!guid && ($scope.routeEntity === 'periodicTaskTemplate' || $scope.routeEntity === 'workOrderTemplate' || $scope.selectedList.length <= 0 || $scope.selectedList.length > 20)) return;

				if ($scope.selectedList.length === 0 && !guid) {
					swal(
						'Ingen valg',
						translationService.translate('web-list-createWorkOrderFromPeriodicTask-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å opprette AO.'),
						'error');
					return;
				}

				if ($scope.selectedList.length > 20 && !guid) {
					swal(
						'For mange valgt',
						translationService.translate('web-list-createWorkOrderFromPeriodicTask-tooManySelect', 'For mange periodiske rutiner er valgt. Maksgrensen er 20 periodiske rutiner.'),
						'error');
					return;
				}

				$modal.open({
					templateUrl: 'app/periodicTask/views/periodicTaskGenerateOrdersConfirmationModal.html',
					controller: 'PeriodicTaskGenerateOrdersConfirmationModalController',
					resolve: {
						params: function () {
							return {
								guids: guid ? [guid] : $scope.selectedList
							};
						}
					},
					size: "lg"
				}).result.then(function (result) {
					$scope.items.reload();
				}, function () {
				});
			};

			//#endregion

			//#region WorkOrder specific functions
			$scope.connectWorkOrdersToProject = function (guid) {
				if (!guid && $scope.selectedList.length === 0) {
					swal(
						'Ingen valg',
						translationService.translate('web-list-connectProject-edit-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å koble til prosjekt'),
						'error');
				} else {
					// Button should only be visible if the currently logged inn person has any active projects
					var filter = {
						FilterModel: {
							Condition: "and",
							Rules: [
								{ Property: 'ClosedDate', Operator: '=', value: null },
								{ Property: 'IsTemplate', Operator: '=', value: 0 },
							]
						}
					};

					if (!$scope.userInfo.isSystemAdministrator && !$scope.userInfo.isProjectAdministrator) {
						filter.FilterModel.Rules.push({
							Condition: "or",
							Rules: [
								{ Property: 'GuidOwnerPerson', Operator: '=', value: $scope.userInfo.guidPerson },
								{ Property: 'GuidManagerPerson', Operator: '=', value: $scope.userInfo.guidPerson },
							]
						});
					}

					swal({
						title: translationService.translate('web-swal-project-modify-workOrder-warning', 'Dette kan påvirke prosjektets økonomi, ønsker du å fortsette?'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal.close();
						$modal.open({
							templateUrl: 'app/common/views/singleSelectModal.html',
							controller: 'SingleSelectModalController',
							size: 'lg',
							resolve: {
								modalParams: function () {
									return {
										title: translationService.translate('web-workOrder-connect-project', 'Velg prosjekt å koble arbeidsordrene til'),
										url: repository.apiData.project.url,
										sorting: { 'CreationDate': 'desc' },
										filter: filter,
										columns: [
											{ title: translationService.translate('web-project-Id', 'Ao Nr'), property: 'Id' },
											{ title: translationService.translate('web-project-Description', 'Beskrivelse'), property: 'Description', PropertyType: 'String' },
											{ title: translationService.translate('web-project-Building-Id', 'Bygg Id'), property: 'Building.Id' },
											{ title: translationService.translate('web-project-Building-Description', 'Byggbeskrivelse'), property: 'Building.Description', PropertyType: 'String' },
										]
									};
								}
							}
						}).result.then(function (selectedEntity) {
							repository.updateMultipleWithGuid(repository.apiData.project.endpoint.addWorkOrders, selectedEntity.Guid, guid ? [guid] : $scope.selectedList)
								.then(function (result) {
									if (guid)
										repository.growl(translationService.translate('web-project-connectSingle-workOrder-success', 'Arbeidsordren har blitt koblet til prosjektet'), 'success');
									else
										repository.growl(translationService.translate('web-project-connect-workOrder-success', 'Arbeidsordrene har blitt koblet til prosjektet'), 'success');
									$scope.items.reload();
								}, function (error) {
									swal(translationService.translate('web-project-connectWorkOrders-error-title', 'En eller flere feiler oppstod under kobling til prosjekt'), error, 'error');
									$scope.items.reload();
								});
						});
					});
				}
			};

			$scope.closeWorkOrderReinvoicing = function (guid) {
				if (!$scope.generalOptions.UseReinvoicing || !$scope.hasModule('Invoice') || !$scope.userInfo.canRunSystemFunction || !$scope.hasEditAccess('WorkOrder')) return;
				if (!guid && $scope.selectedList.length === 0) {
					swal(
						'Ingen valg',
						translationService.translate('web-list-setInvoiced-edit-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for sluttfakturering'),
						'error');
				} else {
					swal({
						title: translationService.translate('web-swal-workOrder-closeWorkOrderReinvoicing-title', 'Bekreft sluttfakturering'),
						text: translationService.translate('web-swal-workOrder-closeWorkOrderReinvoicing-message', 'Dette vil sette alle valgte arbeidsordre som har en faktureringsstatus til sluttfakturert.\n\n Hvis mange arbeidsordre er valgt, så kan operasjonen ta lang tid.'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						repository.runAction(repository.apiData.workOrder.endpoint.closeReinvoicing, guid ? [guid] : $scope.selectedList).then(function (result) {
							swal(translationService.translate('web-workorder-close-reinvoicing-success', 'Arbeidsordrene er sluttfakturert'), result, "success");
							$scope.items.reload();
						});
					});
				}
			};

			$scope.sendWorkOrdersToHistory = function () {
				if ((!$scope.userInfo.canRunSystemFunction && !$scope.userInfo.canRunNewPeriod) || !$scope.hasEditAccess('WorkOrder')) return;
				$modal.open({
					templateUrl: 'app/workOrder/views/sendWorkOrderToHistoryModal.html',
					controller: 'SendWorkOrderToHistoryModalController',
					size: 'xl',
					resolve: {
						params: function () {
							return {
								SelectedListSetup: $scope.selectedListSetup
							};
						}
					}
				}).result.then(function (result) {
					$scope.items.reload();
				}, function () {
				});
			};

			//#endregion

			//#region WebUserToken functions
			$scope.forceLogout = function () {
				if ($scope.selectedList.length === 0) {
					swal('Ingen valg',
						translationService.translate('web-webUserToken-selectedList-missing', 'Ingen valg. Velg en eller flere brukere for kaste dem ut av systemet'),
						'error');
				}
				else {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-webUserToken-selectedList-logout-confirmationMessage', 'Valgte brukere vil bli kastet ut av Plania, men dette vil ikke sperre dem fra å logge inn på nytt'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-webUserToken-selectedList-logout-confirm', 'Ja, kast ut brukere!'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;

						repository.updateMultiple($state.params.entityData.url + 'forceLogout/', $scope.selectedList).then(function (result) {
							swal(translationService.translate('web-webUserToken-delete-success', 'Brukerene har blitt kastet ut'), "", "success");
							$scope.items.reload();
							$scope.selectedList = [];
						});
					});
				}
			};
			//#endregion

			$scope.generateCalendarEvent = function (guid) {
				if (!guid && $scope.selectedList.length < 1) {
					swal('Ingen valg', translationService.translate('web-list-missingSelect', 'Velg minst en rad i listen'), 'error');
					return;
				}
				var filter = {};

				if (guid)
					filter.PropertyFilter = [{ Property: 'Guid', Operator: '=', value: guid }];
				else
					filter.PropertyFilter = [{ Property: 'Guid', Operator: 'in', value: $scope.selectedList.join(',') }];

				repository.getWithUrlParameter(repository.apiData.workOrderCalendar.url, 'calendar=true', filter).then(function (response) {
					var blob = new Blob([response], { type: "text/calendar" });
					if (window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveBlob(blob, translationService.translate('web-workorder-calendar-filename', 'arbeidsordrer') + ".ics");
					}
					else {
						var element = document.createElement('a');
						element.style.display = 'none';
						element.href = window.URL.createObjectURL(blob);
						element.download = translationService.translate('web-workorder-calendar-filename', 'arbeidsordrer') + ".ics";
						document.body.appendChild(element);
						element.click();
						document.body.removeChild(element);
						window.URL.revokeObjectURL(element.href);
					}
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var getMenuLink = function (url, text, icon) {
				var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
				// Show icon, else draw a random icon to keep same padding on left.
				if (icon)
					htmlText += '<i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
				else
					htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
				htmlText += text + '</a>';

				return {
					html: htmlText,
					isHref: true
				};
			};

			$scope.contextMenu = new function () {
				var me = this;

				this.useDefaultParentObjectNavigation = true;

				this.addDefaultParentObjectNavigation = function (item, options) {
					if (!$rootScope.userInfo.enableWebNavigation || !this.useDefaultParentObjectNavigation) return;

					var navigationOptions = [];

					if (item.GuidEstate && !item.GuidBuilding && !item.GuidEquipment && !item.GuidArea && $scope.hasReadAccess('Estate'))
						navigationOptions.push(getMenuLink($rootScope.navigation.href('estate.edit', { guid: item.GuidEstate }), translationService.translate('web-listview-contextMenu-common-viewEstate', 'Gå til eiendom')));
					if (item.GuidBuilding && !item.GuidEquipment && !item.GuidArea && $scope.hasReadAccess('Building'))
						navigationOptions.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: item.GuidBuilding }), translationService.translate('web-listview-contextMenu-common-viewBuilding', 'Gå til bygg')));
					if (item.GuidEquipment && $scope.hasReadAccess('Equipment'))
						navigationOptions.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.GuidEquipment }), translationService.translate('web-listview-contextMenu-common-viewEquipment', 'Gå til anlegg')));
					if (item.GuidArea && $scope.hasReadAccess('Area')) {
						if (item.Area && item.Area.IsHousing) {
							navigationOptions.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.GuidArea }), translationService.translate('web-listview-contextMenu-common-viewHousing', 'Gå til bolig')));
						} else {
							navigationOptions.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.GuidArea }), translationService.translate('web-listview-contextMenu-common-viewArea', 'Gå til areal')));
						}
					}

					if (navigationOptions.length > 0) {
						navigationOptions[0].hasTopDivider = !!options.length;
						navigationOptions.forEach(function (o) {
							options.push(o);
						});
					}
				};

				this.getOptions = function (item) {
					var itemPrefix = $state.params.entityData.prefix;
					var options = [];
					var getOptions = me[itemPrefix];

					if (getOptions) {
						getOptions(item, options);
						me.addDefaultParentObjectNavigation(item, options);
					}
					else {
						// angular context menu will always display, even if it contains no item (!)
						// -> for the moment make it as narrow as possible by adding one invisible item (by waiting maybe default items related to CrUD functionalites...)
						options.push({
							html: '<a class="dropdown-item" style="display:none" tabindex= "-1" href= "#">Ingen handlinger tilgjengelig</a>'
						});
					}

					return options;
				};

				this.hasOptions = function () {
					var itemPrefix = $state.params.entityData.prefix;
					var getOptions = me[itemPrefix];
					return !!getOptions;
				};

				this.WorkOrderXResourceGroup = function (item, options) {
					if ($scope.hasReadAccess('WorkOrder') && item.GuidWorkOrder) {
						options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-listview-contextMenu-workOrderXResourceGroup-viewWorkOrder', 'Gå til arbeidsordre')));
					}
				};

				this.EquipmentOperatingHours = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-equipmentOperatingHours-view', 'Åpne anleggstimer') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							openEquipmentOperatingHoursModal(item);
						}
					});

					if ($scope.hasReadAccess('Equipment') && item.GuidEquipment && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.GuidEquipment }), translationService.translate('web-listview-contextMenu-equipment-view', 'Gå til anlegg')));
					}
				};

				this.EntityCounter = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-entityCounter-view', 'Åpne autonummerering') + '</a >',
						click: function ($itemScope, $event) {
							$scope.goToEdit(item, $event);
						}
					});

					if ($scope.hasDeleteAccess('EntityCounter')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="fas invisible  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-entityCounter-delete', 'Slett') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								swal({
									title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
									text: translationService.translate('web-swal-entityCounter-message', 'Autonummereringen vil bli fjernet!'),
									type: "warning",
									showCancelButton: true,
									confirmButtonColor: "#f44336",
									confirmButtonText: translationService.translate('web-swal-entityCounter-button-confirm', 'Ja, fjern autonummereringen!'),
									cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
									closeOnConfirm: false,
									showLoaderOnConfirm: true
								}, function () {
									repository.deleteSingle(repository.apiData.entityCounter.url, item.Guid).then(function (result) {
										swal(translationService.translate('web-swal-entityCounter-success', 'Autonummereringen ble fjernet!'), result, "success");
										$scope.items.reload();
									}, function (error) {
										swal('Error', error, "error");
									});
								});
							}
						});
					}
				};

				this.Contract = function (item, options) {
					if ($state.current.name === 'reinvoicingContract.list')
						options.push(getMenuLink($rootScope.navigation.href('reinvoicingContract.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-reinvoicingContract-view', 'Åpne avtale')));
					else
						options.push(getMenuLink($rootScope.navigation.href('contract.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-contract-view', 'Åpne kontrakt')));

					if ($scope.hasReadAccess('Customer') && item.GuidCustomer && $rootScope.userInfo.enableWebNavigation) {
						if ($state.current.name === 'reinvoicingContract.list')
							options.push(getMenuLink($rootScope.navigation.href('housingCustomer.edit', { guid: item.GuidCustomer }), translationService.translate('web-listview-contextMenu-reinvoicingContract-viewHousingCustomer', 'Gå leietaker')));
						else
							options.push(getMenuLink($rootScope.navigation.href('customer.edit', { guid: item.GuidCustomer }), translationService.translate('web-listview-contextMenu-contract-viewCustomer', 'Gå til kunde')));
					}
				};

				//#region Basisdata

				this.Estate = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('estate.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-estate-view', 'Åpne eiendom')));
				};

				this.Building = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-building-view', 'Åpne bygg')));
				};

				this.Equipment = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-equipment-view', 'Åpne anlegg')));
				};

				this.Area = function (item, options) {
					if (item.IsHousing) {
						options.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-housing-view', 'Åpne bolig')));
					} else {
						options.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-area-view', 'Åpne areal')));
					}

					if ($scope.hasReadAccess('Drawing') && item.GuidDrawing && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('dwg.view', { guid: item.GuidDrawing }), translationService.translate('web-listview-contextMenu-area-viewDrawing', 'Gå til SVG-visningen')));
					}

					if (!!item.GuidIfc) {
						options.push(getMenuLink($rootScope.navigation.href('bimFile.viewBim', { guidArea: item.Guid }), translationService.translate('web-listview-contextMenu-area-viewBimsyncModel', 'Gå til bim modell')));
					}
				};

				this.Drawing = function (item, options) {
					// Drawing via cleaning has some special code to set correct view in the drawing. TODO - Find way to support this.
					if ($state.current.name === 'cleaning.dwg.list') return [];

					options.push(getMenuLink($rootScope.navigation.href('dwg.view', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-drawing-view', 'Åpne SVG-visningen')));
					options.push(getMenuLink($rootScope.navigation.href('dwg.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-drawing-editPage', 'Åpne tegningssiden')));
				};

				this.Article = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('article.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-article-view', 'Åpne artikkel')));
				};

				this.Document = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('document.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-document-view', 'Åpne dokument siden')));

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-eye fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-document-openDocument', 'Åpne dokument fil') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.openDocument(item);
						}
					});
				};

				this.DocumentXEntity = function (item, options) {
					me.useDefaultParentObjectNavigation = false;

					options.push(getMenuLink($rootScope.navigation.href('document.edit', { guid: item.Document.Guid }), translationService.translate('web-listview-contextMenu-document-view', 'Åpne dokument siden')));

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-eye fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-document-openDocument', 'Åpne dokument fil') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.openDocument(item.Document);
						}
					});

					var prefix = item.EntityType;
					var entityRoute = prefix.charAt(0).toLowerCase() + prefix.slice(1);
					var entityGuid = item['Guid' + prefix];
					var routeExists = repository.apiData[entityRoute];
					if (routeExists) {
						var title = translationService.translate('web-listview-contextMenu-documentXEntity-openEntity', 'Gå til') + ' ' + translationService.translate('web-' + prefix).toLowerCase();
						options.push(getMenuLink($rootScope.navigation.href(routeExists.navigationState, { guid: entityGuid }), title));
					}
				};

				this.Component = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('component.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-component-view', 'Åpne komponent')));
				};

				this.Customer = function (item, options) {
					if ($scope.contextId === 'List-HousingCustomer')
						options.push(getMenuLink($rootScope.navigation.href('housingCustomer.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-housingCustomer-view', 'Åpne leietaker')));
					else
						options.push(getMenuLink($rootScope.navigation.href('customer.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-customer-view', 'Åpne kunde')));
				};

				this.Supplier = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('supplier.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-supplier-view', 'Åpne leverandør')));
				};

				this.Person = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('person.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-person-view', 'Åpne person')));
				};

				//#endregion

				//#region Maintenance objects

				this.Budget = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('budget.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-budget-view', 'Åpne budsjett')));
				};

				this.Condition = function (item, options) {
					if (item.Type === 'CorrectiveAction')
						options.push(getMenuLink($rootScope.navigation.href('condition.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-condition-viewCorrectiveAction', 'Åpne tiltak')));
					else if (item.Type === 'CurrentConditionInfo')
						options.push(getMenuLink($rootScope.navigation.href('condition.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-condition-viewCurrentConditionInfo', 'Åpne gjeldende tiltak')));
					else
						options.push(getMenuLink($rootScope.navigation.href('condition.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-condition-view', 'Åpne tilstand')));

					if ($scope.hasCreateAccess("Condition")) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-view-list fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-condition-copy', 'Kopier valgt tilstand/tiltak') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.copy(item.Guid);
							}
						});
					}

					if (item.Type !== 'CurrentConditionInfo' && $scope.hasCreateAccess('WorkOrder') && ((!item.GuidCorrectiveWorkOrder && item.Status === 'New') || $scope.selectedList.length > 0)) {
						var htmlText = translationService.translate('web-listview-contextMenu-condition-createWorkOrder', 'Opprett arbeidsordre fra dette tiltaket');
						if ($scope.selectedList.length > 0)
							htmlText = translationService.translate('web-listview-contextMenu-condition-createWorkOrderFromSelected', 'Opprett arbeidsordre fra valgte tiltak') + ' (' + $scope.selectedList.length + ')';

						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-eye fa-hc-fw fa-hc-lg"></i> ' + htmlText + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								if ($scope.selectedList.length > 0)
									$scope.createWorkOrder(true);
								else
									$scope.createWorkOrder(true, item.Guid);
							}
						});
					}

					if ($localStorage.generalOptions.UseNewCorrectiveAction) {
						var confirmHtmlText = translationService.translate('web-listview-contextMenu-condition-confirmVersion', 'Bekreft');
						if ($scope.selectedList.length > 0)
							confirmHtmlText = translationService.translate('web-listview-contextMenu-condition-confirmVersionFromSelected', 'Bekreft valgte') + ' (' + $scope.selectedList.length + ')';

						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-eye fa-hc-fw fa-hc-lg"></i> ' + confirmHtmlText + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.confirmCondition(item.Guid);
							}
						});
					}

					if (item.Type !== 'CurrentConditionInfo' && $scope.hasReadAccess('WorkOrder') && $rootScope.userInfo.enableWebNavigation) {
						if (item.GuidInspectionWorkOrder)
							options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidInspectionWorkOrder }), translationService.translate('web-listview-contextMenu-condition-viewInspectionWorkOrder', 'Gå til arbeidsordre som opprettet tiltaket')));

						if (item.GuidCorrectiveWorkOrder) {
							if (item.Status === 'Closed')
								options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidCorrectiveWorkOrder }), translationService.translate('web-listview-contextMenu-condition-viewCorrectiveWorkOrderClosed', 'Gå til arbeidsordre som lukket tiltaket')));
							else
								options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidCorrectiveWorkOrder }), translationService.translate('web-listview-contextMenu-condition-viewCorrectiveWorkOrder', 'Gå til arbeidsordre som jobber med tiltaket')));
						}
					}
				};

				this.ControlList = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-view-list fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-controlList-view', 'Åpne kontrolliste') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.goToEdit(item);
						}
					});

					if ($scope.hasCreateAccess("ControlList")) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-view-list fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-controlList-copy', 'Kopier valgt kontrolliste') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.copy(item.Guid);
							}
						});
					}
				};

				this.ControlListItemAnswer = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-view-list fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-controlListItemAnswer-view', 'Åpne kontrollistesvar') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.goToEdit(item);
						}
					});
				};

				this.ControlListXEntity = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-view-list fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-controlListXEntity-view', 'Åpne utfylt kontrolliste') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.goToEdit(item);
						}
					});

					if ($scope.hasReadAccess('WorkOrder') && item.GuidWorkOrder && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-listview-contextMenu-controlListXEntity-viewWorkOrder', 'Gå til arbeidsordre')));
					}
				};

				this.Cost = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('cost.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-cost-view', 'Åpne kostnad')));
					if ($scope.hasReadAccess('WorkOrder') && item.GuidWorkOrder && $rootScope.userInfo.enableWebNavigation)
						options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-listview-contextMenu-cost-viewWorkOrder', 'Gå til arbeidsordre')));
					if ($scope.hasReadAccess('PurchaseOrder') && item.GuidPurchaseOrder && $rootScope.userInfo.enableWebNavigation)
						options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: item.GuidPurchaseOrder }), translationService.translate('web-listview-contextMenu-cost-viewPurchaseOrder', 'Gå til bestilling')));
					if ($scope.hasReadAccess('PurchaseOrderItem') && item.GuidPurchaseOrderItem && $rootScope.userInfo.enableWebNavigation) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-cost-viewPurchaseOrderItem', 'Åpne konteringsobjekt') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$modal.open({
									templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
									controller: 'PurchaseOrderItemModalController',
									size: 'xl',
									resolve: {
										params: function () {
											return {
												Guid: item.GuidPurchaseOrderItem
											};
										}
									}
								});
							}
						});
					}
				};

				this.Deviation = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('deviation.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-deviation-view', 'Åpne avvik')));

					if ($scope.hasCreateAccess('WorkOrder') && ((!item.GuidCorrectiveWorkOrder && item.Status === 'New') || $scope.selectedList.length > 0)) {
						var htmlText = translationService.translate('web-listview-contextMenu-deviation-createWorkOrder', 'Opprett arbeidsordre fra dette avviket');
						if ($scope.selectedList.length > 0)
							htmlText = translationService.translate('web-listview-contextMenu-deviation-createWorkOrderFromSelected', 'Opprett arbeidsordre fra valgte avvik') + ' (' + $scope.selectedList.length + ')';

						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-eye fa-hc-fw fa-hc-lg"></i> ' + htmlText + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								if ($scope.selectedList.length > 0)
									$scope.createWorkOrder(false);
								else
									$scope.createWorkOrder(false, item.Guid);
							}
						});
					}

					if ($scope.hasReadAccess('WorkOrder') && $rootScope.userInfo.enableWebNavigation) {
						if (item.GuidInspectionWorkOrder)
							options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidInspectionWorkOrder }), translationService.translate('web-listview-contextMenu-deviation-viewInspectionWorkOrder', 'Gå til arbeidsordre som opprettet avviket')));
						if (item.GuidCorrectiveWorkOrder) {
							if (item.Status === 'Closed')
								options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidCorrectiveWorkOrder }), translationService.translate('web-listview-contextMenu-deviation-viewCorrectiveWorkOrderClosed', 'Gå til arbeidsordre som lukket avviket')));
							else
								options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidCorrectiveWorkOrder }), translationService.translate('web-listview-contextMenu-deviation-viewCorrectiveWorkOrder', 'Gå til arbeidsordre som jobber med avviket')));
						}
					}
					if ($scope.selectedList.length <= 1) {
						if (item.Status !== 'Closed' && $scope.hasEditAccess('Deviation') && !item.GuidCorrectiveWorkOrder) {
							options.push({
								html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-closeDeviationDirectly', 'Lukk avvik direkte') + '</a >',
								click: function () {
									$scope.closeDeviationDirectly(item.Guid);
								}
							});
						}
					}

				};

				this.OperationalMessage = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-operationalMessage-view', 'Åpne driftsmelding') + '</a >',
						click: function ($itemScope, $event) {
							$scope.goToEdit(item, $event);
						}
					});

					if (item.GuidWorkOrder && $scope.hasEditAccess('OperationalMessage')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-operationalMessage-removeWorkOrder', 'Fjern fra arbeidsordren') + '</a >',
							click: function () {
								repository.patch(repository.apiData.operationalMessage.url, item.Guid, { GuidWorkOrder: null }).then(function (response) {
									repository.growl(translationService.translate('web-operationalMessage-removeWorkOrder-success', 'Driftsmeldingen ble fjernet fra arbeidsordren'), 'success');
									$scope.items.reload();
								}, function (error) {
									repository.showError(error);
								});
							}
						});
					}

					if (item.GuidWorkOrder)
						options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-listview-contextMenu-operationalMessage-viewWorkOrder', 'Gå til arbeidsordre')));
					else if ($scope.hasCreateAccess('WorkOrder')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-operationalMessage-createWorkOrder', 'Opprett arbeidsordre') + '</a >',
							click: function () {
								operationalMessageService.createWorkOrder(item);
							}
						});
					}

					if ($scope.hasDeleteAccess('OperationalMessage')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="fas invisible  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-operationalMessage-delete', 'Slett') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								swal({
									title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
									text: translationService.translate('web-swal-operationalMessage-message', 'Driftsmeldingen vil bli fjernet!'),
									type: "warning",
									showCancelButton: true,
									confirmButtonColor: "#f44336",
									confirmButtonText: translationService.translate('web-swal-operationalMessage-button-confirm', 'Ja, fjern driftsmeldingen!'),
									cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
									closeOnConfirm: false,
									showLoaderOnConfirm: true
								}, function () {
									repository.deleteSingle(repository.apiData.operationalMessage.url, item.Guid).then(function (result) {
										swal(translationService.translate('web-swal-operationalMessage-success', 'Driftsmeldingen ble fjernet!'), result, "success");
										$scope.items.reload();
									}, function (error) {
										swal('Error', error, "error");
									});
								});
							}
						});
					}
				};

				this.Project = function (item, options) {
					me.useDefaultParentObjectNavigation = !item.IsTemplate;

					if (item.IsTemplate)
						options.push(getMenuLink($rootScope.navigation.href('projectTemplate.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-projectTemplate-view', 'Åpne prosjektmal')));
					else
						options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-project-view', 'Åpne prosjekt')));
				};

				this.PeriodicTask = function (item, options) {
					me.useDefaultParentObjectNavigation = !item.IsTemplate;

					if (item.TemplateType === "PeriodicTemplate")
						options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-periodicTemplate-view', 'Åpne malrutine')));
					else if (item.TemplateType === "WorkOrderTemplate")
						options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-workOrderTemplate-view', 'Åpne aktivitetsmal')));
					else
						options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-periodicTask-view', 'Åpne periodisk')));

					if (($scope.userInfo.canRunSystemFunction || $scope.userInfo.canRunNewPeriod) && $scope.hasEditAccess('WorkOrder')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-plus fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-periodicTask-createWorkOrderFromPeriodicTask', 'Opprett arbeidsordre fra denne rutinen') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.createWorkOrderFromPeriodicTask(item.Guid);
							},
							enabled: $scope.routeEntity !== 'periodicTaskTemplate' && $scope.routeEntity !== 'workOrderTemplate'
						});
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-plus fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-periodicTask-createWorkOrderFromSelectedPeriodicTasks', 'Opprett arbeidsordre fra valgte rutiner' + ' (' + $scope.selectedList.length + '/20)') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.createWorkOrderFromPeriodicTask();
							},
							enabled: $scope.routeEntity !== 'periodicTaskTemplate' && $scope.routeEntity !== 'workOrderTemplate' && $scope.selectedList.length > 0 && $scope.selectedList.length <= 20
						});
					}
				};

				this.PurchaseOrder = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-purchaseOrder-view', 'Åpne bestilling')));
				};

				this.PurchaseOrderItem = function (item, options) {
					if ($scope.hasEditAccess('PurchaseOrderItem')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-purchaseOrderItem-view', 'Åpne konteringsobjekt') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$modal.open({
									templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
									controller: 'PurchaseOrderItemModalController',
									size: 'xl',
									resolve: {
										params: function () {
											return {
												Guid: item.Guid
											};
										}
									}
								}).result.then(function (result) {
									$scope.items.reload();
								}, function () {
								});
							}
						});
					}

					if ($scope.hasCreateAccess('Cost') &&
						item.Status !== 'Proposal' &&
						item.Status !== 'Closed') {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-receipt fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-purchaseOrderItem-createCost', 'Legg til kostnad') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								var persistedData = {};
								persistedData.GuidPurchaseOrderItem = item.Guid;
								repository.persistedData.setPersistedData('cost.create', persistedData);
								$scope.$parent.navigation.go('cost.create', { showPersistantData: true }, { reload: true });
							}
						});
					}

					if ($scope.hasReadAccess('PurchaseOrder') && item.GuidPurchaseOrder && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: item.GuidPurchaseOrder }), translationService.translate('web-listview-contextMenu-purchaseOrderItem-viewPurchaseOrder', 'Gå til bestilling')));
					}

					if ($scope.hasReadAccess('WorkOrder') && item.GuidWorkOrder && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-listview-contextMenu-purchaseOrderItem-viewWorkOrder', 'Gå til arbeidsordre')));
					}
				};

				this.Request = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('request.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-request-view', 'Åpne melding')));
				};

				this.SparePart = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('sparePart.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-sparePart-view', 'Åpne reservedel')));

					if ($scope.hasReadAccess('Component') && item.GuidComponent && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('component.edit', { guid: item.GuidComponent }), translationService.translate('web-listview-contextMenu-sparePart-viewComponent', 'Gå til komponent')));
					}
				};

				this.SparePartCounting = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('sparePartCounting.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-sparePartCounting-view', 'Åpne varetelling')));
				};

				this.SparePartWithdrawal = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('sparePartWithdrawal.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-sparePartWithdrawal-view', 'Åpne uttakshistorikk')));

					if ($scope.hasReadAccess('SparePart') && item.GuidSparePart && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('sparePart.edit', { guid: item.GuidSparePart }), translationService.translate('web-listview-contextMenu-sparePartWithdrawal-viewSparePart', 'Gå til reservedel')));
					}
				};

				this.WorkOrder = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-workOrder-view', 'Åpne arbeidsordre')));
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-calendar fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-workOrder-generateCalendarEvent', 'Last ned arbeidsordre for kalender') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.generateCalendarEvent(item.Guid);
						}
					});

					if ($scope.items.filter().ActivityStatus === 'Active' && $scope.showProjectLinkButton && $scope.hasModule('ProjectManagement') && $scope.hasReadAccess('Project')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-link-simple fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-workOrder-connectWorkOrdersToProject', 'Koble til prosjekt') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.connectWorkOrdersToProject(item.Guid);
							}
						});
					}

					if ($scope.generalOptions.UseReinvoicing && $scope.hasModule('Invoice') && $scope.userInfo.canRunSystemFunction && $scope.hasEditAccess('WorkOrder')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-check fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-workOrder-closeWorkOrderReinvoicing', 'Sett som sluttfakturert') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.closeWorkOrderReinvoicing(item.Guid);
							}
						});
					}

					if ($rootScope.hasCreateAccess('WorkOrder')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas fa-check fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-workOrder-copy', 'Kopier arbeidsordre') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.copy(item.Guid);
							}
						});
					}
				};
				//#endregion

				//#region Cleaning

				this.AreaXCleaningTask = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-flower fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-areaXCleaningTask-view', 'Åpne renholdsoppgave') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.goToEdit(item);
						}
					});

					if ($scope.hasReadAccess("CleaningTask") && item.GuidCleaningTask && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('cleaningTask.edit', { guid: item.GuidCleaningTask }), translationService.translate('web-listview-contextMenu-areaXCleaningTask-viewCleaningTask', 'Gå til renholdsoppgave definisjon')));
					}
				};

				this.CleaningCompletion = function (item, options) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-folder-person fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-cleaningCompletion-view', 'Åpne renholdsaktivitet') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.goToEdit(item);
						}
					});

					if ($scope.hasReadAccess("CleaningTask") && item.GuidCleaningTask && $rootScope.userInfo.enableWebNavigation) {
						options.push(getMenuLink($rootScope.navigation.href('cleaningTask.edit', { guid: item.GuidCleaningTask }), translationService.translate('web-listview-contextMenu-areaXCleaningTask-viewCleaningTask', 'Gå til renholdsoppgave definisjon')));
					}
				};

				this.CleaningQualityControl = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('cleaningQualityControl.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-cleaningQualityControl-view', 'Åpne INSTA kontroll')));
				};

				this.CleaningTask = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('cleaningTask.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-cleaningTask-view', 'Åpne renholdsoppgave definisjon')));
				};

				this.BimFile = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('bimFile.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-bimFile-view', 'Åpne bim fil administrasjon')));
					var selectedItems = getSelectedItems();
					if (selectedItems.length) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-folder-person fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-bimFile-viewModels', 'Se modeller') + ' (' + selectedItems.length + ')' + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								var validItems = _.filter(selectedItems, function (item) { return item.GuidBimProject && item.ExternalId && item.ModelVersion; });
								if (!validItems.length) {
									swal(
										translationService.translate('web-listview-contextmenu-bimFile-viewModels-missingValidModels-title', 'Ingen gyldige modeller.'),
										translationService.translate('web-listview-contextmenu-bimFile-viewModels-missingValidModels-message', 'Velg minst 1 modell som er ferdig prossessert.'),
										'error');
									return;
								}

								if (!_.every(selectedItems, { GuidBimProject: validItems[0].GuidBimProject })) {
									swal(
										translationService.translate('web-listview-contextmenu-bimFile-viewModels-notSameBimProject-title', 'Modeller er på forskjellige prosjekter/eiendommer.'),
										translationService.translate('web-listview-contextmenu-bimFile-viewModels-notSameBimProject-message', 'Alle valgte modeller må være under samme prosjekt/eiendom.'),
										'error');
									return;
								}

								var selectedGuids = selectedItems.map(function (item) { return item.Guid; }).join(",");

								$rootScope.navigation.go('bimFile.viewBim', { guidBimProject: validItems[0].GuidBimProject, guidBimFile: selectedGuids });
							}
						});
					} else if (item.GuidBimProject && item.ExternalId && item.ModelVersion) {
						options.push(getMenuLink($rootScope.navigation.href('bimFile.viewBim', { guidBimProject: item.GuidBimProject, guidBimFile: item.Guid }), translationService.translate('web-listview-contextMenu-bimFile-viewBimsync', 'Se modell')));
					}
				};

				//#endregion

				this.DataImport = function (item, options) {
					if ($scope.hasCreateAccess('DataImport')) {
						options.push(getMenuLink($rootScope.navigation.href('dataImport.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-dataImport-view', 'Åpne dataimport')));

						if (item.InputConfig && item.InputConfig.Type) {
							options.push({
								html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-dataImport-copy', 'Opprett ny fra eksisterende') + '</a >',
								click: function ($itemScope, $event, modelValue, text, $li) {
									$scope.navigation.go(item.InputConfig.Type === 'Bim' ? 'dataImport.bim' : 'dataImport.edit', { guidEntityToCopy: item.Guid });
								}
							});
						}

					}
					if ($scope.hasDeleteAccess('DataImport')) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"> <i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-dataImport-delete', 'Slett dataimport') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								swal({
									title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
									text: translationService.translate('web-listview-contextMenu-dataImport-deleteMessage', "Dataimport vil bli fjernet"),
									type: "warning",
									showCancelButton: true,
									confirmButtonColor: "#f44336",
									confirmButtonText: translationService.translate('web-swal-button-confirm', 'Ja'),
									cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
									closeOnConfirm: false
								}, function () {
									window.onkeydown = null;
									window.onfocus = null;
									repository.deleteSingle(repository.apiData.dataImport.url, item.Guid).then(
										function () {
											repository.growl("Dataimport slettet", "success");
											$scope.items.reload();
											swal.close();
										}
									);
								});
							}
						});
					}
				};
				this.WebDashboard = function (item, options) {
					if ($scope.hasCreateAccess("WebDashboard")) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-webDashboard-copy', 'Lag kopi') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.copy(item.Guid);
							},
							enabled: !$scope.loadingStates.isCreatingWebDashboardCopy
						});
					}
				};

				this.ChangeSet = function (item, options) {
					me.useDefaultParentObjectNavigation = true;
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-changeSet-view', 'Åpne endringsett') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.goToEdit(item);
						}
					});

					var prefix = item.EntityType;
					var entityRoute = prefix.charAt(0).toLowerCase() + prefix.slice(1);
					var entityGuid = item.GuidEntity;
					var routeExists = repository.apiData[entityRoute];
					if (routeExists) {
						var title = translationService.translate('web-listview-contextMenu-changeSet-openEntity', 'Gå til') + ' ' + translationService.translate('web-' + prefix).toLowerCase();
						options.push(getMenuLink($rootScope.navigation.href(routeExists.navigationState, { guid: entityGuid }), title));
					}
				};

				this.ScheduledJobExecution = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('scheduledJobExecution.edit', { guid: item.Guid }), translationService.translate('web-listview-contextMenu-scheduledJobExecution-view', 'Åpne utført bakgrunnsjobb')));

					if ($scope.hasReadAccess("ScheduledJob") && item.GuidScheduledJob) {
						options.push(getMenuLink($rootScope.navigation.href('scheduledJob.edit', { guid: item.GuidScheduledJob }), translationService.translate('web-listview-contextMenu-scheduledJob-view', 'Åpne bakgrunnsjobb')));
					}
				};

				this.ImageXEntity = function (item, options) {
					me.useDefaultParentObjectNavigation = false;
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-imageXEntity-view', 'Åpne bildereferanse') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							openImageXEntityModal(item);
						}
					});

					var prefix = item.LinkedEntityType;
					var entityRoute = prefix.charAt(0).toLowerCase() + prefix.slice(1);
					var entityGuid = item['Guid' + prefix];
					var routeExists = repository.apiData[entityRoute];
					if (routeExists && routeExists.allowEntityNavigation) {
						var title = translationService.translate('web-listview-contextMenu-imageXEntity-openEntity', 'Gå til') + ' ' + translationService.translate('web-' + prefix).toLowerCase();
						options.push(getMenuLink($rootScope.navigation.href(routeExists.navigationState, { guid: entityGuid }), title));
					}
				};

				this.UserXExternalLogin = function (item, options) {
					options.push(getMenuLink($rootScope.navigation.href('user.edit', { guid: item.GuidUser }), translationService.translate('web-listview-contextMenu-user-view', 'Gå til bruker')));
				};

			}();

			$scope.autoCompleteFilter = function (filterName) {
				switch (filterName) {
					case "resourceGroup":
						return {
							PropertyFilter: [{ Property: 'DoesCleaningTasks', Operator: '=', Value: true }]
						};
				}
			};
		}
	]);
})();
