angular.module('Plania').factory('ActivityThemeService', ['$modal', 'Repository', '$q', 'TranslationService', 'DwgMarkerService', 'DwgThemeService', function ($modal, repository, $q, translationService, markerService, dwgThemeService) {
	var service = {};
	var hasActiveWorkOrder = '#428bca';
	var isOverDeadline = '#d9534f';

	var updateIndicatorColours = function (workorders) {
		workorders.forEach(function (workOrder) {
			var elements = $('#' + workOrder.GuidArea).children();

			if (elements[0]) {
				var path = elements[0];

				$(path).attr('stroke', hasActiveWorkOrder);
				$(path).attr('fill', hasActiveWorkOrder);
				$(path).attr('stroke-width', '100');
				$(path).attr('stroke-opacity', '1');
				$(path).attr('fill-opacity', '0.3');
				$(path).attr('stroke-dasharray', '');
				$(path).attr('stroke-linecap', '');

				var overDeadlineDate = new Date(workOrder.DueDate);

				if (new Date() > overDeadlineDate) {
					$(path).attr('stroke', isOverDeadline);
					$(path).attr('fill', isOverDeadline);
					$(path).attr('fill-opacity', '0.3');
				}
			}
		});
	};

	service.hideMarkers = function () {
		$('#marker-coordinates').attr('visibility', 'hidden');
	};

	service.showMarkers = function () {
		$('#marker-coordinates').attr('visibility', 'visible');
	};

	var updateMarkers = function (areaGuids) {
		areaGuids.forEach(function (guid) {
			var elements = $('#' + guid).children();

			if (elements[0]) {
				var path = elements[0];
				markerService.addRequestMarker(path, guid);
			}

		});
	};

	service.getLegends = function () {
		return [
			{ label: translationService.translate('web-dwg-legend-activity-overdeadline', 'AO over tidsfrist'), legendClass: 'legend-danger' },
			{ label: translationService.translate('web-dwg-legend-activity-activeWorkorder', 'Aktive arbeidsordre'), legendClass: 'legend-primary' },
			{ label: translationService.translate('web-dwg-legend-activity-activeRequests', 'Aktive meldinger'), imageSrc: 'images/svg-marker.png' }
		];
	};

	service.onClick = function (guid, type) {
		var deferred = $q.defer();

		if (type === 'marker') {
			$modal.open({
				templateUrl: 'app/request/views/requestModal.html',
				controller: 'RequestModalController',
				animation: false,
				resolve: {
					params: function () { return { guidArea: guid }; }
				}
			}).result.then(function (result) {
				deferred.resolve({});
			});
		} else {
			$modal.open({
				templateUrl: 'app/dwg/views/workOrderModal.html',
				size: 'lg',
				controller: 'DwgWorkOrderController',
				animation: false,
				resolve: {
					params: function () { return { guid: guid }; }
				}
			}).result.then(function (result) {
				//do nothing in standard theme
				deferred.resolve({});
			}, function (error) {
				if (error === 'cancel') deferred.resolve({});
				else deferred.reject();
			});
		}

		return deferred.promise;
	};

	service.getStatus = function (areas, guidDrawing) {
		dwgThemeService.removeCategoryLabels(areas);

		var filter = {
			ActivityStatus: 'Active',
			PropertyFilter: [{
				Property: 'Area.GuidDrawing',
				Operator: '=',
				Value: guidDrawing
			}]
		};

		var requestFilter = {
			FilterModel: {
				Condition: 'and',
				Rules: [
					{
						Condition: 'or',
						Rules: [
							{ Property: 'Status', Operator: '=', Value: '0' },
							{ Property: 'Status', Operator: '=', Value: '1' }
						]
					},
					{ Property: 'WorkOrder.EndDate', Operator: '=', Value: '' },
					{ Property: 'Area.GuidDrawing', Operator: '=', Value: guidDrawing }
				]
			}
		};

		repository.GetPaginated(repository.apiData.request.url, 0, 1000, {}, requestFilter, '', JSON.stringify(['Guid'])).then(function (result) {
			dwgThemeService.removePeriodicMarkers(areas);
			dwgThemeService.removeRequestMarkers(areas);

			var areaGuids = [];
			result.List.forEach(function (request) {
				if (!_.contains(areaGuids, request.GuidArea))
					areaGuids.push(request.GuidArea);
			});
			updateMarkers(areaGuids);
		}, function (error) {
			repository.growl(error, 'danger');
		});

		return repository.GetPaginated(repository.apiData.workOrder.url, 0, 1000, { 'DueDate': 'asc' }, filter, "", JSON.stringify(['DueDate'])).then(function (result) {
			dwgThemeService.removeAreaIndicators(areas);
			updateIndicatorColours(result.List);
			return result;
		});
	};

	return service;
}]);
