(function () {

	angular.module('Plania').factory('ValidationService', [function () {
		var service = {};

		service.validateModel = function (model) {
			if (!model.DynamicProperty) return;
			if (!model || typeof (model) !== 'object' || !Array.isArray(model.DynamicProperty.Groups)) return;

			model.DynamicProperty.Groups.forEach(function (group) {
				group.Items.forEach(function (property) {
					if (property.Type === 'Nummeric' && model[property.PropertyName] && typeof (model[property.PropertyName]) === 'string') {
						var input = model[property.PropertyName];
						var newNumber = (input) ? input.trim().replace(",", ".") : null;
						model[property.PropertyName] = parseFloat(newNumber);
					}
					if (property.Type === 'Date' && model[property.PropertyName]) {
						model[property.PropertyName] = new Date(model[property.PropertyName]).toISOString();
					}
				});
			});
		};

		return service;
	}]);
})();
