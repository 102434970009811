(function () {
	angular.module('Plania').controller('EntityCounterModalController', ['$scope', 'Repository', 'params', '$modalInstance', '$modal', '$localStorage', 'TranslationService', controller]);
	function controller($scope, repository, params, $modalInstance, $modal, $localStorage, translationService) {
		$scope.isUpdate = !!params.guid;
		$scope.activateAutocomplete = false;

		// Extract parameters
		$scope.guid = params.guid;


		$scope.availableList = [];
		$scope.availableEntityTypes = [];
		$scope.availableFieldNames = [];



		var updateAccess = function () {
			if ($scope.isUpdate) {
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.EntityCounter);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.EntityCounter);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.EntityCounter);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.EntityCounter);
			}
		};
		updateAccess();

		$scope.onLinkedDataOwnerSelect = function (dataOwner) {
			$scope.model.EntityType = "";
			if (!dataOwner) {
				$scope.model.GuidLinkedDataOwner = "";
				getAvailableEntityTypes();
				return;
			}

			$scope.model.GuidLinkedDataOwner = dataOwner.Guid;
			getAvailableEntityTypes();
		};

		var columns = [
			'LinkedDataOwner.Id', 'LinkedDataOwner.Description',
			'UserUpdatedBy.RealName', 'UserUpdatedBy.RealName'
		];

		var getModel = function () {
			repository.getSingle(repository.apiData.entityCounter.url, params.guid, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;

				var systemKey = 'web-' + result.Data.EntityType + "-" + result.Data.FieldName;
				$scope.model.TranslatedFieldName = translationService.translate(systemKey);
				$scope.model.TranslatedPreFix = translationService.getEnumTranslation('PreFix', result.Data.EntityType);

				updateAccess();
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		getAvailableEntityTypes = function () {
			repository.getSingle(repository.apiData.entityCounter.url + "availableEntityTypes/", $scope.model.GuidLinkedDataOwner).then(function (result) {
				var options = [];

				result.forEach(function (r) {
					var systemKey = 'web-' + r.PreFix + "-" + r.FieldName;
					options.push({ PreFix: r.PreFix, FieldName: r.FieldName, TranslatedPreFix: translationService.getEnumTranslation('PreFix', r.PreFix), TranslatedFieldName: translationService.translate(systemKey), Max: r.Max });
				});
				$scope.availableList = options;

				var newAvailableEntityTypes = [];

				options.forEach(function (r) {
					if (!newAvailableEntityTypes.map(function (x) {
						return x.PreFix;
					}).includes(r.PreFix)) {
						newAvailableEntityTypes.push({ PreFix: r.PreFix, TranslatedPreFix: translationService.getEnumTranslation('PreFix', r.PreFix) });
					}
				});
				$scope.availableEntityTypes = newAvailableEntityTypes.sort(function (a, b) {
					var nameA = a.TranslatedPreFix.toUpperCase();
					var nameB = b.TranslatedPreFix.toUpperCase();

					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				});
				$scope.availableFieldNames = options.filter(function (y) { return y.PreFix === $scope.model.EntityType; }).map(function (x) { return { FieldName: x.FieldName, TranslatedFieldName: x.TranslatedFieldName }; });
				$scope.model.FieldName = "";

			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.$watch("model.EntityType", function (newVal, oldVal) {
			if (!params.guid) getAvailableFieldNames();
		});

		$scope.prefillValue = function () {
			var item = $scope.availableList.find(function (x) { return x.PreFix === $scope.model.EntityType && x.FieldName === $scope.model.FieldName; });
			if (item) {
				$scope.model.Value = item.Max;
			}
		};

		getAvailableFieldNames = function () {
			$scope.model.FieldName = "";
			var newAvailableFieldNames = [];
			newAvailableFieldNames = $scope.availableList.filter(function (y) { return y.PreFix === $scope.model.EntityType; }).map(function (x) { return { PreFix: x.PreFix, FieldName: x.FieldName, TranslatedFieldName: x.TranslatedFieldName }; });
			if (newAvailableFieldNames.length === 1) {
				$scope.model.FieldName = newAvailableFieldNames[0].FieldName;
				$scope.prefillValue();
			}
			$scope.availableFieldNames = newAvailableFieldNames.sort(function (a, b) {
				var nameA = a.TranslatedFieldName.toUpperCase();
				var nameB = b.TranslatedFieldName.toUpperCase();

				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
		};

		if (params.guid) {
			getModel();
		} else {
			$scope.model = {};
			getAvailableEntityTypes();
			updateAccess();
			$scope.activateAutocomplete = true;
		}

		$scope.delete = function () {
			if (params.guid) {

				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-entitycounter-message', 'Autonummereringen vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-entitycounter-delete-button-confirm', 'Ja, fjern autonummereringen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.entityCounter.url, params.guid).then(function (result) {
						swal(translationService.translate('web-entityCounter-deleted', 'Autonummerering slettet'), result, 'success');
						$modalInstance.close('ok');
					}).catch(function (error) {
						repository.growl(error, 'danger');
					});
				});
			}
		};

		$scope.save = function (action) {
			var saveDto = angular.copy($scope.model);

			delete saveDto.LinkedDataOwner;

			if (params.guid) {
				repository.updateSingle(repository.apiData.entityCounter.url, saveDto).then(function (result) {
					repository.growl(translationService.translate('web-entityCounter-updated', 'Autonummerering oppdatert'), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingle(repository.apiData.entityCounter.url, saveDto).then(function (result) {
					repository.growl(translationService.translate('web-entityCounter-created', "Autonummereringen ble lagt til"), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
