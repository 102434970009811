
(function () {
	var app = angular.module('Plania');

	app.config(['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider', routeConfigurator]);

	function routeConfigurator($stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) {
		$urlMatcherFactoryProvider.caseInsensitive(true);
		$urlMatcherFactoryProvider.strictMode(false);
		getStates().forEach(function (s) {
			if (s.reloadOnSearch === undefined)
				s.reloadOnSearch = false;

			$stateProvider.state(s);
		});

		$urlRouterProvider.otherwise('/login/');
	}

	app.value('states', { list: getStates() });

	app.value('apiData', getApiData());

	function getApiData() {
		/**
		 *	description - Name shown together together with availableForListView
		 *  availableForListView
		 *		- If true = webMenu will use this to show list setups
		 *		- If true = widgets will use this to select available apiData's that can be used in the widget. Ex what element is the basis of a kpi widget.
		 *	allowEntityNavigation - Control if the route is navigable from plEntityNavigation.
		 * */

		var apiData = {
			account: { url: 'account/', prefix: 'Account', description: 'Kontoer', availableForListView: true, navigationState: 'account.edit', allowEntityNavigation: false, },
			accountAccounting: { url: 'AccountXAccountingDimension/', prefix: 'AccountXAccountingDimension', description: '', availableForListView: false, allowEntityNavigation: false, },
			accountingXAccounting: { url: 'AccountingDimensionXAccountingDimension/', prefix: 'AccountingDimensionXAccountingDimension', description: '', availableForListView: false, allowEntityNavigation: false, },
			accounting: { url: 'accountingDimension/', prefix: 'AccountingDimension', description: 'Dimensjoner', availableForListView: true, navigationState: 'accounting.edit', allowEntityNavigation: false, },
			activityCategory: { url: 'activityCategory/', prefix: 'ActivityCategory', description: 'Aktivitetskategori', availableForListView: true, navigationState: 'activityCategory.edit', allowEntityNavigation: false, },
			activityGroup: { url: 'activityGroup/', prefix: 'ActivityGroup', description: 'Aktivitetsgruppe', availableForListView: true, navigationState: 'activityGroup.edit', allowEntityNavigation: false, },
			address: { url: 'address/', description: 'Addresse', allowEntityNavigation: false, },
			alarm: { url: 'alarm/', prefix: 'Alarm', description: '', availableForListView: false, navigationState: 'alarm.edit', allowEntityNavigation: false, },
			alarmLog: { url: 'AlarmLog/', prefix: 'AlarmLog', description: 'Alarm logg', availableForListView: false, navigationState: 'alarmLog.edit', allowEntityNavigation: false, },
			anonymizationConfiguration: { url: 'AnonymizationConfiguration/', description: 'Konfigurering av anonymisering', allowEntityNavigation: false, },
			apiClient: { url: 'apiClient/', prefix: 'ApiClient', description: 'API klienter', availableForListView: false, navigationState: 'apiClient.edit', allowEntityNavigation: false, },
			apiRequestLog: { url: 'apiRequestLog/', prefix: 'ApiRequestLog', description: 'API log', availableForListView: true, navigationState: 'apiRequestLog.edit', allowEntityNavigation: false, },
			area: { url: 'area/', prefix: 'Area', description: 'Rom', availableForListView: true, navigationState: 'area.edit', allowEntityNavigation: true, },
			areaCategory: { url: 'areaCategory/', prefix: 'AreaCategory', description: 'Arealkategori', availableForListView: false, navigationState: 'areaCategory.edit', allowEntityNavigation: false, },
			areaXEntity: { url: 'areaXEntity/', prefix: 'AreaXEntity', description: 'Areal/Objekt', availableForListView: false, allowEntityNavigation: false, },
			areaXCleaningTask: { url: 'areaXCleaningTask/', prefix: 'AreaXCleaningTask', description: 'Renhold', availableForListView: false, allowEntityNavigation: false, },
			article: { url: 'article/', prefix: 'Article', description: 'Artikkel', availableForListView: true, navigationState: 'article.edit', allowEntityNavigation: true, },
			barcode: { url: 'BarCode/', prefix: 'Barcode', description: 'Strekkode', availableForListView: false, allowEntityNavigation: false, },
			bcfProject: { url: 'BcfProject/', prefix: "BcfProject", description: 'Bcf prosjekt', availableForListView: true, navigationState: 'bcfProject.edit', allowEntityNavigation: true, },
			budget: { url: 'budget/', prefix: 'Budget', description: 'Budsjett', availableForListView: true, navigationState: 'budget.edit', allowEntityNavigation: true, },
			building: { url: 'building/', prefix: 'Building', description: 'Bygning', availableForListView: true, navigationState: 'building.edit', allowEntityNavigation: true, },
			buildingTemplate: { url: 'building/', prefix: 'Building', description: 'Byggmal', availableForListView: true, navigationState: 'buildingTemplate.edit', contextId: planiaUtils.contextIds.buildingTemplate, allowEntityNavigation: false, },
			buildingContract: { url: 'buildingXContract/', prefix: 'BuildingXContract', availableForListView: false, allowEntityNavigation: false, },
			buildingPerson: { url: 'buildingXPerson/', prefix: 'BuildingXPerson', description: 'Driftsansvarlig', availableForListView: true, navigationState: "buildingPerson.edit", allowEntityNavigation: false, },
			buildingSelection: { url: 'BuildingSelection/', prefix: 'BuildingSelection', description: 'Utvalg', availableForListView: true, navigationState: "buildingSelection.edit", allowEntityNavigation: false, },
			buildingBuildingSelection: { url: 'BuildingXBuildingSelection/', prefix: 'BuildingXBuildingSelection', availableForListView: false, allowEntityNavigation: false, },
			buildingSupplier: { url: 'BuildingXSupplier/', prefix: 'BuildingXSupplier', availableForListView: false, allowEntityNavigation: false, },
			buildingCategory: { url: 'BuildingCategory/', prefix: 'BuildingCategory', description: 'Byggkategori', availableForListView: false, navigationState: 'buildingCategory.edit', allowEntityNavigation: false, },
			cacheInfo: { url: 'CacheInfo/', navigationState: "cacheInfo.edit", description: 'Mellomlagrede objekter', allowEntityNavigation: false, },
			cause: { url: 'Cause/', prefix: 'Cause', availableForListView: true, navigationState: "cause.edit", description: 'Årsak', allowEntityNavigation: false, },
			changeSet: { url: 'ChangeSet/', prefix: 'ChangeSet', availableForListView: true, description: 'Endringsett', navigationState: 'changeSet.edit', allowEntityNavigation: false, },
			controlList: { url: 'controlList/', prefix: 'ControlList', description: 'Kontrolliste', availableForListView: true, navigationState: 'controlList.edit', allowEntityNavigation: false, },
			controlListItem: { url: 'controlListItem/', prefix: 'ControlListItem', description: 'Kontrollistepunkt', availableForListView: false, allowEntityNavigation: false, },
			controlListLogItem: { url: 'controlListLogItem/', prefix: 'ControlListLogItem', description: 'Loggepunkt', availableForListView: false, allowEntityNavigation: false, },
			controlListRule: { url: 'controlListRule/', prefix: 'ControlListRule', description: 'Kontrollisteregel', availableForListView: false, allowEntityNavigation: false, },
			controlListItemAnswer: { url: 'controlListItemAnswer/', urlUpdateMultiple: 'controlListItemAnswer/updateMultiple', prefix: 'ControlListItemAnswer', description: 'Kontrollistesvar', availableForListView: true, navigationState: 'controlListItemAnswer.edit', allowEntityNavigation: false, },
			controlListXEntity: { url: 'controlListXEntity/', prefix: 'ControlListXEntity', description: 'Kontrollister', availableForListView: true, allowEntityNavigation: false, },
			checklistCompletion: { url: 'checklistCompletion/', prefix: 'ChecklistXStandardTextAnswer', description: '', availableForListView: false, allowEntityNavigation: false, },
			cleaning: { url: 'Cleaning/', prefix: 'Cleaning', description: 'Renhold', availableForListView: false, allowEntityNavigation: true, },
			cleaningCompletion: { url: 'cleaningCompletion/', prefix: 'CleaningCompletion', description: 'Aktivitetsoversikt', availableForListView: true, allowEntityNavigation: true, },
			cleaningQuality: { url: 'CleaningQuality/', prefix: 'CleaningQuality', description: 'Renholdskvalitet', availableForListView: false, navigationState: 'cleaningQuality.edit', allowEntityNavigation: true, },
			cleaningQualityControl: { url: 'CleaningQualityControl/', prefix: 'CleaningQualityControl', description: 'INSTA kontroll', availableForListView: false, navigationState: 'cleaningQualityControl.edit', allowEntityNavigation: true, },
			cleaningQualityControlArea: { url: 'CleaningQualityControlXArea/', prefix: 'CleaningQualityControlXArea', description: 'Rom knyttet til INSTA kontroll', availableForListView: false, allowEntityNavigation: true, },
			cleaningStatus: { url: 'CleaningStatus/', prefix: '', description: 'Renholdsstatus', availableForListView: false, allowEntityNavigation: true, },
			cleaningCompletionHistory: { url: 'cleaningCompletionHistory/', prefix: 'CleaningCompletionHistory', description: 'Renholdshistorie', availableForListView: false, allowEntityNavigation: true, },
			cleaningTask: { url: 'CleaningTask/', prefix: 'CleaningTask', description: 'Renholdsmetode', availableForListView: false, navigationState: 'cleaningTask.edit', allowEntityNavigation: true, },
			component: { url: 'component/', prefix: 'Component', description: 'Komponent', availableForListView: true, navigationState: 'component.edit', allowEntityNavigation: true, },
			componentArea: { url: 'componentXArea/', prefix: 'ComponentXArea', description: '', availableForListView: false, allowEntityNavigation: false, },
			componentCategory: { url: 'componentCategory/', prefix: 'ComponentCategory', description: 'Komponentkategori', availableForListView: false, navigationState: 'componentCategory.edit', allowEntityNavigation: false, },
			componentEquipment: { url: 'componentXEquipment/', prefix: 'ComponentXEquipment', description: '', availableForListView: false, allowEntityNavigation: false, },
			componentSupplier: { url: 'componentXSupplier/', prefix: 'ComponentXSupplier', description: '', availableForListView: false, allowEntityNavigation: false, },
			correctiveAction: { url: 'condition/', prefix: 'Condition', description: 'Tiltak', availableForListView: true, navigationState: 'condition.edit', allowEntityNavigation: true, },
			condition: { url: 'condition/', prefix: 'Condition', description: 'Tilstand', availableForListView: true, navigationState: 'condition.edit', allowEntityNavigation: true, },
			conditionType: { url: 'conditionType/', prefix: 'ConditionType', description: 'Tilstandstype', availableForListView: true, navigationState: 'conditionType.edit', allowEntityNavigation: false, },
			consequence: { url: 'consequence/', prefix: 'Consequence', description: 'Konsekvensgrad', availableForListView: true, navigationState: 'consequence.edit', allowEntityNavigation: false, },
			consequenceType: { url: 'consequenceType/', prefix: 'ConsequenceType', description: 'Konsekvenstype', availableForListView: true, navigationState: 'consequenceType.edit', allowEntityNavigation: false, },
			constructionType: { url: 'constructionType/', prefix: 'ConstructionType', description: 'Konstruksjonstype', availableForListView: false, navigationState: 'constructionType.edit', allowEntityNavigation: false, },
			contactPerson: { url: 'contactPerson/', prefix: 'ContactPerson', description: 'Kontaktperson', availableForListView: false, navigationState: 'contactPerson.edit', allowEntityNavigation: false, },
			contractAdjustment: { url: 'contractAdjustment/', prefix: 'ContractAdjustment', allowEntityNavigation: false, },
			contract: { url: 'contract/', prefix: 'Contract', description: 'Kontrakt', availableForListView: true, navigationState: 'contract.edit', allowEntityNavigation: true, },
			contractType: { url: 'contractType/', prefix: 'ContractType', description: 'Kontrakt type', availableForListView: true, navigationState: 'contractType.edit', allowEntityNavigation: false, },
			contractCategory: { url: 'contractCategory/', prefix: 'ContractCategory', description: 'Kontrakt kategori', availableForListView: true, navigationState: 'contractCategory.edit', allowEntityNavigation: false, },
			contractItem: { url: 'contractItem/', prefix: 'ContractItem', description: 'Kontraktsobjekt', availableForListView: true, navigationState: 'contractType.edit', allowEntityNavigation: false, },
			cost: { url: 'cost/', prefix: 'Cost', description: 'Kost', availableForListView: true, navigationState: 'cost.edit', allowEntityNavigation: true, },
			costCenter: { url: 'costCenter/', prefix: 'CostCenter', description: 'Koststed', availableForListView: false, navigationState: 'costCenter.edit', allowEntityNavigation: false, },
			customer: { url: 'customer/', prefix: 'Customer', description: 'Kunde', availableForListView: true, navigationState: 'customer.edit', allowEntityNavigation: true, },
			customerDeliveryAddress: { url: 'customerDeliveryAddress/', prefix: 'CustomerDeliveryAddress', description: 'Leveringsadresse', availableForListView: false, allowEntityNavigation: false, },
			customerGroup: { url: 'customerGroup/', prefix: 'CustomerGroup', description: 'Kundegruppe', availableForListView: true, navigationState: 'customerGroup.edit', allowEntityNavigation: false, },
			customerCategory: { url: 'customerCategory/', prefix: 'CustomerCategory', description: 'Kundekategori', availableForListView: true, navigationState: 'customerCategory.edit', allowEntityNavigation: false, },
			customerLineOfBusiness: { url: 'customerLineOfBusiness/', prefix: 'CustomerLineOfBusiness', description: 'Bransje (Kunde)', availableForListView: true, navigationState: 'customerLineOfBusiness.edit', allowEntityNavigation: false, },
			customerLog: { url: 'customerLog/', prefix: 'CustomerLog', description: 'Kundelog', availableForListView: false, allowEntityNavigation: false, },
			cylinderType: { url: 'cylinderType/', prefix: 'CylinderType', description: 'Sylindertype', availableForListView: false, navigationState: 'cylinderType.edit', allowEntityNavigation: false, },
			dataImport: { url: 'dataImport/', prefix: 'DataImport', description: 'Dataimport', availableForListView: true, navigationState: "dataImport.edit", allowEntityNavigation: false, },
			dataImportBim: { url: 'dataImport/', prefix: 'DataImport', description: '', availableForListView: true, navigationState: "dataImport.bim", allowEntityNavigation: false, },
			dataOwner: { url: 'dataOwner/', prefix: 'DataOwner', description: 'Klient', availableForListView: false, navigationState: 'dataOwner.edit', allowEntityNavigation: false, },
			deliveryTerm: { url: 'deliveryTerm/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			deviation: { url: 'deviation/', prefix: 'Deviation', description: 'Avvik', availableForListView: true, navigationState: 'deviation.edit', allowEntityNavigation: true, },
			deviationType: { url: 'deviationType/', prefix: 'DeviationType', description: 'Avvikstyper', availableForListView: true, navigationState: 'deviationType.edit', allowEntityNavigation: false, },
			department: { url: 'department/', prefix: 'Department', description: 'Avdeling', availableForListView: true, navigationState: 'department.edit', allowEntityNavigation: false, },
			document: { url: 'document/', urlChunk: 'documentChunk/', prefix: 'Document', description: 'Dokument', availableForListView: true, navigationState: 'document.edit', allowEntityNavigation: true, },
			documentData: { url: 'documentData/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			doorKeySystem: { url: 'doorKeySystem/', prefix: 'DoorKeySystem', description: 'Nøkkelsystem', availableForListView: true, navigationState: 'doorKeySystem.edit', allowEntityNavigation: true, },
			doorKey: { url: 'doorKey/', prefix: 'DoorKey', description: 'Nøkkel', availableForListView: true, navigationState: 'doorKey.edit', allowEntityNavigation: true, },
			doorKeyXUser: { url: 'doorKeyXUser/', prefix: 'DoorKeyXUser', description: 'Utlevering', availableForListView: true, navigationState: 'doorKeyXUser.edit', allowEntityNavigation: true, },
			doorLock: { url: 'doorLock/', prefix: 'DoorLock', description: 'Lås', availableForListView: true, navigationState: 'doorLock.edit', allowEntityNavigation: true, },
			doorLockXArea: { url: 'doorLockXArea/', prefix: 'DoorLockXArea', description: 'Låser på areal', availableForListView: true, allowEntityNavigation: true, },
			doorKeyXDoorLock: { url: 'doorKeyXDoorLock/', prefix: 'DoorKeyXDoorLock', description: 'Nøkkel/sylinder', navigationState: 'doorKeyXDoorLock.list', availableForListView: true, allowEntityNavigation: false, },
			doorKeyTransaction: { url: 'doorKeyTransaction/', prefix: 'DoorKeyTransaction', description: 'Nøkkeltransaksjoner', availableForListView: true, allowEntityNavigation: true, },
			bim: { url: 'bim/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: true, },
			documentCategory: { url: 'documentCategory/', prefix: 'DocumentCategory', description: 'DokumentKategori', availableForListView: true, navigationState: 'documentCategory.edit', allowEntityNavigation: false, },
			documentType: { url: 'documentType/', prefix: 'DocumentType', description: 'Dokumenttype', availableForListView: true, navigationState: 'documentType.edit', allowEntityNavigation: false, },
			documentXEntity: { url: 'documentXEntity/', prefix: 'DocumentXEntity', description: 'Dokument referanser', availableForListView: true, navigationState: 'document.edit', allowEntityNavigation: true, },
			documentDataAcquisitionMatrix: { url: 'documentXEntity/', prefix: 'DocumentXEntity', description: 'Dokument datafangst matrise', availableForListView: true, navigationState: 'document.dataAcquisitionMatrix', contextId: planiaUtils.contextIds.dataAcquisitionDocXEntity, allowEntityNavigation: false, },
			documentDataAcquisitionListView: { url: 'documentXEntity/', prefix: 'DocumentXEntity', description: 'Dokument datafangst listevisning', availableForListView: true, navigationState: 'document.edit', contextId: planiaUtils.contextIds.dataAcquisitionDocXEntity, allowEntityNavigation: false, },
			documentDataAcquisitionUpload: { url: 'documentXEntity/', prefix: 'DocumentXEntity', description: 'Dokument datafangst opplasting', availableForListView: true, navigationState: 'document.upload', contextId: planiaUtils.contextIds.dataAcquisitionDocXEntity, allowEntityNavigation: false, },
			drawing: { url: 'Drawing/', prefix: 'Drawing', description: 'Tegning', availableForListView: true, navigationState: 'dwg.view', allowEntityNavigation: true, },
			email: { url: 'email/', prefix: 'Email', description: 'E-post', availableForListView: true, navigationState: 'email.edit', allowEntityNavigation: false, },
			emailContact: { url: 'emailContact/', prefix: 'EmailContact', description: 'Emailkontakt', availableForListView: false, allowEntityNavigation: false, },
			emailTemplate: { url: 'emailTemplate/', prefix: 'EmailTemplate', description: 'Epostmal', availableForListView: true, navigationState: 'emailTemplate.edit', allowEntityNavigation: false, },
			entityAttribute: { url: 'entityAttribute/', prefix: 'EntityAttribute', description: 'Egendefinerte felt', availableForListView: false, allowEntityNavigation: false, },
			entityComment: { url: 'entityComment/', prefix: 'EntityComment', description: 'Entitetskommentar', availableForListView: false, allowEntityNavigation: false, },
			entityCounter: { url: 'entityCounter/', prefix: 'EntityCounter', description: 'Tellere', availableForListView: true, allowEntityNavigation: false, },
			entityEmailList: { url: 'entityEmailList/', prefix: 'EntityEmailList', description: 'Epostliste', availableForListView: true, allowEntityNavigation: false, },
			entityHistory: { url: 'entityHistory/', prefix: 'EntityHistory', description: 'Datalogg', availableForListView: false, allowEntityNavigation: false, },
			entityLink: { url: 'entityLink/', prefix: 'EntityLink', description: 'Navigasjonslenke', availableForListView: true, navigationState: 'entityLink.edit', allowEntityNavigation: false, },
			entityPermission: { url: 'entityPermission/', prefix: 'EntityPermission', description: '', availableForListView: false, allowEntityNavigation: false, },
			entityPermissionProfile: { url: 'entityPermissionProfile/', prefix: 'EntityPermissionProfile', description: '', availableForListView: false, navigationState: 'entityPermissionProfile.edit', allowEntityNavigation: false, },
			entityTask: { url: 'entityTask/', prefix: 'EntityTask', description: 'Handlinger', availableForListView: true, navigationState: 'entityTask.edit', allowEntityNavigation: false, },
			entityTypeInfo: { url: 'entityTypeInfo/', prefix: 'undefined', description: '', availableForListView: false, navigationState: '', allowEntityNavigation: false, },
			entityXAttribute: { url: 'EntityXAttribute/', prefix: 'EntityXAttribute', description: 'Tilknyttet egendefinerte felt', availableForListView: false, allowEntityNavigation: false, },
			equipment: { url: 'equipment/', prefix: 'Equipment', description: 'Anlegg', availableForListView: true, navigationState: 'equipment.edit', allowEntityNavigation: true, },
			equipmentDataAcquisition: { url: 'equipment/dataacquisition/', prefix: 'Equipment', description: 'Datafangst', availableForListView: false, navigationState: 'equipment.datacquisition', allowEntityNavigation: true, },
			equipmentOperatingHours: { url: 'equipmentOperatingHours/', prefix: 'EquipmentOperatingHours', description: 'Driftstimer', availableForListView: true, navigationState: 'equipmentOperatingHours.edit', allowEntityNavigation: false, },
			equipmentOperatingHourType: { url: 'equipmentOperatingHourType/', prefix: 'EquipmentOperatingHourType', description: 'Anlegg registreringstype', availableForListView: false, navigationState: 'equipmentOperatingHourType.edit', allowEntityNavigation: false, },
			equipmentCategory: { url: 'equipmentCategory/', prefix: 'EquipmentCategory', description: 'Anleggskategori', availableForListView: false, navigationState: 'equipmentCategory.edit', allowEntityNavigation: false, },
			estate: { url: 'estate/', prefix: 'Estate', description: 'Eiendom', availableForListView: true, navigationState: 'estate.edit', allowEntityNavigation: true, },
			estateCategory: { url: 'estateCategory/', prefix: 'EstateCategory', description: 'Eiendomskategori', availableForListView: false, navigationState: 'estateCategory.edit', allowEntityNavigation: false, },
			externalLoginProvider: { url: 'externalLoginProvider/', prefix: 'ExternalLoginProvider', description: 'Eksterne innlogginstilbydere', availableForListView: false, allowEntityNavigation: false, },
			generalOptions: { url: 'GeneralOptions/', prefix: 'GeneralOptions', description: '', availableForListView: false, allowEntityNavigation: false, },
			gisEntity: { url: 'gisEntity/', prefix: 'GisEntity', description: '', availableForListView: false, allowEntityNavigation: false, },
			gisManagement: { url: 'gisManagement/', prefix: 'GisManagement', description: '', availableForListView: false, allowEntityNavigation: false, },
			housing: { url: 'Area/', prefix: 'Area', description: 'Bolig', availableForListView: true, navigationState: 'housing.edit', contextId: planiaUtils.contextIds.housing, allowEntityNavigation: true, },
			housingCustomer: { url: 'Customer/', prefix: 'Customer', description: 'Leietaker', availableForListView: true, navigationState: 'housingCustomer.edit', contextId: planiaUtils.contextIds.housingCustomer, allowEntityNavigation: true, },
			housingContract: { url: 'Contract/', prefix: 'Contract', description: 'Boligavtale', availableForListView: true, navigationState: 'housingContract.edit', contextId: planiaUtils.contextIds.housingContract, allowEntityNavigation: true, },
			hourType: { url: 'hourType/', prefix: 'HourType', description: 'Timetype', availableForListView: true, navigationState: 'hourType.edit', allowEntityNavigation: false, },
			kpi: { url: 'kpi/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			bimFile: { url: 'bimFile/', urlChunk: 'documentChunk/', prefix: 'BimFile', description: 'Bim fil', availableForListView: true, navigationState: 'bimFile.edit', allowEntityNavigation: true, },
			buildingXBimFile: { url: 'buildingXBimFile/', prefix: 'BuildingXBimFile', description: '', availableForListView: false, allowEntityNavigation: false, },
			image: { url: 'image/', prefix: 'Image', description: 'Bilder', availableForListView: true, allowEntityNavigation: false, },
			imageXEntity: { url: 'imageXEntity/', prefix: 'ImageXEntity', description: 'Bildereferanser', availableForListView: true, allowEntityNavigation: false, },
			invoicingTerm: { url: 'invoicingTerm/', prefix: 'InvoicingTerm', description: '', availableForListView: false, navigationState: 'invoicingTerm.edit', allowEntityNavigation: false, },
			integrationData: { url: 'IntegrationData/', prefix: 'IntegrationData', description: 'Integrasjonsdata', availableForListView: true, navigationState: 'integrationData.edit', allowEntityNavigation: false, },
			integrationDataJobId: { url: 'IntegrationDataJobId/', prefix: '', description: '', availableForListView: false, navigationState: '', allowEntityNavigation: false, },
			language: { url: 'language/', prefix: 'Language', description: 'Språk', availableForListView: false, allowEntityNavigation: false, },
			log: { url: 'log/', prefix: 'LogRecord', description: 'Logg', availableForListView: false, allowEntityNavigation: false, },
			maintenance: { url: 'maintenance/', prefix: '', description: 'Vedlikeholdsplan', availableForListView: false, allowEntityNavigation: false, },
			mobileMenuProfile: { url: 'mobileMenuProfile/', prefix: 'MobileMenuProfile', description: 'Mobil meny profil', availableForListView: true, navigationState: 'mobileMenuProfile.edit', allowEntityNavigation: false, },
			namedSelection: { url: 'namedSelection/', prefix: 'NamedSelection', description: 'Nedtrekksliste', availableForListView: false, navigationState: 'namedSelection.edit', allowEntityNavigation: false, },
			namedSelectionValue: { url: 'namedSelectionValue/', prefix: 'NamedSelectionValue', availableForListView: false, allowEntityNavigation: false, },
			nonsReference: { url: 'nonsReference/', prefix: 'NonsReference', availableForListView: false, allowEntityNavigation: false, },
			operationalMessage: { url: 'operationalMessage/', prefix: 'OperationalMessage', description: 'Driftsmeldinger', availableForListView: true, allowEntityNavigation: false, },
			organization: { url: 'organization/', prefix: 'Organization', description: 'Organisasjon', availableForListView: true, allowEntityNavigation: false, },
			organizationUnit: { url: 'organizationUnit/', prefix: 'OrganizationUnit', description: 'Organisasjon enhet', availableForListView: true, allowEntityNavigation: false, },
			organizationSection: { url: 'organizationSection/', prefix: 'OrganizationSection', description: 'Organisasjon seksjon', availableForListView: true, allowEntityNavigation: false, },
			passwordrecovery: { url: 'passwordrecovery/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			paymentTerm: { url: 'paymentTerm/', prefix: 'PaymentTerm', description: '', availableForListView: false, navigationState: 'paymentTerm.edit', allowEntityNavigation: false, },
			paymentOrderForm: { url: 'paymentOrderForm/', prefix: 'PaymentOrderForm', description: '', availableForListView: false, navigationState: 'paymentOrderForm.edit', allowEntityNavigation: false, },
			periodOfNotice: { url: 'periodOfNotice/', prefix: 'PeriodOfNotice', allowEntityNavigation: false, },
			periodicTask: { url: 'periodictask/', prefix: 'PeriodicTask', description: 'Periodisk rutine', availableForListView: true, navigationState: 'periodicTask.edit', allowEntityNavigation: true, },
			periodicTaskTemplate: { url: 'periodictask/', prefix: 'PeriodicTask', description: 'Periodisk rutine mal', availableForListView: true, navigationState: 'periodicTask.edit', contextId: planiaUtils.contextIds.periodicTaskTemplate, allowEntityNavigation: false, },
			PeriodicTaskXArea: { url: 'PeriodicTaskXArea/', prefix: 'PeriodicTaskXArea', description: '', availableForListView: false, allowEntityNavigation: false, },
			periodicTaskXEquipment: { url: 'PeriodicTaskXEquipment/', prefix: 'PeriodicTaskXEquipment', description: '', availableForListView: false, allowEntityNavigation: false, },
			periodicTaskXResourceGroup: { url: 'PeriodicTaskXResourceGroup/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			periodicTaskXStandardText: { url: 'PeriodicTaskXStandardText/', prefix: 'PeriodicTaskXStandardText', description: '', availableForListView: false, allowEntityNavigation: false, },
			periodicTaskXSparePart: { url: 'PeriodicTaskXSparePart/', prefix: 'PeriodicTaskXSparePart', description: '', availableForListView: false, allowEntityNavigation: false, },
			person: { url: 'person/', prefix: 'Person', description: 'Person', availableForListView: true, navigationState: 'person.edit', allowEntityNavigation: true, },
			personRole: { url: 'personRole/', prefix: 'PersonRole', description: 'Personrolle', availableForListView: true, navigationState: 'personRole.edit', allowEntityNavigation: false, },
			planiaSystem: { url: 'PlaniaSystem/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			priority: { url: 'priority/', prefix: 'Priority', description: 'Prioritet', availableForListView: true, navigationState: 'priority.edit', allowEntityNavigation: false, },
			project: { url: 'project/', prefix: 'Project', description: 'Prosjekt', availableForListView: true, navigationState: 'project.edit', allowEntityNavigation: true, },
			projectPhase: { url: 'projectPhase/', setupUrl: 'projectPhaseSetup/', prefix: 'ProjectPhase', description: 'Prosjektfase', availableForListView: false, allowEntityNavigation: false, },
			projectStatus: { url: 'projectStatus/', prefix: 'ProjectStatus', description: 'Prosjektstatus', availableForListView: false, allowEntityNavigation: false, },
			projectMilestone: { url: 'projectMilestone/', prefix: 'ProjectMilestone', description: 'Prosjektmilepæl', availableForListView: false, allowEntityNavigation: false, },
			projectCategory: { url: 'projectCategory/', prefix: 'ProjectCategory', description: 'Prosjektkategori', availableForListView: true, navigationState: 'projectCategory.edit', allowEntityNavigation: false, },
			projectType: { url: 'projectType/', prefix: 'ProjectType', description: 'Prosjekttype', availableForListView: true, navigationState: 'projectType.edit', allowEntityNavigation: false, },
			projectTemplate: { url: 'project/', prefix: 'Project', description: 'Prosjekt Mal', availableForListView: true, navigationState: 'projectTemplate.edit', contextId: planiaUtils.contextIds.projectTemplate, allowEntityNavigation: false, },
			purchaseOrder: { url: 'purchaseOrder/', prefix: 'PurchaseOrder', description: 'Bestilling', availableForListView: true, navigationState: 'purchaseOrder.edit', allowEntityNavigation: true, },
			purchaseOrderForm: { url: 'purchaseOrderForm/', prefix: 'PurchaseOrderForm', description: 'Bestillingsskjema', availableForListView: true, navigationState: 'purchaseOrderForm.edit', allowEntityNavigation: false, },
			purchaseOrderItem: { url: 'purchaseOrderItem/', prefix: 'PurchaseOrderItem', description: 'Bestillingsobjekt', availableForListView: true, navigationState: 'purchaseOrderItem.edit', allowEntityNavigation: true, },
			planiaBim: { url: 'planiaBim/', availableForListView: false, allowEntityNavigation: false, },
			region: { url: 'Region/', prefix: 'Region', description: 'Region', availableForListView: false, navigationState: 'region.edit', allowEntityNavigation: false, },
			registeredField: { url: 'RegisteredField/', prefix: 'RegisteredField', description: '', availableForListView: false, allowEntityNavigation: false, },
			enum: { url: 'Enum/', prefix: 'undefined', description: '', availableForListView: false, allowEntityNavigation: false, },
			referenceData: { url: 'referenceData/', prefix: 'ReferenceData', availableForListView: false, navigationState: 'referenceData.edit', allowEntityNavigation: false, },
			referenceType: { url: 'referenceType/', prefix: 'ReferenceType', description: 'Referanser', availableForListView: true, navigationState: 'referenceType.edit', allowEntityNavigation: false, },
			reinvoicingContract: { url: 'Contract/', prefix: 'Contract', description: 'Avtale', availableForListView: true, navigationState: 'reinvoicingContract.edit', contextId: planiaUtils.contextIds.reinvoicingContract, allowEntityNavigation: true, },
			rentalGroup: { url: 'rentalGroup/', prefix: 'RentalGroup', description: 'Husleiegruppe', availableForListView: false, navigationState: 'rentalGroup.edit', allowEntityNavigation: false, },
			reportMetadata: { url: 'ReportMetadata/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			report: { url: 'Report/', prefix: 'Report', description: '', availableForListView: false, allowEntityNavigation: false, },
			reportDownload: { url: 'ReportDownload/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			request: { url: 'request/', prefix: 'Request', description: 'Melding', availableForListView: true, navigationState: 'request.edit', allowEntityNavigation: true, },
			resourceGroup: { url: 'resourceGroup/', prefix: 'ResourceGroup', description: 'Ressursgruppe', availableForListView: true, navigationState: 'resourceGroup.edit', allowEntityNavigation: false, },
			resourceGroupCause: { url: 'ResourceGroupXCause/', prefix: 'ResourceGroupXCause', description: '', availableForListView: false, allowEntityNavigation: false, },
			scheduledJob: { url: 'scheduledJob/', prefix: 'ScheduledJob', description: 'Bakgrunnsjobb', availableForListView: true, navigationState: 'scheduledJob.edit', allowEntityNavigation: false, },
			scheduledJobStart: { url: 'scheduledJobStart/', prefix: 'ScheduledJob', description: 'Start bakgrunnsjobb', availableForListView: false, allowEntityNavigation: false, },
			scheduledJobExecution: { url: 'scheduledJobExecution/', prefix: 'ScheduledJobExecution', description: 'Utført bakgrunnsjobb', availableForListView: true, navigationState: 'scheduledJobExecution.edit', allowEntityNavigation: false, },
			smsProvider: { url: 'SmsProvider/', description: 'Sms-leverandør instillinger', allowEntityNavigation: false, },
			sparePart: { url: 'sparePart/', prefix: 'SparePart', description: 'Reservedel', availableForListView: true, navigationState: 'sparePart.edit', allowEntityNavigation: true, },
			sparePartCounting: { url: 'sparePartCounting/', prefix: 'SparePartCounting', description: 'Varetelling', availableForListView: true, navigationState: 'sparePartCounting.edit', allowEntityNavigation: true, },
			sparePartCountingItem: { url: 'sparePartCountingItem/', prefix: 'sparePartCountingItem', description: 'Varetelling post', availableForListView: true/*, navigationState: 'sparePartCountingItem.edit'*/, allowEntityNavigation: false, },
			sparePartCountingList: { url: 'sparePartCountingList/', prefix: 'SparePartCountingList', description: 'Varetelling liste', availableForListView: true, navigationState: 'sparePartCountingList.edit', allowEntityNavigation: false, },
			sparePartWithdrawal: { url: 'SparePartWithdrawal/', prefix: 'SparePartWithdrawal', description: 'Uttaksliste', availableForListView: true, navigationState: 'sparePartWithdrawal.edit', allowEntityNavigation: true, },
			standardText: { url: 'standardText/', prefix: 'StandardText', description: 'Standard tekst', availableForListView: true, navigationState: 'standardText.edit', allowEntityNavigation: false, },
			supplier: { url: 'supplier/', prefix: 'Supplier', description: 'Leverandør', availableForListView: true, navigationState: 'supplier.edit', allowEntityNavigation: true, },
			supplierAgreement: { url: 'supplierAgreement/', prefix: 'Supplier', description: 'Leverandør avtale', availableForListView: true, navigationState: 'supplierAgreement.edit', allowEntityNavigation: false, },
			translate: { url: 'translate/', prefix: 'LanguageXWebText', description: '', availableForListView: false, navigationState: 'translation.edit', allowEntityNavigation: false, },
			twoFactorToken: { url: 'twoFactorToken/', prefix: 'TwoFactorToken', description: 'To-faktor tokens', availableForListView: false, allowEntityNavigation: false, },
			user: { url: 'user/', urlChangePassword: 'user/changePassword/', urlChangeTeam: 'user/changeTeam/', urlChangeImage: 'user/changeImage/', prefix: 'User', description: 'Bruker', availableForListView: true, navigationState: 'user.edit', allowEntityNavigation: false, },
			userAccess: { url: 'UserAccess/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			userGroup: { url: 'user/', prefix: 'UserGroup', description: 'Brukergruppe', availableForListView: true, navigationState: 'usergroup.edit', contextId: planiaUtils.contextIds.userGroup, allowEntityNavigation: false, },
			userNotification: { url: 'userNotification/', prefix: 'UserNotification', allowEntityNavigation: false, },
			userProfile: { url: 'UserProfile/', prefix: 'UserProfile', description: 'Brukerprofil', availableForListView: true, allowEntityNavigation: false, },
			userXExternalLogin: { url: 'UserXExternalLogin/', prefix: 'UserXExternalLogin', description: 'Bruker/Ekstern innlogging', availableForListView: true, allowEntityNavigation: false, },
			userXUserNotification: { url: 'userXUserNotification/', prefix: 'UserXUserNotification', description: 'Aktivitetsnotifikasjoner', availableForListView: false, allowEntityNavigation: false, },
			userXSparePartCountingList: { url: 'userXSparePartCountingList/', prefix: 'userXSparePartCountingList', description: 'Bruker/Varetelling liste', availableForListView: false/*, navigationState: 'sparePartCountingList.edit'*/, allowEntityNavigation: false, },
			userXWebProfile: { url: 'userXWebProfile/', prefix: 'UserXWebProfile', description: '', availableForListView: false, allowEntityNavigation: false, },
			video: { url: 'video/', prefix: 'Video', description: '', availableForListView: false, allowEntityNavigation: false, },
			videoBinary: { url: 'videoBinary/', dataUrl: 'videoData', prefix: 'VideoBinary', description: '', availableForListView: false, allowEntityNavigation: false, },
			videoXEntity: { url: 'videoXEntity/', urlNew: 'videoXEntity/new', prefix: 'VideoXEntity', description: '', availableForListView: false, allowEntityNavigation: false, },
			webClientSettings: { url: 'WebClientSettings/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			webColumns: { url: 'webColumns/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			webDashboard: { url: 'webDashboard/', prefix: 'WebDashboard', description: 'Dashbord', availableForListView: false, navigationState: 'dashboard.view', allowEntityNavigation: false, },
			webListView: { url: 'WebListView/', prefix: 'WebListView', description: '', availableForListView: false, navigationState: 'weblistView.edit', allowEntityNavigation: false, },
			webMenu: { url: 'webMenu/', prefix: 'WebMenu', description: 'Webmeny', availableForListView: true, navigationState: 'webMenu.edit', allowEntityNavigation: false, },
			webMenuFilter: { url: 'webMenuFilter/', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			webProfile: { url: 'webProfile/', prefix: 'WebProfile', description: 'Webprofil', availableForListView: true, navigationState: 'webProfile.edit', allowEntityNavigation: false, },
			webProfileWebMenu: { url: 'webProfileXWebMenu/', prefix: 'WebProfileXWebMenu', description: '', availableForListView: false, allowEntityNavigation: false, },
			webUserToken: { url: 'webUserToken/', prefix: 'WebUserToken', description: 'Innloggede brukere', availableForListView: true, allowEntityNavigation: false, },
			workingDaysOff: { url: 'WorkingDaysOff/', prefix: "WorkingDaysOff", description: 'Fridager', availableForListView: true, navigationState: 'workingDaysOff.edit', allowEntityNavigation: false, },
			workOrder: { url: 'workOrder/', prefix: 'WorkOrder', description: 'Arbeidsordre', availableForListView: true, navigationState: 'workOrder.edit', allowEntityNavigation: true, },
			WorkOrderXArea: { url: 'WorkOrderXArea/', prefix: 'WorkOrderXArea', description: '', availableForListView: false, allowEntityNavigation: false, },
			WorkOrderXAreaStatus: { url: 'WorkOrderXArea/AreaStatus', prefix: '', description: '', availableForListView: false, allowEntityNavigation: false, },
			workOrderSparePart: { url: 'WorkOrderXSparePart/', prefix: 'WorkOrderXSparePart', description: 'Arbeidsordre/reservedel', availableForListView: true, navigationState: 'sparePart.edit', allowEntityNavigation: false, },
			workOrderXResourceGroup: { url: 'WorkOrderXResourceGroup/', prefix: 'WorkOrderXResourceGroup', description: 'Mine timer', availableForListView: true, navigationState: 'workOrderXResourceGroup.edit', allowEntityNavigation: false, },
			workOrderCalendar: { url: 'workOrderCalendar/', prefix: 'WorkOrderCalendar', description: 'Arbeidsordrekalender', allowEntityNavigation: false, },
			workOrderTemplate: { url: 'periodictask/', prefix: 'PeriodicTask', availableForListView: true, description: 'Aktivitetsmal', navigationState: 'workOrderTemplate.edit', contextId: planiaUtils.contextIds.workOrderTemplate, allowEntityNavigation: false, },

			getApiDataByName: function (name) {
				var allApiData = this;
				for (var p in allApiData) {
					if (allApiData.hasOwnProperty(p) && p.toLowerCase() === name.toLowerCase()) {
						return allApiData[p];
					}
				}
				console.error("getApiDataByName could not find: " + name);
				return null;
			}
		};

		addCustomEndpoints(apiData);

		// Add default contextIds
		Object.keys(apiData).forEach(function (key) {
			if (apiData[key].contextId === undefined && apiData[key].prefix)
				apiData[key].contextId = 'List-' + apiData[key].prefix;
		});

		return apiData;
	}

	function addCustomEndpoints(apiData) {
		// Project endpoints
		apiData.project.endpoint = {
			addWorkOrders: 'project/addWorkOrders/',
			removeWorkOrders: 'project/removeWorkOrders/',
			updateSingleSum: 'project/updateSingleProjectSum/',
			updateAllSums: 'project/updateAllProjectSums/',
			updateBudget: 'project/updateBudget/',
			createTemplate: 'project/createTemplate/',
			updateTemplate: 'project/updateTemplate/'
		};

		apiData.workOrder.endpoint = {
			closePayment: 'workOrder/closePayment/',
			closeReinvoicing: 'workOrder/closeReinvoicing/',
			generateHistory: 'workOrder/generateHistory/',
			createWorkOrdersWithoutValidation: 'workOrder/createWorkOrdersWithoutValidation',
			getDefaultSparePartEntity: 'workOrder/getDefaultSparePartEntity',
			getSparePartAttachments: 'workOrder/getSparePartAttachments',
			activateWorkOrders: 'workOrder/activateWorkOrders/',
			setCompletionRate: 'workOrder/setCompletionRate/'
		};

		apiData.periodicTask.endpoint = {
			generateOrders: 'periodicTask/generateOrders/'
		};

		apiData.bim.endpoint = {
			ping: 'bim/ping/'
		};

		apiData.userXUserNotification.endpoint = {
			setAsRead: 'userXUserNotification/setAsRead/'
		};

		apiData.condition.endpoint = {
			confirmVersion: 'condition/confirmVersion/',
			close: 'condition/close/',
			approveCondition: 'condition/approveCondition/',
		};

		apiData.deviation.endpoint = {
			close: 'deviation/close/',
			resetCorrectiveWorkOrder: 'deviation/resetCorrectiveWorkOrder/'
		};

		apiData.customer.endpoint = {
			searchPublicRegister: 'customer/searchPublicRegister/'
		};

		apiData.housingCustomer.endpoint = {
			searchPublicRegister: 'customer/searchPublicRegister/'
		};

		apiData.imageXEntity.endpoint = {
			toggleIsDefault: 'imageXEntity/toggleIsDefault/',
			toggleIsAttachment: 'imageXEntity/toggleIsAttachment/',
		};

		apiData.equipment.endpoint = {
			setPendingApproval: 'equipment/setPendingApproval/',
		};

		apiData.equipmentOperatingHours.endpoint = {
			consumption: 'equipmentOperatingHours/consumption/',
			buildingSummary: 'equipmentOperatingHours/buildingSummary/'
		};

		apiData.entityTask.endpoint = {
			setStatus: 'entityTask/setStatus/'
		};

		apiData.controlList.endpoint = {
			takeDraft: 'controlList/takeDraft/',
			publish: 'controlList/publish/',
			revertDraft: 'controlList/revertDraft/',
			countRelations: 'controlList/countRelations/',
		};

		apiData.controlListXEntity.endpoint = {
			getEarliestControlListDueDates: 'controlListXEntity/getEarliestControlListDueDates/'
		};

		apiData.document.endpoint = {
			approveAllDocumentXEntity: 'document/approveAllDocumentXEntity/',
			exportSizeEstimate: 'document/export/sizeEstimate',
			exportToken: 'document/export/token',
			exportDownload: 'document/export/download',
		};

		apiData.documentXEntity.endpoint = {
			setDataAcquisitionStatus: 'documentXEntity/setDataAcquisitionStatus/'
		};

		apiData.purchaseOrder.endpoint = {
			canIssue: 'purchaseOrder/canIssue/',
			issue: 'purchaseOrder/issue/',
		};

		apiData.purchaseOrderItem.endpoint = {
			receive: 'purchaseOrderItem/receive/',
		};

		apiData.cost.endpoint = {
			synchronizeWithPurchaseOrder: 'cost/synchronizeWithPurchaseOrder/',
			synchronizeWithPurchaseOrderItem: 'cost/synchronizeWithPurchaseOrderItem/',
			synchronizeWithWorkOrder: 'cost/synchronizeWithWorkOrder/',
			synchronizeWithProject: 'cost/synchronizeWithProject/',
			synchronizeWithConsumable: 'cost/synchronizeWithConsumable/',
		};

		apiData.address.endpoint = {
			search: "address/search"
		};

		apiData.email.endpoint = {
			sendTestEmail: "email/sendTestEmail"
		};

		apiData.workingDaysOff.endpoint = {
			getDueDate: "getDueDate",
		};
		apiData.generalOptions.endpoint = {
			getTermsOfUseDocument: "generalOptions/termsOfUse",
			resetTermsOfUseAcceptedDates: "generalOptions/resetTermsOfUseAcceptedDates"
		};

		apiData.user.endpoint = {
			acceptTermsOfUse: "user/acceptTermsOfUse"
		};

		apiData.maintenance.endpoint = {
			calendar: "maintenance/calendar/"
		};
	}

	function getStates() {

		var filtervars =
		{
			GuidDocument: { label: 'Guid dokument', type: 'input', key: 'GuidDocument' },
			GetLoggedInCleanerDrawing: { label: 'Filtrer på renhold', type: 'dropdown', key: 'GetLoggedInCleanerDrawing' },
			report: { label: 'Rapport', type: 'dropdown', key: 'reportName' },
			dashboard: { label: 'Dashboard', type: 'dropdown', key: 'GuidDashboard' },
			selectedTheme: { label: 'Tegningsfunksjon', type: 'dropdown', key: 'selectedTheme' },
			PropertyFilter: { label: 'Filter', type: 'filter', key: 'PropertyFilter' },
			Prefill: { label: 'Prefill', type: 'prefill', key: 'Prefill' },
			LimitCleaning: { label: 'Vis dagens renhold', type: 'checkbox', key: 'ShowTodaysTasks' }
		};

		var apiData = getApiData();

		var telerikReportFilter = [filtervars.report];
		var dashboardFilter = [filtervars.dashboard];
		var userListFilter = [filtervars.PropertyFilter];
		var dataOwnerListFilter = [filtervars.PropertyFilter];
		var estateListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var requestListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var newRequestFilter = [filtervars.Prefill];
		var newWorkOrderFilter = [filtervars.Prefill];
		var deviationListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var conditionListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var webmenuListFilter = [filtervars.PropertyFilter];
		var webProfileListFilter = [filtervars.PropertyFilter];
		var userXWebProfileListFilter = [filtervars.PropertyFilter];
		var cleaningQualityControlListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var buildingselectionListFilter = [filtervars.PropertyFilter];
		var documentListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var documentXEntityListFilter = [filtervars.PropertyFilter];
		var periodicTaskListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var areaListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var supplierListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var personListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var equipmentListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var buildingListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var buildingTemplateListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var buildingPersonFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var controlListFilter = [filtervars.PropertyFilter];
		var controlListXEntityFilter = [filtervars.PropertyFilter];
		var controlListItemAnswerFilter = [filtervars.PropertyFilter];
		var cleaningListFilter = [filtervars.PropertyFilter, filtervars.LimitCleaning];
		var workOrderListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var viewPdfFilter = [filtervars.GuidDocument];
		var drawingFilter = [filtervars.PropertyFilter, filtervars.GetLoggedInCleanerDrawing, filtervars.selectedTheme];
		var translationListFilter = [filtervars.GuidDocument];
		var mapFilter = [filtervars.PropertyFilter];
		var contractFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var customerFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var housingFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var sparePartFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var sparePartCountingFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var sparePartWithdrawalFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var purchaseOrderFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var costListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var articleListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var budgetListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var projectListFilter = [filtervars.PropertyFilter, filtervars.Prefill];
		var imageListFilter = [filtervars.PropertyFilter];
		var imageXEntityListFilter = [filtervars.PropertyFilter];


		/**
		 * availableWebMenu will activate the route for webmenu creation. The name set is the hardcoded name that will be shown.
		 *
		 * */

		var routes = [
			//common
			{ name: 'login', url: '/login/:refreshtoken?:returnToState', params: { returnToState: null, message: null }, templateUrl: 'app/login/views/login.html', controller: 'LoginController', publicPage: true },
			{ name: 'logout', url: '/logout', templateUrl: 'app/login/views/login.html', controller: 'LogoutController', excludeFromHistory: true },
			{ name: 'forgotPassword', url: '/forgotPassword', templateUrl: 'app/login/views/forgotPassword.html', controller: 'ForgotPasswordController', publicPage: true },
			{ name: 'mustChangePassword', url: '/mustChangePassword', params: { returnToState: null }, templateUrl: 'app/login/views/mustChangePassword.html', controller: 'MustChangePasswordController', publicPage: true, excludeFromHistory: true },
			{ name: 'unAuthorized', url: '/unAuthorized', templateUrl: 'app/common/views/unAuthorized.html', controller: 'UnAuthorizedController', excludeFromHistory: true },

			//dashboard
			{ name: 'dashboard', url: '/dashboard', abstract: true, template: '<ui-view/>' },
			{ name: 'dashboard.view', url: '/view/:guid?:menuGuid??filter', availableWebMenu: 'Dashboard', filters: dashboardFilter, params: { editMode: false }, templateUrl: 'app/dashboard/dashboard.html', controller: 'DashboardController', reloadOnSearch: true },
			{ name: 'dashboard.list', url: '/list', templateUrl: 'app/dashboard/listDashboard.html', controller: 'ListController', params: { entityData: apiData.webDashboard } },

			//user
			{ name: 'user', url: '/user', abstract: true, template: '<ui-view/>' },
			{ name: 'user.list', availableWebMenu: 'Brukerliste', filters: userListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/user/views/listUser.html', controller: 'ListController', params: { entityData: apiData.user } },
			{ name: 'user.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/user/views/editUser.html', controller: 'UserController', excludeFromHistory: true },
			{ name: 'user.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/user/views/editUser.html', controller: 'UserController' },

			//WebUserToken - Logged in users
			{ name: 'webUserToken', url: '/webUserToken', abstract: true, template: '<ui-view/>' },
			{ name: 'webUserToken.list', availableWebMenu: 'InnloggetBrukerliste', filters: userListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/webUserToken/views/listWebUserToken.html', controller: 'ListController', params: { entityData: apiData.webUserToken } },

			//profile
			{ name: 'profile', url: '/profile', abstract: true, template: '<ui-view/>' },
			{ name: 'profile.view', url: '/view', templateUrl: 'app/profile/views/myProfile.html', controller: 'MyProfileController' },
			{ name: 'profile.changePassword', url: '/changePassword', templateUrl: 'app/profile/views/changePassword.html', controller: 'ChangePasswordController', excludeFromHistory: true },

			//Work orders
			{ name: 'workOrder', url: '/workOrder', abstract: true, template: '<ui-view/>' },
			{ name: 'workOrder.list', availableWebMenu: 'Arbeidsordreliste', filters: workOrderListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/workOrder/views/listWorkOrder.html', controller: 'ListController', params: { entityData: apiData.workOrder } },
			{ name: 'workOrder.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/workOrder/views/editWorkOrder.html', controller: 'WorkOrderController' },
			{ name: 'workOrder.create', url: '/create?menuGuid', availableWebMenu: 'Ny arbeidsordre', filters: newWorkOrderFilter, params: { showPersistantData: false, menuGuid: null, guidEntityToCopy: null, entityData: apiData.workOrder }, templateUrl: 'app/workOrder/views/editWorkOrder.html', controller: 'WorkOrderController', excludeFromHistory: true },

			//WorkOrder templates
			{ name: 'workOrderTemplate', url: '/workOrderTemplate', abstract: true, template: '<ui-view/>' },
			{ name: 'workOrderTemplate.list', availableWebMenu: 'Arbeidsordremal liste', filters: periodicTaskListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/periodicTask/views/listPeriodicTask.html', controller: 'ListController', params: { entityData: apiData.workOrderTemplate } },
			{ name: 'workOrderTemplate.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/periodicTask/views/editPeriodicTask.html', controller: 'PeriodicTaskController' },
			{ name: 'workOrderTemplate.create', url: '/create', params: { showPersistantData: false, menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/periodicTask/views/editPeriodicTask.html', controller: 'PeriodicTaskController', excludeFromHistory: true },

			//WorkOrderXResourceGroup
			{ name: 'workOrderXResourceGroup', url: '/workOrderXResourceGroup', abstract: true, template: '<ui-view/>' },
			{ name: 'workOrderXResourceGroup.list', availableWebMenu: 'Timeføringsliste', url: '/list/:menuGuid??filter', templateUrl: 'app/resourceGroup/views/listWorkOrderXResourceGroup.html', controller: 'ListController', params: { entityData: apiData.workOrderXResourceGroup } },

			//Estate
			{ name: 'estate', url: '/estate', abstract: true, template: '<ui-view/>' },
			{ name: 'estate.list', availableWebMenu: 'Eiendomsliste', filters: estateListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/estate/views/listEstate.html', controller: 'ListController', params: { entityData: apiData.estate } },
			{ name: 'estate.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/estate/views/editEstate.html', controller: 'EstateController' },
			{ name: 'estate.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/estate/views/editEstate.html', controller: 'EstateController', excludeFromHistory: true },

			//Building
			{ name: 'building', url: '/building', abstract: true, template: '<ui-view/>' },
			{ name: 'building.list', availableWebMenu: 'Byggliste', filters: buildingListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/building/views/listBuilding.html', controller: 'ListController', params: { entityData: apiData.building } },
			{ name: 'building.edit', availableWebMenu: 'Bygginformasjon', url: '/edit/:guid', params: { menuGuid: '', tab: '' }, templateUrl: 'app/building/views/editBuilding.html', controller: 'BuildingController' },
			{ name: 'building.create', url: '/create', params: { showPersistantData: false, menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/building/views/editBuilding.html', controller: 'BuildingController', excludeFromHistory: true },

			//Building template
			{ name: 'buildingTemplate', url: '/buildingTemplate', abstract: true, template: '<ui-view/>' },
			{ name: 'buildingTemplate.list', availableWebMenu: 'Byggmal', filters: buildingTemplateListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/buildingTemplate/views/listBuildingTemplate.html', controller: 'ListController', params: { entityData: apiData.buildingTemplate } },
			{ name: 'buildingTemplate.edit', url: '/edit/:guid', params: { menuGuid: '', tab: '' }, templateUrl: 'app/buildingTemplate/views/editBuildingTemplate.html', controller: 'BuildingTemplateController' },
			{ name: 'buildingTemplate.create', url: '/create', params: { showPersistantData: false, menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/buildingTemplate/views/editBuildingTemplate.html', controller: 'BuildingTemplateController', excludeFromHistory: true },

			//BuildingPerson
			{ name: 'buildingPerson', url: '/buildingPerson', abstract: true, template: '<ui-view/>' },
			{ name: 'buildingPerson.list', availableWebMenu: 'Driftsansvarlig', filters: buildingPersonFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/buildingPerson/views/listBuildingPerson.html', controller: 'ListController', params: { entityData: apiData.buildingPerson, modal: { templateUrl: 'app/buildingPerson/views/editBuildingPerson.html', controller: 'BuildingPersonController', modalParam: { isUpdate: true } } } },
			{ name: 'buildingPerson.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/buildingPerson/views/editBuildingPerson.html', controller: 'BuildingPersonController' },


			//Equipment
			{ name: 'equipment', url: '/equipment', abstract: true, template: '<ui-view/>' },
			{ name: 'equipment.list', availableWebMenu: 'Anleggsliste', filters: equipmentListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/equipment/views/listEquipment.html', controller: 'ListController', params: { entityData: apiData.equipment } },
			{ name: 'equipment.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/equipment/views/editEquipment.html', controller: 'EquipmentController' },
			{ name: 'equipment.create', url: '/create', params: { showPersistantData: false, menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/equipment/views/editEquipment.html', controller: 'EquipmentController', excludeFromHistory: true },
			{ name: 'equipment.dataacquisition', availableWebMenu: 'Datafangst anleggsdokumentasjon', url: '/dataacquisition', templateUrl: 'app/equipment/dataAcquisition/equipmentDataAcquisition.html', controller: 'EquipmentDataAcquisitionController', params: { entityData: apiData.equipmentDataAcquisition } },

			//Component
			{ name: 'component', url: '/component', abstract: true, template: '<ui-view/>' },
			{ name: 'component.list', availableWebMenu: 'Komponentliste', filters: buildingListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/component/views/listComponent.html', controller: 'ListController', params: { entityData: apiData.component } },
			{ name: 'component.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/component/views/editComponent.html', controller: 'ComponentController' },
			{ name: 'component.create', url: '/create', params: { showPersistantData: false, menuGuid: '' }, templateUrl: 'app/component/views/editComponent.html', controller: 'ComponentController', excludeFromHistory: true },

			//Person
			{ name: 'person', url: '/person', abstract: true, template: '<ui-view/>' },
			{ name: 'person.list', availableWebMenu: 'Personliste', filters: personListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/person/views/listPerson.html', controller: 'ListController', params: { entityData: apiData.person } },
			{ name: 'person.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/person/views/editPerson.html', controller: 'PersonController' },
			{ name: 'person.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/person/views/editPerson.html', controller: 'PersonController', excludeFromHistory: true },

			//Supplier
			{ name: 'supplier', url: '/supplier', abstract: true, template: '<ui-view/>' },
			{ name: 'supplier.list', availableWebMenu: 'Leverandørliste', filters: supplierListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/supplier/views/listSupplier.html', controller: 'ListController', params: { entityData: apiData.supplier } },
			{ name: 'supplier.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/supplier/views/editSupplier.html', controller: 'SupplierController' },
			{ name: 'supplier.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/supplier/views/editSupplier.html', controller: 'SupplierController', excludeFromHistory: true },

			//SupplierAgreement
			{ name: 'supplierAgreement', url: '/supplierAgreement', abstract: true, template: '<ui-view/>' },
			{ name: 'supplierAgreement.list', availableWebMenu: 'LeverandørAvtaleliste', url: '/list/:menuGuid??filter', templateUrl: 'app/supplierAgreement/views/listSupplierAgreement.html', controller: 'ListController', params: { entityData: apiData.supplierAgreement } },
			{ name: 'supplierAgreement.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/supplierAgreement/views/editSupplierAgreement.html', controller: 'SupplierAgreementController' },
			{ name: 'supplierAgreement.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/supplierAgreement/views/editSupplierAgreement.html', controller: 'SupplierAgreementController', excludeFromHistory: true },

			//ContactPerson
			{ name: 'contactPerson', url: '/contactPerson', abstract: true, template: '<ui-view/>' },
			{ name: 'contactPerson.list', availableWebMenu: '', url: '/list/:menuGuid??filter', templateUrl: 'app/contactPerson/views/listContactPerson.html', controller: 'ListController', params: { entityData: apiData.contactPerson } },
			{ name: 'contactPerson.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/contactPerson/views/editContactPerson.html', controller: 'ContactPersonController' },
			{ name: 'contactPerson.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/contactPerson/views/editContactPerson.html', controller: 'ContactPersonController', excludeFromHistory: true },

			//Area
			{ name: 'area', url: '/area', abstract: true, template: '<ui-view/>' },
			{ name: 'area.list', availableWebMenu: 'Romliste', filters: areaListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/area/views/listArea.html', controller: 'ListController', params: { entityData: apiData.area } },
			{ name: 'area.edit', url: '/edit/:guid', params: { menuGuid: '', model: null }, templateUrl: 'app/area/views/editArea.html', controller: 'AreaController' },
			{ name: 'area.create', url: '/create', params: { showPersistantData: false, menuGuid: '' }, templateUrl: 'app/area/views/editArea.html', controller: 'AreaController', excludeFromHistory: true },

			//Periodic task
			{ name: 'periodicTask', url: '/periodicTask', abstract: true, template: '<ui-view/>' },
			{ name: 'periodicTask.list', availableWebMenu: 'Periodisk rutineliste', filters: periodicTaskListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/periodicTask/views/listPeriodicTask.html', controller: 'ListController', params: { entityData: apiData.periodicTask } },
			{ name: 'periodicTask.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/periodicTask/views/editPeriodicTask.html', controller: 'PeriodicTaskController' },
			{ name: 'periodicTask.create', url: '/create', params: { showPersistantData: false, menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/periodicTask/views/editPeriodicTask.html', controller: 'PeriodicTaskController', excludeFromHistory: true },

			//Periodic task template
			{ name: 'periodicTaskTemplate', url: '/periodicTaskTemplate', abstract: true, template: '<ui-view/>' },
			{ name: 'periodicTaskTemplate.list', availableWebMenu: 'Periodisk malrutineliste', filters: periodicTaskListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/periodicTask/views/listPeriodicTask.html', controller: 'ListController', params: { entityData: apiData.periodicTaskTemplate } },

			//Document
			{ name: 'document', url: '/document', abstract: true, template: '<ui-view/>' },
			{ name: 'document.list', availableWebMenu: 'Dokumentliste', filters: documentListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/document/views/listDocument.html', controller: 'ListController', params: { entityData: apiData.document } },
			{ name: 'document.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/document/views/editDocumentMetadata.html', controller: 'EditDocumentController' },
			{ name: 'document.viewPdf', availableWebMenu: 'PDF dokument', filters: viewPdfFilter, url: '/viewPdf/:menuGuid', templateUrl: 'app/document/views/viewPdfDocument.html', controller: 'ViewPdfDocumentController', excludeFromHistory: true },
			{ name: 'document.dataAcquisitionMatrix', availableWebMenu: 'Datafangst dokument informasjonstype matrise', filters: documentXEntityListFilter, url: '/dataacquisition/matrix/:menuGuid??filter', templateUrl: 'app/document/dataAcquisition/documentDataAcquisitionMatrix.html', controller: 'DocumentDataAcquisitionMatrixController', params: { entityData: apiData.documentDataAcquisitionMatrix } },
			{ name: 'document.dataAcquisitionList', availableWebMenu: 'Datafangst dokument anlegg trestruktur', filters: documentXEntityListFilter, url: '/dataacquisition/listview/:menuGuid??filter', templateUrl: 'app/document/dataAcquisition/documentDataAcquisitionListView.html', controller: 'ListController', params: { entityData: apiData.documentDataAcquisitionListView } },
			{ name: 'document.dataAcquisitionUpload', url: '/dataacquisition/upload', templateUrl: 'app/document/dataAcquisition/documentDataAcquisitionUpload.html', controller: 'DocumentDataAcquisitionUploadController', params: { entityData: apiData.documentDataAcquisitionUpload } },

			//DocumentXentity
			{ name: 'documentXEntity', url: '/documentXEntity', abstract: true, template: '<ui-view/>' },
			{ name: 'documentXEntity.list', availableWebMenu: 'Dokument referanser', filters: documentXEntityListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/documentXEntity/views/listDocumentXEntity.html', controller: 'ListController', params: { entityData: apiData.documentXEntity } },
			{ name: 'documentXEntity.archiveList', availableWebMenu: 'Dokument arkivbehandling', filters: documentXEntityListFilter, url: '/documentArchiveList/:menuGuid??filter', templateUrl: 'app/documentXEntity/documentArchive/archiveList.html', controller: 'ArchiveListController', backNavigation: true, params: { entityData: apiData.documentXEntity } },

			//Image
			{ name: 'image', url: '/image', abstract: true, template: '<ui-view/>' },
			{ name: 'image.list', availableWebMenu: 'Bildeliste', filters: imageListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/image/views/listImage.html', controller: 'ListController', params: { entityData: apiData.image } },

			//ImageXentity
			{ name: 'imageXEntity', url: '/imageXEntity', abstract: true, template: '<ui-view/>' },
			{ name: 'imageXEntity.list', availableWebMenu: 'Bildereferanser', filters: imageXEntityListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/imageXEntity/views/listImageXEntity.html', controller: 'ListController', params: { entityData: apiData.imageXEntity } },

			//Building selection
			{ name: 'buildingSelection', url: '/buildingSelection', abstract: true, template: '<ui-view/>' },
			{ name: 'buildingSelection.list', availableWebMenu: 'Utvalgsliste', filters: buildingselectionListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/buildingSelection/views/listBuildingSelection.html', controller: 'ListController', params: { entityData: apiData.buildingSelection } },
			{ name: 'buildingSelection.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/buildingSelection/views/buildingSelection.html', controller: 'BuildingSelectionController' },
			{ name: 'buildingSelection.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/buildingSelection/views/buildingSelection.html', controller: 'BuildingSelectionController', excludeFromHistory: true },

			//Deviation
			{ name: 'deviation', url: '/deviation', abstract: true, template: '<ui-view/>' },
			{ name: 'deviation.list', availableWebMenu: 'Avviksliste', filters: deviationListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/deviation/views/listDeviation.html', controller: 'ListController', params: { entityData: apiData.deviation } },
			{ name: 'deviation.create', url: '/create/:menuGuid??filter', params: { entityData: apiData.deviation, menuGuid: '' }, templateUrl: 'app/deviation/views/editDeviation.html', controller: 'DeviationController', excludeFromHistory: true, resolve: { $modalInstance: function () { return null; } } },
			{ name: 'deviation.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/deviation/views/editDeviation.html', controller: 'DeviationController', resolve: { $modalInstance: function () { return null; } } },

			//Request
			{ name: 'request', url: '/request', abstract: true, template: '<ui-view/>' },
			{ name: 'request.list', availableWebMenu: 'Meldingsliste', filters: requestListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/request/views/listRequest.html', controller: 'ListController', params: { entityData: apiData.request } },
			{ name: 'request.create', availableWebMenu: 'Ny Melding', filters: newRequestFilter, url: '/create/:menuGuid?', params: { entityData: apiData.request, menuGuid: '', showPersistantData: false }, excludeFromHistory: true, templateUrl: 'app/request/views/editRequest.html', controller: 'RequestController' },
			{ name: 'request.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/request/views/editRequest.html', controller: 'RequestController' },

			//UserXExternalLogin
			{ name: 'userXExternalLogin', url: '/userXExternalLogin', abstract: true, template: '<ui-view/>' },
			{ name: 'userXExternalLogin.list', availableWebMenu: 'Bruker/Ekstern innlogging liste', url: '/list/:menuGuid??filter', templateUrl: 'app/userXExternalLogin/views/listUserXExternalLogin.html', controller: 'ListController', params: { entityData: apiData.userXExternalLogin } },

			//userWebProfile
			{ name: 'userXWebProfile', url: '/userXWebProfile', abstract: true, template: '<ui-view/>' },
			{ name: 'userXWebProfile.list', availableWebMenu: 'Brukerwebprofil liste', filters: userXWebProfileListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/user/views/listUserWebProfile.html', controller: 'ListController', params: { entityData: apiData.userXWebProfile } },

			//WebProfile
			{ name: 'webProfile', url: '/webProfile', abstract: true, template: '<ui-view/>' },
			{ name: 'webProfile.list', availableWebMenu: 'Webprofil liste', filters: webProfileListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/webProfile/views/listWebProfile.html', controller: 'ListController', params: { entityData: apiData.webProfile } },
			{ name: 'webProfile.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/webProfile/views/editWebProfile.html', controller: 'WebProfileController' },
			{ name: 'webProfile.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/webProfile/views/editWebProfile.html', controller: 'WebProfileController', excludeFromHistory: true },

			//WebMenu
			{ name: 'webMenu', url: '/webMenu', abstract: true, template: '<ui-view/>' },
			{ name: 'webMenu.list', availableWebMenu: 'Webmenyliste', filters: webmenuListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/webMenu/views/listWebMenu.html', controller: 'ListController', params: { entityData: apiData.webMenu } },
			{ name: 'webMenu.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/webMenu/views/editWebMenu.html', controller: 'WebMenuController' },
			{ name: 'webMenu.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/webMenu/views/editWebMenu.html', controller: 'WebMenuController', excludeFromHistory: true },

			//Report
			{ name: 'report', url: '/report', abstract: true, template: '<ui-view/>' },
			//{ name: 'report.ssrs', url: '/ssrs', templateUrl: 'app/report/views/ssrs.html', controller: 'SsrsController' },
			{ name: 'report.view', availableWebMenu: 'Rapport', filters: telerikReportFilter, url: '/view/:menuGuid??filter', templateUrl: 'app/report/views/telerik.html', controller: 'TelerikController' },
			{ name: 'report.print', url: '/print/:reportName?/:reportParams?', templateUrl: 'app/report/views/telerik.html', controller: 'PrintReportController', excludeFromHistory: true },
			{ name: 'report.list', url: '/list/:menuGuid??filter', templateUrl: 'app/report/views/listReport.html', controller: 'ListController', params: { entityData: apiData.report } },
			//Navigation
			{ name: 'navigation', url: '/navigation', abstract: true, template: '<ui-view/>' },
			{ name: 'navigation.view', url: '/view', params: { menuGuid: '' }, template: '<plania-dashboard></plania-dashboard>' },

			//Map
			{ name: 'map', url: '/map', abstract: true, template: '<ui-view/>', reloadOnSearch: false },
			{ name: 'map.view', url: '/view', availableWebMenu: 'Kart', filters: mapFilter, templateUrl: 'app/map/views/map.html', controller: 'MapController', params: {}, reloadOnSearch: false },
			//GisEntity
			{ name: 'gisEntity', url: '/gisEntity', abstract: true, template: '<ui-view/>' },
			{ name: 'gisEntity.list', availableWebMenu: '', filters: deviationListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/map/views/listGisEntity.html', controller: 'ListController', params: { entityData: apiData.gisEntity } },

			//System
			{ name: 'planiasystem', url: '/planiasystem', abstract: true, template: '<ui-view/>' },
			{ name: 'planiasystem.ps', url: '/ps', template: '<plania-system-details></plania-system-details>' },

			//Map
			{ name: 'admin', url: '/admin', abstract: true, template: '<ui-view/>' },
			{ name: 'admin.dashboard', url: '/view', availableWebMenu: 'Administrasjon', template: '<admin-dashboard></admin-dashboard>' },


			//Control lists
			{ name: 'controlList', url: '/controlList', abstract: true, template: '<ui-view/>' },
			{ name: 'controlList.list', availableWebMenu: 'Kontrollister', filters: controlListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/controlList/views/listControlList.html', controller: 'ListController', params: { entityData: apiData.controlList } },
			{ name: 'controlList.edit', url: '/edit/:guid', params: { menuGuid: '', tab: '' }, templateUrl: 'app/controlList/views/editControlList.html', controller: 'ControlListController' },
			{ name: 'controlList.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/controlList/views/editControlList.html', controller: 'ControlListController', excludeFromHistory: true },

			//ControlListItemAnswer
			{ name: 'controlListItemAnswer', url: '/controlListItemAnswer', abstract: true, template: '<ui-view/>' },
			{ name: 'controlListItemAnswer.list', availableWebMenu: 'Kontrollistesvar', filters: controlListItemAnswerFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/controlList/views/listControlListItemAnswer.html', controller: 'ListController', params: { entityData: apiData.controlListItemAnswer } },

			//ControlListXEntity
			{ name: 'completedControlLists', url: '/completedControlLists', abstract: true, template: '<ui-view/>' },
			{ name: 'completedControlLists.list', availableWebMenu: 'Utfylte kontrollister', filters: controlListXEntityFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/controlList/views/listCompletedControlList.html', controller: 'ListController', params: { entityData: apiData.controlListXEntity } },

			//Cleaning module
			{ name: 'cleaning', url: '/cleaning', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaning.list', availableWebMenu: 'Renholdsliste', filters: cleaningListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/cleaning/views/listCleaning.html', controller: 'ListController', params: { entityData: apiData.areaXCleaningTask } },

			//Cleaning Area
			{ name: 'cleaning.area', url: '/area', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaning.area.list', availableWebMenu: 'Romliste renhold', filters: areaListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/area/views/listArea.html', controller: 'ListController', backNavigation: true, params: { entityData: apiData.area } },

			//Cleaning dwg
			{ name: 'cleaning.dwg', url: '/dwg', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaning.dwg.list', availableWebMenu: 'Tegning renhold', filters: drawingFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/dwg/views/listDrawing.html', controller: 'ListController', backNavigation: true, params: { entityData: apiData.drawing } },

			//Cleaning ResourceGroup
			{ name: 'cleaning.resourceGroup', url: '/resourceGroup', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaning.resourceGroup.list', url: '/list/:menuGuid??filter', templateUrl: 'app/resourceGroup/views/listResourceGroup.html', controller: 'ListController', params: { entityData: apiData.resourceGroup } },

			//CleaningTask
			{ name: 'cleaningTask', url: '/cleaningTask', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaningTask.list', url: '/list/:menuGuid??filter', availableWebMenu: 'Renholdsoppgaver', templateUrl: 'app/cleaningTask/views/listCleaningTask.html', controller: 'ListController', params: { entityData: apiData.cleaningTask } },
			{ name: 'cleaningTask.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/cleaningTask/views/cleaningTask.html', controller: 'CleaningTaskController' },
			{ name: 'cleaningTask.create', url: '/create', params: { showPersistantData: false, menuGuid: '' }, templateUrl: 'app/cleaningTask/views/cleaningTaskWizard.html', controller: 'CleaningTaskWizardController', excludeFromHistory: true },

			//CleaningQuality
			{ name: 'cleaningQuality', url: '/cleaningQuality', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaningQuality.list', url: '/list/:menuGuid??filter', availableWebMenu: 'Renholdskvaliteter', templateUrl: 'app/cleaningQuality/views/listCleaningQuality.html', controller: 'ListController', params: { entityData: apiData.cleaningQuality } },
			{ name: 'cleaningQuality.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/cleaningQuality/views/cleaningQuality.html', controller: 'CleaningQualityController' },
			{ name: 'cleaningQuality.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/cleaningQuality/views/cleaningQuality.html', controller: 'CleaningQualityController', excludeFromHistory: true },

			//CleaningQualityControl
			{ name: 'cleaningQualityControl', url: '/cleaningQualityControl', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaningQualityControl.list', availableWebMenu: 'INSTA-kontroll', filters: cleaningQualityControlListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/cleaningQualityControl/views/listCleaningQualityControl.html', controller: 'ListController', params: { entityData: apiData.cleaningQualityControl } },
			{ name: 'cleaningQualityControl.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/cleaningQualityControl/views/cleaningQualityControl.html', controller: 'CleaningQualityControlController' },
			{ name: 'cleaningQualityControl.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/cleaningQualityControl/views/cleaningQualityControl.html', controller: 'CleaningQualityControlController', excludeFromHistory: true },

			//CleaningCompletion
			{ name: 'cleaningCompletion', url: '/cleaningCompletion', abstract: true, template: '<ui-view/>' },
			{ name: 'cleaningCompletion.list', availableWebMenu: 'Aktivitetsoversikt', url: '/list/:menuGuid??filter', templateUrl: 'app/cleaningCompletion/views/listCleaningCompletion.html', controller: 'ListController', params: { entityData: apiData.cleaningCompletion } },
			{ name: 'cleaningCompletion.overview', url: '/overview', templateUrl: 'app/cleaningCompletion/views/cleaningCompletionOverview.html', controller: 'CleaningCompletionOverviewController', params: { menuGuid: '' } },

			//Drawing
			{ name: 'dwg', url: '/dwg', abstract: true, template: '<ui-view/>' },
			{ name: 'dwg.view', url: '/view/:guid', templateUrl: 'app/dwg/views/dwg.html', controller: 'DwgController', params: { theme: null, guidQualityControl: null, menuGuid: '', guidArea: null, selectedTheme: null } },
			{ name: 'dwg.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<dwg-details></dwg-details>' },
			{ name: 'dwg.create', url: '/create', params: { menuGuid: '' }, template: '<drawing-create></drawing-create>', excludeFromHistory: true },
			{ name: 'dwg.list', availableWebMenu: 'Tegning', filters: drawingFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/dwg/views/listDrawing.html', controller: 'ListController', params: { entityData: apiData.drawing } },

			//BIM File
			{ name: 'bimFile', url: '/bimFile', abstract: true, template: '<ui-view/>' },
			{ name: 'bimFile.edit', url: '/edit/:guid', templateUrl: 'app/bimFile/views/editBimFile.html', controller: 'BimFileController' },
			{ name: 'bimFile.list', url: '/list/:menuGuid??filter', availableWebMenu: 'Bim filliste', templateUrl: 'app/bimFile/views/listBimFile.html', controller: 'ListController', params: { entityData: apiData.bimFile } },
			{ name: 'bimFile.viewBim', url: '/viewBim?guidEstate&guidRequest&guidBimProject&guidBuilding&guidArea&guidBimFile&guidEquipment&nonsReferencePath', template: '<react-bim-viewer></react-bim-viewer>', reloadOnSearch: false, params: { entityData: apiData.bimFile, guidEstate: null, guidBimProject: null, guidBuilding: null, guidArea: null, guidBimFile: null, guidEquipment: null, nonsReferencePath: null, guidRequest: null } },

			//BIM project
			{ name: 'bimProjects', url: '/bimProjects', template: '<bim-projects></bim-projects>' },

			//BCF project
			{ name: 'bcfProject', url: '/bcfProject', abstract: true, template: '<ui-view/>' },
			{ name: 'bcfProject.list', availableWebMenu: 'Bcf prosjekt liste', url: '/list/:menuGuid??filter', templateUrl: 'app/bcfProject/views/listBcfProject.html', controller: 'ListController', params: { entityData: apiData.bcfProject } },
			{ name: 'bcfProject.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<bcf-project-details></bcf-project-details>', },
			{ name: 'bcfProject.create', url: '/create', params: { menuGuid: '' }, template: '<bcf-project-details></bcf-project-details>', excludeFromHistory: true },

			//usergroups
			{ name: 'usergroup', url: '/usergroup', abstract: true, template: '<ui-view/>' },
			{ name: 'usergroup.list', availableWebMenu: 'Brukergruppe', filters: userListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/usergroup/views/listusergroups.html', controller: 'ListController', params: { entityData: apiData.userGroup } },
			{ name: 'usergroup.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/usergroup/views/editUserGroup.html', controller: 'UserGroupController' },
			{ name: 'usergroup.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/usergroup/views/editUserGroup.html', controller: 'UserGroupController', excludeFromHistory: true },

			//dataImport
			{ name: 'dataImport', url: '/dataImport', abstract: true, template: '<ui-view/>' },
			{ name: 'dataImport.list', availableWebMenu: 'Dataimport', url: '/list/:menuGuid??filter', templateUrl: 'app/dataImport/views/listDataImport.html', controller: 'ListController', params: { entityData: apiData.dataImport } },
			{ name: 'dataImport.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/dataImport/views/createDataImport.html', controller: 'CreateDataImportController' },
			{ name: 'dataImport.edit', url: '/edit/:guid', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/dataImport/views/editDataImport.html', controller: 'DataImportController' },
			{ name: 'dataImport.bim', url: '/bim/edit/:guid', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/dataImport/views/editBimDataImport.html', controller: 'DataBimImportController' },

			//dataowner
			{ name: 'dataOwner', url: '/dataOwner', abstract: true, template: '<ui-view/>' },
			{ name: 'dataOwner.list', url: '/list/:menuGuid??filter', availableWebMenu: 'Klient', filters: dataOwnerListFilter, templateUrl: 'app/dataOwner/views/listDataOwner.html', controller: 'ListController', params: { entityData: apiData.dataOwner } },
			{ name: 'dataOwner.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/dataOwner/views/editDataOwner.html', controller: 'DataOwnerController' },
			{ name: 'dataOwner.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/dataOwner/views/editDataOwner.html', controller: 'DataOwnerController', excludeFromHistory: true },

			//generalOptions
			{ name: 'generalOptions', url: '/generalOptions', templateUrl: 'app/generalOptions/views/generalOptions.html', controller: 'GeneralOptionsController' },

			//generalOptions
			{ name: 'registeredFields', url: '/registeredFields', templateUrl: 'app/registeredFields/registeredFields.html', controller: 'RegisteredFieldsController' },

			//entityPermissionProfile
			{ name: 'entityPermissionProfile', url: '/entityPermissionProfile', abstract: true, template: '<ui-view/>' },
			{ name: 'entityPermissionProfile.list', url: '/list/:menuGuid??filter', templateUrl: 'app/entityPermissionProfile/listEntityPermissionProfile.html', controller: 'ListController', params: { entityData: apiData.entityPermissionProfile } },
			{ name: 'entityPermissionProfile.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<entity-permission-profile-details></entity-permission-profile-details>', },
			{ name: 'entityPermissionProfile.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, template: '<entity-permission-profile-details></entity-permission-profile-details>', excludeFromHistory: true },

			//translation
			{ name: 'translation', url: '/translation', abstract: true, template: '<ui-view/>' },
			{ name: 'translation.list', url: '/list/:menuGuid??filter', availableWebMenu: 'Oversettelser', filters: translationListFilter, templateUrl: 'app/translation/views/listTranslations.html', controller: 'TranslationListController', params: { entityData: apiData.translate } },
			{ name: 'translation.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/translation/views/editTranslation.html', controller: 'TranslationController' },

			//log
			{ name: 'log', url: '/log', abstract: true, template: '<ui-view/>' },
			{ name: 'log.list', url: '/list/:menuGuid??filter', availableWebMenu: 'Log', filters: dataOwnerListFilter, templateUrl: 'app/log/views/listLog.html', controller: 'ListController', params: { entityData: apiData.log } },
			{ name: 'log.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/log/views/editLog.html', controller: 'LogController', excludeFromHistory: true },

			//ConditionTypes
			{ name: 'conditionType', url: '/conditionType', abstract: true, template: '<ui-view/>' },
			{ name: 'conditionType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/conditionType/views/listConditionType.html', controller: 'ListController', params: { entityData: apiData.conditionType } },
			{ name: 'conditionType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/conditionType/views/editConditionType.html', controller: 'ConditionTypeController' },
			{ name: 'conditionType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/conditionType/views/editConditionType.html', controller: 'ConditionTypeController', excludeFromHistory: true },

			//DeviationType
			{ name: 'deviationType', url: '/deviationType', abstract: true, template: '<ui-view/>' },
			{ name: 'deviationType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/deviationType/views/listDeviationType.html', controller: 'ListController', params: { entityData: apiData.deviationType } },
			{ name: 'deviationType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/deviationType/views/editDeviationType.html', controller: 'DeviationTypeController' },
			{ name: 'deviationType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/deviationType/views/editDeviationType.html', controller: 'DeviationTypeController', excludeFromHistory: true },

			//Activity group
			{ name: 'activityGroup', url: '/activityGroup', abstract: true, template: '<ui-view/>' },
			{ name: 'activityGroup.list', url: '/list/:menuGuid??filter', templateUrl: 'app/activityGroup/views/listActivityGroup.html', controller: 'ListController', params: { entityData: apiData.activityGroup } },
			{ name: 'activityGroup.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'activityGroup.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//personRole
			{ name: 'personRole', url: '/personRole', abstract: true, template: '<ui-view/>' },
			{ name: 'personRole.list', url: '/list/:menuGuid??filter', templateUrl: 'app/personRole/views/listPersonRole.html', controller: 'ListController', params: { entityData: apiData.personRole } },
			{ name: 'personRole.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/personRole/views/editPersonRole.html', controller: 'PersonRoleController' },
			{ name: 'personRole.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/personRole/views/editPersonRole.html', controller: 'PersonRoleController', excludeFromHistory: true },

			//priority
			{ name: 'priority', url: '/priority', abstract: true, template: '<ui-view/>' },
			{ name: 'priority.list', url: '/list/:menuGuid??filter', templateUrl: 'app/priority/views/listPriority.html', controller: 'ListController', params: { entityData: apiData.priority } },
			{ name: 'priority.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/priority/views/priority.html', controller: 'PriorityController' },
			{ name: 'priority.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/priority/views/priority.html', controller: 'PriorityController', excludeFromHistory: true },

			//project
			{ name: 'project', url: '/project', abstract: true, template: '<ui-view/>' },
			{ name: 'project.list', availableWebMenu: 'Prosjektliste', filters: projectListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/project/views/listProject.html', controller: 'ListController', params: { entityData: apiData.project } },
			{ name: 'project.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/project/views/project.html', controller: 'ProjectController' },
			{ name: 'project.editPhase', url: '/phase/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/project/views/projectPhaseSetup.html', controller: 'ProjectPhaseSetupController', excludeFromHistory: true },
			{ name: 'project.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/project/views/createProject.html', controller: 'CreateProjectController', excludeFromHistory: true },

			//projectTemplate
			{ name: 'projectTemplate', url: '/projectTemplate', abstract: true, template: '<ui-view/>' },
			{ name: 'projectTemplate.list', availableWebMenu: 'Prosjektmalliste', filters: projectListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/projectTemplate/views/listProjectTemplate.html', controller: 'ListController', params: { entityData: apiData.projectTemplate } },
			{ name: 'projectTemplate.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/project/views/project.html', controller: 'ProjectController' },
			{ name: 'projectTemplate.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/projectTemplate/views/createProjectTemplate.html', controller: 'CreateProjectTemplateController', excludeFromHistory: true },

			//projectCategory
			{ name: 'projectCategory', url: '/projectCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'projectCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/projectCategory/views/listProjectCategory.html', controller: 'ListController', params: { entityData: apiData.projectCategory } },
			{ name: 'projectCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<project-category-details></project-category-details>' },
			{ name: 'projectCategory.create', url: '/create', params: { menuGuid: '' }, template: '<project-category-details></project-category-details>', excludeFromHistory: true },

			//equipmentCategory
			{ name: 'equipmentCategory', url: '/equipmentCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'equipmentCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/equipmentCategory/views/listEquipmentCategory.html', controller: 'ListController', params: { entityData: apiData.equipmentCategory } },
			{ name: 'equipmentCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<equipment-category-details></equipment-category-details>' },
			{ name: 'equipmentCategory.create', url: '/create', params: { menuGuid: '' }, template: '<equipment-category-details></equipment-category-details>', excludeFromHistory: true },

			//buildingCategory
			{ name: 'buildingCategory', url: '/buildingCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'buildingCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/buildingCategory/views/listBuildingCategory.html', controller: 'ListController', params: { entityData: apiData.buildingCategory } },
			{ name: 'buildingCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<building-category-details></building-category-details>' },
			{ name: 'buildingCategory.create', url: '/create', params: { menuGuid: '' }, template: '<building-category-details></building-category-details>', excludeFromHistory: true },

			//estateCategory
			{ name: 'estateCategory', url: '/estateCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'estateCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/estateCategory/views/listEstateCategory.html', controller: 'ListController', params: { entityData: apiData.estateCategory } },
			{ name: 'estateCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<estate-category-details></estate-category-details>' },
			{ name: 'estateCategory.create', url: '/create', params: { menuGuid: '' }, template: '<estate-category-details></estate-category-details>', excludeFromHistory: true },

			//areaCategory
			{ name: 'areaCategory', url: '/areaCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'areaCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/areaCategory/views/listAreaCategory.html', controller: 'ListController', params: { entityData: apiData.areaCategory } },
			{ name: 'areaCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<area-category-details></area-category-details>' },
			{ name: 'areaCategory.create', url: '/create', params: { menuGuid: '' }, template: '<area-category-details></area-category-details>', excludeFromHistory: true },

			//componentCategory
			{ name: 'componentCategory', url: '/componentCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'componentCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/componentCategory/views/listComponentCategory.html', controller: 'ListController', params: { entityData: apiData.componentCategory } },
			{ name: 'componentCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<component-category-details></component-category-details>' },
			{ name: 'componentCategory.create', url: '/create', params: { menuGuid: '' }, template: '<component-category-details></component-category-details>', excludeFromHistory: true },

			//activityCategory
			{ name: 'activityCategory', url: '/activityCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'activityCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/activityCategory/views/listActivityCategory.html', controller: 'ListController', params: { entityData: apiData.activityCategory } },
			{ name: 'activityCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<activity-category-details></activity-category-details>' },
			{ name: 'activityCategory.create', url: '/create', params: { menuGuid: '' }, template: '<activity-category-details></activity-category-details>', excludeFromHistory: true },

			//projectType
			{ name: 'projectType', url: '/projectType', abstract: true, template: '<ui-view/>' },
			{ name: 'projectType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/projectType/views/listProjectType.html', controller: 'ListController', params: { entityData: apiData.projectType } },
			{ name: 'projectType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'projectType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//purchaseOrder
			{ name: 'purchaseOrder', url: '/purchaseOrder', abstract: true, template: '<ui-view/>' },
			{ name: 'purchaseOrder.list', availableWebMenu: 'Bestillingsliste', filters: purchaseOrderFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/purchaseOrder/views/listPurchaseOrder.html', controller: 'ListController', params: { entityData: apiData.purchaseOrder } },
			{ name: 'purchaseOrder.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/purchaseOrder/views/purchaseOrder.html', controller: 'PurchaseOrderController' },
			{ name: 'purchaseOrder.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/purchaseOrder/views/purchaseOrder.html', controller: 'PurchaseOrderController', excludeFromHistory: true },

			//purchaseOrderForm
			{ name: 'purchaseOrderForm', url: '/purchaseOrderForm', abstract: true, template: '<ui-view/>' },
			{ name: 'purchaseOrderForm.list', url: '/list/:menuGuid??filter', templateUrl: 'app/purchaseOrderForm/views/listPurchaseOrderForm.html', controller: 'ListController', params: { entityData: apiData.purchaseOrderForm } },
			{ name: 'purchaseOrderForm.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/purchaseOrderForm/views/purchaseOrderForm.html', controller: 'PurchaseOrderFormController' },
			{ name: 'purchaseOrderForm.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/purchaseOrderForm/views/purchaseOrderForm.html', controller: 'PurchaseOrderFormController', excludeFromHistory: true },

			//purchaseOrderItem
			{ name: 'purchaseOrderItem', url: '/purchaseOrderItem', abstract: true, template: '<ui-view/>' },
			{ name: 'purchaseOrderItem.list', url: '/list/:menuGuid??filter', templateUrl: 'app/purchaseOrder/views/listPurchaseOrderItem.html', controller: 'ListController', params: { entityData: apiData.purchaseOrderItem } },
			{ name: 'purchaseOrderItem.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/purchaseOrder/views/purchaseOrderItem.html', controller: 'PurchaseOrderItemController' },
			{ name: 'purchaseOrderItem.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/purchaseOrder/views/purchaseOrderItem.html', controller: 'PurchaseOrderItemController', excludeFromHistory: true },


			//deliveryTerm
			{ name: 'deliveryTerm', url: '/deliveryTerm', abstract: true, template: '<ui-view/>' },
			{ name: 'deliveryTerm.list', url: '/list/:menuGuid??filter', templateUrl: 'app/deliveryTerm/views/listDeliveryTerm.html', controller: 'ListController', params: { entityData: apiData.deliveryTerm } },
			// 			{ name: 'deliveryTerm.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/deliveryTerm/views/editDeliveryTerm.html', controller: 'DeliveryTermController' },
			// 			{ name: 'deliveryTerm.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/deliveryTerm/views/editDeliveryTerm.html', controller: 'DeliveryTermController', excludeFromHistory: true },

			//consequenceType
			{ name: 'consequenceType', url: '/consequenceType', abstract: true, template: '<ui-view/>' },
			{ name: 'consequenceType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/consequenceType/views/listConsequenceType.html', controller: 'ListController', params: { entityData: apiData.consequenceType } },
			{ name: 'consequenceType.edit', url: '/edit/:guid', params: { menuGuid: '', hasDescription: true }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'consequenceType.create', url: '/create', params: { menuGuid: '', hasDescription: true }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//consequence
			{ name: 'consequence', url: '/consequence', abstract: true, template: '<ui-view/>' },
			{ name: 'consequence.list', url: '/list/:menuGuid??filter', templateUrl: 'app/consequence/views/listConsequence.html', controller: 'ListController', params: { entityData: apiData.consequence } },
			{ name: 'consequence.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/consequence/views/editConsequence.html', controller: 'ConsequenceController' },
			{ name: 'consequence.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/consequence/views/editConsequence.html', controller: 'ConsequenceController', excludeFromHistory: true },

			//cylinderType
			{ name: 'cylinderType', url: '/cylinderType', abstract: true, template: '<ui-view/>' },
			{ name: 'cylinderType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/common/views/simpleListWrapper.html', controller: 'ListController', params: { entityData: apiData.cylinderType } },
			{ name: 'cylinderType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'cylinderType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//HourType
			{ name: 'hourType', url: '/hourType', abstract: true, template: '<ui-view/>' },
			{ name: 'hourType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/hourType/views/listHourType.html', controller: 'ListController', params: { entityData: apiData.hourType } },
			{ name: 'hourType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/hourType/views/editHourType.html', controller: 'HourTypeController' },
			{ name: 'hourType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/hourType/views/editHourType.html', controller: 'HourTypeController', excludeFromHistory: true },

			//Alarm
			{ name: 'alarm', url: '/alarm', abstract: true, template: '<ui-view/>' },
			{ name: 'alarm.list', url: '/list/:menuGuid??filter', templateUrl: 'app/alarm/listAlarm.html', controller: 'ListController', params: { entityData: apiData.alarm } },
			{ name: 'alarm.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<alarm-details></alarm-details>' },
			{ name: 'alarm.create', url: '/create', params: { menuGuid: '' }, template: '<alarm-details></alarm-details>', excludeFromHistory: true },

			//AlarmLog
			{ name: 'alarmLog', url: '/alarmLog', abstract: true, template: '<ui-view/>' },
			{ name: 'alarmLog.list', url: '/list/:menuGuid??filter', templateUrl: 'app/alarmLog/views/listAlarmLog.html', controller: 'ListController', params: { entityData: apiData.alarmLog } },
			{ name: 'alarmLog.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/alarmLog/views/alarmLog.html', controller: 'AlarmLogController' },
			{ name: 'alarmLog.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/alarmLog/views/alarmLog.html', controller: 'AlarmLogController', excludeFromHistory: true },

			//account
			{ name: 'account', url: '/account', abstract: true, template: '<ui-view/>' },
			{ name: 'account.list', availableWebMenu: "Konto liste", url: '/list/:menuGuid??filter', templateUrl: 'app/account/views/listAccount.html', controller: 'ListController', params: { entityData: apiData.account } },
			{ name: 'account.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/account/views/editAccount.html', controller: 'AccountController' },
			{ name: 'account.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/account/views/editAccount.html', controller: 'AccountController', excludeFromHistory: true },

			//costCenter
			{ name: 'costCenter', url: '/costCenter', abstract: true, template: '<ui-view/>' },
			{ name: 'costCenter.list', availableWebMenu: 'Koststedliste', url: '/list/:menuGuid??filter', templateUrl: 'app/costCenter/views/listCostCenter.html', controller: 'ListController', params: { entityData: apiData.costCenter } },
			{ name: 'costCenter.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/costCenter/views/editCostCenter.html', controller: 'SimpleGenericFormController' },
			{ name: 'costCenter.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/costCenter/views/editCostCenter.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//department
			{ name: 'department', url: '/department', abstract: true, template: '<ui-view/>' },
			{ name: 'department.list', availableWebMenu: 'Avdelingsliste', url: '/list/:menuGuid??filter', templateUrl: 'app/department/views/listDepartment.html', controller: 'ListController', params: { entityData: apiData.department } },
			{ name: 'department.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/department/views/editDepartment.html', controller: 'DepartmentController' },
			{ name: 'department.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/department/views/editDepartment.html', controller: 'DepartmentController', excludeFromHistory: true },

			//standardtext
			{ name: 'standardText', url: '/standardText', abstract: true, template: '<ui-view/>' },
			{ name: 'standardText.list', url: '/list/:menuGuid??filter', templateUrl: 'app/standardText/views/listStandardText.html', controller: 'ListController', params: { entityData: apiData.standardText } },
			{ name: 'standardText.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/standardText/views/editStandardText.html', controller: 'StandardTextController' },
			{ name: 'standardText.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/standardText/views/editStandardText.html', controller: 'StandardTextController', excludeFromHistory: true },

			//contract
			{ name: 'contract', url: '/contract', abstract: true, template: '<ui-view/>' },
			{ name: 'contract.list', availableWebMenu: 'Kontraktlisteliste', filters: contractFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/contract/views/listContract.html', controller: 'ListController', params: { entityData: apiData.contract } },
			{ name: 'contract.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editContract.html', controller: 'ContractController' },
			{ name: 'contract.create', url: '/contract', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editContract.html', controller: 'ContractController', excludeFromHistory: true },

			//housingContract
			{ name: 'housingContract', url: '/housingContract', abstract: true, template: '<ui-view/>' },
			{ name: 'housingContract.list', filters: contractFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/contract/views/listHousingContract.html', controller: 'ListController', backNavigation: true, params: { entityData: apiData.housingContract } },
			{ name: 'housingContract.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editHousingContract.html', controller: 'HousingContractController', backNavigation: true },
			{ name: 'housingContract.create', url: '/contract', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editHousingContract.html', controller: 'HousingContractController', backNavigation: false, excludeFromHistory: true },

			//reinvoicingContract
			{ name: 'reinvoicingContract', url: '/reinvoicingContract', abstract: true, template: '<ui-view/>' },
			{ name: 'reinvoicingContract.list', availableWebMenu: 'Avtaleliste Viderefakturering', filters: contractFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/contract/views/listReinvoicingContract.html', controller: 'ListController', backNavigation: true, params: { entityData: apiData.reinvoicingContract } },
			{ name: 'reinvoicingContract.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editReinvoicingContract.html', controller: 'ContractController', backNavigation: true },
			{ name: 'reinvoicingContract.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editReinvoicingContract.html', controller: 'ContractController', backNavigation: false, excludeFromHistory: true },

			//contractItem
			{ name: 'contractItem', url: '/contractItem', abstract: true, template: '<ui-view/>' },
			//{ name: 'contractItem.list', availableWebMenu: 'Kontrakt objekter', filters: contractFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/contractItem/views/listContractItemt.html', controller: 'ListController', params: { entityData: apiData.contractItem } },
			{ name: 'contractItem.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/contract/views/editContractItem.html', controller: 'ContractItemController' },
			{ name: 'contractItem.create', url: '/contractItem', params: { menuGuid: '' }, templateUrl: 'app/contractItem/views/editContractItem.html', controller: 'ContractItemController', excludeFromHistory: true },

			//contractCategory
			{ name: 'contractCategory', url: '/contractCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'contractCategory.list', availableWebMenu: 'Kontrakt kategori liste', filters: contractFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/contractCategory/views/listContractCategory.html', controller: 'ListController', params: { entityData: apiData.contractCategory } },
			{ name: 'contractCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<contract-category-details></contract-category-details>' },
			{ name: 'contractCategory.create', url: '/create', params: { menuGuid: '' }, template: '<contract-category-details></contract-category-details>', excludeFromHistory: true },

			//contractType
			{ name: 'contractType', url: '/contractType', abstract: true, template: '<ui-view/>' },
			{ name: 'contractType.list', availableWebMenu: 'Kontrakt type liste', filters: contractFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/contractType/views/listContractType.html', controller: 'ListController', params: { entityData: apiData.contractType } },
			{ name: 'contractType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/contractType/views/editContractType.html', controller: 'ContractTypeController' },
			{ name: 'contractType.create', url: '/contractType', params: { menuGuid: '' }, templateUrl: 'app/contractType/views/editContractType.html', controller: 'ContractTypeController', excludeFromHistory: true },

			//invoicingTerm
			{ name: 'invoicingTerm', url: '/invoicingTerm', abstract: true, template: '<ui-view/>' },
			{ name: 'invoicingTerm.list', url: '/list/:menuGuid??filter', templateUrl: 'app/invoicingTerm/views/listInvoicingTerm.html', controller: 'ListController', params: { entityData: apiData.invoicingTerm } },
			{ name: 'invoicingTerm.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/invoicingTerm/views/editInvoicingTerm.html', controller: 'InvoicingTermController' },
			{ name: 'invoicingTerm.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/invoicingTerm/views/editInvoicingTerm.html', controller: 'InvoicingTermController', excludeFromHistory: true },

			//integrationData
			{ name: 'integrationData', url: '/integrationData', abstract: true, template: '<ui-view/>' },
			{ name: 'integrationData.list', url: '/list/:menuGuid??filter', templateUrl: 'app/integrationData/views/listIntegrationData.html', controller: 'ListController', params: { entityData: apiData.integrationData } },
			{ name: 'integrationData.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/integrationData/views/editIntegrationData.html', controller: 'IntegrationDataController' },

			//paymentTerm
			{ name: 'paymentTerm', url: '/paymentTerm', abstract: true, template: '<ui-view/>' },
			{ name: 'paymentTerm.list', url: '/list/:menuGuid??filter', templateUrl: 'app/paymentTerm/views/listPaymentTerm.html', controller: 'ListController', params: { entityData: apiData.paymentTerm } },
			{ name: 'paymentTerm.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/paymentTerm/views/editPaymentTerm.html', controller: 'PaymentTermController' },
			{ name: 'paymentTerm.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/paymentTerm/views/editPaymentTerm.html', controller: 'PaymentTermController', excludeFromHistory: true },

			//paymentOrderForm
			{ name: 'paymentOrderForm', url: '/paymentOrderForm', abstract: true, template: '<ui-view/>' },
			{ name: 'paymentOrderForm.list', url: '/list/:menuGuid??filter', templateUrl: 'app/paymentOrderForm/views/listpaymentOrderForm.html', controller: 'ListController', params: { entityData: apiData.paymentOrderForm } },
			{ name: 'paymentOrderForm.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/paymentOrderForm/views/editPaymentOrderForm.html', controller: 'PaymentOrderFormController' },
			{ name: 'paymentOrderForm.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/paymentOrderForm/views/editPaymentOrderForm.html', controller: 'PaymentOrderFormController', excludeFromHistory: true },

			//customer
			{ name: 'customer', url: '/customer', abstract: true, template: '<ui-view/>' },
			{ name: 'customer.list', availableWebMenu: 'Kundeliste', filters: customerFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/customer/views/listCustomer.html', controller: 'ListController', params: { entityData: apiData.customer } },
			{ name: 'customer.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<customer-details></customer-details>' },
			{ name: 'customer.create', url: '/create', params: { menuGuid: '' }, template: '<customer-create></customer-create>', excludeFromHistory: true },
			{ name: 'customer.anonymizationDashboard', url: '/anonymizationDashboard', templateUrl: 'app/customer/anonymization/dashboard/anonymizationDashboard.html', controller: 'AnonymizationDashboardController' },
			{ name: 'customer.anonymizationConfiguration', url: '/anonymizationConfiguration', templateUrl: 'app/customer/anonymization/configuration/anonymizationConfiguration.html', controller: 'AnonymizationConfigurationController' },

			//housingCustomer
			{ name: 'housingCustomer', url: '/housingCustomer', abstract: true, template: '<ui-view/>' },
			{ name: 'housingCustomer.list', availableWebMenu: 'Leietakerliste', filters: customerFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/customer/views/listHousingCustomer.html', controller: 'ListController', params: { entityData: apiData.housingCustomer } },
			{ name: 'housingCustomer.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<customer-details></customer-details>' },
			{ name: 'housingCustomer.create', url: '/create', params: { menuGuid: '' }, template: '<customer-create></customer-create>', excludeFromHistory: true },

			//article
			{ name: 'article', url: '/article', abstract: true, template: '<ui-view/>' },
			{ name: 'article.list', availableWebMenu: 'Artikkelliste', filters: articleListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/article/views/listArticle.html', controller: 'ListController', params: { entityData: apiData.article } },
			{ name: 'article.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/article/views/editArticle.html', controller: 'ArticleController' },
			{ name: 'article.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/article/views/editArticle.html', controller: 'ArticleController', excludeFromHistory: true },

			//resourceGroup
			{ name: 'resourceGroup', url: '/resourceGroup', abstract: true, template: '<ui-view/>' },
			{ name: 'resourceGroup.list', availableWebMenu: 'Liste av ressursgrupper', url: '/list/:menuGuid??filter', templateUrl: 'app/resourceGroup/views/listResourceGroup.html', controller: 'ListController', params: { entityData: apiData.resourceGroup } },
			{ name: 'resourceGroup.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/resourceGroup/views/editResourceGroup.html', controller: 'ResourceGroupController' },
			{ name: 'resourceGroup.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/resourceGroup/views/editResourceGroup.html', controller: 'ResourceGroupController', excludeFromHistory: true },

			//condition
			{ name: 'condition', url: '/condition', abstract: true, template: '<ui-view/>' },
			{ name: 'condition.list', availableWebMenu: 'Tiltaksliste', filters: conditionListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/condition/views/listConditions.html', controller: 'ListController', params: { entityData: apiData.condition } },
			{ name: 'condition.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/condition/views/editCondition.html', controller: 'ConditionController', resolve: { $modalInstance: function () { return null; } } },
			{ name: 'condition.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/condition/views/editCondition.html', controller: 'ConditionController', excludeFromHistory: true, resolve: { $modalInstance: function () { return null; } } },

			//Accounting
			{ name: 'accounting', url: '/accounting', abstract: true, template: '<ui-view/>' },
			{ name: 'accounting.list', url: '/list/:menuGuid??filter', templateUrl: 'app/accounting/views/listAccounting.html', controller: 'ListAccountingController', params: { entityData: apiData.accounting } },

			//NamedSelection
			{ name: 'namedSelection', url: '/namedSelection', abstract: true, template: '<ui-view/>' },
			{ name: 'namedSelection.list', url: '/list/:menuGuid??filter', templateUrl: 'app/namedSelection/listNamedSelection.html', controller: 'ListController', params: { entityData: apiData.namedSelection } },
			{ name: 'namedSelection.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<namedselection-details></namedselection-details>' },
			{ name: 'namedSelection.create', url: '/create', params: { menuGuid: '' }, template: '<namedselection-details></namedselection-details>', excludeFromHistory: true },

			//EquipmentOperatingHours
			{ name: 'equipmentOperatingHours', url: '/equipmentOperatingHours', abstract: true, template: '<ui-view/>' },
			{ name: 'equipmentOperatingHours.list', availableWebMenu: 'Driftstimer', filters: documentXEntityListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/equipmentOperatingHours/views/listEquipmentOperatingHours.html', controller: 'ListController', params: { entityData: apiData.equipmentOperatingHours } },

			//EquipmentOperatingHourType
			{ name: 'equipmentOperatingHourType', url: '/equipmentOperatingHourType', abstract: true, template: '<ui-view/>' },
			{ name: 'equipmentOperatingHourType.list', availableWebMenu: 'Driftstimetype liste', url: '/list/:menuGuid??filter', templateUrl: 'app/equipmentOperatingHourType/views/listEquipmentOperatingHourType.html', controller: 'ListController', params: { entityData: apiData.equipmentOperatingHourType } },
			{ name: 'equipmentOperatingHourType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'equipmentOperatingHourType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//MaintenancePlan
			{ name: 'maintenancePlan', url: '/maintenancePlan??filter', availableWebMenu: 'Vedlikeholdsplan', params: { menuGuid: '' }, templateUrl: 'app/maintenancePlan/maintenancePlan.html', controller: 'MaintenancePlanController' },

			//cost
			{ name: 'cost', url: '/cost', abstract: true, template: '<ui-view/>' },
			{ name: 'cost.list', availableWebMenu: 'Kostnadsliste', filters: costListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/cost/views/listCosts.html', controller: 'ListController', params: { entityData: apiData.cost } },
			{ name: 'cost.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/cost/views/cost.html', controller: 'CostController' },
			{ name: 'cost.create', url: '/create', params: { showPersistantData: false, menuGuid: '' }, templateUrl: 'app/cost/views/cost.html', controller: 'CostController', excludeFromHistory: true },

			//budget
			{ name: 'budget', url: '/budget', abstract: true, template: '<ui-view/>' },
			{ name: 'budget.list', availableWebMenu: 'Budsjettliste', filters: budgetListFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/budget/views/listBudget.html', controller: 'ListController', params: { entityData: apiData.budget } },
			{ name: 'budget.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/budget/views/budget.html', controller: 'BudgetController' },
			{ name: 'budget.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/budget/views/budget.html', controller: 'BudgetController', excludeFromHistory: true },

			//documentCategory
			{ name: 'documentCategory', url: '/documentCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'documentCategory.list', url: '/list/:menuGuid??filter', templateUrl: 'app/documentCategory/views/listDocumentCategory.html', controller: 'ListController', params: { entityData: apiData.documentCategory } },
			{ name: 'documentCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<document-category-details></document-category-details>' },
			{ name: 'documentCategory.create', url: '/create', params: { menuGuid: '' }, template: '<document-category-details></document-category-details>', excludeFromHistory: true },

			//documentType
			{ name: 'documentType', url: '/documentType', abstract: true, template: '<ui-view/>' },
			{ name: 'documentType.list', url: '/list/:menuGuid??filter', templateUrl: 'app/documentType/views/listDocumentType.html', controller: 'ListController', params: { entityData: apiData.documentType } },
			{ name: 'documentType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'documentType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//region
			{ name: 'region', url: '/region', abstract: true, template: '<ui-view/>' },
			{ name: 'region.list', url: '/list/:menuGuid??filter', templateUrl: 'app/common/views/simpleListWrapper.html', controller: 'ListController', params: { entityData: apiData.region } },
			{ name: 'region.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'region.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//customerGroup
			{ name: 'customerGroup', url: '/customerGroup', abstract: true, template: '<ui-view/>' },
			{ name: 'customerGroup.list', url: '/list/:menuGuid??filter', templateUrl: 'app/common/views/simpleListWrapper.html', controller: 'ListController', params: { entityData: apiData.customerGroup } },
			{ name: 'customerGroup.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'customerGroup.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//customerCategroy
			{ name: 'customerCategory', url: '/customerCategory', abstract: true, template: '<ui-view/>' },
			{ name: 'customerCategory.list', availableWebMenu: 'Kundekategorier', url: '/list/:menuGuid??filter', templateUrl: 'app/common/views/simpleListWrapper.html', controller: 'ListController', params: { entityData: apiData.customerCategory } },
			{ name: 'customerCategory.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<customer-category-details></customer-category-details>' },
			{ name: 'customerCategory.create', url: '/create', params: { menuGuid: '' }, template: '<customer-category-details></customer-category-details>', excludeFromHistory: true },

			//customerLineOfBusiness
			{ name: 'customerLineOfBusiness', url: '/customerLineOfBusiness', abstract: true, template: '<ui-view/>' },
			{ name: 'customerLineOfBusiness.list', availableWebMenu: 'Bransje (kunde)', url: '/list/:menuGuid??filter', templateUrl: 'app/common/views/simpleListWrapper.html', controller: 'ListController', params: { entityData: apiData.customerLineOfBusiness } },
			{ name: 'customerLineOfBusiness.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController' },
			{ name: 'customerLineOfBusiness.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/common/views/simpleGenericForm.html', controller: 'SimpleGenericFormController', excludeFromHistory: true },

			//sparePart
			{ name: 'sparePart', url: '/sparePart', abstract: true, template: '<ui-view/>' },
			{ name: 'sparePart.list', availableWebMenu: 'Reservedeler', filters: sparePartFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/sparePart/views/listSpareParts.html', controller: 'ListController', params: { entityData: apiData.sparePart } },
			{ name: 'sparePart.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/sparePart/views/editSpareParts.html', controller: 'SparePartsController' },
			{ name: 'sparePart.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/sparePart/views/editSpareParts.html', controller: 'SparePartsController', excludeFromHistory: true },

			//sparePartCounting
			{ name: 'sparePartCounting', url: '/sparePartCounting', abstract: true, template: '<ui-view/>' },
			{ name: 'sparePartCounting.list', availableWebMenu: 'Varetellinger', filters: sparePartCountingFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/sparePartCounting/views/listSparePartCountings.html', controller: 'ListController', params: { entityData: apiData.sparePartCounting } },
			{ name: 'sparePartCounting.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/sparePartCounting/views/sparePartCounting.html', controller: 'SparePartCountingController' },
			{ name: 'sparePartCounting.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/sparePartCounting/views/sparePartCounting.html', controller: 'SparePartCountingController', excludeFromHistory: true },


			//sparePartCountingItem
			{ name: 'sparePartCountingItem', url: '/sparePartCountingItem', abstract: true, template: '<ui-view/>' },
			{ name: 'sparePartCountingItem.list', availableWebMenu: 'Lageravvik', url: '/list/:menuGuid??filter', templateUrl: 'app/sparePartCountingItem/views/listSparePartCountingItems.html', controller: 'ListController', params: { entityData: apiData.sparePartCountingItem } },
			//{ name: 'sparePartCounting.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/sparePartCounting/views/sparePartCounting.html', controller: 'SparePartCountingController' },
			//{ name: 'sparePartCounting.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/sparePartCounting/views/sparePartCounting.html', controller: 'SparePartCountingController', excludeFromHistory: true },

			//sparePartWithdrawal
			{ name: 'sparePartWithdrawal', url: '/sparePartWithdrawal', abstract: true, template: '<ui-view/>' },
			{ name: 'sparePartWithdrawal.list', availableWebMenu: 'Uttakshistorikk', filters: sparePartWithdrawalFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/sparePartWithdrawal/views/listSparePartWithdrawal.html', controller: 'ListController', params: { entityData: apiData.sparePartWithdrawal } },
			{ name: 'sparePartWithdrawal.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/sparePartWithdrawal/views/sparePartWithdrawal.html', controller: 'SparePartWithdrawalController' },
			{ name: 'sparePartWithdrawal.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/sparePartWithdrawal/views/sparePartWithdrawal.html', controller: 'SparePartWithdrawalController', excludeFromHistory: true },

			// 			//sparePartCountingList
			// 			{ name: 'sparePartCountingList', url: '/sparePartCountingList', abstract: true, template: '<ui-view/>' },
			// 			{ name: 'sparePartCountingList.list', url: '/list/:menuGuid??filter', templateUrl: 'app/sparePartCountingList/views/listSparePartCountingLists.html', controller: 'ListController', params: { entityData: apiData.sparePartCountingList } },
			// 			{ name: 'sparePartCountingList.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/sparePartCountingList/views/sparePartCountingList.html', controller: 'SparePartCountingListController' },
			// 			{ name: 'sparePartCountingList.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/sparePartCountingList/views/sparePartCountingList.html', controller: 'SparePartCountingListController' },

			//mobileMenuProfile
			{ name: 'mobileMenuProfile', url: '/mobileMenuProfile', abstract: true, template: '<ui-view/>' },
			{ name: 'mobileMenuProfile.list', url: '/list/:menuGuid??filter', templateUrl: 'app/mobileMenuProfile/views/listMobileMenuProfile.html', controller: 'ListController', params: { entityData: apiData.mobileMenuProfile } },
			{ name: 'mobileMenuProfile.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<mobile-menu-profile-details></mobile-menu-profile-details>' },
			{ name: 'mobileMenuProfile.create', url: '/create', params: { menuGuid: '' }, template: '<mobile-menu-profile-details></mobile-menu-profile-details>', excludeFromHistory: true },

			//housing
			{ name: 'housing', url: '/housing', abstract: true, template: '<ui-view/>' },
			{ name: 'housing.list', availableWebMenu: 'Boligliste', filters: housingFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/housing/views/listHousing.html', controller: 'ListController', params: { entityData: apiData.housing } },
			{ name: 'housing.edit', url: '/edit/:guid', params: { menuGuid: '', model: null }, templateUrl: 'app/housing/views/editHousing.html', controller: 'HousingController' },
			{ name: 'housing.create', url: '/create', params: { guidEntityToCopy: '', menuGuid: '' }, templateUrl: 'app/housing/views/editHousing.html', controller: 'HousingController', excludeFromHistory: true },

			//cause
			{ name: 'cause', url: '/cause', abstract: true, template: '<ui-view/>' },
			{ name: 'cause.list', availableWebMenu: 'Årsaksliste', filters: housingFilter, url: '/list/:menuGuid??filter', templateUrl: 'app/cause/views/listCause.html', controller: 'ListController', params: { entityData: apiData.cause } },
			{ name: 'cause.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/cause/views/cause.html', controller: 'CauseController' },
			{ name: 'cause.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/cause/views/cause.html', controller: 'CauseController', excludeFromHistory: true },

			//apiClient
			{ name: 'apiClient', url: '/apiClient', abstract: true, template: '<ui-view/>' },
			{ name: 'apiClient.list', url: '/list/:menuGuid??filter', templateUrl: 'app/apiClient/views/listApiClients.html', controller: 'ListController', params: { entityData: apiData.apiClient } },
			{ name: 'apiClient.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/apiClient/views/editApiClient.html', controller: 'ApiClientController' },
			{ name: 'apiClient.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '' }, templateUrl: 'app/apiClient/views/editApiClient.html', controller: 'ApiClientController', excludeFromHistory: true },

			//apiRequestLog
			{ name: 'apiRequestLog', url: '/apiRequestLog', abstract: true, template: '<ui-view/>' },
			{ name: 'apiRequestLog.list', availableWebMenu: 'API log', url: '/list/:menuGuid??filter', templateUrl: 'app/apiRequestLog/views/listApiRequestLog.html', controller: 'ListController', params: { entityData: apiData.apiRequestLog } },
			{ name: 'apiRequestLog.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/apiRequestLog/views/editApiRequestLog.html', controller: 'ApiRequestLogController' },

			//scheduledJob
			{ name: 'scheduledJob', url: '/scheduledJob', abstract: true, template: '<ui-view/>' },
			{ name: 'scheduledJob.list', availableWebMenu: 'Bakgrunnsjobber', url: '/list/:menuGuid??filter', templateUrl: 'app/scheduledJob/views/listScheduledJobs.html', controller: 'ListController', params: { entityData: apiData.scheduledJob } },
			{ name: 'scheduledJob.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/scheduledJob/views/editScheduledJob.html', controller: 'ScheduledJobController' },
			{ name: 'scheduledJob.create', url: '/create', params: { menuGuid: '', guidEntityToCopy: '', scheduledJobModel: false }, templateUrl: 'app/scheduledJob/views/editScheduledJob.html', controller: 'ScheduledJobController', excludeFromHistory: true },

			//scheduledJobExecution
			{ name: 'scheduledJobExecution', url: '/scheduledJobExecution', abstract: true, template: '<ui-view/>' },
			{ name: 'scheduledJobExecution.list', availableWebMenu: 'Utførte bakgrunnsjobber', url: '/list/:menuGuid??filter', templateUrl: 'app/scheduledJobExecution/views/listScheduledJobExecutions.html', controller: 'ListController', params: { entityData: apiData.scheduledJobExecution } },
			{ name: 'scheduledJobExecution.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/scheduledJobExecution/views/editScheduledJobExecution.html', controller: 'ScheduledJobExecutionController', excludeFromHistory: true },

			//emailTemplate
			{ name: 'emailTemplate', url: '/emailTemplate', abstract: true, template: '<ui-view/>' },
			{ name: 'emailTemplate.list', url: '/list/:menuGuid??filter', templateUrl: 'app/emailTemplate/views/listEmailTemplate.html', controller: 'ListController', params: { entityData: apiData.emailTemplate } },
			{ name: 'emailTemplate.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/emailTemplate/views/editEmailTemplate.html', controller: 'EmailTemplateController', excludeFromHistory: true },
			{ name: 'emailTemplate.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/emailTemplate/views/editEmailTemplate.html', controller: 'EmailTemplateController', excludeFromHistory: true },

			//Cache info
			{ name: 'cacheInfo', url: '/cacheInfo', abstract: true, template: '<ui-view/>' },
			{ name: 'cacheInfo.list', url: '/list', templateUrl: 'app/cacheInfo/views/cacheInfo.html', controller: 'CacheInfoController' },

			//entityLink
			{ name: 'entityLink', url: '/entityLink', abstract: true, template: '<ui-view/>' },
			{ name: 'entityLink.list', url: '/list/:menuGuid??filter', templateUrl: 'app/entityLink/views/listEntityLink.html', controller: 'ListController', params: { entityData: apiData.entityLink } },
			{ name: 'entityLink.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/entityLink/views/editEntityLink.html', controller: 'EntityLinkController' },
			{ name: 'entityLink.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/entityLink/views/editEntityLink.html', controller: 'EntityLinkController', excludeFromHistory: true },

			//entityCounter
			{ name: 'entityCounter', url: '/entityCounter', abstract: true, template: '<ui-view/>' },
			{ name: 'entityCounter.list', url: '/list/:menuGuid??filter', templateUrl: 'app/entityCounter/views/listEntityCounter.html', controller: 'ListController', params: { entityData: apiData.entityCounter, modal: { templateUrl: 'app/entityCounter/views/entityCounterModal.html', controller: 'EntityCounterModalController' } } },
			// { name: 'entityLink.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/entityLink/views/editEntityLink.html', controller: 'EntityLinkController' },
			// { name: 'entityLink.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/entityLink/views/editEntityLink.html', controller: 'EntityLinkController', excludeFromHistory: true },

			//entityTask
			{ name: 'entityTask', url: '/entityTask', abstract: true, template: '<ui-view/>' },
			{ name: 'entityTask.list', availableWebMenu: 'Handlinger', url: '/list/:menuGuid??filter', templateUrl: 'app/entityTask/views/listEntityTask.html', controller: 'ListController', params: { entityData: apiData.entityTask } },
			{ name: 'entityTask.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/entityTask/views/editEntityTask.html', controller: 'EntityTaskController' },

			//referenceType
			{ name: 'referenceType', url: '/referenceType', abstract: true, template: '<ui-view/>' },
			{ name: 'referenceType.list', availableWebMenu: 'Referanser', url: '/list/:menuGuid??filter', templateUrl: 'app/referenceType/views/listReferenceType.html', controller: 'ListController', params: { entityData: apiData.referenceType } },
			{ name: 'referenceType.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/referenceType/views/editReferenceType.html', controller: 'ReferenceTypeController' },
			{ name: 'referenceType.create', url: '/create', params: { menuGuid: '' }, templateUrl: 'app/referenceType/views/editReferenceType.html', controller: 'ReferenceTypeController', excludeFromHistory: true },

			{ name: 'weblistView', url: '/weblistView', abstract: true, template: '<ui-view/>' },
			{ name: 'weblistView.list', availableWebMenu: "Liste av skjermlister", url: '/list/:menuGuid??filter', templateUrl: 'app/webListView/views/listWebListView.html', controller: 'ListController', params: { entityData: apiData.webListView } },
			{ name: 'weblistView.edit', url: '/edit/:guid', params: { menuGuid: '' }, templateUrl: 'app/webListView/views/editWebListView.html', controller: 'WebListViewController' },

			{ name: 'userProfile', url: '/userProfile', abstract: true, template: '<ui-view/>' },
			{ name: 'userProfile.list', availableWebMenu: 'Brukerprofil', url: '/list/:menuGuid??filter', templateUrl: 'app/userProfile/views/listUserProfile.html', controller: 'ListController', params: { entityData: apiData.userProfile } },

			//smsProvider
			{ name: 'smsProvider', url: '/smsProvider', templateUrl: 'app/smsProvider/smsProvider.html', controller: 'SmsProviderController' },

			//branding
			{ name: 'branding', url: '/branding', templateUrl: 'app/branding/views/editBranding.html', controller: 'BrandingController' },

			//operationalMessage
			{ name: 'operationalMessage', url: '/operationalMessage', abstract: true, template: '<ui-view/>' },
			{ name: 'operationalMessage.list', availableWebMenu: 'Driftsmeldinger', url: '/list/:menuGuid??filter', templateUrl: 'app/operationalMessage/views/listOperationalMessage.html', controller: 'ListController', params: { entityData: apiData.operationalMessage, modal: { templateUrl: 'app/operationalMessage/views/operationalMessageModal.html', controller: 'OperationalMessageModalController' } } },

			//doorKeySystem
			{ name: 'doorKeySystem', url: '/doorKeySystem', abstract: true, template: '<ui-view/>' },
			{ name: 'doorKeySystem.list', availableWebMenu: 'Låssystemer', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorKeySystem.html', controller: 'ListController', params: { entityData: apiData.doorKeySystem } },
			{ name: 'doorKeySystem.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<door-key-system-details></door-key-system-details>' },
			{ name: 'doorKeySystem.create', url: '/create', params: { menuGuid: '' }, template: '<door-key-system-details></door-key-system-details>', excludeFromHistory: true },

			//doorKey
			{ name: 'doorKey', url: '/doorKey', abstract: true, template: '<ui-view/>' },
			{ name: 'doorKey.list', availableWebMenu: 'Nøkler', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorKey.html', controller: 'ListController', params: { entityData: apiData.doorKey } },
			{ name: 'doorKey.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<door-key-details></door-key-details>' },
			{ name: 'doorKey.create', url: '/create?guidDoorKeySystem', params: { menuGuid: '', guidDoorKeySystem: null }, template: '<door-key-details></door-key-details>', excludeFromHistory: true },

			//doorLock
			{ name: 'doorLock', url: '/doorLock', abstract: true, template: '<ui-view/>' },
			{ name: 'doorLock.list', availableWebMenu: 'Sylindre', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorLock.html', controller: 'ListController', params: { entityData: apiData.doorLock } },
			{ name: 'doorLock.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<door-lock-details></door-lock-details>' },
			{ name: 'doorLock.create', url: '/create?guidDoorKeySystem', params: { menuGuid: '' }, template: '<door-lock-details></door-lock-details>', excludeFromHistory: true },

			//doorKeyXUser
			{ name: 'doorKeyXUser', url: '/doorKeyXUser', abstract: true, template: '<ui-view/>' },
			{ name: 'doorKeyXUser.list', availableWebMenu: 'Utleveringer', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorKeyXUser.html', controller: 'ListController', params: { entityData: apiData.doorKeyXUser } },
			{ name: 'doorKeyXUser.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<door-key-x-user-details></door-key-x-user-details>' },

			//doorLockXArea
			{ name: 'doorLockXArea', url: '/doorLockXArea', abstract: true, template: '<ui-view/>' },
			{ name: 'doorLockXArea.list', availableWebMenu: 'Sylinder/areal', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorLockXArea.html', controller: 'ListController', params: { entityData: apiData.doorLockXArea } },

			//doorKeyXDoorLock
			{ name: 'doorKeyXDoorLock', url: '/doorKeyXDoorLock', abstract: true, template: '<ui-view/>' },
			{ name: 'doorKeyXDoorLock.list', availableWebMenu: 'Nøkkel/sylinder', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorKeyXDoorLock.html', controller: 'ListController', params: { entityData: apiData.doorKeyXDoorLock } },

			//doorKeyXDoorLock
			{ name: 'doorKeyTransaction', url: '/doorKeyTransaction', abstract: true, template: '<ui-view/>' },
			{ name: 'doorKeyTransaction.list', availableWebMenu: 'Nøkkeltransaksjoner', url: '/list/:menuGuid??filter', templateUrl: 'app/doorKeySystem/views/listDoorKeyTransaction.html', controller: 'ListController', params: { entityData: apiData.doorKeyTransaction } },

			{ name: 'databaseInfo', url: '/databaseInfo', template: '<database-info></database-info>' },

			{ name: 'installationValidation', url: '/installationValidation', template: '<installation-validation-details></installation-validation-details>' },

			//WorkingDaysOff
			{ name: 'workingDaysOff', url: '/workingDaysOff', abstract: true, template: '<ui-view/>' },
			{ name: 'workingDaysOff.list', availableWebMenu: "Liste av fridager", url: '/list/:menuGuid??filter', templateUrl: 'app/workingDaysOff/views/listWorkingDaysOff.html', controller: 'ListController', params: { entityData: apiData.workingDaysOff } },
			{ name: 'workingDaysOff.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<working-days-off-details></working-days-off-details>', },
			{ name: 'workingDaysOff.create', url: '/create', params: { menuGuid: '' }, template: '<working-days-off-details></working-days-off-details>', excludeFromHistory: true },

			{ name: 'changeSet', url: '/changeSet', abstract: true, template: '<ui-view/>' },
			{ name: 'changeSet.list', availableWebMenu: 'Endringsett', url: '/list/:menuGuid??filter', templateUrl: 'app/changeSet/views/listChangeSet.html', controller: 'ListController', params: { entityData: apiData.changeSet, modal: { templateUrl: 'app/changeSet/views/changeSetModal.html', controller: 'ChangeSetModalController' } } },

			{ name: 'externalLoginProvider', url: '/externalLoginProvider', abstract: true, template: '<ui-view/>' },
			{ name: 'externalLoginProvider.list', url: '/list', template: '<external-login-provider-list></external-login-provider-list>' },
			{ name: 'externalLoginProvider.edit', url: '/edit/:guid', params: { menuGuid: '' }, template: '<external-login-provider-details></external-login-provider-details>' },
			{ name: 'externalLoginProvider.create', url: '/create', params: { menuGuid: '' }, template: '<external-login-provider-details></external-login-provider-details>', excludeFromHistory: true },

			//userXUserNotification
			{ name: 'userXUserNotification', url: '/userXUserNotification', abstract: true, template: '<ui-view/>' },
			{ name: 'userXUserNotification.list', url: '/list', templateUrl: 'app/userXUserNotification/views/listUserXUserNotification.html', controller: 'ListController', params: { entityData: apiData.userXUserNotification } },
		];

		// Reroute ListController routes to React page
		//routes.forEach(function (route) {
		//	if (route.controller === "ListController" && route.params && route.params.entityData) {
		//		route.controller = "ReactCommonListController";
		//		route.templateUrl = "app/common/react/reactCommonList.html";
		//	}
		//});

		return routes;
	}
})();
