(function () {
	angular.module('Plania').controller('CostController', ['$scope', 'Repository', '$q', '$stateParams', 'TranslationService', '$modal', '$localStorage', 'AccountingService', 'RegisteredFieldService', controller]);

	function controller($scope, repository, $q, $stateParams, translationService, $modal, $localStorage, accountingService, registeredFieldService) {
		$scope.isUpdate = $scope.navigation.current.name === 'cost.edit';
		$scope.model = { Amount: "0" };
		$scope.selectedSubEntity = null;
		$scope.modelDto = {};
		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.prefix = repository.commonService.prefix.Cost;
		$scope.isDocumentsCollapsed = true;
		$scope.reloadDocumentTable = false;
		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictSave = !repository.authService.hasEditAccess(repository.commonService.prefix.Cost, checkOtherDo);
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Cost, checkOtherDo);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Cost, checkOtherDo);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Cost);
			}
		};

		updateAccess();

		$scope.getValidityRules = function (fieldName) {
			if (!$scope.baseFieldrules)
				return null;// cannot do anything as long as base rules are not loaded (done asynchronously)

			var fieldrule = _.filter($scope.baseFieldrules, { DomainFieldName: fieldName })[0];
			return accountingService.onGetValidityRules($scope.modelDto.dataOwner, fieldrule);
		};

		$scope.clearSubEntitySelection = function () {
			$scope.model.GuidEquipment = '';
			$scope.equipment = '';
			$scope.model.GuidArea = '';
			$scope.area = '';
			$scope.model.GuidProject = '';
			$scope.project = '';
		};

		var setSubEntityVisibility = function (fieldRules) {
			if (!fieldRules)
				return;

			// Visibility for Radiobuttons
			$scope.subEntityVisibility = {
				showEquipment: !_.filter(fieldRules, { DomainFieldName: 'GuidEquipment' })[0].Hidden,
				showArea: !_.filter(fieldRules, { DomainFieldName: 'GuidArea' })[0].Hidden,
				showProject: !_.filter(fieldRules, { DomainFieldName: 'GuidProject' })[0].Hidden,
			};

			var countHiddenFields = 0;
			if (!$scope.subEntityVisibility.showEquipment)
				countHiddenFields++;
			if (!$scope.subEntityVisibility.showArea)
				countHiddenFields++;
			if (!$scope.subEntityVisibility.showProject)
				countHiddenFields++;

			$scope.subEntityVisibility.hidden = countHiddenFields === 2 || countHiddenFields === 3;

			// Visibility for selectedSubEntity
			if ($scope.subEntityVisibility.showEquipment)
				$scope.selectedSubEntity = 'Equipment';
			else if ($scope.subEntityVisibility.showArea)
				$scope.selectedSubEntity = 'Area';
			else if ($scope.subEntityVisibility.showProject)
				$scope.selectedSubEntity = 'Project';
		};

		var setParent = function (parentPropertyName, parent) {
			if (parent) {
				$scope.model[parentPropertyName] = parent ? parent : null;
				$scope.model['Guid' + parentPropertyName] = parent ? parent.Guid : null;

				// set caption as modelDto member for consistency with accounting card
				$scope.modelDto[_.camelCase(parentPropertyName) + "Caption"] = parent ? getAutocompleteString(parent) : null;
			}
			else {
				delete $scope.model[parentPropertyName];
				delete $scope.model['Guid' + parentPropertyName];
				if ($scope.modelDto)
					delete $scope.modelDto[_.camelCase(parentPropertyName) + "Caption"];
			}
		};

		var synchronizeParents = function () {
			for (var propertyName in $scope.model) {
				if (!propertyName.startsWith('Guid'))
					continue;
				var parentPropertyName = _.capitalize(propertyName.substring(4));
				var parent = $scope.model[parentPropertyName];
				if (parent)
					setParent(parentPropertyName, parent);
			}
		};

		var setProperties = function () {
			// reset the DTO to remove properties that were set before reloading but are no longer relevant
			$scope.modelDto = { dataOwner: $scope.model.DataOwner };
			synchronizeParents();
		};

		var columns = [
			'Building.Id', 'Building.Description',
			'Equipment.Id', 'Equipment.Description',
			'Area.Id', 'Area.Description',
			'Project.Id', 'Project.Decription',
			'Supplier.Id', 'Supplier.Description',
			'Consumable.Id', 'Consumable.Description',
			'Account.Id', 'Account.Description',
			'CostCenter.Id', 'CostCenter.Description',
			'WorkOrder.Id', 'WorkOrder.Description',
			'PurchaseOrder.Id', 'PurchaseOrder.Description',
			'PurchaseOrderItem.Id', 'PurchaseOrderItem.Description',
			'Accounting0.Id', 'Accounting0.Description',
			'Accounting1.Id', 'Accounting1.Description',
			'Accounting2.Id', 'Accounting2.Description',
			'Accounting3.Id', 'Accounting3.Description',
			'Accounting4.Id', 'Accounting4.Description',
			'Department.Id', 'Department.Description',
			'DataOwner.DepartmentCost', 'DataOwner.LastDimensionCost', 'DataOwner.Dimension1', 'DataOwner.Dimension2', 'DataOwner.Dimension3', 'DataOwner.Dimension4', 'DataOwner.Dimension5'
		];

		// gather data that are going to be passed to the API, which will use them during entity creation process
		var getPresets = function () {
			if ($scope.isUpdate)
				return null;// not relevant for already created entities
			var presets = null;

			var setParameter = function (name, value) {
				if (!value)
					return false;
				if (!presets)
					presets = {};
				presets[name] = value;
				return true;
			};

			if ($localStorage.userData.userAccount)
				setParameter("GuidAccount", $localStorage.userData.guidAccount);

			if ($stateParams.showPersistantData) {
				var persistedData = repository.persistedData.getPersistedData('cost.create');
				if (persistedData) {
					setParameter("GuidProject", persistedData.GuidProject);
					setParameter("GuidPurchaseOrder", persistedData.GuidPurchaseOrder);
					setParameter("GuidPurchaseOrderItem", persistedData.GuidPurchaseOrderItem);
					setParameter("GuidWorkOrder", persistedData.GuidWorkOrder);
				}
				else
					setParameter("GuidDepartment", $localStorage.userData.guidDepartment);
			}
			return presets;
		};

		var onGet = function (response) {
			var cost = response.Data ? response.Data : response;
			if (!$scope.baseFieldrules && response.FieldRules)
				$scope.baseFieldrules = response.FieldRules;

			$scope.model = cost;

			setSubEntityVisibility(response.FieldRules);

			if ($scope.model.GuidEquipment) $scope.selectedSubEntity = 'Equipment';
			if ($scope.model.GuidArea) $scope.selectedSubEntity = 'Area';
			if ($scope.model.GuidProject) $scope.selectedSubEntity = 'Project';

			updateAccess();
			setProperties();
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

			if ($scope.isUpdate) {
				$scope.reload = true;
				$scope.reloadDocumentTable = true;
			}

			if (!$scope.restrictEdit)
				$scope.restrictEdit = $scope.model.IsInvoiced;
			if (!$scope.restrictSave)
				$scope.restrictSave = $scope.model.IsInvoiced;
			if (!$scope.restrictDelete)
				$scope.restrictDelete = $scope.model.IsInvoiced;
		};

		var getEntity = function () {
			var defer = $q.defer();
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			var queryString = JSON.stringify(columns);
			if ($stateParams.guidEntityToCopy)
				queryString += "&GuidCopyFrom=" + $stateParams.guidEntityToCopy;
			var presets = getPresets();
			repository.getMainModel(repository.apiData.cost.url, ($scope.isUpdate ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), queryString, presets ? JSON.stringify(presets) : null).then(
				function (response) {
					onGet(response);
					defer.resolve(response);
				}).catch(function (error) {
					repository.growl(error, 'danger');
					defer.reject();
				});

			return defer.promise;
		};

		// Process model to prepare it to be sent to the API
		var getUpdatePayload = function () {
			// get all (not null) parent properties currently defined for the entity (not all "registered" properties of the BL class may exist in the JS model)
			// assume that no parent property is defined in the JS entity without its FK counterpart (= for all 'XXX' there is a 'GuidXXX')
			var parentProperties = [];
			for (var propertyName in $scope.model) {
				if (!propertyName.startsWith('Guid'))
					continue;
				var parentPropertyName = _.capitalize(propertyName.substring(4));
				var parent = $scope.model[parentPropertyName];
				if (parent)
					parentProperties.push(parentPropertyName);
			}

			// get a copy of the original entity whose parent entities are reset (to be passed as payload for update function)
			var payloadEntity = angular.copy($scope.model);
			parentProperties.forEach(function (field) {
				delete payloadEntity[field];
			});

			return {
				payload: payloadEntity,
				parentProperties: parentProperties
			};
		};

		var reApplyParentProperties = function (model, parentProperties) {
			// instead of copying every single property to the model, while skipping parent properties that are set in the model but not in the response,
			// it is more convenient to first copy from the model to the response every parent property set in the model but missing in the response (provided its GUID is valid), then copy the whole response object back to the model
			parentProperties.forEach(function (field) {
				if (!model[field] && model['Guid' + field] && $scope.model[field])
					model[field] = angular.copy($scope.model[field]);
			});
		};

		var updateEntity = function () {
			// properties to parent entities may contain incomplete (ex. when fetched using columns), outdated or unreliable data, that may cause errors when deserialized on server side
			// furthermore, they would send an unnecessary heavy payload
			// -> unless explicitly required (seldom), reset them to be sure they are correctly reloaded if needed
			var defer = $q.defer();

			var updatePayload = getUpdatePayload();

			($scope.isUpdate ? repository.updateSingle : repository.createSingle)(repository.apiData.cost.url, updatePayload.payload).then(
				function (response) {
					// on returning from update, refresh the model with any up-to-date parent property sent by the API
					// NB: the API may not send all properties currently loaded in the model (if the BL did not need them, they were not loaded, just the GUID is valid)

					reApplyParentProperties(response, updatePayload.parentProperties);
					onGet(response);
					defer.resolve(response);
				}).catch(function (error) {
					repository.growl(error, 'danger');
					defer.reject();
				});
			return defer.promise;
		};

		getEntity();

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "equipment":
					filter.PropertyFilter = [{ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding }];
					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					return filter;
				case "area":
				case "project":
					filter.PropertyFilter = [{ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding }];
					return filter;
				case "purchaseOrderItem":
					filter.PropertyFilter = [];
					if ($scope.model.GuidPurchaseOrder) {
						filter.PropertyFilter.push({ Property: 'GuidPurchaseOrder', Operator: '=', Value: $scope.model.GuidPurchaseOrder });
					}
					if ($scope.model.GuidWorkOrder) {
						filter.PropertyFilter.push({ Property: 'GuidWorkOrder', Operator: '=', Value: $scope.model.GuidWorkOrder });
					}
					if ($scope.model.GuidProject) {
						filter.PropertyFilter.push({ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.GuidProject });
					}
					//Can't add costs to proposals and closed objects
					filter.PropertyFilter.push({ Property: 'Status', Operator: '<>', Value: 0 });
					filter.PropertyFilter.push({ Property: 'Status', Operator: '<>', Value: 6 });
					return filter;
				case "purchaseOrder":
					filter.PropertyFilter = [];
					if ($scope.model.GuidWorkOrder) {
						filter.PropertyFilter.push({ Property: 'PurchaseOrderItem.GuidWorkOrder', Operator: '=', Value: $scope.model.GuidWorkOrder });
					}
					if ($scope.model.GuidProject) {
						filter.PropertyFilter.push({ Property: 'PurchaseOrderItem.WorkOrder.GuidProject', Operator: '=', Value: $scope.model.GuidProject });
					}
					//Can't add costs to proposals and closed objects
					filter.PropertyFilter.push({ Property: 'Status', Operator: '<>', Value: 0 });
					filter.PropertyFilter.push({ Property: 'Status', Operator: '<>', Value: 6 });
					return filter;
				case "workOrder":
					if ($scope.model.GuidProject) {
						filter.PropertyFilter = [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.GuidProject }];
					}
					return filter;
			}
		};

		$scope.update = function (destination) {
			var onUpdate = function () {
				if ($scope.model.PostingDate) {
					var postingDate = new Date($scope.model.PostingDate);
					postingDate.setHours(postingDate.getHours() + 2);
					$scope.reloadDocumentTable = true;
					$scope.model.PostingDate = postingDate.toISOString();
				}

				var success = function (result) {
					repository.growl($scope.isUpdate ? translationService.translate('update-cost-success', 'Kostnaden har blitt oppdatert.') : translationService.translate('create-cost-success', 'Kostnaden har blitt opprettet.'), 'success');
					var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
					repository.commonService.setLastRegisterGuid(destination, guid);
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
				};

				var error = function (error) {
					if (typeof (error) === "string") {
						repository.growl(error, 'danger');
					} else {
						if (error) // Is handled already in UpdateEntity, but keeping it here just in case
							repository.growl(error.Data.Message, 'danger');
					}
				};

				updateEntity().then(success, error);
			};

			var amountFieldRule = registeredFieldService.getLocalRegisteredField(repository.apiData.cost.prefix, 'Amount');

			if (!$scope.model.Amount && amountFieldRule && amountFieldRule.Mandatory) {
				swal({
					title: translationService.translate('web-cost-swal-missingAmount-title', 'Registrering av kostnad med 0 kr?'),
					text: translationService.translate('web-cost-swal-missingAmount-text', 'Ønsker du å fortsette?'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-cost-swal-missingAmount-confirm', 'Ja'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					onUpdate();
				});
			} else {
				onUpdate();
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cost-message', "Kostnaden vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cost-button-confirm', 'Ja, fjern Kostnaden'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.cost.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cost-success', 'Kostnaden ble fjernet!'), result, "success");
						$scope.goBack('priority.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		var getAutocompleteString = function (object) {
			if (!object)
				return "";
			if (object.Caption)
				return object.Caption;
			var caption = object.Id;
			if (object.Description)
				caption += ' - ' + object.Description;
			return caption;
		};

		var run = function (action, parameter1) {
			var defer = $q.defer();
			$scope.model.RequestAction = action;
			$scope.model.RequestActionParameter1 = parameter1;
			updateEntity().then(
				function (response) {
					defer.resolve(response);
				}).catch(function (error) {
					defer.reject();
				});
			return defer.promise;
		};

		$scope.isFieldEnabled = function (field) {
			if ($scope.restrictEdit)
				return false;

			var model = $scope.model;
			// do not lock empty mandatory fields (the entiy could then not be saved)
			if (field.startsWith('Guid') && !model[field]) {
				var validityRules = $scope.getValidityRules(field);
				if (validityRules && validityRules.Mandatory)
					return true;
			}

			var canEdit = !$scope.isUpdate || $localStorage.generalOptions.CanEditOrDeleteCost;

			switch (field) {
				case 'GuidPurchaseOrderItem':
				case 'Description':
					break;
				default:
					if (!canEdit)
						return false;
					break;
			}

			var isLockedAccounting = model.GuidPurchaseOrderItem && !$localStorage.generalOptions.CanChangePoItemFromCost;

			switch (field) {
				case 'GuidPurchaseOrderItem':
				case 'Description':
				case 'Quantity':
				case 'GrossAmount':
					return true;
				case 'GuidBuilding':
					return !model.GuidPurchaseOrderItem && !model.GuidWorkOrder && !model.GuidEquipment && !model.GuidArea && !model.GuidProject;
				case 'GuidSupplier':
					return !model.GuidPurchaseOrderItem && !model.GuidPurchaseOrder;
				case 'Amount':
					return !$localStorage.generalOptions.UseUnitPriceWithCost;
				case 'UnitPrice':
					return !model.GuidConsumable;
				case 'GuidEquipment':
				case 'GuidArea':
				case 'GuidProject':
				case 'GuidWorkOrder':
				case 'GuidPurchaseOrder':
					return !model.GuidPurchaseOrderItem;
				case 'GuidCostCenter':
					return !isLockedAccounting && !($localStorage.generalOptions.GetPoItemAccountingFromWo && (model.GuidWorkOrder || model.GuidPurchaseOrder));
				case 'GuidDepartment':
					return !isLockedAccounting && !($localStorage.generalOptions.GetPoItemAccountingFromWo && model.WorkOrder && model.WorkOrder.GuidDepartment);
				case 'GuidAccount':
				case 'GuidAccounting0':
				case 'GuidAccounting1':
				case 'GuidAccounting2':
				case 'GuidAccounting3':
				case 'GuidAccounting4':
					return !isLockedAccounting;
				default:
					return !model.GuidPurchaseOrderItem;
			}

			return true;
		};

		$scope.onSelectParent = function (parentPropertyName, parent) {
			if (!parent) {
				setParent(parentPropertyName, parent);
				return;
			}
			if ($scope.model[parentPropertyName] && $scope.model[parentPropertyName].Guid === parent.Guid)
				return;

			$scope.model['Guid' + parentPropertyName] = parent.Guid;

			// let the BL handle it:
			// - consistent behaviour, whether the parent is set during creation, or selected by user
			// - the logic may lead to a possibly complicated process (ex. selecting purchase order may transfer synchronization to unique child item)
			// - attempting to do it in front end may anyway require loading some entities, what would defeat the purpose
			// - (performance: can eventually get rid of hardcoded column preset for each type in autoCompleteService, which are use only for some contexts, but load to much unused data in others)
			$scope.activateAutocomplete = false;

			var url = repository.apiData.cost.endpoint['synchronizeWith' + parentPropertyName];

			var updatePayload = getUpdatePayload();
			repository.runAction(url, updatePayload.payload, "columns=" + JSON.stringify(columns), true).then(function (response) {
				reApplyParentProperties(response, updatePayload.parentProperties);
				onGet(response);
				$scope.activateAutocomplete = true;
			});
		};

		$scope.onPurchaseOrderItem = function (purchaseOrderItem) {
			$scope.onSelectParent('PurchaseOrderItem', purchaseOrderItem);
		};

		$scope.onWorkOrder = function (workOrder) {
			$scope.onSelectParent('WorkOrder', workOrder);
		};

		$scope.onPurchaseOrder = function (purchaseOrder) {
			$scope.onSelectParent('PurchaseOrder', purchaseOrder);
		};

		$scope.onConsumable = function (consumable) {
			$scope.onSelectParent('Consumable', consumable);
		};

		// No need to have the watcher active when it is not used
		if (!$scope.isUpdate) {
			$scope.$watch('model.InvoiceDate', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$scope.onInvoiceDateChanged();
			});
		}

		$scope.onInvoiceDateChanged = function () {
			if ($scope.isUpdate) return;
			$scope.model.PostingDate = $scope.model.InvoiceDate;
		};

		$scope.openPurchaseOrderItem = function () {
			if (!$scope.model.GuidPurchaseOrderItem || !$scope.hasEditAccess("PurchaseOrderItem"))
				return;

			$modal.open({
				templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
				controller: 'PurchaseOrderItemModalController',
				size: 'xl',
				resolve: {
					params: function () {
						return {
							Guid: $scope.model.GuidPurchaseOrderItem
						};
					}
				}
			}).result.then(function (result) {
			}, function () {
			});
			return;
		};

		if ($localStorage.generalOptions.UseUnitPriceWithCost) {
			$scope.$watchGroup(['model.Quantity', 'model.UnitPrice'], function (newVal, oldVal) {
				$scope.model.Amount = $scope.model.Quantity * $scope.model.UnitPrice;
			});
		}
	}
})();
