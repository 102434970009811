(function () {
	angular.module('Plania').controller('MassEditModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'EnumService',controller]);

	function controller($scope, $modalInstance, params, repository, translationService, enumService) {
		var entityPrefix = params.prefix;
		$scope.datePickers = {};
		$scope.showPeriodicCleaningRecurrence = params.showPeriodicCleaningRecurrence;

		var entityApiData = _.find(repository.apiData, function (obj) {
			return obj.prefix === entityPrefix && !!obj.url;
		});

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix[entityPrefix]).then(function (result) {
			$scope.fieldRules = result;
			$scope.columns = [];

			params.columns.slice().forEach(function (column) {
				var columnFieldRule = _.find($scope.fieldRules, function (o) { return o.DomainFieldName === column.Property; });

				if (column.Property === 'UpdatedDate' || column.Property === 'CreationDate') return;
				if (columnFieldRule && (columnFieldRule.Unique || columnFieldRule.NotEditableInListView)) return;

				if (column.PropertyType === 'date') {
					$scope.datePickers[column.Title] = false;
				}

				if (column.Property.indexOf('CleaningQuality.') >= 0) {
					column.PropertyType = 'autocomplete';
					column.AutocompleteProperty = 'GuidCleaningQuality';
					column.AutocompleteService = 'cleaningQuality';
				}

				if ((entityPrefix === repository.apiData.area.prefix) && (column.Property.indexOf('ResourceGroup.') >= 0)) {
					column.PropertyType = 'autocomplete';
					column.AutocompleteProperty = 'GuidCleaningTeam';
					column.AutocompleteService = 'resourceGroup';
				}

				if (column.PropertyType.toLowerCase() === 'recurrence' || column.PropertyType.toLowerCase() === 'recurrencerule') {
					column.RecurrenceRule = {};
					column.StartDate = new Date();
					// Todo - Set default values from one place
					if ($scope.showPeriodicCleaningRecurrence) {
						column.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Once',
							PeriodNumber: 1,
							PeriodUnit: 'Day'
						};
					} else {
						column.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Day'
						};
					}
					$scope.model = column;
				}

				$scope.columns.push(column);
			});
		});

		$scope.selectedRows = params.selectedRows;
		$scope.selectedColumns = [{}];

		$scope.getSelectedRowCaption = function (result) {
			var object = _.find(params.selectedRowsObjects, { Guid: result.Guid });
			if (object.Caption) return object.Caption;

			if (object.Area.Caption) return object.Area.Caption;
			if (object.Building.Caption) return object.Building.Caption;
		};

		$scope.save = function () {
			var editedRows = [];

			$scope.selectedRows.forEach(function (rowGuid) {
				var editedRow = { Guid: rowGuid };
				$scope.selectedColumns.forEach(function (column) {
					if (!column.model) {
						return;
					}

					if (column.model.PropertyType.toLowerCase() === 'recurrence' || column.model.PropertyType.toLowerCase() === 'recurrencerule') {
						if (column.model.RecurrenceRule) {
							enumService.setTimeUnitOnRecurrence(column.model.RecurrenceRule);

							if (column.model.RecurrenceRule && column.model.RecurrenceRule.StartDate)
								column.model.RecurrenceRule.StartDate = new Date(column.model.RecurrenceRule.StartDate).toISOString();

							if (column.model.RecurrenceRule && column.model.RecurrenceRule.EndDate)
								column.model.RecurrenceRule.EndDate = new Date(column.model.RecurrenceRule.EndDate).toISOString();

							if (column.model.RecurrenceRule && column.model.RecurrenceRule.Until)
								column.model.RecurrenceRule.Until = new Date(column.model.RecurrenceRule.Until).toISOString();

							editedRow.RecurrenceRule = column.model.RecurrenceRule;
						}

						if (column.model.StartDate) {
							editedRow.StartDate = new Date(column.model.StartDate).toISOString();
						}

						if (column.model.UntilDate) {
							editedRow.UntilDate = new Date(column.model.UntilDate).toISOString();
						}
					}

					else if (column.model.NewValue || column.model.NewValue === 0) {
						if (column.model.PropertyType === 'string' || column.model.PropertyType === 'number') {
							editedRow[column.model.Property] = column.model.NewValue;
						} else if (column.model.PropertyType === 'autocomplete') {
							editedRow[column.model.AutocompleteProperty] = column.model.newPropertyValue;
						} else if (column.model.PropertyType === 'date') {
							editedRow[column.model.Property] = new Date(column.model.NewValue).toISOString();
						}
					}
				});
				editedRows.push(editedRow);
			});

			repository.patch(entityApiData.url, null, editedRows).then(function (response) {
				$scope.updateResult = response;

				var success = _.every(response, function (response) {
					return response.IsSuccess;
				});

				if (success)
					$modalInstance.close();
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.columnWasSelected = function (column) {
			var index = $scope.columns.indexOf(column);
			$scope.columns.splice(index, 1);
			if ($scope.columns.length > 0) {
				$scope.selectedColumns.push({});
			}
		};

		$scope.removeSelectedColumn = function (column, index) {
			$scope.selectedColumns.splice(index, 1);

			if ($scope.columns.length === 0) {
				$scope.selectedColumns.push({});
			}

			$scope.columns.push(column);
		};

		$scope.autocompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "resourceGroup":
					if (entityPrefix === repository.commonService.prefix.AreaXCleaningTask) {
						filter.FilterModel = {
							Condition: "and",
							Rules: [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }]
						};
					}

					return filter;
			}
		};

		$scope.cancel = function () {
			if ($scope.updateResult) {
				$modalInstance.close();
			} else {
				$modalInstance.dismiss('canceled');
			}
		};
	}
})();
