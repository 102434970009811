(function () {
	angular.module('Plania').controller('DesktopModalOpenController', ['$scope', '$modal', '$window', '$localStorage', 'authService', openModalController]);

	function openModalController($scope, $modal, $window, $localStorage, authService) {
		var vm = this;
		vm.AllowApiConnections = $localStorage.generalOptions.AllowApiConnections;
		vm.openModal = function () {
			$modal.open({
				controller: 'DesktopModalController',
				templateUrl: 'app/common/views/DesktopModal.html'
			}).result.then(function(result) {
				$window.open('plania://url-' + window.location.origin + '|' + $localStorage.planiaToken.refresh, '_self');
				authService.forceRefreshAuthentication();
			});
		};
	}

	angular.module('Plania').controller('DesktopModalController', ['$scope', '$modalInstance', '$http', 'config', '$localStorage', desktopModalController]);

	function desktopModalController($scope, $modalInstance, $http, config, $localStorage) {

		$scope.collapseDesktop = $localStorage.CollapseDesktopInstallationGuide;

		$http({
			method: 'GET',
			url: encodeURI(config.baseUrl + 'Clients/Version.txt')
		}).then(function (result) {
			$scope.version = result.data;
			$scope.version.PlaniaLauncherDownloadURL = '/' + result.data.PlaniaLauncherDownloadURL;
		});

		$scope.openDesktop = function () {
			$localStorage.CollapseDesktopInstallationGuide = true;
			$modalInstance.close('open');
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}
})();
