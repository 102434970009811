(function () {
    angular.module('Plania').factory('MapService', ['$localStorage',
        function ($localStorage) {
            var service = {};

            service.getPropertyFilter = function (entity, params) {
                return [];
            };

            return service;
        }]);
})();
