(function () {
	angular.module('Plania').controller('ConditionTypeController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'conditionType.edit';
		$scope.model = {
			Guid: $stateParams.guid,
			LikertScale: 0
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.ConditionType, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ConditionType, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.ConditionType);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ConditionType);
			}
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.conditionType.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
					$scope.reload = true;
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-conditionType-success', 'Tilstandstypen ble oppdatert.') : translationService.translate('create-conditionType-success', 'Tilstandstypen ble opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate)
				repository.updateSingle(repository.apiData.conditionType.url, $scope.model).then(success).catch(error);
			else
				repository.createSingle(repository.apiData.conditionType.url, $scope.model).then(success).catch(error);
		};

		$scope.remove = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-conditionType-message', "Tilstandstypen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-conditionType-button-confirm', 'Ja, fjern tilstandstypen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.conditionType.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-conditionType-success', 'Tilstandstypen ble fjernet!'), result, "success");
						$scope.goBack('conditionType.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.isNumber = function (value) {
			return angular.isNumber(value) && !isNaN(value);
		};
	}
})();
