(function () {
	// Keep in sync with listService.getPropertyValue for likert scale.
	var app = angular.module('Plania');
	app.directive('plLikertScale', function () {
		return {
			restrict: 'E',
			scope: {
				ngModel: '=',
			},
			controller: ['$scope', 'Constants', controller],
			template: '<span ng-show="colors[ngModel]" class="colored-circle colored-circle-readonly" style="background-color: {{colors[ngModel]}}"></span>'
		};
	});

	function controller($scope, constants) {
		$scope.colors = constants.defaultLikertScaleColors;
	}
})();
