(function () {
	angular.module('Plania').controller('CleaningQualityController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', '$localStorage', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService, $localStorage) {
		$scope.isUpdate = $scope.navigation.current.name === 'cleaningQuality.edit';
		$scope.model = {};
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-area-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-area-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-areaCategory-Description'), Property: 'AreaCategory.Description', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-area-ComputedGrossArea'), Property: 'ComputedGrossArea', PropertyType: 'string' },
			{ Position: 7, Title: translationService.translate('web-area-ComputedNetArea'), Property: 'ComputedNetArea', PropertyType: 'string' }
		];

		if ($localStorage.generalOptions && $localStorage.generalOptions.UseAreaClassification) {
			$scope.areaColumns.push({ Position: 8, Title: translationService.translate('web-Area-MainFunction'), Property: 'MainFunction', PropertyType: 'string' });
			$scope.areaColumns.push({ Position: 9, Title: translationService.translate('web-Area-PartFunction'), Property: 'PartFunction', PropertyType: 'string' });
			$scope.areaColumns.push({ Position: 10, Title: translationService.translate('web-Area-RoomName'), Property: 'RoomName', PropertyType: 'string' });
			$scope.areaColumns.push({ Position: 11, Title: translationService.translate('web-Area-RoomSpecification'), Property: 'RoomSpecification', PropertyType: 'string' });
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.CleaningQuality, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.CleaningQuality, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.CleaningQuality);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.CleaningQuality);
			}
		};

		updateAccess();

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.cleaningQuality.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;

					updateAccess();

					$scope.reload = true;
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);

			$scope.areaTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { Id: 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidCleaningQuality', Operator: '=', Value: $stateParams.guid }] }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						$scope.loadingAreas = true;
						var columns = _.map($scope.areaColumns, 'Property');

						repository.GetPaginated(repository.apiData.area.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.loadingAreas = false;
								$scope.areaTable.settings().total = result.TotalCount;
								$scope.areaTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								$scope.loadingAreas = false;
								repository.growl(error, 'danger');
							});
					}
				});
		}

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-cleaningQuality-success', 'Renholdskvaliteten har blitt oppdatert.') : translationService.translate('create-cleaningQuality-success', 'Renholdskvaliteten har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.cleaningQuality.url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData.cleaningQuality.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningQuality-message', "Renholdskvaliteten vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningQuality-button-confirm', 'Ja, fjern Renholdskvaliteten'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.cleaningQuality.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cleaningQuality-success', 'Renholdskvaliteten ble fjernet!'), result, "success");
						$scope.goBack('cleaningQuality.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.addArea = function () {
			var columns = [
				{ Title: translationService.translate('web-building-id'), Property: 'Building.Id' },
				{ Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
				{ Title: translationService.translate('web-area-id'), Property: 'Id' },
				{ Title: translationService.translate('web-area-description'), Property: 'Description' },
				{ Title: translationService.translate('web-areacategory-Description'), Property: 'AreaCategory.Description' },
			];
			if ($localStorage.generalOptions && $localStorage.generalOptions.UseAreaClassification) {
				columns.push({ Title: translationService.translate('web-Area-MainFunction'), Property: 'MainFunction' });
				columns.push({ Title: translationService.translate('web-Area-PartFunction'), Property: 'PartFunction' });
				columns.push({ Title: translationService.translate('web-Area-RoomName'), Property: 'RoomName' });
				columns.push({ Title: translationService.translate('web-Area-RoomSpecification'), Property: 'RoomSpecification' });
			}

			var filter = {};
			if (!$scope.hasEditAccess('Area', null, true)) {
				filter.PropertyFilter = [{
						Property: 'GuidDataOwner',
						Operator: '=',
						Value: repository.commonService.getFilterData().selectedDataOwner.Guid
					}
				];
			}

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til Rom',
							columns: columns,
							url: repository.apiData.area.url,
							sorting: { Id: 'asc' },
							filter: filter
						};
					}
				}
			}).result.then(function (selectedGuids) {
				var areas = [];

				selectedGuids.forEach(function (guid) {
					areas.push({ Guid: guid, GuidCleaningQuality: $scope.model.Guid });
				});

				repository.patch(repository.apiData.area.url, null, areas).then(function (response) {
					var success = _.every(response, function (response) {
						return response.IsSuccess;
					});

					if (success)
						repository.growl("Rommene har blitt lagt til", 'success');
					else
						repository.growl("En eller flere rom ble ikke lagt til", 'danger');

					$scope.areaTable.reload();
				}, function (error) {
					repository.showError(error);
				});
			}, function (error) {

			});
		};

		$scope.removeArea = function (area) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningquality-area-message', "Rommet vil ikke lenger være knyttet til renholdskvaliteten!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningquality-area-button-confirm', 'Ja, fjern rommet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.patch(repository.apiData.area.url, area.Guid, { GuidCleaningQuality: null }).then(function (response) {
					swal(translationService.translate('web-swal-cleaningquality-area-success', 'Rommet ble fjernet!'), "", "success");
					$scope.areaTable.reload();
				}, function (error) {
					swal("Error!", error, "error");
				});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}

})();
