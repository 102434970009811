(function () {
    angular.module('Plania').controller('AddNewLinkModalController', ['$scope', '$modalInstance', 'link', 'TranslationService', controller]);

	function controller($scope, modalInstance, link, translationService) {
		$scope.isUpdate = !!link;
		
        $scope.linkTypes = [
            {
                value: "UrlLink",
                name: translationService.translate('web-web-webDashboardWidget-link-type-urlLink', 'Url lenke')
            },
            {
                value: "WebMenu",
                name: translationService.translate('web-web-webDashboardWidget-link-type-webMenu', 'Webmeny')
            }
        ];

        if (link) {
            $scope.model = link;

            // Default of existing is UrlLink
            if (!$scope.model.Type)
                $scope.model.Type = "UrlLink";
        } else {
            $scope.model = {};
		}

		$scope.activateAutocomplete = true;

        $scope.onWebmenuSelect = function (webmenu) {
            if (!webmenu) {
                $scope.model.GuidWebMenu = "";
                return;
            }
            $scope.model.GuidWebMenu = webmenu.Guid;

            if (!$scope.model.Title) {
                $scope.model.Title = webmenu.Name;
            }
        };

        $scope.isSaveDisabled = function () {
            if (!$scope.model.Title || !$scope.model.Type) return true;

            if ($scope.model.Type === "UrlLink") {
                return !$scope.model.Url;
            } else if ($scope.model.Type === "WebMenu") {
                return !$scope.model.GuidWebMenu;
            }
            return false;
        };

        $scope.save = function () {
            if ($scope.model.IsExternalLink && $scope.model.Url.indexOf('http') !== 0) {
                $scope.model.Url = 'https://' + $scope.model.Url;
            }

            modalInstance.close($scope.model);
        };

		$scope.cancel = function () {
			modalInstance.dismiss("cancel");
        };
    }
})();
