(function () {
	angular.module('Plania').directive('plShowMore', [
		function showMoreDirective() {
			return {
				restrict: 'AE',
				replace: true,
				scope: {
					text: '=',
					textLimit: '=',
					rowLimit: '='
				},
				templateUrl: 'app/common/directives/views/plShowMoreDirective.html',

				link: function (scope, element, attrs) {
					if (!scope.textLimit) scope.textLimit = 500;
					if (!scope.rowLimit) scope.rowLimit = 10;

					var fallbackTextLimit = angular.copy(scope.textLimit);
					var fallbackRowLimit = angular.copy(scope.rowLimit);

					scope.showMoreClicked = false;

					scope.shouldShowMoreButton = function () {
						return scope.text && scope.text.length > scope.textLimit ||
							(scope.numberOfLineBreaks(scope.text) > scope.rowLimit && scope.rowLimit > 0);
					};

					scope.showMore = function() {
						scope.textLimit = scope.text.length;
						scope.rowLimit = 0;
						scope.showMoreClicked = true;
					};

					scope.showLess = function() {
						scope.textLimit = fallbackTextLimit;
						scope.rowLimit = fallbackRowLimit;
						scope.showMoreClicked = false;

						if (!planiaUtils.isElementInViewport(element))
							window.scrollTo(0, element[0].offsetTop - 100);
					};

					scope.numberOfLineBreaks = function(text) {
						if (text)
							return (text.match(/\r\n|\r|\n|<br \/>/g) || []).length;
						return 0;
					};
				}
			};
		}
	]);
})();
