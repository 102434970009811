(function () {

    var app = angular.module('Plania');

    app.directive('clusterPopup', function () {
        return {
            restrict: 'E',
            controller: ['$scope', 'ngTableParams', '$filter', controller],
            templateUrl: 'app/map/views/clusterMessage.html',
            link: function (scope, elements, attrs) {
            }
        };
    });

    function controller($scope, ngTableParams, $filter) {

        switch ($scope.selectedTheme) {
            case "basicData":
                $scope.title = "Grunndata";
                break;
            case "workOrder":
                $scope.title = "Arbeidsordre";
                break;
            case "request":
                $scope.title = "Meldinger";
                break;
            default:
                $scope.title = "Punkter";
        }

        $scope.mapClusterTableSmall = new ngTableParams({
            page: 1,
            count: 10
        },
            {
                total: $scope.clustermarkers.length,
                getData: function ($defer, params) {
                    var data = params.sorting() ? $filter('orderBy')($scope.clustermarkers, params.orderBy()) : $scope.clustermarkers;
                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                    $defer.resolve(data);
                }
            });
    }
})();
