(function () {
	angular.module('Plania').controller('UseDynamicCategoryCommonLabelsModalController', ['$scope', 'Repository', '$modalInstance', 'TranslationService', 'ngTableParams', 'ListService', controller]);
	function controller($scope, repository, modalInstance, translationService, ngTableParams, listService) {
		$scope.isLoading = true;
		$scope.validations = [];

		$scope.hasConflictsTexts = {
			header: translationService.translate('web-generalOptions-dynamicCategoryCommonLabelsValidation-alertHeader-hasConflicts', 'Ikke anbefalt å aktivere'),
			text: translationService.translate('web-generalOptions-dynamicCategoryCommonLabelsValidation-alertText-hasConflicts', 'Installasjonen inneholder kategorier som har konflikt mellom sine ledetekster. Undersøk helsesjekk for å se hvilke kategorier som har konflikt.'),
		};

		$scope.noConflictsTexts = {
			header: translationService.translate('web-generalOptions-dynamicCategoryCommonLabelsValidation-alertHeader-noConflicts', 'Anbefalt å aktivere'),
			text: translationService.translate('web-generalOptions-dynamicCategoryCommonLabelsValidation-alertText-noConflicts', 'Fant ingen kolliderende ledetekster blant alle kategorier på installasjonen.'),
		};

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-generalOptions-dynamicCategoryCommonLabelsValidation-dataOwnerId', 'Klient'), Property: 'DataOwnerId', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-generalOptions-dynamicCategoryCommonLabelsValidation-hasConflictingLabel', 'Har kollisjon'), Property: 'HasConflictingLabel', PropertyType: 'bool' },
		];

		repository.getSingleWithNoParameter(repository.apiData.generalOptions.url + "validate/UseDynamicCategoryCommonLabels").then(function (response) {
			$scope.isLoading = false;
			$scope.validations = response.Data.Validations;

		}, function (error) {
			repository.showError(error);
			$scope.isLoading = false;
		});

		$scope.table = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { 'DataOwnerId': 'asc' }
		}, {
			total: 0,
			counts: [10, 20, 50],
			filterDelay: 10,
			getData: function ($defer, params) {
				var sortingKey = Object.keys(params.sorting())[0];
				var direction = params.sorting()[sortingKey];

				var data = $scope.validations.sort(function (a, b) {
					var aVal = a[sortingKey];
					var bVal = b[sortingKey];

					if (aVal < bVal) {
						return -1;
					}
					if (aVal > bVal) {
						return 1;
					}
					return 0;
				});

				if (direction.toLowerCase() === "desc") data = data.reverse();

				data = data.slice(
					(params.page() - 1) * params.count(),
					params.page() * params.count()
				);

				$scope.table.settings().total = $scope.validations.length;
				$scope.table.settings().filterDelay = 50;
				$defer.resolve(data);
			}
		});

		$scope.confirm = function () {
			modalInstance.close();
		};

		$scope.cancel = function () {
			modalInstance.dismiss("Avbryt");
		};
	}
})();
