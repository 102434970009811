(function() {
    angular.module('Plania').factory('MenuService', ['Repository', '$state', 'CommonService', 'states', '$localStorage', 
        function MenuService(repository, $state, commonService, states, $localStorage) {
            var service = {};
            // Note that webmenu's references in here are modified version from repository.getShortcuts.

            service.getShortcuts = function() {
	            return repository.getShortcuts();
            };

            service.isDisabled = function (webMenu) {
                if (webMenu.state && webMenu.isBuildingDependent) {
                    return commonService.disableWebMenuNavigation();
                }
                return false;
            };

            service.getParams = function (webMenu) {
                if (service.isDisabled(webMenu) || !webMenu.state) {
                    return null;
                }

                var params = { menuGuid: webMenu.guid };
                if (webMenu.isBuildingDependent && webMenu.state === 'building.edit')
                    params.guid = commonService.getFilterData().selectedBuilding.Guid;
                return params;
            };

            service.cleanupBeforeNavigation = function (webMenu) {
                // Reset pagination
                try {
                    var splittedState = webMenu.state.split(".");
                    if (splittedState[1] && splittedState[1] === "list") {
                        var state = _.find(states.list, function (state) {
                            return state.name === webMenu.state;
                        });
                        if (state && state.params && state.params.entityData && state.params.entityData.prefix) {
                            var prefix = state.params.entityData.prefix;
                            if ($localStorage.listParams && $localStorage.listParams[prefix])
                                delete $localStorage.listParams[prefix].page;
                        }
                    }
                } catch (ex) { }
            };

            // Navigate if route is found.
            service.navigate = function (webMenu) {
                var params = service.getParams(webMenu);
                if (!params) return false;
                service.cleanupBeforeNavigation(webMenu);

                $state.go(webMenu.state, params, { reload: true, inherit: false });
                return true;
            };

            // Helper functions to check if user expects to open link in another tab.
            var isModifiedEvent = function (event) {
                return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
            };

            var shouldNavigateInternally = function (event) {
                var target = event.target.target;

                return event.button === 0 &&
                    (!target || target === "_self") &&
                    !isModifiedEvent(event);
            };

            // Helper to check if we should navigate internally or from default from link logic
            service.navigateFromLink = function (event, webMenu) {
                if (shouldNavigateInternally(event) && service.navigate(webMenu)) {
                    event.preventDefault();
                    return true;
                } else {
                    service.cleanupBeforeNavigation();
                    return false;
                }
            };

            service.getHref = function (webMenu) {
                var params = service.getParams(webMenu);
                if (!params) return null;
                return $state.href(webMenu.state, params, { reload: true, inherit: false });
            };

            return service;
        }
    ]);
})();
