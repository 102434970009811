(function () {
	angular.module('Plania').controller('BimFileCacheStatusModalController', ['$scope', '$modalInstance', 'Repository', 'params', controller]);

	function controller($scope, $modalInstance, repository, params) {
		
		$scope.ProcessingStatus = params.ProcessingStatus;
		$scope.ProcessingError = params.ProcessingError;
		$scope.RevisionComment = params.RevisionComment;

		$scope.bimCache = {};

		getCacheStatistics(true);

		function pollCacheStatistics(forcePolling) {
			if (params.ProcessingStatus === 'Internal' || forcePolling === true)
			{
				params.getModel().then(function (){
					getCacheStatistics(true);
				});
			}
			else
				getCacheStatistics();
		}

		function getCacheStatistics(withPolling) {
			repository.getSingleWithNoParameter(repository.apiData.bimFile.url + params.GuidBimFile + "/CacheStatistics").then(function (result){
				$scope.bimCache = result;
				if (withPolling)
					setTimeout(function () {
						pollCacheStatistics();
						}, 5000);
			});
		}

		$scope.resetCache = function (){
			repository.runAction(repository.apiData.bimFile.url + params.GuidBimFile + "/resetCache").then(function (result) {
				params.resetCacheData();
				setTimeout(function () {
					pollCacheStatistics(true);
				}, 5000);
			}, function(error){
				repository.growl(error, 'danger');
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('Canceled');
		};

	}
})();
