angular.module('Plania').directive('spinner', ['$window', function ($window) {
	return {
		restrict: 'A',
		link: function link(scope, element, attrs) {
			scope.spinner = null;
			scope.$watch(attrs.spinner, function (options) {
				if (scope.spinner) {
					scope.spinner.stop();
				}
				new $window.Spinner(options).spin(element[0]);
			}, true);
		},
	};
}]);
