(function () {
    angular.module('Plania').service('setSavingInterceptorService', ['$q', '$injector', '$rootScope', function ($q, $injector, rootScope) {
        var saveInterceptorService = {};
        var $http;

        var request = function (config) {
        	if (config.method === "GET")
        		return config;

        	rootScope.isSaving = true;
            return config;
        };

        var requestError = function (config) {
        	if (config.method === "GET")
        		return config;

        	rootScope.isSaving = false;
    		return $q.reject(config);
    	};

        var response = function (res) {
        	if (res.config.method === "GET")
        		return res;

        	rootScope.isSaving = false;
    		return res;
    	};

        var responseError = function (res) {
        	if (res.config.method === "GET")
        		return res;

    		rootScope.isSaving = false;
    		return $q.reject(res);
    	};

    	saveInterceptorService.request = request;
    	saveInterceptorService.requestError = requestError;
    	saveInterceptorService.response = response;
    	saveInterceptorService.responseError = responseError;

    	return saveInterceptorService;
    }]);
})();
