(function () {
	var app = angular.module('Plania');
	app.directive('viewField', function () {
		return {
			restrict: 'E',
			scope: {
				ngModel: '=ngModel',
				logicalType: '@logicalType' //phoneNumber
			},
			link: function (scope, element, attrs, ctrl) {
				scope.model = "-";
				scope.elementType = 'span';
				scope.elementData = {};

				var isEmpty = function (value) {
					return value === undefined || value === null || value === "";
				};

				var getPhoneAreaLabel = function (value) {
					if (isEmpty(value)) return;
					if (typeof (value) !== "string") {
						value = value.toString();
					}

					var splittedNumber = value.split("");
					var ariaLabelText = "";
					splittedNumber.forEach(function (str, index) {
						ariaLabelText += str;
						if (index !== splittedNumber.length - 1) {
							if ((index + 1) % 2 === 0) {
								ariaLabelText += ". ";
							} else {
								ariaLabelText += " ";
							}
						}
					});
					return ariaLabelText;
				};

				scope.$watch('ngModel', function (newVal, oldVal) {
					var ngModelAsString = !isEmpty(scope.ngModel) ? scope.ngModel.toString() : "";


					if (scope.logicalType === 'phoneNumber' && !isEmpty(ngModelAsString)) {
						scope.elementType = 'anchor';

						var phoneNumer = ngModelAsString.replace(/\s/g, '');
						scope.elementData.href = 'tel:' + phoneNumer;
						scope.elementData.ariaLabel = getPhoneAreaLabel(phoneNumer);
					} else {
						scope.elementType = 'span';
					}

					if (isEmpty(ngModelAsString))
						scope.model = "-";
					else
						scope.model = ngModelAsString;
				}, true);

			},
			templateUrl: 'app/common/directives/views/viewFieldDirective.html'
		};
	});
})();
