(function () {
	angular.module('Plania').factory('DynamicPropertyService', serviceMethod);

    function serviceMethod() {
		var service = {};

		service.getItems = function (groups) {
			var items = [];

			if (!Array.isArray(groups)) return items;

			groups.forEach(function (group) {
				group.Items.forEach(function (item) {
					if (item.Type === 'Category') {
						if (item.Template && item.Template.Groups) {
							service.getItems(item.Template.Groups).forEach(function (x) {
								items.push(x);
							});
						}
					} else {
						items.push(item);
					}
				});
			});

			return items;
		};

        return service;
    }
})();
