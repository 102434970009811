(function () {
	angular.module('Plania').controller('IssuePurchaseOrderModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', '$localStorage', 'WorkOrderService', controller]);

	function controller($scope, $modalInstance, params, repository, translationService, $localStorage, workOrderService) {
		$scope.isSaving = false;
		$scope.readyToIssue = false;
		$scope.validationMessage = null;
		$scope.model = params.model;
		$scope.canChangeStatus = !$localStorage.generalOptions.SendPoOnlyByMail;
		$scope.canActivateWorkOrder = false;
		if ($scope.model.OrderingDate) {
			repository.growl(translationService.translate('web-swal-purchaseOrder-issueOrder-alreadyIssued', 'Ordren er allerede bestilt'), 'danger');
			$scope.cancel();
		}

		// Keep errorMessage if user can activate workOrder
		var workOrderActivationErrorMessage;

		repository.runAction(repository.apiData.purchaseOrder.endpoint.canIssue + $scope.model.Guid).then(function (response) {
			// validation passed
			$scope.readyToIssue = true;
		}, function (errorMessage) {
			if (errorMessage) {
				if (workOrderService.isWorkOrderStage0(errorMessage) && $scope.hasEditAccess(repository.commonService.prefix.WorkOrder)) {
					$scope.canActivateWorkOrder = true;
					$scope.readyToIssue = true;
					workOrderActivationErrorMessage = errorMessage;
				}
				else if (workOrderService.isWorkOrderStage0(errorMessage) && !$scope.hasEditAccess(repository.commonService.prefix.WorkOrder))
					errorMessage = translationService.translate('web-purchaseOrder-workOrder-activate-missingAccess', 'Kan ikke bestille med en eller flere bestillingsobjekter koblet til en planlagt arbeidsordre og bruker mangler rettighet til å aktivere arbeidsordren');

				$scope.validationMessage = errorMessage;
			}
			return;
		});

		$scope.continue = function (sendEmail) {
			if ($scope.ValidationMessage || $scope.isSaving) return;

			var issueOrder = function () {
				$scope.isSaving = true;
				$modalInstance.close(sendEmail ? {
					ReportId:  $scope.model.GuidPurchaseOrderForm ? $scope.model.PurchaseOrderForm.Report.Id : 'PrintPurchaseOrder',
					ButtonTitle: translationService.translate('web-purchaseOrder-printPurchaseOrder-preview','Forhåndsvis bestillingsraport')
				} : 'changeStatus');
			};

			if ($scope.canActivateWorkOrder) {
				workOrderService.shouldActivateWorkOrder(params.guidWorkOrder, workOrderActivationErrorMessage)
					.then(function (result) {
						$scope.isSaving = false;
						issueOrder();
					}, function (error) {
						$scope.isSaving = false;
						if (error)
							repository.growl(error, 'danger');
					});
			}
			else
				issueOrder();

		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
